import "react-datepicker/dist/react-datepicker.css";
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import FormI from './FormI';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import './styles.css';

interface FormDateI extends FormI {
    placeHolder?: string,
    selected?: Date,
    value?: Date,
    onKeyDown?: any
    style?: any
    useMyClass?: boolean
}
export default function FormDate(props: FormDateI) {
    const onChange = props.onChange ?? (() => { });
    // const required = props.required ?? false;
    const requiredFeedback = props.requiredFeedback ?? "";
    const placeHolder = props.placeHolder ?? undefined;
    const selected = props?.selected ? props.selected : undefined;
    const value = props?.value ? props.value : undefined;
    const disabled = props.disabled ?? undefined;
    const onKeyDown = props.onKeyDown ?? undefined;
    const style = props.style ?? undefined;
    return (
        <>
            {props.label && <Form.Label>{props.label}</Form.Label>}
            <InputGroup hasValidation style={{ width: "100%", textAlignLast: "auto", ...(style ? style : {}) }} className={"lala"}>
                <InputGroup.Text id="eqmpqf" style={{ color: "gray", borderRadius: 0 }}>
                    <FontAwesomeIcon icon={faCalendar} style={{ color: "gray", borderRadius: 0 }} />
                </InputGroup.Text>
                {value ?
                    <DatePicker
                        portalId="START_DATE_PORTAL"
                        placeholderText={placeHolder}
                        className={props.useMyClass ? "aaaaform-control-date-pick-edit-case" : "sform-control"}
                        selected={selected}
                        onChange={onChange}
                        dateFormat="dd/MM/yyyy"
                        wrapperClassName={"datePicker"}
                        disabled={disabled}
                        onKeyDown={onKeyDown}
                    // popperProps={{
                    //     positionFixed: true // use this to make the popper position: fixed
                    // }}
                    /> :
                    <DatePicker
                        portalId="START_DATE_PORTAL"
                        placeholderText={placeHolder}
                        // className="form-control"
                        className={props.useMyClass ? "form-control-date-pick-edit-case" : "form-control"}
                        selected={selected}
                        onChange={onChange}
                        dateFormat="dd/MM/yyyy"
                        // wrapperClassName="datePicker"
                        wrapperClassName={"datePicker"}
                        disabled={disabled}
                        onKeyDown={onKeyDown}
                    // popperProps={{
                    //     positionFixed: true // use this to make the popper position: fixed
                    // }}
                    />
                }
                {requiredFeedback === "" ? <></> :
                    <Form.Control.Feedback type="invalid">
                        {requiredFeedback}
                    </Form.Control.Feedback>
                }
            </InputGroup>
        </>)
}
