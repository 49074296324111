import { DomainsActionType } from "../actionsTypes";
import { } from "../actionsTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any, action: any) => {
    const { payload, type } = action;
    switch (type) {
        // Async methods
        case DomainsActionType.GET_DOMAIN_SUCCESS: return { ...state, domain: { data: payload, loading: false, error: false, loaded: true } };
        case DomainsActionType.GET_DOMAIN_ERROR: return { ...state, domain: { data: state.domain.data, loading: false, error: payload, loaded: true } };
        case DomainsActionType.GET_DOMAIN_LOADING: return { ...state, domain: { data: state.domain.data, loading: true, error: false, loaded: false } };

        case DomainsActionType.GET_DOMAINS_SUCCESS: return { ...state, domains: { data: payload, loading: false, error: false, loaded: true, refresh: false } };
        case DomainsActionType.GET_DOMAINS_ERROR: return { ...state, domains: { data: state.domains.data, loading: false, error: payload, loaded: true, refresh: false } };
        case DomainsActionType.GET_DOMAINS_LOADING: return { ...state, domains: { data: state.domains.data, loading: true, error: false, loaded: false, refresh: false } };
        case DomainsActionType.GET_DOMAINS_REFRESH: return { ...state, domains: { ...state.domains, refresh: true } };
        // Async methods
        // Sync methods
        case DomainsActionType.SET_FILTERS_DOMAINS: return { ...state, filters_domains: payload };
        case DomainsActionType.SET_FILTERS_CASES: return { ...state, filters_cases: payload };
        case DomainsActionType.SET_FILTERS_USERS: return { ...state, filters_users: payload };

        // case DomainsActionType.SET_SELECTEDS_DOMAINS: return { ...state, selectedsDomains: payload };
        case DomainsActionType.SET_SELECTEDS_CASES: return { ...state, selectedsCases: payload };
        case DomainsActionType.SET_SELECTEDS_USERS: return { ...state, selectedsUsers: payload };
        // Sync methods

        default:
            return state;
    }
}