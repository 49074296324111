import React from 'react';
import { useContext, useState } from 'react';
import { LangContext } from "../../Contexts/Lang";
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import IgvBody from './IgvBody';
import { SampleIgvDataI, AlignmentT } from './types';
import SamplesInfoTable from './SamplesInfoTable';

// type vartype = "danger" | "warning" | "info" | "secondary"
type modalTabsType = "sample" | "igv"

interface ModalSampleDataTableI {
    name: string,
    badge: JSX.Element,
    info: JSX.Element | string,
}
interface ModalSampleDataI {
    change?: string,
    table: undefined | ModalSampleDataTableI[]
}
interface SampleInfoIgvDataI extends SampleIgvDataI {
    mood: modalTabsType,
    sampleData?: ModalSampleDataI,
}
interface ModalSamplesIgvI extends SampleInfoIgvDataI {
    show: boolean,
    handleClose: any,
}
function ModalSamplesIgv(props: ModalSamplesIgvI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const [mood, setmood] = useState<modalTabsType>(props.mood)

    const getSampleSelected = () => {
        if (props.igvData && props.igvData?.alignments && props.igvData?.alignments.length > 0) {
            let sampleSelected = props.igvData.alignments[0].sampleName;
            if (props.igvData?.sampleNameSelected)
                sampleSelected = props.igvData?.sampleNameSelected
            return { sampleSelected: sampleSelected, alignmentT: props.igvData.view ?? "variant" }
        }
        return undefined;
    }
    let sampleSelectedInit = getSampleSelected()
    const [sampleSelected, setsampleSelected] = useState<{ sampleSelected: string, alignmentT: AlignmentT } | undefined>(sampleSelectedInit)
    const handleSampleSelected = (sampleName: string, alignmentT: AlignmentT) => {
        setsampleSelected({ sampleSelected: sampleName, alignmentT: alignmentT })
        setmood("igv")
    }

    const chPosition = `${props.chromosome ?? " - "}:${props.position ?? " - "}`
    const change = (props?.sampleData?.change ?? "").toUpperCase().replaceAll(/[0-9]/g, '').replaceAll("+", "").replace("C.", "")
    const singleTabComponent: boolean = !props.sampleData || !props.igvData;

    const modalBody = (
        <Modal.Body style={{ padding: singleTabComponent ? 0 : "auto" }}>
            {props.igvData && (props?.igvData?.alignments?.length ?? 0) > 0 && props.sampleData ?
                <Tabs defaultActiveKey="sample"
                    id="uncontrolled-tab-example"
                    style={{ margin: "0 5px 2px" }}
                    activeKey={mood}
                    onSelect={(x: any) => {
                        setmood(x)
                    }}
                >
                    <Tab eventKey="sample"
                        style={{ margin: "-1px 5px" }}
                        title={
                            <span style={{ verticalAlign: "top", fontSize: "14px" }}>{translate("common.samplesDetail")}</span>
                        }>
                        <SamplesInfoTable
                            chPosition={chPosition}
                            gene={props.gen ?? ""}
                            change={change}
                            sampleData={props.sampleData?.table ?? []}
                            hasAlignment={true}
                            handleSampleSelected={handleSampleSelected}
                        />
                    </Tab>
                    <Tab eventKey="igv"
                        style={{ margin: "-1px 5px" }}
                        title={
                            <span style={{ verticalAlign: "top", fontSize: "14px" }}>{translate("common.alignment")}</span>
                        }>
                        <>
                            {mood === "igv" && <IgvBody caseId={props.caseId}
                                gen={props.gen ?? ""}
                                chromosome={props.chromosome}
                                position={props.position}
                                assembly={props.igvData.assembly}
                                library={props.igvData?.library}
                                viewT={sampleSelected?.alignmentT}
                                sampleNameSelected={sampleSelected?.sampleSelected}

                                mutation={props.igvData.alignments} />}
                        </>
                    </Tab>
                </Tabs> :
                props.igvData && (props.igvData?.alignments?.length ?? 0) > 0 ?
                    <IgvBody caseId={props.caseId}
                        gen={props.gen ?? ""}
                        chromosome={props.chromosome}
                        position={props.position}
                        assembly={props.igvData.assembly}
                        library={props.igvData?.library}
                        viewT={props.igvData.view}
                        sampleNameSelected={sampleSelected?.sampleSelected}

                        mutation={props.igvData.alignments}
                    />
                    :
                    <SamplesInfoTable
                        chPosition={chPosition}
                        gene={props.gen ?? ""}
                        change={change}
                        sampleData={props.sampleData?.table ?? []}
                        handleSampleSelected={handleSampleSelected}
                        hasAlignment={false}
                    />
            }

        </Modal.Body >

    )
    const modalHeader = (
        <Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: "#444B59" }}>
            <Modal.Title style={{ margin: "0px 10px", color: "#FFF", fontSize: "17px" }}>
                {translate(`casesQuery.sampleInformation`)}
            </Modal.Title>
        </Modal.Header>
    )
    const modalFooter = (
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.handleClose}>
                Close
            </Button>
        </Modal.Footer >
    )
    if (mood === "igv")
        return (
            <Modal id="ModalSamplesIgv1" dialogClassName="my-modal-85"
                style={{ maxWidth: "none", width: "100" }}
                show={props.show} onHide={props.handleClose}>
                {modalHeader}
                {modalBody}
                {modalFooter}
            </Modal>
        )
    return (
        <Modal id="ModalSamplesIgv2" dialogClassName="my-modal-85"
            style={{ maxWidth: "none", width: "100" }}
            show={props.show} onHide={props.handleClose} //dialogClassName="samples_modal"
        >
            {modalHeader}
            {modalBody}
            {modalFooter}
        </Modal>
    )
}


export { ModalSamplesIgv }
export type { modalTabsType, ModalSampleDataI, SampleInfoIgvDataI, ModalSampleDataTableI }