import React from 'react';
import { useContext } from "react";
import { Badge } from "react-bootstrap";
import { LangContext } from "src/Contexts/Lang";
import ThemeContext from "../../../Contexts/Theme/ThemeContext";
import { Tooltip } from "../Tooltip/Tooltip";
import { PredictorsConsT, PredictorsVeredictI, getPredictorsByType } from 'src/Components/Cases/Query/Detail/Predictors/Predictors';

type VeredictConservationT = "C" | "MC" | "NC"
interface BadgeVeredictConservationPredictorsI {
    type: PredictorsConsT,
    value: any
}
export default function BadgeVeredictConservationPredictors(props: BadgeVeredictConservationPredictorsI) {
    const { theme } = useContext(ThemeContext);
    const { dispatch: { translate } } = useContext(LangContext);
    if (isNaN(+props.value)) return <></>;
    if (props.type === undefined || props.value === undefined) return <></>
    const confT: { veredict: string | null, info: string } = getPredictorVeredict(translate, props.value, props.type);
    const color = confT.veredict === "NC" ? theme.success : confT.veredict === "C" ? theme.error : "#F28B00";
    const badge = (<Badge style={{ backgroundColor: color, color: "white", alignSelf: "center" }}>
        {confT.veredict}
    </Badge>)
    if ((confT.info ?? "").length > 2)
        return (
            <Tooltip label={confT.info}>
                {badge}
            </Tooltip>
        )
    return badge
}

function getPredictor(type: PredictorsConsT): PredictorsVeredictI | null {
    return predictorsConservationVereDict.get(type)?.veredict ?? null;
}

function getPredictorVeredict(translate: any, value: any, type: PredictorsConsT): { type: PredictorsConsT, veredict: VeredictConservationT | null, info: string } {
    const predictor: PredictorsVeredictI | null = getPredictor(type);
    if (!predictor || (value !== 0 && !value)) return ({ type, veredict: null, info: "" })
    const intValue = +value;
    let veredict: VeredictConservationT | null = "MC";
    if (predictor.pathogenic) {
        // eslint-disable-next-line no-eval
        if (eval(`${intValue} ${predictor.pathogenic.condition} ${predictor.pathogenic.value}`)) {
            veredict = "NC"
        }
    }
    if (predictor.benign) {
        // eslint-disable-next-line no-eval
        if (eval(`${intValue} ${predictor.benign.condition} ${predictor.benign.value}`)) veredict = "C"
    }
    const info = getPredictorFlag(translate, predictor.type as PredictorsConsT)
    return ({ type, veredict, info })
}

function getPredictorFlag(translate: any, predictorType: PredictorsConsT): string {
    let info = ""
    const predictor: PredictorsVeredictI | null = getPredictor(predictorType);
    if (predictor && !Array.isArray(predictor?.benign?.value)) {
        if (predictor.pathogenic) info += `${"not conserved"} ${predictor.pathogenic.condition} ${predictor.pathogenic.value}. `;
        if (predictor.benign) info += `${"conserved"} ${predictor.benign.condition} ${predictor.benign.value}. `;
    }
    return info;
}

const predictorsConservationVereDict = getPredictorsByType("CONS");

export { getPredictorVeredict, getPredictorFlag }
export type { VeredictConservationT }