import { PanelGenesActionType } from "../actionsTypes";
import { } from "../actionsTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any, action: any) => {
    const { payload, type } = action;
    switch (type) {
        // Async methods
        case PanelGenesActionType.GET_PANEL_SUCCESS: return { ...state, panel: { data: payload, loading: false, error: false, loaded: true } };
        case PanelGenesActionType.GET_PANEL_ERROR: return { ...state, panel: { data: state.panel.data, loading: false, error: payload, loaded: true } };
        case PanelGenesActionType.GET_PANEL_LOADING: return { ...state, panel: { data: state.panel.data, loading: true, error: false, loaded: false } };
        
        case PanelGenesActionType.GET_PANELS_SUCCESS: return { ...state, panels: { data: payload, loading: false, error: false, loaded: true } };
        case PanelGenesActionType.GET_PANELS_ERROR: return { ...state, panels: { data: state.panels.data, loading: false, error: payload, loaded: true } };
        case PanelGenesActionType.GET_PANELS_LOADING: return { ...state, panels: { data: state.panels.data, loading: true, error: false, loaded: false } };
        case PanelGenesActionType.GET_PANELS_REFRESH: return { ...state, panels: { ...state.panels, refresh: true } };
        
        // Sync methods
        case PanelGenesActionType.SET_FILTERS: return { ...state, filters: payload };
        case PanelGenesActionType.SET_SELECTEDS: return { ...state, selecteds: payload };
        
        default:
            return state;
    }
}