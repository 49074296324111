import React from 'react';
import { useState, useContext } from "react";
import { LangContext } from "../../../Contexts/Lang";
import { ModalWarning } from "../../Common/ModalWarning";
import DetailHeader from "../../Layout/Detail/DetailHeader";
import ProcessPanelGene from "../ProcessPanelGenes/ProcessPanelGene";
import GensetService from "../../../Services/GensetService";
import { PanelGenesI } from "../../../Contexts/interfaces";

interface DetailPanelGenesHaderI {
    history: any,
    actualGene: PanelGenesI,
    setReload(key?: string): void,
    showProcessGene: boolean,
    handleCloseProcessCase(): void,
    handleShowProcessGene(): void,
    addGenesView?: boolean,
}

export default function DetailPanelGenesHeader(props: DetailPanelGenesHaderI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const [showWarningDelete, setshowWarningDelete] = useState<boolean>(false)
    const handleCloseWarningDelete = () => setshowWarningDelete(false);

    const GenesetS = new GensetService();

    const handleDelete = () => {
        GenesetS.delete_panels([{ id: props.actualGene.key, regionKey: props.actualGene.regionKey }]);
        props.setReload()
        handleCloseWarningDelete()
    }

    return (
        <>
            {props.showProcessGene &&
                <ProcessPanelGene history={props.history}
                    show={props.showProcessGene}
                    handleClose={props.handleCloseProcessCase}
                    handleReload={(key: string) => {
                        return setTimeout(() => {
                            props.setReload(key)
                            // return props.history.push(`/genes/${props.actualGene.regionKey}/${key}`)
                        }, 500);
                    }}
                    editGenePanel={{
                        id: props.actualGene?.key,
                        region: props.actualGene?.regionKey
                    }}
                    addGenesView={props.addGenesView ?? false}
                />
            }
            <ModalWarning labelTitle={translate('common.confirm')}
                label={translate('genesPanel.confirmDeleteSelectedGenPanel')}
                labelButton={translate('home.delete')}
                showState={showWarningDelete}
                handleClose={handleCloseWarningDelete}
                onClick={handleDelete}
            />
            <DetailHeader history={props.history}
                titleTooltipLabel={"Go to CASES"}
                titleLabel={props.actualGene?.name}
                buttonsActions={{
                    edit: () => props.handleShowProcessGene(),
                    delete: () => setshowWarningDelete(true),
                }}
            />
        </>
    )
}