import { AbstractService } from "../Services/AbstractService";

export class ContextStateManager {
    state: any;
    initialState: any;
    actionsType: any;
    dispatch: React.Dispatch<any>;
    constructor(state: any, initialState: any, actionTypes: any, dispatch: React.Dispatch<any>) {
        this.state = state;
        this.initialState = initialState;
        this.actionsType = actionTypes;
        this.dispatch = dispatch;
    }

    handleAsyncError = (serviceName: string, serviceArgs: any, actionPrefix: string, error: any) => {
        console.log(`Error on ${serviceName}. Request args:`)
        console.log(serviceArgs);
        console.log(`Request Error:`)
        console.log(error);
        this.dispatch({ type: this.actionsType[`${actionPrefix}ERROR`], payload: error });
    }

    async GetAsyncData(actionPrefix: string, serviceArgs: any, serviceObj: AbstractService, serviceName: string, callback?: (succesResponse: any) => void) {
        // console.log("actionPrefix")
        // console.log(actionPrefix)
        // console.log("serviceArgs")
        // console.log(serviceArgs)
        try {
            this.dispatch({ type: this.actionsType[`${actionPrefix}LOADING`], payload: null });
            // eslint-disable-next-line no-eval
            let res = await eval(`serviceObj.${serviceName}(...Object.values(serviceArgs))`)
            // console.log("res",res)
            if (res.status === 200) {
                res = res.data
                if (callback) res = callback(res)
                this.dispatch({ type: this.actionsType[`${actionPrefix}SUCCESS`], payload: res });
            } else {
                this.handleAsyncError(serviceName, serviceArgs, actionPrefix, res.data)
            }
        } catch (error) {
            this.handleAsyncError(serviceName, serviceArgs, actionPrefix, error)
        }
    }

    SetFilters(filterName: string, actionName: string, key: string, value: string): boolean {
        if (Object.keys(this.state[filterName]).find((x: string) => x === key)) {
            const f: any = this.state[filterName]
            f[key] = value;
            this.dispatch({ type: this.actionsType[actionName], payload: f });
            return true
        } else {
            alert(`Value : ${key} : ${value} not found on filter fields`)
        }
        return false
    }
    CleanFilters(filterName: string, action: string) {
        if (JSON.stringify(this.initialState[filterName]) !== JSON.stringify(this.state[filterName])) {
            this.dispatch({ type: this.actionsType[action], payload: this.initialState[filterName] });
        }
    }

    HandleSelecteds(filterName: string, action: string, rawData: any[], id: string, fieldId: string = "id") {
        // console.log("rawData")
        // console.log(rawData)
        // console.log("id")
        // console.log(id)
        var selecteds = this.state[filterName]
        // console.log("selecteds")
        // console.log(selecteds)
        if (rawData.find((x: any) => `${x[fieldId]}` === `${id}`)) {
            if (selecteds.find((x: any) => `${x}` === `${id}`))
                this.dispatch({ type: this.actionsType[action], payload: selecteds.filter((x: any) => `${x}` !== `${id}`) });
            else this.dispatch({ type: this.actionsType[action], payload: [...selecteds, `${id}`] });
        } else this.dispatch({ type: this.actionsType[action], payload: [] });
    }
    HandleSelectAll(filterName: string, action: string, rawData: any[], fieldId: string = "id") {
        var selecteds = this.state[filterName]
        if (selecteds.length === (rawData ?? []).length) this.dispatch({ type: this.actionsType[action], payload: [] });
        else this.dispatch({ type: this.actionsType[action], payload: [...(rawData ?? []).map((x: any) => x[fieldId])] });
    }
}
