import React, { useContext } from 'react';
import { LangContext } from 'src/Contexts/Lang';
import { Badge } from 'react-bootstrap';
import { Tooltip } from '../Tooltip/Tooltip';
import WarningTrianglePopover from '../WarningTrianglePopover';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { getVariantFiltersLabels } from 'src/utils/BioFunctionalData';


interface BadgeFiltersI {
	_key: string,
	index: number
}
export function BadgeFilters(props: BadgeFiltersI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { theme } = useContext(ThemeContext);
	if ((!props.index && props.index !== 0)|| !props._key) return <></>
	const key = props._key
	const index = props.index
	const variantFiltersLabels: { [name: string]: string } = getVariantFiltersLabels(translate);
	if (key.indexOf("pass") > -1) {
		return <span key={index} style={{ fontWeight: "bold", color: "darkgreen" }}>
			&nbsp;{variantFiltersLabels[key]}
		</span>
	} else if (
		key.indexOf("lowqual") > -1 ||
		key.indexOf("fail") > -1) {
		const isLowQual = key.indexOf("lowqual") > -1;
		return (
			<span key={index}>
				<Tooltip label={translate(`casesQuery.filtersDescriptions.${key}`)}>
					<div style={{ color: "darkred", fontWeight: "bold", opacity: 0.9, }}>
						&nbsp;{variantFiltersLabels[key]}
						<WarningTrianglePopover
							type={isLowQual ? 'warning' : 'danger'}
							bodyContent={isLowQual ? translate("casesQuery.lowQuality") : translate("casesQuery.filtersDescriptions.fail")}
							placement={"bottom"} />
					</div>
				</Tooltip>
			</span>
		)
	} else if (
		key.indexOf("off_target") > -1 ||
		key.indexOf("germline") > -1 ||
		key.indexOf("panel_of_normals") > -1) {
		return (
			<span key={index}>
				<Tooltip label={translate(`casesQuery.filtersDescriptions.${key}`)}>
					<Badge variant="light"
						style={{
							fontWeight: 100,
							color: theme.grayHeader,
							fontSize: 10,
							backgroundColor: "#EAEAEA",
							verticalAlign: "middle"
						}}>
						{variantFiltersLabels[key].replace("_", " ")}
					</Badge>
				</Tooltip>
			</span>
		)
	} else {
		return (
			<span key={index}>
				<Tooltip label={translate(`casesQuery.filtersDescriptions.${key}`)}>
					<span key={index}
						style={{
							fontWeight: 100,
							color: theme.grayHeader,
							fontSize: 10,
							verticalAlign: "middle"
						}}>
						{variantFiltersLabels[key].replace("_", " ")}
					</span>
				</Tooltip>
			</span>
		)
	}
}