import React from 'react';
import { StudyI } from "../../../../Contexts/interfaces";
import CoverageGenTable from "./GenTable/GeneTable";
import { Card, Table } from "react-bootstrap";
import { useRef } from "react";
import GeneTableHeaders from "./GenTable/GeneTableHeaders";
import CoverageProteinBullet from 'src/Components/Common/Nivo/CoverageProtein/CoverageProteinBullet';

interface CoverageByGenesAccordionBodyI {
    study: StudyI,
    studyId: string,
    studyName: string,  //x
    isActive: boolean, // activeKey === `${ii}`
    data: {             //xx
        regions: any[],
        genename: string
    },
    settableMounted: any
}
export default function CoverageByGenesAccordionBody(props: CoverageByGenesAccordionBodyI) {
    const TableBodyRef: React.MutableRefObject<any> = useRef();
    const TableHeadersRef: React.MutableRefObject<any> = useRef();
    const handleScrollTableBody = (scroll: any): void => {
        const thScrollLeft = TableHeadersRef?.current?.scrollLeft;
        if (thScrollLeft || thScrollLeft === 0) TableHeadersRef.current.scrollLeft = scroll.target.scrollLeft;
    };
    const handleScrollHeadersTable = (scroll: any): void => {
        const tbScrollLeft = TableBodyRef?.current?.scrollLeft;
        if (tbScrollLeft || tbScrollLeft === 0) TableBodyRef.current.scrollLeft = scroll.target.scrollLeft;
    };
    const regionsSorted = props.data.regions.sort((a, b) => {
        const startA = parseInt(a.start);
        const startB = parseInt(b.start);

        return startA - startB;
    })
    return (
        <Card.Body style={{ padding: "0px" }}>
            {props.isActive &&
                <>
                    {props.data.regions.length > 1 &&
                        <div style={{ padding: 5, display: "grid" }}>
                            <CoverageProteinBullet geneName={props.data.genename}
                                dataCoverage={props.data.regions}
                                height={170}
                            />
                        </div>
                    }
                    <div ref={TableHeadersRef}
                        onScrollCapture={(e: any) => { if (handleScrollHeadersTable) handleScrollHeadersTable(e); }}
                        style={{ maxHeight: "500px", overflowX: "auto" }}>
                        <Table responsive="sm" style={{ marginBottom: 0 }}>
                            <GeneTableHeaders study={props.study}
                                geneName={props.data.genename}
                                studyName={props.studyName}
                            />
                        </Table>
                    </div>
                    <div ref={TableBodyRef}
                        onScrollCapture={(e: any) => { if (handleScrollTableBody) handleScrollTableBody(e); }}
                        style={{ maxHeight: "500px", overflowX: "auto" }}>
                        <CoverageGenTable regions={regionsSorted}
                            geneName={props.data.genename}
                            study={props.study}
                            studyName={props.studyName}
                            cardStyles={{ boxShadow: "none", margin: 0 }}
                            studyId={`${props.studyId}`}
                            settableMounted={props.settableMounted}
                        />
                    </div>
                </>
            }
        </Card.Body>
    )
}