import React from 'react';
import LinkTooltip from "./LinkTooltip";
import { LangContext } from "../../../Contexts/Lang";
import { useContext } from "react";
import { aminoacidsDict } from '../../../Helpers/Helpers';

function GooglesQuerysLinkComposer(geneName: string, codonChange: string | undefined, chPosition: string | undefined, aaChange: string | undefined, rs: string | undefined = undefined) {
    codonChange = (codonChange ?? "").replaceAll("c.", "")
    let firstLetter: any = codonChange.match(/[a-zA-Z]/g);
    let pos_change: any = undefined
    if (firstLetter?.length > 0) {
        pos_change = codonChange.substring(0, codonChange.indexOf(firstLetter[0])) + " " + codonChange.substring(codonChange.indexOf(firstLetter[0]))
    }
    aaChange = (aaChange ?? "").trim().toLowerCase().replace("p.(", "").replace(")", "")
    let AAChange: string | undefined = aaChange.toLowerCase()
    if (aaChange.length > 3) {
        const replaceAmino = (): boolean => {
            let result = false;
            Object.keys(aminoacidsDict).forEach((x: any) => {
                if ((AAChange ?? "").indexOf(x) > -1) {
                    AAChange = (AAChange ?? "").replace(x, aminoacidsDict[x])
                    result = true;
                }
                result = result ? true : false
            })
            return result
        }
        let ra = replaceAmino()
        while (ra) {
            ra = replaceAmino()
        }
    } else AAChange = undefined
    codonChange = (codonChange || codonChange !== "") ? `"${codonChange}"` : undefined;
    aaChange = aaChange ? ` | "${aaChange}"` : undefined;
    pos_change = pos_change ? ` | "${pos_change}"` : undefined;
    AAChange = AAChange ? ` | "${AAChange}"` : undefined;
    chPosition = chPosition ? ` | "${chPosition}"` : undefined;
    rs = rs && rs.trim() !== "" ? ` | "${rs}"` : undefined;
    let geneNameOrRs = `"${geneName ?? ""}"${rs ?? ""}`
    let hasGeneNameOrRs = geneNameOrRs.trim().length > 3;
    geneNameOrRs = hasGeneNameOrRs ? geneNameOrRs : "";
    let query: string = `${geneNameOrRs} ${hasGeneNameOrRs?"(":""}${codonChange ?? ""}${pos_change ?? ""}${aaChange ?? ""}${AAChange ?? ""}${chPosition ?? ""}${rs ?? ""}${hasGeneNameOrRs?")":""}`
    return encodeURIComponent(query);
}
interface LinkGoogleI {
    geneName: string,
    codonChange: string,
    chPosition?: string,
    aaChange?: string,
    rs?: string
}
function LinkGoogle(props: LinkGoogleI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const query = GooglesQuerysLinkComposer(props.geneName, props.codonChange, props.chPosition, props.aaChange, props.rs)
    let url = `https://www.google.com/search?q=${query}&oq${query}`
    return (
        <span key={`Google_${(Math.random() + 1).toString(36)}`} style={{ marginLeft: "5px" }}>
            <LinkTooltip tooltipLabel={props.rs ? translate("casesQuery.googleLink") : translate("casesQuery.googleLinkNotRs")}
                href={url}
                label={`${props.rs ?? props.geneName ?? "Google"}`} />
        </span>
    );
}
function LinkGoogleScholar(props: LinkGoogleI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const query = GooglesQuerysLinkComposer(props.geneName, props.codonChange, props.chPosition, props.aaChange, props.rs)
    let url = `https://scholar.google.com/scholar?as_q=${query}&as_oq${query}`
    return (
        <span key={`GoogleSchoolar_${(Math.random() + 1).toString(36)}`} style={{ marginLeft: "5px" }} >
            <LinkTooltip tooltipLabel={props.rs ? translate("casesQuery.googleLink") : translate("casesQuery.googleLinkNotRs")}
                href={url}
                label={`${props.rs ?? props.geneName ?? "Google schoolar"}`} />
        </span>
    )
}

export {
    LinkGoogle,
    LinkGoogleScholar
}