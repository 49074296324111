import React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { LangContext } from "../../../Contexts/Lang";
import { Formik } from 'formik';
import * as yup from 'yup';
import { formInputType } from "../Interfaces";
import FormInput from "../Form/FormInput";
import GroupServices from "../../../Services/GroupService";
import VariantServices from "../../../Services/VariantServices";
import { commentStatesColors, variantRelevanceValues } from '../../../utils/BioFunctionalData';
import Paginator from '../Paginator/Paginator';
import ReactSelect from 'src/Components/Common/Form/ReactSelect';
import VariantIdentificationTable, { VariantIdentificationTableRowI } from './VariantIdentificationTableRow';
export type ModalCommentGroupT = "comment" | "create_group" | "add_to_group"
interface ModalCommentGroupModal {
    show: boolean,
    handleClose: any,
    type: ModalCommentGroupT,
    data: any,
    editData?: any,
    studyId: string,
    handleReload: any
}
function ModalCommentGroup(props: ModalCommentGroupModal) {
    const { dispatch: { translate } } = useContext(LangContext);
    const [initialForm, setInitialForm] = useState<any>()
    const [variants, setvariants] = useState<any[]>([])
    const [errorVariants, seterrorVariants] = useState<boolean>(false)
    let GroupS = new GroupServices();
    let VariantS = new VariantServices();
    let formRef: any = useRef();
    const paginator = Paginator({ reloadCall: () => { }, pageSizeOptions: [500] })

    let relevanceOptions: any = variantRelevanceValues(translate)
    const relevanceColor = (x: string) => (commentStatesColors && Object.keys(commentStatesColors).indexOf(x) > -1) ? commentStatesColors[x] : "default";
    relevanceOptions = relevanceOptions.map((x: any, i: number) => {
        return {
            label: (<span style={{ color: commentStatesColors && relevanceColor(x.value), fontWeight: "bold", fontSize: "14px" }}>
                {x.label}
            </span>),
            value: x.value
        }
    })
    let optionsAddToGroup: any = [];
    if (props.type === "add_to_group")
        optionsAddToGroup = (props.data?.groups ?? []).map((x: any) => {
            let label = `${x.name} : ${x.description.length > 27 ? (x.description.substring(0, 27) + "...") : x.description}`
            return {
                label: label,
                value: x.id
            }
        })


    const getTitle = () => {
        switch (props.type) {
            case ("comment"): return translate("casesQuery.commentVariants")
            case ("create_group"): return translate("casesQuery.createGroup")
            case ("add_to_group"): return translate("casesQuery.addToGroup")
        }
    }
    useEffect(() => {
        paginator.pageSize_set((props?.data?.variants ?? []).length)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator.setActualPage, variants])

    useEffect(() => {
        seterrorVariants(false)
        if (props.editData && formRef?.current) {
            let newData: any = {}
            switch (props.type) {
                case "comment": {
                    const getState = () => {
                        if (!props.editData?.state) return relevanceOptions[1];
                        return relevanceOptions.find((x: any) => x.value === props.editData?.state?.toUpperCase())
                    }
                    newData["relevanceIndicator"] = getState();
                    newData["comment"] = props.editData?.comment ?? "";
                    break;
                }
                case "create_group": {
                    newData["name"] = props.editData.name ?? "";
                    newData["description"] = props.editData.description ?? "";
                    break;
                }
                case "add_to_group": {
                    newData["group"] = optionsAddToGroup.length > 0 ? optionsAddToGroup[0] : "";
                    break;
                }
            }
            setInitialForm(newData)
            formRef?.current?.setValues(newData)
        } else {
            setInitialForm(initialize())
            formRef?.current?.setValues(initialize())
        }
        if (props?.data?.variants)
            setvariants(
                (props.data?.variants ?? [])
                    .map((x: any) => { return { ...x, checked: true } })
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])
    function initialize() {
        switch (props.type) {
            case "comment": {
                return { relevanceIndicator: relevanceOptions[1], comment: "" }
            }
            case "create_group": {
                return { name: "", description: "" };
            }
            case "add_to_group": {
                return { group: optionsAddToGroup.length > 0 ? optionsAddToGroup[0] : "" }
            }
        }
    }
    function createSchema() {
        switch (props.type) {
            case "comment": {
                const relevanceIndicator = yup.object().required(translate(`error.required`));
                const comment = yup.string();
                return yup.object().shape({
                    relevanceIndicator,
                    comment
                })
            }
            case "create_group": {
                const name = yup.string()
                    .min(3, translate(`error.toShort`))
                    .max(50, translate(`error.toLong`))
                    .required(translate(`error.required`));
                const description = yup.string();
                const variants = yup.array();
                return yup.object().shape({
                    name,
                    description,
                    variants
                })
            }
            case "add_to_group": {
                const group = yup.object().required(translate(`error.required`));
                return yup.object().shape({ group })
            }
        }
    }
    function createForm(values: any, handleChange: any, errors: any) {
        function commonFormInput(type: formInputType, key: string, name: string, label: string, placeholder?: string, required: boolean = false) {
            return <FormInput type={type}
                labelBold={true}
                labelColor={"#444B59"}
                label={label}
                key={key}
                formGrouplStyle={{ "marginBottom": "15px" }}       // onKeyDown={(e: any) => handleEnter(e)}
                placeHolder={placeholder}
                name={name}
                required={required}
                requiredFeedback={errors[name]}
                feedbackValidator={!!errors[name]}
                value={values[name]}
                onChange={handleChange}
                textAreaRows={4}
            />
        }
        function commonFormSelect(key: string, name: string, label: string, options?: any[], required: boolean = false) {
            return <Form.Group key={key} style={{ marginBottom: "10px" }} >
                <Form.Label style={{ borderRadius: "0", color: "#444B59" }}>
                    {required && <span style={{ color: "#DD3D4D", float: "right" }}> *</span>}
                    <strong>{label}</strong>
                </Form.Label>
                <Form.Control as="select" aria-label="Default select example" style={{ borderRadius: 0, fontSize: "12px" }}
                    name={name}
                    value={values[name]}
                    // disabled={(props.editData && name === "studyType") ? true : false}
                    onChange={(e: any) => { return handleChange(e) }}
                    isInvalid={!!errors[name]}>
                    {options}
                </Form.Control>
                <Form.Control.Feedback type="invalid"> {errors[name]} </Form.Control.Feedback>
            </Form.Group>
        }
        const table = (
            <VariantIdentificationTable variants={variants}
                setvariants={setvariants} />
        )
        if (props.type === "comment") {
            const getRelevanceIndicatorI = () => {
                const handleClick = (stateValue: string) => {
                    var newState = formRef?.current?.values;
                    newState.relevanceIndicator = stateValue
                    formRef?.current?.setValues(newState)
                }
                return (
                    <Row style={{ margin: 0, padding: 2 }}>
                        <Col sm={6} style={{ padding: 0, alignSelf: "center" }}>
                            <label style={{ margin: 0, color: "#444B59", fontWeight: "bold" }}>
                                {translate(`casesQuery.relevanceFlag`)}:
                            </label>
                        </Col>
                        <Col sm={6} style={{ padding: 0 }}>
                            <ReactSelect
                                options={relevanceOptions}
                                onChange={(e: any) => {
                                    handleClick(e)
                                }}
                                value={formRef?.current?.values?.relevanceIndicator}
                                placeholder={"Order by"}
                                divStyles={{ width: "inherit", margin: "3px" }}
                            />
                        </Col>
                    </Row>
                )
            }
            const relevanceIndicatorI = getRelevanceIndicatorI()
            const comment = commonFormInput("textarea", "codeKey", "comment", translate(`common.commentArio`), `${translate(`common.commentArio`)}...`);
            return (
                <>
                    {relevanceIndicatorI}
                    <hr style={{ margin: "10px -15px" }} />
                    {props?.data?.variants &&
                        Array.isArray(props.data.variants) &&
                        props.data.variants.length > 1 &&
                        <p style={{ fontSize: "13px", color: "gray", marginBottom: "10px" }}>
                            &nbsp;{translate("casesQuery.commentVariantsSelected")}
                            {" "}
                            ({(variants ?? []).filter((x: VariantIdentificationTableRowI) => x.checked).length})
                        </p>
                    }
                    {table}
                    <hr style={{ margin: "15px -15px" }} />
                    {comment}
                </>
            )
        } else {
            if (props.type === "create_group") {
                const nameI = commonFormInput("text", "nameKey", "name", translate(`common.name`), "", true);
                const descriptionI = commonFormInput("textarea", "descriptionKey", "description", translate(`common.description`), "");
                return (
                    <>
                        <p style={{ fontSize: "13px", color: "gray", marginBottom: "10px" }}>
                            {translate("casesQuery.theSelectedVariantsWillBeStoredInTheGroup")}
                        </p>
                        {nameI}
                        {descriptionI}
                        <p style={{ color: "#444B59", fontSize: "15px", marginBottom: "8px" }}>
                            <strong>Variantes ({(variants ?? []).length})</strong>
                            {errorVariants && <span style={{ color: "#DC3545", fontSize: "12px", marginLeft: "10px" }}>
                                *{translate("casesQuery.errors.atLeastOneVariantsSelected")}
                            </span>}
                        </p>
                        {table}
                    </>
                )
            } else {
                let getSelectOptions = (array: any[], addCeroValue: boolean = false) => {
                    if (addCeroValue)
                        array = [{ value: undefined, label: "..." }, ...array]
                    return array?.map((x: any, i: number) =>
                        <option key={i} value={x.value}>{x.label}</option>
                    )
                }
                const nameSelect = commonFormSelect("nameKey", "name", translate(`common.groups`), getSelectOptions(optionsAddToGroup), true)
                return (
                    <>
                        <p style={{ fontSize: "13px", color: "gray", marginBottom: "10px" }}>
                            {translate("casesQuery.theSelectedVariantsWillBeStoredInTheNewGroup")}
                        </p>
                        {nameSelect}
                        <p style={{ color: "#444B59", fontSize: "15px", marginBottom: "8px" }}>
                            <strong>Variantes ({(variants ?? []).length})</strong>
                            {errorVariants && <span style={{ color: "#DC3545", fontSize: "12px", marginLeft: "10px" }}>
                                *{translate("casesQuery.errors.atLeastOneVariantsSelected")}
                            </span>}
                        </p>
                        {table}
                    </>
                )
            }
        }
    }

    function handleSubmit(a: any, b: any) {
        if (props.type === "create_group" || props.type === "add_to_group") {
            const variantsSelecteds = variants.filter((x: VariantIdentificationTableRowI) => x.checked)
            if (variantsSelecteds.length === 0) {
                seterrorVariants(true);
                return
            } else {
                if (errorVariants) seterrorVariants(false);
                if (props.type === "create_group") {
                    let desc = a.description;
                    if (desc.length > 1200) desc = desc.substring(0, 1200);
                    // GroupS.add_to_group()
                    GroupS.create(
                        props.studyId,
                        desc,
                        a.name,
                        variantsSelecteds.length,
                        variantsSelecteds.map((x: any) => x.id))
                        .then((res: any) => {
                            if (res.status === 200) {
                                props.handleClose();
                                props.handleReload();
                            }
                        }).catch((e: any) => {
                            console.log("e")
                            console.log(e)
                        })
                } else {
                    GroupS.add_to_group(
                        a.group.value.toString(),
                        variantsSelecteds.map((x: any) => x.id))

                        .then((res: any) => {
                            // console.log("res");
                            // console.log(res);
                            if (res.status === 200) {
                                props.handleClose();
                                props.handleReload();
                            }
                        }).catch((e: any) => {
                            console.log("e")
                            console.log(e)
                        })
                }
            }
        } else {
            const variantsToComment = variants
                .filter((x: VariantIdentificationTableRowI) => x.checked)
                .map((x: VariantIdentificationTableRowI) => x.id)
            if (props.type === "comment" && variantsToComment.length > 0) {
                VariantS.comment_variants(
                    props.studyId,
                    variantsToComment.join(),
                    a.comment,
                    a.relevanceIndicator.value
                ).then((res: any) => {
                    if (res.status === 200) {
                        props.handleReload();
                        props.handleClose();
                    }
                }).catch((e: any) => {
                    console.log("e")
                    console.log(e)
                })
            }
        }
    }
    return (
        <Modal id="ModalCommentGroup"
            dialogClassName="my-modal-50"
            show={props.show}
            onHide={props.handleClose}>
            <Formik initialValues={initialForm}
                validationSchema={createSchema()}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                // handleSubmit={handleSubmit}
                innerRef={formRef}
                validateOnChange={false}
                validateOnBlur={true}>
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: "#444B59" }}>
                            <Modal.Title style={{ margin: "0px 10px", color: "#FFF" }}>
                                {getTitle()}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{}}>
                            {createForm(values, handleChange, errors)}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" onClick={props.handleClose}>
                                {translate(`common.close`)}
                            </Button>
                            <Button variant="outline-primary" type="submit" disabled={variants.filter((x: VariantIdentificationTableRowI) => x.checked).length === 0}>
                                {translate(`common.save`)}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

const getConcatedComments = (comments: any, commentIds: any[]) => {
    if (commentIds.length > 1) {
        let newComments = commentIds.map((x: any) => {
            if (typeof comments === 'object' && comments[x])
                return comments[x].comment
            else {
                const newId = comments.find((y: any) => y.id === x)
                if (newId) return newId.comment
            } return false
        }).filter((x: any) => x !== false && x.trim().length !== 0)
        let commentsConcatedLowered = newComments.map((x: any, i: number) => {
            return [x.toLowerCase().replace(/ /g, '').replace(/"\n"/g, '').replace(/(\r\n|\n|\r)/gm, ""), i]
        });
        let setComments = new Set(commentsConcatedLowered.map((x: any) => x[0]));
        let filteredIndex = Array.from(setComments.values())
            .map((x: any) => (commentsConcatedLowered.find((y: any) => y[0] === x) ?? ["", ""])[1])
        if (filteredIndex.length > 1)
            return newComments.filter((x: any, i: number) => filteredIndex.indexOf(i) > -1).map((x: any) => { return `· ${x}` }).join("\n")
        return newComments.filter((x: any, i: number) => filteredIndex.indexOf(i) > -1).join("\n")
    }
    else {
        if (typeof comments === 'object' && comments[commentIds[0]])
            return comments[commentIds[0]].comment
        else {
            const newId = comments.find((y: any) => y.id === commentIds[0])
            if (newId) return newId.comment
        }
        return ""
    }
}

const getStateComments = (comments: any[], commentIds: any[]) => {
    let states = commentIds.map((x: any) => {
        if (typeof comments === 'object' && comments[x])
            return comments[x].state
        else {
            const newId = comments.find((y: any) => y.id === x)
            if (newId) return newId.state
        } return false
    }).filter((x: any) => x !== false)
    function getMode(array: any[]) {
        var frequency: any = []; // array of frequency.
        var maxFreq = 0; // holds the max frequency.
        var modes: any = [];
        for (var i in array) {
            frequency[array[i]] = (frequency[array[i]] || 0) + 1; // increment frequency.
            if (frequency[array[i]] > maxFreq)  // is this frequency > max so far ?
                maxFreq = frequency[array[i]]; // update max.
        }
        for (var k in frequency) if (frequency[k] === maxFreq) modes.push(k);
        return modes[0];
    }
    const mode = getMode(states)
    return mode
}

export { ModalCommentGroup, getConcatedComments, getStateComments }