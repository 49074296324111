// type IgvTrackT = "genes" | "clinvarVariants" | "library" | "variant";
type IgvFileExtensionsT = "narrowpeak" | "broadpeak" | "regionpeak" | "peaks" | "bedgraph" | "wig" | "gff3" | "gff" | "gtf" | "fusionjuncspan" | "refflat" | "seg" | "aed" | "bed" | "vcf" | "bb" | "bigbed" | "bw" | "bigwig" | "bam" | "tdf" | "refgene" | "genepred" | "genepredext" | "bedpe" | "bp" | "snp" | "rmsk" | "cram" | "gwas" | "maf" | "mut";
type TrackTypeT = "variant" | "annotation" | "genes" | "fusionjuncspan" | "snp" | "seg" | "maf" | "mut" | "junctions" | "splicejunctions";
type DisplayModeT = "EXPANDED" | "SQUISHED" | "COLLAPSED";
interface IgvTrackI {
    name: string,
    url: string, //requeired ª!!!!
    indexURL: string, //required!
    type?: TrackTypeT,//TODO: Completar!!!
    format?: IgvFileExtensionsT,
    sourceType?: string,
    displayMode?: DisplayModeT,
    visibilityWindow?: number,
    height?: number,
    color?: string,
    colorBy?: "strand" | "firstInPairStrand" | "tag",
    squishedCallHeight?: number,
    expandedCallHeight?: number,
    order?: number,
    autoscaleGroup?: string
}

//------------------------ SETTING TRACKs--------------------------------------------
function getIgvTrackSettings_Gene(url: string, indexURL: string, format: IgvFileExtensionsT) {
    let track: IgvTrackI = {
        url: url,
        indexURL: indexURL,
        format: format,
        name: "Genes",
        type: "annotation",
        sourceType: "file",
        visibilityWindow: 300000000,
        displayMode: "EXPANDED",
        order: Number.MAX_VALUE,
        height: 200,
        autoscaleGroup: '1'
    }
    return track;
}
function getIgvTrackSettings_Clinvar(url: string, indexURL: string) {
    let track: IgvTrackI = {
        url: url,
        indexURL: indexURL,
        name: "Clinvar",
        type: "variant",
        format: "vcf",
        sourceType: "file",
        visibilityWindow: 300000000,
        height: 60,
        squishedCallHeight: 1,
        expandedCallHeight: 2,
        displayMode: "EXPANDED",
        color: "#ff2101"
    }
    return track;
}
function getIgvTrackSettings_Library(url: string, indexURL: string) {
    let track: IgvTrackI = {
        url: url,
        indexURL: indexURL,
        name: "Library",
        type: "annotation",
        format: "bed",
        visibilityWindow: 300000000,
        squishedCallHeight: 1,
        expandedCallHeight: 2,
        height: 30,
        displayMode: "SQUISHED",
        color: "#FFB111"
    }
    return track;
}
function getIgvTrackSettings_SampleNGS(url: string, indexURL: string, name: string) {
    let track: IgvTrackI = {
        url: url,
        indexURL: indexURL,
        name: name,
        format: "bam",
        colorBy: "strand",
        sourceType: "file",
        visibilityWindow: 300000000,
    }
    return track;
}
// CLINGEN TRACKS
function getCommonClingenFields(): IgvTrackI {
    return {
        url: "",                     // fields to override
        indexURL: "",                // fields to override
        name: "",                    // fields to override
        type: "annotation",
        format: "bed",
        sourceType: "file",
        visibilityWindow: 300000000,
        height: 60,
        squishedCallHeight: 1,
        expandedCallHeight: 2,
        displayMode: "EXPANDED",
    }
}
function getIgvTrackSettings_Clingen_CuratedRegionList(url: string, indexURL: string) {
    let track: IgvTrackI = {
        ...getCommonClingenFields(),
        url: url,
        indexURL: indexURL,
        name: "Clingen - curated region list",
        color: "#F77356",
    }
    return track;
}
function getIgvTrackSettings_Clingen_HaploinsufficiencyGenes(url: string, indexURL: string) {
    let track: IgvTrackI = {
        ...getCommonClingenFields(),
        url: url,
        indexURL: indexURL,
        name: "Clingen - haploinsufficiency genes",
        color: "#9455AE",
    }
    return track;
}
function getIgvTrackSettings_Clingen_TriplosensitivityGenes(url: string, indexURL: string) {
    let track: IgvTrackI = {
        ...getCommonClingenFields(),
        url: url,
        indexURL: indexURL,
        name: "Clingen - triplosensitivity genes",
        color: "#F86F6F",
    }
    return track;
}
function getIgvTrackSettings_Clingen_RecurrentCNV(url: string, indexURL: string) {
    let track: IgvTrackI = {
        ...getCommonClingenFields(),
        url: url,
        indexURL: indexURL,
        name: "Clingen - recurrent CNV",
        color: "#4FA8FB",
    }
    return track;
}
//------------------------ SETTING TRACKs--------------------------------------------

function getTrackGene(bamData: any) {
    return getIgvTrackSettings_Gene(bamData.tracks_url, bamData.tracks_indexURL, bamData.genes_format);
}
function getTrackClinvar(bamData: any, prefixUrl: any) {
    if (bamData.clinvarVcfUrl) {
        const trackUrl = `${prefixUrl}${bamData.clinvarVcfUrl[`url`]}`,
            trackIndexUrl = `${prefixUrl}${bamData.clinvarVcfUrl[`indexURL`]}`;
        // console.log("getTrackClinvar")
        // console.log(trackUrl)
        return getIgvTrackSettings_Clinvar(trackUrl, trackIndexUrl);
    }
    return undefined
}
function getTrackLibrary(bamData: any, prefixUrl: any, library: any) {
    if (library && bamData.libraryUrl) {
        const trackUrl = `${prefixUrl}${bamData.libraryUrl[`url`]}`,
            trackIndexUrl = `${prefixUrl}${bamData.libraryUrl[`url`]}.idx`
        // console.log("getTrackLibrary")
        // console.log(trackUrl)
        return getIgvTrackSettings_Library(trackUrl, trackIndexUrl)
    }
    return undefined;
}
function getTrackClingen_CuratedRegionList(bamData: any, prefixUrl: any) {
    if (bamData.cnvBedUrl) {
        const trackUrl = `${prefixUrl}${bamData.cnvBedUrl[`dsh.url`]}`,
            trackIndexUrl = `${prefixUrl}${bamData.cnvBedUrl[`dsh.indexURL`]}`;
        // console.log("getTrackClingen_CuratedRegionList")
        // console.log(trackUrl)
        return getIgvTrackSettings_Clingen_CuratedRegionList(trackUrl, trackIndexUrl);
    }
    return undefined
}
function getTrackClingen_HaploinsufficiencyGenes(bamData: any, prefixUrl: any) {
    if (bamData.cnvBedUrl) {
        const trackUrl = `${prefixUrl}${bamData.cnvBedUrl[`dsc.url`]}`,
            trackIndexUrl = `${prefixUrl}${bamData.cnvBedUrl[`dsc.indexURL`]}`;
        return getIgvTrackSettings_Clingen_HaploinsufficiencyGenes(trackUrl, trackIndexUrl);
    }
    return undefined
}
function getTrackClingen_TriplosensitivityGenes(bamData: any, prefixUrl: any) {
    if (bamData.cnvBedUrl) {
        const trackUrl = `${prefixUrl}${bamData.cnvBedUrl[`dst.url`]}`,
            trackIndexUrl = `${prefixUrl}${bamData.cnvBedUrl[`dst.indexURL`]}`;
        return getIgvTrackSettings_Clingen_TriplosensitivityGenes(trackUrl, trackIndexUrl);
    }
    return undefined
}
function getTrackClingen_RecurrentCNV(bamData: any, prefixUrl: any) {
    if (bamData.cnvBedUrl) {
        const trackUrl = `${prefixUrl}${bamData.cnvBedUrl[`dsr.url`]}`,
            trackIndexUrl = `${prefixUrl}${bamData.cnvBedUrl[`dsr.indexURL`]}`;
        return getIgvTrackSettings_Clingen_RecurrentCNV(trackUrl, trackIndexUrl);
    }
    return undefined
}


export {
    getIgvTrackSettings_SampleNGS,
    getTrackGene,
    getTrackLibrary,
    getTrackClinvar,
    getTrackClingen_CuratedRegionList,
    getTrackClingen_HaploinsufficiencyGenes,
    getTrackClingen_TriplosensitivityGenes,
    getTrackClingen_RecurrentCNV,
}
export type { IgvTrackI }
