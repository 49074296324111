import React from 'react';
import { faExternalLinkAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Badge } from "react-bootstrap";
import { LangContext } from "../../../Contexts/Lang";
import { Tooltip } from "../Tooltip/Tooltip";

interface BadgeManeSelectI {
    fontSize?: number,
}
export default function BadgeManeSelect(props: BadgeManeSelectI) {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <Tooltip label={<>
            {`${translate("common.goTo")} MANE Select`}
            <FontAwesomeIcon icon={faInfoCircle}
                style={{
                    marginLeft: "5px",
                    color: "lightgray",
                    verticalAlign: "top",
                    fontSize: "14px"
                }} />
        </>}>
            <a href="https://www.ncbi.nlm.nih.gov/refseq/MANE/#What" target="_blank" rel="noreferrer">
                <Badge variant="info"
                    style={{
                        fontWeight: "inherit",
                        fontSize: props.fontSize ? `${props.fontSize}px` : "13px",
                        backgroundColor: "#3CC9FF",
                        margin: "0 5px"
                    }}>
                    <strong>MANE</strong>
                    <FontAwesomeIcon icon={faExternalLinkAlt}
                        style={{
                            fontSize: props.fontSize ? `${props.fontSize - 3}px` : "10px",
                            margin: "1px 0px 0px 5px",
                            verticalAlign: "top"
                        }} />
                </Badge>
            </a>
        </Tooltip>
    )
}