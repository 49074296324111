import React from 'react';
import { useContext } from "react";
import { Col, Spinner, Row, Card } from 'react-bootstrap';
import { faCheckCircle, faFolder, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { LangContext } from "../../../Contexts/Lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BadgeSample from "../../Common/Badges/BadgeSample";
import BadgeTypeSubtype from '../../Common/Badges/BadgeSampleTypeSubtype';
import CopyToClipboard from "../../Common/CopyToClipboard";
import ThemeContext from "../../../Contexts/Theme/ThemeContext";
import { Tooltip } from 'src/Components/Common/Tooltip/Tooltip';
import CircleLabel from 'src/Components/Common/CircleLabel';
import { AsyncDataI } from '../../../Contexts/interfaces';

interface DetailCaseDataI {
	actualCase: AsyncDataI
}
export default function DetailCaseData(props: DetailCaseDataI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { theme } = useContext(ThemeContext);
	const actualCase = props.actualCase.data

	const rowTitleStyle = { marginLeft: 0, marginRight: 0, fontSize: "13px" }
	const rowStyle = { color: "gray", fontSize: "12px", marginLeft: 0, marginRight: 0 }
	function getSamplesBadges() {
		return actualCase?.samples?.map((x: any, i: number) => {
			let sex = x.sample?.sexo ? x.sample.sexo.trim().toLowerCase() : "";
			sex = sex === "male" || "female" ? sex : " - ";
			if (sex !== " - ")
				sex = translate(`common.sexType.${sex}`)
			return (
				<Col sm={6} md={4} lg={4} xl={3} key={i} style={{ margin: 0 }}>
					<BadgeSample name={x.sample.name}
						afected={x.afectado}
						fontSize="12px"
						style={{ marginRight: 0 }} />
					<CopyToClipboard valueToCopy={x.sample.name} fontSize={"12px"} />
					<span style={{ fontSize: "12px" }}>
						{(actualCase?.samples ?? []).length >= 7 ?
							(sex ?? "").length > 0 ?
								<Tooltip label={sex}>
									<strong>{sex[0].toUpperCase()}</strong>
								</Tooltip>
								: <strong>&nbsp;-&nbsp;</strong> : sex}
						&nbsp;&nbsp;
					</span>
				</Col>
			)
		})
	}
	function getStatus() {
		const styleIcon = { border: "none", color: theme.grayHeader, alignSelf: "center", marginRight: "5px" }
		const state = actualCase?.state?.trim()?.toLowerCase()
		const styleP = { color: (state === "processing" ? "gray" : theme.grayHeader), marginBottom: 0, fontSize: "13px" }
		const states: any = {
			active: <>
				<FontAwesomeIcon icon={faCheckCircle} style={styleIcon} />
				<p style={styleP}>
					{translate('home.studyState.active')}
				</p>
			</>,
			archived: <>
				<FontAwesomeIcon icon={faFolder} style={styleIcon} />
				<p style={styleP}>
					{translate('home.studyState.archived')}
				</p>
			</>,
			processing: <>
				<Spinner animation="border" variant="secondary" size="sm" style={{ marginRight: "5px", color: `${theme.grayHeader} !important` }} />
				<p style={styleP}>
					{translate('home.studyState.processing')}
				</p>
			</>,
			reported: <>
				<FontAwesomeIcon icon={faCheckCircle} style={styleIcon} />
				<p style={styleP}>
					{translate('home.studyState.reported')}
				</p>
			</>,
			default: <p style={styleP}>{actualCase?.state}</p>
		}
		return <Row style={{ margin: 0 }}>
			{states[state] ?? states["default"]}
		</Row>
	}
	return (
		<Card style={{ width: "100%" }}>
			<Card.Header style={{ padding: "0 0 0 1.25rem", display: "flex", justifyContent: "space-between" }}>
				<h5 style={{ color: theme.grayHeader, fontSize: "17px", paddingTop: "12px" }}>
					{/* <FontAwesomeIcon icon={faDatabase} style={{ color: "lightgray", fontSize: "20px", marginRight : "10px" }} /> */}
					<FontAwesomeIcon icon={faClipboard} style={{ color: "lightgray", fontSize: "20px", marginRight: "10px" }} />
					{translate('casesDetail.generalData')}
				</h5>
				<div style={{ marginRight: "15px", alignSelf: "center" }}>
				</div>
			</Card.Header>
			<Card.Body>
				<Row style={{ marginRight: 0, marginLeft: 0 }}>
				</Row>
				<Row style={{ marginRight: 0, marginLeft: 0 }}>
					<Col>
						<Row style={rowTitleStyle}>
							{translate('common.studyType')}
						</Row>
						<Row style={rowStyle}>
							<BadgeTypeSubtype type={actualCase?.type ?? "simple"}
								subtype={actualCase?.subtype?.toLowerCase() ?? "wes"}
								fontSize="13px"
								style={{  "verticalAlign": "text-top", "margin": "10px 5px" }}
							/>
						</Row>
					</Col>
					<Col>
						<Row style={rowTitleStyle}>
							{translate('casesDetail.professional')}
						</Row>
						<Row style={rowStyle}>
							{actualCase?.professional}
						</Row>
					</Col>
					<Col>
						<Row style={rowTitleStyle}>
							{translate('common.institution')}
						</Row>
						<Row style={rowStyle}>
							{actualCase?.institution}
						</Row>
					</Col>
					<Col>
						<Row style={rowTitleStyle}>
							{translate('common.creationDate')}
						</Row>
						<Row style={rowStyle}>
							{actualCase?.dateCreated}
						</Row>
					</Col>
					<Col>
						<Row style={rowTitleStyle}>
							{translate('casesDetail.status')}
						</Row>
						<Row style={rowStyle}>
							{getStatus()}
						</Row>
					</Col>
					<Col>
						<Row style={rowTitleStyle}>
							{translate('common.code')}
						</Row>
						<Row style={rowStyle}>
							{actualCase?.code}
						</Row>
					</Col>
					<Col>
						<Row style={rowTitleStyle}>
							{translate('common.batch')}
						</Row>
						<Row style={rowStyle}>
							{actualCase?.batch}
						</Row>
					</Col>
				</Row>
				<Row style={{ margin: 0 }}>
					<Col>
						<Row style={rowTitleStyle}>
							{translate('casesDetail.description')}
						</Row>
						<Row style={rowStyle}>
							{actualCase?.description}
						</Row>
					</Col>

				</Row>
				{/* <hr/> */}
			</Card.Body>
			<Card.Footer className="text-muted">
				{/* {translate(`home.processCase.editSamples`)} */}
				<div style={{ display: "flex" }}>
					<h5 style={{ color: theme.grayHeader, fontSize: "15px", padding: 0, margin: "3px 5px 5px 0" }}>
						{translate('casesDetail.samples')}
					</h5>

					{!props.actualCase.loaded ? "" :
						props.actualCase.loading ?
							<Spinner animation="grow" role="status" style={{ height: "22px", width: "22px", color: theme.grayHeader }} /> :
							<CircleLabel label={`${(props.actualCase.data.samples ?? []).length}`}
								size={1}
								backColor={theme.grayHeader}
							/>
					}
					{/* <div style={{
                        height: "22px",
                        width: "22px",
                        backgroundColor: theme.grayHeader,
                        borderRadius: "50px",
                        textAlign: "center",
                        verticalAlign: "baseline",
                        color: theme.backgroundPrimary
                    }}>
                        {(actualCase?.samples ?? []).length}
                    </div> */}
				</div>
				<Row style={{ marginLeft: "10px", display: "flex" }}>
					{getSamplesBadges()}
				</Row>
			</Card.Footer>
		</Card>
	)
}
