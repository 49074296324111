import React from 'react';
import { useEffect, useState } from "react";
import GenomicVServices from "../../Services/GenomicVService";
import Loader from '../Common/Loader/Loader';
import "./igv.css"
// import igv from "igv";//ORIGINAL //igv.esm.js
import igv  from "/node_modules/igv/dist/igv.esm";
// import igv from "https://cdn.jsdelivr.net/npm/igv@2.15.10/dist/igv.esm.min.js"

import {
  getIgvTrackSettings_SampleNGS,
  getTrackGene,
  getTrackLibrary,
  getTrackClinvar,
  getTrackClingen_CuratedRegionList,
  getTrackClingen_HaploinsufficiencyGenes,
  getTrackClingen_TriplosensitivityGenes,
  getTrackClingen_RecurrentCNV
} from './IgvTracks';

export default function Igv(props) {
  let { chromosome, position, gen, assembly, sampleName, view, library, type, cnvType } = props.data;
  type = type && type === "cnv" ? "cnv" : "snp";
  const isCnv = type === "cnv";
  view = view === "gene" ? "gene" : "variant";
  const [browser, setBrowser] = useState(null);
  const [bamData, setbamData] = useState(false);
  const GenopmicVS = new GenomicVServices();
  let startPosition = +(`${position}`.split("-")[0]);
  let endPosition = +(`${position}`.split("-")[1]);
  const chromPosition = `${chromosome}:${position}`

  useEffect(() => {
    if (!bamData) {
      GenopmicVS.get_bam(assembly, sampleName, gen, chromPosition, view, library)
        .then((res) => {
          setbamData(res.data)
        })
        .catch((err) => {
          console.log("error on response: GenopmicVS.get_bam")
          console.log(err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  function getTrackSampleNgss(prefixUrl) {
    const trackIndexURL = `${prefixUrl}${bamData.bamUrl[`${sampleName}`]}.bai`,
      trackUrl = `${prefixUrl}${bamData.bamUrl[`${sampleName}`]}.bam`,
      trackName = props?.data?.sampleName;
    return getIgvTrackSettings_SampleNGS(trackUrl, trackIndexURL, trackName);
  }

  const getRoi = () => {
    if (isCnv) {
      const roiFeature = {
        chr: chromosome,
        start: startPosition,
        end: endPosition
      };
      const auxType = cnvType.trim().toLowerCase();
      const color = auxType.indexOf("dup") > -1 ? "rgba(100, 170, 200, 0.5)" : auxType.indexOf("del") > -1 ? "rgba(255, 78, 88, 0.5)" : "rgba(170, 170, 170, 0.5)";
      return [{
        name: "CNV",
        color: color,
        features: [roiFeature]
      }]
    }
    return []
  }

  useEffect(() => {
    if (!bamData) return
    var igvContainer = document.getElementById("igv-div");
    var localRun = false;
    if (window.location.href.indexOf("localhost") > -1) {
      console.log("Warning: Dont forget to enable CORS chrome plugin to the igv works!!")
      localRun = true
    }
    let prefixUrl = "", prefixClinvarUrl = "", prefixClingenUrl = "", prefixLibraryUrl = "", prefixSampleNgsUrl = "";
    if (localRun) {
      prefixUrl = process.env.REACT_APP_API_URL.replace("/bioflux-platform-gaip-facade/", "");
      console.log(window.location.href)
      if (process.env.REACT_APP_API_URL.indexOf("10.100.100.55") > -1) { //test           environment runing from local 
        prefixUrl = `${prefixUrl}:3000`;
        prefixClingenUrl = prefixClinvarUrl = prefixLibraryUrl = prefixSampleNgsUrl = prefixUrl;
      } else {                                                  //prod & preprod environment runing from local
        prefixClingenUrl = `${prefixUrl}:`;
        prefixClinvarUrl = prefixLibraryUrl = prefixSampleNgsUrl = prefixClingenUrl;
      }
    } else {                                                    //prod & preprod environment runing from deploy
      prefixClingenUrl = prefixClinvarUrl = `${prefixUrl}:3000`;
      prefixLibraryUrl = prefixSampleNgsUrl = prefixUrl;
    }
    const trackGene = getTrackGene(bamData);
    const trackClinvar = getTrackClinvar(bamData, prefixClinvarUrl);
    const trackLibrary = getTrackLibrary(bamData, prefixLibraryUrl, library);
    const trackSampleNgss = getTrackSampleNgss(prefixSampleNgsUrl);
    const getIgvTracks = () => {
      const getIgvTracks_snp = () => {
        return [
          trackClinvar,
          trackLibrary,
          trackSampleNgss,
          trackGene]
      }
      const getIgvTracks_cnv = () => {
        const curatedRegionList = getTrackClingen_CuratedRegionList(bamData, prefixClingenUrl);
        const haploinsufficiencyGenes = getTrackClingen_HaploinsufficiencyGenes(bamData, prefixClingenUrl);
        const triplosensitivityGenes = getTrackClingen_TriplosensitivityGenes(bamData, prefixClingenUrl);
        const recurrentCNV = getTrackClingen_RecurrentCNV(bamData, prefixClingenUrl);
        return [
          trackGene,
          trackClinvar,
          curatedRegionList,
          haploinsufficiencyGenes,
          triplosensitivityGenes,
          recurrentCNV
        ]
      }
      const igvTracks = isCnv ? getIgvTracks_cnv() : getIgvTracks_snp();
      return igvTracks
    }
    const getLocus = () => {
      const newLocus = view === "gene" ? bamData.locus : bamData.variantlocus;
      if (!isCnv) return newLocus;
      const ch = newLocus.split(":")[0];
      const poss = newLocus.split(":")[1];
      let igvStartPosition = +(poss.split("-")[0]);
      let igvEndPosition = +(poss.split("-")[1]);
      let space = (igvEndPosition - igvStartPosition) * 0.1
      igvStartPosition = igvStartPosition > space ? igvStartPosition - space : igvStartPosition;
      return `${ch}:${igvStartPosition}-${igvEndPosition + space}`;
    }

    var igvOptions = {
      showNavigation: true,
      showCursorTrackingGuide: true,
      cursorGuideVisible: true,
	  showCenterGuide: true,  
      showRuler: true,
      reference: {
        id: bamData.reference_id,
        fastaURL: bamData.reference_fastaURL,
        cytobandURL: bamData.reference_cytobandURL,
      },
      // locus: view === "gene" ? bamData.locus : bamData.variantlocus,
      locus: getLocus(),
      roi: getRoi(),
      tracks: getIgvTracks()
    };
    igv.createBrowser(igvContainer, igvOptions)
      .then(function (browser) {
        setBrowser(browser);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bamData]);

  useEffect(() => {
    if (bamData && browser !== null) browser.search(gen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gen]);

  if (bamData)
    return (
      <div id="igv-div" style={{ width: "100%", border: "none", backgroundColor: "#FFFFFF", padding: 0 }}></div>
    );
  return (
    <Loader onContainer={true} type={'DNA'} />
  );
}
//
// Request URL: https://bplatform.bitgenia.com/clinvarvcf/clinvar_P-LP.vcf.idx
// Request URL:          http://192.168.210.85/clinvarvcf/clinvar_P-LP.vcf.idx

// Request URL: https://bplatform.bitgenia.com/library/SureSelectHumanAllExonV6_genes_notpadded.bed.idx

