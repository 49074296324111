import React from 'react';
import LinkTooltip from "./LinkTooltip";
import { LangContext } from "../../../Contexts/Lang";
import { useContext } from "react";
import { Tooltip } from "../Tooltip/Tooltip";
import { Link } from "react-router-dom";
import CopyToClipboard from "../CopyToClipboard";
import { getSampleId_NameTagAffSex } from 'src/utils/BioHelpers';
import { SamplesI } from 'src/Contexts/interfaces';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';

interface LinkI {
    fontSize?: string;
}
//---------------------------------------- GEN Links --------------------------------------------------------
interface LinkGeneCardsI extends LinkI {
    geneName: string
}
function LinkGeneCards(props: LinkGeneCardsI) {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <LinkTooltip tooltipLabel={`${translate("common.goTo")} GeneCards`}
            href={`https://www.genecards.org/cgi-bin/carddisp.pl?gene=${props.geneName}`}
            label={`GeneCards`}
            fontSize={props.fontSize ?? "auto"}
        />
    )
}

interface LinkOMIMI extends LinkI {
    omimId: string,
    label?: string
}
function LinkOMIM(props: LinkOMIMI) {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <LinkTooltip tooltipLabel={`${translate("common.goTo")} OMIM`}
            href={`http://omim.org/entry/${props.omimId}`}
            label={props.label ?? "OMIM"}
            fontSize={props.fontSize ?? "auto"}
        />
    )
}
interface LinkClinGenI extends LinkI {
    clingenId?: string,
    label?: string,
    variant?: {
        hgvsRefSeq: string, //Ex: NM_002496.3 
        change: string,     //Ex: c.64C>T
        genename: string
    }
}
function LinkClinGen(props: LinkClinGenI) {
    const { dispatch: { translate } } = useContext(LangContext);
    let baseUrl = `https://search.clinicalgenome.org`;
    if (props.clingenId)
        return (
            <LinkTooltip tooltipLabel={`${translate("common.goTo")} ClinGen`}
                href={`${baseUrl}/kb/gene-dosage/${props.clingenId}`}
                label={props.label ?? "ClinGen"}
                fontSize={props.fontSize ?? "auto"}
            />
        )
    // const baseUrl = "https://reg.clinicalgenome.org/redmine/projects/registry/genboree_registry/allele?hgvsOrDescriptor=NM_000418.4%3Ac.223A%3EG"
    baseUrl = `https://reg.clinicalgenome.org`;
    if (props.variant && props.variant.change) {
        let aacheng_refgene = props.variant.change;
        let genename = props.variant.genename ?? "";
        let trans = props.variant.hgvsRefSeq ?? "";
        aacheng_refgene = Array.from(new Set(aacheng_refgene.split(","))).join(",").replaceAll("FLG:", "").replaceAll("flg:", "").replaceAll(genename + ":", "")
        const aachengSplitted = aacheng_refgene.replaceAll("+", "%2B").split(",")
        const url = `${baseUrl}/redmine/projects/registry/genboree_registry/allele?hgvsOrDescriptor=${trans}%3A${aachengSplitted}`;
        return (
            <LinkTooltip tooltipLabel={`${translate("common.goTo")} ClinGen`}
                href={url}
                label={trans + " - " + aachengSplitted}
                fontSize={props.fontSize ?? "auto"}
            />
        )
        // let tuple = (aacheng_refgene
        //     .split(",")
        //     .map((x: string) => {
        //         let transcripts = x.split(":");
        //         return [
        //             trans,
        //             transcripts[0],
        //             transcripts.length > 2 ? convertirSNP(transcripts[2]) : "",
        //             transcripts.slice(1, transcripts.length).join(" - ")]
        //     }).filter((x: any[]) => {
        //         return x[1] === trans.slice(0, trans.lastIndexOf("."))
        //     }))[0];
    }
    return <></>
}

// function convertirSNP(anotacion: string) {
//     // Extraer el número de posición y las bases del SNP de la anotación
//     const match = anotacion.match(/^c\.([ATCG])(\d+)([ATCG])$/)

//     if (!match) {
//         // Si la anotación no coincide con el formato esperado, lanzar un error
//         throw new Error('Formato de anotación de SNP inválido');
//         return;
//     }

//     // Obtener la posición del SNP y las bases que lo forman
//     const base1 = match[1];
//     const posicion = match[2];
//     const base2 = match[3];

//     // Construir la nueva anotación en el formato "c.<posicion><base1><base2>"
//     const nuevaAnotacion = `c.${posicion}${base1}>${base2}`;

//     // Devolver la nueva anotación
//     return nuevaAnotacion;
// }


//---------------------------------------- VARIANT Links ----------------------------------------------------
interface LinkENSEMBLI extends LinkI {
    chromosome?: string,
    position?: number,
    transcriptEnst?: string,
    label?: string,
    notBold?: boolean,
    genomeRef?: string
}
function LinkENSEMBL(props: LinkENSEMBLI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const genomRef = props.genomeRef ?? "38";
    let ensemblUrl = 'http://ensembl.org/Homo_sapiens/';
    if (genomRef.indexOf("38") === -1) {
        ensemblUrl = 'http://grch37.ensembl.org/Homo_sapiens/';
    }
    if (props.chromosome && props.position) {
        const wholeChPosition = `${props.chromosome}:${props.position}`;
        let label = props.label ?? wholeChPosition
        label = label.length > 33 ? `${label.substring(0, 30)}...` : label;
        label = label.includes("chr") ? label.replace("chr","") : label;
        return (
            <LinkTooltip tooltipLabel={`${translate("common.goTo")} ENSEMBL`}

                href={`${ensemblUrl}Location/View?r=${wholeChPosition}-${props.position + 1};db=core`}
                label={props.notBold ? label : <strong>{label}</strong>}
                fontSize={props.fontSize ?? "auto"}
            />
        )
    }
    if (props.transcriptEnst)
        return (
            <LinkTooltip tooltipLabel={`${translate("common.goTo")} ENSEMBL - ${translate("common.transcript")} ${props.transcriptEnst}`}
                href={`${ensemblUrl}Transcript/Summary?t=${props.transcriptEnst}`}
                label={`${props.label ?? props.transcriptEnst}`}
                fontSize={props.fontSize ?? "auto"}
            />
        )

    //     return (
    //         <LinkTooltip tooltipLabel={`${translate("common.goTo")} ENSEMBL`}
    //             href={`http://grch37.ensembl.org/Homo_sapiens/Transcript/Summary?t=${props.transcript}`}
    //             label={props.transcript}
    //             fontSize={props.fontSize ?? "auto"}
    //         />
    //     )
    return <></>
}
interface LinkMutalizerI extends LinkI {
    effect: string,
    maneSelect?: string,
    codonChange?: string,
}
function LinkMutalizer(props: LinkMutalizerI) {
    const { dispatch: { translate } } = useContext(LangContext);
    if (LinkMutalizer_validator(props.maneSelect, props.codonChange, props.effect)) {
        const codonChange = (props.codonChange ?? "").replaceAll("+", "_")
        return (
            <LinkTooltip tooltipLabel={`${translate("common.goTo")} Mutalyzer`}
                href={`https://v3.mutalyzer.nl/namechecker/${props.maneSelect}:${codonChange}`}
                label={`${props.maneSelect}`}
                fontSize={props.fontSize ?? "auto"}
            />
        )
    }
    return <></>;
}
const LinkMutalizer_validator = (maneSelect?: string, codonChange?: string, effect?: string): boolean => {
    if (!(maneSelect) || !(codonChange)) return false;
    let effectToEnableMutalizer = [
        "frameshift_variant", "stop_gained", "stop_lost", "start_lost", "missense_variant", "inframe_insertion",
        "disruptive_inframe_insertion", "inframe_deletion", "disruptive_inframe_deletion"
    ]
    if (effectToEnableMutalizer.indexOf(((effect ?? "").replaceAll(" ", "_") ?? "").toLowerCase().trim()) > -1)
        return true;
    else
        return false;
}

interface LinkMutationTasterI extends LinkI {
    chromosome: string,
    chPosition: string,
    reference: string,
    alternative: string,
    label?: string
}
function LinkMutationTaster(props: LinkMutationTasterI) {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <LinkTooltip tooltipLabel={`${translate("common.goTo")} mutationtaster.org`}
            href={`http://www.mutationtaster.org/cgi-bin/MutationTaster/MT_ChrPos.cgi?chromosome=${props.chromosome}&position=${props.chPosition}&ref=${props.reference}&alt=${props.alternative}`}
            label={props.label ?? "M.Taster"}
            fontSize={props.fontSize ?? "auto"}
        />
    )
}

interface SpliceAiLookupI extends LinkI {
    chromosome?: string,
    position?: string,
    reference?: string,
    alternative?: string,
    hg?: string,
    label?: string
}
function SpliceAiLookup(props: SpliceAiLookupI) {
    const { dispatch: { translate } } = useContext(LangContext);
    if (!props.chromosome || !props.position || !props.reference || !props.alternative || !props.hg) return <>--</>
    // urlTarget : `https://spliceailookup.broadinstitute.org/#variant=16-27344882-A-G&hg=38&distance=50&mask=0&precomputed=0`
    const hg = ((props?.hg ?? "").indexOf("37") > -1) ? "37" : "38";
    let url = `https://spliceailookup.broadinstitute.org/#variant=`;
    const chPosRefAlt = `${props.chromosome}-${props.position}-${props.reference}-${props.alternative}`;
    let label = props.label ?? chPosRefAlt
    label = label.length > 43 ? `${label.substring(0, 40)}...` : label;
    url += `${chPosRefAlt}&hg=${hg}&distance=50&mask=0&precomputed=0`
    return (
        <LinkTooltip tooltipLabel={`${translate("common.goTo")} SpliceAi-lookup - broadinstitute`}
            href={url}
            label={label}
            fontSize={props.fontSize ?? "auto"}
        />
    )
}

interface LinkDecipherI extends LinkI {
    chrom: string,
    position: string,
    gene: string,
    reference: string,
    alt: string,
}
function LinkDecipher(props: LinkDecipherI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const chrom = props.chrom.toUpperCase().replace("CHR", "");
    const position = props.position.trim();
    const ref = props.reference.trim();
    const alt = props.alt.trim();
    const gene = props.gene.trim();
    let link = `https://www.deciphergenomics.org/sequence-variant/`;
    link = `${link}${chrom}-${position}-${ref}-${alt}/genes/${gene}/clinical-info`

    return (<LinkTooltip tooltipLabel={`${translate("common.goTo")} Decipher`}
        href={link}
        label={"Decipher"}
        fontSize={props.fontSize ?? "auto"}
        externalLink={true}
    />)
}

//---------------------------------------- BOTH: GEN & VARIANT Links ----------------------------------------

interface LinkGnomAdI extends LinkI {
    geneName?: string,
    variant?: {
        chromosome: string,
        chPosition: string,
        reference: string,
        alternative: string
        genomeRef: string,
    },
    region?: {
        chromosome: string,
        start: string,
        end: string,
        reference: string,
    }
    label?: string
}
function LinkGnomAd(props: LinkGnomAdI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const genomeRef = (props.variant?.genomeRef ?? "").indexOf("37") > -1 ? 37 : 38;
    const baseUrl = "http://gnomad.broadinstitute.org/";
    let label = props.label ?? "gnomAD";
    label = label.length > 33 ? `${label.substring(0, 30)}...` : label;
  
    if (props.geneName)
        return (
            <LinkTooltip tooltipLabel={`${translate("common.goTo")} gnomAD`}
                href={`${baseUrl}gene/${props.geneName}?dataset=${genomeRef === 37 ? "gnomad_r2_1" : "gnomad_r3"}`}
                label={label}
                fontSize={props.fontSize ?? "auto"}
            />
        )
    if (props.variant)
        return (<LinkTooltip tooltipLabel={`${translate("common.goTo")} gnomAD`}
            href={`${baseUrl}variant/${props.variant.chromosome}-${props.variant.chPosition}-${props.variant.reference}-${props.variant.alternative}?dataset=${genomeRef === 37 ? "gnomad_r2_1" : "gnomad_r3"}`}
            fontSize={props.fontSize ?? "auto"}
            label={label}
        />
        )
    if (props.region)
        return (<LinkTooltip tooltipLabel={`${translate("common.goTo")} gnomAD`}
            href={`${baseUrl}region/${props.region.chromosome}-${props.region.start}-${props.region.end}?dataset=${genomeRef === 37 ? "gnomad_r2_1" : "gnomad_r3"}`}
            fontSize={props.fontSize ?? "auto"}
            label={label}
        />
        )
    return (<>-</>)
}

interface NcbiDbsnpLinksByVcfIdI extends LinkI {
    vcfIds: string,
    copyToClipboard?: boolean,
}
function NcbiDbsnpLinksByVcfId(props: NcbiDbsnpLinksByVcfIdI) {
    const vcfIdsSplited = props.vcfIds.split(/,|;/)
    if (!vcfIdsSplited || (vcfIdsSplited.length === 1 && vcfIdsSplited[0].trim().length < 2)) return <></>;
    const clinvarRsLinks = vcfIdsSplited
        .map((x: string, i: number) => {

            if (x.trim().toLowerCase().startsWith("rs")) {
                return (    // apuntar a dbsnp
                    <span key={i}>
                        <LinkNCBI key={i} dbsnpRs={x} fontSize={"12px"} />
                        {props.copyToClipboard && <CopyToClipboard valueToCopy={x.trim()} />}

                    </span>)
            } else {
                return (""
                    // <span key={i}>
                    //     <LinkNCBI key={i} clinvarNonRs={x} fontSize={"12px"} label="Clinvar" />
                    //     {props.copyToClipboard && <CopyToClipboard valueToCopy={x.trim()} />}
                    // </span>
                )
            }
        })
    return <>{clinvarRsLinks}</>
}
interface NcbiClinvarLinkByVcfIdI extends LinkI {
    vcfIds: string,
    copyToClipboard?: boolean,
}
function NcbiClinvarLinkByVcfId(props: NcbiClinvarLinkByVcfIdI) {
    let vcfIdsSplited = props.vcfIds.split(/,|;/)
    if (!vcfIdsSplited || (vcfIdsSplited.length === 1 && vcfIdsSplited[0].trim().length < 2)) return <></>;
    const clinvarLink = vcfIdsSplited.filter((x: string) => !x.trim().toLowerCase().startsWith("rs"))
    // let link: any;
    if (clinvarLink.length > 0)
        return <>{clinvarLink.map((x: string, i: number) => {
            return <span key={`${i}`}>
                <LinkNCBI key={i} clinvarNonRs={x.trim()} fontSize={"12px"} label={`Clinvar${clinvarLink.length > 0 ? (" - " + x.trim()) : ""}`} />
                {i !== clinvarLink.length - 1 ? <>,&nbsp;</> : ""}
            </span>
        })}
        </>
    vcfIdsSplited = vcfIdsSplited.filter((x: string) => x.trim().toLowerCase().startsWith("rs")).map((x: string) => x.trim())
    return <>{vcfIdsSplited.map((x: string, i: number) => {
        return <span key={`${i}`}>
            <LinkNCBI key={0} clinvarRs={x} fontSize={"12px"} label={`Clinvar${clinvarLink.length > 0 ? (" - " + x) : ""}`} />
            {i !== vcfIdsSplited.length - 1 ? <>,&nbsp;</> : ""}
        </span>
    })}
    </>
}

interface LinkNCBII extends LinkI {
    geneName?: string,
    dbsnpRs?: string,
    clinvarRs?: string,
    clinvarNonRs?: string,
    litvarRs?: string,
    transcriptNm?: string,
    label?: string,
}
function LinkNCBI(props: LinkNCBII) {
    const { dispatch: { translate } } = useContext(LangContext);
    const ncbiUrl = "http://www.ncbi.nlm.nih.gov/"
    if (props.geneName)
        return (
            <LinkTooltip tooltipLabel={`${translate("common.goTo")} NCBI`}
                href={`${ncbiUrl}gene/?term=${props.geneName}[Gene Name] AND Human[Organism]`}
                label={"NCBI"}
                fontSize={props.fontSize ?? "auto"}
            />
        )
    if (props.dbsnpRs)
        return (
            <LinkTooltip tooltipLabel={"NCBI - dbSNP"}
                href={`${ncbiUrl}snp/${props.dbsnpRs}`}
                label={props.dbsnpRs}
                fontSize={props.fontSize ?? "auto"}
            />
        )
    if (props.clinvarRs)
        return (
            <LinkTooltip tooltipLabel={`${translate("common.goTo")} NCBI - Clinvar ${props.clinvarRs}`}
                href={`${ncbiUrl}clinvar?term=${props.clinvarRs}`}
                label={props.label ?? `${props.clinvarRs}`}
                fontSize={props.fontSize ?? "auto"}
            />
        )
    if (props.clinvarNonRs)
        return (
            <LinkTooltip tooltipLabel={`${translate("common.goTo")} NCBI - Clinvar ${props.clinvarNonRs}`}
                href={`${ncbiUrl}clinvar/variation/${props.clinvarNonRs}`}
                label={props.label ?? `${props.clinvarNonRs}`}
                fontSize={props.fontSize ?? "auto"}
            />
        )
    if (props.litvarRs)
        return (
            <LinkTooltip tooltipLabel={`${translate("common.goTo")} NCBI - Litvar ${props.litvarRs}`}
                // href={`${ncbiUrl}CBBresearch/Lu/Demo/LitVar/#!?query=${props.litvarRs}`} //olddd
                // https://www.ncbi.nlm.nih.gov/research/litvar2/docsum?variant=litvar@rs1800562%23%23&query=rs1800562
                href={`${ncbiUrl}research/litvar2/docsum?variant=litvar@${props.litvarRs}%23%23&query=${props.litvarRs}`}
                label={`${props.litvarRs}`}
                fontSize={props.fontSize ?? "auto"}
            />
        )
    if (props.transcriptNm)
        return (
            <LinkTooltip tooltipLabel={`${translate("common.goTo")} NCBI - ${translate("common.transcript")} ${props.transcriptNm}`}
                href={`${ncbiUrl}nuccore/${props.transcriptNm}`}
                label={`${props.transcriptNm}`}
                fontSize={props.fontSize ?? "auto"}
            />
        )

    return (<>-</>)
}

//---------------------------------------- PROTIEN Links ----------------------------------------------------
interface LinkUniprotI extends LinkI {
    uniprotId?: string
}
function LinkUniprot(props: LinkUniprotI) {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <LinkTooltip tooltipLabel={`${translate("common.goTo")} Uniprot`}
            href={`https://www.uniprot.org/uniprot/${props.uniprotId}`}
            label={"Uniprot"}
            fontSize={props.fontSize ?? "auto"}
        />
    )
}
//---------------------------------------- LOCAL Links ------------------------------------------------------
interface LinkLocalGenI extends LinkI {
    geneName: string,
    caseId: string,
    variantId: string,
    fontSize?: string,
    onClick?(): void,
    label?: string | JSX.Element,
    tooltip?: string,
}
function LinkLocalGen(props: LinkLocalGenI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const tooltip = props.tooltip ?? `${translate("common.goTo")} ${props.geneName}`
    return (
        <Tooltip label={tooltip}>
            <Link to={`/cases/query/gene/${props.caseId}/${props.geneName}/${props.variantId}`}
                onClick={(e) => {
                    e.stopPropagation();
                    if (props.onClick) props.onClick();
                }}
            >
                <span style={{ fontSize: props.fontSize ?? "auto", fontWeight: "bold" }}>
                    {props.label ?? props.geneName}
                </span>
            </Link>
        </Tooltip>
    )
}

interface LinkLocalAdvancedQuerysI extends LinkI {
    rs: string,
    caseId: string,
    sample: SamplesI,
    fontSize?: string,
    onClick?(): void,
    label?: string | JSX.Element,
    tooltip?: string,
}
function LinkLocalAdvancedQuerys(props: LinkLocalAdvancedQuerysI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const tooltip = props.tooltip ?? `${translate("common.goTo")} ${translate('casesDetail.advancedQueries')}: ${props.rs}`

    const sampleId = getSampleId_NameTagAffSex(props.sample.sample.name, props.sample.tag, `${props.sample.afectado}`, props.sample.sample.sexo)
    // http://localhost:3000/cases/query/caseId/713/sample_1372=B1183746PTC-PROBAND-true-&rs=rs397509198&studyId=713
    // http://localhost:3000/cases/query/caseId/713/sample_1372=B1183746PTC-PROBAND-true-&rs=rs6998213&studyId=713
    const url = `/cases/query/caseId/${props.caseId}/sample_${props.sample.id}=${sampleId}&rs=${props.rs}&studyId=${props.caseId}`;
    return (
        <Tooltip label={tooltip}>
            <Link to={url}
                onClick={(e) => {
                    e.stopPropagation();
                    if (props.onClick) props.onClick();
                }}>
                <span style={{ fontSize: props.fontSize ?? "auto", fontWeight: "bold" }}>
                    <Button variant="light" style={{ borderRadius: 50, padding: 4, verticalAlign: "text-top" }}>
                        <FontAwesomeIcon icon={faSearchPlus} style={{ color: theme.link, float: "right", fontSize: "12px" }} />
                    </Button>
                </span>
            </Link>
        </Tooltip>
    )
}

export {
    //---------------------------------------- GEN Links --------------------------------------------------------
    LinkGeneCards,
    LinkOMIM,
    LinkClinGen,
    //---------------------------------------- VARIANT Links ----------------------------------------------------   
    LinkENSEMBL,
    LinkMutalizer,
    LinkMutalizer_validator,
    LinkMutationTaster,
    NcbiDbsnpLinksByVcfId,
    NcbiClinvarLinkByVcfId,
    SpliceAiLookup,
    LinkDecipher,
    // LinkDecipher2,
    //---------------------------------------- BOTH: GEN & VARIANT Links ----------------------------------------
    LinkGnomAd,
    LinkNCBI,
    //---------------------------------------- PROTIEN Links ----------------------------------------------------
    LinkUniprot,
    //---------------------------------------- LOCAL Links ------------------------------------------------------
    LinkLocalGen,
    LinkLocalAdvancedQuerys
}