import React from 'react';
import { Popover } from '../../Components/Common/Popover';
import { TriggersT } from '../IGV/types';

interface LabelPopoverI {
	popoverTitle?: string | JSX.Element,
	popoverBody: string | JSX.Element | JSX.Element[],
	label: string | JSX.Element,
	style?: any,
	trigger?: TriggersT
}
export default function LabelPopover(props: LabelPopoverI) {
	return (
		<Popover title={props.popoverTitle}
			content={
				<div>
					{props.popoverBody}
				</div>
			}
			trigger={props.trigger ?? "hover"} style={{ fontSize: "11px", zIndex: 100000000 }}>
			<span style={props.style ? { ...props.style } : {}}>
				{props.label}
			</span>
		</Popover>
	)
}
