import React from 'react';
import { useContext } from "react";
import { LangContext } from "../../Contexts/Lang";
import { Popover } from './Popover';
import { checkValue } from "../../utils/helpers";
import UserContext from "../../Contexts/User/UserContext";
import ThemeContext from "src/Contexts/Theme/ThemeContext";
import Frequency from './Frequency';
import BadgeAbsentInGnomad from './Badges/BadgeAbsentInGnomad';
import { Col, Row } from 'react-bootstrap';

interface VariantFrequencyNewI {
    infoCD?: any,
    hr?: boolean,
}
export default function VariantFrequencyNew(props: VariantFrequencyNewI) {
    const { user } = useContext(UserContext);
    const { theme } = useContext(ThemeContext);
    const hr = props.hr ?? true
    const infoCD = props.infoCD;

    let frequencies: Frequency;
    frequencies = new Frequency(props.infoCD);
    const { dispatch: { translate } } = useContext(LangContext);
    type freqsTypes = "gnomad" | "exac" | "tgp" | "account" | "community";
    let listOfFreqs: freqsTypes[] = []
    var frequencyComponent: any = "";

    const hrComponent = hr ? <hr style={{ margin: "0px" }} /> : <br style={{ margin: "1px" }} />
    if (!frequencies.thereIsGnomadExome && !frequencies.thereIsGnomadGenome)
        return (<><BadgeAbsentInGnomad /><br />{hrComponent}</>);
    const getPercentAndProbValue = (value: any) => {
        if (value === undefined) return " - "
        return (<>
            {(parseFloat(value ?? "0") * 100).toFixed(2)} %
            <span style={{ fontSize: 9, verticalAlign: "middle" }}> &nbsp;({value}) </span>
        </>)
    }

    if (infoCD) {
        // var gnomad_exome: any = "3.50412344e-05",//test values
        // gnomad_genome: any = "8.504e-05",//test values
        let popMaxAlleleCount = frequencies.gnomadExome.popMaxAlleleCount;
        let popMaxNHom = frequencies.gnomadExome.popMaxNHom;
        frequencyComponent = (
            <>
                <b style={{ color: "gray" }}> Exomes </b>
                {
                    !frequencies.thereIsGnomadExome ? "-" :
                        <div style={{ color: theme.grayHeader }}>
                            <span style={{ fontSize: 11, fontWeight: "bold" }}>AF&nbsp;:&nbsp;</span>{getPercentAndProbValue(frequencies.gnomadExome.popMaxAlleleFreq ?? frequencies.gnomadExome.alleleFreq ?? " - " )}
                            <br />
                            <Row style={{ margin: 0, fontSize: 11 }}>
                                <Col xs = {12} md ={12} lg={12} xl={6} style={{ padding: 0 }}>
                                    AC&nbsp;:&nbsp;{popMaxAlleleCount || popMaxAlleleCount === 0 ? popMaxAlleleCount : " - "}
                                </Col>
                                <Col xs = {12} md ={12} lg={12} xl={6} style={{ padding: 0 }}>
                                    HOM&nbsp;:&nbsp;{popMaxNHom || popMaxNHom === 0 ? popMaxNHom : " - "}
                                </Col>
                            </Row >
                        </div>
                }
                {hrComponent}
            </>
        )
        listOfFreqs.push("gnomad")
        popMaxAlleleCount = frequencies.gnomadGenome.popMaxAlleleCount;
        popMaxNHom = frequencies.gnomadGenome.popMaxNHom;
        frequencyComponent = <>
            {frequencyComponent}
            <b style={{ color: "gray" }}>Genomes </b>
            {
                !frequencies.thereIsGnomadGenome ? "-" :
                    <div style={{ color: theme.grayHeader }}>
                        <span style={{ fontSize: 11, fontWeight: "bold" }}>AF&nbsp;:&nbsp;</span>{getPercentAndProbValue(frequencies.gnomadGenome.popMaxAlleleFreq?? frequencies.gnomadGenome.alleleFreq ?? " - ")}
                        <br />
                        <Row style={{ margin: 0, fontSize: 11 }}>
                            <Col xs = {12} md ={12} lg={12} xl={6} style={{ padding: 0 }}>
                                AC&nbsp;:&nbsp;{popMaxAlleleCount || popMaxAlleleCount === 0 ? popMaxAlleleCount : " - "}
                            </Col>
                            <Col xs = {12} md ={12} lg={12} xl={6} style={{ padding: 0 }}>
                                HOM&nbsp;:&nbsp;{popMaxNHom || popMaxNHom === 0 ? popMaxNHom : " - "}
                            </Col>
                        </Row >
                    </div>
            }
            {hrComponent}
        </>
        listOfFreqs.push("gnomad")
        if (frequencies.frequencies.length > 0 && frequencies.domains.length > 0) {
            var idxa = frequencies.domains.indexOf('-1'),
                idxi = frequencies.domains.indexOf(user.data.domain.toString());
            if (idxi >= 0) {
                let freqdomstring = frequencies.frequencies[idxi].toString() + "/" + frequencies.countsamples[idxi].toString();
                frequencyComponent = <>{frequencyComponent}<b style={{ color: theme.grayHeader }}> {translate('common.account')}: </b> {freqdomstring} {hr && <hr style={{ margin: "0" }} />}</>

                listOfFreqs.push("account")
            }
            if (idxa >= 0) {
                let freqall = frequencies.frequencies[idxa] / frequencies.countsamples[idxa];
                frequencyComponent = <>{frequencyComponent}<b style={{ color: theme.grayHeader }}> {translate('common.community')}: </b> {freqall.toFixed(4)} {hr && <hr style={{ margin: "0" }} />}</>
                listOfFreqs.push("community")
            }
        }
    }
    if (listOfFreqs.length > 0) {
        const getPopoverTitle = (ft: freqsTypes) => {
            switch (ft) {
                case "gnomad": return "GnomAD";
                case "exac": return "ExAC";
                case "tgp": return "TGP";
                case "account": return translate('common.account');
                case "community": return translate('common.community');
            }
        }
        frequencyComponent = <Popover style={{ fontSize: "11px" }}
            trigger={"hover"}
            content={<div>
                {listOfFreqs.map((x: freqsTypes, i: number) => {
                    return <div key={i}>
                        <strong> {getPopoverTitle(x)} : </strong>
                        {translate(`casesQuery.frequencyDescriptors.${x}`)} <br />
                    </div>
                })}
            </div>}>
            <div style={{}}>
                {frequencyComponent}
            </div>
        </Popover>
    }

    return <>{frequencyComponent}</>;
}
interface FrequencyMitochondrialI {
    infoCD?: any,
}
function FrequencyMitochondrial(props: FrequencyMitochondrialI) {
    const infoCd = props.infoCD;
    const getLabelValue = (label: string, value: any) => {
        return (<div>
            <span style={{ fontWeight: 'bold', margin: "5px" }}>
                {label}:
            </span>
            <span>
                {value}
            </span>
        </div>)
    }
    let het = checkValue(infoCd.AF_HET);
    let hom = checkValue(infoCd.AF_HOM);
    const roundNumber = (n: any) => {
        if (n !== "--" && !isNaN(n))
            return Math.round(n * 100000) / 100000;
        return n
    }
    return (
        <div>
            {getLabelValue("Source", "gnomAD")}
            {getLabelValue("Heteroplasmic AF", roundNumber(het))}
            {getLabelValue("Homoplasmic AF", roundNumber(hom))}
        </div>
    )
}

export { VariantFrequencyNew, FrequencyMitochondrial }