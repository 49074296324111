import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';

export default class StudyService extends AbstractService {
	basePath: string;
	constructor() {
		super();
		this.basePath = this.REACT_APP_API_URL + "study/";
	}
	async search(filters?: any, pageNumber: number = 1, pageSize: number = 25): Promise<AxiosResponse<any>> {
		filters = filters ?? {}
		const setFilter = (filters: any) => {
			filters["studyType"] = filters?.studyType ?? null;
			filters["dateCreatedFromSearch"] = filters?.dateCreatedFromSearch ?? "";
			filters["dateCreatedToSearch"] = filters?.dateCreatedToSearch ?? "";
			filters["descriptionSearch"] = filters?.descriptionSearch ?? "";
			filters["nameSearch"] = filters?.nameSearch ?? "";
			filters["sortColumn"] = filters?.sortColumn ?? "";
			return filters
		}
		filters = setFilter(filters)
		const url = `${this.basePath}search`
		const data = {
			"domainId": `${this.user?.domain}`,
			"userId": this.user?.id.toString(),
			"pageNumber": pageNumber,
			"pageSize": pageSize,
			...filters
		}
		return await axios.post(url, data, this.authHeader)
	}

	async archive(casesIds: number[]): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}archive`
		const config = {
			...this.authHeader,
			params: {
				id: casesIds.join(","),
				_: (new Date()).getTime()
			}
		}
		return await axios.get(url, config)
	}
	async active(casesIds: number[]): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}active`
		const config = {
			...this.authHeader,
			params: {
				id: casesIds.join(","),
				_: (new Date()).getTime()
			}
		}
		return await axios.get(url, config)
	}
	async delete(casesIds: number[], domainId?: string): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}delete`
		const data = {
			domainId: domainId ?? `${this.user?.domain}`,
			id: casesIds.join(","),
			userId: this.user?.id
		}
		return await axios.post(url, data, this.authHeader)
	}
	async read_case(id: string, domainId?: string): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}study`
		const config = {
			...this.authHeader,
			params: {
				id: id,
				domainId: domainId ?? this.user?.domain,
				userId: this.user?.id,
				_: (new Date()).getTime()
			}
		}
		return await axios.get(url, config)
	}

	async create_case(input: create_caseI, domainId?: string): Promise<AxiosResponse<any>> {
		let data: any = input;
		data.id = "";
		data.domainId = domainId ?? `${this.user?.domain}`;
		data.user = this.user?.id.toString();
		const url = `${this.basePath}save`
		return await axios.post(url, data, this.authHeader)
	}


	async upload_batch_cases(file: any): Promise<AxiosResponse<any>> {
		let data: any = {};
		// data.file = file;

		// data.domainId = domainId ?? `${this.user?.domain}`;
		data.user = this.user?.id.toString();
		const url = `${this.basePath}upload`
		return await axios.post(url, file, this.authHeader)
		// return await axios.post(url, file, 
			// { headers: { ...this.authHeader.headers, 'Content-Type': 'multipart/form-data' } }
			// { headers: { ...this.authHeader.headers, 'Content-Type': 'multipart' } }
		// )

	}
	async update_case(input: Update_caseI, domainId?: string): Promise<AxiosResponse<any>> {
		if (input.hasBam !== undefined) input.hasBam = `${input.hasBam}`
		if (input.hasCnv !== undefined) input.hasCnv = `${input.hasCnv}`
		let data: any = input;
		input.samples.forEach((x: any) => {
			let s: any = {}
			data[`sample-${x.id}-id`] = x.id.toString()
			if (x.affected !== undefined && x.affected)
				data[`sample-${x.id}-affected`] = "on"
			data[`sample-${x.id}-birthDate`] = x.birthDate ?? ""
			data[`sample-${x.id}-sex`] = x.sex ?? ""
			if (x.relation !== undefined)
				data[`sample-${x.id}-tag`] = x.relation ?? ""
			return s;
		})
		delete data['samples']
		data.id = input.id.toString();
		data.domainId = domainId ?? `${this.user?.domain}`;
		data.user = this.user?.id.toString();
		const url = `${this.basePath}update`
		return await axios.post(url, data, this.authHeader)
	}

	async update_case_bam(studyId: string, hasBam: boolean): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}studyBam`
		const data = {
			studyId: studyId,
			hasBam: `${hasBam}`
		}
		return await axios.post(url, data, this.authHeader)
	}
	async update_case_cnv(studyId: string, hasCnv: boolean): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}studyCnv`
		const data = {
			studyId: studyId,
			hasCnv: `${hasCnv}`
		}
		return await axios.post(url, data, this.authHeader)
	}

	async getCNVForStudy(studyId: string, pageNumber: number = 1, pageSize: number = 25): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}getCNVForStudy?`
		const config = {
			...this.authHeader,
			params: {
				studyId: studyId,
				pageNumber: pageNumber,
				pageSize: pageSize,
				_: (new Date()).getTime()
			}
		}
		return await axios.get(url, config)
	}
}

interface SamplesI {
	affected: boolean,
	birthDate: string,
	id: number,
	sex: string,
	tag: string
}

interface caseI {
	id: number,
	code: string,
	customLibrary: string
	genomeReference: string,
	hasBam?: string,
	hasCnv?: string,
	institution: string,
	libraryPrepKit: string,
	professional: string,
	studyDescription: string,
	studyName: string,
	studyState: string,
	subtype: string
}
interface Update_caseI extends caseI {
	samples: SamplesI[]
}
interface create_caseI extends caseI {
	fileSamples: string,
	type: "simple"
}
// fileSamples: "10k_ADN-1160_variant_calling_final_annot.vcf"
// user: "51"
// domainId: "26"

// id: ""
// code: ""
// customLibrary: ""
// genomeReference: "GRCh37"
// institution: "Cromosoma 1"
// libraryPrepKit: "SSHAEV5"
// professional: "Cromosoma1"
// studyDescription: ""
// studyName: "DDTEST3"
// subtype: "WES"
// type: "simple"

export type { Update_caseI }