import React, { useContext } from 'react';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "react-bootstrap";
import { Tooltip } from "src/Components/Common/Tooltip/Tooltip";
import { Popover } from "../../../../Common/Popover";
import ThemeContext from 'src/Contexts/Theme/ThemeContext';

interface PredictorsTemplateTableRowI {
    id: string,
    label: string | JSX.Element,
    tooltipLabel?: string | JSX.Element,
    score?: any,
    conclution?: string | JSX.Element,
    detail?: string | JSX.Element,
    info?: {
        title: string,
        body: string
    }
}
interface PredictorsTemplateTableI {
    rowData: PredictorsTemplateTableRowI[],
    tableStyle?: React.CSSProperties,
    labelWidth?: string,
    noScore?: boolean,
    noConclution?: boolean,
    noDetail?: boolean,
    noInfo?: boolean,
}
export default function PredictorsTemplateTable(props: PredictorsTemplateTableI) {
    const rows = (props.rowData ?? []).map((x: PredictorsTemplateTableRowI, i: number) => {

        return (
            <tr key={i} style={{ wordBreak: "break-word", fontSize: "12px" }}>
                <td style={{ color: "dimgray", wordWrap: "break-word", width: props.labelWidth ?? "100px", }}>
                    {
                        x.label ?
                            x.tooltipLabel ?
                                <Tooltip label={x.tooltipLabel}>
                                    <span>
                                        {x.label}
                                    </span>
                                </Tooltip> :
                                x.label
                            : ""
                    }
                </td>
                {!props.noScore &&
                    <td style={{ textAlign: "right", width: "50px" }}>
                        {x.score ?? ""}
                    </td>
                }
                {!props.noConclution &&
                    <td style={{ textAlign: "center", width: "35px", paddingTop: "3px" }}>
                        {x.conclution ?? ""}
                    </td>}
                {!props.noDetail &&
                    <td style={{}}>
                        {x.detail ?? ""}
                    </td>
                }
                {!props.noInfo &&
                    <td style={{ width: "25px" }}>
                        {x.info &&

                            <Popover title={x.info.title}
                                content={x.info.body}
                                trigger={"hover"}
                                style={{ fontSize: "10px" }}>
                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "13px" }} />
                            </Popover>
                        }
                    </td>
                }
            </tr >

        )
    })

    return (
        <Table id={"PredictorsTable"} responsive hover style={{ border: "none", margin: 0, ...(props.tableStyle ?? {}) }}>
            <tbody style={{ fontSize: "13px" }}>
                {rows}
            </tbody>
        </Table>
    )
}

interface PredictorsTableTitleI {
    title: string | JSX.Element,
    styles?: React.CSSProperties
}
function PredictorsTableTitle(props: PredictorsTableTitleI) {
    const { theme } = useContext(ThemeContext);
    const titleStyles: React.CSSProperties = {
        margin: "5px",
        color: theme.grayHeader,
        fontWeight: "bold",
        fontSize: "15px",
        paddingBottom: "5px",
        ...(props.styles ?? {})
    }
    return (
        <h6 style={{ ...titleStyles }}>
            {props.title}
        </h6>
    )
}

export { PredictorsTableTitle }
export type { PredictorsTemplateTableRowI, PredictorsTemplateTableI }