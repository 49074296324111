import React from 'react';
import { Table } from 'react-bootstrap';
import "../../Common/table.css"
import { useState } from 'react';
import { StudyI } from '../../../Contexts/interfaces';
import Loader from '../../Common/Loader/Loader';
import { ModalCnvIgv } from 'src/Components/IGV/ModalCnv';
import { SampleIgvDataI } from 'src/Components/IGV/types';
import TableRow from './CnvTableRow';

interface CnvTableI {
    history: any,
    hugoGenes: any,
    study: StudyI,
    dataTable: any,
    loading: boolean,
    studyId: string
    handleReload(): void,
    handleSortByFilter(fieldToSort: "sample" | "gene" | "assessment"): void,
    rowRef: any,
    lastChildUpdated: any,
    setlastChildUpdated(updated: boolean): void,
}
export default function CnvTable(props: CnvTableI) {
    const [showModalIgv, setShowModalIgv] = useState<{ show: boolean, data?: SampleIgvDataI }>({ show: false, data: undefined });
    const handleCloseModalSamplesIgv = () => setShowModalIgv({ show: false, data: undefined });
    const handleShowModalSamplesIgv = (i: SampleIgvDataI) => {
        setShowModalIgv({
            show: true,
            data: {
                ...i,
                igvData: i.igvData ? {
                    ...i.igvData,
                    library: props.study.libraryPrepKit
                } : undefined
            }
        });
    }
    // const studyType = (props.study?.type ?? "").toLowerCase().trim()
    let rows: any = []
    if (!props.loading)
        rows = (props.dataTable ?? []).map((x: any, i: number) => {
            const getHugoGenes = () => {
                const thereIsGene = Object.keys(props.hugoGenes ?? {}).indexOf(x.gene) > -1
                if (thereIsGene)
                    return props.hugoGenes[x.gene]
                return false
            }
            if (i === props.dataTable.length - 1) setTimeout(() => { props.setlastChildUpdated(true) }, 10);
            return (
                <TableRow key={i}
                    actualRow={x}
                    study={props.study}
                    handleReload={props.handleReload}
                    history={props.history}
                    studyId={props.studyId}
                    hugoGene={getHugoGenes()}
                    rowRef={i === 0 ? props.rowRef : undefined}
                    handleShowModalSamplesIgv={handleShowModalSamplesIgv}
                />
            )
        })
    return (
        props.loading ?
            <Loader onContainer={true} type={'DNA'} /> :
            <>
                {(props.study?.hasBam ?? false) &&
                    (showModalIgv?.data?.chromosome &&
                        showModalIgv?.data?.position &&
                        showModalIgv?.data?.gen) &&
                    <ModalCnvIgv
                        caseId={`${props.study.id}`}
                        show={showModalIgv.show}
                        handleClose={handleCloseModalSamplesIgv}
                        chromosome={showModalIgv?.data?.chromosome}
                        position={showModalIgv?.data?.position}
                        gen={showModalIgv?.data?.gen}
                        // sampleData={showModalIgv.data?.sampleData}// VEEEEEEERR
                        igvData={showModalIgv.data?.igvData}
                    />
                }
                <Table responsive="md" className="selectTable" style={{ marginBottom: "-1px" }}>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
            </>
    )
}
