import React from 'react';
import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Urlify } from "../../../Helpers/Helpers";
import GensetService from "../../../Services/GensetService";
import DetailPanelGenesHeader from "./DetailPanelGenesHeader";
import DetailPanelGenesTable from "./DetailPanelGenesTable";
import Paginator from '../../Common/Paginator/Paginator';
import { LangContext } from "../../../Contexts/Lang";
import DetailPanelGenesWarningTable from "./DetailPanelGenesWarningTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "../../Common/Tooltip/Tooltip";
import { ModalWarning } from "../../Common/ModalWarning";
import FormInput from "../../Common/Form/FormInput";
import DetailLayout from "../../Layout/Detail/DetailLayout";
import PanelGenesContext from "../../../Contexts/PanelGenes/PanelGenesContext";
import Loader from "../../Common/Loader/Loader";
import CircleLabel from "../../Common/CircleLabel";
import CopyToClipboard from 'src/Components/Common/CopyToClipboard';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';

interface DetailPanelGenesI {
    history: any,
    panelGenesId: number,
    region: string,
}

export default function DetailPanelGenes(props: DetailPanelGenesI) {
    const { panel, dispatch: { getPanel, refreshPanels } } = useContext(PanelGenesContext);
    const { theme } = useContext(ThemeContext);
    const { dispatch: { translate } } = useContext(LangContext);

    const [tableData, settableData] = useState<any>();
    const [genesFilter, setgenesFilter] = useState<string>("")
    const [genesSelecteds, setgenesSelecteds] = useState<string[]>([])
    const [warningGenesSelecteds, setwarningGenesSelecteds] = useState<string[]>([])

    const [showProcessGene, setshowProcessGene] = useState<{ show: boolean, addGenesView: boolean }>({ show: false, addGenesView: false })
    const handleCloseProcessCase = () => setshowProcessGene({ ...showProcessGene, show: false });
    const handleShowProcessGene = (addGenesView?: boolean) => {
        if (addGenesView)
            setshowProcessGene({ show: true, addGenesView: addGenesView })
        else
            setshowProcessGene({ show: true, addGenesView: false })
    };

    const paginator = Paginator({
        reloadCall: () => { },
    })

    const GensetS = new GensetService()

    function handleGetPanelGene(id?: string) {
        getPanel(`${id ?? props.panelGenesId}`, props.region, 1, paginator.itemsPerPage);
    }
    useEffect(() => {
        if ((!panel.loaded && !panel.loading) || (panel.loaded && panel.data.panel.key !== props.panelGenesId)) {
            handleGetPanelGene()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const GH: any = panel.data?.panel?.genesHugo ?? {}
        if (genesFilter.trim().length < 2) {
            paginator.pageSize_set(Object.keys(GH).length)
            let td = Object.keys(GH)
                .filter((x: any, i: number) => {
                    return (
                        i >= ((paginator.actualPage) * paginator.itemsPerPage) &&
                        i < ((paginator.actualPage + 1) * paginator.itemsPerPage)
                    )
                })
                .map((x: string) => { return { name: x, ...GH[x] } })
            settableData(td);
        } else {
            if (Object.keys(GH).length > 0) {
                let allTableData = Object.keys(GH)
                    .filter((x: string) => {
                        const gf = genesFilter.trim().toUpperCase();
                        return (
                            (GH[x]?.approvedsymbol ?? "").indexOf(gf) >= 0 ||
                            (GH[x]?.synonyms ?? "").indexOf(gf) >= 0 ||
                            (GH[x]?.previoussymbols ?? "").indexOf(gf) >= 0
                        )
                    })
                    .map((x: string) => { return { name: x, ...GH[x] } })
                if (paginator.actualPage * paginator.itemsPerPage > allTableData.length) {
                    paginator.setActualPage(0)
                }
                const pageTableData = allTableData
                    .filter((x: any, i: number) => {
                        return (
                            i >= ((paginator.actualPage) * paginator.itemsPerPage) &&
                            i < ((paginator.actualPage + 1) * paginator.itemsPerPage)
                        )
                    })
                settableData(pageTableData);
                paginator.pageSize_set(allTableData.length)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panel, paginator.itemsPerPage, paginator.actualPage, genesFilter])

    function handleGenesSelecteds(id: string) {
        var selecteds = genesSelecteds
        if (selecteds.find((x: any) => x === id))
            setgenesSelecteds(selecteds.filter((x: any) => x !== id))
        else
            setgenesSelecteds([...selecteds, id])
    }
    function handleWarningGenesSelecteds(id: string) {
        var selecteds = warningGenesSelecteds
        if (selecteds.find((x: any) => x === id))
            setwarningGenesSelecteds(selecteds.filter((x: any) => x !== id))
        else
            setwarningGenesSelecteds([...selecteds, id])
    }
    const initModalDelete = { show: false, geneNames: [] }
    const [modalDelete, setmodalDelete] = useState<{ show: boolean, geneNames: string[] }>(initModalDelete);
    const handleCloseModalDelete = () => setmodalDelete(initModalDelete)
    const handleShowModalDelete = (geneNames: string[]) => {
        setmodalDelete({ show: true, geneNames: geneNames })
    }
    function handleDeleteGenes(genesId: string[]) {
        GensetS.delete_gene(`${props.panelGenesId}`, props.region, genesId)
            .then((r: any) => {
                if (r.status === 200) {
                    paginator.setActualPage(0)
                    handleGetPanelGene();
                    refreshPanels();
                }
            })
    }
    function handleDeleteSelectedGenes(unknownGenes: boolean = false) {
        if (unknownGenes) {
            handleShowModalDelete(warningGenesSelecteds)
        }
        else handleShowModalDelete(genesSelecteds)
    }

    // console.log(panel?.data?.panel?.geneNames)
    const allPanelGenes: string = panel?.data ? panel?.data?.panel?.geneNames.join(", ").slice(0, -2) : "";
    // console.log(allPanelGenes)
    const allPanelGenesCutted = allPanelGenes.length > 1000 ? `${allPanelGenes.substring(0, 1000)}...` : allPanelGenes;
    // console.log(allPanelGenesCutted)
    return (
        <DetailLayout DetailHeader={
            <DetailPanelGenesHeader history={props.history}
                actualGene={panel?.data?.panel}
                setReload={(id?: string) => {
                    handleGetPanelGene(id);
                    refreshPanels();
                }}
                showProcessGene={showProcessGene.show}
                handleCloseProcessCase={handleCloseProcessCase}
                handleShowProcessGene={handleShowProcessGene}
                addGenesView={showProcessGene.addGenesView} />
        }>
            <>
                {
                    modalDelete.show &&
                    <ModalWarning labelTitle={translate('common.confirm')}
                        label={translate("genesPanel.confirmDeleteGen")}
                        labelButton={translate('common.confirm')}
                        showState={modalDelete.show}
                        handleClose={handleCloseModalDelete}
                        onClick={() => handleDeleteGenes(modalDelete.geneNames)}
                    />
                }
                <div style={{ backgroundColor: theme.backgroundSecondary, border: "solid 1px #DDD", width: "100%", padding: "15px" }}>
                    <label style={{ color: "gray", margin: 0 }}>{panel?.data?.panel?.description ?? "-"} </label>&nbsp;
                    <hr style={{ margin: "4px 0" }} />
                    <label style={{ fontWeight: "bold", }}>{translate("common.author")} : </label>&nbsp;{panel?.data?.panel?.author ?? "-"}<br />
                    <label style={{ fontWeight: "bold", }}>{translate("common.references")} : </label>&nbsp;
                    <Urlify text={panel?.data?.panel?.references ?? ""} /><br />
                    <hr style={{ marginTop: 0 }} />
                    <span style={{ fontWeight: "lighter", fontSize: 9, color: "gray", display: "inline-block", lineHeight: 1.4 }}>
                        <span style={{ fontWeight: "bold" }}>PANEL : </span>&nbsp;
                        {allPanelGenesCutted}
                        <CopyToClipboard valueToCopy={allPanelGenes} fontSize={"8px"} buttonStyles={{marginTop: -3}} />
                    </span>&nbsp;
                    <br />
                </div>
                <>
                    {
                        panel?.data?.panel?.unknownGeneNames && panel?.data?.panel?.unknownGeneNames.length > 0 &&
                        <>
                            <Card style={{ borderRadius: "0px" }}>
                                <Card.Header style={{ padding: "5px" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>
                                            <label style={{ fontSize: "17px", color: theme.grayHeader, fontWeight: "bold", marginBottom: 0 }}>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                {translate("genesPanel.genesWithWarning")}
                                                &nbsp;
                                            </label>
                                            <CircleLabel
                                                label={(panel?.data?.panel?.unknownGeneNames ?? []).length}
                                                backColor={theme.grayHeader} />
                                            &nbsp;&nbsp;
                                        </div>
                                        <div style={{ float: "right" }}>
                                            <Button variant="outline-light"
                                                style={{ border: "none", borderRadius: "50px", padding: "5px 7px 2px" }}
                                                onClick={() => { handleDeleteSelectedGenes(true) }}>
                                                <FontAwesomeIcon icon={faTrash} style={{ border: "none", color: "gray", fontSize: "16px" }} />&nbsp;
                                            </Button>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body style={{ padding: "0px" }}>
                                    <DetailPanelGenesWarningTable warningGenes={panel?.data?.panel?.unknownGeneNames}
                                        genesSelecteds={warningGenesSelecteds}
                                        handleGenesSelecteds={handleWarningGenesSelecteds}
                                        handleDeleteGen={handleShowModalDelete}
                                    />
                                </Card.Body>
                            </Card>
                            <br />
                        </>
                    }
                    <Card style={{ borderRadius: "0px" }}>
                        <Card.Header style={{ padding: "5px" }}>
                            <Row style={{ justifyContent: "space-between" }}>
                                <Col sm={11} style={{ fontSize: "17px", color: theme.grayHeader, fontWeight: "bold", marginBottom: 0, display: "flex" }}>
                                    <Tooltip label={translate("genesPanel.addGenes")}>
                                        <Button variant={"outline-success"}
                                            style={{ borderRadius: "50px", padding: "2px 6px", border: "none", margin: "0 2px 3px 0", height: "fit-content" }}
                                            onClick={() => handleShowProcessGene(true)}>
                                            <FontAwesomeIcon icon={faPlus}
                                                style={{ border: "none", marginTop: "3px", fontSize: "17px" }}
                                                data-tip="Add case"
                                            />
                                        </Button>
                                    </Tooltip>
                                    &nbsp;
                                    <label style={{ margin: 0, alignSelf: "center" }}>
                                        Genes
                                    </label>
                                    &nbsp;
                                    <CircleLabel style={{ alignSelf: "center" }}
                                        label={(panel?.data?.panel?.sortedGenes ?? []).length}
                                        backColor={theme.grayHeader} />

                                    &nbsp;&nbsp;
                                    <FormInput type={"text"}
                                        formLabelStyle={{ marginBottom: "3px" }}
                                        formControlStyle={{ minWidth: "290px", fontSize: "10px" }}       // onKeyDown={(e: any) => handleEnter(e)}
                                        value={genesFilter}
                                        placeHolder={`${translate("common.filterBy")}: ${translate("genesPanel.approvedSymbol")}, ${translate("common.previoussymbols")}, ${translate("common.synonyms")}`}
                                        onChange={(e: any) => {
                                            setgenesFilter(e.target.value)
                                        }} />
                                </Col>
                                <Col sm={1} style={{}}>
                                    <Button variant="outline-light"
                                        style={{ border: "none", borderRadius: "50px", padding: "5px 7px 2px", float: "right", maxHeight: "30px" }}
                                        onClick={() => { handleDeleteSelectedGenes() }}>
                                        <FontAwesomeIcon icon={faTrash} style={{ border: "none", color: "gray", fontSize: "16px" }} />&nbsp;
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body style={{ padding: "0px" }}>
                            {panel.loading ?
                                <Loader onContainer={true} type={'DNA'} /> :
                                (!panel.loading && (tableData ?? []).length === 0) ?
                                    <div>
                                        <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                            {translate('home.noCasesFound')}
                                        </p>
                                    </div> :
                                    <DetailPanelGenesTable panelGenes={tableData ?? []}
                                        panelGenesLoading={panel.loading}
                                        genesSelecteds={genesSelecteds}
                                        handleGenesSelecteds={handleGenesSelecteds}
                                        handleDeleteGen={handleShowModalDelete}
                                    />
                            }
                        </Card.Body>
                        {(panel?.data?.panel?.geneNames ?? []).length > 0 ?
                            <Card.Footer style={{ padding: "6px" }}>
                                {paginator.component}
                            </Card.Footer>
                            : <></>}
                    </Card>
                </>
            </>
        </DetailLayout >
    )
}

