import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "./Tooltip/Tooltip";
import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";

interface DropdownToolkitI {
    icon: any,
    tooltip: string,
    items: {
        label: any,
        href?: string
        onClick?: any,
        disabled?: boolean,
        itemText?: boolean
    }[],
    disabled?: boolean,
}

export default function DropdownToolkit(props: DropdownToolkitI) {
    return (
        <Tooltip label={props.tooltip}>
            <DropdownButton
                // show={show}
                as={ButtonGroup}
                variant="outline-secondary" id="bg-nested-dropdown"
                disabled={props.disabled ?? false}
                style={{ padding: "0px", borderColor: "lightgray" }}
                title={
                    <FontAwesomeIcon icon={props.icon}
                        style={{ margin: "0 2px 0 2px", fontSize: "15px" }}
                        data-tip="Add case"
                    />
                }>
                {props.items.map((x: any, i: number) => {
                    if (x.itemText)
                        return (
                            <Dropdown.ItemText key={i}
                                style={{ borderRadius: "0", fontSize: "12px" }}
                                onClick={() => { if (x.onClick) x.onClick() }}
                            >
                                {x.label}
                            </Dropdown.ItemText>
                        )
                    return (
                        <Dropdown.Item key={i}
                            style={{ borderRadius: "0", fontSize: "12px" }}
                            variant="secondary"
                            disabled={x.disabled ?? false}
                            href={x.href ?? undefined}
                            onClick={() => { if (x.onClick) x.onClick() }}
                            target="_blank"
                        >
                            {x.label}
                        </Dropdown.Item>
                    )
                })}
            </DropdownButton >
        </Tooltip >
    )
}

