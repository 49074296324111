import { createContext } from "react";
import { AsyncPagesI, AsyncDataI, PanelGenesI, } from "../interfaces";

interface FiltersPanelGenesI {
    panelnameSearch?: string,
    genenameSearch?: string,
    sortColumn?: string,
}
interface ContextPanelGenesStatesI {
    panel: AsyncDataI & { data: PanelGenesI | null },                  //TODO especificar data con interface
    panels: AsyncDataI & {
        data: null | (AsyncPagesI & {
            results: PanelGenesI[]
        })
    },                 //TODO especificar data con interface
    filters: FiltersPanelGenesI,
    selecteds: number[],

}
interface ContextPropsPanelGenesI extends ContextPanelGenesStatesI {
    dispatch: {
        getPanel: (id: string, region: string, page: number, pageSize: number) => Promise<void>;
        getPanels: (filters?: FiltersPanelGenesI, pageNumber?: number, pageSize?: number) => Promise<void>;
        refreshPanels: () => void;
        setFilters: (key: string, value: string) => boolean;
        cleanFilters: () => void;
        handleSelecteds: (id: string) => void;
        handleSelectAll: () => void;
    }
}

const PanelGenesContext = createContext({} as ContextPropsPanelGenesI);

export default PanelGenesContext;
export type { FiltersPanelGenesI, ContextPanelGenesStatesI }