import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';

export default class GroupServices extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "group/";
    }
    async edit(studyId: number, groupId: number, name: string, description: string, varsIds: string, count: number): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}edit`

        const config = {
            ...this.authHeader,
            params: {
                domainId: this.user?.domain,
                studyId: studyId,
                userId: this.user?.id,
                groupId: groupId,
                name: name,
                description: description,
                vars: varsIds,
                count: count,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }

    async delete(studyId: number, groupId: number): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}delete`
        const config = {
            ...this.authHeader,
            params: {
                studyId: studyId,
                domainId: this.user?.domain,
                groupId: groupId,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }
    async create(studyId: string, description: string, groupName: string, variantCount: number, variantsToGroup: number[]): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}create`
        const data = {
            domainId: `${this.user?.domain}`,
            userId: `${this.user?.id}`,
            studyId: `${studyId}`,
            groupDescription: description,
            groupName: groupName,
            variantCount: `${variantCount}`,
            variantsToGroup: `${variantsToGroup}`
        }
        return await axios.post(url, data, this.authHeader)
    }
    async add_to_group(groupId: string, variantsToGroup: number[]): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}addVariantsToGroup`
        const data = {
            groupId: groupId,
            addVariantCount: variantsToGroup.length.toString(),
            addVariantsToGroup: variantsToGroup.toString()
        }
        return await axios.post(url, data, this.authHeader)
    }
}