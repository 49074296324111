import React from 'react';
import { useContext } from "react";
import { LangContext } from "../../../Contexts/Lang";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import DomainForm from "./DomainForm";
import ThemeContext from "../../../Contexts/Theme/ThemeContext";

interface EditDomainI {
    id: string,
    name: string,
    description: string,
    enable: boolean
}
interface ProcessDomainI {
    history: any,
    show: boolean,
    editDomain?: EditDomainI,
    handleClose(): void,
    handleReload?(): void,
}
export default function ProcessDomain(props: ProcessDomainI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    
    return (
        <Modal show={props.show}
            onHide={props.handleClose}
        // dialogClassName="my-modal"
        >
            <Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: theme.grayHeader, fontWeight: "bold", color: theme.backgroundPrimary, fontSize: "24px" }}>
                {props.editDomain ?
                    <div style={{ padding: "0px 10px" }}>
                        <FontAwesomeIcon icon={faEdit} style={{ color: theme.edit, fontSize: "20px", marginRight: "10px" }} />
                        {translate('domain.editDomain')}
                    </div> :
                    <div style={{ padding: "0px 10px" }}>
                        <FontAwesomeIcon icon={faPlus} style={{ color: theme.success, fontSize: "20px", marginRight: "10px" }} />
                        {translate('domain.createDomain')}
                    </div>
                }

            </Modal.Header>
            <Modal.Body style={{ padding: "0px" }}>
                <DomainForm history={props.history}
                    editDomain={props.editDomain}
                    handleClose={props.handleClose}
                    handleReload={props.handleReload}
                />
            </Modal.Body>
        </Modal >
    )
}

export type { EditDomainI }
