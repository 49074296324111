import React from 'react';
import { useContext, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { LangContext } from "../../../../Contexts/Lang";
import Loader from "../../../Common/Loader/Loader";
import VariantsTable from "../VariantsTable/VariantsTable";
import Paginator from "../../../Common/Paginator/Paginator";
import PhenotypesTable from "./QueryGenesPhenotypesTable";
import RegionsTable from "./QueryGenesRegionTable";
import { variantsOrderByOptions, VariantsOrderByValueT } from "../../../../utils/BioFunctionalData";
import QueryGenesPanel from "./QueryGenesPanel";
import QueryContext, { ColumnIdT } from "../../../../Contexts/Query/QueryContext";
import QueryTitle from "../QueryTitle";
import { useWindowSize } from "../../../../Helpers/WindowsSizeHelper";
type bodyType = "variants" | "regions" | "phenotypes"
interface QueryGenesI {
    history: any,
    caseId: any,
    geneName: any,
    variantId?: any
}
export default function QueryGenes(props: QueryGenesI) {
    const { genesVariants, dispatch: { getGenesVariants, SetOrderBySS, refreshQueryVariants, refreshVariant } } = useContext(QueryContext);
    const { dispatch: { translate } } = useContext(LangContext);

    const [body, setbody] = useState<bodyType>("variants")
    const [variantsSelecteds, setvariantsSelecteds] = useState<string[]>([])
    const [variants, setvariants] = useState<any[]>([])
    const [regionSelected, setregionSelected] = useState<any>()

    const isMitochondrial = genesVariants.data?.study?.subtype.toUpperCase().trim() === "MT-DNA"
    const isSomatic = genesVariants.data?.study?.subtype.toUpperCase().trim() === "SOMATIC"
    let orderByOpt = variantsOrderByOptions(translate)
    const initOrderByOptions = () => {
        if (isMitochondrial) {
            orderByOpt = orderByOpt.filter((x: any) => x.value !== "infoColumnData.INTERVAR_VEREDICT" && x.value !== "infoColumnData.GNOMAD_GENOME_ALL" && x.value !== "infoColumnData.GNOMAD_EXOME_ALL")
        }
        let localOrderBy = localStorage.getItem(`query_orderBy`);
        if (localOrderBy) localOrderBy = JSON.parse(localOrderBy)
        let initOrderBy: any = [orderByOpt[0]]
        if (!localOrderBy || !Array.isArray(localOrderBy)) {
            SetOrderBySS([!isMitochondrial ? orderByOpt[0].value : ""])
        } else {
            initOrderBy = (localOrderBy ?? []).map((x: any) => orderByOpt.find((y: any) => y.value === x))
        }
        return initOrderBy
    }
    const [orderBy, setorderBy] = useState<{ label: string, value: VariantsOrderByValueT }[]>(initOrderByOptions())
    const handleOrderBy = (value: any) => {
        if (value && value.length > 3) {
            const newOrder = [...value.filter((x: any, i: number) => i !== 0)]
            setorderBy(newOrder)
            SetOrderBySS(newOrder.map((x: any) => x.value))
        }
        else {
            setorderBy(value)
            SetOrderBySS(value.map((x: any) => x.value))
        }
    }

    const [reload, setreload] = useState(false)

    const paginator = Paginator({
        id: "querysGenes",
        reloadCall: () => { setreload(true) },
        pageSizeOptions: [25, 50, 100]
    })

    const launchGenesVariantTableUpdate = () => {
        getGenesVariants(
            props.geneName,
            props.variantId,
            paginator.actualPage + 1,
            100000, orderBy && orderBy[0] ? orderBy?.map((x: any) => x.value).join(", ") : undefined)
    }

    useEffect(() => {
        if (reload) {
            launchGenesVariantTableUpdate()
            setreload(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    useEffect(() => {
        if ((!genesVariants.loaded && !genesVariants.loading) ||
            (props.geneName !== genesVariants?.data?.gene?.geneName) ||
            genesVariants.refresh)
            launchGenesVariantTableUpdate()
        if (genesVariants.refresh && genesVariants.loaded && !genesVariants.loading) {
            refreshQueryVariants();
            refreshVariant()
        }
        // setTimeout(() => { removeRefreshQuery(); }, 400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])
    useEffect(() => {
        setorderBy(initOrderByOptions())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [genesVariants])

    useEffect(() => {
        if (genesVariants.loaded) {
            (genesVariants.data?.gene?.variants ?? []).map((x: any) => {
                const firstVal = x.value1[0],
                    position = `${firstVal.chromosome}:${firstVal.position}`,
                    gene = firstVal.infoColumnData?.GENE_NAME ?? "--",
                    impact = firstVal.infoColumnData?.IMPACT ?? "--",
                    effect = firstVal.infoColumnData?.EFFECT ?? "--",
                    gnomad_exome = firstVal.infoColumnData?.GNOMAD_EXOME_ALL ?? "--",
                    gnomad_genome = firstVal.infoColumnData?.GNOMAD_GENOME_ALL ?? "--"
                return {
                    ...x, position, gene, impact, effect, gnomad_exome, gnomad_genome
                }
            })
            const variants = (genesVariants.data?.gene?.variants ?? [])
                .filter((x: any, i: number) =>
                    i >= paginator.actualPage * paginator.itemsPerPage &&
                    i < (paginator.actualPage + 1) * paginator.itemsPerPage
                )
            setvariants(variants)
            if (Object.keys(genesVariants.data?.gene?.regions ?? {}).length > 0) {
                const firstElementName = Object.keys(genesVariants.data?.gene?.regions)[0]
                if ((genesVariants.data?.gene?.regions[firstElementName] ?? []).length > 0) {
                    setregionSelected({
                        name: firstElementName,
                        ...genesVariants.data?.gene?.regions[firstElementName][0]
                    })
                }
            }
            if (genesVariants.loaded && !genesVariants.loading) {
                paginator.pageSize_set(genesVariants.data?.gene?.variants?.length)
                paginator.setActualPage(genesVariants.data.pageNumber - 1)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [genesVariants, paginator.actualPage, paginator.itemsPerPage, orderBy])

    function handleVariantSelecteds(id: string, all: boolean = false) {
        if (all) {
            if (variantsSelecteds.length === (variants ?? []).length) {
                setvariantsSelecteds([])
            } else {
                setvariantsSelecteds((variants ?? [])
                    .map((x: any) => x.value1
                        .map((x: any) => x.id).join(","))
                )
            }
        } else {
            if (variantsSelecteds.find((x: any) => x === id))
                setvariantsSelecteds(variantsSelecteds.filter((x: any) => x !== id))
            else
                setvariantsSelecteds([...variantsSelecteds, id])
        }
    }

    const loaderElement = (
        <div style={{
            display: "block",
            minHeight: "150px",
            background: "linear-gradient(to top, rgba(0,0,0, 0), rgba(255,255,255,1), rgba(255,255,255,1))",
            width: "100%",
            paddingTop: "20px"
        }}>
            <Loader onContainer={true} type={'DNA'} />
        </div>
    )

    const tableTitles: { order: number, type: ColumnIdT, title: string, checked: boolean }[] = [
        {
            order: 1,
            type: "variant",
            title: translate("common.variant"),
            checked: true,
        },
        {
            order: 2,
            type: "consecuence",
            title: translate("common.consecuence"),
            checked: true,
        },
        {
            order: 3,
            type: "impactoEfecto",
            title: translate("common.impactEffect"),
            checked: true,
        },
        {
            order: 4,
            type: "classification",
            title: translate("common.classification"),
            checked: true,
        },
        {
            order: 5,
            type: "mitoMap",
            title: "MitoMap",
            checked: true,
        },
        {
            order: 6,
            type: "frecuence",
            title: translate("common.frequency"),
            checked: true,
        },
        {
            order: 7,
            type: "evidence",
            title: translate("common.evidence"),
            checked: true,
        },
        {
            order: 8,
            type: "sampleInfo",
            title: translate("casesQuery.sampleInfo"),
            checked: true,
        }
    ]
    const elementRef: any = useRef(null);
    const { windowSize } = useWindowSize()
    const [headerHeight, setheaderHeight] = useState<number>(0)
    useEffect(() => {
        setTimeout(() => {
            setheaderHeight(elementRef?.current?.clientHeight + 10)
            // const element = document.getElementById("QueryGenes_div")
            // if (element) {
            // Ver con Anita, Si no funciona probar con lo siguiente 
            // let colHeight = element.getBoundingClientRect().width ? (`${element.getBoundingClientRect().width}`.split(".")[0] + "px") : "100px"
            // console.log(element)
            // console.log("colHeight")
            // console.log(colHeight)
            // console.log("elementRef?.current?.clientHeight")
            // console.log(elementRef?.current?.clientHeight)
            // }
        }, 1);
    }, [windowSize, genesVariants.data, body])

    const TableBodyRef: React.MutableRefObject<any> = useRef();
    const TableHeadersRef: React.MutableRefObject<any> = useRef();
    const handleScrollTableBody = (scroll: any): void => {
        const thScrollLeft = TableHeadersRef?.current?.scrollLeft;
        if (thScrollLeft || thScrollLeft === 0) TableHeadersRef.current.scrollLeft = scroll.target.scrollLeft;
    };
    const handleScrollHeadersTable = (scroll: any): void => {
        const tbScrollLeft = TableBodyRef?.current?.firstElementChild?.scrollLeft;
        if (tbScrollLeft || tbScrollLeft === 0) TableBodyRef.current.firstElementChild.scrollLeft = scroll.target.scrollLeft;
    };

    const getBody = () => {
        const variantsBody = (
            <div style={{ paddingTop: "15px" }}>
                <Card style={{
                    borderRadius: "0px",
                    width: "100%",
                    boxShadow: "rgb(200 200 200) 0px 5px 4px",
                    marginBottom: "25px"
                }}>
                    <Card.Body style={{ padding: "0px", width: "100%" }}>
                        <VariantsTable history={props.history}
                            QVTType={{ type: "queryGenes", data: { variantIds: genesVariants?.data?.variantIds } }}
                            studyId={genesVariants.data?.study?.id}
                            study={genesVariants.data?.study}
                            queryVariants={{ ...genesVariants, data: { variants: variants } }}
                            listaCigotas={genesVariants.data.listaCigotas}
                            comments={genesVariants.data?.study?.comments ?? {}}
                            studyType={genesVariants.data?.study?.type.toLowerCase().trim()}
                            isMitochondrial={isMitochondrial}
                            isSomatic={isSomatic}
                            tableTitles={tableTitles}
                            variantSelecteds={variantsSelecteds}
                            handleVariantSelecteds={handleVariantSelecteds}
                            handleGetQueryVariant={(pageNumber?: number, pageSize?: number, then?: any) => {
                                setreload(true)
                            }}
                            tableBodyRef={TableBodyRef}
                            handleScrollTableBody={handleScrollTableBody}
                            handleAftercomment={() => { refreshQueryVariants(); refreshQueryVariants(); }}
                        />
                    </Card.Body>
                    <Card.Footer style={{ padding: "10px 10px 10px 15px" }}>
                        {paginator.component}
                    </Card.Footer>
                </Card>
            </div>
        )
        switch (body) {
            case "variants": return variantsBody;
            case "regions": return (
                <div style={{ paddingTop: "15px" }}>
                    <RegionsTable regions={regionSelected?.regions ?? []}
                        study={genesVariants.data?.study}
                    />
                </div>)
            case "phenotypes": return (
                <div style={{ paddingTop: "15px" }}>
                    <PhenotypesTable phenotypes={genesVariants.data?.gene?.descriptor?.diseases ?? []} />
                </div>
            )
        }
    }

    return (
        <>
            <div id="QueryGenes_div"
                style={{
                    paddingTop: "20px",
                    marginLeft: "-24px",
                    position: "fixed",
                    zIndex: 1,
                    width: "calc(100vw - 2px)",
                    background: "linear-gradient(rgb(244, 244, 244, 1), rgb(244, 244, 244, 1), rgba(244, 244, 244, 0.1))"
                }}>
                <div style={{ margin: "0 25px" }}
                    ref={elementRef}
                >
                    <QueryTitle history={props.history}
                        study={genesVariants.data?.study ?? {}} />
                    <hr style={{ margin: 0 }} />
                    <div style={{
                        margin: "0",
                        minWidth: "100%",
                        backgroundColor: "white",
                        width: "100%",
                        boxShadow: "rgb(124 124 124 / 35%) 0px 5px 10px 0px"
                    }}>
                        {!genesVariants.loaded && genesVariants.loading ?
                            loaderElement :
                            <>
                                <QueryGenesPanel
                                    history={props.history}
                                    study={genesVariants.data?.study}
                                    gene={genesVariants.data?.gene}
                                    setbody={setbody}
                                    phenotypes={genesVariants.data?.gene?.descriptor?.diseases}
                                    regions={genesVariants.data?.gene?.regions}
                                    regionSelected={regionSelected}
                                    setregionSelected={setregionSelected}
                                    variantsSelecteds={variantsSelecteds}
                                    orderBy={orderBy}
                                    isMitochondrial={isMitochondrial}
                                    handleOrderBy={handleOrderBy}
                                    handleReload={() => {
                                        refreshQueryVariants();
                                        setreload(true)
                                    }}
                                    handleReloadOnGoBack={() => {
                                        console.log("callingRefr")
                                        refreshQueryVariants();
                                        refreshVariant()
                                    }}
                                    queryVariants={{ ...genesVariants, data: { variants: variants } }}
                                    tableTitles={tableTitles}
                                    handleVariantSelecteds={handleVariantSelecteds}
                                    tableHeadersRef={TableHeadersRef}
                                    handleScrollHeadersTable={handleScrollHeadersTable}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
            <div style={{
                marginTop: `${(headerHeight ?? 0)}px`,
                width: "calc(100vw - 50px)"
            }}>
                {!genesVariants.loading && genesVariants.loaded && getBody()}
            </div>
        </ >
    )
}

export type { bodyType }