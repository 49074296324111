import React from 'react';
import { useContext, useState } from 'react';
// @ts-ignore
import Select from 'react-select'
import { Navbar, Container, Button, Dropdown, Col, Nav, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faLanguage, faSignOutAlt, IconDefinition, faUsers, faUserEdit, faBook } from '@fortawesome/free-solid-svg-icons';
import "./layout.css"
import { LangContext } from '../../Contexts/Lang';
import UserContext from '../../Contexts/User/UserContext';
import ThemeContext from '../../Contexts/Theme/ThemeContext';

interface NavbarBPropsI {
	history: any
}
export default function NavbarB(props: NavbarBPropsI) {
	const { theme } = useContext(ThemeContext);
	const { dispatch: { logout } } = useContext(UserContext);
	if (localStorage.getItem("_Buser") === null) { logout(); props.history.push("/login") }
	return (
		<Navbar className="navbar">
			<Button variant="outline-danger"
				style={{ color: theme.redBitgenia, border: "none", padding: "10px 35px 5px 30px", boxShadow: "none", borderRadius: "0" }}>
				<div className="navbar2">
					<Container>
						<Navbar.Brand href="#home">
						</Navbar.Brand>
					</Container>
				</div>
			</Button>
			<Nav></Nav>
			<UserDropdown history={props.history} />
		</Navbar>
	)
}
interface UserDropdownPropsI {
	history: any
}
function UserDropdown(props: UserDropdownPropsI) {
	const { theme } = useContext(ThemeContext);
	const { user, dispatch: { logout } } = useContext(UserContext);
	const { actualLang, dispatch: { translate, setLanguage } } = useContext(LangContext);
	const [show, setShow] = useState(false);
	const setClose = () => setShow(false)
	// const setOpen = () => setShow(true)

	const langOptions = [{ label: translate(`home.languages.en`), value: "EN" },
	{ label: translate(`home.languages.es`), value: "ES" }]
	const langValue = langOptions.filter((x: any) => x.value === actualLang.language)
	function handleLanguage(e: any) {
		setLanguage(e.value)
	}
	function logOut() {
		setClose()
		logout()
		props.history.push("/login")
	}

	return (
		<>
			<Dropdown alignRight={true} show={show}>
				<Dropdown.Toggle variant="outline-danger" id="dropdown-basic"
					onClick={() => setShow(!show)}
					style={{ border: "none", padding: "10px 20px", boxShadow: "none", borderRadius: "0" }}>
					<span style={{
						borderRadius: "50px",
						border: "solid 2px",
						color: theme.backgroundPrimary,
						display: "inline-block",
						width: "30px",
						fontWeight: "bold",
						height: "30px",
						fontSize: "14px",
						backgroundColor: user?.data?.color ?? "#DC3545",
						lineHeight: "27px"
					}}>
						{(user.data?.username ?? "C")[0].toUpperCase()}
					</span>
				</Dropdown.Toggle>

				<Dropdown.Menu style={{ minWidth: "200px", borderRadius: "0" }} >
					<DropdownItem icon={faUserCircle}
						iconFontSize={"19px"}
						onClick={() => { setClose(); props.history.push("/user"); }}
						content={<>
							<p style={{ color: "gray", fontSize: "12px", margin: 0, fontWeight: "bold" }}>
								{user.data?.username}
							</p>
							<p style={{ color: "gray", fontSize: "12px", margin: 0 }}>
								[ {user.data?.fullName} ]
							</p>
						</>}
					/>
					<DropdownItem icon={faUserEdit}
						iconFontSize={"15px"}
						onClick={() => { setClose(); props.history.push("/user/edit"); }}
						content={<>
							<p style={{ color: "gray", fontSize: "12px", margin: 0 }}>
								{translate("user.editProfile")}
							</p>
						</>}
					/>

					<Dropdown.Divider />
					{
						(user.data?.rolestoString ?? "").trim().toUpperCase() === "ROLE_ADMIN" &&
						<DropdownItem icon={faUsers}
							iconFontSize={"17px"}
							onClick={() => { setClose(); props.history.push("/users"); }}
							content={translate('user.editProfile')}
						/>
					}
					<DropdownItem icon={faLanguage}
						iconFontSize={"20px"}
						content={<Select options={langOptions}
							style={{ widht: "120px" }}
							captureMenuScroll={false} // este parametro es solo para eliiminar [violation] warning
							// onKeyDown={checkKeyPress}
							onChange={handleLanguage}
							value={langValue}
							theme={th => ({
								...th,
								borderRadius: 0,
								colors: {
									...th.colors,
									primary25: 'dangerlight',
									primary: 'gray',
								},
							})}
						/>}
					/>
					<DropdownItem icon={faBook}
						iconFontSize={"17px"}
						target={"blank"}
						href={"https://drive.google.com/file/d/1YbJXRItf78v47FhSreBdhe8kOfEYO0ix/view?usp=sharing"}
						// onClick={() => { setClose(); props.history.push("https://www.google.com"); }}
						content={
							// <a id="goToUserManual"
							//     href={`https://drive.google.com/file/d/1YbJXRItf78v47FhSreBdhe8kOfEYO0ix/view?usp=sharing`}
							//     target="_blank" rel="noreferrer"
							//     style={{ color: "unset", textDecoration: "none" }}>
							translate('user.userManual')
							// </a>
						}
					/>
					{/* <Dropdown.Divider />
					<DropdownItem icon={faBook}
						iconFontSize={"17px"}
						color={"darkgray"}
						onClick={() => { setClose(); props.history.push("/changelog"); }}
						target={"blank"}
						// href={"https://drive.google.com/file/d/1YbJXRItf78v47FhSreBdhe8kOfEYO0ix/view?usp=sharing"}
						// onClick={() => { setClose(); props.history.push("https://www.google.com"); }}
						content={
							<>Changelog</>
							// <a id="goToUserManual"
							//     href={`https://drive.google.com/file/d/1YbJXRItf78v47FhSreBdhe8kOfEYO0ix/view?usp=sharing`}
							//     target="_blank" rel="noreferrer"
							//     style={{ color: "unset", textDecoration: "none" }}>
							// translate('user.userManual')
							// </a>
						}
					/> */}
					<Dropdown.Divider />
					<DropdownItem icon={faSignOutAlt}
						iconFontSize={"17px"}
						content={translate('user.close-session')}
						onClick={() => logOut()}
					/>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}

interface DropdownItemI {
	icon: IconDefinition,
	content: string | JSX.Element,
	iconFontSize?: string,
	href?: string,
	target?: string,
	onClick?(): void,
	color?: string,
}
function DropdownItem(props: DropdownItemI) {
	const { theme } = useContext(ThemeContext);
	return (
		<Dropdown.Item variant="secondary"
			href={props.href}
			target={props.target}
			style={{ minWidth: "215px", borderRadius: "0", backgroundColor: theme.backgroundPrimary }}
			onClick={(e: any) => {
				if (props.href === undefined) e.preventDefault();
				if (props.onClick) props.onClick();
			}}
		>
			<Row>
				<Col sm={2} style={{
					display: "flex",
					justifyContent: "center",
					alignSelf: "center"
				}}>
					<FontAwesomeIcon icon={props.icon}
						style={{
							border: "none",
							color: props.color ?? "gray",
							fontSize: props.iconFontSize ?? "20px"
						}} />
				</Col>
				<Col sm={10} style={{ padding: "0 8px 0 6px" }}>
					{props.content}
				</Col>
			</Row>
		</Dropdown.Item>
	)
}
