import React from 'react';
import { faCalendarDay, faEnvelope, faUser, faCheck, faTimes, faUsers, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Card } from "react-bootstrap";
import { LangContext } from "../../../Contexts/Lang";
import CopyToClipboard from "../../Common/CopyToClipboard";
import { Tooltip } from "../../Common/Tooltip/Tooltip";

interface CardReportedByI {
    institution?: string,
    email?: string,
    contact?: string,
    createdDate?: string,
    shared?: string
}
export default function CardReportedBy(props: CardReportedByI) {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <Tooltip label={translate("common.reportedBy")}>
            <Card style={{ backgroundColor: "#FDFDFD", marginBottom: "15px" }}>
                <Card.Body style={{ padding: "10px 20px", color: "dimgray", fontSize: "13px" }}>
                    <Tooltip label={translate("common.institution")}>
                        <span style={{ fontSize: "17px" }}>
                            {(props.institution ?? " - ")}<br />
                        </span>
                    </Tooltip>
                    <hr style={{ margin: "2px 0" }} />
                    {props.email ?
                        <div style={{ display: "flex", alignItems: "center", fontSize: "12px", margin: "2px" }}>

                            <FontAwesomeIcon icon={faEnvelope} style={{ border: "none", color: "gray", fontSize: "12px" }} />
                            &nbsp;&nbsp;&nbsp;
                            <a href={`mailto:${props.email}`} style={{}}>
                                {(props.email)}
                            </a>
                            <CopyToClipboard valueToCopy={props.email} /> <br />

                        </div>
                        : " - "}
                    <Tooltip label={translate("common.contact")}>
                        {props.contact ?
                            <label style={{ fontSize: "13px", margin: "2px" }}>

                                <FontAwesomeIcon icon={faUser} style={{ border: "none", color: "gray", fontSize: "12px" }} />
                                &nbsp;&nbsp;&nbsp;
                                {props.contact}
                            </label>
                            : <label>{" - "}</label>}
                    </Tooltip>
                    <br />
                    <Tooltip label={translate("common.creationDate")}>
                        <label style={{ fontSize: "12px", margin: "2px" }}>
                            <FontAwesomeIcon icon={faCalendarDay} style={{ border: "none", color: "gray" }} />
                            &nbsp;&nbsp;&nbsp;
                            {props.createdDate ? props.createdDate : " - "}
                        </label>
                    </Tooltip>
                    <br />
                    <Tooltip label={translate("variant.contactDataSharedBetweenOtherPlatformUsers")}>
                        <label style={{ fontSize: "12px", margin: "2px" }}>
                            <FontAwesomeIcon icon={faUsers} style={{ border: "none", color: "gray" }} />
                            &nbsp;&nbsp;
                            {props.shared ?
                                <FontAwesomeIcon icon={faCheck} style={{ border: "none", color: "gray" }} /> :
                                <FontAwesomeIcon icon={faTimes} style={{ border: "none", color: "gray" }} />
                            }
                        </label>
                    </Tooltip>
                </Card.Body>
            </Card>
        </Tooltip>)

}