import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';
import { FiltersReportedVariantsI } from '../Contexts/ReportedVariants/ReportedVariantsContext';

export default class ReportedVariantServices extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "reportedVariant/";
    }
    async search(filters: FiltersReportedVariantsI, pageNumber: number = 1, pageSize: number = 25): Promise<AxiosResponse<any>> {
        const reqFilters = {
            genenameSearch: filters?.geneName ?? "",
            rsSearch: filters?.dbsnpId ?? "",
            decisionSearch: filters?.decision ?? "",
            classificationSearch: filters?.classification ?? "",
            studynameSearch: filters?.study ?? "",
            sortColumn: filters?.sortColumn ?? "",
            studyId: filters?.studyId ?? "",
            identity: filters?.identity ?? ""
        }
        const url = `${this.basePath}search`
        const data = {
            domainId: this.user?.domain,
            userId: this.user?.id,
            pageNumber: pageNumber,
            pageSize: pageSize,
            ...reqFilters
        }
        return await axios.post(url, data, this.authHeader)
    }
    async delete(reportedVarIds: string[]): Promise<AxiosResponse<any>> {
        console.log(reportedVarIds)
        const url = `${this.basePath}delete`
        const data = {
            domainId: +(this.user?.domain ?? "-1"),
            userId: this.user?.id,
            id: reportedVarIds.join(",")
        }
        return await axios.post(url, data, this.authHeader)
    }
    //Request URL: http://10.100.100.55/bioflux-platform-gaip-facade/reportedVariant/getReportVariant.do
    // domainId: "26"
    // userId: "51"
    // infoId: "27"
    // pageNumber: 1
    // pageSize: 500

    async readReportedVariant(variantId: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getReportVariant`
        const data = {
            domainId: `${this.user?.domain}`,
            userId: `${this.user?.id}`,
            pageNumber: 1,
            pageSize: 500,
            infoId: variantId
        }
        return await axios.post(url, data, this.authHeader)
    }

    async updateReportedVariant(updateReportedVariant: createUpdateReportedVariantI): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}update`
        const data = {
            domainId: `${this.user?.domain}`,
            user: `${this.user?.id}`,
            ...updateReportedVariant
        }
        return await axios.post(url, data, this.authHeader)
    }

    async createReportedVariant(createReportedVariant: createUpdateReportedVariantI): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}save`
        const data = {
            domainId: `${this.user?.domain}`,
            user: `${this.user?.id}`,
            ...createReportedVariant
        }
        return await axios.post(url, data, this.authHeader)
    }

    async getReportedVariant(studyId: string, variantId: string, samplenames: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}reportVariant`
        const config = {
            ...this.authHeader,
            params: {
                userid: `${this.user?.id}`,
                domainId: `${this.user?.domain}`,
                studyId: studyId,
                variants: variantId,
                samplenames: samplenames,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }
}

interface createUpdateReportedVariantI {
    study: string,
    sample: string,
    id: string,
    varInfoId: string,
    varid: string,
    classification: string,
    contact: string,
    decision: string,
    diagnostic: string,
    email: string,
    genomeReference: string,
    institution: string,
    libraryPrepKit: string,
    lineage: string,
    observation: string,
    validated: "on" | "",
    shared: "true" | "",
}

export type { createUpdateReportedVariantI }