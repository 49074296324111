import React from 'react';
import { useContext, useState } from "react";
import { faAngleDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Collapse, Row } from "react-bootstrap";
import { Tooltip } from "../../Common/Tooltip/Tooltip";
import { LangContext } from "../../../Contexts/Lang";
import CircleLabel from "../../Common/CircleLabel";
import { Spinner } from 'react-bootstrap';
import UserContext from '../../../Contexts/User/UserContext';
import ThemeContext from "../../../Contexts/Theme/ThemeContext";

type HomeLayoutT = "default" | "domainDetail"
interface HomeLayoutI {
	history: any
	filterTitle?: string | JSX.Element,
	filterTitleCustom?: string | JSX.Element,
	filterTitleRight?: string | JSX.Element,
	filterTitleLeft?: string | JSX.Element,
	rowsAmount?: {
		amount: number,
		loading: boolean
	}
	Filters?: JSX.Element,
	FiltersEmpty?: JSX.Element,
	Toolkit?: JSX.Element,
	Table?: JSX.Element,
	HLType?: HomeLayoutT
	filtersBorder?: boolean
}
export default function HomeLayout(props: HomeLayoutI) {
	const { user, dispatch: { logout } } = useContext(UserContext);
	const { theme } = useContext(ThemeContext);
	if (!user) {
		logout().then(() => props.history.push("/login"))
	}
	const { dispatch: { translate } } = useContext(LangContext);
	const [filterOpen, setfilterOpen] = useState(true);
	const HLType: HomeLayoutT = props.HLType ? props.HLType : "default";
	const isDomainDetail = (HLType === "domainDetail");

	const restWidthDomainDetail = 85;
	const restWidthDefault = 52;
	const getCommonWidth = (rest?: number): string => {
		if (!rest) return `calc(100vw - ${isDomainDetail ? restWidthDomainDetail : restWidthDefault}px)`
		else return `calc(100vw - ${rest}px)`
	}
	const homeLColor = isDomainDetail ? `linear-gradient(${theme.backgroundPrimary}, ${theme.backgroundPrimary}, transparent)` : `linear-gradient( ${theme.backgroundSecondary}, ${theme.backgroundSecondary}, transparent)`;
	return (
		<div id="HomeLayout">
			<div style={{
				position: "fixed",
				zIndex: 2,
				width: getCommonWidth(isDomainDetail ? restWidthDomainDetail - 30 : restWidthDefault - 30),
				background: homeLColor,
				margin: isDomainDetail ? "0 -15px" : "10px -15px 0px -15px",
				padding: isDomainDetail ? "15px 15px 0 15px" : "0 15px 0 15px",
				borderTop: "solid 1px #D6D6D6"
				// padding: isDomainDetail ? "15px 15px 0 15px" : "0",
			}}>
				<Row style={{
					margin: 0,
					borderRadius: "0px",
					alignItems: "end",
					zIndex: 1,
					// backgroundColor: "#F4F4F4",
					backgroundColor: `${isDomainDetail ? "#FFF" : "#F4F4F4"}`,
					justifyContent: "space-between",
					width: "100%"
				}}>
					{props.filterTitle ?
						<>
							<h5 style={{ color: "#444B59", maxHeight: "35px", margin: "15px 10px 0", display: "flex", float: "left", fontFamily: "Raleway, sans-serif" }}>
								{props.filterTitle}&nbsp;
								{props?.rowsAmount &&
									(props?.rowsAmount?.loading ?
										< Spinner animation="grow" role="status" style={{ height: "22px", width: "22px", color: theme.grayHeader }} /> :
										<CircleLabel label={`${props?.rowsAmount?.amount ?? "-"}`}
											size={3}
											backColor={theme.grayHeader}
											style={{ alignSelf: "center" }}
										/>)
								}
								{props.filterTitleLeft && <>
									&nbsp;{props.filterTitleLeft}
								</>
								}
							</h5>
						</>
						: props.filterTitleCustom ? props.filterTitleCustom : <></>
					}
					{props.Filters &&
						<div>
							{props.filterTitleRight}

							<Tooltip label={translate('home.filter.collapseFilter')}>
								<Button
									onClick={() => { setfilterOpen(!filterOpen) }}
									aria-controls="example-collapse-text"
									style={{ border: "none", color: theme.edit, fontSize: "15px", borderRadius: "10px 10px 0 0" }}
									aria-expanded={filterOpen}
									variant="outline-light"
								>
									<>
										<FontAwesomeIcon icon={faFilter} style={{ border: "none", marginRight: "5px" }} />
										<FontAwesomeIcon icon={faAngleDown} style={{ border: "none", marginRight: "5px" }} />
									</>
								</Button>
							</Tooltip>
						</div>
					}
				</Row>
				{(props.Filters || props.Toolkit) &&
					<Row style={{
						margin: 0,
						borderRadius: "0px",
						alignItems: "end",
						zIndex: 1,
						backgroundColor: theme.backgroundSecondary,
						// backgroundColor: `${isDomainDetail ? "#FFF" : "#F4F4F4"}`,
						justifyContent: "space-between",
						width: "100%",
						border: props.filtersBorder ? "1px solid lightgrey" : undefined
					}}>
						{props.Filters &&
							<Collapse in={filterOpen} >
								{props.Filters}
							</Collapse>
						}
						{props.Toolkit && props.Toolkit}
					</Row>
				}
			</div>
			<div style={{ borderRadius: "0px", paddingTop: `${isDomainDetail ? 50 : 48}px`, width: getCommonWidth() }}>
				{props.FiltersEmpty &&
					<div style={{ opacity: 0 }}>
						<Collapse in={filterOpen}>
							{props.FiltersEmpty}
						</Collapse>
					</div>
				}
				<br />
				<br />
				<div style={{
					boxShadow: isDomainDetail ? "auto" : "0px 5px 10px 0px rgba(124, 124, 124, 0.35)",
					marginBottom: "20px",
				}}>
					{props.Table}
				</div>

			</div>
		</div >
	)
}