import GnomadFrequency from "./GnomadFrequency";

export default class Frequency {
    public readonly threshold = 0.02;
    public gnomadGenome: GnomadFrequency;
    public gnomadExome: GnomadFrequency;
    public thereIsGnomadGenome: boolean;
    public thereIsGnomadExome: boolean;
    public frequencies: any[];
    public countsamples: any[];
    public domains: any[];
    public warningnIcon: any;

    constructor(infoCd: any) { // todo: typify this infoCD data!!
        this.gnomadExome = new GnomadFrequency("exome", infoCd)
        this.gnomadGenome = new GnomadFrequency("genome", infoCd)
        if (infoCd && infoCd.FREQUENCY && infoCd.DOMAIN_FREQUENCY) {
            if (Array.isArray(infoCd.FREQUENCY)) this.frequencies = infoCd.FREQUENCY;
            else this.frequencies = infoCd.FREQUENCY.toString().split(',');
            if (Array.isArray(infoCd.SAMPLES_FREQUENCY)) this.countsamples = infoCd.SAMPLES_FREQUENCY;
            else this.countsamples = infoCd.SAMPLES_FREQUENCY.toString().split(",");
            if (Array.isArray(infoCd.DOMAIN_FREQUENCY)) this.domains = infoCd.DOMAIN_FREQUENCY;
            else this.domains = infoCd.DOMAIN_FREQUENCY.toString().split(",");
        } else {
            this.frequencies = []; this.countsamples = []; this.domains = [];
        }
        this.thereIsGnomadExome = Object.values(this.gnomadExome).filter((x: any) => x !== undefined).length > 4; // the 4 is for  "threshold","type", "adapter" and "hasWarning" properties
        this.thereIsGnomadGenome = Object.values(this.gnomadGenome).filter((x: any) => x !== undefined).length > 4;// the 4 is for  "threshold","type", "adapter" and "hasWarning" properties
    }
}