import React from 'react';
import { useEffect, useContext, useState } from "react";
import QueryTitle from "../QueryTitle";
import Loader from '../../../Common/Loader/Loader';
import { Col, Row } from "react-bootstrap";
import DetailLayout from "../../../Layout/Detail/DetailLayout";
import QueryContext from "../../../../Contexts/Query/QueryContext";
import PredictorsCard, { thereIsPredictionData } from "./Predictors/PredictorsCard";
import ClassificationTable from './ClassificationTable';
import AnnotationTable from "./AnnotationTable";
import SomaticTable from "./SomaticTable";
import NomenclatureTable from "./NomenclatureTable";
import VariantIdTitle from './VariantIdTitle';
import { useWindowSize } from 'src/Helpers/WindowsSizeHelper';
import GnomAdTable from './GnomAdTable';
import ConstraintsTable from './ConstraintsTable';
import SampleInfoTable from './SampleInfoDetailTable';
import { ModalSamplesIgv, SampleInfoIgvDataI } from 'src/Components/IGV/ModalSamplesIgv';
interface DetaliQueryI {
	history: any
	studyId: number
	variantId: string
}
export default function DetaliQuery(props: DetaliQueryI) {
	const { variant, dispatch: { getVariant, refreshQueryVariants, refreshGenesVariants } } = useContext(QueryContext);
	// console.log(variant)
	const [reload, setreload] = useState<boolean>(false)
	const handleReload = () => { setreload(true) }
	const isMitochondrial = (variant.data?.study?.subtype ?? "").toUpperCase().trim() === "MT-DNA";
	const isSomatic = (variant.data?.study?.subtype ?? "").toUpperCase().trim() === "SOMATIC";
	const { widthType } = useWindowSize()

	const [showModalSamplesIgv, setShowModalSamplesIgv] = useState<{ show: boolean, data?: SampleInfoIgvDataI }>({ show: false, data: undefined });
	const handleCloseModalSamplesIgv = () => setShowModalSamplesIgv({ show: false, data: undefined });
	const handleShowModalSamplesIgv = (i: SampleInfoIgvDataI) => {
		setShowModalSamplesIgv({ show: true, data: i });
	}
	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (props.studyId && props.variantId) {
				if ((!variant.loaded && !variant.loaded) ||
					(`${variant.data.variant.id}` !== `${props.variantId}`)
					|| variant.refresh || reload) {
					getVariant(+props.studyId, props.variantId.toString())
				}
				if (variant.refresh && variant.loaded && !variant.loading) {
					refreshQueryVariants();
					refreshGenesVariants();
				}
			}
			setreload(false)
		}
		return () => { isMounted = false };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props, reload])


	const loaderElement = (
		<div style={{
			display: "block",
			minHeight: "70vh",
			background: "linear-gradient(to top, rgba(0,0,0, 0), rgba(255,255,255,1), rgba(255,255,255,1))",
			width: "100%",
			paddingTop: "70px",
		}}>
			<Loader onContainer={true} type={'DNA'} />
		</div>
	)
	const variantIdTitle = (<>
		<VariantIdTitle history={props.history}
			studyId={props.studyId}
			variantId={props.variantId}
			handleReload={handleReload}
			refreshQueryVariants={refreshQueryVariants}
			refreshGenesVariants={refreshGenesVariants}
		/>
		<br />
	</>)
	const gnomAdTable = (<GnomAdTable variantData={variant.data} />)
	const annotationTable = (
		<AnnotationTable studyId={props.studyId}
			variantId={props.variantId}
			variant={variant}
			isMitochondrial={isMitochondrial}
			isSomatic={isSomatic}
		/>)
	const somaticTable = (!isSomatic ? <></> :
		<SomaticTable studyId={props.studyId}
			variantId={props.variantId}
			variant={variant}
		/>)
	const nomenclatureTable = (
		<NomenclatureTable variant={variant} // agregarUseMemo
			isMitochondrial={isMitochondrial}
		/>
	)
	const classificationTable = (
		<ClassificationTable variant={variant}
			studyId={props.studyId}
			isMitochondrial={isMitochondrial}
			widthType={widthType} />
	)
	const constraintsTable = (
		<ConstraintsTable variant={variant} />
	)
	const predictorsCard = <PredictorsCard infoCd={variant.data?.variant?.infoColumnData ?? {}} />
	const thereIsPrediction = thereIsPredictionData(variant.data?.variant?.infoColumnData ?? {})
	let sampleInfo_simpleOrFamiliarComponent = (variant.data?.study?.type ?? "") !== "simple" ?
		<></> :
		<SampleInfoTable
			variant={variant}
			isMitochondrial={isMitochondrial}
			isSomatic={isSomatic}
			study={variant.data?.study}
			handleShowModalSamplesIgv={handleShowModalSamplesIgv}
		/>

	return (
		<DetailLayout DetailHeader={
			<div style={{ display: "flex" }}>
				{variant.data?.study &&
					<QueryTitle history={props.history}
						study={variant.data?.study} />}
			</div>
		}>
			<>
				{variant.loading ? loaderElement :
					<>
						{(variant.data?.study?.type === "multiple" || variant.data?.study?.type === "familiar" || variant.data?.study?.hasBam) &&
							(showModalSamplesIgv?.data?.chromosome && showModalSamplesIgv?.data?.position && showModalSamplesIgv?.data?.gen) &&
							<ModalSamplesIgv
								caseId={`${props.studyId}`}
								show={showModalSamplesIgv.show}
								handleClose={handleCloseModalSamplesIgv}
								mood={showModalSamplesIgv?.data?.mood ?? "sample"}
								chromosome={showModalSamplesIgv?.data?.chromosome}
								position={showModalSamplesIgv?.data?.position}
								gen={showModalSamplesIgv?.data?.gen}
								sampleData={showModalSamplesIgv.data?.sampleData}
								igvData={showModalSamplesIgv.data?.igvData}
							/>
						}
						{widthType === "xxl" ?
							<>
								<Row style={{ margin: 0 }}>
									<Col sm={12} md={6} xl={5} style={{ padding: "0 10px" }} >
										{variantIdTitle}
										{gnomAdTable}
										{isSomatic ? classificationTable : somaticTable}
										{constraintsTable}
										{annotationTable}
										{thereIsPrediction ? nomenclatureTable : ""}
										{isSomatic ? sampleInfo_simpleOrFamiliarComponent : <></>}
									</Col>
									<Col sm={12} md={6} xl={7} style={{ padding: "0 10px" }}>
										{isSomatic ? somaticTable : classificationTable}
										{thereIsPrediction ? predictorsCard : nomenclatureTable}
										{thereIsPrediction ? "" : predictorsCard}
										{isSomatic ? <></> : sampleInfo_simpleOrFamiliarComponent}
									</Col>
								</Row>
							</> :
							widthType === "xs" || widthType === "sm" ?
								<>
									<Row style={{ margin: 0 }}>
										<Col sm={12} style={{ padding: "0 10px" }} >
											{variantIdTitle}
											{gnomAdTable}
											{constraintsTable}
											{annotationTable}
											{classificationTable}
											{somaticTable}
											{nomenclatureTable}
											{predictorsCard}
											{sampleInfo_simpleOrFamiliarComponent}
										</Col>
									</Row>
								</> :
								<>
									<Row style={{ margin: 0 }}>
										<Col sm={12} md={12} style={{ padding: "0 10px" }} >
											{variantIdTitle}
										</Col>
									</Row>
									<Row style={{ margin: 0 }}>
										<Col sm={12} md={5} lg={6} xl={5} style={{ padding: "0 10px" }} >
											{gnomAdTable}
											{isSomatic ? classificationTable : somaticTable}
											{constraintsTable}
											{annotationTable}
											{thereIsPrediction ? nomenclatureTable : ""}
											{isSomatic ? sampleInfo_simpleOrFamiliarComponent : <></>}
										</Col>
										<Col sm={12} md={7} lg={6} xl={7} style={{ padding: "0 10px" }}>
											{isSomatic ? somaticTable : classificationTable}
											{thereIsPrediction ? predictorsCard : <>{nomenclatureTable}{predictorsCard}</>}
											{isSomatic ? <></> : sampleInfo_simpleOrFamiliarComponent}
										</Col>
									</Row>
								</>
						}
					</>
				}
			</>
		</DetailLayout>
	)
}
