import React, { useReducer } from "react";
import ThemeContext, { ContextThemeStatesI, ThemeT } from "./ThemeContext";
import ThemeReducer from "./ThemeReducer";
import { ThemeActionType } from "../actionsTypes";
// import StudyService from "../../Services/StudyServices";
// import { ContextStateManager } from "../ContextStateManager";

const ThemeContextState = (props: any) => {
	const initialState: ContextThemeStatesI = {
		theme: {
			themeType: "default",
			redBitgenia: "#FF4E58",         //rgb(255,78,88)
			success: "#34AC4f",             //rgb()
			edit: "#007bFF",                //rgb(0,123,255)
			link: "#009EEA",                //rgb(0,158,234)
			info: "#3CC9FF",
			error: "#DD3D4D",               //rgb(221,61,77)
			warning: "#FFB111",               //
			grayHeader: "#444B59",          //rgb(68,75,89)
			backgroundPrimary: "#FFFFFF",   //rgb(255,255,255)
			backgroundSecondary: "#f4f4f4", //rgb(245,245,245)
			backgroundThird: "#E5E5E5",     //rgb(229,229,229)
			background4: "#F9F9F9",     //rgb(229,229,229)
			borderColor: "#E3E3E3",
			cnvDuplication: "#4DA3FF",
			cnvDeletion: "#F78A93",
			cnvNone: "#989FA4",

			heterozygous: "#17A2B8",

			acmg_vere_p: "#FF4E58",
			acmg_vere_lp: "#F6A13B",
			acmg_vere_vus: "#AAAAAA",
			acmg_vere_lb: "#89C699",
			acmg_vere_b: "#1F9C36",
			acmg_crit_p_vs: "#FF4E58",
			acmg_crit_p_str: "#F1734B",
			acmg_crit_p_mod: "#F6A13B",
			acmg_crit_p_sup: "#FDD25B",
			acmg_crit_b_sa: "#1F9C36",
			acmg_crit_b_str: "#5CB25D",
			acmg_crit_b_mod: "#89C699",
			acmg_crit_b_sup: "#A6D6A6",
			chart_nivo_set3: [
				"#8DD3C7",
				"#FFFFB3",
				"#BEBADA",
				"#FB8072",
				"#80B1D3",
				"#FDB462",
				"#B3DE69",
				"#FCCDE5",
				"#D9D9D9",
				"#BC80BD"
			],
			chart_nivo_nivo: [
				"#E8C1A0",
				"#12B48C", // nivo extended by IA
				"#F47560",
				"#F1E15B",
				"#61CDBB",
				"#E8A838",
				"#97E365",
				"#F08B9C", // nivo extended by IA
				"#CCEEA3", // nivo extended by IA
				"#A9D1F6" // nivo extended by IA
			]
		},
		// theme: {
		//     themeType: "default",
		//     redBitgenia: "#FF4E58",         //rgb(255,78,88)
		//     success: "#34AC4f",             //rgb()
		//     // success: "#28A745",             //rgb(40,167,69)
		//     edit: "#007bFF",                //rgb(0,123,255)
		//     link: "#009EEA",                //rgb(0,158,234)
		//     error: "#DD3D4D",               //rgb(221,61,77)
		//     grayHeader: "#999999",          //rgb(68,75,89)
		//     backgroundPrimary: "#1d1d1e",      
		//     backgroundSecondary: "#303032", 
		//     backgroundThird: "#3e3e40",    
		//     //link2: "#216BA5",             //(0,123,255)
		// }
	};
	const [state, dispatch] = useReducer(ThemeReducer, initialState);
	const changeTheme = (themeType: ThemeT): void => {
		dispatch({ type: ThemeActionType.CHANGE_THEME, payload: themeType })
	}
	return (
		<ThemeContext.Provider
			value={{
				theme: state.theme,
				dispatch: {
					changeTheme
				}
			}}
		>
			{props.children}
		</ThemeContext.Provider>
	);
};

export default ThemeContextState;
