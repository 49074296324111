import React from 'react';
import { useState, useContext } from "react";
import { LangContext } from "../../../Contexts/Lang";
import DomainService from "../../../Services/DomainService";
import EnableDisableBadge from "../../Common/Badges/EnableDisable";
import { ModalWarning } from "../../Common/ModalWarning";
import DetailHeader from "../../Layout/Detail/DetailHeader";
// import GensetService from "../../../Services/GensetService";
import ProcessDomain, { EditDomainI } from "../ProcessDomain/ProcessDomain";

interface DetailDomainHaderI {
    history: any,
    domain: EditDomainI,
    region: string,
    handleReload(): void,
}

export default function DetailDomainHeader(props: DetailDomainHaderI) {
    const { dispatch: { translate } } = useContext(LangContext);

    const inithandleDeleteDomain = { show: false }
    const [handleWarningDelete, sethandleWarningDelete] = useState<{ show: boolean, error?: string }>(inithandleDeleteDomain)
    const handleCloseWarningDelete = () => sethandleWarningDelete(inithandleDeleteDomain);

    const [showProcesDomain, setshowProcesDomain] = useState<boolean>(false);
    const handleCloseprocesDomain = () => { setshowProcesDomain(false) }
    const handleShowprocesDomain = () => { setshowProcesDomain(true) }

    const DomainS = new DomainService();

    const handleDelete = () => {
        DomainS.delete(props.domain.id)
            .then((data: any) => {
                if (data?.data?.errors) {
                    if (data?.data?.errors === "domain.errors.hasUsers") {
                        sethandleWarningDelete({ show: true, error: translate("error.domainHasUsers") })
                    }
                } else {
                    props.handleReload()
                    handleCloseWarningDelete()
                    props.history.push("/")
                }
            })
    }

    return (
        <>
            <ProcessDomain history={props.history}
                show={showProcesDomain}
                editDomain={props.domain}
                handleClose={handleCloseprocesDomain}
                handleReload={props.handleReload}
            />

            <ModalWarning labelTitle={translate('common.confirm')}
                label={translate('domain.confirmDeleteDomain')}
                error={handleWarningDelete.error}
                labelButton={translate('home.delete')}
                showState={handleWarningDelete.show}
                handleClose={handleCloseWarningDelete}
                onClick={handleDelete}
            />
            <DetailHeader DHType={"domain"}
                history={props.history}
                titleTooltipLabel={translate("common.goToPreviousPage")}
                titleLabel={<>
                    {props.domain.id}&nbsp;-&nbsp;
                    {props.domain.name}&nbsp;&nbsp;
                    <EnableDisableBadge enable={props.domain.enable}
                        styles={{ fontSize: "15px", alignSelf: "center", marginBottom: "5px" }}
                    />
                </>
                }
                // refreshOnGoBack={props.reloadOnGoBack}
                buttonsActions={{
                    edit: () => handleShowprocesDomain(),
                    delete: () => sethandleWarningDelete({ show: true }),
                }}
            />
        </>
    )
}