import { createContext } from "react";
import { AsyncPagesI, ReportedVariantsI, AsyncDataI } from "../interfaces";

interface FiltersReportedVariantsI {
    geneName?: string,
    dbsnpId?: string,
    decision?: string | null,
    classification?: string,
    study?: string,
    sortColumn?: string,
    studyId?: string,
    identity?: string
}

interface ContextReportedVariantsStatesI {
    variant: AsyncDataI & { data: null | ReportedVariantsI },
    variants: AsyncDataI & {
        data: null | (AsyncPagesI & {
            reports: ReportedVariantsI[]
        })
    },
    filters: FiltersReportedVariantsI,
    selecteds: number[]

}
interface ContextPropsReportedVariantsI extends ContextReportedVariantsStatesI {
    dispatch: {
        getVariant: (id: string) => Promise<void>;
        getVariants: (filters?: any, pageNumber?: number, pageSize?: number) => Promise<void>;
        refreshVariants: () => void;
        setFilters: (key: string, value: string) => boolean;
        cleanFilters: () => void;
        handleSelecteds: (id: string) => void;
        handleSelectAll: () => void;
    }
}

const ReportedVariantsContext = createContext({} as ContextPropsReportedVariantsI);

export default ReportedVariantsContext;
export type { FiltersReportedVariantsI, ContextReportedVariantsStatesI }