import React from 'react';
import DomainsPage from './DomainsPage';
import CasesPage from './CasesPage';
import UserService from '../../Services/UserServices';
import UserContext from '../../Contexts/User/UserContext';
import { useContext, useEffect } from 'react';
import { RollesT } from '../../Contexts/interfaces';
import { Redirect } from 'react-router-dom';

interface HomePageI {
    history: History,
}
function HomePage(props: HomePageI) {
    const { user } = useContext(UserContext);
    // console.log("--------HOME-Page user")
    // console.log(user.data)
    const US = new UserService();
    let roll: "" | RollesT = ""
    const currentRoll: any = user.data?.rolestoString ?? "";

    useEffect(() => { }, [user])
    if (currentRoll !== "" && (currentRoll !== US.user?.rolestoString ?? "-")) {
        localStorage.removeItem("_Buser")
        return <Redirect to='/login' />
    }
    if (US.user) roll = US.user.rolestoString
    if (roll === "ROLE_SYSTEM") return <DomainsPage history={props.history} />
    else return <CasesPage history={props.history} />
}

export default HomePage