import React from 'react';
import "rc-tooltip/assets/bootstrap.css";
import "rc-slider/assets/index.css";
import Slider, { SliderTooltip } from "rc-slider";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

interface RangeSliderI {
    arrayData: number[],
    handleData: any,
    values: number[];
    doublePresicion?: number;
}
function RangeSlider(props: RangeSliderI) {
    const dp = props?.doublePresicion ?? 1
    const arrayData = dp ? props.arrayData.map((x: any) => Math.round(x * dp)) : props.arrayData;
    function handleChange(range: any) {
        if (range[0] !== props.values[0] * dp) {
            props.handleData([range[0] / dp, arrayData[arrayData.length - 1] / dp]);
        }
        if (range[1] !== props.values[1] * dp) {
            props.handleData([arrayData[0] / dp, range[1] / dp]);
        }
    }
    const marks: any = {};
    marks[`${arrayData[0]}`] = arrayData[0] / dp;
    marks[`${arrayData[arrayData.length - 1]}`] = arrayData[arrayData.length - 1] / dp;
    return (
        <div style={{ margin: "0px 5px 20px" }}>
            <Range
                min={arrayData[0]}
                max={arrayData[arrayData.length - 1]}
                marks={marks}
                // dots
                //defaultValue={[min, max]}urn
                value={props.values.map((x: any) => x * dp)}
                onChange={handleChange}
                tipFormatter={(value) => { return `${(value / dp).toFixed(dp.toString().split("").length - 1)}` }}
            />
        </div>
    );
}
interface MySliderI {
    arrayData: number[],
    handleData: any;
    value: number;
    doublePresicion?: number
}
function MySlider(props: MySliderI) {
    const dp = props?.doublePresicion ?? 1;
    const arrayData = dp ? props.arrayData.map((x: any) => Math.round(x * dp)) : props.arrayData;
    function handleChange(val: any) {
        props.handleData(val / dp);
    }
    const marks: any = {};
    marks[`${arrayData[0]}`] = arrayData[0] / dp;
    marks[`${arrayData[arrayData.length - 1]}`] = arrayData[arrayData.length - 1] / dp;
    const { Handle } = Slider;
    const handleSlider = (props: any) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <SliderTooltip
                overlay={(value / dp).toFixed(dp.toString().split("").length - 1)}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </SliderTooltip>
        );
    };
    return (
        <div style={{ margin: "0px 5px 20px" }}>
            <Slider
                min={arrayData[0]}
                max={arrayData[arrayData.length - 1]}
                marks={marks}
                value={props.value * dp}
                handle={handleSlider}
                onChange={handleChange}
            />
        </div>
    );
}


export { RangeSlider, MySlider }