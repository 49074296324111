import React, { useContext } from 'react';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button } from 'react-bootstrap';
import { LangContext } from 'src/Contexts/Lang';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { Popover } from '../Popover';

interface BadgeSampleI {
	name: string,
	afected?: boolean,
	fontSize?: string,
	style?: any,
	infoIcon?: boolean
}
function BadgeSample(props: BadgeSampleI) {
	const fontSize = props.fontSize ?? "11px";
	const infoIcon = props.infoIcon ?? false;
	const afected = props.afected ?? false;
	const style = props.style ?? {}
	return (
		<Badge key={props.name} variant="secondary"
			style={{
				fontWeight: "inherit",
				fontSize: fontSize,
				color: "white",
				backgroundColor: afected ? "#FF4E58" : "#999999",
				// margin: "2px",
				// marginRight: "5px",
				// padding: "1px 5px 3px 5px",
				padding: "3px 5px 3px",
				verticalAlign: "middle",
				...style
			}}>
			{props.name}
			{infoIcon &&
				<FontAwesomeIcon icon={faInfoCircle}
					style={{ fontSize: "12px", margin: "0 0 2px 6px", verticalAlign: "middle" }} />
			}
		</Badge>
	)
}

interface BadgeSamplePopoverI {
	sample: any,
}
function BadgeSamplePopover(props: BadgeSamplePopoverI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { theme } = useContext(ThemeContext);

	const sample = props.sample
	return (
		<Popover content={
			<>
				{sample &&
					<div>
						<strong>{translate("common.name")} : </strong> {sample.sample.name ?? "-"} <br />
						<strong>{translate("common.sex")} : </strong> {sample.sample.sexo ?? "-"} <br />
						<strong>{translate("casesQuery.bDate")} : </strong> {sample.sample.simpleDateFormatBirthday ?? "-"} <br />
						<strong>Pedigree : </strong> {sample.tag}<br />
						{
							sample.afectado &&
							<strong>{translate("common.affected")} :
								<FontAwesomeIcon icon={faCheck}
									style={{
										border: "none",
										fontSize: "13px",
										marginLeft: "5px",
										color: theme.redBitgenia
									}} />
							</strong>
						}
					</div>
				}
			</>
		}
			trigger={"hover"} style={{ fontSize: "11px" }}>
			<Button style={{ margin: 0, padding: 0, backgroundColor: "rgba(1,1,1,0)", border: "none" }}>
				<BadgeSample
					name={sample?.sample?.name ?? "--"}
					afected={sample?.afectado ?? false}
					style={{
						fontSize: "11px",
						margin: "2px"
					}}
					infoIcon={true}
				/>
			</Button>
		</Popover >
	)
}
export default BadgeSample;
export { BadgeSamplePopover }