// https://codesandbox.io/s/react-hooks-usestate-forked-xepxb?file=/package.json:154-160
import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import * as venn from "venn.js";
import "./styles.css";

export default function VennDiagram(props) {
  const width = props.width ?? 380;
  const height = props.height ?? 300;
  const sets = useState(props.data);

  useEffect(() => {
    var div = d3.select("#venn")
    div.data(sets).call(
      venn.VennDiagram()
        .width(width)
        .height(height)
      // .width(200)
      // .height(200)
    )

    // var tooltip = d3.select("body").append("div").attr("class", "venntooltip");

    var tooltip = d3.select("body").append("div")
      .attr("class", "venntooltip");

    div.selectAll("path")
      .style("stroke-opacity", 0)
      .style("stroke", "#fff")
      .style("stroke-width", 3)

    div.selectAll("g")
      .on("mouseover", function (d, i) {
        // sort all the areas relative to the current item
        venn.sortAreas(div, d);

        // Display a tooltip with the current size
        tooltip.transition().duration(400).style("opacity", .9);
        tooltip.text(d.size + " variants");

        // highlight the current path
        var selection = d3.select(this).transition("tooltip").duration(400);
        selection.select("path")
          .style("fill-opacity", d.sets.length === 1 ? .4 : .1)
          .style("stroke-opacity", 1);
      })

      .on("mousemove", function () {
        tooltip.style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      })

      .on("mouseout", function (d, i) {
        tooltip.transition().duration(400).style("opacity", 0);
        var selection = d3.select(this).transition("tooltip").duration(400);
        selection.select("path")
          .style("fill-opacity", d.sets.length === 1 ? .25 : .0)
          .style("stroke-opacity", 0);
      });

    div.selectAll(".venn-intersection text").style("visibility", "collapse");

    div.selectAll(".venn-circle text").on("mouseover", function (d, i) {

      d3.select(this).style("font-weight", "100")
        .style("font-size", "14px");
    })
      .on("mouseout", function (d, i) {
        d3.select(this).style("font-weight", "100")
          .style("font-size", "12px");
      });

  });
  //-----

  return <div id="venn" style={{ textAlign: "center", width: width, height: height }}></div>;
}


// const venChartData = [
//   {
//       sets: ["170000580443_S2"],
//       size: 318,
//       label: "170000580443_S2"
//   },
//   {
//       sets: ["170000580437_S7"],
//       size: 303,
//       label: "170000580437_S7"
//   },
//   {
//       sets: ["170000580437_S6"],
//       size: 302,
//       label: "170000580437_S6"
//   },
//   {
//       sets: ["170000580443_S2", "170000580437_S6"],
//       size: 211,
//   },
//   {
//       sets: ["170000580443_S2", "170000580437_S7"],
//       size: 210,
//   },
//   {
//       sets: ["170000580437_S6", "170000580437_S7"],
//       size: 203,
//   },
//   {
//       sets: ["170000580443_S2", "170000580437_S6", "170000580437_S7"],
//       size: 161,
//   }
// ]
