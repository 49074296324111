import React, { useEffect, useLayoutEffect } from 'react';
import { useContext } from "react";
import { LangContext } from "../../../../Contexts/Lang";
import { StudyI } from "../../../../Contexts/interfaces";
import ThemeContext from '../../../../Contexts/Theme/ThemeContext';
import { CoverageVariantsColT, getColumnClinvarVariantId } from "./VariantsClinvarTable";

interface VariantsClinvarTableHeadersI {
    study: StudyI,
    studyName: string,
    variantClinvar: any[],
    tableMounted: boolean;
}
export default function VariantsClinvarTableHeaders(props: VariantsClinvarTableHeadersI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    // const getWidthByCol = useMemo(() =>
    // (colType: CoverageVariantsColT): string => {
    const getWidthByCol = (colType: CoverageVariantsColT): string => {
        let colWidth: any = "auto"
        const colId = getColumnClinvarVariantId(props.studyName, colType, 0)
        const element = document.getElementById(colId)
        if (element) {
            colWidth = element.getBoundingClientRect().width ?? colWidth
            // colWidth = `${(+colWidth * 0.62).toFixed()}px`;
            colWidth = `${(+colWidth).toFixed()}px`;
            // console.log(colWidth)
        }
        return colWidth
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [props.tableMounted])
    useEffect(() => { }, [props.tableMounted])
    useLayoutEffect(() => { }, [props.tableMounted])

    return (
        <thead style={{ backgroundColor: "#EEEEEE", color: theme.grayHeader, fontSize: 10, textTransform: "uppercase" }}>
            <tr >
                <th id={getColumnClinvarVariantId(props.studyName, "gene")} style={{ minWidth: getWidthByCol("gene") }}>
                    {translate("common.gene")}
                </th>
                <th id={getColumnClinvarVariantId(props.studyName, "variant")} style={{ minWidth: getWidthByCol("variant") }}>
                    {translate("common.variant")}
                </th>
                <th id={getColumnClinvarVariantId(props.studyName, "sampleAlleles")} style={{ minWidth: getWidthByCol("sampleAlleles"), textAlign: "center" }}>
                    {translate("common.sampleAlleles")}
                </th>
                <th id={getColumnClinvarVariantId(props.studyName, "coverage")} style={{ minWidth: getWidthByCol("coverage"), textAlign: "center" }}>
                    {translate("common.coverage")}
                </th>
                <th id={getColumnClinvarVariantId(props.studyName, "onTarget")} style={{ minWidth: getWidthByCol("onTarget"), textAlign: "center" }}>
                    On target
                </th>
                <th id={getColumnClinvarVariantId(props.studyName, "classification")} style={{ minWidth: getWidthByCol("classification") }}>
                    {translate("common.classification")}
                </th>
                <th id={getColumnClinvarVariantId(props.studyName, "sampleInfo")} style={{ minWidth: getWidthByCol("sampleInfo") }}>
                    {translate("casesQuery.sampleInformation")}
                </th>
            </tr>
        </thead>
    )

}