import React from 'react';
export default function FreezeDiv(props: any) {
    return (
        <div id="freezeComponent"
            style={{
                cursor: "wait",
                display: "block",
                position: "fixed",
                width: "100%",
                height: "100%",
                background: "black",
                top: 0,
                zIndex: 1000000000,
                left: 0,
                opacity: "0.1"
            }}>
            .
        </div>)
}
