import React from 'react';
import { useContext } from "react";
import { Row, Badge } from "react-bootstrap";
import BadgeVeredictPredictors from 'src/Components/Common/Badges/BadgePredictorsVeredict';
import QueryContext from "../../../../../Contexts/Query/QueryContext";
import ThemeContext from "../../../../../Contexts/Theme/ThemeContext";
import { SpliceAiLookup } from "../../../../Common/Links/Links";
import PredictorsTemplateTable, { PredictorsTableTitle, PredictorsTemplateTableRowI } from './PredictorsTemplateTable';

interface SplicingTableI {
    infoCd: any,
    defaultScoreValue: string,
    getEmptyTableRow(id: string, label?: string): PredictorsTemplateTableRowI,
}
export default function SplicingTable(props: SplicingTableI) {
    const { variant } = useContext(QueryContext);
    const { theme } = useContext(ThemeContext);
    const infoCd = props.infoCd;

    const getSpliceAiTableData = (): { ds: any, dp: any, saiDist: any } => {
        if (!infoCd || (!infoCd.SAI_DS_AG && !infoCd.SAI_DS_AL && !infoCd.SAI_DS_DG && !infoCd.SAI_DS_DL &&
            !infoCd.SAI_DP_AG && !infoCd.SAI_DP_AL && !infoCd.SAI_DP_DG && !infoCd.SAI_DP_DL && !infoCd.SAI_DIST
        )) return { ds: null, dp: null, saiDist: null }


        const getDsData = () => {
            if (!infoCd || (
                !infoCd.SAI_DS_AG &&
                !infoCd.SAI_DS_AL &&
                !infoCd.SAI_DS_DG &&
                !infoCd.SAI_DS_DL
            )) return " -- "
            // const getSAI_DS_variant = (value: number): string => {
            //     if (value <= 0.2) return "success";
            //     if (value >= 0.5) return "danger";
            //     return "warning";
            // }
            // const getBadgeSpliceAi = (value?: string) => {
            //     if (!value) return "0.0"
            //     return [`${value}`.split(",")[0]].map((x: any, i: number) => {
            //         return <Badge key={i} variant={getSAI_DS_variant(+x)}
            //             style={{ fontWeight: "bold", margin: "1px 2px", fontSize: "11px" }}>
            //             {x}
            //         </Badge>
            //     })
            // }
            return (
                <Row style={{ color: "dimgray", margin: 0, padding: 0 }}>
                    <b>DS_AG:</b>{getBadgeSpliceAi(infoCd.SAI_DS_AG)}&nbsp;|&nbsp;
                    <b>DS_AL:</b>{getBadgeSpliceAi(infoCd.SAI_DS_AL)}&nbsp;|&nbsp;
                    <b>DS_DG:</b>{getBadgeSpliceAi(infoCd.SAI_DS_DG)}&nbsp;|&nbsp;
                    <b>DS_DL:</b>{getBadgeSpliceAi(infoCd.SAI_DS_DL)}&nbsp;|&nbsp;
                </Row >)
        }
        const getDpData = () => {
            if (!infoCd || (
                !infoCd.SAI_DP_AG &&
                !infoCd.SAI_DP_AL &&
                !infoCd.SAI_DP_DG &&
                !infoCd.SAI_DP_DL
            )) return " -- "
            return (
                <span style={{ color: theme.grayHeader }}>
                    <b>DP_AG:</b> {!infoCd.SAI_DP_AG ? "0.0" : `${infoCd.SAI_DP_AG}`.split(",")[0]} &nbsp;|&nbsp;
                    <b>DP_AL:</b> {!infoCd.SAI_DP_AL ? "0.0" : `${infoCd.SAI_DP_AL}`.split(",")[0]} &nbsp;|&nbsp;
                    <b>DP_DG:</b> {!infoCd.SAI_DP_DG ? "0.0" : `${infoCd.SAI_DP_DG}`.split(",")[0]} &nbsp;|&nbsp;
                    <b>DP_DL:</b> {!infoCd.SAI_DP_DL ? "0.0" : `${infoCd.SAI_DP_DL}`.split(",")[0]}
                </span>)
        }
        const getSaiDistData = () => {
            if (!infoCd || !infoCd.SAI_DIST) return " -- "
            return (
                <span style={{ color: theme.grayHeader }}>
                    <b>SAI_DIST:</b> {`${infoCd.SAI_DIST}`.split(",")[0]}
                </span>)
        }
        return {
            ds: getDsData(),
            dp: getDpData(),
            saiDist: getSaiDistData()
        }
    }
    const spliceAiData = getSpliceAiTableData()
    let dataSpliceAi: PredictorsTemplateTableRowI[] = [];
    let dataSplice: PredictorsTemplateTableRowI[] = [];
    if (spliceAiData.ds && spliceAiData.dp && spliceAiData.saiDist) {
        dataSpliceAi = [
            {
                id: "spliceAiTitle",
                label: <span style={{ fontWeight: "bold", color: "gray" }}>SpliceAI</span>,
                detail: <span style={{ float: "right", marginRight: "0px" }}>
                    <SpliceAiLookup
                        chromosome={variant?.data?.variant?.chromosome}
                        position={`${variant?.data?.variant?.position}`}
                        reference={variant?.data?.variant?.reference}
                        alternative={variant?.data?.variant?.alternative}
                        hg={variant?.data?.study?.genomeReference}
                    />
                </span>,
            },
            {
                id: "ds",
                label: "Donor Splice (DS)",
                score: "",
                conclution: "",
                detail: spliceAiData.ds,
            },
            {
                id: "dp",
                label: "Donor Position (DP)",
                score: "",
                conclution: "",
                detail: spliceAiData.dp
            },
            {
                id: "dist",
                label: "SpliceAI Dist",
                score: "",
                conclution: "",
                detail: spliceAiData.saiDist
            },
            {
                id: "space",
                label: <br />,
                score: "",
                conclution: "",
                detail: ""
            },
        ];
        if (infoCd.DBSCSNV_ADA_SCORE || infoCd.DBSCSNV_RF_SCORE) {
            dataSpliceAi.push({
                id: "spliceAiTitle",
                label: <span style={{ fontWeight: "bold", color: "gray" }}>dbscSNV</span>,
                detail: "",
            })
            if (infoCd.DBSCSNV_ADA_SCORE) {
                dataSplice.push({
                    id: "adaScore",
                    label: "ADA score",
                    score: (+infoCd.DBSCSNV_ADA_SCORE).toFixed(3),
                    conclution: infoCd?.DBSCSNV_ADA_SCORE ? <BadgeVeredictPredictors type={"ada"} value={+infoCd?.DBSCSNV_ADA_SCORE} /> : "-",
                    detail: ""
                })
            }
            if (infoCd.DBSCSNV_RF_SCORE) {
                dataSplice.push({
                    id: "rfScore",
                    label: "RF score",
                    score: (+infoCd.DBSCSNV_RF_SCORE).toFixed(3),
                    conclution: infoCd?.DBSCSNV_RF_SCORE ? <BadgeVeredictPredictors type={"rf"} value={+infoCd?.DBSCSNV_RF_SCORE} /> : "-",
                    detail: ""
                })
            }
        }
    } else {
        dataSpliceAi = [
            props.getEmptyTableRow("spliceAi", "SpliceAi")
        ]
        dataSplice = [
            props.getEmptyTableRow("spliceAi", "SpliceAi")
        ]
    }
    return (
        <>
            <PredictorsTableTitle title={"Splicing"} styles={{ margin: "15px 5px 8px" }} />
            <PredictorsTemplateTable rowData={dataSpliceAi} noScore={true} noInfo={true} noConclution={true} labelWidth={"auto"} />
            {dataSplice.length > 0 ? <PredictorsTemplateTable rowData={dataSplice} labelWidth={"auto"} /> : <></>}
        </>
    )
}

// function getBadgeSpliceAi(){}
const getBadgeSpliceAi = (value?: string, fontSize?: number) => {
    if (!value) return "0.0"
    const getSAI_DS_variant = (value: number): string => {
        if (value <= 0.2) return "success";
        if (value >= 0.5) return "danger";
        return "warning";
    }
    return [`${value}`.split(",")[0]].map((x: any, i: number) => {
        return <Badge key={i} variant={getSAI_DS_variant(+x)}
            style={{ fontWeight: "bold", margin: "1px 2px", fontSize: fontSize ?? "11px" }}>
            {x}
        </Badge>
    })
}

export { getBadgeSpliceAi }