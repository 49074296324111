import React from 'react';
import { useState, useEffect } from "react";
import * as d3 from "d3";
import { hexToRgb } from '../../../utils/helpers'
interface LayoutChartI {
    history: any,
    children: any,
    data: {
        label: string,
        value: number,
        color: string
    }[],
    width: number,
    height: number,
    labels?: boolean,
    labelsPosition?: "right" | "bottom" | "left",
    title?: string
}

export default function LayoutChart(props: LayoutChartI) {
    const labels = props.labels ? props.labels : false;
    const labelsPosition = props.labelsPosition ?? "bottom";
    function setData(data: any[]) {
        const newData = data
            .filter((x: any) => x.value !== 0)
            .map((d: any, i: number) => {
                let result: any = d;
                d["color"] = hexToRgb(d3.schemeSet1[i])
                d["label"] = data[i].label
                d["link"] = data[i].link
                return result
            })
        return newData
    }

    const [renderData, setrenderData] = useState([]);

    useEffect(() => {
        const newData: any = setData(props.data);
        setrenderData(newData)
    }, [props]);

    function getDotReferenceS(color: string, size: string) {
        return {
            height: size,
            width: size,
            backgroundColor: color,
            borderRadius: 0,
            display: "inline-block",
            marginRight: "5px"
        }
    }

    function getLabels() {

        return (renderData ?? []).map((d: any, i: number) => {
            return (
                <div id={`${i}`} key={`${i}`} style={{ display: "flex", fontSize: "11px", cursor: "pointer", marginBottom: "1px" }}
                    onClick={() =>
                        props.history.push(d.link)
                    }>
                    <span style={getDotReferenceS(d.color, "15px")} />
                    {d.label} :  {d.value}
                </div>
            )
        })
    }
    return (
        <div style={{
            minWidth: props.width,
            minHeight: (props.title ? props.height + 30 : props.height),
            marginBottom: "15px"
        }}>
            {props.title &&
                <h5 style={{ margin: "0 0 0 15px", fontSize: "14px", color: "darkslategray" }}>
                    {props.title}
                </h5>
            }
            <div style={{ display: (labelsPosition === "bottom" ? "grid" : "auto") }}>
                {props.children ?
                    props.children :
                    <div key={"aasd"}></div>
                }
            </div>
            <div style={{ margin: "0 0 0 15px" }}>
                {labelsPosition === "bottom" ?? <br />}
                {labels && getLabels()}
            </div>
        </div >
        // </div>
    );
};