import React from 'react';
import { useContext, useEffect } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import { LangContext } from '../../../Contexts/Lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

interface DetailPanelGenesWarningTableI {
    warningGenes: any[],
    genesSelecteds: any,
    handleGenesSelecteds?: any,
    handleDeleteGen(genId: string[]): void,
}
export default function DetailPanelGenesWarningTable(props: DetailPanelGenesWarningTableI) {

    const { dispatch: { translate } } = useContext(LangContext);
    useEffect(() => { }, [props])
    const rows = props.warningGenes.map((x: any, i: number) =>
        <TableRow key={i}
            actualRow={x}
            genesSelecteds={props.genesSelecteds}
            handleGenesSelecteds={props.handleGenesSelecteds}
            handleDeleteGen={props.handleDeleteGen}
        />
    )
    const columnTitleStyle = { padding: 4, fontSize: 14 }
    return (
        <div style={{}}>
            <Table responsive="md" style={{ marginBottom: "-1px", fontSize: "12px", backgroundColor: "#FBEFD7" }}
            >
                <thead>
                    <tr style={{ color: "dimgray", "backgroundColor": "#F7F7F7", margin: 0, padding: "5px" }}>
                        <th style={columnTitleStyle}></th>
                        <th style={columnTitleStyle}>{translate("genesPanel.notApprovedSymbol")}</th>
                        <th style={columnTitleStyle}>{translate("genesPanel.symbolIsNotApprovedByHugo")}</th>
                        <th style={columnTitleStyle}></th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        </div>
    )
}

interface TableRowI {
    actualRow: any,
    genesSelecteds: any[],
    handleGenesSelecteds?: any,
    handleDeleteGen(genId: string[]): void,
}

function TableRow(props: TableRowI) {
    const sysPanel = props.actualRow.systemPanel;
    const columnStyles = { border: "none", verticalAlign: "middle" }
    return (
        <tr style={{ borderBottom: "1px solid lightgray" }}>
            <td style={{ border: "none", width: "35px", verticalAlign: "middle", textAlign: "center" }}>
                <Form.Check type="checkbox"
                    checked={!sysPanel && (props?.genesSelecteds?.find((y: any) => y === props.actualRow)??false)}
                    disabled={sysPanel}
                    onChange={() => {
                        props.handleGenesSelecteds(props.actualRow)
                    }}
                />

            </td>
            <td style={columnStyles}>
                {props.actualRow ?? " - "}
            </td>
            <td style={columnStyles}>
                {
                    <a href={"https://www.genenames.org"} target="_blank" rel="noreferrer">	www.genenames.org
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{ fontSize: "10px", margin: "1px 5px", color: "#009EEA" }} />
                    </a>
                }
            </td>
            <td style={columnStyles}>
                <Button variant="outline-light"
                    style={{ border: "none", borderRadius: "50px", padding: "5px 7px 2px" }}
                    onClick={() => props.handleDeleteGen([props.actualRow])}
                >
                    <FontAwesomeIcon icon={faTrash} style={{ border: "none", color: "gray", fontSize: "13px" }} />
                </Button>
            </td>
        </tr>
    )
}

