import React from 'react';
import { useContext } from "react";
import { Badge } from "react-bootstrap";
import ThemeContext from 'src/Contexts/Theme/ThemeContext';

const oncogenicEffect = ["oncogenic", "likely_oncogenic", "likely_neutral", "inconclusive"]
interface BadgeOncogenicEffectI {
	effect: string,
	fontSize?: number
}
export default function BadgeOncogenicEffect(props: BadgeOncogenicEffectI) {
	const { theme } = useContext(ThemeContext);
	const effColors: { [name: string]: string } = {
		oncogenic: theme.acmg_vere_p,
		likely_oncogenic: theme.acmg_vere_lp,
		likely_neutral: theme.grayHeader,
		inconclusive: theme.grayHeader
	}
	let effect = props.effect.toLowerCase().replaceAll(" ", "_")
	let effectValidated = oncogenicEffect.filter((x: string) => x === effect);
	if (effectValidated.length === 0) return <></>;
	effect = effectValidated[0];
	return (
		<Badge variant="secondary"
			style={{
				fontWeight: "bolder",
				fontSize: props.fontSize ?? 12,
				color: effColors[effect],
				backgroundColor: "lightgray",
				margin: "1px 2px",
			}}>
			{effect[0].toUpperCase() + effect.slice(1).replaceAll("_", " ")}
		</Badge>
	)
}