import React, { useReducer } from "react";
import DomainsReducer from "./DomainsReducer";
import { DomainsActionType } from "../actionsTypes";
import DomainContext, { ContextDomainStatesI, FilterDomainReqI, FiltersCasesDomainReqI, FiltersUserDomainReqI } from "./DomainsContext";
import DomainService from "../../Services/DomainService";
import { ContextStateManager } from "../ContextStateManager";

const DomainsContextState = (props: any) => {
    const DomainS = new DomainService();
    const initialState: ContextDomainStatesI = {
        domain: { data: null, loaded: false, loading: false, error: false },
        domains: { data: null, loaded: false, loading: false, error: false, refresh: false },
        filters_domains: { domainidSearch: "", domainnameSearch: "", studynameSearch: "", usernameSearch: "", sortColumn: "" },
        filters_cases: { nameSearch: "", studyStateSearch: "", dateCreatedFromSearch: "", dateCreatedToSearch: "", sortColumn: "" },
        filters_users: { fullnameSearch: "", usernameSearch: "", sortColumn: "" },
        // selectedsDomains: [],
        selectedsCases: [],
        selectedsUsers: []
    };

    const [state, dispatch] = useReducer(DomainsReducer, initialState);
    const CSM = new ContextStateManager(state, initialState, DomainsActionType, dispatch)

    const getDomain = async (domainId: string, pageNumber?: number, pageSize?: number, tab?: "study" | "user",
        filtersDomain?: FiltersCasesDomainReqI, filtersUsers?: FiltersUserDomainReqI) =>
        CSM.GetAsyncData("GET_DOMAIN_", { domainId, pageNumber, pageSize, tab, filtersDomain, filtersUsers }, DomainS, "getDomain",
            (resData: any) => {
                resData["cases"] = resData.studies;
                resData.studies = undefined;
                return resData
            })
    const getDomains = async (filters?: FilterDomainReqI, pageNumber?: number, pageSize?: number) =>
        CSM.GetAsyncData("GET_DOMAINS_", { filters, pageNumber, pageSize }, DomainS, "search", (resData: any) => { return resData.result })
    const refreshDomains = () => dispatch({ type: DomainsActionType.GET_DOMAINS_REFRESH, })

    const setFiltersDomains = (key: string, value: string) => CSM.SetFilters("filters_domains", "SET_FILTERS_DOMAINS", key, value)
    const cleanFiltersDomains = () => CSM.CleanFilters("filters_domains", "SET_FILTERS_DOMAINS")
    const setFiltersCases = (key: string, value: string) => CSM.SetFilters("filters_cases", "SET_FILTERS_CASES", key, value)
    const cleanFiltersCases = () => CSM.CleanFilters("filters_cases", "SET_FILTERS_CASES")
    const setFiltersUsers = (key: string, value: string) => CSM.SetFilters("filters_users", "SET_FILTERS_USERS", key, value)
    const cleanFiltersUsers = () => CSM.CleanFilters("filters_users", "SET_FILTERS_USERS")
    // const handleSelectedsDomains = (id: string) => CSM.HandleSelecteds("selectedsDomains", "SET_SELECTEDS_DOMAINS", state.domains.data?.results ?? [], id)
    // const handleSelectAllDomains = () => CSM.HandleSelectAll("selectedsDomains", "SET_SELECTEDS_DOMAINS", state.domains.data?.results ?? [])
    const handleSelectedsCases = (id: string) => CSM.HandleSelecteds("selectedsCases", "SET_SELECTEDS_CASES", state.domain.data?.cases?.results ?? [], id)
    const handleSelectAllCases = () => CSM.HandleSelectAll("selectedsCases", "SET_SELECTEDS_CASES", state.domain.data?.cases?.results ?? [])
    const handleSelectedsUsers = (id: string) => CSM.HandleSelecteds("selectedsUsers", "SET_SELECTEDS_USERS", state.domain.data?.users?.results ?? [], id)
    const handleSelectAllUsers = () => CSM.HandleSelectAll("selectedsUsers", "SET_SELECTEDS_USERS", state.domain.data?.users?.results ?? [])



    return (
        <DomainContext.Provider
            value={{
                domain: state.domain,
                domains: state.domains,
                filters_domains: state.filters_domains,
                filters_cases: state.filters_cases,
                filters_users: state.filters_users,
                // selectedsDomains: state.selectedsDomains,
                selectedsCases: state.selectedsCases,
                selectedsUsers: state.selectedsUsers,
                dispatch: {
                    getDomain,
                    getDomains,
                    refreshDomains,
                    setFiltersDomains,
                    cleanFiltersDomains,
                    setFiltersCases,
                    cleanFiltersCases,
                    setFiltersUsers,
                    cleanFiltersUsers,
                    // handleSelectedsDomains,
                    // handleSelectAllDomains,
                    handleSelectedsCases,
                    handleSelectAllCases,
                    handleSelectedsUsers,
                    handleSelectAllUsers
                }
            }}
        >
            {props.children}
        </DomainContext.Provider>
    );
};

export default DomainsContextState;