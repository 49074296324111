const aaDict: { [key: string]: string } = {
	'A': 'Ala', 'R': 'Arg', 'N': 'Asn', 'D': 'Asp', 'C': 'Cys', 'Q': 'Gln', 'E': 'Glu',
	'G': 'Gly', 'H': 'His', 'I': 'Ile', 'L': 'Leu', 'K': 'Lys', 'M': 'Met', 'F': 'Phe',
	'P': 'Pro', 'S': 'Ser', 'T': 'Thr', 'W': 'Trp', 'Y': 'Tyr', 'V': 'Val'
};

function parseAminoAcidSequence(input: string): string {
	// Reverse the aaDict to map three-letter codes to single-letter codes
	const reverseAaDict: { [key: string]: string } = {};
	for (const key in aaDict) {
		reverseAaDict[aaDict[key]] = key;
	}

	// Regular expression to match three-letter amino acid codes
	const regex = /([A-Z][a-z]{2})/g;

	// Replace matched patterns using the reverseAaDict
	let result = input.replace(regex, (match) => {
		return reverseAaDict[match] || match;
	});

	return result;
}

// Test cases
// console.log(parseAminoAcidSequence('Arg1423Pro')); // Output: A1423P
// console.log(parseAminoAcidSequence('Gly23ProArg')); // Output: G23PA
// console.log(parseAminoAcidSequence('ProArg13Trp')); // Output: PA13W
// console.log(parseAminoAcidSequence('ProArg13*'));   // Output: PA13*


interface AminoAcidDict {
	[key: string]: string;
}

const aaDictReverse: AminoAcidDict = Object.keys(aaDict).reduce((acc, key) => {
	acc[aaDict[key]] = key;
	return acc;
}, {} as AminoAcidDict);

function parseAminoAcids(input: string): string {
	const pattern = /([A-Z][a-z]{2})(\d+)([A-Z][a-z]{2}|\*)/g;
	let match;
	const results: string[] = [];

	// Match all occurrences in the input
	while ((match = pattern.exec(input)) !== null) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [_, amino1, number, amino2] = match;
		const single1 = aaDictReverse[amino1] || amino1;
		const single2 = aaDictReverse[amino2] || amino2;
		results.push(`${single1}${number}${single2}`);
	}

	return results.join('');
}


export { parseAminoAcids, parseAminoAcidSequence }