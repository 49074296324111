import React from 'react';
import { useContext } from "react";
import ThemeContext from "../../../../../Contexts/Theme/ThemeContext";
import { roundWithTooltipoTo0 } from "../../../../../Helpers/Helpers";
import BadgeVeredictPredictors from "../../../../Common/Badges/BadgePredictorsVeredict";
import PredictorsTemplateTable from './PredictorsTemplateTable';
import { VariantTranscriptI } from 'src/Contexts/interfaces';
import { MetaPredictorsNamesI } from './Predictors';

interface MetapredictorsTableI {
    infoCd: any,
    variantTranscript: VariantTranscriptI,
    defaultScoreValue: string
}
export default function MetapredictorsTable(props: MetapredictorsTableI) {
    const { theme } = useContext(ThemeContext);
    const vt: VariantTranscriptI = props.variantTranscript;
    if (!vt || !vt.predictors) return <></>;
    const mpredictors: MetaPredictorsNamesI = vt.predictors;
    
    return (
        <div style={{ display: "flex", width: "100%", backgroundColor: "#f9f9f9"}}>

            <PredictorsTemplateTable rowData={
                [
                    {
                        id: "revelScore",
                        label: <span style={{ fontWeight: "bold", color: theme.grayHeader }}>Revel score</span>,
                        score: mpredictors.REVELSCORE ? roundWithTooltipoTo0(mpredictors.REVELSCORE) : props.defaultScoreValue,
                        conclution: <BadgeVeredictPredictors type={"revel_score"} value={mpredictors.REVELSCORE} />,
                    },
                    {
                        id: "BayesDel",
                        label: <span style={{ fontWeight: "bold", color: theme.grayHeader }}>BayesDel</span>,
                    },
                    {
                        id: "addAF",
                        label: <>&nbsp;addAF</>,
                        score: mpredictors.BAYESDEL_ADDAF_SCORE ? roundWithTooltipoTo0(mpredictors.BAYESDEL_ADDAF_SCORE) : props.defaultScoreValue,
                        conclution: <BadgeVeredictPredictors type={"bayesdel_addaf"} value={mpredictors.BAYESDEL_ADDAF_SCORE} />,
                    },
                    {
                        id: "noAF",
                        label: <>&nbsp;noAF</>,
                        score: mpredictors.BAYESDEL_NOAF_SCORE ? roundWithTooltipoTo0(mpredictors.BAYESDEL_NOAF_SCORE) : props.defaultScoreValue,
                        conclution: <BadgeVeredictPredictors type={"bayesdel_noaf"} value={mpredictors.BAYESDEL_NOAF_SCORE} />,
                    }]}
                tableStyle={{ width: "100%" }}
                noDetail={true}
                noInfo={true}
            />
        </div>
    )
}