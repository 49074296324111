import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';
import { FilterDomainReqI, FiltersCasesDomainReqI, FiltersUserDomainReqI } from '../Contexts/Domains/DomainsContext';

export default class DomainVServices extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "domain/";
    }
    // domainidSearch: "2"
    // domainnameSearch: "domainName"
    // pageNumber: 1
    // pageSize: 25
    // sortColumn: ""
    // sortDirection: "DESC"
    // studynameSearch: "studyNameOrCode"
    // userId: "5"
    // usernameSearch: "username"
    async search(filters?: FilterDomainReqI, pageNumber: number = 1, pageSize: number = 25): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}search`
        const newFilters: any = {
            domainidSearch: filters?.domainidSearch,
            domainnameSearch: filters?.domainnameSearch,
            studynameSearch: filters?.studynameSearch,
            usernameSearch: filters?.usernameSearch,
            sortColumn: filters?.sortColumn
        }
        const data = {
            userId: `${this.user?.id}`,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sortDirection: "DESC",
            ...newFilters
        }
        return await axios.post(url, data, this.authHeader)
    }
    // domainId: "30"
    // tab: "study"
    //       ------------domain filters------------------------
    // nameSearch: "studyNameOrCode"
    // studyStateSearch: "ACTIVE"
    // dateCreatedFromSearch: "18/10/1999"
    // dateCreatedToSearch: "18/10/1999"
    //       ------------users filters------------------------
    // fullnameSearch : ""
    // usernameSearch : ""

    // sortColumn: "dateCreated"

    // pageNumber: 1
    // pageSize: 25
    // userId: 5
    async getDomain(domainId: string, pageNumber: number = 1, pageSize: number = 25, tab?: "study" | "user",
        filtersDomain?: FiltersCasesDomainReqI, filtersUsers?: FiltersUserDomainReqI): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getDomain`
        var filters: any = {}
        if (filtersDomain) filters = filtersDomain
        if (filtersUsers) filters = filtersUsers
        const data = {
            domainId: domainId,
            tab: tab ?? "study",
            userId: this.user?.id,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sortColumn: filters.sortColumn ?? filters.sortColumn !== "" ? filters.sortColumn : "dateCreated",
            sortDirection: "DESC",
            ...filters
        }
        return await axios.post(url, data, this.authHeader)
    }
    async create(domainDescription: string, domainName: string, enableDomain: boolean): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}save`
        const data = { domainDescription, domainName, enableDomain }
        return await axios.post(url, data, this.authHeader)
    }
    async update(domainId: string, domainDescription: string, domainName: string, enableDomain: boolean): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}update`
        const data = {
            domainDescription,
            domainName,
            enableDomain: enableDomain ? "on" : "off",
            id: domainId,
            user: `${this.user?.id}`,
        }
        return await axios.post(url, data, this.authHeader)
    }
    async move(domainId: string, newDomain: string, studies: string[]): Promise<AxiosResponse<any>> {
        // domainId: "26"
        // newDomainId: "33-TESTDOMAIN2"
        // studies: "696"
        const url = `${this.basePath}moveStudiesToDomain`
        const data = {
            domainId: domainId,
            newDomainId: newDomain,
            studies: studies.join(",")
        }
        return await axios.post(url, data, this.authHeader)
    }

    async delete(domainId: string) {
        const url = `${this.basePath}delete`
        const data = {
            domainId: domainId
        }
        return await axios.post(url, data, this.authHeader)
    }
}