import React from 'react';
import { useContext } from "react";
import ThemeContext from "../../../Contexts/Theme/ThemeContext";

type DetailLayoutT = "default" | "domainDetail"
interface DetailLayoutI {
    children: JSX.Element,
    DetailHeader: JSX.Element,
    DLType?: DetailLayoutT
}
export default function DetailLayout(props: DetailLayoutI) {
    const { theme } = useContext(ThemeContext);
    const DLType: DetailLayoutT = props.DLType ?? "default";
    const isDomainDetail = DLType === "domainDetail"
    return (
        <div id="DetailLayout" style={{ marginTop: `${isDomainDetail ? 0 : 25}px`, marginBottom: "30px" }}>
            {props.DetailHeader}
            <hr style={{ margin: "5px 0 0" }} />
            <div style={{
                margin: `${isDomainDetail ? 53 : 0}px 0 30px`,
                // minWidth: "100%",
                minWidth: `${isDomainDetail ? 100 : 0}px`,
                width: isDomainDetail ? "calc(100vw - 55px)" : "100%",  // pasar desde parent 
                padding: "15px",
                backgroundColor: theme.backgroundPrimary,
                paddingTop: isDomainDetail ? "0" : "20px",
                boxShadow: "rgb(124 124 124 / 35%) 0px 5px 10px 0px"
            }}>
                {props.children}
            </div>
        </div>
    )
}