import React from 'react';
import { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import { LangContext } from '../../../Contexts/Lang';
import { studyType, studySubtypeT } from '../Interfaces';

interface BadgeSampleTypeSubtypeI {
    type: studyType,
    subtype: studySubtypeT,
    fontSize?: string,
    style?: any
}
export default function BadgeSampleTypeSubtype(props: BadgeSampleTypeSubtypeI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const fontSize = props.fontSize ?? "10px"
    const style = props.style ?? {}
    const getType = () => {
        const subtype = translate(`home.table.subTypes.${props.subtype.toLowerCase()}`) || false;
        return `${props.type} - ${subtype}`
    }
    return (
        <Badge variant="info"
            style={{
                fontWeight: "inherit",
                fontSize: fontSize,
                backgroundColor: "#009EEA",
                marginRight: "5px",
                ...style
            }}>
            {getType()}
        </Badge>
    )
}