import { createContext } from "react";
import { VariantsOrderByValueT } from "../../utils/BioFunctionalData";
import { QueryGeneVariantsI, QueryVariantsI } from "../interfaces";
import { AsyncDataI } from "../interfaces";
import { state_request_dictI, TabsI } from "./QueryState";

type ColumnIdT = "variant" | "gene" | "consecuence" | "impactoEfecto" | "classification" | "frecuence" | "evidence" | "sampleInfo" | "mitoMap";
// type viewtype = "gene" | "variant"
type QueryVariantsTableT = "default" | "queryGenes";

interface TableTitleI {
	type: ColumnIdT,
	title: string,
	checked: boolean,
	sortBy?: { sort: VariantsOrderByValueT, tooltipLabel: string }[]
}

// --- Filters ----------------------------
interface FiltersPropsQueryI {              //TODO englobar todos los filtros en un solo estado "filters", dentro cada uno de los tipos de filtros
	filters_initialState?: FiltersPropsQueryI,
	filters_samples: {
		samples: any[],
		operations: any,
		diseaseModel: any
	},
	filters_variants: {
		code: string,
		relevance: any[],
		excludeComments: boolean,
		disease: Object,
		genotype: Object,
		cromosome: string,
		variantTypes: any[],
		multipleAlleles: {}, //all|yes|no
	},
	filters_qc: {
		filter: {},
		depth: number[],
		ratio: number,
		genotypeQuality: number,
		qual: number,
	},
	filters_frequency: {
		frecGnomAdExomes: number[],
		frecGnomAdGenomes: number[]
	},
	filters_predictors: {
		revel: number,
		bayesdel: number,
		bayesdelnoaf: number,
		spliceai: number,
		mcap: number,
		dann: number,
		mutationTaster: any[],
		sift: any[],
		polyphen: any[]
	},
	filters_genes: {
		genName: string,
		panel: any[],
		excludePanel: boolean,
		omimgenes: boolean,
		gnomadpli: number,
		gnomadzmiss: number[],
	},
	filters_phenotypes: {
		aux_search: string
		phenotype: string[],
	},
	filters_variantgroups: {
		groups: string[],
		excludeGroups: boolean
	},
	filters_consequence: {
		impact: string[],
		effect: string[],
		excludeEffects: boolean
	},
	filters_evidence: {
		veredict: string[],
		excludeVeredict: boolean,
		acmgBayClasification: string[],
		excludeAcmgBayClasification: boolean,
		acmgBayScore: number,
		clinvar: string[],
		excludeClinvar: boolean,
		dbsnp: {}
	},
	filters_mitochondrial: {
		reference: {},
		scoreInterpretation: string[],
		apogeescore: number,
		diseaseStatus: string[],
		heteroplasmy: number
		afhomoplasmy: number
	},
	filters_somatic: {
		oncokb: boolean,
		cosmic: boolean,
		civic: boolean,
		hotspots: boolean,
		pmkb: boolean,
		hotspotsorgantypes: string[],
		pmkbtiers: string[],
		oncoevidence: boolean,
		vaf: number,
		pon: boolean
	},
	filters_pharmgkb: {
		pharmGkb: string[],
		evidence: string[]
	},
}
interface AsyncQueryVariantsI extends AsyncDataI {
	data: null | QueryVariantsI
}

interface ContextDataQueryI extends FiltersPropsQueryI {
	// ---------------------------- Async data ----------------------------
	// Data with variants query    
	variant: AsyncDataI,                                    //TODO: especificar datos con interface
	queryVariants: AsyncQueryVariantsI,
	genesVariants: AsyncDataI & { data: {} | QueryGeneVariantsI },
	// Comobos to fill query panel (collapsable on left side)
	hpoPhenotypes: AsyncDataI,
	queryCombos: AsyncDataI & {
		data?: {
			panels: any[],
			variantGroups: any[],
			savedFilters: any[],
			study: any,
		}
	},
	// ---------------------------- Sync data ----------------------------
	// ------------- tabs
	tabs: any,
	tabSelected: any,
	tabsMax: number,
	// ------------- tabs
	badgesFilters: [],
	scrollYPosition: {
		y: number | null,
		height: number | null,
	}
}
interface ContextPropsQueryI extends ContextDataQueryI {
	state_request_dictParser: state_request_dictI[],
	studyId: string,  // this field is the first to be set. From this, we can get all the other methods.
	//------- Filters Parameters ----------------------------------------------
	filters_samplesParams: any,
	filters_variantsParams: any,
	filters_qcParams: any,
	filters_frequencyParams: any,
	filters_predictorsParams: any,
	filters_genesParams: any,
	filters_phenotypesParams: any,
	filters_variantgroupsParams: any,
	filters_consequenceParams: any,
	filters_evidenceParams: any,
	filters_mitochondrialParams: any,
	filters_somaticParams: any,
	filters_pharmgkbParams: any,
	//------- Filters Parameters ----------------------------------------------
	// ------------------- Async --------------------------------------
	dispatch: {
		getVariant: (fstudyId: number, variantId: string) => Promise<void>;
		refreshVariant: () => void;
		getQueryVariants: (filters?: any, pageNumber?: number, pageSize?: number, sortDirection?: string, sortColumn?: VariantsOrderByValueT[]) => Promise<void>;
		getGenesVariants: (geneName: string, variantsId: string, pageNumber?: number, pageSize?: number, sortBy?: string) => Promise<void>;
		refreshGenesVariants: () => void;
		getPhenotypes: (filters?: any, pageNumber?: number, pageSize?: number) => Promise<void>;
		getQueryCombos: (id: number) => Promise<void>;
		// ------------------- Sync --------------------------------------
		cleanQueryVariants: () => void;
		refreshQueryVariants: () => void;

		// -------------------------- Filters --------------------------
		getSamplesLabelValue(): void,
		// getBadgesFiltersData(): { value: any, name?: string, label?: string, reference?: any }[],
		setBadgesFiltersData(): void,
		cleanBadgesFiltersData(): void,
		parseFilters_StateToRequest(): any,
		parseFilters_RequestToState(urlData: string, getArrayParams?: boolean): void | string[] | undefined,

		setfilters_samples: (value: any) => void;
		cleanfilters_samples: () => void;
		setfilters_variants: (value: any) => void;
		cleanfilters_variants: () => void;
		setfilters_qc: (value: any) => void;
		cleanfilters_qc: () => void;
		setfilters_frequency: (value: any) => void;
		cleanfilters_frequency: () => void;
		setfilters_predictors: (value: any) => void;
		cleanfilters_predictors: () => void;
		setfilters_genes: (value: any) => void;
		cleanfilters_genes: () => void;
		setfilters_phenotypes: (value: any) => void;
		cleanfilters_phenotypes: () => void;
		setfilters_variantgroups: (value: any) => void;
		cleanfilters_variantgroups: () => void;
		setfilters_consequence: (value: any) => void;
		cleanfilters_consequence: () => void;
		setfilters_evidence: (value: any) => void;
		cleanfilters_evidence: () => void;
		setfilters_mitochondrial: (value: any) => void;
		cleanfilters_mitochondrial: () => void;
		setfilters_somatic: (value: any) => void;
		cleanfilters_somatic: () => void;
		setfilters_pharmgkb: (value: any) => void;
		cleanfilters_all: () => void;
		cleanfilters_pharmgkb: () => void;
		// --------------------------
		// ------------------- TABS --------------------------------------
		getTabsId: () => string;
		getTabSelectedId: () => string;
		getActualTabs: () => TabsI[] | undefined;
		getActualTabSelected: () => TabsI | undefined;
		getActualTabSelectedId: () => number | undefined;
		setFiltersTabsAndSessionS: (newFilterTabs: any) => void;
		setFiltersTabSelectedAndSessionS: (id: number) => void;
		getFiltersTabsFromSessionS: () => { tabs: TabsI[], selected: TabsI };
		CleanFiltersBadgesAndTable: () => void;
		SetFiltersTabSelected: (params: string) => void;
		AddFiltersTabs: (urlData: string, filterName: string, id: number, description?: string) => void;
		RemoveFiltersTabs: (id: number, clean: boolean) => void;
		// ------------------- TABS --------------------------------------
		SetOrderBySS: (value: any) => void;
		setQueryVariantsFromTabs: (tabId: number) => void;
		SetQueryColumnsOnLS: (tableData?: (TableTitleI[] | undefined)) => void;
		SetScrollYPosition: (position: number | null) => void
		// setBadges: (key: string, value: string) => void;

	}
}

const QueryContext = createContext({} as ContextPropsQueryI);

export default QueryContext;
export type { ColumnIdT, QueryVariantsTableT, TableTitleI, FiltersPropsQueryI, ContextDataQueryI, ContextPropsQueryI, AsyncQueryVariantsI };