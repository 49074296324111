import React from 'react';
import { useContext } from "react";
import { LangContext } from "../../Contexts/Lang";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Popover } from './Popover';
import { checkValue } from "../../utils/helpers";
import UserContext from "../../Contexts/User/UserContext";
import ThemeContext from "src/Contexts/Theme/ThemeContext";
import Frequency from './Frequency';

interface FrequencyI {
    infoCD?: any,
    hr?: boolean,
}
function VariantFrequency(props: FrequencyI) {
    const { user } = useContext(UserContext);
    const { theme } = useContext(ThemeContext);
    const hr = props.hr ?? true
    const infoCD = props.infoCD;

    let frequencies: Frequency;
    frequencies = new Frequency(props.infoCD);
    const { dispatch: { translate } } = useContext(LangContext);
    const existCAF = (caf: any) => { return (caf && typeof caf != 'undefined' && caf.cafAlt); }
    const existExAC = (exac: any) => { return (typeof exac != 'undefined'); }
    const existZExAC = (exac: any) => { return (exac && typeof exac != 'undefined'); }
    const existMAF = (maf: any) => { return (maf && typeof maf != 'undefined' && maf.mafAll); }
    type freqsTypes = "gnomad" | "exac" | "tgp" | "account" | "community";
    let listOfFreqs: freqsTypes[] = []
    var frequencyComponent: any = "";
    const warningnIcon = <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: "5px", border: "none", fontSize: "12px", color: "#F28B00" }} />
    const hrComponent = hr ? <hr style={{ margin: "0px" }} /> : <br style={{ margin: "1px" }} />
    if (infoCD) {
        var threshold = 0.02,
            exac,
            Exac_Af = infoCD.EXAC_AF,
            hom: any = "",
            warn: any = "",
            // gnomad_exome: any = "3.50412344e-05",//test values
            // gnomad_genome: any = "8.504e-05",//test values
            AC_Adj = infoCD.AC_ADJ,
            AN_Adj = infoCD.AN_ADJ
        if (frequencies.gnomadExome.alleleFreq || frequencies.gnomadExome.alleleFreq === "0") {
            if (frequencies.gnomadExome.hasWarning) warn = warningnIcon
            const popMaxAlleleCount = frequencies.gnomadExome.popMaxAlleleCount;
            const popMaxNHom = frequencies.gnomadExome.popMaxNHom;
            frequencyComponent = (
                <>
                    <b style={{ color: theme.grayHeader }}>GnomAD exomes: </b>
                    {(parseFloat(frequencies.gnomadExome.alleleFreq ?? "0") * 100).toFixed(2)} %
                    <span style={{ fontSize: 9, verticalAlign: "middle" }}>
                        &nbsp;({frequencies.gnomadExome.alleleFreq})
                    </span> {warn}<br />
                    {popMaxAlleleCount ? <span style={{ fontSize: 11 }}>
                        {translate("common.GNOMAD_EXOME_POPMAX_AC")}&nbsp;:&nbsp;{popMaxAlleleCount}<br />
                    </span> : <></>}
                    {popMaxNHom ? <span style={{ fontSize: 11 }}>
                        {translate("common.GNOMAD_EXOME_POPMAX_NHOM")}&nbsp;:&nbsp;{popMaxNHom}<br />
                    </span> : <></>}
                    {hrComponent}
                </>
            )
            listOfFreqs.push("gnomad")
        } else {
            //FIXME DEPRECATED
            if (existExAC(AC_Adj) && existZExAC(AN_Adj) && AN_Adj > 0) {
                exac = AC_Adj / AN_Adj;
                if (AC_Adj === 0)
                    hom = (<small>Allele Number = 0<br /></small>);
                if (infoCD.AC_HOM === 0)
                    hom = (<>
                        {hom} <small>(ExAC-hom: {infoCD.AC_HOM} )</small>
                    </>)
            } else {
                if (existZExAC(Exac_Af)) {
                    if (Array.isArray(Exac_Af))
                        exac = Exac_Af[0]
                    else
                        exac = Exac_Af;
                }
            }
            if (existExAC(exac)) {
                if (exac < frequencies.threshold) warn = warningnIcon;
                frequencyComponent = <>
                    {frequencyComponent} <b style={{ color: theme.grayHeader }}>ExAC: </b> {exac.toFixed(4)} {warn}
                </>
                listOfFreqs.push("exac")
                if (hom) frequencyComponent = <>{frequencyComponent}<br />{hom};</>
            }
            if (frequencyComponent !== "")
                frequencyComponent = (<>
                    {frequencyComponent}
                    {hr && <hr style={{ margin: '0px' }} />}
                </>)
        }
        if (frequencies.gnomadGenome.alleleFreq || frequencies.gnomadGenome.alleleFreq === "0") {
            const popMaxAlleleCount = frequencies.gnomadGenome.popMaxAlleleCount;
            const popMaxNHom = frequencies.gnomadGenome.popMaxNHom;
            if (frequencies.gnomadGenome.hasWarning) warn = warningnIcon;
            frequencyComponent = <>
                {frequencyComponent}
                <b style={{ color: theme.grayHeader }}>GnomAD genomes: </b>
                {(+`${frequencies.gnomadGenome?.alleleFreq ?? 0}` * 100).toFixed(2)} %
                <span style={{ fontSize: 9, verticalAlign: "middle" }}>
                    &nbsp;({frequencies.gnomadGenome.alleleFreq})
                </span>{warn}<br />
                {popMaxAlleleCount ? <span style={{ fontSize: 11 }}>
                    {translate("common.GNOMAD_EXOME_POPMAX_AC")}&nbsp;:&nbsp;{popMaxAlleleCount}<br />
                </span> : <></>}
                {popMaxNHom ? <span style={{ fontSize: 11 }}>
                    {translate("common.GNOMAD_EXOME_POPMAX_NHOM")}&nbsp;:&nbsp;{popMaxNHom}<br />
                </span> : <></>}
                {hrComponent}
            </>
            listOfFreqs.push("gnomad")
        } else {
            //FIXME DEPRECATED
            if (existCAF(infoCD.CAF)) {
                warn = warningnIcon;
                frequencyComponent = <> {frequencyComponent} <b style={{ color: theme.grayHeader }}>TGP: </b> {infoCD.CAF.cafAlt.toFixed(4)} {warn} {hrComponent}</>
                listOfFreqs.push("tgp")
            } else {
                if (existMAF(infoCD.MAF)) {
                    var maf = (infoCD.MAF.mafAll / 100);
                    if (maf < threshold) warn = warningnIcon;
                    frequencyComponent = <> {frequencyComponent} <b style={{ color: theme.grayHeader }}>TGP: </b> {maf.toFixed(4)} {warn} {hrComponent}</>
                    listOfFreqs.push("tgp")
                }
            }
        }
        if (frequencies.frequencies.length > 0 && frequencies.domains.length > 0) {
            var idxa = frequencies.domains.indexOf('-1'),
                idxi = frequencies.domains.indexOf(user.data.domain.toString());
            if (idxi >= 0) {
                let freqdomstring = frequencies.frequencies[idxi].toString() + "/" + frequencies.countsamples[idxi].toString();
                frequencyComponent = <>{frequencyComponent}<b style={{ color: theme.grayHeader }}> {translate('common.account')}: </b> {freqdomstring} {hr && <hr style={{ margin: "0" }} />}</>

                listOfFreqs.push("account")
            }
            if (idxa >= 0) {
                let freqall = frequencies.frequencies[idxa] / frequencies.countsamples[idxa];
                frequencyComponent = <>{frequencyComponent}<b style={{ color: theme.grayHeader }}> {translate('common.community')}: </b> {freqall.toFixed(4)} {hr && <hr style={{ margin: "0" }} />}</>
                listOfFreqs.push("community")
            }
        }
    }
    if (listOfFreqs.length > 0) {
        const getPopoverTitle = (ft: freqsTypes) => {
            switch (ft) {
                case "gnomad": return "GnomAD";
                case "exac": return "ExAC";
                case "tgp": return "TGP";
                case "account": return translate('common.account');
                case "community": return translate('common.community');
            }
        }
        frequencyComponent = <Popover style={{ fontSize: "11px" }}
            trigger={"hover"}
            content={<div>
                {listOfFreqs.map((x: freqsTypes, i: number) => {
                    return <div key={i}>
                        <strong> {getPopoverTitle(x)} : </strong>
                        {translate(`casesQuery.frequencyDescriptors.${x}`)} <br />
                    </div>
                })}
            </div>}>
            <div style={{}}>
                {frequencyComponent}
            </div>
        </Popover>
    }
    return <>{frequencyComponent}</>;
}
interface FrequencyMitochondrialI {
    infoCD?: any,
}
function FrequencyMitochondrial(props: FrequencyMitochondrialI) {
    const infoCd = props.infoCD;
    const getLabelValue = (label: string, value: any) => {
        return (<span>
            <span style={{ fontWeight: 'bold', margin: "5px" }}>
                {label}:
            </span>
            <span>
                {value}
            </span>
        </span>)
    }
    let het = checkValue(infoCd.AF_HET);
    let hom = checkValue(infoCd.AF_HOM);
    const roundNumber = (n: any) => {
        if (n !== "--" && !isNaN(n))
            return Math.round(n * 100000) / 100000;
        return n
    }
    return (
        <span>
            {getLabelValue("Source", "gnomAD")}
            {getLabelValue("Heteroplasmic AF", roundNumber(het))}
            {getLabelValue("Homoplasmic AF", roundNumber(hom))}
        </span>
    )
}

export { VariantFrequency as Frequency, FrequencyMitochondrial }