import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';

export default class GenomicVServices extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "genomicv/";
    }

    //-----------bam to variant----------------------------
    // getBam?
    // reference=GRCh37&
    // domain=26&
    // sample=PMP3_25&
    // geneName=GJB4&
    // variant=1%3A35227007&
    // view=variantLocus&
    // _=1638537751469
    //-----------bam to gen----------------------------
    // reference=GRCh37&
    // domain=26&
    // sample=PMP3_25&
    // geneName=GJB4&
    // variant=1%3A35227007&
    // view=geneLocus&
    // _=1638538164674
    async get_bam(assembly: string, sample: number, geneName: string, variant: string, view: "variant" | "gene", library: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getBam`
        const config = {
            ...this.authHeader,
            params: {
                reference: assembly,
                domain: this.user?.domain,
                sample: sample,
                geneName: geneName,
                variant: variant.replace("chr","").replace("ch",""), //ch11:11189-11195 con view en variantLocus!
                // variant: variant, //ch11:11189-11195 con view en variantLocus!
                view: view === "variant" ? "variantLocus" : "geneLocus",
                library: library,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }
}

