import React from 'react';
import { useContext } from "react";
import { LangContext } from "../../../../Contexts/Lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FrequencyMitochondrial } from "../../../Common/VariantFrequency";
import { LinkGnomAd } from '../../../Common/Links/Links';
import TableVariantFrequency from 'src/Components/Common/TableVariantFrequency';

interface GnomAdTableI {
    variantData?: any,
}
export default function GnomAdTable(props: GnomAdTableI) {
    const variantData = props.variantData

    const { dispatch: { translate } } = useContext(LangContext);
    const isMitochondrial = (variantData?.study?.subtype ?? "").toUpperCase().trim() === "MT-DNA";

    const gnomAdBrowserComp = (<>
        <span style={{ margin: "0 5px", float: "right" }}>
            <LinkGnomAd
                variant={{
                    chromosome: variantData?.variant?.chromosome,
                    chPosition: variantData?.variant?.position,
                    reference: variantData?.variant?.reference,
                    alternative: variantData?.variant?.alternative,
                    genomeRef: variantData?.study?.genomeReference
                }}
                label={`${variantData?.variant?.chromosome}-${variantData?.variant?.position}-${variantData?.variant?.reference}-${variantData?.variant?.alternative}`}
                fontSize={"13px"} />
            {
                variantData?.variant?.infoColumnData?.GENE_NAME &&
                <> |
                    <span style={{ margin: "0 5px" }}>
                        <LinkGnomAd geneName={variantData?.variant?.infoColumnData?.GENE_NAME} fontSize={"13px"}
                            label={variantData?.variant?.infoColumnData?.GENE_NAME} />
                    </span>
                </>
            }
        </span>
    </>)
    return (
        <div id="GnomaAdTable" style={{}}>
            <label style={{
                margin: "0 10px 0px",
                color: "gray",
                fontWeight: "bold",
                fontSize: "14px",
                paddingBottom: "2px",
                borderBottom: "solid 1px #e4e4e4",
                width: "-webkit-fill-available"
            }}>
                &nbsp;<FontAwesomeIcon icon={faUsers} style={{ color: "lightgray", fontSize: "14px" }} />
                &nbsp;
                {translate("variantDetail.populationFrequency")}
                &nbsp;&nbsp;-&nbsp;gnomAD
				{gnomAdBrowserComp}
            </label>
            <br/>
            <div style={{ margin: "0px 15px", fontSize: "13px" }}>
                {isMitochondrial ?
                    <FrequencyMitochondrial infoCD={variantData?.variant?.infoColumnData} /> :
                    <TableVariantFrequency infoCd={variantData?.variant?.infoColumnData} />
                }
            </div>
        </div>
    )
}