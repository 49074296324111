import React from 'react';
import { useContext } from "react";
import { AsyncDataI } from "src/Contexts/interfaces";
import { LangContext } from "src/Contexts/Lang";
import DetailQueryTable, { DetailQueryTableRowI } from "./DetailQueryTable";
import { getCivicLinkFromInfoCD, getCosmicLinkFromInfoCD, getHotspotsLinkFromInfoCD, getOncoKbLinkFromInfoCD, LinkCancerVariants, LinkMyCancerGenome, LinkOncoTree, PmkbDbIcon } from 'src/Components/Common/Somatic/DbLinks';
import { Badge } from 'react-bootstrap';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import HotspotsDetail from '../../../Common/Somatic/HotspotsDetail';
import OncokbDetail from 'src/Components/Common/Somatic/OncokbDetail';
import PmkbDetail from 'src/Components/Common/Somatic/PmkbDetail';

interface SomaticTableI {
	studyId: number,
	variantId: string,
	variant: AsyncDataI,
}

export default function SomaticTable(props: SomaticTableI) {
	const { theme } = useContext(ThemeContext);
	const { dispatch: { translate } } = useContext(LangContext);
	const variant = props.variant.data;
	let infoCd: any = variant?.variant?.infoColumnData;
	// infoCd["PMKB_VARIANT"] = "mutation,_mutation,_undefined,_undefined,_undefined,_undefined,_undefined,_undefined,_undefined";
	// infoCd["PMKB_TUMOR_TYPES"] = "Acute_Myeloid_Leukemia|Myelodysplastic_Syndrome|Chronic_Myelomonocytic_Leukemia|Atypical_Chronic_Myeloid_Leukemia|Primary_Myelofibrosis|T_Lymphoblastic_Leukemia/Lymphoma|Acute_Leukemia_of_Unspecified_Cell_Type|Anemia,Unspecified|B_Lymphoblastic_Leukemia/Lymphoma|Chronic_Myeloid_Leukemia|Chronic_Neutrophilic_Leukemia|Cytopenia|Eosinophilia|Essential_Thrombocythemia|Histiocytic_and_Dendritic_Cell_Neoplasms|Langerhans_Cell_Histiocytosis|Leukocytosis|Leukopenia|Mast_Cell_Neoplasm|MDS_with_Ring_Sideroblasts|Monocytosis|Myelodysplastic/Myeloproliferative_Neoplasm|Myeloproliferative_Neoplasm|Myeloid_Neoplasm|Other_Acute_Leukemia|Polycythemia_Vera|Polycythemia|Thrombocytopenia,Unspecified|Thrombocytosis,_Acute_Myeloid_Leukemia|Acute_Leukemia_of_Unspecified_Cell_Type|Anemia,Unspecified|Atypical_Chronic_Myeloid_Leukemia|B_Lymphoblastic_Leukemia/Lymphoma|Chronic_Myelomonocytic_Leukemia|Chronic_Neutrophilic_Leukemia|Cytopenia|Leukocytosis|Leukopenia|MDS_with_Ring_Sideroblasts|Monocytosis|Myelodysplastic_Syndrome|Myelodysplastic/Myeloproliferative_Neoplasm|Myeloid_Neoplasm|Myeloproliferative_Neoplasm|Other_Acute_Leukemia|Primary_Myelofibrosis|Thrombocytopenia,Unspecified|Thrombocytosis|T_Lymphoblastic_Leukemia/Lymphoma,_Urothelial_Carcinoma,_Ganglioglioma,_Adenocarcinoma|Carcinoma,_Adenocarcinoma,_Urothelial_Carcinoma,_-,_Adenocarcinoma";
	// infoCd["PMKB_TISSUE_TYPES"] = "Blood|Bone_Marrow|Kidney|Ureter,_Blood|Bone_Marrow,_Kidney|Ureter,_Brain,_Esophagus|Gastroesophageal_Junction,_Endometrium,_Bladder,_Ampulla_(Pancreaticobiliary_Duct)|Pancreas,_Prostate";
	// infoCd["PMKB_TIER"] = "1,_2,_2,_2,_2,_2,_2,_2,_2";
	// infoCd["PMKB_URL"] = "http://pmkb.weill.cornell.edu/therapies/34,_http://pmkb.weill.cornell.edu/therapies/2283,_http://pmkb.weill.cornell.edu/therapies/316,_http://pmkb.weill.cornell.edu/therapies/406,_http://pmkb.weill.cornell.edu/therapies/294,_http://pmkb.weill.cornell.edu/therapies/274,_http://pmkb.weill.cornell.edu/therapies/250,_http://pmkb.weill.cornell.edu/therapies/247,_http://pmkb.weill.cornell.edu/therapies/292";
	const getSomaticTableData = (): DetailQueryTableRowI[] => {
		const thereIsGene = (infoCd?.AA_CHANGE ?? "").length > 2
		const thereIsOncokbMutation = infoCd.ONCOKB_ALTERATION && infoCd.ONCOKB_ALTERATION.trim().length > 2;
		const thereIsPmkb = infoCd.PMKB_VARIANT && infoCd.PMKB_VARIANT.trim().length > 2;
		const is_oncogene = infoCd?.ONCOKB_IS_ONCOGENE ? true : false;
		const is_tsg = infoCd?.ONCOKB_IS_TUMOR_SUPPRESSOR ? true : false;
		// const thereIsOncokbGene = is_oncogene || is_tsg;
		const getGene_OncogeneOrTSG = () => {
			if (!is_oncogene && !is_tsg) return ("")
			const label = is_oncogene && is_tsg ? `${translate("common.oncogene")} / ${translate("common.tumorSuppressorGene")}` :
				is_oncogene ? translate("common.oncogene") :
					is_tsg ? translate("common.tumorSuppressorGene") : ""
			return (
				<Badge style={{ backgroundColor: "#EEE", fontSize: 11, fontWeight: 100 }}>
					{label}
				</Badge>
			)
		}
		const getMyCancerGenomeLinkFromInfoCD = (infoCd: any) => {
			if (thereIsGene) {
				return <LinkMyCancerGenome aaChange={infoCd.AA_CHANGE} geneName={infoCd.GENE_ID} parse={true} />
			}
			return <LinkMyCancerGenome />
		}
		let dataTableSomatic: DetailQueryTableRowI[] = []
		if (infoCd) {
			const cosmicLink: any = getCosmicLinkFromInfoCD(infoCd)
			const civicLink: any = getCivicLinkFromInfoCD(infoCd)
			const hotspotsLink: any = getHotspotsLinkFromInfoCD(infoCd)
			const myCancerGenomeLink: any = getMyCancerGenomeLinkFromInfoCD(infoCd)
			// if (cosmicLink || civicLink || hotspotsLink) {
			dataTableSomatic = [
				...dataTableSomatic,
				{
					id: "somaticEvidence",
					label: translate("common.evidence"),
					value: <div style={{
						verticalAlign: "middle",
						display: "flex",
						justifyContent: "space-between"
					}}>
						<div >
							<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
								{translate("common.variant")}
							</span>
							&nbsp;: &nbsp;
							{cosmicLink ? <>{cosmicLink}&nbsp;</> : ""}
							{civicLink ? <>{civicLink}&nbsp;</> : ""}
							{thereIsGene ? myCancerGenomeLink : ""}
						</div>
						<div >
							<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
								{translate("common.gene")}
							</span>
							&nbsp;: &nbsp;
							{getGene_OncogeneOrTSG()}&nbsp;&nbsp;
							{!thereIsOncokbMutation && getGene_OncogeneOrTSG() !== "" ? getOncoKbLinkFromInfoCD(infoCd, infoCd.GENE_ID, undefined, "gene") : <></>}
						</div>

						<div style={{}}>
							| &nbsp;
							<small style={{ color: theme.grayHeader }}>
								{translate("common.usefulLinks")}
							</small>
							&nbsp;: &nbsp;
							<>
								{/* <LinkPMKB />,&nbsp; */}
								{!thereIsPmkb ? <>{PmkbDbIcon({ defaultLink: true })} ,&nbsp;</> : ""}
								<LinkOncoTree />
								{thereIsGene ? <>,&nbsp;<LinkCancerVariants aaChange={infoCd?.AA_CHANGE} geneName={infoCd?.GENE_ID} /> </> : ""}
								{!thereIsGene ? <>,&nbsp;{myCancerGenomeLink} </> : ""}
							</>
						</div>
					</div>
				}
			]
			// }
			if (thereIsOncokbMutation) {
				dataTableSomatic = [
					...dataTableSomatic,
					{
						id: "oncokb",
						label: "OncoKB",
						value: <OncokbDetail infoCd={infoCd} />
					}
				]
			}
			if (thereIsPmkb) {
				dataTableSomatic = [
					...dataTableSomatic,
					{
						id: "pmkb",
						label: "PMKB",
						value: <PmkbDetail infoCd={infoCd} />
					}
				]
			}
			if (hotspotsLink) {
				dataTableSomatic = [
					...dataTableSomatic,
					{
						id: "hotspots",
						label: "Cancer Hotspots",
						value: <HotspotsDetail infoCd={infoCd} />
					}
				]
			}
		}
		return dataTableSomatic
	}
	const dataTableSomatic = getSomaticTableData()

	return (
		<DetailQueryTable id="DetailQueryTable_annotations"
			title={
				<>
					{/* {/* <FontAwesomeIcon icon={faPrescriptionBottle} style={{ color: "lightgray", float: "right", margin: "4px 5px 0 0", fontSize: "15px" }} /> 
					<FontAwesomeIcon icon={faVial} style={{ color: "lightgray", float: "right", margin: "4px 5px 0 0", fontSize: "15px" }} /> */}
					{/* <FontAwesomeIcon icon={faDisease} style={{ color: "lightgray", float: "right", margin: "4px 5px 0 0", fontSize: "15px" }} /> */}
					{/* <FontAwesomeIcon icon={fa6 FaPrescriptionBottleMedical} style={{ color: "lightgray", float: "right", margin: "4px 5px 0 0", fontSize: "15px" }} /> */}
					&nbsp;{translate("casesQuery.oncogenicityEvidence")}
				</>
			}
			data={dataTableSomatic}
		/>
	)
}