import React from 'react';
import { useContext } from "react";
import { Badge } from "react-bootstrap";
import { StudyI } from "../../../Contexts/interfaces";
import { LangContext } from "../../../Contexts/Lang";
import { Tooltip } from "../Tooltip/Tooltip";
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { alleleClassifier } from 'src/utils/BioHelpers';


interface BadgeZygosityI {
    type: "homref" | "hom" | "het" | "notcov" | "hem" | any,
    fontSize?: string
}

function BadgeZygosity(props: BadgeZygosityI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);

    let settings: any = {};
    switch (props.type) {
        case "het":
            settings.label = "Het";
            settings.variant = "info";
            settings.backgroundColor = theme.heterozygous;
            settings.tooltipLabel = translate(`common.zygosityOptions.het`);
            break;
        case "hom":
            settings.label = "Hom";
            settings.variant = "danger";
            settings.tooltipLabel = translate(`common.zygosityOptions.hom`);
            settings.backgroundColor = theme.redBitgenia;
            break;
        case "hem":
            settings.label = "Hem";
            settings.variant = "danger";
            settings.tooltipLabel = translate(`common.zygosityOptions.hem`);
            settings.backgroundColor = theme.redBitgenia;
            break;
        case "homref":
            settings.label = "Hom Ref";
            settings.variant = "warning";
            settings.backgroundColor = theme.warning;
            settings.tooltipLabel = translate(`common.zygosityOptions.homref`);
            break;
        case "notcov":
            settings.label = "Not Cover";
            settings.variant = "warning";
            settings.backgroundColor = theme.warning;
            settings.tooltipLabel = translate(`common.zygosityOptions.notcov`);
            break;
    }
    if (Object.keys(settings).length === 0) return (<></>)
    return (
        <Tooltip label={settings.tooltipLabel}>
            <Badge variant={settings.variant}
                style={{
                    fontWeight: "bold",
                    fontSize: props.fontSize ?? "14px",
                    color: "white",
                    padding: "3px 5px 4px",
                    // verticalAlign: "bottom",
                    verticalAlign: "middle",
                    margin: "0 2px",
                    backgroundColor: settings.backgroundColor ? settings.backgroundColor : "auto"
                }}>
                {settings.label}
            </Badge>
        </Tooltip>
    )
}
interface BadgeZygosityByAlleleI {
    alleles: string[];
    study: StudyI,
    chromosome: string,
    isMale: boolean,
    isMitochondrial: boolean,
    fontSize?: string,
}
function BadgeZygosityByAllele(props: BadgeZygosityByAlleleI) {
    let vType = alleleClassifier(props.alleles.toString(), props.chromosome, props.isMale)
    // console.log(vType)
    // console.log(props.chromosome)
    // console.log(props.alleles.toString())
    return props.isMitochondrial ? <></> :
        <BadgeZygosity type={vType} fontSize={props.fontSize} />
}

export { BadgeZygosity, BadgeZygosityByAllele }