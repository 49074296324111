import React from 'react';
import './styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faBusinessTime } from '@fortawesome/free-solid-svg-icons';
import HealthCheckService from '../../Services/HealthcheckService';
import { useEffect } from 'react';
// import { Button } from 'react-bootstrap';

interface MaintenancePageI {
    history: any,
    match: any
}

export default function MaintenancePage(props: MaintenancePageI) {
    let HCS = new HealthCheckService()
    useEffect(() => {
        localStorage.removeItem("_Buser")
        const backendHealthChecker = () => {
            // const TIMEOUT = 3000;
            // let didTimeOut = false;
            return new Promise(function (resolve, reject) {
                // const timeout = setTimeout(() => { didTimeOut = true; }, TIMEOUT);

                HCS.getCheck()
                    .then((res: any) => {
                        // clearTimeout(timeout);
                        if (HCS.check(res)) props.history.push('/login')
                        // if (didTimeOut)
                    })
                    .catch(() => { });
            })
        }
        backendHealthChecker()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="background">
            <section style={{ "background": "rgb(90,90,90,0.2)", width: "100%", height: "350px" }}>
                <div style={{ textAlign: "center", placeContent: "center", marginTop: "3vh", display: "flex" }}>
                    <div>
                        <div style={{ height: "250px", width: "250px", borderRadius: "300px", backgroundColor: "white", margin: "0 100px" }}>
                            <FontAwesomeIcon icon={faTools} style={{
                                marginLeft: "5px",
                                border: "none",
                                color: "#DC3545",
                                fontSize: "130px",
                                marginTop: "62px"
                            }} />
                        </div>
                        <p style={{ textAlign: "center", margin: "0px", color: "white", fontSize: "25px" }}>
                            <FontAwesomeIcon icon={faBusinessTime} style={{ marginLeft: "15px", border: "none" }} />
                            &nbsp;Sorry, we're down for maintenance
                        </p>
                        <p style={{ textAlign: "center", margin: "0px", color: "lightgray", fontSize: "15px" }}>
                            we'll be back soon.
                        </p>
                        {/* <Button variant="outline-light"
                            style={{ border: "none", position: "absolute", left: "10px", bottom: "10px", opacity: "0.1" }}
                            onClick={() => {
                                sessionStorage.setItem("loginAsSys", "true")
                                props.history.push('/login')
                            }}
                        >
                            <FontAwesomeIcon icon={faUserCog} style={{ border: "none", color: "#DC3545", fontSize: "30px" }} />
                        </Button> */}
                    </div>
                </div>
            </section >
        </section >
    );
}
