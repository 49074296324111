import React from 'react';
import { Badge, Button, Row } from "react-bootstrap"
import { modalTabsType, SampleInfoIgvDataI } from "../../../IGV/ModalSamplesIgv";
import { AlignmentT, ModalIgvDataI } from "../../../IGV/types";
import { StudyI } from "../../../../Contexts/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { LabelValueI, ZygosityT, variantAcmgValues } from "src/utils/BioFunctionalData";
import { LinkLocalGen, NcbiDbsnpLinksByVcfId, LinkNCBI, LinkENSEMBL, LinkLocalAdvancedQuerys } from "src/Components/Common/Links/Links";
import WarningTrianglePopover from "src/Components/Common/WarningTrianglePopover";
import CopyToClipboard from "src/Components/Common/CopyToClipboard";
import { BadgeZygosityByAllele } from "src/Components/Common/Badges/BadgeZygosity";
import { getColumnClinvarVariantId } from "./VariantsClinvarTable";
import BadgeVariantVeredict from 'src/Components/Common/Badges/BadgeVariantVeredict';
import { SampleInfoDp, SampleInfoGq } from '../../Query/VariantsTable/VariantsTableComponents';
import { useWindowSize } from 'src/Helpers/WindowsSizeHelper';
import { alleleClassifier } from 'src/utils/BioHelpers';
import NucleotidChange from 'src/Components/Common/NucleotidChange';
import TruncatedTextWithPopover from 'src/Components/Common/TruncatedTextWithPopover';

interface ClinvarVariantCovI {
    chrom: string,//"chr7"
    classification: string,//"Likely_pathogenic"
    clinvarAlleles: string,//"CAG/C"
    clinvarID: string,//"421014"
    covered: boolean,
    genename: string,//"NPSR1"
    gt: string, //"0/0"
    dp: string, //"67"
    gq: string, //"99"
    ontarget: boolean,//true
    position: number,//32316419
    rs: string, //"rs758732038"
    vcfAlleles: string//"C/C"
}

interface VariantClinvarRowsI {
    data: (any | ClinvarVariantCovI)[]
    study: StudyI,
    studyId: string,
    studyName: string,
    handleShowModalSamplesIgv(i: SampleInfoIgvDataI): void,
    settableMounted(val: boolean): void,
}
export default function VariantsClinvarTableRows(props: VariantClinvarRowsI) {
    const { widthType } = useWindowSize()
    const sizeXl = widthType === "xl" || widthType === "xxl"
    const getVariantColumn = (x: ClinvarVariantCovI) => {
        const dbsnpClinvarComp = (<span style={{ wordBreak: "break-word", fontSize: "12px", marginBlock: sizeXl ? "-3px" : "none" }}>
            <NcbiDbsnpLinksByVcfId vcfIds={x.rs} copyToClipboard={true} />
            <span style={{}}>
                <LinkNCBI clinvarNonRs={x.clinvarID}
                    fontSize={"11px"}
                    label={"Clinvar"} />
            </span>
        </span>)
        const chromosome = x.chrom.replace("chr", "").replace("ch", "");
        return <>
            <span style={{ wordBreak: "break-word", display: "flex", paddingTop: 2 }}>
                <LinkENSEMBL genomeRef={props.study.genomeReference}
                    chromosome={chromosome}
                    position={+x.position}
                    fontSize={"13px"}
                    notBold={false} />
                <CopyToClipboard valueToCopy={`${chromosome}:${x.position}`} fontSize={"11px"} iconStyle={{ verticalAlign: "text-bottom" }} />
                &nbsp;&nbsp;
                <NucleotidChange clinvarAlleles={x.clinvarAlleles} />
                {sizeXl && <>&nbsp;&nbsp;{dbsnpClinvarComp}</>}
            </span>
            {!sizeXl && dbsnpClinvarComp}
        </>
    }


    const getAcmgColumn = (rowValue: ClinvarVariantCovI) => {
        const getAcmgVeredictData = (x: ClinvarVariantCovI) => {
            let newClassification = x.classification;
            const badgesAcmgVeredictColumn = variantAcmgValues
                .filter((xx: LabelValueI) => {
                    const indexOfVeredict = newClassification.toLowerCase().indexOf((xx?.value ?? "").toLowerCase());
                    if (indexOfVeredict > -1) {
                        const veredictLength = (xx.value ?? "").length;
                        if (indexOfVeredict > 0 && newClassification[indexOfVeredict - 1].match(/^[A-Za-z]+$/)) return false;
                        if (indexOfVeredict + veredictLength + 1 < newClassification.length && newClassification[indexOfVeredict + veredictLength].match(/^[A-Za-z]+$/))
                            return false;
                        newClassification = newClassification.toLowerCase().replace((xx.value ?? "").toLowerCase(), "");
                    }
                    return indexOfVeredict > -1;
                }).map((xx: LabelValueI, i: number) => {
                    return <BadgeVariantVeredict labelInput={xx?.value ?? ""}
                        id={i}
                        key={`-${i}-`}
                        size={"big"}
                    />
                })
            return {
                badgesAcmg: badgesAcmgVeredictColumn,
                newClassification: newClassification
            };
        }
        const acmgVeredictCol = getAcmgVeredictData(rowValue)
        return (
            <>
                {acmgVeredictCol.badgesAcmg}
                {acmgVeredictCol.newClassification.length > 2 && <>
                    <WarningTrianglePopover type="secondary" bodyContent={acmgVeredictCol.newClassification} fontSize={"14px"} />
                </>}
            </>
        )
    }

    const getSampleInfoColumn = (rowValue: any) => {
        const getModal_SampleIgvButton = (x: ClinvarVariantCovI) => {
            const getModal_SampleIgvData = (x: ClinvarVariantCovI, mood: modalTabsType = "sample", view: AlignmentT, sampleNameSelected?: string): SampleInfoIgvDataI => {
                const getModal_IGVData = (viewType?: AlignmentT, sampleNameSelected?: string): ModalIgvDataI => {
                    const getBadge_Sample = (sample: any, infoIcon: boolean = false, fontSize: string = "11px") => {
                        return (
                            <Badge variant="secondary"
                                style={{
                                    fontWeight: "inherit",
                                    fontSize: fontSize,
                                    color: "white",
                                    backgroundColor: sample?.afectado ? "#FF4E58" : "#999999",
                                    margin: "2px",
                                    padding: "1px 5px 3px 5px",
                                    alignSelf: "center"
                                }}>
                                {`${sample?.sample?.name ?? "-"} ${sample ? sample.tag[0] : ""}`}
                                {infoIcon &&
                                    <FontAwesomeIcon icon={faInfoCircle}
                                        style={{ fontSize: "12px", margin: "2px 0 0 6px", verticalAlign: "text-bottom" }} />
                                }
                            </Badge>
                        )
                    }
                    let commonData: any
                    let data: any = {}
                    const alignaments = (props.study.samples ?? []).map((x: any, i: number) => {
                        const actualSample = props.study?.samples.find((y: any) => y.sample.name === x.sampleName)
                        if (i === 0) {
                            data.studyName = x.sampleName;
                            data.assembly = props.study?.genomeReference;
                            commonData = {
                                effect: "NA",
                                impact: "-"
                            }
                        }
                        return ({
                            effect: x.effect,
                            impact: x.impact,
                            sampleName: x.sampleName,//x.sampleName,
                            sampleBadge: getBadge_Sample(actualSample, false, "14px")//x.sampleName,
                        })
                    })
                    const igvSamples = props.study?.samples.map((x: any) => {
                        const igvElement = alignaments.find((y: any) => y.sampleName === x.sample.name)
                        if (igvElement)
                            return (igvElement)
                        return {
                            ...commonData,
                            sampleName: x.sample.name,//x.sample.name
                            sampleBadge: getBadge_Sample(x, false, "14px")//x.sample.name
                        }
                    })
                    return {
                        ...data,
                        sampleNameSelecte: sampleNameSelected,
                        view: viewType,
                        alignments: igvSamples
                    }
                }
                let handlerData: any = {
                    mood: mood,
                    chromosome: x.chrom,
                    position: `${x.position - 1}-${x.position + 1}`,
                    gen: x.genename
                }
                // if (props.studyType === "familiar" || props.studyType === "multiple") {
                //     const sd = getModal_SampleData()
                //     handlerData = {
                //         ...handlerData,
                //         sampleData: {
                //             change: rv.codonChange,
                //             table: sd
                //         }
                //     }
                // }
                if (props.study?.hasBam) {
                    const igvData = getModal_IGVData(view, sampleNameSelected)
                    handlerData = {
                        ...handlerData,
                        igvData: igvData
                    }
                }
                return handlerData
            }
            const getShowModalLink = (label: string, mood: modalTabsType = "sample", view: AlignmentT = "variant", sampleNameSelected?: string) => {
                return (
                    <Button style={{ cursor: "pointer", color: "#009EEA", padding: "2px 5px" }} variant="outline-light"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            const handlerData = getModal_SampleIgvData(x, mood, view, sampleNameSelected)
                            props.handleShowModalSamplesIgv(handlerData)
                        }}>
                        {label}
                    </Button>
                )
            }
            if (props.study.type === "familiar" || props.study.type === "multiple")
                return (
                    <></>
                    // <div style={{ textAlign: "center" }}>
                    //     <hr style={{ margin: "7px" }} />
                    //     {getShowModalLink("Show samples", "sample")}
                    // </div>
                )
            // if (props.study?.hasBam) //TODO: UNCOMMENT THIS!!!!!!!!!!!
            return (
                <span style={{ textAlign: "center", padding: 2 }}>
                    {getShowModalLink("Locus", "igv", "variant", props.study.samplesNames[0])}
                </span>
            )
        }
        const isMale = (props.study.samples[0].sample.sexo ?? "").toUpperCase().trim() === "MALE"
        let vType: ZygosityT = alleleClassifier(rowValue.gt, rowValue.chrm, isMale, "homref")
        const badgeZygosity = rowValue.gt ?
            <BadgeZygosityByAllele alleles={rowValue.gt}
                chromosome={rowValue.chrm}
                study={props.study}
                isMale={isMale}
                isMitochondrial={(props.study?.subtype ?? "").toUpperCase().trim() === "MT-DNA"}
                fontSize="12px" />
            : "--"
        const DpGqAndIgv = <>
            <SampleInfoDp dp={rowValue.dp} style={{ alignSelf: "center" }} />
            <span style={{ alignSelf: "center" }}>&nbsp;|&nbsp;</span>
            <SampleInfoGq conditionalQuality={rowValue.gq} style={{ alignSelf: "center" }} />
            &nbsp;&nbsp; {
                rowValue.rs && (vType === "het" || vType === "hom" || vType === "hem") &&
                <LinkLocalAdvancedQuerys rs={rowValue.rs} sample={props.study.samples[0]} caseId={`${props.study.id}`} />
            }
            &nbsp;
            {getModal_SampleIgvButton(rowValue)}
        </>
        return (
            sizeXl ? <Row style={{ placeContent: "center", margin: 0, alignSelf: "center", alignItems: "center" }}>
                <span>{badgeZygosity}</span>
                &nbsp;|&nbsp;
                {DpGqAndIgv}
            </Row> :
                <>
                    {<Row style={{ placeContent: "center", margin: 0 }}>
                        {badgeZygosity}
                    </Row>}
                    {<Row style={{ placeContent: "center", margin: 0, alignSelf: "center" }}>
                        {DpGqAndIgv}
                    </Row>}
                </>
        )
    }
    const commonColStyle = { padding: "3px 5px", verticalAlign: "middle" }
    const getVcfAlleles = (all: string): JSX.Element => {
        let vcfAlleles = all.split("/")
        if (vcfAlleles.length < 2) vcfAlleles.push("-")
        let result = []
        if (vcfAlleles[0].length > 10) result.push(<TruncatedTextWithPopover popoveTitle={""} popoveContent={vcfAlleles[0]} treshold={10} />)
        else result.push(<label>{vcfAlleles[0]}</label>)
        if (vcfAlleles[1].length > 10) result.push(<TruncatedTextWithPopover popoveTitle={""} popoveContent={vcfAlleles[1]} treshold={10} />)
        else result.push(<label>{vcfAlleles[1]}</label>)
        return result.length > 1 ? <>{result[0]}<label>/</label>{result[1]}</> : <>{result}</>;
    }
    return (
        <>
            {(props.data ?? []).map((x: any, i: number) => {
                const variantCol = getVariantColumn(x)
                const register = (
                    <tr key={i}
                        style={{ fontSize: "12px" }}
                        onClick={() => { }}>
                        <td id={getColumnClinvarVariantId(props.studyName, "gene", i)}
                            style={{ ...commonColStyle, minWidth: 50 }}>
                            <LinkLocalGen geneName={x.genename}
                                caseId={`${props.studyId}`}
                                fontSize={"14px"}
                                variantId={"1"} />
                        </td>
                        <td id={getColumnClinvarVariantId(props.studyName, "variant", i)} style={{ ...commonColStyle, minWidth: 230 }}>
                            {variantCol}
                        </td>
                        <td id={getColumnClinvarVariantId(props.studyName, "sampleAlleles", i)}
                            style={{ padding: "3px 5px", textAlign: "center", verticalAlign: "middle", minWidth: 100 }}>
                            {
                                x.vcfAlleles ? getVcfAlleles(x.vcfAlleles) : "--"
                            }
                        </td>
                        <td id={getColumnClinvarVariantId(props.studyName, "coverage", i)}
                            style={{ padding: "3px 5px", textAlign: "center", verticalAlign: "middle", minWidth: 80 }}>
                            {
                                x.covered ?
                                    <FontAwesomeIcon icon={faCheckCircle}
                                        style={{ color: "green", fontSize: "13px" }} /> :
                                    <FontAwesomeIcon icon={faTimesCircle}
                                        style={{ color: "indianred", fontSize: "13px" }} />
                            }
                        </td>
                        <td id={getColumnClinvarVariantId(props.studyName, "onTarget", i)}
                            style={{ padding: "3px 5px", textAlign: "center", verticalAlign: "middle", minWidth: 80 }}>
                            {
                                x.ontarget ?
                                    <FontAwesomeIcon icon={faCheckCircle}
                                        style={{ color: "green", fontSize: "13px" }} /> :
                                    <FontAwesomeIcon icon={faTimesCircle}
                                        style={{ color: "indianred", fontSize: "13px" }} />
                            }
                        </td>
                        <td id={getColumnClinvarVariantId(props.studyName, "classification", i)}
                            style={commonColStyle}>
                            {getAcmgColumn(x)}
                        </td>
                        <td id={getColumnClinvarVariantId(props.studyName, "sampleInfo", i)}
                            style={{ ...commonColStyle, minWidth: 130, textAlign: "center" }}>
                            {getSampleInfoColumn(x)}
                        </td>

                    </tr >

                )
                if (i === (props.data ?? []).length - 1) {
                    setTimeout(() => {
                        props.settableMounted(true)
                    }, 300);
                }
                return register
            })}
        </>)
}