import React from 'react';
import { Layout } from "../../Components/Layout/Layout";
import { faFileMedical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Row, Table } from "react-bootstrap";
import DetailLayout from "../../Components/Layout/Detail/DetailLayout";
import { useContext, useEffect, useState } from "react";
import HealthCheckService from '../../Services/HealthcheckService';
import { AsyncDataI } from "../../Contexts/interfaces";
import Loader from "../../Components/Common/Loader/Loader";
import { Redirect } from "react-router-dom";
import UserContext from "../../Contexts/User/UserContext";

interface HealthCheckPageI {
    history: any,
}
export default function HealthCheckPage(props: HealthCheckPageI) {
    const { user } = useContext(UserContext);
    const HealthcheckS = new HealthCheckService();
    const [hcRes, sethcRes] = useState<AsyncDataI>({ data: {}, error: false, loading: false, loaded: false })
    const currentRoll = user.data?.rolestoString ?? ""
    const [redirect, setredirect] = useState<JSX.Element>(<></>)

    useEffect(() => {
        let isMounted = true
        if (currentRoll !== "ROLE_SYSTEM") setredirect(<Redirect to='/' />)
        else {
            sethcRes({ data: {}, error: false, loading: true, loaded: false })

            if (isMounted) {
                HealthcheckS.getCheck().then((res: any) => {
                    if (res.status === 200) {
                        sethcRes({ data: res.data, error: false, loading: false, loaded: true })
                    }
                }).catch((err: any) => {
                    sethcRes({ data: {}, error: err, loading: false, loaded: false })
                })
            }
            return () => {
                isMounted = false
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const tdStyles = { border: "none", width: "5px", verticalAlign: "middle", fontSize: "16px", color: "gray" }
    const getBadgeInfo = (label: string, error?: boolean) => {
        error = error ?? false
        const variant = error ? "danger" : "info";
        return (
            <Badge variant={variant}>
                {label}
            </Badge>
        )
    }
    let activeMQConnectionElement = <>-</>
    let hbaseConnectionElement = <>-</>
    let hbaseDeadServerElement = <>-</>
    let hbaseRegionServerElement = <>-</>
    let mySqlConnectionElement = <>-</>
    let resdisConnectionElement = <>-</>
    if (hcRes.loaded) {
        const activeMQConnection: string = hcRes.data["ActiveMQ Connection"];
        const activeMQConnectionExpected: string = "Open";
        activeMQConnectionElement = getBadgeInfo(hcRes.data["ActiveMQ Connection"], activeMQConnection !== activeMQConnectionExpected)

        const hbaseConnection = hcRes.data["Hbase Connection"];
        const hbaseConnectionExpected = "Open";
        hbaseConnectionElement = getBadgeInfo(hcRes.data["Hbase Connection"], hbaseConnection !== hbaseConnectionExpected)

        const hbaseDeadServer = hcRes.data["Hbase dead server"];
        const hbaseDeadServerExpected = "0";
        hbaseDeadServerElement = getBadgeInfo(hcRes.data["Hbase dead server"], hbaseDeadServer !== hbaseDeadServerExpected)

        const hbaseRegionServer = hcRes.data["Hbase region server"];
        const hbaseRegionServerExpected = "3";
        hbaseRegionServerElement = getBadgeInfo(hcRes.data["Hbase region server"], hbaseRegionServer !== hbaseRegionServerExpected)

        const mySqlConnection = hcRes.data["MySql connection"];
        const mySqlConnectionExpected = "Open";
        mySqlConnectionElement = getBadgeInfo(hcRes.data["MySql connection"], mySqlConnection !== mySqlConnectionExpected)

        const resdisConnection = hcRes.data["Redis Connection"];
        const resdisConnectionExpected = "Open";
        resdisConnectionElement = getBadgeInfo(hcRes.data["Redis Connection"], resdisConnection !== resdisConnectionExpected)
    }

    return (
        <>
            {redirect}
            <Layout history={props.history}>
                <DetailLayout DetailHeader={
                    <div style={{ color: "#444B59", display: "flex", fontFamily: "Raleway, sans-serif", fontSize: "20px", alignItems: "center" }}>
                        &nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faFileMedical} style={{}} />&nbsp;
                        Healthcheck
                    </div>
                }>
                    {hcRes.loading || !hcRes.loaded ?
                        <Loader onContainer={true} type={'DNA'} /> :
                        <Row style={{ width: "100%", margin: 0 }}>
                            <Table bordered striped>
                                <tbody>
                                    <tr style={{}}>
                                        <td style={tdStyles}>
                                            <strong>&nbsp;&nbsp;ActiveMQ Connection</strong>
                                        </td>
                                        <td style={tdStyles}>
                                            {activeMQConnectionElement}
                                        </td>
                                    </tr>
                                    <tr style={{}}>
                                        <td style={tdStyles}>
                                            <strong>&nbsp;&nbsp;Hbase Connection</strong>
                                        </td>
                                        <td style={tdStyles}>
                                            {hbaseRegionServerElement}
                                        </td>
                                    </tr>
                                    <tr style={{}}>
                                        <td style={tdStyles}>
                                            <strong>&nbsp;&nbsp;Hbase dead server</strong>
                                        </td>
                                        <td style={tdStyles}>
                                            {hbaseDeadServerElement}
                                        </td>
                                    </tr>
                                    <tr style={{}}>
                                        <td style={tdStyles}>
                                            <strong>&nbsp;&nbsp;Hbase region server</strong>
                                        </td>
                                        <td style={tdStyles}>
                                            {hbaseConnectionElement}
                                        </td>
                                    </tr>
                                    <tr style={{}}>
                                        <td style={tdStyles}>
                                            <strong>&nbsp;&nbsp;MySql connection</strong>
                                        </td>
                                        <td style={tdStyles}>
                                            {mySqlConnectionElement}

                                        </td>
                                    </tr>
                                    <tr style={{}}>
                                        <td style={tdStyles}>
                                            <strong>&nbsp;&nbsp;Redis Connection</strong>
                                        </td>
                                        <td style={tdStyles}>
                                            {resdisConnectionElement}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    }
                </DetailLayout>
            </Layout >
        </>
    )
}
