import React from 'react';
import { useContext } from 'react';
import { LangContext } from "../../Contexts/Lang";
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import IconButtonTooltipPopover from './IconButtonTooltipPopover';
import { Alert } from 'react-bootstrap';

interface CopyToClipboardI {
    valueToCopy: string,
    fontSize?: string,
    buttonStyles?: any,
    iconStyle?: any,
}
export default function CopyToClipboard(props: CopyToClipboardI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const buttonStyles = props.buttonStyles ?? {}
    const iconStyles = props.iconStyle ?? {}
    if (props.valueToCopy === undefined) return <></>
    function copyToClipboard() {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            return navigator.clipboard.writeText(props.valueToCopy);
        }
        else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = props.valueToCopy.trim();
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return prompt("Copy to clipboard: Ctrl+C, Enter", props.valueToCopy);
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    }

    const handleCopy = (e: any) => {
        e.stopPropagation()
        copyToClipboard()
        // navigator.clipboard.writeText(props.valueToCopy)
        // darkslategray
    }
    const bodyPopover = (props.valueToCopy ?? "").length > 200 ? `${props.valueToCopy.substring(0, 200)}...` : props.valueToCopy;
    return (
        <IconButtonTooltipPopover
            icon={faCopy}
            tooltipLabel={translate('casesDetail.copyToClipboard')}
            placement="top"
            onClick={handleCopy}
            autoClosePopover={4000}
            autoCloseTooltip={1000}
            fontSize={props.fontSize ?? "11px"}
            style={{ cursor: "pointer", ...buttonStyles }}
            iconStyle={{ ...iconStyles }}
            popOver={{
                place: "top",
                trigger: "click",
                content: (
                    <Alert variant={"secondary"} style={{ marginBottom: 0, fontSize: "11px" }}>
                        <span style={{}}>
                            {`${translate("common.copiedValue")} :`}
                        </span> &nbsp;
                        <span style={{ color: "darkslategray", fontWeight: "bold" }}>
                            {bodyPopover}
                        </span>
                    </Alert>
                )
            }} />
    )
}
