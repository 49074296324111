import React, { useEffect, useReducer } from "react";

import UserContext, { ContextUserStatesI, FilterUsersI } from "./UserContext";
import UserReducer from "./UserReducer";

import { UserActionType } from "../actionsTypes";
import UserService from "../../Services/UserServices";
import { encryptLocalStorage, encrypt } from "../../utils";
import LoginService from "../../Services/LoginService";
import { ContextStateManager } from "../ContextStateManager";
import { UserI } from "../interfaces";

const UserContextState = (props: any) => {
	const US = new UserService();
	const LS = new LoginService();
	const initialState: ContextUserStatesI = {
		user: { data: {} as UserI, loaded: false, loading: false, error: false },
		users: { data: null, loaded: false, loading: false, error: false, refresh: false },
		filters: { username: "", fullname: "", sortColumn: "" },
		selecteds: []
	};
	const [state, dispatch] = useReducer(UserReducer, initialState);
	// console.log("state.user.data", state.user.data)
	const CSM = new ContextStateManager(state, initialState, UserActionType, dispatch)

	const getRandomColor = () => {
		const userCirclePalette = ["#9357AD", "#5357AD", "#41729F", "#48A9A6", "#A6E2D3", "#D4B483", "#FFA384", "#C1666B", "#EF7C8E"]
		const userColor = userCirclePalette[+(Math.random() * userCirclePalette.length - 1).toFixed(0)];
		return userColor;
	}

	const login = async (name: string, password: string) => {
		return CSM.GetAsyncData("LOGIN_", { name, password }, LS, "login", (resData: any) => {
			const color = getRandomColor()
			resData = { ...resData, color }
			encryptLocalStorage.setItem('_Buser', encrypt ? resData : JSON.stringify(resData));
			refreshUsers();
			return resData
		})
	}
	const logout = async () => {
		CSM.GetAsyncData("LOGOUT_", {}, LS, "logout")
	}
	const updateLoggedUser = async (userName: string, fullName: string, email: string) => {
		const encryptAndReturnUser = (resData: any) => {
			encryptLocalStorage.setItem('_Buser', encrypt ? resData.user : JSON.stringify({ ...resData.user, color: getRandomColor() }));
			return resData.user
		}
		CSM.GetAsyncData("UPDATE_USER_", { userName, fullName, email }, US, "updateLoggedUser", encryptAndReturnUser)
	}
	const getUser = async () => CSM.GetAsyncData("GET_USER_", {}, US, "getUser");
	const getUsers = async (filters?: FilterUsersI, pageNumber?: number, pageSize?: number) => {
		return CSM.GetAsyncData("GET_USERS_", { filters, pageNumber, pageSize }, US, "getUsers", (resData) => resData.results)
	}
	const refreshUsers = () => dispatch({ type: UserActionType.GET_USERS_REFRESH })
	const setFilters = (key: string, value: string): boolean => CSM.SetFilters("filters", "SET_FILTERS", key, value)
	const cleanFilters = () => CSM.CleanFilters("filters", "SET_FILTERS")

	const handleSelecteds = (id: string) => CSM.HandleSelecteds("selecteds", "SET_SELECTEDS", state.users.data?.results ?? [], id)
	const handleSelectAll = () => CSM.HandleSelectAll("selecteds", "SET_SELECTEDS", state.users.data?.results ?? [])

	useEffect(() => {
		let isMounted = true;
		if (!state.user.lodaed && !state.user.lodaing && US.user && Object.keys(US.user).length > 0 && isMounted) {
			let u = encryptLocalStorage.getItem("_Buser");
			if (!encrypt) u = JSON.parse(u)
			dispatch({ type: UserActionType.LOGIN_SUCCESS, payload: u });
		}
		return () => { isMounted = false };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<UserContext.Provider
			value={{
				user: state.user,
				users: state.users,
				filters: state.filters,
				selecteds: state.selecteds,
				dispatch: {
					login,
					logout,
					getUser,
					getUsers,
					refreshUsers,
					updateLoggedUser,
					setFilters,
					cleanFilters,
					handleSelecteds,
					handleSelectAll
				}
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
};

export default UserContextState;