import React, { useReducer } from "react";

import PanelGenesContext, { ContextPanelGenesStatesI, FiltersPanelGenesI } from "./PanelGenesContext";
import PanelGenesReducer from "./PanelGenesReducer";

import { PanelGenesActionType } from "../actionsTypes";
import GensetServices from "../../Services/GensetService";
import { ContextStateManager } from "../ContextStateManager";

const PanelGenesContextState = (props: any) => {
    let GensetS = new GensetServices();
    const initialState: ContextPanelGenesStatesI = {
        panel: { data: null, loaded: false, loading: false, error: false },
        panels: { data: null, loaded: false, loading: false, error: false, refresh: false },
        filters: { panelnameSearch: "", genenameSearch: "", sortColumn: "" },
        selecteds: []
    };

    const [state, dispatch] = useReducer(PanelGenesReducer, initialState);
    const CSM = new ContextStateManager(state, initialState, PanelGenesActionType, dispatch)

    const getPanel = async (id: string, region: string, page: number, pageSize: number) => CSM.GetAsyncData("GET_PANEL_", { id, region, page, pageSize }, GensetS, "get_panel");
    const getPanels = async (filters?: FiltersPanelGenesI, pageNumber?: number, pageSize?: number) => CSM.GetAsyncData("GET_PANELS_", { filters, pageNumber, pageSize }, GensetS, "search");
    const refreshPanels = () => dispatch({ type: PanelGenesActionType.GET_PANELS_REFRESH })

    const setFilters = (key: string, value: string): boolean => CSM.SetFilters("filters", "SET_FILTERS", key, value)
    const cleanFilters = () => CSM.CleanFilters("filters", "SET_FILTERS")
    const handleSelecteds = (id: string) => CSM.HandleSelecteds("selecteds", "SET_SELECTEDS", state.panels?.data?.results ?? [], id, "key")
    const handleSelectAll = () => CSM.HandleSelectAll("selecteds", "SET_SELECTEDS", state.panels?.data?.results ?? [], "key")

    return (
        <PanelGenesContext.Provider
            value={{
                panel: state.panel,
                panels: state.panels,
                filters: state.filters,
                selecteds: state.selecteds,
                dispatch: {
                    getPanel,
                    getPanels,
                    refreshPanels,
                    setFilters,
                    cleanFilters,
                    handleSelecteds,
                    handleSelectAll
                }
            }}
        >
            {props.children}
        </PanelGenesContext.Provider>
    );
};

export default PanelGenesContextState;