// ProcessUser
import React from 'react';
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../../Contexts/Lang";
import { Modal } from "react-bootstrap";
import { UserForm } from "../User/UserForm";
import UserPassForm from "../User/UserPassForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faUserEdit, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import UserContext, { AsyncUserUndefI } from "../../../Contexts/User/UserContext";
import UserService from "../../../Services/UserServices";
import Loader from "../../Common/Loader/Loader";

// type formInputType = "number" | "text" | "textarea" | "password" | "email"
type EditT = "user" | "password"
interface ProcessUserI {
    history: any,
    show: boolean,
    createUser?: boolean,
    editUserId?: string,
    domain?: {
        id: string,
        name: string
    },
    editT: EditT,
    handleClose(): void,
    handleReload?(): void,
}
export default function ProcessUser(props: ProcessUserI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { user: loggedUser, dispatch: { getUser } } = useContext(UserContext);
    const US = new UserService();
    const [editUser, seteditUser] = useState<AsyncUserUndefI>({ loaded: false, loading: false, error: null, data: undefined });
    const editState = props.editT;
    // const [editState, seteditState] = useState<"user" | "password">("user")
    // const handleEditState = () => {
    //     if (editState === "user") { seteditState("password") }
    //     else { seteditState("user") }
    // }
    // useEffect(() => {
    //     seteditState("user")
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [props.show])

    useEffect(() => {
        seteditUser({ loaded: false, loading: true, error: null, data: undefined })
        if (props.editUserId && !props.createUser) {
            US.getUserAndAllRoles(props.editUserId)
                .then((res: any) => {
                    if (res.status === 200) {
                        seteditUser({ loaded: true, loading: false, error: null, data: res.data.user })
                    }
                })
                .catch((err: any) => {
                    console.log("err")
                    console.log(err)
                    seteditUser({ loaded: false, loading: false, error: err, data: undefined })
                })
        } else if (!loggedUser.loaded && !props.createUser) {
            getUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editUserId])

    const userForm =
        <UserForm history={props.history}
            editUser={editUser.data}
            createUser={props.createUser}
            // handleEditState={handleEditState}
            handleClose={props.handleClose}
            handleReload={props.handleReload}
            domain={props.domain}
        />
    const userPass =
        <UserPassForm history={props.history}
            editUser={editUser.data}
            showTitle={false}
            // handleEditState={handleEditState}
            handleClose={props.handleClose}
            handleReload={props.handleReload}
        />
    return (
        <Modal show={props.show}
            onHide={props.handleClose}
            id="ProcessUser"
        // dialogClassName="my-modal"
        >
            <Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: "#444B59", fontWeight: "bold", color: "white", fontSize: "24px" }}>
                {
                    props.editT === "user" ?
                        props.createUser ?
                            <div style={{ padding: "0px 10px" }}>
                                <FontAwesomeIcon icon={faUserPlus} style={{ color: "#34AC4f", fontSize: "20px", marginRight: "10px" }} />
                                {translate('user.user-create')}
                            </div> :
                            <div style={{ padding: "0px 10px" }}>
                                <FontAwesomeIcon icon={faUserEdit} style={{ color: "#007BFF", fontSize: "20px", marginRight: "10px" }} />
                                {translate('user.user-edit')}
                            </div> :
                        <div style={{ padding: "0px 10px" }}>
                            <FontAwesomeIcon icon={faKey} style={{ color: "#007BFF", fontSize: "20px", marginRight: "10px" }} />
                            {translate('user.change-password')}
                        </div>
                }

            </Modal.Header>
            <Modal.Body style={{ padding: "0px" }}>
                {props.editUserId ?
                    editUser.loaded && editUser.data ?
                        editState === "user" ?
                            userForm :
                            userPass :
                        <Loader onContainer={true} type={'DNA'} /> :
                    props.createUser ?
                        userForm :
                        editState === "user" ?
                            userForm :
                            userPass
                }
            </Modal.Body>
        </Modal >
    )
}

export type { EditT }