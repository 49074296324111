import React from 'react';
import { useContext } from "react";
import BadgeVeredictConservationPredictors from 'src/Components/Common/Badges/BadgePredictorsConservedVeredict';
import { LangContext } from "../../../../../Contexts/Lang";
import { roundWithTooltipoTo0 } from "../../../../../Helpers/Helpers";
import PredictorsTemplateTable, { PredictorsTableTitle, PredictorsTemplateTableRowI } from './PredictorsTemplateTable';

interface ConservationTableI {
    infoCd: any,
    defaultScoreValue: string
}
export default function ConservationTable(props: ConservationTableI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const infoCd = props.infoCd;

    let dataPredictors: PredictorsTemplateTableRowI[] = [
        // {
        //     id: "conservationTitle",
        //     label: <><br /><span style={{ fontWeight: "bold", color: theme.grayHeader }}>{translate("variantDetail.conservation")}</span></>,
        // },
        {
            id: "phylop",
            label: "PhyloP",
            tooltipLabel: "PhyloP - phylogenetic p-values",
            score: infoCd?.PHYLOP ? roundWithTooltipoTo0(infoCd?.PHYLOP) : props.defaultScoreValue,
            conclution: infoCd?.PHYLOP ? <BadgeVeredictConservationPredictors type={"phylop"} value={infoCd?.PHYLOP} /> : "-",
            detail: "",
            info: {
                title: "Phylop",
                body: translate("variantDetail.polypInfo")
            }
        },
        {
            id: "phast_cons",
            label: "PhastCons",
            tooltipLabel: "PhastCons - PHylogenetic Analysis with Space/Time models for identifying evolutionarily Conserved elements in a multiple alignment",
            score: infoCd?.PHASTCONS ?? props.defaultScoreValue,
            conclution: infoCd?.PHASTCONS ? <BadgeVeredictConservationPredictors type={"phast_cons"} value={infoCd?.PHASTCONS} /> : "-",
            detail: "",
            info: {
                title: "PhastCons",
                body: translate("variantDetail.phastconsInfo")
            }
        },
        {
            id: "gerp",
            label: "GERP++ RS",
            tooltipLabel: "Genomic Evolutionary Rate Profiling ",
            score: infoCd?.GERP_RS ?? props.defaultScoreValue,
            conclution: infoCd?.GERP_RS ? <BadgeVeredictConservationPredictors type={"gerp"} value={infoCd?.GERP_RS} /> : "-",
            detail: "",
            info: {
                title: "GERP",
                body: translate("variantDetail.gerpInfo")
            }
        }
    ]
    return (
        <>
            <PredictorsTableTitle title={translate("variantDetail.conservation")} />
            <PredictorsTemplateTable rowData={dataPredictors} noDetail={true} />
        </>
    )
}