import React from 'react';
import { useContext, useState } from 'react';
import { LangContext } from "../../../../Contexts/Lang";
import { Table, Row } from 'react-bootstrap';
import { ModalSamplesIgv, SampleInfoIgvDataI } from '../../../IGV/ModalSamplesIgv';
import { ModalCommentGroupT, ModalCommentGroup } from '../../../Common/ModalCommentGroup/ModalCommentGroup';
import VariantServices from '../../../../Services/VariantServices';
import { ModalWarning } from '../../../Common/ModalWarning';
import { QueryVariantsTableT, TableTitleI } from '../../../../Contexts/Query/QueryContext';
import { AsyncDataI } from "../../../../Contexts/interfaces";
import ModalAcmgCalc, { AcmgCalcDataI } from '../../../Common/AcmgCalc/ModalAcmgCalc';
import { StudyI } from '../../../../Contexts/Cases/CasesContext';
import { ListaCigotasI } from '../../../../Contexts/interfaces';
import ThemeContext from '../../../../Contexts/Theme/ThemeContext';
import VariantsTableBody from './VariantsTableBody';


interface VariantsTableI {
	history: any,
	studyId: string, //TODO ver si puedo eliminarlo y tomar el id de el campo de abajo "study" --> study.id
	study: StudyI
	tableTitles: TableTitleI[],
	queryVariants?: AsyncDataI,
	listaCigotas: ListaCigotasI[],
	comments?: any,
	studyType: string,
	isMitochondrial: boolean,
	isSomatic: boolean,
	variantSelecteds?: string[],
	handleVariantSelecteds?: any,
	handleGetQueryVariant?(pageNumber?: number, pageSize?: number, then?: any): void,
	QVTType?: {
		type: QueryVariantsTableT,
		data?: any
	},
	setScrollYPosition?(position: number | null): void,
	tableBodyRef?: React.MutableRefObject<any>,
	handleScrollTableBody?(scroll: any): void,
	handleAftercomment?(): void,
}

export default function VariantsTable(props: VariantsTableI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { theme } = useContext(ThemeContext);

	const [showModalSamplesIgv, setShowModalSamplesIgv] = useState<{ show: boolean, data?: SampleInfoIgvDataI }>({ show: false, data: undefined });
	const handleCloseModalSamplesIgv = () => setShowModalSamplesIgv({ show: false, data: undefined });
	const handleShowModalSamplesIgv = (i: SampleInfoIgvDataI) => {
		setShowModalSamplesIgv({ show: true, data: i });
	}

	const initFormikModal = { show: false, type: "comment" as ModalCommentGroupT, data: undefined, editData: undefined }
	const [commentGroupModal, setcommentGroupModal] = useState<{ show: boolean, type: ModalCommentGroupT, data: any, editData: any }>(initFormikModal);
	const handleCloseCommentGroupModal = () => { setcommentGroupModal(initFormikModal) }
	const handleShowCommentGroupModal = (type: ModalCommentGroupT, data: any, editData?: any) => {
		setcommentGroupModal({ show: true, type: type, data: data, editData: editData })
	}

	const VariantS = new VariantServices();
	const [removeCommentModal, setremoveCommentModal] = useState<{ show: boolean, variants: string[] }>({ show: false, variants: [] });
	const handleCloseRemoveCommentModal = () => { setremoveCommentModal({ show: false, variants: [] }) }
	const handleShowRemoveCommentModal = (variants: string[]) => { setremoveCommentModal({ show: true, variants: variants }) }
	const handleUncomentVariant = () => {
		if (removeCommentModal.variants)
			VariantS.uncomment_variants(props.study.id, removeCommentModal.variants)
				.then((res: any) => {
					if (res.status === 200) {
						handleCloseRemoveCommentModal();
						if (props.handleGetQueryVariant) props.handleGetQueryVariant();
					}
				}).catch((e: any) => {
					console.log("e")
					console.log(e)
				})
	}

	const initAcmgCalculator = { show: false, data: undefined }
	const [acmgCalculator, setacmgCalculator] = useState<{ show: boolean, data: AcmgCalcDataI | undefined }>(initAcmgCalculator);
	const handleCloseacmgCalculator = () => { setacmgCalculator(initAcmgCalculator) }
	const handleShowacmgCalculator = (veredict: string, evidence: string[], variantId: string, acmgBayScore: string, acmgBayVeredict: string, title: JSX.Element) => {
		setacmgCalculator({ show: true, data: { veredict, evidence, variantId, acmgBayScore, acmgBayVeredict, title } })
	}

	let sortedAndCheckTable = props.tableTitles
		.filter((x: any) => {
			if (props.isMitochondrial) return (x.checked && x.type !== "classification")
			else return (x.checked && x.type !== "mitoMap")
		})
		.sort((a: any, b: any) => a.order - b.order)

	const getVariantsWithComments = () => {
		if (props.comments && Object.keys(props.comments).length > 0) {
			let variantsWithComments = props.queryVariants?.data?.variants?.map((x: any) => {
				if (x.value1 &&
					Array.isArray(x.value1) &&
					x.value1.length > 0 &&
					Object.keys(props.comments ?? {}).indexOf(`${x.value1[0].id}`) > -1) {
					return {
						...x,
						comment: props.comments[x.value1[0].id]
					}
				}
				return x
			})
			return variantsWithComments
		} else {
			return props.queryVariants?.data?.variants
		}

	}

	return (
		<Row id={"QueryVariantsTable-Div"}
			ref={props.tableBodyRef}
			onScrollCapture={(e: any) => { if (props.handleScrollTableBody) props.handleScrollTableBody(e); }}
			style={{ backgroundColor: theme.backgroundPrimary, width: "100%", margin: 0, overflowX: "auto" }}>
			{removeCommentModal?.show && <ModalWarning labelTitle={translate('common.confirm')}
				label={translate('casesQuery.doYouWantToRemoveCommentsOnTheSelectedVariants')}
				labelButton={translate('common.delete')}
				showState={removeCommentModal.show}
				handleClose={handleCloseRemoveCommentModal}
				onClick={handleUncomentVariant}
			/>}
			<ModalCommentGroup show={commentGroupModal.show}
				handleClose={handleCloseCommentGroupModal}
				type={commentGroupModal.type}
				data={commentGroupModal.data}
				editData={commentGroupModal.editData}
				studyId={props.studyId}
				handleReload={() => {
					if (props.setScrollYPosition) props.setScrollYPosition(window.pageYOffset);
					if (props.handleGetQueryVariant) props.handleGetQueryVariant();
					if (props.handleAftercomment) props.handleAftercomment();
				}}
			/>
			{(props.studyType === "multiple" || props.studyType === "familiar" || props.study?.hasBam) &&
				(showModalSamplesIgv?.data?.chromosome && showModalSamplesIgv?.data?.position && showModalSamplesIgv?.data?.gen) &&
				<ModalSamplesIgv
					caseId={`${props.studyId}`}
					show={showModalSamplesIgv.show}
					handleClose={handleCloseModalSamplesIgv}
					mood={showModalSamplesIgv?.data?.mood ?? "sample"}
					chromosome={showModalSamplesIgv?.data?.chromosome}
					position={showModalSamplesIgv?.data?.position}
					gen={showModalSamplesIgv?.data?.gen}
					sampleData={showModalSamplesIgv.data?.sampleData}
					igvData={showModalSamplesIgv.data?.igvData}
				/>
			}
			<ModalAcmgCalc studyId={props.studyId}
				show={acmgCalculator.show}
				handleClose={handleCloseacmgCalculator}
				data={acmgCalculator.data}
			/>
			<Table id={"QueryVariantsTable"} responsive hover striped bordered className="selectTable"
				ref={props.tableBodyRef}

				style={{
					width: "100%",
					overflowX: "scroll",
					maxHeight: "",
					margin: 0
				}}>
				{/* <VariantsTableHeaders queryVariants={props.queryVariants}
                    variantSelecteds={props.variantSelecteds}
                    tableTitles={props.tableTitles}
                    isMitochondrial={props.isMitochondrial}
                    handleGetQueryVariant={props.handleGetQueryVariant}
                    handleVariantSelecteds={props.handleVariantSelecteds}
                /> */}
				<VariantsTableBody variants={getVariantsWithComments()}

					studyId={props.studyId}
					study={props.study}
					studyType={props.studyType}
					isMitochondrial={props.isMitochondrial}
					isSomatic={props.isSomatic}
					listaCigotas={props.listaCigotas}

					history={props.history}
					QVTType={props.QVTType}
					columnsShowIds={sortedAndCheckTable.map((x: any) => x.type)}
					variantSelecteds={props.variantSelecteds}
					handleVariantSelecteds={props.handleVariantSelecteds}

					handleShowCommentGroupModal={handleShowCommentGroupModal}
					handleShowRemoveCommentModal={handleShowRemoveCommentModal}
					handleShowModalSamplesIgv={handleShowModalSamplesIgv}
					handleShowacmgCalculator={handleShowacmgCalculator}

					setScrollYPosition={props.setScrollYPosition}
				/>
			</Table>
		</Row>
	)
}

