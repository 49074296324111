import React from 'react';
import { useState, useRef, useEffect, useContext } from 'react';
import QueryBodyHeader from './QueryBodyHeader';
import QueryBodyTableToolkit from "./QueryBodyToolkit";
// import "../../styles.css";
import VariantsTable from '../../VariantsTable/VariantsTable';
import { Card } from 'react-bootstrap';
import { useWindowSize } from '../../../../../Helpers/WindowsSizeHelper';
import Loader from '../../../../Common/Loader/Loader';
import QueryBodyEmpty from './QueryBodyEmpty';
import QueryContext, { ColumnIdT, TableTitleI } from '../../../../../Contexts/Query/QueryContext';
import { LangContext } from '../../../../../Contexts/Lang';
import { VariantsOrderByValueT } from '../../../../../utils/BioFunctionalData';
import ThemeContext from '../../../../../Contexts/Theme/ThemeContext';
import { getVariantsSelecteds_IdsPosChangeGene } from 'src/Components/Common/ModalCommentGroup/VariantIdentificationTableRow';

interface QueryBodyI {
    history: any,
    filterCollapsed: any,
    setFilterCollapsed: any,
    paginator: any,
    filtersTabs: { urlData: string, filterName: string, id: number }[],
    filtersTabSelected: number | undefined,
    handlefiltersTabSelected: any,
    handleCreateFilter: any,
    handleReloadDataTable: any,
    handleGetQueryVariant(pageNumber?: number, pageSize?: number, then?: any): void,
    setScrollYPosition(position: number | null): void,
}
export default function QueryBody(props: QueryBodyI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const { studyId, queryVariants, queryCombos, dispatch: { SetQueryColumnsOnLS } } = useContext(QueryContext);

    const isMitochondrial = queryCombos.data?.study?.subtype.toUpperCase().trim() === "MT-DNA";
    const isSomatic = queryCombos.data?.study?.subtype.toUpperCase().trim() === "SOMATIC";

    let tableData: { order: number, type: ColumnIdT, title: string, checked: boolean, sortBy?: { sort: VariantsOrderByValueT, tooltipLabel: string }[] }[] = [
        {
            order: 1,
            type: "variant",
            title: translate("common.variant"),
            checked: true,
            sortBy: [
                {
                    sort: "position",
                    tooltipLabel: `${translate("common.sortBy")} ${translate("common.position")}`
                }
            ]
        },
        {
            order: 2,
            type: "gene",
            title: translate("common.gene"),
            checked: true,
            sortBy: [{
                sort: "infoColumnData.GENE_NAME",
                tooltipLabel: `${translate("common.sortBy")} ${translate("common.geneName")}`
            }]
        },
        {
            order: 3,
            type: "consecuence",
            title: translate("common.consecuence"),
            checked: true,
        },
        {
            order: 4,
            type: "impactoEfecto",
            title: translate("common.impactEffect"),
            checked: true,
            sortBy: [{
                sort: "infoColumnData.EFFECT",
                tooltipLabel: `${translate("common.sortBy")} ${translate("common.impactEffect")}`
            }]
        },
        {
            order: 5,
            type: "classification",
            title: translate("common.classification"),
            checked: true,
            sortBy: [{
                sort: "infoColumnData.INTERVAR_VEREDICT",
                tooltipLabel: `${translate("common.sortBy")} ${translate("common.classification")}`
            }]
        },
        {
            order: 6,
            type: "mitoMap",
            title: "MitoMap",
            checked: true,
        },
        {
            order: 7,
            type: "frecuence",
            title: translate("common.frequency"),
            checked: true,
            sortBy: isMitochondrial ? undefined : [
                {
                    sort: "infoColumnData.GNOMAD_EXOME_ALL",
                    tooltipLabel: `${translate("common.sortBy")} GnomAD exomes`
                },
                {
                    sort: "infoColumnData.GNOMAD_GENOME_ALL",
                    tooltipLabel: `${translate("common.sortBy")} GnomAD genomes`
                }
            ]
        },
        {
            order: 8,
            type: "evidence",
            title: translate("common.evidence"),
            checked: true,
        },
        {
            order: 9,
            type: "sampleInfo",
            title: translate("casesQuery.sampleInfo"),
            checked: true,
        }
    ]
    let columns: any = []
    let lsColumns = localStorage.getItem(`query_columns`)
    if (lsColumns) lsColumns = JSON.parse(lsColumns)
    if (!lsColumns || !Array.isArray(lsColumns)) {
        const columnsOnSS = Object.keys(sessionStorage).filter((x: string) => x.indexOf("query_columns") > -1)
        if (columnsOnSS.length > 2) sessionStorage.removeItem(columnsOnSS[2])
        SetQueryColumnsOnLS(tableData)
        columns = tableData
    } else {
        const columnsShowed = lsColumns
        if (isMitochondrial)
            columns = columnsShowed.filter((x: any) => x !== x.acmg)
        else
            columns = columnsShowed.filter((x: any) => x !== x?.mitoMap)

    }
    const [showedColumns, setshowedColumns] = useState<any>(columns)

    const [variantsSelecteds, setvariantsSelecteds] = useState<string[]>([])

    function handleVariantSelecteds(id: string, all: boolean = false) {
        if (all) {
            if (variantsSelecteds.length === (queryVariants?.data?.variants ?? []).length) {
                setvariantsSelecteds([])
            } else {
                setvariantsSelecteds((queryVariants?.data?.variants ?? []).map((x: any) => x.value1.map((x: any) => x.id).join(",")))
            }
        } else {
            if (variantsSelecteds.find((x: any) => x === id))
                setvariantsSelecteds(variantsSelecteds.filter((x: any) => x !== id))
            else
                setvariantsSelecteds([...variantsSelecteds, id])
        }
    }
    function handleColumnSelect(tableTitles: TableTitleI[]) {
        let newTableColumn: any = tableTitles.map((x: any) => {
            return tableData.find((y: any) => y.type === x.value)
        })
        SetQueryColumnsOnLS(newTableColumn)
        setshowedColumns(newTableColumn)
    }

    const loader = (
        <div style={{
            position: "fixed",
            minHeight: "70vh",
            background: "linear-gradient(to top, rgba(0,0,0, 0), rgba(255,255,255,1), rgba(255,255,255,1))",
            width: "calc(100vw - 56px)",
            paddingTop: "150px",
            marginLeft: "19px"
        }}>
            <Loader onContainer={true} type={'DNA'} />
        </div>
    )

   
    const getColumnesFilteredByMitochCase = (data: any[]) => {
        if (isMitochondrial)
            return data.filter((x: any) => x.type !== "classification")
                .map((x: any) => { return { label: x.title, value: x.type } })
        return data.filter((x: any) => x.type !== "mitoMap")
            .map((x: any) => { return { label: x.title, value: x.type } })

    }

    const elementRef: any = useRef(null);
    const { windowSize } = useWindowSize()


    const TableBodyRef: React.MutableRefObject<any> = useRef();
    const TableHeadersRef: React.MutableRefObject<any> = useRef();
    const handleScrollTableBody = (scroll: any): void => {
        const thScrollLeft = TableHeadersRef?.current?.scrollLeft;
        if (thScrollLeft || thScrollLeft === 0) TableHeadersRef.current.scrollLeft = scroll.target.scrollLeft;
    };
    const handleScrollHeadersTable = (scroll: any): void => {
        const tbScrollLeft = TableBodyRef?.current?.firstElementChild?.scrollLeft;
        if (tbScrollLeft || tbScrollLeft === 0) TableBodyRef.current.firstElementChild.scrollLeft = scroll.target.scrollLeft;
    };


    let QueryBody_TableDiv2_width: any = "150";
    let queryBodyE: any = document.getElementById("QueryBody");
    if (queryBodyE?.firstChild && queryBodyE.firstChild.getBoundingClientRect()) {
        QueryBody_TableDiv2_width = queryBodyE.firstChild.getBoundingClientRect().width;
    }
    const [queryBody_TableDiv2_Y, setqueryBody_TableDiv2_Y] = useState(100)
    useEffect(() => {
        // let queryBodyE: any = document.getElementById("QueryBody");
        // if (queryBodyE?.firstChild && queryBodyE.firstChild.getBoundingClientRect()) {
        setTimeout(() => {
            // let queryBodyE: any = document.getElementById("QueryBody");
            // let newqueryBody_TableDiv2_Y = queryBodyE.getBoundingClientRect().height;
            // elementRef?.current?.clientHeight
            setqueryBody_TableDiv2_Y(elementRef?.current?.clientHeight + 17)
        }, 1);
        // }
        setvariantsSelecteds([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowSize, queryVariants])
    return (
        <>
            <div id="QueryBody"
                style={{
                    width: "100%",
                    // width: "calc(100vw - 17px)",
                    position: "fixed",
                    backgroundColor: theme.backgroundSecondary,
                    zIndex: 2,
                    paddingRight: "57px"
                }}>
                {
                    props.filtersTabs.length === 0 && ((!queryVariants.loading && !queryVariants.data) || !queryVariants.loaded) ?
                        <QueryBodyEmpty history={props.history}
                            filterCollapsed={props.filterCollapsed} />
                        :
                        <div
                            ref={elementRef}
                            style={{
                                margin: '12px 0 0 20px',
                                width: "100%",
                                backgroundColor: theme.backgroundPrimary,
                                paddingTop: "10px",
                                border: "solid 1px lightgray",
                                borderBottom: "none",
                                boxShadow: "rgb(200 200 200) 0px 0px 4px"
                            }}>
                            <QueryBodyHeader history={props.history}
                                filterCollapsed={props.filterCollapsed}
                                filtersTabs={props.filtersTabs}
                                filtersTabSelected={props.filtersTabSelected}
                                handlefiltersTabSelected={props.handlefiltersTabSelected}
                                handleCreateFilter={props.handleCreateFilter} />
                            <QueryBodyTableToolkit
                                history={props.history}
                                optionsColumnSelect={getColumnesFilteredByMitochCase(tableData)}
                                handleColumnSelect={handleColumnSelect}
                                columnsSelecteds={getColumnesFilteredByMitochCase(showedColumns)}
                                paginatorComp={props.paginator.paginatorComponent}
                                variantSelecteds={variantsSelecteds}
                                variantsToSaveGroup={getVariantsSelecteds_IdsPosChangeGene(queryVariants?.data?.variants, variantsSelecteds)}
                                handleGetQueryVariant={(pageNumber?: number, pageSize?: number, then?: any): void => {
                                    props.handleGetQueryVariant(pageNumber, pageSize, then)
                                }}
                                handleReloadDataTable={props.handleReloadDataTable}
                                isMitochondrial={isMitochondrial}
                                tableTitles={showedColumns}
                                handleVariantSelecteds={handleVariantSelecteds}
                                headersTableRef={TableHeadersRef}
                                handleScrollHeadersTable={handleScrollHeadersTable}
                                queryVariantsLoaded={queryVariants.loaded && queryVariants.data ? true : false}
                                setScrollYPosition={props.setScrollYPosition}
                            />
                        </div >
                }
            </div >
            {queryVariants.loading === true ? loader :
                queryVariants.loaded && (
                    !queryVariants.data ? <div id="QueryBody_TableDiv1" style={{
                        margin: `${(elementRef?.current?.clientHeight ?? 0) + 20}px 0 25px 20px`,
                        width: QueryBody_TableDiv2_width
                    }}>
                        <Card style={{ borderRadius: "0px", boxShadow: "rgb(200 200 200) 0px 5px 4px", backgroundColor: theme.backgroundThird, border: "none" }}>
                            <Card.Body style={{ padding: "70px", backgroundColor: theme.backgroundPrimary, textAlign: "center", color: theme.grayHeader, fontSize: "9px", fontWeight: "bold" }}>
                                {translate("casesQuery.noQueryWasDoneYet").toUpperCase()}
                            </Card.Body>
                        </Card>
                    </div> :
                        <div id="QueryBody_TableDiv2" style={{
                            //----------------OPCION 1-------------------------------------------------------
                            // margin: `${(elementRef?.current?.clientHeight ?? 0) + 20}px 0 25px 20px`,
                            margin: `${(queryBody_TableDiv2_Y ?? 0 + 20).toString().split(",")}px 0 25px 20px`,
                            // width: "calc(100vw - 18px)"
                            width: QueryBody_TableDiv2_width

                            //--------------------------------------------------------------------------------
                            //----------------OPCION 2-------------------------------------------------------
                            // width: "100%",
                            // position: "absolute",
                            // backgroundColor: "#F4F4F4",
                            // marginLeft: "-23px"
                        }}>
                            <Card style={{
                                //----------------OPCION 1-------------------------------------------------------
                                borderRadius: "0px",
                                boxShadow: "rgb(200 200 200) 0px 5px 4px",
                                backgroundColor: theme.backgroundThird,
                                //--------------------------------------------------------------------------------
                                //----------------OPCION 2-------------------------------------------------------
                                // borderRadius: "0px",
                                // boxShadow: "rgb(200 200 200) 0px 5px 4px",
                                // backgroundColor: theme.backgroundThird,
                                // margin: `${(elementRef?.current?.clientHeight ?? 0) + 20}px 14px 25px 43px`
                            }}>
                                <Card.Body style={{ padding: "0px" }}>
                                    {(queryVariants.data.variants ?? []).length === 0 ?
                                        <div style={{ padding: "30px", backgroundColor: theme.backgroundPrimary, textAlign: "center", color: theme.grayHeader, fontSize: "9px", fontWeight: "bold" }}>
                                            {translate("common.noResultsFound").toUpperCase()}
                                        </div>
                                        :
                                        <VariantsTable history={props.history}
                                            studyId={studyId}
                                            study={queryVariants.data?.study}
                                            tableTitles={showedColumns}
                                            queryVariants={queryVariants}
                                            listaCigotas={queryVariants.data?.listaCigotas}
                                            variantSelecteds={variantsSelecteds}
                                            comments={queryVariants.data?.study?.comments}
                                            studyType={queryCombos.data?.study?.type.toLowerCase().trim()}
                                            isMitochondrial={isMitochondrial}
											isSomatic={isSomatic}
                                            handleVariantSelecteds={handleVariantSelecteds}
                                            handleGetQueryVariant={(pageNumber?: number, pageSize?: number, then?: any): void => {
                                                props.handleGetQueryVariant(pageNumber, pageSize, then);
                                            }}
                                            setScrollYPosition={props.setScrollYPosition}
                                            tableBodyRef={TableBodyRef}
                                            handleScrollTableBody={handleScrollTableBody}
                                        />}
                                </Card.Body>
                                <Card.Footer style={{ padding: "10px 10px 10px 15px", backgroundColor: "#F7F7F7" }}>
                                    {props.paginator.component}
                                </Card.Footer>
                            </Card>
                        </div>)
            }
            {/* //-------------------------------------------------------------------------------- */}
            {/* <Footer/> */}
        </>
    )
}
