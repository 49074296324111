import React from 'react';
import { useContext } from "react";
import ReportedVariantsContext from "../../../Contexts/ReportedVariants/ReportedVariantsContext";
import UserContext from "../../../Contexts/User/UserContext";
import DetailHeader from "../../Layout/Detail/DetailHeader";

interface DetailVariantsHaderI {
    history: any,
    handleShowProcessVariant(): void,
    reportedBy: string,
    handleShowDeleteVariant(): void
}

export default function DetailVariantsHeader(props: DetailVariantsHaderI) {
    const { user } = useContext(UserContext);
    const { variant } = useContext(ReportedVariantsContext);
    let buttonsActions = {}
    if (`${user.data.id}` === `${props.reportedBy}`) {

        buttonsActions = {
            edit: () => { props.handleShowProcessVariant() },
            delete: () => { props.handleShowDeleteVariant() },
        }
    }
    const repVar = variant.data?.reportedVariantInfo
    const title = `${repVar?.chromosome}:${repVar?.position} - ${repVar?.reference} > ${repVar?.alternative}`

    return (
        <>
            <DetailHeader history={props.history}
                titleTooltipLabel={"Go to Reported variants"}
                titleLabel={title}
                buttonsActions={buttonsActions}
            />
        </>
    )
}
export { DetailVariantsHeader as DetailHeader }