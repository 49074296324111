import React from 'react';
import { useState, useEffect, useContext } from 'react';
import QueryHome from './QueryHome/QueryHome';
import DetailQuery from './Detail/DetailQuery';
import { Layout } from '../../Layout/Layout';
import QueryGenes from './QueryGenes/QueryGenes';
import QueryContextState from '../../../Contexts/Query/QueryState';
import { Redirect } from 'react-router-dom';
import UserContext from '../../../Contexts/User/UserContext';
import UserService from '../../../Services/UserServices';
import "./styles.css";

interface QueryI {
    history: any,
    match: any
}
export default function Query(props: QueryI) {
    const { user } = useContext(UserContext);
    const [body, setbody] = useState<any>(<></>)
    const UserS = new UserService();
    const cuRoll: any = user.data?.rolestoString ?? ""
    const usRoll: any = UserS.user?.rolestoString ?? ""
    const caseId = props.match?.params?.caseId;
    useEffect(() => {
        if ((usRoll !== "ROLE_ADMIN" && usRoll !== "ROLE_OPERATOR" && usRoll !== "ROLE_REPORTER" && usRoll !== "ROLE_GUEST") ||
            (cuRoll !== "" && (cuRoll !== UserS.user?.rolestoString ?? "-"))) {
            setbody(<Redirect to='/' />)
        }
        else {
            const variantId = props.match?.params?.variantId;
            const geneId = props.match?.params?.geneId;
            if ((props.history?.location?.pathname ?? "").indexOf("query/gene") > -1 && caseId && geneId)
                setBodyQueryGenes(caseId, geneId, variantId ?? 0)
            else if (caseId && variantId)
                setBodyDetails(caseId, variantId)
            else if (caseId)
                setBodyQuery(caseId)
            else
                setNullBody()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])
    function setBodyQueryGenes(caseId: number, geneName: string, variantId?: string) {
        setbody(
            <QueryGenes history={props.history}
                caseId={caseId}
                variantId={variantId}
                geneName={geneName}
            />
        )
    }

    function setBodyQuery(caseId: number) {
        setbody(
            <QueryHome history={props.history}
                caseId={caseId} />
        )
    }
    function setBodyDetails(caseId: number, variantId: string) {
        setbody(
            <DetailQuery history={props.history}
                studyId={caseId}
                variantId={variantId} />
        )
    }
    function setNullBody() {
        setbody(<></>)
    }
    return (
        <QueryContextState studyId={caseId}>
            <Layout history={props.history}>
                {body}
            </Layout>
        </QueryContextState>
    )
}
