import { ZygosityT } from "./BioFunctionalData";

function getSampleId_NameTagAffSex(name: string, tag: string, affected: string, sex: string): string {
    sex = (sex && sex.trim().toLowerCase() !== "null") ? sex : ""
    return `${name}-${tag}-${affected}-${sex}`
}

function alleleClassifier(alleles: string, chrom: string, isMale: boolean, defaultRes?: ZygosityT): ZygosityT {
    let vType: ZygosityT = defaultRes ?? "het";
    switch (alleles.toString().trim().replace("/", ",")) {
        case `1,1`:
            if (isMale &&
                (chrom.toLowerCase().indexOf("x") > -1 ||
                    chrom.toLowerCase().indexOf("y") > -1))
                vType = "hem";
            else
                vType = "hom";
            break;  // hom     --> het = false,hom = true
        case `0,1`: vType = "het"; break;// het     --> het = true ,hom = false
        case `1,0`: vType = "het"; break;// het     --> het = true ,hom = false	--> esta expresion capas no esta
        case `1,2`: vType = "het"; break;// het     --> het = true ,hom = false
        case `0,0`: vType = "homref"; break;// hom_ref --> homRef = true, ad =
        case `.,.`: vType = "notcov"; break;// not_covered
    }
    return vType
}
export { getSampleId_NameTagAffSex, alleleClassifier }

