import React from 'react';
import { faPlus, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs } from "react-bootstrap";
// import "../../styles.css";
import QueryTitle from "../../QueryTitle";
import { useContext } from "react";
import QueryContext from "../../../../../Contexts/Query/QueryContext";
import { Tooltip } from "../../../../Common/Tooltip/Tooltip";
import { LangContext } from "../../../../../Contexts/Lang";

interface QueryBodyHeaderI {
    history: any,
    filterCollapsed: any,
    filtersTabs: { urlData: string, filterName: string, id: number }[],
    filtersTabSelected: number | undefined,
    handlefiltersTabSelected: any,
    handleCreateFilter: any
}

export default function QueryBodyHeader(props: QueryBodyHeaderI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { queryCombos,tabsMax, dispatch: { getActualTabs, getActualTabSelected, RemoveFiltersTabs, getTabsId, getTabSelectedId, CleanFiltersBadgesAndTable } } = useContext(QueryContext);
    const selected = props.filtersTabSelected ? props.filtersTabSelected : "none";
    const handleCloseFilter = (e: Event, filterTab: any, index: number) => {
        e.stopPropagation(); // previene que no me quede ninguna ventana desseleccionada // por el evento de seleccionado de tab
        const studyTabSelected = getActualTabSelected();
        if (studyTabSelected && `${studyTabSelected.id}` === `${filterTab.id}`) {
            const query_filterTabs = getActualTabs() ?? []
            if (query_filterTabs.length > 1) {
                if (index !== 0) {
                    props.handlefiltersTabSelected(+query_filterTabs[index - 1].id)
                } else {
                    props.handlefiltersTabSelected(+query_filterTabs[index + 1].id)
                }
            } else {
                sessionStorage.removeItem(getTabSelectedId())
                sessionStorage.removeItem(getTabsId())
                CleanFiltersBadgesAndTable()
                props.history.push(`/cases/query/caseId/${queryCombos.data?.study.id}/`)
            }
            RemoveFiltersTabs(filterTab.id, false)
        } else {
            e.preventDefault();  // previene que no se recarga cuando la previa cuando cierro la ventana que se esta mostrando.
            RemoveFiltersTabs(filterTab.id, false)
        }
    }

    return (
        <div id={"QueryBodyHeader"} style={{ display: "flex", width: "100%" }}>
            <div style={{ borderBottom: "solid 1px #DEE2E6" }}>
                <QueryTitle history={props.history}
                    study={queryCombos.data?.study}
                    goToCasesDetail={true} />
            </div>
            <Tabs activeKey={selected} id="uncontrolled-tab-example"
                style={{
                    // marginLeft: "15px",
                    marginRight: "8px",
                    width: "inherit",
                    alignSelf: "flex-end",
                    justifyContent: "flex-end"
                }}
                onSelect={(x: any) => {
                    if (x === "new") {
                        props.handleCreateFilter("")
                    } else {
                        const query_filterTabs = getActualTabs() ?? []
                        if (query_filterTabs.find((y: any) => y.id === +x))
                            props.handlefiltersTabSelected(+x)
                    }
                }}

            >
                {props.filtersTabs.length > 0 && props.filtersTabs.map((x: any, i: number) => {
                    const tabTitle = (
                        <span>
                            {x.filterName?.length > 10 ? `${x.filterName.substring(0, 17)}...` : x.filterName}
                            <FontAwesomeIcon icon={faWindowClose}
                                onClick={(e: any) => { handleCloseFilter(e, x, i) }}
                                style={{
                                    fontSize: "19px",
                                    color: "darkgray",
                                    float: "right",
                                    marginLeft: "10px"
                                }}
                            />
                        </span>
                    )
                    if (!x.description) return <Tab key={x.id} eventKey={x.id} title={tabTitle} />
                    return <Tab key={x.id} eventKey={x.id}
                        title={
                            <Tooltip label={`${x.filterName}: ${x.description}`}>{tabTitle}</Tooltip>
                        } />
                })
                }
                {
                    props.filtersTabs.length < tabsMax &&
                    <Tab key={"new"} eventKey="new" title={
                        <Tooltip label={translate("casesQuery.addQuery")}>
                            <FontAwesomeIcon icon={faPlus} style={{ fontSize: "18px", color: "#28A745" }} />
                        </Tooltip>
                    }
                        style={{ margin: "-1px 5px" }}
                    />
                }
            </Tabs>
        </div>
    )
}

