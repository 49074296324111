import React from 'react';
import { Button, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { evidenciaAcmg } from "../../../utils/BioFunctionalData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faExternalLinkAlt, faUndo } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../../Contexts/Lang";
import VariantServices from "../../../Services/VariantServices";
import { AsyncDataI } from '../../../Contexts/interfaces';
import { Tooltip } from "../Tooltip/Tooltip";
import BadgeVariantEvidence from '../Badges/BadgeVariantEvidence';
import BadgeVariantVeredict from '../Badges/BadgeVariantVeredict';
interface AcmgCalcDataI {
    veredict: string,
    evidence: string[]
    variantId: string,
    acmgBayScore: string,
    acmgBayVeredict: string,
    title: JSX.Element,
}
interface ModalAcmgCalcI {
    studyId: string,
    show: boolean,
    handleClose(): void,
    data?: AcmgCalcDataI
}

export default function ModalAcmgCalc(props: ModalAcmgCalcI) {
    const { dispatch: { translate } } = useContext(LangContext);

    const [evidenceSelected, setevidenceSelected] = useState<string[]>([])

    const initialNewVeredict = { loading: false, loaded: false, data: null, error: false }
    const [newVerdict, setnewVerdict] = useState<AsyncDataI>(initialNewVeredict)

    const VariantS = new VariantServices();
    useEffect(() => {
        if (props.data) setevidenceSelected(props?.data?.evidence)
        setnewVerdict(initialNewVeredict)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.data?.evidence])

    const launchAcmgCalculatorRequest = () => {
        setnewVerdict({ loading: true, loaded: false, data: null, error: false })
        if (props.data && evidenceSelected.length > 0 && `${props.data?.evidence}` !== `${evidenceSelected}`) {
            VariantS.classify_variant(
                props.studyId,
                [props.data?.variantId],
                props.data?.acmgBayScore,
                props.data?.acmgBayVeredict,
                evidenceSelected
            ).then((res: any) => {
                if (res.status === 200) {
                    setnewVerdict({ loading: false, loaded: true, data: res.data, error: false })
                } else {
                    setnewVerdict({ loading: false, loaded: false, data: null, error: res.data })
                }
            }).catch((error: any) => {
                setnewVerdict({ loading: false, loaded: false, data: null, error: error })
            })
        }
        // if (evidenceSelected.length === 0) {
        //     setnewVerdictResponse(false)
        //     setnewVerdictLoading(false)
        // }
    }
    function splitArrayIntoChunksOfLen(arr: any[], len: number) {
        var chunks = [], i = 0, n = arr.length;
        while (i < n) {
            chunks.push(arr.slice(i, i += len));
        }
        return chunks;
    }
    function handleCheck(evidence: string) {
        if (evidenceSelected.find((z: any) => { return z === evidence })) {
            setevidenceSelected(evidenceSelected.filter((z: any) => z !== evidence))
        } else {
            setevidenceSelected([...evidenceSelected, evidence])
        }
    }
    const evidenceGrid = splitArrayIntoChunksOfLen(evidenciaAcmg, 4)
        .map((x: any[], i: number) => {
            return (
                <Row key={i}> {
                    x.map((y: string, ii: number) => {
                        return (
                            <Col xs={3} style={{ display: "flex" }} key={ii}
                                onClick={() => { handleCheck(y) }}
                            >
                                <Form.Check type="checkbox"
                                    style={{}}
                                    key={`${i}_${ii}`}
                                    checked={evidenceSelected.find((z: any) => z === y) ? true : false}
                                    onChange={() => { }}
                                />
                                <BadgeVariantEvidence labelInput={y}
                                    id={i * 10 + ii}
                                    fontSize={10} />
                            </Col>
                        )
                    })
                }</Row>
            )
        })
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: "#444B59" }}>
                <Modal.Title style={{ margin: "0px 10px", color: "#FFF" }} >
                    <FontAwesomeIcon icon={faCalculator} style={{ alignSelf: "center", fontSize: "17px" }} />&nbsp;
                    <span>
                        Pathogenicity Calculator
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    {/* <br /> */}
                    <div style={{
                        "backgroundColor": "#f1f1f1",
                        "padding": "10px",
                        "opacity": "0.8",
                        "borderRadius": "3px",
                        "borderBottom": "solid 1px lightgray",
                        // "fontSize": "13px"
                    }}>
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>{translate("common.variant")}</span>:&nbsp;&nbsp;{props.data?.title ?? "-"}
                        <hr style={{ margin: "7px 0" }} />
                        <div key={"lalala"} style={{ display: "flex" }}>
                            <span style={{ fontWeight: "bold", color: "darkslategray", fontSize: "13px" }}>Evidence:</span> &nbsp;&nbsp;
                            {props.data?.evidence.map((x: any, i: number) => {
                                return (
                                    <span key={`${i + 100}`}>
                                        <BadgeVariantEvidence labelInput={x}
                                            id={i + 1}
                                            fontSize={10} />
                                    </span>
                                )
                            })}
                            &nbsp;
                        </div>
                        {/* <FontAwesomeIcon icon={faEquals} style={{ color: "darkslategray", alignSelf: "center", fontSize: "13px" }} /> */}
                        <span style={{ fontWeight: "bold", color: "darkslategray", fontSize: "13px" }}>ACMG veredict:</span> &nbsp;&nbsp;
                        <BadgeVariantVeredict labelInput={props.data?.veredict ?? ""}
                            id={0}
                            size={"big"}
                        />
                    </div>
                    <span style={{ float: "right", fontSize: "10px", color: "#009EEA", padding: "3px 5px" }}>
                        <a href="https://www.mgz-muenchen.com/files/Public/Downloads/2018/ACMG%20Classification%20of%20Sequence%20Variants.pdf" target="_blank" rel="noreferrer">
                            ACMG Standards and Guidelines
                        </a>
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{ margin: "1px 5px" }} />
                    </span>
                    <br />
                    <Container style={{ marginTop: "5px", marginBottom: "10px" }}>
                        {evidenceGrid}
                    </Container>
                    {`${props.data?.evidence}` !== `${evidenceSelected}` && <>
                        <div >
                            <div style={{ float: "right" }}>
                                <Tooltip label={translate('common.restore')}>
                                    <Button variant="outline-secondary"
                                        style={{ margin: "0 2px", height: "fit-content" }}
                                        onClick={() => {
                                            if (props.data) setevidenceSelected(props?.data?.evidence)
                                            setnewVerdict(initialNewVeredict)
                                        }}>
                                        <FontAwesomeIcon icon={faUndo} style={{ border: "none" }} />
                                    </Button>
                                </Tooltip>
                                <Button variant="outline-primary"
                                    style={{ margin: "0 10px 0px 0px", height: "fit-content" }}
                                    onClick={() => { launchAcmgCalculatorRequest() }}>
                                    {translate('common.calculate')}
                                </Button>
                            </div>
                            <br />
                            <br />
                        </div>
                    </>}
                    {

                        (newVerdict.loading || newVerdict.loaded) && <>
                            {/* <br style={{ height: "2px" }} /> */}
                            <span style={{ fontSize: "16px", marginLeft: "10px" }}>{translate("common.results")}</span>
                            <div style={{ "backgroundColor": "#f7f7f7", "padding": "10px", borderRadius: "3px" }}>
                                <span style={{ fontWeight: "bold", color: "darkslategray", fontSize: "13px" }}>{translate("common.evidence")}:</span> &nbsp;&nbsp;
                                {evidenceSelected.map((x: any, i: number) => {
                                    return (
                                        <span key={`${i + 200}`}>
                                            <BadgeVariantEvidence labelInput={x}
                                                id={i + 1}
                                                fontSize={10} />
                                        </span>
                                    )
                                })}
                                <br />
                                <span style={{ fontWeight: "bold", color: "darkslategray", fontSize: "13px" }}>ACMG veredict:</span> &nbsp;&nbsp;
                                {newVerdict.loading ?
                                    <Spinner animation="grow" role="status" style={{ height: "20px", width: "20px" }} /> :
                                    <BadgeVariantVeredict labelInput={newVerdict.data?.InterVarVeredict ?? ""}
                                        id={0}
                                        size={"big"}
                                    />
                                }
                                <br />
                                <span style={{ fontWeight: "bold", color: "darkslategray", fontSize: "13px" }}>Bayesian classification:</span> &nbsp;&nbsp;
                                {newVerdict.loading ?
                                    <Spinner animation="grow" role="status" style={{ height: "20px", width: "20px" }} /> :
                                    <>
                                        <BadgeVariantVeredict labelInput={newVerdict.data?.ACMG_BAYESIAN_VEREDICT ?? ""}
                                            id={0}
                                            size={"big"}
                                        />&nbsp;
                                        ({(newVerdict.data?.ACMG_BAYESIAN_SCORE ?? 0.0).toFixed(4)})
                                    </>}
                            </div>
                        </>
                    }
                </>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => {
                    setnewVerdict(initialNewVeredict)
                    props.handleClose()
                }}>
                    {translate(`common.close`)}
                </Button>

            </Modal.Footer>
        </Modal >
    )
}

export type { AcmgCalcDataI }