import React from 'react';
import { faFileMedical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
// import ModalAcmgCalc, { AcmgCalcDataI } from "src/Components/Common/AcmgCalc/ModalAcmgCalc";
import { AsyncDataI } from "src/Contexts/interfaces";
import { LangContext } from "src/Contexts/Lang";
import DetailQueryTable, { DetailQueryTableRowI } from "./DetailQueryTable";
// import ButtonAcmgCalc from "src/Components/Common/AcmgCalc/ButtonAcmgCalc";
import { Col, Container, Row } from 'react-bootstrap';
import { widthSizesT } from 'src/Helpers/WindowsSizeHelper';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import BadgeVariantVeredict from '../../../Common/Badges/BadgeVariantVeredict';
import BadgeVariantEvidence from '../../../Common/Badges/BadgeVariantEvidence';

interface ClassificationTableI {
	variant: AsyncDataI,
	isMitochondrial: boolean,
	studyId: number,
	widthType: widthSizesT
}

export default function ClassificationTable(props: ClassificationTableI) {
	const { theme } = useContext(ThemeContext);
	const { dispatch: { translate } } = useContext(LangContext);

	// const initAcmgCalculator = { show: false, data: undefined }
	// const [acmgCalculator, setacmgCalculator] = useState<{ show: boolean, data: AcmgCalcDataI | undefined }>(initAcmgCalculator);
	// const handleCloseacmgCalculator = () => { setacmgCalculator(initAcmgCalculator) }
	// const handleShowacmgCalculator = (veredict: string, evidence: string[], variantId: string, acmgBayScore: string, acmgBayVeredict: string, title: JSX.Element) => {
	//     setacmgCalculator({ show: true, data: { veredict, evidence, variantId, acmgBayScore, acmgBayVeredict, title } })
	// }

	const infoCd = props.variant.data?.variant?.infoColumnData

	let intervarComponent: any = <></>
	let mitochondrialComponent: any = <></>
	if (!props.isMitochondrial) {
		let inter_ver = infoCd?.INTERVAR_VEREDICT;
		const inter_evi = infoCd?.INTERVAR_EVIDENCE && infoCd?.INTERVAR_EVIDENCE !== "" ? infoCd?.INTERVAR_EVIDENCE.split(",") : []
		// const change = `${props.variant.data?.variant?.reference} > ${props.variant.data?.variant?.alternative}`

		if (inter_ver || inter_evi.lenght > 0) {
			intervarComponent = (<div key={"commonSource"}>
				{inter_ver &&
					<span key={"lalala"}>
						<BadgeVariantVeredict labelInput={inter_ver}
							id={0}
							size={"big"}
						/>
						{/* <ButtonAcmgCalc handleShowacmgCalculator={handleShowacmgCalculator}
                            intervar_veredict={inter_ver}
                            intervar_evidence={inter_evi}
                            variantId={props.variant.data?.variant?.id}
                            acmgBayScore={infoCd.ACMG_BAYESIAN_SCORE}
                            acmgBayVeredict={infoCd.ACMG_BAYESIAN_VEREDICT}
                            chromosome={props.variant.data?.variant?.chromosome}
                            position={props.variant.data?.variant?.position}
                            change={change}
                            geneName={infoCd.GENE_NAME}
                            studyId={`${props.studyId}`}
                            rsArray={[props.variant.data?.variant?.vcfId]}
                        /> */}
						<br />
					</span>
				}
				{inter_evi.map((x: any, i: number) => {
					return (
						<span key={`${i + 100}`}>
							<BadgeVariantEvidence labelInput={x}
								id={i + 1}
								fontSize={10} />
						</span>
					)
				})}
			</div>);
		}
	} else {
		let dataTableclassification: DetailQueryTableRowI[] = [
			{
				id: "apogeeScore",
				label: "APOGEE score",
				value: infoCd?.APOGEE_PROBP ?? " -- "
			},
			{
				id: "AF_HOM",
				label: "GnomaAd homoplasmy",
				value: infoCd?.AF_HOM ?? " -- "
			},
		]
		mitochondrialComponent = <DetailQueryTable id="DetailQueryTable_classification"
			title={
				<>
					<FontAwesomeIcon icon={faFileMedical} style={{ color: "lightgray", float: "right", margin: "3px 5px 0 0" }} />
					&nbsp;{translate("common.classification")}
				</>
			}
			data={dataTableclassification}
		/>

	}
	const rowBaseStyle: React.CSSProperties = { margin: 0, padding: 0 }
	return (
		<>
			{/* <ModalAcmgCalc studyId={`${props.studyId}`}
                show={acmgCalculator.show}
                handleClose={handleCloseacmgCalculator}
                data={acmgCalculator.data}
            /> */}
			<Container style={{ padding: 0, marginTop: 15 }}>
				{props.isMitochondrial ? mitochondrialComponent :
					<>
						<Row style={{
							color: theme.grayHeader,
							display: "block",
							margin: 0,
							padding: "0px 5px 3px",
							fontSize: "17px",
						}}>
							<FontAwesomeIcon icon={faFileMedical} style={{ color: "lightgray", float: "right", margin: "3px 5px 0 0" }} />
							&nbsp;{translate("common.classification")}
						</Row>
						<Row style={{ margin: 0, padding: 0 }}>
							<Col xs={12} sm={7} md={7} lg={6} style={{ ...rowBaseStyle, borderTop: "1px solid lightgray", }}>
								<Row style={{ ...rowBaseStyle, padding: "5px" }}>
									<Col lg={4} xl={3} style={{ ...rowBaseStyle, padding: "0 5px", fontSize: "13px", color: theme.grayHeader, fontWeight: "bold" }}>
										ACMG/AMP 2015
									</Col>
									<Col lg={8} xl={9} style={{ ...rowBaseStyle, padding: "0 5px" }}>
										{infoCd?.INTERVAR_VEREDICT ? intervarComponent : " -- "}
									</Col>
								</Row>
							</Col>
							<Col xs={12} sm={5} md={5} lg={6} style={{ ...rowBaseStyle, borderTop: "1px solid lightgray", }}>
								<Row style={{ ...rowBaseStyle, padding: "5px" }}>
									<Col lg={4} xl={3} style={{ ...rowBaseStyle, padding: "0 5px", fontSize: "13px", color: theme.grayHeader, fontWeight: "bold" }}>
										{translate("common.bayesianClassifier")}
									</Col>
									<Col lg={8} xl={9} style={{ ...rowBaseStyle, padding: "0 5px" }}>
										{infoCd?.ACMG_BAYESIAN_VEREDICT ?
											<>
												<BadgeVariantVeredict labelInput={infoCd?.ACMG_BAYESIAN_VEREDICT}
													id={0}
													size={"big"}
												/> &nbsp;-&nbsp;
												{infoCd?.ACMG_BAYESIAN_SCORE &&
													<span style={{ marginLeft: "5px", fontSize: "13px" }}>
														{`${translate("common.score")[0].toUpperCase()}${translate("common.score").substring(1, translate("common.score").length)}`}&nbsp;:&nbsp;
														{infoCd?.ACMG_BAYESIAN_SCORE}
													</span>
												}
											</>
											: " -- "}
									</Col>
								</Row>

							</Col>
						</Row>
					</>
				}
			</Container>
			<br />
		</>
	)
} 