import React from 'react';
import { useState, useEffect } from 'react';
import { Layout } from '../../Components/Layout/Layout';
import CasesContextState from '../../Contexts/Cases/CasesState';

import { marked } from 'marked';

interface ChangelogPageI {
	history: History,
}
export default function ChangelogPage(props: ChangelogPageI) {
	// const markdownContent = raw('./changelog.md');
	const [content, setContent] = useState('');
	useEffect(() => {
		fetch('/changelog.md')
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.text();
			})
			.then((data) => { setContent(data) })
			.catch((error) => {
				console.error('Error fetching the text file: ', error);
			});
	}, []);

	return (
		<CasesContextState>
			<Layout history={props.history}>
				<div style={{
					display: "flex",
					placeContent: "center"
				}}>
					<div style={{
						margin: "55px 35px",
						backgroundColor: "#FFF",
						padding: "60px 70px",
						maxWidth: 1000,
						boxShadow: "rgba(124, 124, 124, 0.35) 0px 5px 10px 0px"
					}}>
						<MyComponent markdownContent={content} />
					</div>
				</div>
			</Layout>
		</CasesContextState >
	)
}


const MyComponent = (props: { markdownContent: any }) => {
	const htmlContent = marked(props.markdownContent);
	return <div dangerouslySetInnerHTML={{ __html: `${htmlContent}` }} />;
};