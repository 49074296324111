//RGB to Hex and Hex to RGB methods
import generatedGitInfo from '../generatedGitInfo.json';

function rgbToHex(r: any, g: any, b: any) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
function hexToRgb(hex: any, opacity?: number) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        var r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);
        return opacity ? `rgba(${r},${g},${b},${opacity})` : `rgba(${r},${g},${b})`;//return 23,14,45 -> reformat if needed 
    }
    return null;
}

//Values checker
const checkValue = (val: any) => {
    return val && val !== 0 ? val : "--"
}
const checkValueAndReturnAnother = (val: any, elementToReturn: string | JSX.Element) => {
    if (val && val !== 0)
        return elementToReturn
    return "--"
}


// Range data used to set filters on Advanced Querys, used to set the slider component <SliderInput... and <SliderRangeButton...
const rangeDataArray = Array.from(Array(51).keys()).map((x) => x * 10);
const common_0to1_steps001_Array: number[] = Array.from(Array(1001).keys()).map((x) => x * 0.001);
const common_0to1_steps0001_Array: number[] = Array.from(Array(10001).keys()).map((x) => x * 0.0001);
const common_Less1to1_steps001_Array: number[] = Array.from(Array(2001).keys()).map((x) => (x - 1000) * 0.001);
const common_Less10to10_steps01_Array: number[] = Array.from(Array(2001).keys()).map((x) => (x - 1000) * 0.01);


const getWolePageHeight = (): number => {
    var body = document.body,
        html = document.documentElement;

    var wholePageHeight = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);
    return wholePageHeight;
}

const compareToSort = (a: any, b: any, field: string, inverse: boolean = false, numeric = false) => {
    if (numeric) {
        a = +a[`${field}`].toString().toLowerCase()
        b = +b[`${field}`].toString().toLowerCase()
    } else {
        a = a[`${field}`].toString().toLowerCase()
        b = b[`${field}`].toString().toLowerCase()
    } if (a < b) return inverse ? 1 : -1;
    if (a > b) return inverse ? -1 : 1;
    return 0;
}
const Caching = () => {
    let version = localStorage.getItem('version');
    if (version !== generatedGitInfo.gitVersion) {
        if ('caches' in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload();
        }
        // localStorage.clear();
        localStorage.setItem('version', generatedGitInfo.gitVersion);
    }
};

const RemoveSpecialCharacters = (input: string): string => {
    //Solo devuelve caracteres alfnumericos, espacios, guiones(-,_), comas, puntos y parentesis  
    return input.replace(/[^,A-Za-zÀ-ÿ0-9.\s_()-]/g, '');
}

function getDateFormated(date: Date | undefined) {
    if (date instanceof Date) {
        let d = date.toISOString().split('T')[0]
        return `${d.split("-")[2]}/${d.split("-")[1]}/${d.split("-")[0]}`
    }
    return ""
}

export {
    rgbToHex,
    hexToRgb,
    checkValue,
    checkValueAndReturnAnother,
    rangeDataArray,
    common_0to1_steps001_Array,
    common_0to1_steps0001_Array,
    common_Less1to1_steps001_Array,
    common_Less10to10_steps01_Array,
    getWolePageHeight,
    compareToSort,
    Caching,
    RemoveSpecialCharacters,
    getDateFormated
}