import React from 'react';
import { useState, useContext, useRef, useEffect } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { LangContext } from "../../../../../Contexts/Lang";
import { Formik } from 'formik';
import * as yup from 'yup';
import FormInput from '../../../../Common/Form/FormInput';
import FiltersBadgesApplied from '../../FiltersBadgesApplied';
import QueryContext from '../../../../../Contexts/Query/QueryContext';

type formInputType = "number" | "text" | "textarea" | "password" | "email"

interface ModalSaveFilterI {
    showState: boolean,
    handleClose: any,
    handleSaveFilter: any
}
function ModalSaveFilter(props: ModalSaveFilterI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { badgesFilters } = useContext(QueryContext);
    let formRef: any = useRef();
    function createSchema() {
        const name = yup.string()
            .min(3, translate(`error.toShort`))
            .max(50, translate(`error.toLong`))
            .required(translate(`error.required`));
        const description = yup.string();
        return yup.object().shape({
            name,
            description,
        })
    }

    const initialForm = {
        name: "",
        description: ""
    }

    function createForm(values: any, handleChange: any, errors: any) {
        function commonFormInput(type: formInputType, key: string, name: string, label: string, placeholder?: string, required: boolean = false) {
            return <FormInput type={type}
                labelBold={true}
                labelColor={"gray"}
                // borderRadius={true}
                label={label}
                key={key}
                formGrouplStyle={{ "marginBottom": "15px" }}       // onKeyDown={(e: any) => handleEnter(e)}
                placeHolder={placeholder}
                name={name}
                formLabelStyle={{ fontSize: "13px", marginBottom: "5px" }}
                // disabled={props.mode === "read"}
                required={required}
                requiredFeedback={errors[name]}
                feedbackValidator={!!errors[name]}
                value={values[name]}
                onChange={handleChange} />
        }
        const nameInput = commonFormInput("text", "nameKey", "name", translate(`home.processCase.name`), `${translate(`home.processCase.name`)}...`, true);
        const descriptionInput = commonFormInput("textarea", "descKey", "description", translate(`home.processCase.description`), `${translate(`home.processCase.description`)}...`)
        return (<>
            <Row>
                <Col xs={12}>{nameInput}</Col>
            </Row >
            <Row>
                <Col xs={12}>{descriptionInput}</Col>
            </Row>
        </>
        )
    }

    function handleSubmit(i: any, methods: any) {
        props.handleSaveFilter(i.name, i.description)
            .then((res: any) => {
                if (res && Object.keys(res).indexOf("error") > -1) {
                    if (res.error === "query.error.titleAlreadyExists" && formRef.current) {
                        formRef.current.setErrors(
                            { "name": `${translate(`common.name`)} ${translate(`error.alreadyDefined`)}` }
                        )
                    }
                    // else 
                    //     formRef.current.setErrors(
                    //         { "name": `${translate(`common.name`)} ${translate(`error.alreadyDefined`)}` }
                    //     )
                } else {
                    props.handleClose()
                }

            })
        // props.handleClose()
    }

    return (
        <Modal show={props.showState} onHide={() => props.handleClose()} animation={false}>

            <Formik initialValues={initialForm}
                validationSchema={createSchema()}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                handleSubmit={console.log}
                innerRef={formRef}
                validateOnChange={false}
                validateOnBlur={true}>
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton style={{ backgroundColor: '#444B59', color: "#fff", padding: "5px 10px 5px 25px" }}>
                                <Modal.Title >Guardar Filtros</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {createForm(values, handleChange, errors)}
                                <FiltersBadgesApplied filtersBadgesData={badgesFilters ?? []} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => props.handleClose()}>
                                    {translate('common.cancel')}
                                </Button>
                                <Button variant="outline-primary" type="submit" >
                                    {translate('common.save')}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>

                )}
            </Formik>
        </Modal >
    )
}


interface ModalLoadFilterI {
    showState: boolean,
    handleClose: any,
    savedFilters: any[],
    handleLoadFilter: any,
    filterTabs: any[]
}
function ModalLoadFilter(props: ModalLoadFilterI) {
    const { tabsMax } = useContext(QueryContext);
    const { dispatch: { translate } } = useContext(LangContext);

    const [selected, setselected] = useState<any>(0)
    useEffect(() => {
        setselected(0)
    }, [props])
    // console.log(props.filterTabs)
    const filtersLoads = (props.filterTabs ?? []).map((x: any) => x.id)

    const rows = props.savedFilters
        // .filter((x: any, i: number) => i === 0)
        .map((x: any, i: number) => {
            const rowSelected = selected === x ? true : false,
                disableFilter = filtersLoads.indexOf(x.id) > -1 || (props.filterTabs ?? []).length >= tabsMax;
            const getRowValue = (backgroundColor: string, titleColor: string, descriptionColor: string) => {
                return (
                    <tr key={i}
                        style={{
                            borderTop: i !== 0 ? "1px solid lightgray" : "",
                            padding: 0,
                            backgroundColor: backgroundColor
                        }}
                        onClick={() => { if (!disableFilter) setselected(x) }}>

                        <td style={{ border: "none", verticalAlign: "middle", textAlign: "center", padding: 0, width: 0, maxWidth: "10px" }}>
                            <Form.Check type={"radio"} style={{ margin: "0 5px", width: 20 }}
                                key={i}
                                checked={rowSelected}
                                disabled={disableFilter}
                                onChange={() => { }}
                            />
                        </td>
                        <td style={{ border: "none", width: "5px", verticalAlign: "middle", padding: 3 }}
                        // onClick={handleRowClick}
                        >
                            <div style={{ fontSize: "12px", fontWeight: "bold", color: titleColor }}>
                                {x.title}
                            </div>
                            <div style={{ fontSize: "10px", color: descriptionColor }}>
                                {
                                    x.description && x.description.trim().length > 0 ?
                                        x.description :
                                        " - "
                                }
                            </div>
                        </td>
                    </tr>
                )
            }
            const backgroundColor = disableFilter ? "#F4F4F4" : "auto",
                titleColor = disableFilter ? "gray" : "darkslategray",
                descriptionColor = disableFilter ? "gray" : "auto";
            return getRowValue(backgroundColor, titleColor, descriptionColor)
        })
    return (
        <Modal id="FilterModals" show={props.showState} onHide={() => props.handleClose()} animation={false}>
            <Modal.Header closeButton style={{ backgroundColor: '#444B59', color: "#fff", padding: "5px 10px 5px 25px" }}>
                <Modal.Title >{translate("casesQuery.loadFilters")}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}>
                <Table hover>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.handleClose()}>
                    {translate('common.cancel')}
                </Button>
                <Button variant="outline-primary" onClick={() => {
                    if (selected) {
                        props.handleLoadFilter(selected.filters, selected.title, selected.id, selected.description)
                    }
                    props.handleClose();
                }}>
                    {translate('common.load')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export { ModalSaveFilter, ModalLoadFilter }