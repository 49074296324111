import React from 'react';
import { useContext, useState } from 'react';
import { Button, Row } from "react-bootstrap";
import { LangContext } from "../../../Contexts/Lang";
import { commentStatesColors, CommentStateT } from '../../../utils/BioFunctionalData';
import { faComment, faTrash, faEdit, faCommentMedical } from '@fortawesome/free-solid-svg-icons';
import CopyToClipboard from '../CopyToClipboard';
import CloseButton from '../CloseButton';
import { PlacementT, Popover } from '../Popover';
import { ModalCommentGroupT } from './ModalCommentGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Urlify } from 'src/Helpers/Helpers';
import { Tooltip } from '../Tooltip/Tooltip';
import { VariantIdentificationTableRowI } from './VariantIdentificationTableRow';

interface CommentPopoverI {
    hasComment: boolean,
    variantId: string,
    variant_IdPosChangeGen: {
        id: string,
        position: string,
        change: string,
        gen: string
    }
    comment?: {
        state: CommentStateT,
        comment: string,
        dateCreated: Date,
    },
    handleShowCommentGroupModal(
        type: ModalCommentGroupT,
        data: { variants: VariantIdentificationTableRowI[] },
        editData?: { comment?: string, state?: string }): void,
    handleShowRemoveCommentModal(variantsIds: string[]): void,
    placement?: PlacementT
}
export default function CommentIconPopover(props: CommentPopoverI) {
    const [show, setshow] = useState<boolean>(false)
    const { dispatch: { translate } } = useContext(LangContext);
    let color = "gray"
    if (Object.keys(commentStatesColors).indexOf((props.comment?.state ?? "").toUpperCase()) > -1) color = commentStatesColors[(props.comment?.state ?? "").toUpperCase()]

    // const color = commentStatesColors[props.comment?.state.toUpperCase()]
    let date;
    if (props.comment?.dateCreated)
        date = new Date(props.comment?.dateCreated ?? "").toISOString().replaceAll("-", "/").replace("T", " ").split(".")[0].split(" ")[0];

    const title = (<>
        <Row style={{ margin: 0, display: "block" }}>
            <span style={{ fontSize: "14px", color: color, fontWeight: "bold" }}>
                {translate(`common.relevanceOptions.${props.comment?.state?.toUpperCase()}`)}
            </span>
            {date &&
                <span style={{ margin: 0, fontSize: "10px", color: "gray" }}>
                    &nbsp;|&nbsp;{date}&nbsp;
                </span>
            }
            <span style={{ float: "right", margin: "-2px -7px 0 0" }}>
                <CopyToClipboard valueToCopy={props.comment?.comment ?? ""}
                    fontSize={"11px"}
                    buttonStyles={{
                        margin: 0, padding: 0, fontSize: "13px"
                    }} />&nbsp;
                <Button variant="outline-danger"
                    style={{ borderRadius: "50px", padding: "0px 5px", border: "none", marginTop: "1px" }}
                    onClick={(e: any) => {
                        e.stopPropagation();
                        props.handleShowRemoveCommentModal([props.variantId])
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} style={{ border: "none", fontSize: "10px", marginBottom: "1px" }} />
                </Button>&nbsp;
                <Button variant="outline-primary"
                    style={{ borderRadius: "50px", padding: "0px 4px", border: "none", marginTop: "1px" }}
                    onClick={(e: any) => {
                        e.stopPropagation();
                        props.handleShowCommentGroupModal("comment",
                            { variants: [{ ...props?.variant_IdPosChangeGen ?? {}, checked: true }], },
                            {
                                comment: props?.comment?.comment,
                                state: props?.comment?.state
                            }
                        )
                    }}
                >
                    <FontAwesomeIcon icon={faEdit} style={{ border: "none", fontSize: "10px", marginBottom: "1px" }} />
                </Button>&nbsp;&nbsp;
                <CloseButton styles={{ marginTop: "1px" }} onClose={() => setshow(false)} />
            </span>
        </Row>


    </>)
    return (
        props.hasComment ?
            <Popover id={"CommentIconPopover"}
                placement={props.placement}
                show={show}
                title={title}
                content={
                    <Urlify text={props.comment?.comment ?? ""} asParegraph={true} />
                }
                trigger={"click"}
                style={{ minWidth: "350px", fontSize: "10px", zIndex: 2 }}
                contentStyle={{ padding: "0 0 0 5px" }}
            >
                <Button variant="outline-light"
                    onClick={(e: any) => { e.stopPropagation(); setshow(true) }}
                    style={{
                        border: "none",
                        borderRadius: "50px",
                        padding: "3px 6px",
                        margin: "0 3px",
                    }}>
                    <FontAwesomeIcon icon={faComment}
                        style={{
                            fontSize: "15px",
                            verticalAlign: "text-bottom",
                            color: color
                        }} />
                </Button>
            </Popover >
            :
            <Tooltip label={translate("common.addComment")} placement={"top"}>
                <Button variant="outline-light"
                    style={{
                        border: "none",
                        borderRadius: "50px",
                        padding: "3px 6px",
                        margin: "0 3px"
                    }}
                    onClick={(e: any) => {
                        e.stopPropagation();
                        props.handleShowCommentGroupModal("comment", {
                            variants: [{ ...props?.variant_IdPosChangeGen ?? {}, checked: true }]
                        })
                    }}
                >
                    <FontAwesomeIcon icon={faCommentMedical}
                        style={{
                            fontSize: "16px",
                            verticalAlign: "text-bottom",
                            color: "gainsboro"
                        }} />
                </Button>
            </Tooltip>
    )
}