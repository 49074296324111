import React, { useContext } from 'react';
import { ModalSampleDataTableI, modalTabsType, SampleInfoIgvDataI } from '../IGV/ModalSamplesIgv';
import { AlignmentT, ModalIgvDataI } from '../IGV/types';
import BadgeSample, { BadgeSamplePopover } from './Badges/BadgeSample';
import { BadgeZygosityByAllele } from './Badges/BadgeZygosity';
import { SampleInfoDp, SampleInfoFilter, SampleInfoFs, SampleInfoGenotype, SampleInfoGq, SampleInfoHf, SampleInfoQual, SampleInfoVaf } from '../Cases/Query/VariantsTable/VariantsTableComponents';
import { LangContext } from 'src/Contexts/Lang';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { Badge, Button } from 'react-bootstrap';
import { StudyI } from 'src/Contexts/interfaces';
import { Chunkfy } from 'src/Helpers/Helpers';
import LabelPopover from './LabelPopover';
import { checkValue } from 'src/utils/helpers';

function getModal_IGVData(
	study: StudyI, studyId: any, allRv: any[] = [], sampleNameSelected?: string, viewType?: AlignmentT): ModalIgvDataI {
	let commonData: any
	let data: any = {}
	const alignaments = allRv.map((x: any, i: number) => {
		const actualSample = study?.samples.find((y: any) => y.sample.name === x.sampleName)
		// console.log("actualSample")
		// console.log(actualSample)
		if (i === 0) {
			data.studyName = x.sampleName;
			data.assembly = study?.genomeReference;
			commonData = {
				effect: "NA",
				impact: "-"
			}
		}
		return ({
			effect: x.effect,
			impact: x.impact,
			sampleName: x.sampleName,//x.sampleName,
			sampleBadge: <BadgeSample name={actualSample?.sample?.name ?? "--"}
				afected={actualSample?.afectado}
				fontSize={"14px"}
			/>
		})
	})
	const igvSamples = study?.samples.map((x: any) => {
		const igvElement = alignaments.find((y: any) => y.sampleName === x.sample.name)
		if (igvElement)
			return (igvElement)
		return {
			...commonData,
			sampleName: x.sample.name,
			sampleBadge: <BadgeSample
				name={x.sample?.name ?? "--"}
				afected={x.afectado}
				fontSize={"14px"}
			/>
		}
	})
	return {
		...data,
		caseId: studyId,
		library: study.libraryPrepKit,
		sampleNameSelecte: sampleNameSelected,
		view: viewType,
		alignments: igvSamples
	}
}

const getModal_SampleData = (study: StudyI, isSomatic: boolean, rv: any, allRv: any[] = []): ModalSampleDataTableI[] => {
	const isMitochondrial = (study?.type ?? "").toUpperCase().trim() === "MT-DNA"
	return (study?.samples ?? []).map((x: any, i: number) => {
		const getInfo = (sampleName: string) => {
			const variant = allRv.find((y: any) => y.genoInfo?.sampleName.trim().toUpperCase() === sampleName)
			if (variant) {
				return <>
					{!isSomatic &&
						<BadgeZygosityByAllele alleles={variant.genoInfo.alleles}
							chromosome={rv.chromosome}
							study={study}
							isMale={(x?.sample?.sexo ?? "").toUpperCase().trim() === "MALE"}
							isMitochondrial={isMitochondrial}
						/>
					}
					&nbsp;&nbsp;
					<SampleInfo variant={variant}
						isMitochondrial={isMitochondrial}
						isSomatic={isSomatic} />
				</>
			}
			return "N/A"
		}
		const info = getInfo((x.sample?.name ?? "").trim().toUpperCase())
		return {
			name: x.sample?.name ?? "",
			badge: <BadgeSample
				name={x.sample?.name ?? "--"}
				afected={x.afectado}
				fontSize={"14px"} />,
			info: info
		}
	})
}


function getModal_SampleIgvData(
	view: AlignmentT,
	study: StudyI,
	studyType: string,
	studyId: any,
	isSomatic: boolean,
	rv: any,
	allRv: any[],
	sampleNameSelected?: string,
	mood: modalTabsType = "sample"
): SampleInfoIgvDataI {
	let handlerData: any = {
		caseId: studyId,
		mood: mood,
		chromosome: rv.chromosome,
		position: rv.chPosition,
		gen: rv.geneName
	}
	if (studyType === "familiar" || studyType === "multiple") {
		const sd = getModal_SampleData(study, isSomatic, rv, allRv)
		handlerData = {
			...handlerData,
			sampleData: {
				change: rv.codonChange,
				table: sd
			}
		}
	}
	if (study?.hasBam) {
		const igvData = getModal_IGVData(study, studyId, allRv, sampleNameSelected, view)
		handlerData = {
			...handlerData,
			igvData: igvData
		}
	}
	return handlerData
}

interface ModalSampleIgvButtonI {
	study: StudyI,
	studyType: string,
	studyId: any,
	isSomatic: boolean,
	rv: any,
	allRv: any[],
	handleShowModalSamplesIgv(i: SampleInfoIgvDataI): void
}
function ModalSampleIgvButton(props: ModalSampleIgvButtonI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { theme } = useContext(ThemeContext);

	const getShowModalLink = (label: string, mood: modalTabsType = "sample", view: AlignmentT = "variant", sampleNameSelected?: string) => {
		return (
			<Button style={{ cursor: "pointer", color: theme.link, padding: "2px 5px" }} variant="outline-light"
				onClick={(e: any) => {
					e.stopPropagation();
					const handlerData = getModal_SampleIgvData(
						view, props.study, props.studyType, props.studyId, props.isSomatic,
						props.rv, props.allRv, sampleNameSelected, mood
					)
					props.handleShowModalSamplesIgv(handlerData)
				}}
			>
				{label}
			</Button>
		)
	}
	if (props.studyType === "familiar" || props.studyType === "multiple")
		return (
			<div style={{ textAlign: "center" }}>
				<hr style={{ margin: "7px" }} />
				<strong>{translate("casesQuery.viewAlignment")} : </strong>
				{getShowModalLink("Show samples", "sample")}
			</div>
		)
	if (props.study?.hasBam)
		return (
			<div style={{ textAlign: "center" }}>
				<hr style={{ margin: "7px" }} />
				<strong>{translate("casesQuery.viewAlignment")} : </strong>
				&nbsp;&nbsp;
				{getShowModalLink(translate("common.variant"), "igv", "variant", props.rv.sampleName)}&nbsp;&nbsp;
				{getShowModalLink(translate("common.gene"), "igv", "gene", props.rv.sampleName)}
			</div>
		)
	return (<></>)
}

interface SampleInfoI {
	variant: any,
	isSomatic: boolean,
	isMitochondrial: boolean,
	longLabel?: boolean
}
function SampleInfo(props: SampleInfoI) {
	return (
		<span style={{ fontSize: "12px" }}>
			{props.isSomatic && <><SampleInfoVaf genoInfo={props.variant.genoInfo} />{` | `}</>}
			<SampleInfoDp genoInfo={props.variant.genoInfo} longLabel={props.longLabel ?? undefined} />
			{
				props.isMitochondrial ? <>
					{` | `}<SampleInfoGenotype genoInfo={props.variant.genoInfo} longLabel={props.longLabel} />
					{` | `}<SampleInfoHf genoInfo={props.variant.genoInfo} longLabel={props.longLabel} />
				</> : <>
					{!props.isSomatic && <>{` | `}<SampleInfoFs fs={props.variant.infoColumnData?.FS} longLabel={props.longLabel} /> </>}
					{!props.isSomatic && <>{` | `}<SampleInfoGq conditionalQuality={props.variant.genoInfo?.conditionalQuality} longLabel={props.longLabel} /> </>}
					{!props.isSomatic && <>{` | `}<SampleInfoQual quality={props.variant.quality} longLabel={props.longLabel} /> </>}
				</>
			}
			{` | `}<SampleInfoFilter filter={props.variant.filter} />
		</span>
	)
}
interface SampleInfoTableI {
	variant: any,
	isSomatic: boolean,
	isMitochondrial: boolean,
	longLabel?: boolean
}
function SampleInfoTable(props: SampleInfoTableI) {

	return (
		<div style={{ fontSize: "12px" }}>
			{props.isSomatic && <><SampleInfoVaf genoInfo={props.variant.genoInfo} />{` | `}</>}
			<SampleInfoDp genoInfo={props.variant.genoInfo} longLabel={props.longLabel} />
			{
				props.isMitochondrial ? <>
					{` | `}<SampleInfoGenotype genoInfo={props.variant.genoInfo} longLabel={props.longLabel} />
					{` | `}<SampleInfoHf genoInfo={props.variant.genoInfo} longLabel={props.longLabel} />
				</> : <>
					{!props.isSomatic && <>{` | `}<SampleInfoFs fs={props.variant.infoColumnData?.FS} longLabel={props.longLabel} /> </>}
					{!props.isSomatic && <>{` | `}<SampleInfoGq conditionalQuality={props.variant.genoInfo?.conditionalQuality} longLabel={props.longLabel} /> </>}
					{!props.isSomatic && <>{` | `}<SampleInfoQual quality={props.variant.quality} longLabel={props.longLabel} /> </>}
				</>
			}
			{` | `}<SampleInfoFilter filter={props.variant.filter} />
		</div>
	)
}




interface SampleInfo_componentI {
	currentKey: number,
	rv: any,
	id: string,
	isSomatic: boolean,
	isMitochondrial: boolean,
	study: StudyI,
	studyType: string,
	studyId: string,
	allRv: any[],
	handleShowModalSamplesIgv(i: SampleInfoIgvDataI): void,
	handleGoVariantsDetails(variantId: number): void
}
interface SampleInfoMultipleComponentI extends SampleInfo_componentI {
	listaCigotas: any[],
}
function SampleInfoSimpleOrFamiliarComponent(props: SampleInfo_componentI) {
	const sampleInfo_simpleOrFamiliar = (props.allRv ?? []).map((x: any, i: number) => {
		const sample = props.study?.samples.find((y: any) => y.sample.name.toUpperCase() === x.genoInfo.sampleName.toUpperCase());
		return (
			<div key={i}>
				<div style={{ textAlign: "center" }}>
					<BadgeSamplePopover sample={sample} />
					{!props.isSomatic &&
						<BadgeZygosityByAllele alleles={x.genoInfo.alleles}
							chromosome={x.chromosome}
							study={props.study}
							isMale={(sample?.sample?.sexo ?? "").toUpperCase().trim() === "MALE"}
							isMitochondrial={(props.study?.subtype ?? "").toUpperCase().trim() === "MT-DNA"}
						/>}
				</div>
				<div >
					<SampleInfo variant={x}
						isSomatic={props.isSomatic}
						isMitochondrial={props.isMitochondrial} />
					{(props.allRv ?? []).length - 1 !== i && <hr style={{ margin: "5px" }} />}
				</div>
			</div>
		)
	})
	return (
		<>
			{sampleInfo_simpleOrFamiliar}
			<ModalSampleIgvButton
				study={props.study}
				studyType={props.studyType}
				studyId={props.studyId}
				isSomatic={props.isSomatic}
				rv={props.rv}
				allRv={props.allRv}
				handleShowModalSamplesIgv={props.handleShowModalSamplesIgv}
			/>
		</>
	)
}
function SampleInfoMultipleComponent(props: SampleInfoMultipleComponentI) {
	const { dispatch: { translate } } = useContext(LangContext);
	if (Object.keys((props.listaCigotas ?? [])).indexOf(`${props.currentKey}`) === -1) return (<></>)
	const multiSampleInfo = (props.listaCigotas ?? [])[props.currentKey]
	return (
		<div key={props.currentKey}
			id={`${props.id}_${props.currentKey}`}
			style={{ minWidth: "100px" }}
			onClick={() => props.handleGoVariantsDetails(props.rv.id)}>
			<span style={{ cursor: "text" }} onClick={(e: any) => { e.preventDefault(); e.stopPropagation() }}>
				<div style={{ textAlign: "center" }}>
					<strong> In samples: </strong>
					<br />
					{
						Chunkfy(multiSampleInfo.samples, 4).map((xx: any, ii: number) =>
							<div key={`chunk_${ii}`}> {
								xx.map((x: any, i: number) =>
									<Badge variant="secondary" key={i}
										style={{ fontSize: "11px", color: "white", margin: "1px 3px", fontWeight: "bolder" }}>
										{x ?? "-"}
									</Badge>)
							}  <br />
							</div>)
					}
				</div>
				<div style={{ textAlign: "center" }}>
					<LabelPopover popoverTitle={translate("casesQuery.sampleInfoDetail.coverage.title")}
						popoverBody={translate("casesQuery.sampleInfoDetail.coverage.content")}
						label={<><strong>DP</strong> {` : ${checkValue(multiSampleInfo.dp)} \t`}</>} />
					<>
						{` | `}<strong>Freq</strong>{` : ${checkValue(multiSampleInfo.frequency)}`}<br />
						{` | `}<strong>Het</strong>{` : ${checkValue(multiSampleInfo.heterozygotQuantity)} `}
						{` | `}<strong>Hom</strong>{` : ${checkValue(multiSampleInfo.homozygotQuantity)} `}
					</>
				</div>
				<ModalSampleIgvButton
					study={props.study}
					studyType={props.studyType}
					studyId={props.studyId}
					isSomatic={props.isSomatic}
					rv={props.rv}
					allRv={props.allRv}
					handleShowModalSamplesIgv={props.handleShowModalSamplesIgv}
				/>
			</span>
		</div>
	)
}


export {
	SampleInfoSimpleOrFamiliarComponent,
	SampleInfoMultipleComponent,
	SampleInfo,
	SampleInfoTable,
	ModalSampleIgvButton
}