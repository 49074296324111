import React from 'react';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { LangContext } from "../../../../../Contexts/Lang";
interface PanelFooterI {
    handleFilter: any,
    handleCleanFilter: any,
    enableFilter: boolean,
}
export default function PanelFooter(props: PanelFooterI) {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <div style={{
            margin: "5px 0",
            placeSelf: "flex-end",
            position: "fixed",
            bottom: "-5px",
            backgroundColor: "#f5f5f5",
            width: "100%",
            paddingRight: "4px",
            zIndex: 1
        }}>
            <div style={{ float: "right", padding: "5px" }}>
                <Button variant="outline-secondary"
                    style={{ margin: "0 2px", height: "fit-content" }}
                    onClick={() => props.handleCleanFilter()}
                >
                    {translate('common.cleanFilter')}
                </Button>
                <Button variant={!props.enableFilter ? "outline-primary" : "primary"}
                    disabled={!props.enableFilter}
                    style={{ margin: "0 2px 0px 0px", height: "fit-content" }}
                    onClick={() => props.handleFilter()}
                >
                    {translate('common.filter')}
                </Button>
            </div>
        </div>
    )
}