import React from 'react';
import { useState } from "react"
import { OverlayTrigger, Popover } from "react-bootstrap"
import "./Tooltip/Tooltip.css"
import { TriggersT } from '../IGV/types';


type PlacementT = 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start'
interface TooltipI {
	children: any,
	content: any,
	title?: any,
	placement?: PlacementT,
	trigger?: TriggersT,
	style?: any,
	titleStyle?: any,
	contentStyle?: any,
	autoClose?: number,
	show?: boolean,
	id?: string
}
function MyPopover(props: TooltipI) {
	const placement = props.placement ?? "top"
	const trigger = props.trigger ?? "click"
	const style = props.style ?? {}
	const titleStyle = props.titleStyle ?? {}
	const contentStyle = props.contentStyle ?? {}
	const [show, setshow] = useState(false)
	if (!props.autoClose || props.show !== undefined) {
		return (
			<OverlayTrigger
				show={props.show !== undefined ? props.show : undefined}
				rootClose
				placement={placement}
				trigger={[trigger, trigger]}
				overlay={
					<Popover id={props.id ?? "popover-basic"} style={{ ...style }}>
						{
							props.title &&
							<Popover.Title as="h5" style={{ padding: "5px 10px", fontSize: "13px", ...titleStyle }}>
								{props.title}
							</Popover.Title>
						}
						<Popover.Content id={"MyPopover-Popover.Content-Body"} style={{ ...contentStyle }}>
							{props.content}
						</Popover.Content>
					</Popover>
				}>
				{props.children}
			</OverlayTrigger>
		)
	}
	const handleAutoClose = (e: any) => {
		if (e) {
			setshow(true)
			setTimeout(() => {
				setshow(false)
			}, props.autoClose);
		} else
			setshow(false)
	}
	return (
		<OverlayTrigger
			show={show}
			rootClose
			placement={placement}
			trigger={[trigger, trigger]}
			onToggle={(e: any) => handleAutoClose(e)}
			overlay={<Popover id={props.id ?? "popover-basic"} style={{ ...style, padding: "0" }}>
				{
					props.title &&
					<Popover.Title as="h5" style={{ padding: "5px 10px", fontSize: "13px", ...titleStyle }}>
						{props.title}
					</Popover.Title>
				}
				<Popover.Content style={{ padding: "0" }}>
					{props.content}
				</Popover.Content>
			</Popover>
			}>
			{props.children}
		</OverlayTrigger>
	)

}

export { MyPopover as Popover }
export type { PlacementT }