import { createContext } from "react";
// import { AsyncDataI } from "../interfaces";

type ThemeT = "default" | "dark";

interface BPlatformThemeI {
    themeType: ThemeT,
    redBitgenia: string,
    success: string,
    edit: string,
    error: string,
    warning: string,
    link: string,
    info: string,
    grayHeader: string,
    backgroundPrimary: string,
    backgroundSecondary: string,
    backgroundThird: string,
    background4: string,
    borderColor: string,
    cnvDuplication: string,
    cnvDeletion: string,
    cnvNone: string,
    heterozygous:string,
    acmg_vere_p: string,
    acmg_vere_lp: string,
    acmg_vere_vus: string,
    acmg_vere_lb: string,
    acmg_vere_b: string,
    acmg_crit_p_vs: string,
    acmg_crit_p_str: string,
    acmg_crit_p_mod: string,
    acmg_crit_p_sup: string,
    acmg_crit_b_sa: string,
    acmg_crit_b_str: string,
    acmg_crit_b_mod: string,
    acmg_crit_b_sup: string,
	chart_nivo_nivo: string[],
	chart_nivo_set3: string[],
}

interface ContextThemeStatesI {
    theme: BPlatformThemeI
}
interface ContextThemePropsStatesI extends ContextThemeStatesI {
    dispatch: {
        changeTheme: (themeType: ThemeT) => void;
    }
}

const ThemeContext = createContext({} as ContextThemePropsStatesI);

export default ThemeContext;
export type { ContextThemeStatesI, ThemeT, BPlatformThemeI }