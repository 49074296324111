import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Layout } from '../Components/Layout/Layout';
import UserContext from '../Contexts/User/UserContext';
import { Redirect } from 'react-router-dom';
import UserService from '../Services/UserServices';
import IgvBody, { MutationI } from '../Components/IGV/IgvBody';
import DetailLayout from '../Components/Layout/Detail/DetailLayout';
import { Spinner } from 'react-bootstrap';
import BadgeSample from '../Components/Common/Badges/BadgeSample';
import StudyService from '../Services/StudyServices';
import QueryTitle from '../Components/Cases/Query/QueryTitle';
import { AsyncDataI, SamplesI } from '../Contexts/interfaces';

interface UrlIgvInputDataI {
    caseId: number,
    type: "cnv" | "snp",
    gen: string,
    chromosome: string,
    position: string,
    assembly: string,
    sampleNameSelected: string,
    // viewT: string,
    alignments: MutationI[]
}
interface IgvPageI {
    history: any,
    match?: any,
    // query: boolean,
    // caseId?: number,
    // variantId?: number
}
export default function IgvPage(props: IgvPageI) {
    const { user } = useContext(UserContext);
    const [body, setbody] = useState<any>(<></>)
    const [urlInputData, seturlInputData] = useState<UrlIgvInputDataI | null>(null)
    const [studyRes, setstudyRes] = useState<AsyncDataI>({ loaded: false, loading: false, error: null, data: null })
    const UserS = new UserService();
    const cuRoll: any = user.data?.rolestoString ?? ""
    const usRoll: any = UserS.user?.rolestoString ?? ""

    const StudyS = new StudyService();
    const getStudy = (id: string) => {
        setstudyRes({ loaded: false, loading: true, error: null, data: null })
        if (urlInputData)
            StudyS.read_case(id).then((res: any) => {
                if (res.status === 200) {
                    setstudyRes({ loaded: true, loading: false, error: null, data: res.data })
                } else
                    setstudyRes({ loaded: true, loading: false, error: null, data: res.data })
            }).catch((e: any) =>
                setstudyRes({ loaded: false, loading: false, error: e, data: null })
            )
    }
    function handleGetCase() { if (urlInputData) getStudy(urlInputData.caseId.toString().trim()) }
    useEffect(() => {
        if ((usRoll !== "ROLE_ADMIN" && usRoll !== "ROLE_OPERATOR" && usRoll !== "ROLE_REPORTER" && usRoll !== "ROLE_GUEST") ||
            (cuRoll !== "" && (cuRoll !== UserS.user?.rolestoString ?? "-")))
            setbody(<Redirect to='/' />)
        else {
            if (!studyRes.loaded && !studyRes.loading) handleGetCase();
            const caseId = props.match?.params?.caseId,
                gen = props.match?.params?.gen,
                type = props.match?.params?.type,
                sampleSelected = props.match?.params?.sampleSelected,
                chPos = props.match?.params?.chPos;
            const ch = chPos.split(":")[0],
                pos = chPos.split(":")[1],
                ref = props.match?.params?.ref;
            let samples = props.match?.params?.samples;
            samples = JSON.parse(samples).samples;
            let rlIgvInputData: UrlIgvInputDataI = {
                caseId: caseId,
                type: type,
                gen: gen,
                chromosome: ch,
                position: pos,
                assembly: ref,
                sampleNameSelected: sampleSelected,
                // viewT: string,
                alignments: samples
            }
            seturlInputData(rlIgvInputData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, user])
    useEffect(() => {
        if (studyRes.loaded) {
            const samples3 = (urlInputData?.alignments ?? []).map((x: MutationI): MutationI => { 
                const s = studyRes.data?.study?.samples.find((xx: SamplesI) => xx.sample.name === x.sampleName);
                return {
                    ...x,
                    sampleName: x.sampleName,
                    sampleBadge: <BadgeSample name={s.sample.name ?? "--"}
                        afected={s.afectado}
                        fontSize={"14px"}
                    />
                }
            })
            setbody(<IgvBody type={urlInputData?.type}
                mutation={samples3}
                // /cases/_igv/caseId/:caseId/gen/:gen/chPos/:chPos/ref/:ref/samples/[effect:"frameshift variant",impact:"high", name:"200000321061"]
                // /cases/_igv/caseId/700/gen/SLC27A4/chPos/11:89948505/ref/GRCh38
                // cases/_igv/:caseId/:ch/:gen/:pos/:ref
                chromosome={urlInputData?.chromosome}
                gen={`${urlInputData?.gen}`}
                position={urlInputData?.position}
                assembly={urlInputData?.assembly}
                sampleNameSelected={urlInputData?.sampleNameSelected}
                viewT={"variant"}                                                       // VEEER!!!
                parentT={"page"}
                library={studyRes.data?.study.libraryPrepKit}
            />)
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studyRes])

    return (
        <Layout history={props.history}>
            <DetailLayout DetailHeader={
                <>
                    {(studyRes.loading || !studyRes.loaded) ?
                        <Spinner animation="grow" role="status" style={{ height: "20px", width: "20px", color: "gray" }} /> :
                        <span onClick={() => {
                            props.history.push(`/cases/caseId/${urlInputData?.caseId}/`);
                        }}>
                            <QueryTitle history={props.history}
                                study={studyRes.data?.study} />
                        </span>
                    }
                </>
                // <>
                //     <h4 style={{ cursor: "pointer", marginBottom: 0, display: "flex" }}
                //         <Button variant="outline-ligth">
                //             <FontAwesomeIcon icon={faAngleLeft}
                //                 style={{ fontSize: "17px" }} />
                //         </Button>
                //         {
                //             (studyRes.loading || !studyRes.loaded) ?
                //                 <Spinner animation="grow" role="status" style={{ height: "20px", width: "20px", color: "gray" }} /> :
                //                 <Tooltip label={"Go to case detail"} placement={"bottom"}>
                //                     <span>
                //                         {studyRes.data?.study?.name}
                //                     </span>
                //                 </Tooltip>
                //         }
                //     </h4>
                // </>
            }>
                {body}
            </DetailLayout>
        </Layout>
    )
}
