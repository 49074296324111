import React from 'react';
import { useContext } from 'react';
import { Badge, Col, Row, Table, Form, Dropdown } from 'react-bootstrap';
import { LangContext } from '../../Contexts/Lang';
import "../Common/table.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends, faCalendarDay, faEdit, faTrash, faFileAlt, faCog, faDownload } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Common/Loader/Loader';
import { Tooltip } from '../Common/Tooltip/Tooltip';
import GensetService from '../../Services/GensetService';
import { createTxtFile } from '../../Helpers/Helpers';
import PanelGenesContext from '../../Contexts/PanelGenes/PanelGenesContext';

interface PanelGenesTableI {
    genesSelecteds: any,
    handleGenesSelecteds?: any,
    handleShowProcessGene?: any,
    handleReload: any,
    history: any
}
export default function PanelGenesTable(props: PanelGenesTableI) {
    const { panels } = useContext(PanelGenesContext);

    const rows = (panels?.data?.results ?? []).map((x: any, i: number) =>
        <TableRow key={i}
            actualRow={x}
            genesSelecteds={props.genesSelecteds}
            handleGenesSelecteds={props.handleGenesSelecteds}
            handleReload={props.handleReload}
            handleShowProcessGene={props.handleShowProcessGene}
            history={props.history}
        />
    )
    return (
        <>
            {panels.loading ?
                <Loader onContainer={true} type={'DNA'} /> :
                <div style={{}}>
                    <Table responsive="md" className="selectTable" style={{ marginBottom: "-1px" }}
                    >
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}

interface TableRowI {
    actualRow: any,
    genesSelecteds: any[],
    handleGenesSelecteds?: any,
    handleReload: any,
    handleShowProcessGene?: any,
    history: any,
}

function TableRow(props: TableRowI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const GenesetS = new GensetService();
    const sysPanel = props.actualRow.systemPanel;
    const getFirstColumn = () => {
        const getDescription = () => {
            if (props.actualRow.description && props.actualRow.description.trim() !== "")
                return ` - ${props.actualRow.description}`
            return ``
        }
        const iconStyle = { border: "none", color: "darkgray", alignSelf: "center", fontSize: "14px" }
        return (
            <>
                <Row style={rowStyles}>
                    {
                        sysPanel ?
                            <FontAwesomeIcon icon={faUserFriends} style={iconStyle} /> :
                            <FontAwesomeIcon icon={faFileAlt} style={iconStyle} />
                    }
                    &nbsp;
                    &nbsp;
                    <p style={{ color: "darkslategray", marginBottom: 0 }}>
                        {props.actualRow.name}
                        <span style={{ color: "gray", marginBottom: 0, fontSize: "12px", }}> - {props.actualRow.code}</span>
                    </p>
                    &nbsp;
                    <Badge variant="secondary" pill
                        style={{ alignSelf: 'center', backgroundColor: "#565F71" }}>
                        {props.actualRow.totalGenes}
                        &nbsp;
                        Genes
                    </Badge>{' '}
                </Row>
                <Row style={rowStyles}>
                    <Col style={{ padding: 0, maxWidth: "fit-content" }}>
                        {/* <BadgeTypeSubtype type={props.actualRow.type}
                            subtype={props.actualRow.subtype.toLowerCase()}
                        /> */}
                        <span style={{ color: "gray", marginBottom: 0, fontSize: "13px" }}>
                            {getDescription()}
                        </span>
                    </Col>
                </Row>
            </>
        )
    }
    const dateSplited = props.actualRow.simpleDateFormatCreated.split(" ")
    const getDateColumn = () => {
        return (
            <>
                <Row style={{ margin: 0, padding: 0 }}>
                    <FontAwesomeIcon icon={faCalendarDay} style={{ border: "none", marginRight: "5px", color: "darkgray" }} />
                    <p style={{ color: "gray", marginBottom: 0, fontSize: "13px" }}>
                        {dateSplited && dateSplited.length > 0 ? dateSplited[0] : "-"}
                    </p>
                </Row>
                <Row style={{ margin: 0, padding: 0, paddingLeft: "18px" }}>
                    <p style={{ color: "gray", marginBottom: 0, fontSize: "13px" }}>
                        {dateSplited && dateSplited.length > 1 ? dateSplited[1] : "-"}
                    </p>
                </Row>
            </>
        )
    }
    const getDropdown = () => {
        const handleDelete = () => {
            GenesetS.delete_panels([{ id: props.actualRow.key, regionKey: props.actualRow.regionKey }]);
            props.handleReload();
        }
        return (
            <Dropdown alignRight={true} >
                <div style={{ display: "flex", placeContent: "center" }}>
                    <Tooltip label={translate('genesPanel.genesPanelActions')}>
                        <Dropdown.Toggle variant="outline-light" id="dropdown-basic"
                            style={{
                                padding: "6px ​20px",
                                boxShadow: "none",
                                border: "none",
                                borderRadius: "50px",
                                fontSize: "15px",
                                marginRight: "5px"
                            }}>
                            <FontAwesomeIcon icon={faCog}
                                style={{ border: "none", color: "gray" }}
                                data-tip="Add case"
                            />
                        </Dropdown.Toggle>
                    </Tooltip>
                </div>
                <Dropdown.Menu style={{ minWidth: "170px", borderRadius: "0", overflow: "visible" }} >
                    {!sysPanel &&
                        <>
                            <Dropdown.Item style={{ borderRadius: "0" }} variant="secondary"
                                onClick={() => {
                                    props.handleShowProcessGene(props.actualRow.key, props.actualRow.regionKey)
                                }}
                            >
                                <Row>
                                    <Col sm={3} style={{ padding: "0 0 0 3px" }}>
                                        <FontAwesomeIcon icon={faEdit} style={{ border: "none", color: "gray" }} />
                                    </Col>
                                    <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                        {translate('home.table.edit')}
                                    </Col>
                                </Row>
                            </Dropdown.Item>
                            <Dropdown.Item style={{ minWidth: "100px", borderRadius: "0" }} variant="secondary" onClick={handleDelete}>
                                <Row>
                                    <Col sm={3} style={{ padding: "0 0 0 3px" }}>
                                        <FontAwesomeIcon icon={faTrash} style={{ border: "none", color: "gray" }} />
                                    </Col>
                                    <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                        {translate('home.delete')}
                                    </Col>
                                </Row>
                            </Dropdown.Item>
                        </>
                    }
                    <Dropdown.Item style={{ minWidth: "100px", borderRadius: "0" }}
                        variant="secondary"
                        onClick={() => {
                            createTxtFile(props.actualRow.name, props.actualRow.geneNames.join(", "))
                        }}>
                        <Row>
                            <Col sm={3} style={{ padding: "0 0 0 3px" }}>
                                <FontAwesomeIcon icon={faDownload} style={{ border: "none", color: "gray" }} />
                            </Col>
                            <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                {translate('common.download')}
                            </Col>
                        </Row>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown >)
    }
    const columnStyles = { border: "none", verticalAlign: "middle", cursor: "pointer" }
    const rowStyles = { margin: 0 }
    const handleRowClick = () => {
        props.history.push(`/genes/${props.actualRow.regionKey}/${props.actualRow.key}`);
    }

    return (
        <tr style={{ borderBottom: "1px solid lightgray" }}>
            <td style={{ border: "none", width: "35px", verticalAlign: "middle", textAlign: "center" }}>
                <Form.Check type="checkbox"
                    checked={!sysPanel && (props?.genesSelecteds?.find((y: any) => y === props.actualRow.key) ?? false)}
                    disabled={sysPanel}
                    onChange={() => { props.handleGenesSelecteds(props.actualRow.key) }}
                />

            </td>
            <td style={columnStyles} onClick={handleRowClick}>
                {getFirstColumn()}
            </td>
            <td style={{ ...columnStyles, width: "120px" }} onClick={handleRowClick}>
                {getDateColumn()}
            </td>
            <td style={{ border: "none", verticalAlign: "middle", width: "60px" }}>
                {getDropdown()}
            </td>
        </tr>
    )
}

