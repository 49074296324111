import React from 'react';
import { useContext, useEffect, useState } from "react";
import Paginator from "../Common/Paginator/Paginator";
import HomeFilter, { InputFilterI } from "../Layout/Home/HomeFilter";
import HomeLayout from "../Layout/Home/HomeLayout";
import { LangContext } from "../../Contexts/Lang";
import ReportedVariantServices from "../../Services/ReportedVariantService";
import { reportedVariantsDesicionOptions, variantAcmgValues } from "../../utils/BioFunctionalData";
import { Card } from "react-bootstrap";
import ReportedVarintsTable from "./ReportedVariantsTable";
import ProcessReportedVariant from "./ProcessReportedVariants/ProcessReportedVariant";
import { ModalWarning } from "../Common/ModalWarning";
import ReportedVariantsContext from "../../Contexts/ReportedVariants/ReportedVariantsContext";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import HomeToolkit from "../Layout/Home/HomeToolkit";
import ReportService from "../../Services/ReportService";

interface ReportedVariantsI {
    history: any,
}
export default function ReportedVariants(props: ReportedVariantsI) {
    const { variants, filters, selecteds, dispatch: {
        getVariants, setFilters, cleanFilters, handleSelecteds, handleSelectAll } } = useContext(ReportedVariantsContext);
    const { dispatch: { translate } } = useContext(LangContext);
    const ReportS = new ReportService();

    let ReportedVariantS = new ReportedVariantServices();

    const [showProcessVariant, setshowProcessVariant] = useState<{ show: boolean, variantId: string | undefined }>({ show: false, variantId: undefined })
    const handleCloseProcessVariant = () => setshowProcessVariant({ show: false, variantId: undefined });
    const handleShowProcessVariant = (variantId: string) => setshowProcessVariant({ show: true, variantId: variantId });

    const [showDeleteVariant, setshowDeleteVariant] = useState<{ show: boolean, variantId: string | undefined }>({ show: false, variantId: undefined })
    const handleCloseDeleteVariant = () => setshowDeleteVariant({ show: false, variantId: undefined });
    const handleShowDeleteVariant = (variantId: string) => setshowDeleteVariant({ show: true, variantId: variantId });

    const paginator = Paginator({
        reloadCall: () => { },
        onChangePage: (i: any) => {
            handleGetVariants(i + 1);
            paginator.setActualPage(i)
        },
        onItemsPerPageChange: (i: any) => {
            handleGetVariants(1, i);
            paginator.pageSize_set(i)
        }
    })

    function handleGetVariants(page?: number, pageSize?: number, filtersClean?: boolean, then?: any) {
        getVariants(
            filtersClean ? undefined : filters,
            page ?? paginator.actualPage + 1,
            pageSize ?? paginator.itemsPerPage)
            .then(() => {
                if (page !== undefined) paginator.setActualPage(page - 1)
                handleSelecteds("")
                if (then) then()
            })
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted && !variants.loaded && !variants.loading) handleGetVariants()
        if (isMounted && variants.loaded) {
            paginator.pageSize_set(variants.data?.rowsCount ?? 0)
            paginator.setActualPage(variants.data?.pageNumber - 1?? 0)
        }
        if (isMounted && variants.refresh) handleGetVariants(1, paginator.itemsPerPage, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variants]);

    function handleSortByFilter(value: string) {
        if (value !== filters.sortColumn) {
            setFilters("sortColumn", value)
            handleGetVariants(1);
        }
    }

    const inputFilterData: InputFilterI[] = [
        {
            type: "text",
            stateName: "geneName",
            placeholder: `${translate("common.geneName")}...`,
        },
        {
            type: "text",
            stateName: "dbsnpId",
            placeholder: "dbSnp Id...",
        },
        {
            type: "text",
            stateName: "study",
            placeholder: translate("common.study"),
        },
        {
            type: "select",
            stateName: "decision",
            options: reportedVariantsDesicionOptions(translate),
        },
        {
            type: "select",
            stateName: "classification",
            options: variantAcmgValues,
            placeholder: translate("common.classification")
        },
    ]

    return (
        <>
            {showDeleteVariant.show && < ModalWarning labelTitle={translate('common.confirm')}
                label={translate("variant.confirmDeleteSelectedReportedVariant")}
                labelButton={translate('home.delete')}
                showState={showDeleteVariant.show}
                handleClose={handleCloseDeleteVariant}
                onClick={() => {
                    if (showDeleteVariant?.variantId)
                        ReportedVariantS.delete([showDeleteVariant?.variantId])
                    handleGetVariants(1)
                    handleCloseDeleteVariant()
                }}
            />
            }
            {showProcessVariant.show && <ProcessReportedVariant history={props.history}
                editVariantId={showProcessVariant.variantId}
                show={showProcessVariant.show}
                handleClose={handleCloseProcessVariant}
                handleReload={() => handleGetVariants(1)}
            />}

            <HomeLayout history={props.history}
                filterTitle={translate('home.reportedVariants')}
                rowsAmount={{
                    loading: !variants.loaded || variants.loading,
                    amount: variants?.data?.rowsCount ?? 0,
                }}
                Filters={
                    <div style={{ width: "100%" }}>
                        <HomeFilter filters={filters}
                            setFilter={setFilters}
                            handleCleanFilter={() => {
                                cleanFilters();
                                handleGetVariants(1, paginator.itemsPerPage, true)
                            }}
                            callFilter={() => {
                                handleGetVariants(1)
                            }}
                            inputFilters={inputFilterData}
                        />
                    </div>
                }
                FiltersEmpty={
                    <div style={{ width: "100%" }}>
                        <HomeFilter inputFilters={inputFilterData} filters={filters} />
                    </div>
                }
                Toolkit={
                    <HomeToolkit rowData={(variants.data?.reports ?? [])}
                        rowDataSelected={selecteds}
                        handleSelectAll={handleSelectAll}
                        multipleActionsOptions={[
                            {
                                icon: faTrash,
                                label: translate('home.delete'),
                                handleClick: () => {
                                    if (variants.data?.reports.length > 0) {
                                        ReportedVariantS.delete(selecteds.map((x: number) => `${x}`)).then(() => {
                                            handleSelecteds("")
                                            handleGetVariants(1);
                                        })
                                    }
                                },
                                modalWarning: {
                                    label: selecteds.length > 1 ?
                                        translate("genesPanel.confirmDeleteAllSelectedGenesPanels") :
                                        translate("genesPanel.confirmDeleteSelectedGenPanel"),
                                    buttonLabel: translate('home.delete')
                                }
                            },
                        ]}
                        sortBy={{
                            value: filters.sortColumn ?? "",
                            options: ["default", { label: translate('common.classification'), value: "classification" }],
                            handleSortBy: handleSortByFilter
                        }}
                        exportActions={[
                            {
                                label: `${translate("common.allVariantsInResult")} (xls)`,
                                href: ReportS.get_downloadVariantInfoResultXls_link()
                            }, {
                                label: `${translate("common.selectedVariants")} (xls)`,
                                disabled: selecteds.length === 0,
                                href: ReportS.get_downloadSelectedVariantInfoXls_link(selecteds.map((x: any) => `${x}`)),
                            }
                        ]}
                    />
                }
                Table={
                    <Card style={{ borderRadius: "0px" }}>
                        <Card.Body style={{ padding: "0px" }}>
                            {(!variants.loading && (variants.data?.reports ?? []).length === 0) ?
                                <div>
                                    <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                        {translate('home.noCasesFound')}
                                    </p>
                                </div> :
                                <ReportedVarintsTable history={props.history}
                                    variantsSelecteds={selecteds}
                                    handleVariantsSelecteds={handleSelecteds}
                                    handleReload={() => { handleGetVariants(1) }}
                                    handleDelete={handleShowDeleteVariant}
                                    handleEdit={handleShowProcessVariant}
                                />
                            }
                        </Card.Body>
                        {(variants.data?.reports ?? []).length > 0 ?
                            <Card.Footer>
                                {paginator.component}
                            </Card.Footer>
                            : <></>}
                    </Card>

                }
            />
        </>

    )
}

