import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { PlacementT, Popover } from './Popover';
type WarningT = "danger" | "warning" | "advice" | "secondary";

interface WarningTrianglePopoverI {
    type: WarningT,
    bodyContent: string | JSX.Element,
    title?: string,
    label?: string,
    fontSize?: string,
    placement?: PlacementT
}
export default function WarningTrianglePopover(props: WarningTrianglePopoverI) {
    let color = "#F28B00";
    switch (props.type) {
        case "danger": color = "#E10000"; break;
        case "advice": color = "#07A6F1"; break;
        case "secondary": color = "#AAA"
    }
    return (
        <Popover title={props.title}
            content={props.bodyContent}
            trigger={"hover"}
            placement={props.placement}
            style={{ fontSize: "10px" }}>
            <label style={{ marginBottom: 0 }}>
                {props.label &&
                    <span style={{ fontWeight: "bold", color: color, fontSize: "10px" }}>
                        {props.label}
                    </span>
                }
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: "2px", border: "none", fontSize: props.fontSize ?? "12px", color: color }} />
            </label>
        </Popover>
    )

}
export type { WarningT }