import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Layout } from '../../Components/Layout/Layout';
import DetailVariants from '../../Components/ReportedVariants/Detail/DetailReportedVariants';
import ReportedVariants from '../../Components/ReportedVariants/ReportedVariants';
import ReportedVariantsContextState from '../../Contexts/ReportedVariants/ReportedVariantsState';
import UserContext from '../../Contexts/User/UserContext';
import UserService from '../../Services/UserServices';

interface ReportedVariantsPageI {
    history: any,
    match: any,
}
export default function ReportedVariantsPage(props: ReportedVariantsPageI) {
    const { user } = useContext(UserContext);
    const [body, setbody] = useState<any>(<></>)
    const UserS = new UserService();
    const cuRoll: any = user.data?.rolestoString ?? ""
    const usRoll: any = UserS.user?.rolestoString ?? ""

    useEffect(() => {
        if ((usRoll !== "ROLE_ADMIN" && usRoll !== "ROLE_OPERATOR" && usRoll !== "ROLE_REPORTER" && usRoll !== "ROLE_GUEST") ||
            (cuRoll !== "" && (cuRoll !== UserS.user?.rolestoString ?? "-")))
            setbody(<Redirect to='/' />)
        else {

            if (props.match?.params?.variantId)
                setBodyDetails(props.match?.params?.variantId)
            else
                setBodyReportedVariants()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, user])

    function setBodyDetails(variantId: string) {
        setbody(
            <DetailVariants history={props.history}
                variantId={variantId}
            />
        )
    }
    function setBodyReportedVariants() {
        setbody(
            <ReportedVariants history={props.history} />
        )
    }

    return (
        <ReportedVariantsContextState>
            <Layout history={props.history}>
                {body}
            </Layout>
        </ReportedVariantsContextState>
    )
}
