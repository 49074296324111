import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import Paginator from '../../Common/Paginator/Paginator';
import { LangContext } from '../../../Contexts/Lang';
import DomainsContext, { FiltersCasesDomainReqI, FiltersUserDomainReqI } from '../../../Contexts/Domains/DomainsContext';
import DetailLayout from '../../Layout/Detail/DetailLayout';
import DetailDomainHeader from './DetailDomainHeader';
import Loader from '../../Common/Loader/Loader';
import DetailDomainCases from './DetailDomainCases';
import CircleLabel from '../../Common/CircleLabel';
import DetailDomainUsers from './DetailDomainUsers';
import ThemeContext from '../../../Contexts/Theme/ThemeContext';

type DomainsTabsT = "study" | "user"
interface DetailDomainI {
    history: any,
    domainId: number
}
export default function DetailDomain(props: DetailDomainI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const { domain, dispatch: { getDomain, handleSelectedsCases, handleSelectedsUsers, refreshDomains } } = useContext(DomainsContext);
    const paginator = Paginator({ reloadCall: () => { } })
    const [tab, settab] = useState<DomainsTabsT>("study")

    // (domainId: string, pageNumber?: number, pageSize?: number, tab?: "study" | "user", filtersDomain?: FiltersCasesDomainReqI, filtersUsers?: FiltersUserDomainReqI)
    function handleGetDomain(
        page?: number, pageSize?: number, tabSelected?: "study" | "user", filtersDomain?: FiltersCasesDomainReqI, filtersUsers?: FiltersUserDomainReqI, then?: any
    ): void {
        getDomain(
            `${props.domainId}`,
            page ?? paginator.actualPage + 1,
            pageSize ?? paginator.itemsPerPage,
            tabSelected,
            filtersDomain,
            filtersUsers
        ).then(() => {
            if (page !== undefined) paginator.setActualPage(page - 1)
            if (tabSelected) settab(tabSelected)
            handleSelectedsCases("")
            handleSelectedsUsers("")
            if (then) then()
        })
    }


    useEffect(() => {
        if ((!domain.loaded && !domain.loading) ||
            (domain.loaded && props.domainId !== domain.data?.domain?.id)) {
            handleGetDomain(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        handleGetDomain(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])
    // useEffect(() => { if (reload) setreload(false) }, [reload])

    const getTabTitle = (title: string, countData: string) => {
        return (
            <>
                <span style={{ fontSize: "14px" }}>
                    {title}&nbsp;
                </span>
                {!domain.loaded || domain.loading ?
                    < Spinner animation="grow" role="status" style={{ height: "22px", width: "22px", color: theme.grayHeader }} /> :
                    <CircleLabel label={countData}
                        size={2}
                        backColor={theme.grayHeader}
                    />
                }
            </>
        )
    }
    const filterTitleTabs = (
        <Tabs defaultActiveKey={tab} id="uncontrolled-tab-example" style={{ border: "none" }}
            onSelect={(x: any) => settab(x as DomainsTabsT)}>
            <Tab eventKey="study"
                style={{ border: "none", fontWeight: "bold", fontSize: "14px" }}
                title={getTabTitle(translate('home.cases'), `${domain?.data?.cases?.rowsCount ?? "-"}`)}
            />
            <Tab eventKey="user"
                title={getTabTitle(translate('common.users'), `${domain?.data?.users?.rowsCount ?? "-"}`)}
            />
        </Tabs>
    )

    return (
        <DetailLayout DLType="domainDetail"
            DetailHeader={
                domain.loaded ?
                    <DetailDomainHeader history={props.history}
                        domain={{
                            id: domain.data?.domain?.id ?? "",//"id",
                            name: domain.data?.domain?.name ?? "",
                            description: domain.data?.domain?.description ?? "",
                            enable: domain.data?.domain?.enabled ?? false,
                        }}
                        region={"allala"}
                        handleReload={() => {
                            handleGetDomain();
                            refreshDomains();
                        }}
                    /> :
                    <div style={{ marginTop: "25px", marginLeft: "15px" }}>
                        <Spinner animation="grow" variant="secondary" size="sm" style={{ marginRight: "5px", color: "darkgray !important" }} />
                    </div >
            }>
            {domain.loading ?
                <div style={{ marginTop: "-52px", marginLeft: "15px" }}>
                    < Loader onContainer={true} type={'DNA'} />
                </div > :
                <Row
                    id = "aaaaaaaaaaa" 
                    style={{ width: "100%", margin: 0 }}>
                    {
                        tab === "study" ?
                            <DetailDomainCases history={props.history}
                                domainId={props.domainId}
                                filterTitleTabs={filterTitleTabs}
                                handleGetDomain={handleGetDomain}
                            />
                            :
                            <DetailDomainUsers history={props.history}
                                domainId={props.domainId}
                                filterTitleTabs={filterTitleTabs}
                                handleGetDomain={handleGetDomain}
                            />
                    }
                </Row>
            }
        </DetailLayout >

    )
}
