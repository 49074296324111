import { createContext } from "react";
import { UserI, AsyncDataI, AsyncPagesI, DomainI } from "../interfaces";
import { } from "../actionsTypes";

interface AsyncUserI extends AsyncDataI {
    data: UserI,
}
interface AsyncUserUndefI extends AsyncDataI {
    data: UserI | undefined,
}
interface FilterUsersI {
    username: string,
    fullname: string,
    sortColumn: string// as sortColumnType
}
interface UsersDataI {
    pageNumber: number,
    pageSize: number,
    results: UserI[],
    rowsCount: number
}
interface ContextUserStatesI {
    user: AsyncUserI,
    users: AsyncDataI & {
        data: null | AsyncPagesI & {
            results: AsyncPagesI & { results: UserI[] },
            domain: DomainI
            domainId: number
        }
    },
    filters: FilterUsersI,
    selecteds: string[],

}
interface ContextPropsUserI extends ContextUserStatesI {
    dispatch: {
        login: (username: string, password: string) => Promise<void>;
        logout: () => Promise<void>;
        getUser: () => void;
        getUsers: (filters?: FilterUsersI, pageNumber?: number, pageSize?: number) => Promise<void>;
        refreshUsers: () => void;
        updateLoggedUser: (userName: string, fullName: string, email: string) => Promise<void>;
        setFilters: (key: string, value: string) => boolean;
        cleanFilters: () => void;
        handleSelecteds: (id: string) => void;
        handleSelectAll: () => void;
    }
}

const UserContext = createContext({} as ContextPropsUserI);

export default UserContext;
export type { AsyncUserUndefI, FilterUsersI, ContextUserStatesI, UsersDataI }