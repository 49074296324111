import React from 'react';
import { useContext, useEffect, useState } from "react";
import { Badge, Button, Card, Modal, Table } from 'react-bootstrap';
import VariantQueryServices from '../../../Services/QueryVariantService';
import { LangContext } from "../../../Contexts/Lang";
import { Tooltip } from "../../Common/Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import QuerySheetServices from "../../../Services/QuerySheetService";
import FormInput from "../../Common/Form/FormInput";
import ThemeContext from "../../../Contexts/Theme/ThemeContext";

interface ModalPredeterminedQueriesI {
    show: boolean,
    handleClose(): void,
    caseId: number,
    history: any
}
export default function ModalPredeterminedQueries(props: ModalPredeterminedQueriesI) {
    const VQService = new VariantQueryServices();
    const QSService = new QuerySheetServices();
    const { theme } = useContext(ThemeContext);
    const [queries, setqueries] = useState<any[]>([])
    const [study, setstudy] = useState<any>(undefined)
    const [editQuerie, seteditQuerie] = useState<any>(undefined)
    const [deleteQuerie, setdeleteQuerie] = useState<any>(undefined)
    const [reload, setreload] = useState<boolean>(false)
    const handleReload = () => setreload(true)
    const { dispatch: { translate } } = useContext(LangContext);
    useEffect(() => {
        let isMounted = true;
        VQService.read_filters(props.caseId)
            .then((res: any) => {
                // console.log("res")
                // console.log(res)
                if (isMounted && res.status === 200) {
                    setqueries(res.data.savedResults)
                    setstudy(res.data.study)
                }
            })
            .catch((e: any) => {

            })
        if (reload) setreload(false)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, reload])

    function getRowTable() {
        function getInputEditQuerie(field: string): JSX.Element {
            return (
                <FormInput type={"text"}
                    formLabelStyle={{ marginBottom: "3px" }}
                    formControlStyle={{ height: "calc(1.5em + .75rem + -2px)", fontSize: "10px" }}       // onKeyDown={(e: any) => handleEnter(e)}
                    value={editQuerie[field]}
                    onChange={(e: any) => {
                        var newState = editQuerie;
                        newState[field] = e.target.value;
                        seteditQuerie({ ...newState })
                    }} />
            )
        }
        if (queries && Array.isArray(queries))
            return queries.map((x: any, i: number) => {
                const editThisRow = x.id === editQuerie?.id;
                return (
                    <tr key={i} onClick={() => {
                        if (!editThisRow)
                            props.history.push(`/cases/query/caseId/${props.caseId}/${x.filters}&studyId=${study?.id ?? 0}`);
                    }}>
                        <td style={{ fontSize: "12px", fontWeight: "bold", color: theme.grayHeader, verticalAlign: "middle" }}>
                            {
                                editThisRow ? getInputEditQuerie("title") : x.title
                            }
                        </td>
                        <td style={{ fontSize: "10px", verticalAlign: "middle" }}>
                            {
                                editThisRow ?
                                    getInputEditQuerie("description") :
                                    x.description && x.description.trim().length > 0 ?
                                        x.description :
                                        " - "
                            }
                        </td>
                        <td style={{ minWidth: "70px" }}>
                            <span style={{ float: 'right' }}>
                                {
                                    !editThisRow &&
                                    <Tooltip label={translate('common.edit')}>
                                        <Button variant="outline-light"
                                            style={{ margin: "0 2px", height: "fit-content", border: "none", padding: "3px 7px", borderRadius: "50px" }}
                                            onClick={(e: any) => { e.stopPropagation(); seteditQuerie(x) }}
                                        >
                                            <FontAwesomeIcon icon={faEdit} style={{ border: "none", color: theme.edit, fontSize: "10px" }} />
                                        </Button>
                                    </Tooltip>
                                }
                                <Tooltip label={translate('home.delete')}>
                                    <Button variant="outline-light"
                                        style={{ margin: "0 2px", height: "fit-content", border: "none", padding: "3px 7px", borderRadius: "50px" }}
                                        onClick={(e: any) => { e.stopPropagation(); setdeleteQuerie(x) }}>
                                        <FontAwesomeIcon icon={faTrash} style={{ border: "none", color: "gray", fontSize: "10px" }} />
                                    </Button>
                                </Tooltip>
                            </span>
                        </td>
                    </tr>
                )
            })
        return []
    }
    let body = <>
        <Badge pill variant="secondary" style={{ marginBottom: "10px" }}>
            {`${(queries ?? []).length} ${translate("casesDetail.savedFilters")}`}
        </Badge>
        <Card style={{ borderTop: "none" }}>
            <Table responsive="md" className="selectTable" style={{ marginBottom: 0 }}>
                <tbody>
                    {getRowTable()}
                </tbody>
            </Table>
        </Card>
    </>
    if (deleteQuerie) {
        body = (<div>
            {`${translate("casesDetail.areYouSureYouWantToDeleteTheFilter")}`}
            <strong>{deleteQuerie.title}</strong>
        </div>)
    }
    return (
        <Modal id="ModalPredeterminedQueries" show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: theme.grayHeader }}>
                <Modal.Title style={{ margin: "0px 10px", color: theme.backgroundPrimary }} >
                    <FontAwesomeIcon icon={faSearch} style={{ color: theme.backgroundPrimary, marginRight: "10px", fontSize: "18px" }} />
                    {`- ${translate('casesDetail.predeterminedQueries')}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary"
                    onClick={() => {
                        if (!deleteQuerie && !editQuerie) props.handleClose()
                        else {
                            if (deleteQuerie) {
                                setdeleteQuerie(undefined)
                            } else {
                                seteditQuerie(undefined)
                            }
                        }
                    }}>
                    {
                        !deleteQuerie && !editQuerie ?
                            translate(`common.close`) :
                            translate(`common.cancel`)
                    }
                </Button>
                {
                    (deleteQuerie || editQuerie) &&
                    <Button variant="outline-primary"
                        onClick={() => {
                            if (deleteQuerie)
                                QSService.delete_query(deleteQuerie.id)
                                    .then((res: any) => {
                                        setdeleteQuerie(undefined)
                                        handleReload()
                                    })
                            if (editQuerie) {
                                QSService.edit_results(
                                    props.caseId,
                                    editQuerie.id,
                                    editQuerie.title,
                                    editQuerie.description
                                )
                                    .then((res: any) => {
                                        seteditQuerie(undefined)
                                        handleReload()
                                    })
                            }
                        }}
                    >
                        {translate(`common.save`)}
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}
