import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Popover } from './Popover';
import { TriggersT } from '../IGV/types';
interface MoreInfoPopoverButtonI {
    children: JSX.Element,
    trigger: TriggersT,
    buttonStyles?: any,
}
export default function MoreInfoPopoverButton(props: MoreInfoPopoverButtonI) {
    return (
        <Popover content={props.children}
            trigger={props.trigger} style={{ fontSize: "11px" }}>
            <Button variant="outline-primary" style={{ padding: "1px 7px 0px 1px", borderRadius: "50px", ...(props.buttonStyles ? props.buttonStyles : {}) }} onClick={(e: any) => e.stopPropagation()}>
                <span style={{}}>
                    <FontAwesomeIcon icon={faPlus}
                        style={{ fontSize: "10px", margin: "1px 5px" }} />
                    Info.
                </span>
            </Button>
        </Popover>
    )
}
