import React from 'react';
import { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { LangContext } from "../../../../Contexts/Lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../../../Common/Tooltip/Tooltip";
import "./styles.css"
import { getValueByKeyInLibraryPrepKit } from 'src/utils/BioFunctionalData';

interface SampleTableI {
    sample: any,
}
export default function SampleTable(props: SampleTableI) {
    const { dispatch: { translate } } = useContext(LangContext);
    let hetHomRatio = " - "
    if (props.sample?.stats?.Heterozygous && props.sample?.stats?.Homozygous)
        hetHomRatio = parseFloat(`${props.sample?.stats?.Heterozygous / props.sample?.stats?.Homozygous}`).toFixed(2)

    const library =
        props.sample?.libraryPrepKit ?
            props.sample?.libraryPrepKit.trim().toUpperCase() === "CUSTOM" ?
                (props.sample?.customLibraryPrepKit??"").replaceAll("_", " ") :
                getValueByKeyInLibraryPrepKit(`${props.sample?.libraryPrepKit.toUpperCase()}`) :
            " - "
    return (
        <Table responsive className="statisticTable" >
            <tbody>
                <tr>
                    <td>
                        {translate('common.library')}
                    </td>
                    <td>
                        {library}
                    </td>
                    <td>

                    </td>
                </tr>
                <tr>
                    <td>
                        {translate('casesDetail.reference')}
                    </td>
                    <td>
                        {props.sample?.genomeReference ? props.sample?.genomeReference : " - "}
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td>
                        #{translate('common.variants')}
                    </td>
                    <td>
                        {props.sample?.stats?.TotalVariants ? props.sample?.stats?.TotalVariants : " - "}
                    </td>
                    <td >
                        <Tooltip label={translate('casesDetail.variantsInfo')}>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "15px" }} />
                        </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td>
                        #{translate('casesDetail.knownVariants')}
                    </td>
                    <td>
                        {props.sample?.stats?.dbSNPVariants ? props.sample?.stats?.dbSNPVariants : " - "}
                    </td>
                    <td >
                        <Tooltip label={translate('casesDetail.knownVariantsInfo')}>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "15px" }} />
                        </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td>
                        {translate('casesDetail.dbSNPRatio')}
                    </td>
                    <td>
                        {props.sample?.stats?.dbSNPVariants && props.sample?.stats?.TotalVariants ? (props.sample?.stats?.dbSNPVariants / props.sample?.stats?.TotalVariants).toFixed(3) : " - "}
                    </td>
                    <td >
                        <Tooltip label={translate('casesDetail.dbSNPRatioInfo')}>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "15px" }} />
                        </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td>
                        {translate('casesDetail.hetHomRatio')}
                    </td>
                    <td>
                        {props.sample?.stats?.Heterozygous && props.sample?.stats?.Homozygous ? hetHomRatio : " - "}
                    </td>
                    <td >
                        <Tooltip label={translate('casesDetail.hetHomRatioInfo')}>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "15px" }} />
                        </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td>
                        {translate('casesDetail.tiTvRatio')}
                    </td>
                    <td>
                        {props.sample?.stats?.Transitions && props.sample?.stats?.Transversions ?
                            parseFloat(`${props.sample?.stats?.Transitions / props.sample?.stats?.Transversions}`).toFixed(2)
                            : " - "}
                    </td>
                    <td >
                        <Tooltip label={translate('casesDetail.tiTvRatioInfo')}>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "15px" }} />
                        </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td>
                        {translate('casesDetail.missenseSilent')}
                    </td>
                    <td>
                        {props.sample?.stats?.SilentVariants ? props.sample?.stats?.SilentVariants : " - "}
                    </td>
                    <td >
                        <Tooltip label={translate('casesDetail.missenseSilentInfo')}>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "15px" }} />
                        </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td>
                        {translate('casesDetail.coverageRatio')}
                    </td>
                    <td>
                        {props.sample?.stats?.TotalCoverageRatio ? props.sample?.stats?.TotalCoverageRatio : " - "}
                    </td>
                    <td >
                        <Tooltip label={translate('casesDetail.coverageRatioInfo')}>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "15px" }} />
                        </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td>
                        #Genes
                    </td>
                    <td>
                        {props.sample?.stats?.Genes ? props.sample?.stats?.Genes : " - "}
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        #SNP
                    </td>
                    <td>
                        {props.sample?.stats?.SingleSNP ? props.sample?.stats?.SingleSNP : " - "}
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        #INS
                    </td>
                    <td>
                        {props.sample?.stats?.Insertions ? props.sample?.stats?.Insertions : " - "}
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        #DEL
                    </td>
                    <td>
                        {props.sample?.stats?.Deletions ? props.sample?.stats?.Deletions : " - "}

                    </td>
                    <td></td>
                </tr>
            </tbody>
        </Table >
    )
}