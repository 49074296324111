import React from 'react';
import { useContext } from "react";
import { Badge } from "react-bootstrap";
import { LangContext } from "../../../Contexts/Lang";
import { Popover } from "../Popover";

type vartype = "danger" | "warning" | "info" | "secondary"
interface BadgeVariantImpactI {
    impact: "high" | "moderate" | "low" | string,
    label?: string,
    fontSize?: string,
}
export default function BadgeVariantImpact(props: BadgeVariantImpactI) {
    const { dispatch: { translate } } = useContext(LangContext);
    if (!props.impact)
        return <></>;
    const impact = props.impact.trim().toLowerCase();
    const impactType: vartype = impact === "high" ? "danger" : impact === "moderate" ? "warning" : impact === "low" ? "info" : "secondary"
    const getBackgroundColor = (vartype: vartype) => {
        let color = "#999999"
        switch (vartype) {
            case "danger": color = "#FF4E58"; break;
            case "warning": color = "#FFB111"; break;
            case "info": color = "#3CC9FF"; break;
        }
        return color;
    }

    return (
        <Popover title={`${props.impact[0].toUpperCase()}${props.impact.toLowerCase().substring(1, props.impact.length)}`}
            content={translate(`casesQuery.impactInfo.${props.impact.toUpperCase()}`)}
            trigger={"hover"} style={{ fontSize: "10px" }}>
            <Badge variant={impactType}
                style={{
                    fontWeight: "bolder",
                    fontSize: props.fontSize ?? "12px",
                    color: "white",
                    backgroundColor: getBackgroundColor(impactType),
                    margin: "1px 2px",
                }}>
                {props.label ?
                    props.label :
                    `${props.impact[0].toUpperCase()}${props.impact.slice(1)}`
                }
            </Badge>
        </Popover>
    )
}