import React from 'react';
import CasesTable from './CasesTable';
import { useEffect, useState, useContext } from 'react';
import { Card } from 'react-bootstrap';
import Paginator from '../../Common/Paginator/Paginator';
import { LangContext } from '../../../Contexts/Lang';
import ProcessCase from '../ProcessCase/ProcessCase';
import HomeLayout from '../../Layout/Home/HomeLayout';
import HomeFilter, { InputFilterI } from '../../Layout/Home/HomeFilter';
import CasesContext from '../../../Contexts/Cases/CasesContext';
import { faFolderOpen, faArchive, faTrash } from '@fortawesome/free-solid-svg-icons';
import HomeToolkit from '../../Layout/Home/HomeToolkit';
import StudyService from '../../../Services/StudyServices';
import Loader from '../../Common/Loader/Loader';
import { studyStateOptions, studyTypeOptions } from '../../../utils/BioFunctionalData';

interface CasesBodyI {
    history: any
}
export default function CasesBody(props: CasesBodyI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { cases, selecteds, filters, dispatch: {
        getCases, setFilters, cleanFilters, handleSelecteds, handleSelectAll } } = useContext(CasesContext);

    const [processCase, setprocessCase] = useState<{ "open": boolean, "id": number | undefined }>({ open: false, id: undefined })
    const handleCloseProcessCase = () => setprocessCase({ open: false, id: undefined });
    const handleShowProcessCase = (id?: number) => { setprocessCase({ open: true, id: id ? id : undefined }) };

    const paginator = Paginator({
        reloadCall: () => { },
        onChangePage: (i: any) => {
            handleGetCases(i + 1);
            paginator.setActualPage(i)
        },
        onItemsPerPageChange: (i: any) => {
            handleGetCases(1, i);
            paginator.pageSize_set(i)
        }
    })
    const StudyS = new StudyService()

    function handleGetCases(page?: number, pageSize?: number, cleanFilters?: boolean, then?: any): void {
        getCases(
            cleanFilters ? undefined : filters,
            page ?? paginator.actualPage + 1,
            pageSize ?? paginator.itemsPerPage)
            .then(() => {
                if (page !== undefined) paginator.setActualPage(page - 1)
                handleSelecteds("")
                if (then) then()
            })
    }
    useEffect(() => {
        let isMounted = true;
        if (isMounted && !cases.loaded && !cases.loading) handleGetCases()
        if (isMounted && cases.loaded) {
            paginator.pageSize_set(cases.data?.rowsCount ?? 0);
            paginator.setActualPage(cases.data?.pageNumber - 1 ?? 0);
        }
        if (isMounted && cases.refresh) handleGetCases(1, paginator.itemsPerPage, true)
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cases])

    function handleSortByFilter(value: string) {
        if (value !== filters.sortColumn) {
            setFilters("sortColumn", value)
            handleGetCases(1);
        }
    }

    //----------------------------Cases Filters--------------------------------------
    const inputFilterData: InputFilterI[] = [
        {
            type: "text",
            stateName: "nameSearch",
            placeholder: translate('home.filter.studyNameCodeOrBatch'),
        },
        {
            type: "text",
            stateName: "descriptionSearch",
            placeholder: translate('home.filter.description'),
        },
        {
            type: "select",
            stateName: "studyType",
            options: studyTypeOptions(translate),
        },
        {
            type: "select",
            stateName: "studyState",
            options: studyStateOptions(translate),
            placeholder: translate("common.states")
        },
        {
            type: "date",
            stateName: "dateCreatedFromSearch",
            placeholder: translate('home.filter.createdFrom'),
        },
        {
            type: "date",
            stateName: "dateCreatedToSearch",
            placeholder: translate('home.filter.createdUntil'),
        }
    ]
    // console.log("filters")
    // console.log(filters)
    //----------------------------Cases Filters--------------------------------------
    return (
        <>
            <ProcessCase show={processCase.open}
                handleClose={handleCloseProcessCase}
                handleReload={() => { handleGetCases(1) }}
                caseId={processCase.id}
                studyTypeOptions={studyTypeOptions(translate)}
            />
            <HomeLayout history={props.history}
                filterTitle={translate('home.cases')}
                rowsAmount={{
                    loading: !cases.loaded || cases.loading,
                    amount: cases?.data?.rowsCount ?? 0,
                }}
                Filters={
                    <div style={{ width: "100%" }}>
                        <HomeFilter
                            filters={filters}
                            setFilter={setFilters}
                            handleCleanFilter={() => {
                                cleanFilters();
                                handleGetCases(1, paginator.itemsPerPage, true)
                            }}
                            callFilter={() => {
                                handleGetCases(1)
                            }}
                            inputFilters={inputFilterData}
                        />
                    </div>
                }
                FiltersEmpty={
                    <div style={{ width: "100%" }}>
                        <HomeFilter inputFilters={inputFilterData} />
                    </div>
                }
                Toolkit={
                    <HomeToolkit id="cases"
                        rowData={(cases.data?.result?.results ?? [])}
                        rowDataSelected={selecteds}
                        handleSelectAll={handleSelectAll}
                        multipleActionsOptions={[
                            {
                                icon: faArchive,
                                label: translate("common.archive"),
                                handleClick: () => {
                                    StudyS.archive(selecteds.map((x: string) => +x)).then(() => {
                                        // let actualPage = paginator.actualPage + 1;
                                        // if (actualPage !== 1 && selecteds.length === (cases.data?.result?.results ?? []).length) {
                                        //     actualPage = actualPage - 1
                                        // }
                                        handleGetCases(1)
                                    })
                                },
                                modalWarning: {
                                    label: translate('home.tableManager.confirmArchiveAllSelectedStudy'),
                                    buttonLabel: translate('common.archive')
                                }
                            },
                            {
                                icon: faFolderOpen,
                                label: translate("common.activate"),
                                handleClick: () => {
                                    StudyS.active(selecteds.map((x: string) => +x)).then(() => {
                                        // handleSelecteds("")
                                        handleGetCases(1)
                                    })
                                },
                                modalWarning: {
                                    label: translate('home.tableManager.confirmActiveAllSelectedStudy'),
                                    buttonLabel: translate('common.activate')
                                }
                            },
                            "divider",
                            {
                                icon: faTrash,
                                label: translate('home.delete'),
                                handleClick: () => {
                                    StudyS.delete(selecteds.map((x: string) => +x)).then(() => {
                                        // handleSelecteds("")
                                        handleGetCases(1)
                                    })
                                },
                                modalWarning: {
                                    label: translate('home.tableManager.confirmDeleteAllSelectedStudy'),
                                    buttonLabel: translate('home.delete')
                                }
                            },
                        ]}
                        addElement={{
                            tooltipLabel: translate('home.tableManager.addCase'),
                            handleCreate: handleShowProcessCase,
                        }}
                        sortBy={{
                            value: filters.sortColumn,
                            options: "default",
                            handleSortBy: handleSortByFilter
                        }}
                    />
                }
                Table={
                    <Card style={{ borderRadius: "0px" }}>
                        <Card.Body style={{ padding: "0px" }}>
                            {!cases.loaded || cases.loading ?
                                <Loader onContainer={true} type={'DNA'} /> :
                                (cases?.data?.result?.results && cases?.data?.result?.results.length !== 0) ?
                                    <CasesTable cases={cases?.data?.result?.results}
                                        casesSelecteds={selecteds}
                                        handleCasesSelecteds={handleSelecteds}
                                        handleShowProcessCase={handleShowProcessCase}
                                        handleReload={() => { handleGetCases(1) }}
                                        history={props.history} />
                                    :
                                    <div>
                                        <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                            {translate('home.noCasesFound')}
                                        </p>
                                    </div>
                            }
                        </Card.Body>
                        {(cases?.data?.result?.results ?? []).length > 0 ?
                            <Card.Footer>
                                {paginator.component}
                            </Card.Footer>
                            : <></>}
                    </Card>
                }
            />
        </>
    )
}
