import React from 'react';
import { Button, Form, Modal,  Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState, useContext } from "react";
import { LangContext } from '../../../Contexts/Lang';
import ReactSelect from "../../Common/Form/ReactSelect";
import { LabelValueI } from "../../../utils/BioFunctionalData";
import DomainService from "../../../Services/DomainService";
import ThemeContext from "../../../Contexts/Theme/ThemeContext";

interface StudiesTableI {
    id: string,
    name: string,
    selected: boolean
}

interface ModalChangeStudyDomainDataI {
    domainId: string,
    domainOptions: LabelValueI[],
    studies: {
        id: string,
        name: string
    }[]
}
interface ModalChangeStudyDomainI {
    show: boolean,
    handleClose: any,
    handleReload?: any,
    data?: ModalChangeStudyDomainDataI
}
export default function ModalChangeStudyDomain(props: ModalChangeStudyDomainI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const initialStudies: StudiesTableI[] = (props.data?.studies ?? []).map((x: any) => { return { selected: true, ...x } })
    const [studies, setstudies] = useState<StudiesTableI[]>(initialStudies)
    const [domain, setdomain] = useState<LabelValueI>()

    const DomainS = new DomainService()
    console.log("domain")
    console.log(domain)
    useEffect(() => {
        setstudies(initialStudies)
        if (props.data?.domainOptions && props.data?.domainOptions.length > 0) setdomain(props.data?.domainOptions[0] ?? "")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getStudiesTable = () => {
        const getRows = () => {
            return studies.map((x: StudiesTableI, i: number) => {
                return (
                    <tr key={i} style={{}}>
                        <td style={{ width: "80px", textAlign: "center" }}>
                            <Form.Check type="checkbox"
                                checked={studies?.find((y: any) => (y.id === x.id && y.selected)) ? true : false}
                                onChange={() => {
                                    setstudies([
                                        ...studies.map((y: StudiesTableI) => {
                                            if (`${y.id}` === `${x.id}`) y.selected = !y.selected
                                            return y
                                        })
                                    ])
                                }}
                            />
                        </td>
                        <td style={{}}>
                            {x.name}
                        </td>
                    </tr>

                )
            })
        }

        return (
            <Table responsive="md" className="selectTable" style={{ marginBottom: "-1px", overflowY: "hidden", borderBottom: "solid 2px lightgray" }} bordered>
                <thead>
                    <tr style={{ color: theme.grayHeader, backgroundColor: theme.backgroundThird, border: "none" }}>
                        <th style={{ verticalAlign: "top", textAlign: "center", width: "80px" }}>
                            Selected
                        </th>
                        <th style={{ verticalAlign: "top" }}>
                            Name
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {getRows()}
                </tbody>
            </Table>)
    }

    const handleChangeDomain = () => {
        console.log("handleChangeDomain")
        // moveDomain(domainId: string, newDomain: string, studies: string[])
        if (props.data && domain?.value && studies.length > 0)
            DomainS.move(props.data.domainId, domain.value, studies.map((x: StudiesTableI) => `${x.id}`))
                .then(() => {
                    props.handleReload()
                    props.handleClose()
                })
                .catch(() => {
                    props.handleReload()
                    props.handleClose()
                })
    }

    return (
        <Modal show={props.data && props.show}
            onHide={props.handleClose}
        // dialogClassName="my-modal"
        >
            <Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: theme.grayHeader }}>
                <Modal.Title style={{ margin: "0px 10px", color: theme.backgroundPrimary }} >
                    <FontAwesomeIcon icon={faExchangeAlt} style={{
                        border: "none", marginRight: "7px", fontSize: "27px", verticalAlign: "text-bottom"
                    }} />
                    &nbsp;
                    <strong>{translate("domain.changeStudiesDomain")}</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{
                // padding: "12er0"
            }}>
                <label style={{ color: "lightgray" }}>
                    {translate("domain.moveSelectedStudiesToDomain")}
                </label><br />
                <label style={{ fontSize: "16px", color: theme.grayHeader, fontWeight: "bold", margin: "5px 5px" }}>
                    Studies ({(props.data?.studies ?? []).length})
                </label>
                {getStudiesTable()}
                <br />
                <label style={{ fontSize: "16px", color: theme.grayHeader, fontWeight: "bold", margin: "5px 5px" }}>
                    Domain
                </label>
                <ReactSelect options={props.data?.domainOptions ?? []}
                    onChange={(e: any) => setdomain({ label: e.value, value: e.value })}
                    value={domain}
                />
                <br />

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    {translate(`common.close`)}
                </Button>
                <Button variant="primary" onClick={handleChangeDomain}>
                    {translate(`common.save`)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export type { ModalChangeStudyDomainDataI }