import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { LangContext } from '../../Contexts/Lang';
import './Login.css';
import UserContext from '../../Contexts/User/UserContext';
import HealthCheckService from '../../Services/HealthcheckService';
import { encryptLocalStorage } from '../../utils';
import Footer from 'src/Components/Layout/Footer';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { Caching } from 'src/utils/helpers';
// import CnvViewer from 'src/Components/Common/GoslingLang/CnvViewer';
interface LoginPageI {
    history: any,
    match: any
}
export default function LoginPage(props: LoginPageI) {
    const { theme } = useContext(ThemeContext);
    const { user, dispatch: { login } } = useContext(UserContext);
    const sys = props.match?.params?.sys;
    const loginAsSys = sys === "system" ? true : false
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [userOrPasswordError, setuserOrPasswordError] = useState(false)
    let HCS = new HealthCheckService()
    const { dispatch: { translate } } = useContext(LangContext);
    Caching()
    function handleSubmit(event: any): void {
        setuserOrPasswordError(false)
        // setToManyequestError(false)
        event.preventDefault()
        if (name && name.trim() !== "" && password && password.trim() !== "") {
            login(name, password)
        }
    }
    const redirectTomMaintenance = () => {
        if (!loginAsSys) props.history.push('/maintenance')
    }
    useEffect(() => {
        const backendHealthChecker = () => {
            const TIMEOUT = 3000;
            let didTimeOut = false;
            return new Promise(function (resolve, reject) {
                const timeout = setTimeout(() => {
                    didTimeOut = true;
                    redirectTomMaintenance()
                }, TIMEOUT);

                HCS.getCheck()
                    .then((res: any) => {
                        clearTimeout(timeout);
                        if (!didTimeOut) {
                            const backendChecked = HCS.check(res);
                            if (backendChecked) resolve(res);
                            else redirectTomMaintenance()
                        } else {
                            redirectTomMaintenance()
                        }
                    })
                    .catch((err: any) => {
                        redirectTomMaintenance()
                        reject(err);
                    });
            })
        }
        backendHealthChecker()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (encryptLocalStorage.getItem("_Buser") && user?.loaded && user.data?.id && user.data.fullName) {
            props.history.push('/')
        } else {
            // if (user.error.response.status === 429) setToManyequestError(true)
            if (user.error?.response || (user?.loaded && !user?.data?.id))
                setuserOrPasswordError(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])


    const divStyle = {
        height: "320px",
        width: "350px",
        backgroundColor: "#444B59",
        padding: "40px 75px 60px 40px",
        borderBottom: "ridge rgb(48,53,63)"
    }
    return (
        <div className="App">
            <header className="App-header">
                <div>
                    <Row style={{ width: "80vw" }}>
                        <Col lg={6} style={{ marginTop: "40px" }}>
                            <div style={{ display: "flex" }}>
                                <h1 style={{ fontWeight: "bold", color: "white", fontSize: "3rem" }}>
                                    _B PLATFORM
                                </h1>
                            </div>
                            <p style={{ color: "white", fontSize: "18px", textAlign: "justify" }}>
                                &nbsp;&nbsp;&nbsp;
                                <br />
                                Plataforma colaborativa para el análisis y priorizacion de variantes. Permite el procesamiento y análisis de datos generados por tecnologías de secuenciación masiva de forma rápida y eficiente, facilitando el análisis e interpretación de los mismos.
                            </p>
                        </Col>
                        <Col lg={1}>
                        </Col>
                        <Col lg={5} style={{ display: "flex", justifyContent: "center" }}>
                            <Form onSubmit={handleSubmit}>
                                <div style={divStyle}>
                                    <h2 className="login-title">.</h2>
                                    <Form.Control type="text"
                                        autoComplete="username"
                                        placeholder="Nombre de Usuario"
                                        onChange={(e: any) => setName(e.target.value)}
                                        style={{ borderRadius: 0 }} />
                                    <br />
                                    <Form.Control type="password"
                                        placeholder="Password"
                                        onChange={(e: any) => setPassword(e.target.value)}
                                        autoComplete="current-password"
                                        style={{ borderRadius: 0 }} />
                                    {userOrPasswordError &&
                                        <div style={{ display: "flex", margin: "10px 0", color: "#DC3545", fontSize: 12 }}>
                                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: 5, marginTop: 2, border: "none" }} />
                                            {translate('login.loginError')}
                                            <br />
                                        </div>
                                    }
                                    <br />
                                    <Button variant="danger"
                                        type="submit"
                                        style={{ backgroundColor: "#FF4E58", borderBottom: "ridge rgb(255, 0, 0)" }}
                                    >
                                        {translate('login.login')}
                                    </Button >
                                    <br />
                                </div>
                                <div style={{ width: "350px" }} className="login-footer">
                                    powered by
                                    <a className="logo-sm" href="https://www.bitgenia.com">BITGENIA</a>
                                </div>
                            </Form >
                        </Col>
                    </Row>
                </div >
                <Footer styles={{ position: "absolute", backgroundColor: "rgba(255,255,255,0.1)", color: theme.grayHeader, border: "none" }} />
            </header>
        </div >
    );
}
