import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Accordion, Card, Table } from "react-bootstrap"
import { LinkOMIM } from "../../../Common/Links/Links"
import { useContext } from "react";
import { LangContext } from "../../../../Contexts/Lang";

interface PhenotypesTableI {
    phenotypes: any[],

}
export default function PhenotypesTable(props: PhenotypesTableI) {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <>
            {(props.phenotypes ?? []).length === 0 ? <></> :
                <Card style={{
                    // paddingTop: "4px",
                    borderRadius: "0px",
                    width: "100%",
                    boxShadow: "rgb(200 200 200) 0px 5px 4px",
                    marginBottom: "25px"
                }}>
                    <Card.Body style={{ padding: "0px", width: "100%" }}>
                        <Accordion>
                            <Table responsive="sm" className="myTable" striped bordered >
                                <thead style={{ backgroundColor: "#EEEEEE" }}>
                                    <tr>
                                        <th style={{ paddingLeft: "15px" }}>{translate("common.phenotype")}</th>
                                        <th style={{}}>{translate("common.reference")}</th>
                                        <th style={{}}>{translate("common.inheritance")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <PhenotypesRows phenotypes={props.phenotypes} />
                                </tbody>
                            </Table >
                        </Accordion>

                    </Card.Body>
                </Card>
            }
        </>
    )
}
interface PhenotypesRowsI {
    phenotypes: any[],
}
function PhenotypesRows(props: PhenotypesRowsI) {
    const { dispatch: { translate } } = useContext(LangContext);

    let result: any = <></>
    if (props.phenotypes.length === 0) return result
    result = props.phenotypes
        .map((x: any, i: number) => {
            return (
                <tr key={i}
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                        // handleSampleSelecteds(x.value0)
                    }}>
                    <td style={{ padding: "3px 5px" }}>
                        <Accordion.Toggle as={Card.Header} eventKey={`${i}`} variant="link" style={{ padding: "3px 10px", border: "none", background: "none" }}>
                            {x.title}
                            <FontAwesomeIcon icon={faAngleDown} style={{ color: "gray", float: "right", border: "none", marginRight: "5px" }} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`${i}`}>
                            <Card style={{ padding: 0, border: "none" }}>
                                <Card.Body style={{ padding: "10px 20px" }}>
                                    {(x.phenotypes ?? []).length > 0 &&
                                        <>
                                            <h5 style={{ fontSize: "13px", fontWeight: "bold" }}>
                                                {translate("casesQueryGenes.clinicalsynopsis")}
                                            </h5>
                                            <ul>
                                                {(x.phenotypes ?? []).map((y: any, ii: number) => {
                                                    return (
                                                        <li key={ii}>
                                                            {y.hpo}&nbsp;:&nbsp; {y.termName}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </>
                                    }
                                </Card.Body>
                            </Card>
                        </Accordion.Collapse>
                    </td>
                    <td style={{ padding: "3px 5px" }}>
                        {!x.source ? " - " : <>
                            <LinkOMIM omimId={x.source} label={x.source} />
                        </>}
                    </td>
                    <td style={{ padding: "3px 5px" }}>{x.inheritanceModel}</td>
                </tr >)
        })
    return result;
}