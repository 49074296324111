import React from 'react';
import { useContext, useState } from 'react';
import { LangContext } from "../../../Contexts/Lang";
import ReportedVariantServices from "../../../Services/ReportedVariantService";
import { Link } from 'react-router-dom';
import { Popover } from '../Popover';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FiltersReportedVariantsI } from '../../../Contexts/ReportedVariants/ReportedVariantsContext';
import CloseButton from '../CloseButton';

interface LinkReportedVariantI {
    studyId: string,
    chromosome: string,
    position: string,
    change: string,
    gene: string
}
export default function LinkReportedVariant(props: LinkReportedVariantI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const [show, setshow] = useState<boolean>(false)

    const [reportedVariantId, setreportedVariantId] = useState<string | undefined>()
    const [loading, setloading] = useState<boolean>(false)
    const ReportedVariantS = new ReportedVariantServices();

    const handleLaunchReportedVIdRequest = () => {
        setloading(true)
        let identity = `${props.chromosome}${props.position}${props.change}${props.gene}`
        const filters: FiltersReportedVariantsI = {
            studyId: `${props.studyId}`,
            identity: identity
        }
        ReportedVariantS.search(filters)
            .then((res: any) => {
                // console.log("res")
                // console.log(res)
                if (res.status === 200) {
                    if (res?.data?.reports && res.data.reports.length > 0)
                        setreportedVariantId(res.data.reports[0].id)
                }
                setloading(false)
            }).catch((err: any) => {
                console.log("err")
                console.log(err)
                setloading(false)
            })
    }
    return (
        <>
            <Popover id={"LinkReportedVariant"}
                show={show}
                style={{ fontSize: "10px" }}
                titleStyle={{ padding: "5px 6px 5px 10px" }}
                title={
                    <span style={{}}>
                        {translate("common.reportedVariant")} &nbsp;&nbsp;
                        <CloseButton styles={{}} onClose={() => setshow(false)} />
                    </span>
                }
                content={
                    <>
                        {
                            loading ?
                                translate("common.loading") :
                                !reportedVariantId ?
                                    <span style={{ color: "red" }}>
                                        {translate("common.error")}
                                    </span> :
                                    <Link to={`/variants/${reportedVariantId}`} onClick={(e) => e.stopPropagation()}>
                                        {translate("casesQuery.goToReportedVariantDetail")}
                                    </Link>
                        }
                    </>
                }
                trigger={"click"} >
                <Button variant="outline-light"
                    onClick={(e: any) => {
                        setshow(true)
                        e.stopPropagation()
                        handleLaunchReportedVIdRequest()
                    }}
                    style={{
                        border: "none",
                        borderRadius: "50px",
                        padding: "3px 6px",
                        margin: "0 3px",
                    }}>
                    <FontAwesomeIcon icon={faBookmark}
                        style={{
                            // marginLeft: "2px",
                            border: "none",
                            fontSize: "13px",
                            verticalAlign: "text-bottom",
                            color: "gray"
                        }} />
                </Button>
            </Popover>
        </>

    )
}