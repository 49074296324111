import { EncryptStorage } from 'encrypt-storage';

// const SECRET_KEY  = process.env;
// const { REACT_APP_API_URL } = process.env??"asopdmasp";
const encryptLS = EncryptStorage("<sssssssssdsad", {
  storageType: 'localStorage',
});

const encryptSS = EncryptStorage('secret-key', {
  storageType: 'sessionStorage',
});
const encrypt: boolean = true;
let encryptLocalStorage = encrypt ? encryptLS : localStorage;
let encryptSessionStorage = encrypt ? encryptSS : sessionStorage;


export { encryptLocalStorage, encryptSessionStorage, encrypt };