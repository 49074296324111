import React from 'react';
import { useContext } from "react";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faFolderOpen, faAngleLeft, faArchive } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from "../../Common/Tooltip/Tooltip";

import { LangContext } from "../../../Contexts/Lang";
import ThemeContext from "../../../Contexts/Theme/ThemeContext";

type DetailHeaderT = "default" | "domain";
interface DetailHeaderI {
    history: any,
    titleTooltipLabel: string,
    titleLabel: string | JSX.Element,
    buttonsActions: {
        edit?(): void,
        archive?(): void,
        active?(): void,
        delete?(): void,
    },
    DHType?: DetailHeaderT,
    handleGoBack?(): void,
}
export default function DetailHeader(props: DetailHeaderI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const DHType: DetailHeaderT = props.DHType ?? "default";
    const isDomainType = (DHType === "domain")
    const iconStyles = { color: theme.grayHeader, fontSize: "15px" }

    const handleGoBack = () => {
        if (props.handleGoBack) props.handleGoBack();
        else props.history.goBack()
    };
    let detailHeaderStyle: any = {
        display: "flex", justifyContent: "space-between", padding: "0"
    };
    if (isDomainType) {
        detailHeaderStyle = {
            ...detailHeaderStyle,
            position: "fixed",
            width: "100%",
            zIndex: 3,
            backgroundColor: theme.backgroundSecondary,
            marginLeft: "-23px",
            padding: "15px 28px 7px 23px"
        }
    }
    return (
        <div id="DetailHeader" style={detailHeaderStyle}>
            <Tooltip label={props.titleTooltipLabel} placement={"bottom"}>
                <h4 style={{ cursor: "pointer", marginBottom: 0, display: "flex", fontSize: "22px" }} onClick={handleGoBack}>
                    <Button variant="outline-ligth">
                        <FontAwesomeIcon icon={faAngleLeft}
                            style={{ fontSize: "15px" }} />
                    </Button>
                    {props.titleLabel}
                </h4>
            </Tooltip>
            <div>
                {props.buttonsActions?.edit &&
                    <>
                        <TooltipActionButton tooltipLabel={translate("common.edit")}
                            actionLaunch={props.buttonsActions?.edit}
                            faIcon={
                                <FontAwesomeIcon icon={faEdit} style={iconStyles} />
                            } />
                        &nbsp;
                    </>
                }
                {props.buttonsActions?.active &&
                    <>
                        <TooltipActionButton tooltipLabel={translate("common.activate")}
                            actionLaunch={props.buttonsActions?.active}
                            faIcon={
                                <FontAwesomeIcon icon={faFolderOpen} style={iconStyles} />
                            } />
                        &nbsp;
                    </>
                }
                {props.buttonsActions?.archive &&
                    <>
                        <TooltipActionButton tooltipLabel={translate("common.archive")}
                            actionLaunch={props.buttonsActions?.archive}
                            faIcon={
                                <FontAwesomeIcon icon={faArchive} style={iconStyles} />
                            } />
                        &nbsp;
                    </>
                }
                {props.buttonsActions?.delete &&
                    <>
                        <TooltipActionButton tooltipLabel={translate("common.delete")}
                            actionLaunch={props.buttonsActions?.delete}
                            faIcon={
                                <FontAwesomeIcon icon={faTrash} style={iconStyles} />
                            } />
                        &nbsp;
                    </>
                }
            </div>
        </div>
    )
}

interface TooltipActionButtonI {
    tooltipLabel: string,
    actionLaunch: any,
    faIcon: any
}
function TooltipActionButton(props: TooltipActionButtonI) {
    const actionButtonStyles = { border: "none", height: "30px", width: "38px", borderRadius: "0px", justifyContent: "center", alignItems: "center" }
    return (
        <Tooltip label={props.tooltipLabel} placement={"bottom"}>
            <Button variant="light"
                onClick={() => props.actionLaunch()}
                style={actionButtonStyles}>
                {props.faIcon}
            </Button>
        </Tooltip>
    )
}

