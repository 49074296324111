import React from 'react';
interface CircleLabelI {
    label: string,
    backColor?: string,
    size?: 0 | 1 | 2 | 3,
    style?: any,
}
export default function CircleLabel(props: CircleLabelI) {
    return (
        <label style={{
            backgroundColor: props.backColor ?? "#444B59",
            color: "white",
            borderRadius: "50px",
            padding: "2px 5px",
            fontSize: props.size === 0 ? "9px" : props.size === 1 ? "11px" : props.size === 2 ? "13px" : props.size === 3 ? "15px" : "11px",
            height: "fit-content",
            minWidth: props.size === 0 ? "18px" : props.size === 1 ? "20px" : props.size === 2 ? "21px" : props.size === 3 ? "23px" : "20px",
            fontWeight: "bold",
            textAlignLast: "center",
            marginBottom: 0,
            ...(props.style ?? {})
        }}>
            {props.label}
        </label>
    )
}