import React, { useLayoutEffect } from 'react';
import { useContext, useState, useEffect } from 'react';
import { Col, Row, Card, Tab, Nav, Button } from 'react-bootstrap';
import { faChartPie, faDownload } from '@fortawesome/free-solid-svg-icons';
import { LangContext } from "../../../../Contexts/Lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DonutChart from '../../../Common/D3/DonutChart';
import StatisticsService from '../../../../Services/StatisticsService';
import VennChart from '../../../Common/D3/VennChart';
import SampleTable from './SamplesTable';
import IntersectionTable from './IntersectionTable';
import { Tooltip } from "../../../Common/Tooltip/Tooltip";
import ReportServices from '../../../../Services/ReportService';
import { consequenceEffectValues, getValueByKeyInLibraryPrepKit } from '../../../../utils/BioFunctionalData';
import UserContext from '../../../../Contexts/User/UserContext';
import ThemeContext from '../../../../Contexts/Theme/ThemeContext';
import CopyToClipboard from '../../../Common/CopyToClipboard';
import { getSampleId_NameTagAffSex } from 'src/utils/BioHelpers';
import { StudyI } from 'src/Contexts/interfaces';
import { useWindowSize } from 'src/Helpers/WindowsSizeHelper';
interface StatisticsI {
    history: any,
    actualCase?: StudyI,
    domainId?: number
    noTitle?: boolean
}

export default function Statistics(props: StatisticsI) {
    const { widthType } = useWindowSize()
    const sizexxl = widthType === "xxl"

    const { user } = useContext(UserContext);
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const StatisticsS = new StatisticsService()
    const ReportS = new ReportServices()
    const [actualTab, setactualTab] = useState(0)
    const [sample, setsample] = useState<any | undefined>()
    const [sampleLoading, setsampleLoading] = useState<boolean>(true)
    const [vennChartData, setvennChartData] = useState<any | undefined>()
    const [intersectionTableData, setintersectionTableData] = useState<any | undefined>()
    const multipleSamples = (props.actualCase?.samples ?? []).length > 1;
    useEffect(() => {
        let isMounted = true;
        setsampleLoading(true)
        if (props?.actualCase?.name &&
            props?.actualCase?.id) {
            if (actualTab !== 0 || !multipleSamples) {
                const sampleName = multipleSamples ?
                    props.actualCase.samples[actualTab - 1].sample.name :
                    props.actualCase.samples[0].sample.name;
                StatisticsS.read_stats_for_sample(props.actualCase.id, sampleName, props.domainId)
                    .then((res: any) => {
                        if (isMounted && res.status === 200) {
                            setsample(res.data)
                            setsampleLoading(false)
                        }
                    })
                    .catch((e: any) => {
                        console.log("e")
                        console.log(e)
                    })
            } else
                StatisticsS.read_stats_chart(props.actualCase.id, props.domainId)
                    .then((res: any) => {
                        if (isMounted && res.status === 200) {
                            setvennChartData(res.data.statsSet.sets)
                            setintersectionTableData(res.data.statsSet)
                            setsampleLoading(false)
                        }
                    })
                    .catch((e: any) => {
                        console.log("e")
                        console.log(e)
                    })
        }
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, actualTab])
    let samples = props?.actualCase?.samples ?? []
    function getTabs() {
        let navHeaders = samples.map((x: any, i: number) => {
            const eventKey = i + (multipleSamples ? 1 : 0)
            return (
                <Nav.Item style={{ margin: 0, maxWidth: "inherit" }} key={eventKey}>
                    <Nav.Link eventKey={eventKey}
                        onClick={() => {
                            setactualTab(eventKey)
                        }}
                        style={{ borderRadius: 0, fontSize: "12px", padding: "5px 10px 5px" }}>
                        {x.sample.name}
                    </Nav.Link>
                </Nav.Item>)
        })
        navHeaders = [
            <Nav.Item style={{ margin: 0, maxWidth: "inherit" }} key={0}>
                <Nav.Link eventKey="0"
                    style={{ borderRadius: 0, fontSize: "14px", padding: "5px 10px 5px", borderBottom: "solid 1px lightgray" }}
                    onClick={() => {
                        setactualTab(0)
                    }}
                >
                    <strong>
                        General
                    </strong>
                </Nav.Link>
            </Nav.Item>,
            ...navHeaders
        ]
        return navHeaders
    }
    function getValuesToCopy() {
        let hete = " ",
            tiTv = " ";
        if (!sample) return ""
        if (sample?.stats?.Heterozygous && sample?.stats?.Homozygous)
            hete = parseFloat(`${sample?.stats?.Heterozygous / sample?.stats?.Homozygous}`).toFixed(2).toString() + "\n";
        if (sample?.stats?.Transitions && sample?.stats?.Transversions)
            tiTv = parseFloat(`${sample?.stats?.Transitions / sample?.stats?.Transversions}`).toFixed(2).toString() + "\n";
        let dbsnp = (sample?.stats?.dbSNPVariants && sample?.stats?.TotalVariants ? (sample?.stats?.dbSNPVariants / sample?.stats?.TotalVariants).toFixed(3) : " - ") + "\n"
        const libr = (sample.libraryPrepKit ? getValueByKeyInLibraryPrepKit(sample.libraryPrepKit.toLowerCase()) ?? " " : " ") + "\n",
            geno = (sample.genomeReference ?? "-").toString() + "\n",
            totV = (sample.stats?.TotalVariants ?? "-").toString() + "\n",
            fram = (sample.stats?.dbSNPVariants ?? "-").toString() + "\n",
            sile = (sample.stats?.SilentVariants ?? "-").toString() + "\n",
            totC = (sample.stats?.TotalCoverageRatio ?? "-").toString() + "\n",
            gene = (sample.stats?.Genes ?? "-").toString() + "\n",
            sing = (sample.stats?.SingleSNP ?? "-").toString() + "\n",
            inse = (sample.stats?.Insertions ?? "-").toString() + "\n",
            dele = (sample.stats?.Deletions ?? "-").toString() + "\n"
        return `${libr}${geno}${totV}${dbsnp}${fram}${hete}${tiTv}${sile}${totC}${gene}${sing}${inse}${dele}`
    }

    const cardFooterButtonStyle = {
        border: "none", height: "30px", width: "33px", display: "flex", borderRadius: "50px",
        justifyContent: "center", alignItems: "center", marginLeft: "5px"
    }

    function getChartData(sample: any, array: any[], type: "impact" | "effect" | "intervarVeredict") {
        const getBaseSampleLink = () => {
            let link = `/cases/query/caseId/${props.actualCase?.id ?? ""}/`
            link += `sample_${sample.id}=${getSampleId_NameTagAffSex(sample.sample.name, sample.tag, sample.afectado, sample.sample.sexo)}&`
            return link
        }
        return array.map((x: any) => {
            let valueProp = x.valueprop.trim().toUpperCase().replaceAll(" ", "_").replaceAll("#", "")
            if (type === "effect") {
                valueProp = consequenceEffectValues
                    .filter((y: any) => y.label.trim().toUpperCase().replaceAll(" ", "_") === valueProp)
                    .map((y: any) => y.value.toLowerCase())
                if (valueProp.length === 0 && x.valueprop === "Synonims")
                    valueProp = "SYNONYMOUS_VARIANT"
            }
            return {
                value: x.total,
                label: x.valueprop,
                link: `${getBaseSampleLink()}${type}[]=${valueProp}&studyId=${props.actualCase?.id ?? ""}`
            }
        })
    }
    function getChartDataEnable(array: any[]) {
        return array
            .filter((x: any) => x.value !== 0)
            .map((x: any) => x.value)
            .reduce((partial_sum: number, a: number) => partial_sum + a, 0) !== 0

    }
    const [maxHeigthSampleSelections, setmaxHeigthSampleSelections] = useState<number | undefined>(undefined)

    let navBody = samples.map((x: any, i: number) => {
        const key = `${i + 1}`
        const potentialImpactData = getChartData(x, sample?.impact ?? [], "impact")
        const potentialImpactDataEnable = getChartDataEnable(potentialImpactData)
        const effectData = getChartData(x, sample?.effect ?? [], "effect")
        const effectDataEnable = getChartDataEnable(effectData)
        const evidenceData = getChartData(x, sample?.evidence ?? [], "intervarVeredict")
        const evidenceDataEnable = getChartDataEnable(evidenceData)
        return (
            <Tab.Pane eventKey={key} key={key}
            >
                <Row style={{ fontSize: "12px", margin: 0, padding: "15px" }}>
                    {/* <Col sm={12} md={12} lg={5} xl={4} style={{ padding: multipleSamples ? "0px 15px 10px 0px" : "0" }}> */}
                    <Col sm={12} md={12} lg={5} xl={4} style={{ padding: multipleSamples ? "0px" : "0" }}>
                        <div style={{ display: "grid" }}>
                            <Card>
                                <SampleTable sample={sample} />
                                <Card.Footer style={{ padding: "3px", display: "flex", justifyContent: "flex-end" }}>
                                    <CopyToClipboard valueToCopy={getValuesToCopy()} fontSize={"13px"} />
                                    <a id="downloadCoverageLink"
                                        href={`${ReportS.basePath}downloadCoverageFile?token=${user.data.token}&domainId=${props.domainId ? props.domainId : user.data.domain}&sample=${x.sample.name}`}
                                        target="_blank" rel="noreferrer">
                                        <Tooltip label={translate('casesDetail.downloadCoverageFile')} placement={"top"}>
                                            <Button variant="light" style={cardFooterButtonStyle}>
                                                <FontAwesomeIcon icon={faDownload} style={{ color: "gray", fontSize: "15px" }} />
                                            </Button>
                                        </Tooltip>
                                    </a>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={7} xl={8} style={{ padding: "0 15px" }}>
                        <Row style={{ margin: "0", display: "flex", justifyContent: sizexxl ? "space-around" : "start" }}>
                            {
                                !sampleLoading && sample?.impact && potentialImpactDataEnable &&
                                <span style={{ padding: `10px 5px` }}>
                                    < DonutChart data={potentialImpactData}
                                        history={props.history}
                                        width={170}
                                        height={170}
                                        title={translate('common.potentialImpact')}
                                        labels={true}
                                        id="impactChart"
                                    />
                                </span>
                            }
                            {
                                !sampleLoading && sample?.effect && effectDataEnable &&
                                <span style={{ padding: "10px 5px " }}>
                                    <DonutChart data={effectData}
                                        history={props.history}
                                        width={170}
                                        height={170}
                                        title={translate('casesDetail.effects')}
                                        labels={true}
                                        id="effectsChart"
                                    />
                                </span>
                            }
                            {
                                !sampleLoading && sample?.evidence && evidenceDataEnable &&
                                <span style={{ padding: "10px 5px " }}>
                                    <DonutChart data={evidenceData}
                                        history={props.history}
                                        width={170}
                                        height={170}
                                        title={translate('casesDetail.acmgEvidence')}
                                        labels={true}
                                        id="acmgEvidenceChart"
                                    />
                                </span>
                            }
                        </Row>
                    </Col>
                </Row >
            </Tab.Pane >
        )
    })
    if (multipleSamples)
        navBody = [
            <Tab.Pane eventKey="0" key={0}
            >
                <Row style={{ fontSize: "12px", margin: 0 }}>
                    <Col sm={12} md={12} lg={5} xl={4} style={{ padding: "0 15px" }}>
                        <IntersectionTable data={intersectionTableData} />
                    </Col>
                    <Col sm={12} md={12} lg={7} xl={8} style={{ display: "flex", justifyContent: "center", padding: "0 15px 0 0" }}>
                        {
                            vennChartData && props.actualCase?.type === "familiar" &&
                            <VennChart data={vennChartData}
                                widht={400}
                                height={350}
                            />
                        }
                    </Col>
                </Row>
            </Tab.Pane>,
            ...navBody
        ]
    useLayoutEffect(() => {
        let timeoutId: NodeJS.Timeout | undefined;
        if (navBody) {

            const handleResize = () => {
              if (navBody) {
                const element = document.getElementById(`left-tabs-example-tabpane-${actualTab}`);
                if (element && element.getBoundingClientRect().height) {
                  setmaxHeigthSampleSelections(element.getBoundingClientRect().height);
                } else {
                  setmaxHeigthSampleSelections(undefined);
                }
              }
            };
        
            timeoutId = setTimeout(handleResize, 10);
        
            // Cleanup function to clear the timeout if the component unmounts
            return () => {
              if (timeoutId) {
                clearTimeout(timeoutId);
              }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sampleLoading, actualTab])

    return (
        <div id={"Statistics"} style={{ width: "100%" }}>
            <Card style={{ width: "100%", marginBottom: "15px" }}>
                {!props.noTitle && <Card.Header
                    style={{ padding: "0 0 0 1.25rem", display: "flex", justifyContent: "space-between" }}>
                    <h5 style={{ color: theme.grayHeader, fontSize: "17px", paddingTop: "12px" }}>
                        <FontAwesomeIcon icon={faChartPie} style={{ color: "lightgray", fontSize: "20px", marginRight: "10px" }} />
                        {translate('casesDetail.statistics')}

                    </h5>
                </Card.Header>}
                <Card.Body
                    style={{ padding: 0, paddingBottom: 0 }}
                >
                    {!multipleSamples ?
                        <Row style={{ margin: 0 }}>
                            <Col sm={12} style={{ padding: 0 }}>
                                {navBody}
                            </Col>
                        </Row> :
                        <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                            <Row style={{ margin: 0 }}>
                                <Col xs={4} sm={3} md={4} lg={3} xl={2}
                                    style={{
                                        padding: 0,
                                        backgroundColor: theme.backgroundSecondary,
                                        borderRight: "1px solid #e7e7e7",
                                    }}>
                                    {navBody &&
                                        <div style={{
                                            overflowY: "auto",
                                            maxHeight: maxHeigthSampleSelections
                                        }}>
                                            <Nav variant="pills" className="flex-column"
                                                style={{
                                                    margin: 0,
                                                    maxWidth: "-webkit-fill-available",
                                                }}>
                                                {getTabs()}
                                            </Nav>
                                        </div>}
                                </Col>
                                <Col xs={8} sm={9} md={8} lg={9} xl={10} style={{ padding: 0 }}>
                                    <Tab.Content>
                                        {navBody}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}




