// Compose chart --> https://www.dtreelabs.com/blog/composable-charts-using-nivo-in-react
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar'
import React from 'react'


interface PredictorsBarI {
    data: {
        benign: number,
        uncertainSignificance: number,
        pathogenic: number
    }
}
export default function PredictorsBar(props: PredictorsBarI) {
    let data: any = [{}], dataKeys = []
    if (props.data.benign !== 0) { data[0]["Benign"] = props.data.benign; dataKeys.push("Benign"); }
    if (props.data.uncertainSignificance !== 0) { data[0]["Uncertain Significance"] = props.data.uncertainSignificance; dataKeys.push("Uncertain Significance"); }
    if (props.data.pathogenic !== 0) { data[0]["Pathogenic"] = props.data.pathogenic; dataKeys.push("Pathogenic"); }
    return (
        <div style={{}}>
            <div style={{ height: "20px", width: "100%" }}>
                <ResponsiveBar
                    data={data}
                    layout={"horizontal"}
                    keys={[
                        'Benign',
                        'Uncertain Significance',
                        'Pathogenic'
                    ]}
                    indexBy=""
                    innerPadding={0}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    padding={0.1}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={["#34AC4F", "#808080", "#DD3D4D"]}
                    defs={[
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#9e9e9e',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'Uncertain Significance'
                            },
                            id: 'lines'
                        }
                    ]}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={null}
                    role="application"
                    tooltip={({ id, value, color }) => (
                        <div style={{ padding: "5px 12px", color, background: '#f4f4f4' }}>
                            <strong> {id}: {value} </strong>
                        </div>
                    )}
                />
            </div>
        </div>
    )

}
