import React from 'react';
import { StudyI } from "../../../../Contexts/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faAngleDown, faExternalLinkSquareAlt, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "src/Components/Common/Tooltip/Tooltip";
import { Accordion, Badge, Card } from "react-bootstrap";
import { LinkLocalGen } from "src/Components/Common/Links/Links";
import CircleLabel from "src/Components/Common/CircleLabel";
import { useContext, useState } from "react";
import { LangContext } from "src/Contexts/Lang";
import ThemeContext from "src/Contexts/Theme/ThemeContext";
import CoverageByGeneBar from 'src/Components/Common/Nivo/CoverageByGene/CoverageByGeneBar';
import CoverageByGenesAccordionBody from './CoverageByGenesAccordionBody';

interface CoverageByGenesTabBySampleI {
    study: StudyI,
    studyId: string,
    studyName: any,  //x
    coverageFilteredData: any,
    coverageRawData: any,
    handleFilter(i: string): void,
    filter: string
}
export default function CoverageByGenesTabBySample(props: CoverageByGenesTabBySampleI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);

    const [, settableMounted] = useState<boolean>(false)

    const [activeKey, setActiveKey] = useState<any>(null);


    const getLabelValueInfo = (label: string, value: string, info: string) => {
        return (
            <Tooltip label={info}>
                <span style={{ fontSize: "12px" }}>
                    <span style={{}}>
                        {label}: &nbsp;
                    </span>
                    <span style={{}}>
                        {value}%&nbsp;
                        <FontAwesomeIcon icon={faInfoCircle} style={{ color: "gray" }} />
                    </span>
                </span>
            </Tooltip >
        )
    }
    const getGeneCoverageDetail = (geneInfo: any) => {
        return (
            <>
                {getLabelValueInfo("% Coverage", geneInfo.pctgeneCoverage ?? "", translate("common.pctCoverageInfo"))} &nbsp;|&nbsp;
                {getLabelValueInfo("% Targets 20X", geneInfo.pct20xAllTargets ?? "", translate("common.pctAt20XInfo"))}&nbsp;|&nbsp;
                {getLabelValueInfo("% Exons 20X", geneInfo.pct20xAllExons ?? "", translate("common.pctAe20XInfo"))}
            </>
        )
    }

    let coverageActualSampleRawData = props.coverageRawData[`${props.studyName}`]
    let coverageActualSampleFilteredData = props.coverageFilteredData[`${props.studyName}`]

    let genesWithoutCov = coverageActualSampleRawData
        .filter((x: any) => (!x.genename || !x.pctgeneCoverage || !x.pct20xAllTargets || !x.pct20xAllExons))
        .map((x: any, i: number) => x.genename)

    const chartData: { geneName: string, coverage: string, cov20XTargets: string, cov20XExons: string }[] = coverageActualSampleRawData
        .map((x: any) => {
            return {
                geneName: x.genename,
                coverage: x.pctgeneCoverage,
                cov20XTargets: x.pct20xAllTargets,
                cov20XExons: x.pct20xAllExons
            }
        })
        .filter((x: any) => genesWithoutCov.indexOf(x.geneName) === -1)

    const coverageGeneBarData = coverageActualSampleFilteredData.length === coverageActualSampleRawData.length ? [] : coverageActualSampleFilteredData
    // const coverageGeneBarData =  coverageActualSampleFilteredData

    // console.log(coverageActualSampleFilteredData[0])

    return (
        <Accordion activeKey={activeKey}
            onSelect={(e: any) => { settableMounted(false); setActiveKey(e) }}
            style={{ marginTop: "2px", borderRadius: 0 }}>
            <div style={{ padding: "10px" }}>
                <h6 style={{ color: theme.grayHeader, fontSize: "12px", marginBottom: 0 }}>
                    &nbsp;&nbsp;{translate("common.meanCoverageByGene").toUpperCase()}
                </h6>
                <CoverageByGeneBar chartData={chartData}
                    coverageFilteredData={coverageGeneBarData}
                    handleSelect={props.handleFilter}
                />
                {genesWithoutCov.length > 0 &&
                    <span style={{ fontSize: "12px", color: theme.grayHeader }}>
                        &nbsp;&nbsp;
                        <FontAwesomeIcon icon={faExclamationCircle} style={{ border: "none", color: "#F28B00", fontSize: "14px", verticalAlign: "middle" }} />
                        &nbsp;{translate("common.theFollowingGenesDoNotPresentCoverageInformation")}&nbsp;
                        <CircleLabel label={genesWithoutCov.length} size={0} backColor={"darkgray"} style={{ verticalAlign: "middle" }} />&nbsp;:&nbsp;
                        {
                            genesWithoutCov
                                .map((x: any, i: number) => <span key={i}>
                                    <Badge variant="light" style={{ fontSize: "10px", backgroundColor: "#e4e4e4", fontWeight: "lighter" }}>
                                        {x}
                                    </Badge>
                                    {`${i !== genesWithoutCov.length - 1 ? ", " : ""}`}
                                </span>
                                )
                        }
                    </span>
                }
            </div>
            {
                (coverageActualSampleFilteredData).map((xx: any, ii: number) => {
                    return (xx.regions ?? []).length === 0 ? <span key="lalal"></span> :
                        <Card key={`-${ii}-`} style={{ borderRadius: 0, overflowX: "auto" }}>
                            <Accordion.Toggle as={Card.Header} eventKey={`${ii}`} style={{ padding: "5px 15px", cursor: "pointer", backgroundColor: activeKey === `${ii}` ? "rgba(85, 124, 185, 0.25)" : "rgba(1, 1, 1, 0.0)" }}>
                                <span style={{ fontSize: "12px", color: theme.grayHeader, fontWeight: "bold" }}>
                                    {xx.genename}
                                </span>&nbsp;
                                <CircleLabel label={xx.regions.length}
                                    size={1}
                                    backColor={"gray"}
                                />
                                &nbsp;:&nbsp;
                                {getGeneCoverageDetail(xx)}
                                <span style={{ float: "right" }}>
                                    <LinkLocalGen geneName={xx.genename}
                                        caseId={`${props.studyId}`}
                                        fontSize={"14px"}
                                        variantId={"1"}
                                        tooltip={translate("common.goToGeneVariants")}
                                        label={<FontAwesomeIcon icon={faExternalLinkSquareAlt} style={{ color: theme.link }} />}
                                    />
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon icon={faAngleDown} style={{ color: "gray" }} />
                                </span>

                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`${ii}`}>
                                <CoverageByGenesAccordionBody
                                    study={props.study}
                                    studyId={props.studyId}
                                    studyName={props.studyName}
                                    isActive={activeKey === `${ii}`} // 
                                    data={xx}
                                    settableMounted={settableMounted} />
                            </Accordion.Collapse>
                        </Card>
                })
            }
        </Accordion >
    )
}
