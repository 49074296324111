import React from 'react';
import { useContext, useEffect, useState } from "react";
import Paginator from "../../Common/Paginator/Paginator";
import HomeLayout from "../../Layout/Home/HomeLayout";
import { Card } from "react-bootstrap";
import { LangContext } from "../../../Contexts/Lang";
import VariantServices from "../../../Services/VariantServices";
import { AsyncDataI } from "../../../Contexts/interfaces";
import CommentsTable from "./CommentsTable";
import { ModalWarning } from "../../Common/ModalWarning";
import { ModalCommentGroupT, getConcatedComments, getStateComments, ModalCommentGroup } from "../../Common/ModalCommentGroup/ModalCommentGroup";
import Loader from "../../Common/Loader/Loader";
import HomeToolkit from "../../Layout/Home/HomeToolkit";
import { faComment, faTrash, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import ReportService from "../../../Services/ReportService";
import { Tooltip } from "../../Common/Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VariantIdentificationTableRowI } from "src/Components/Common/ModalCommentGroup/VariantIdentificationTableRow";

interface CommentsI {
    history: any,
    caseId: string,
}
export default function Comments(props: CommentsI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const VariantS = new VariantServices()
    const ReportS = new ReportService()

    const initialVariantsComments = { loaded: false, loading: false, error: null, data: undefined }
    const [variantsComments, setvariantsComments] = useState<AsyncDataI>(initialVariantsComments);
    const [reloadCall, setReloadCall] = useState(false);
    const [variantsSelecteds, setvariantsSelecteds] = useState<string[]>([])

    const paginator = Paginator({
        reloadCall: () => setReloadCall(true),
        onChangePage: () => { setReloadCall(true) }
    })

    const initFormikModal = { show: false, type: "comment" as ModalCommentGroupT, data: undefined, editData: undefined }
    const [commentGroupModal, setcommentGroupModal] = useState<{ show: boolean, data: any, editData: any }>(initFormikModal);
    const handleCloseCommentGroupModal = () => { setcommentGroupModal(initFormikModal) }
    const handleShowCommentGroupModal = (data: any, editData?: any) => {
        setcommentGroupModal({ show: true, data: data, editData: editData })
    }

    const [removeCommentModal, setremoveCommentModal] = useState<{ show: boolean, variants: string[] }>({ show: false, variants: [] });
    const handleCloseRemoveCommentModal = () => { setremoveCommentModal({ show: false, variants: [] }) }
    const handleShowRemoveCommentModal = (variantsIds?: string[]) => {
        setremoveCommentModal({ show: true, variants: variantsIds ?? variantsSelecteds })
    }
    const handleUncomentVariant = () => {
        if (removeCommentModal.variants)
            VariantS.uncomment_variants(+props.caseId, removeCommentModal.variants.map((x: any) => x))
                .then((res: any) => {
                    // console.log("res");
                    // console.log(res);
                    if (res.status === 200) {
                        handleCloseRemoveCommentModal();
                        handleReload();
                    }
                }).catch((e: any) => {
                    console.log("e")
                    console.log(e)
                })
    }
    const handleCommentVariant = (variantId?: string) => {
        const getVariantsSelecteds_IdsPosChangeGene = (): VariantIdentificationTableRowI[] => {
            const variantIdsPosChangeGen: any[] = (variantsComments?.data?.results ?? []).map((x: any) => {
                return ({
                    id: x.id,
                    position: `${x.chromosome}:${x.position}`,
                    change: `${x.reference} > ${x.alternative}`,
                    gen: x.infoColumnData.GENE_NAME,
                    checked: true,
                })
            })
            if (variantId) return variantIdsPosChangeGen.filter((y: any) => `${y.id}` === `${variantId}`)
            return variantsSelecteds.map((x: string) => {
                return variantIdsPosChangeGen.find((y: any) => `${y.id}` === `${x}`)
            })

        }
        handleShowCommentGroupModal(
            { variants: getVariantsSelecteds_IdsPosChangeGene() },
            {
                comment: variantsComments.data?.results ? getConcatedComments(variantsComments.data?.results, variantId ? [variantId] : variantsSelecteds) ?? undefined : undefined,
                state: variantsComments.data?.results ? getStateComments(variantsComments.data?.results, variantId ? [variantId] : variantsSelecteds) ?? undefined : undefined
            }
        )
    }

    const initialFilters = { geneName: "", dbdnpId: "", study: "", classification: "", sortColumn: "state" }
    const [filters, setfilters] = useState<{ geneName: string, dbdnpId: string, study: string, classification: string, sortColumn: string }>(initialFilters)
    // const cleanFilters = () => { setfilters(initialFilters) }
    // function handleSetFilters(key: string, value: string) {
    //     const f: any = filters
    //     f[key] = value;
    //     setfilters({ ...f })
    // }
    // const inputFilterData: InputFilterI[] = [
    //     {
    //         type: "text",
    //         stateName: "geneName",
    //         placeholder: `${translate("common.geneName")}...`,
    //     },
    //     {
    //         type: "text",
    //         stateName: "dbsnpId",
    //         placeholder: "dbSnp Id...",
    //     },
    //     {
    //         type: "text",
    //         stateName: "study",
    //         placeholder: translate("common.study"),
    //     },
    //     {
    //         type: "select",
    //         stateName: "classification",
    //         options: variantAcmgValues,
    //         placeholder: translate("common.classification")
    //     },
    // ]


    // const getRequestFilters = () => {
    //     return {
    //         genenameSearch: filters.geneName,
    //         rsSearch: filters.dbsnpId,
    //         decisionSearch: filters.decision ?? "",
    //         classificationSearch: filters.classification,
    //         studynameSearch: filters.study,
    //         sortColumn: filters.sortColumn,
    //     }
    // }
    useEffect(() => {
        setvariantsComments({ loaded: false, loading: true, error: null, data: variantsComments.data })
        if ((!variantsComments.loaded && !variantsComments.loading) || reloadCall) {
            VariantS.readAllCommmentVariants(props.caseId, filters.sortColumn, paginator.actualPage + 1, paginator.itemsPerPage)
                .then((res: any) => {
                    if (res.status === 200) {
                        setvariantsComments({ loaded: true, loading: false, error: null, data: res.data })
                    }
                })
                .catch((err: any) => {
                    console.log("err")
                    console.log(err)
                    setvariantsComments({ loaded: false, loading: false, error: err, data: undefined })
                })
            // paginator.setActualPage(actualPage - 1)
        }
        if (reloadCall) { setReloadCall(false); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadCall, paginator.itemsPerPage]);
    // console.log("variantsComments")
    // console.log(variantsComments.data)
    useEffect(() => {
        paginator.pageSize_set(variantsComments.data?.rowsCount ?? 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variantsComments])
    function handleReload() { setReloadCall(true) }
    function handleSortByFilter(value: string) {
        if (value !== filters.sortColumn) {
            setfilters({ ...filters, "sortColumn": value })
            // handleLaunchFilter();
        }
    }

    function handleVariantsSelecteds(id: string) {
        var selecteds = variantsSelecteds
        if (selecteds.find((x: any) => x === id))
            setvariantsSelecteds(selecteds.filter((x: any) => x !== id))
        else
            setvariantsSelecteds([...selecteds, id])
    }

    function handleSelectAll() {
        if (variantsSelecteds.length === (variantsComments?.data?.results ?? []).length) {
            // console.log(1)
            setvariantsSelecteds([])
        }
        else {
            // console.log(2)
            setvariantsSelecteds([...(variantsComments?.data?.results ?? []).map((x: any) => x.id)])
        }
    }

    return (
        <>
            {removeCommentModal?.show &&
                <ModalWarning labelTitle={translate('common.confirm')}
                    label={translate('casesQuery.doYouWantToRemoveCommentsOnTheSelectedVariants')}
                    labelButton={translate('common.delete')}
                    showState={removeCommentModal.show}
                    handleClose={handleCloseRemoveCommentModal}
                    onClick={handleUncomentVariant}
                />}
            <ModalCommentGroup show={commentGroupModal.show}
                handleClose={handleCloseCommentGroupModal}
                type={"comment"}
                data={commentGroupModal.data}
                editData={commentGroupModal.editData}
                studyId={props.caseId}
                handleReload={() => { handleReload() }}
            />

            <HomeLayout history={props.history}
                // filterTitle={translate('common.commens')}
                filterTitle={
                    <span onClick={() => props.history.goBack()} style={{ cursor: 'pointer', fontSize: '22px' }}>
                        <Tooltip label={translate("common.goToPreviousPage")} placement={"bottom"}>
                            <span>
                                <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "17px" }} />&nbsp;
                                {variantsComments.loaded && <>{variantsComments.data.study.name} &nbsp;- &nbsp;</>}

                                {translate("commentedVariants.commentedVariants")}
                            </span>
                        </Tooltip>
                    </span>
                }
                rowsAmount={{
                    loading: !variantsComments.loaded || variantsComments.loading,
                    amount: variantsComments.data?.rowsCount ?? 0
                }}
                Filters={
                    <div style={{ width: "100%" }}>
                        {/* <HomeFilter filters={filters}
                            reload={handleReload}
                            setFilter={setfilters}
                            handleCleanFilter={() => {
                                cleanFilters();
                                handleReload()
                            }}
                            callFilter={() => {
                                // getVariants(getRequestFilters(), 1, paginator.itemsPerPage)
                                paginator.setActualPage(0)
                                handleReload()
                            }}
                            inputFilters={inputFilterData}
                        // setLaunchFilter={handleLaunchFilter}
                        /> */}
                    </div>
                }
                FiltersEmpty={
                    <div style={{ width: "100%" }}>
                        {/* <HomeFilter inputFilters={inputFilterData} /> */}
                    </div>
                }
                Toolkit={
                    <HomeToolkit rowData={(variantsComments.data?.results ?? [])}
                        rowDataSelected={variantsSelecteds}
                        handleSelectAll={handleSelectAll}
                        multipleActionsOptions={[
                            {
                                icon: faTrash,
                                label: translate('home.delete'),
                                handleClick: () => {
                                    if (variantsComments.data?.reports.length > 0) {
                                        VariantS.uncomment_variants(+props.caseId, variantsSelecteds.map((x: any) => x))
                                            .then((x: any) => {
                                                setvariantsSelecteds([]);
                                                handleReload();
                                            })
                                    }
                                },
                                modalWarning: {
                                    label: translate("commentedVariants.confirmDeleteAllSelectedComments"),
                                    buttonLabel: translate('home.delete')
                                }
                            },
                            {
                                icon: faComment,
                                label: translate('common.comment'),
                                handleClick: () => handleCommentVariant(),
                            },
                        ]}
                        sortBy={{
                            value: filters.sortColumn,
                            options: [{ label: translate('common.relevanceFlag'), value: "state" }, "default"],
                            handleSortBy: handleSortByFilter
                        }}
                        exportActions={[
                            {
                                label: `${translate("common.allVariantsInResult")} (xls)`,
                                href: ReportS.get_downloadAllVariantCommentXls_link(props.caseId)                           //Falta terminar este servicio
                            }, {
                                label: `${translate("common.selectedVariants")} (xls)`,
                                disabled: variantsSelecteds.length === 0,
                                href: ReportS.get_downloadVariantCommentXls_link(props.caseId, variantsSelecteds.map((x: any) => `${x}`)), //Falta terminar este servicio
                            }
                        ]}
                    />
                }
                Table={
                    <Card style={{ borderRadius: "0px" }}>
                        <Card.Body style={{ padding: "0px" }}>
                            {/* // {(variantsComments.loaded && (variantsComments.data?.results ?? []).length === 0) ? */}
                            {(variantsComments.loaded && (variantsComments.data?.results ?? []).length === 0) ?
                                <div>
                                    <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                        {translate('commentedVariants.noCommentsFound')}
                                    </p>
                                </div> :
                                !variantsComments.loaded && variantsComments.loading ?
                                    <Loader onContainer={true} type={'DNA'} /> :
                                    <CommentsTable history={props.history}
                                        comments={variantsComments}
                                        variantsSelecteds={variantsSelecteds}
                                        handleVariantsSelecteds={handleVariantsSelecteds}
                                        handleReload={handleReload}
                                        handleCommentVariant={handleCommentVariant}
                                        handleDeleteComment={handleShowRemoveCommentModal}
                                        studyId={`${props.caseId}`}
                                    />
                            }
                        </Card.Body>
                        {(variantsComments.data?.results ?? []).length > 0 ?
                            <Card.Footer>
                                {paginator.component}
                            </Card.Footer>
                            : <></>}
                    </Card>

                }
            />
        </>
    )
}

