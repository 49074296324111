import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { VariantsOrderByValueT } from '../utils/BioFunctionalData';
import { AbstractService } from './AbstractService';

export default class VariantServices extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "variant/";
    }

    async read_variant(studyId: number, variantId: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getVariant`
        const config = {
            ...this.authHeader,
            params: {
                domainId: this.user?.domain,
                studyId: studyId,
                id: variantId,
                page: 1,
                label: "advancedQuery",
                _: (new Date()).getTime()
            }
        }
        // console.log(config)
        return await axios.get(url, config)
    }
    async read_variants(studyId: number, variantsIds: string, sortBy?: VariantsOrderByValueT): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getVariants`
        const config = {
            ...this.authHeader,
            params: {
                domainId: this.user?.domain,
                studyId: studyId,
                variantIds: variantsIds,
                sortColumn: sortBy ?? "infoColumnData.INTERVAR_VEREDICT",
                sortDirection: "ASC",
                _: (new Date()).getTime()
            }
        }
        // console.log(config)
        return await axios.get(url, config)
    }
    async read_all_variants(studyId: string, geneName: string, variantsId: string, page: number = 1, pageSize: number = 25, sortBy: string = "infoColumnData.INTERVAR_VEREDICT"): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getAllVariants`
        const data = {
            domainId: this.user?.domain,
            studyId: `${studyId}`,
            geneName: geneName,
            variantIds: variantsId,
            label: "geneDetail",
            page: `${page}`,
            pageSize: pageSize,
            sortColumn: sortBy,
            sortDirection: "ASC"
        }
        return await axios.post(url, data, this.authHeader)
    }
    async comment_variants(studyId: string, variantsId: string, comment: string, variantState: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}commentVariants`
        const data = {
            domainId: `${this.user?.domain}`,
            userId: `${this.user?.id}`,
            studyId: `${studyId}`,
            variantsToComment: `${variantsId}`,
            commentText: comment,
            variantState: `${variantState}`,
        }
        return await axios.post(url, data, this.authHeader)
    }
    async uncomment_variants(studyId: number, variantsIds: string[]): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}uncommentVariants`

        const data = {
            domainId: `${this.user?.domain}`,
            userId: `${this.user?.id}`,
            studyId: `${studyId}`,
            variantsToUnComment: variantsIds.join()
        }
        return await axios.post(url, data, this.authHeader)
    }
    async classify_variant(studyId: string, variantsIds: string[], acmgBayScore: string, acmgBayVeredict: string, intervarEvidence: string[]) {
        const url = `${this.basePath}classifyVariant`
        const data = {
            domainId: `${this.user?.domain}`,
            userId: `${this.user?.id}`,
            studyId: `${studyId}`,
            variantId: variantsIds.join(),
            ACMG_BAYESIAN_SCORE: acmgBayScore,
            ACMG_BAYESIAN_VEREDICT: acmgBayVeredict,
            intervarEvidence: intervarEvidence.join()
        }
        return await axios.post(url, data, this.authHeader)
    }
    async readAllCommmentVariants(studyId: string, sortColumn: string, page: number = 1, pageSize: number = 25): Promise<AxiosResponse<any>> {
        // Request URL: http://10.100.100.55/bioflux-platform-gaip-facade/variant/allCommentVariants?studyId=691&domainId=26&pageNumber=1&pageSize=25&_=1650635624204
        const url = `${this.basePath}allCommentVariants`
        const config = {
            ...this.authHeader,
            params: {
                studyId: studyId,
                sortColumn: sortColumn,
                pageNumber: `${page}`,
                pageSize: pageSize,
                domainId: this.user?.domain,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }

    async getGenesCoverage(samplesNames: string, studyId: string, geneName?: string, panel?: string, page: number = 1, pageSize: number = 25): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getGenesCoverage`
        let data: any = {
            samplesNames, studyId, domainId: this.user?.domain,
            page: `${page}`,
            pageSize: pageSize
        }
        if (geneName) data = { ...data, symbols: geneName.replaceAll(/\s+/g,",")};
        if (panel) data = { ...data, genePanelName: panel }
        return await axios.post(url, data, this.authHeader)
    }

    async getClinvarCoverage(symbols: string, panel: string, samplesNames: string, studyId: string, page: number = 1, pageSize: number = 25): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getClinvarCoverage`
        const data = {
            symbols, samplesNames, studyId, domainId: this.user?.domain,
            genePanelName: panel,
            page: `${page}`,
            pageSize: pageSize
        }
        // console.log(url)
        return await axios.post(url, data, this.authHeader)

    }
}