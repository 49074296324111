import { createContext } from "react";
import { AsyncPagesI, StudyI, AsyncDataI } from "../interfaces";

interface FiltersCasesI {
    studyType: string | null,
    studyState: string,
    dateCreatedFromSearch: string,
    dateCreatedToSearch: string,
    descriptionSearch: string,
    nameSearch: string,
    sortColumn: string
}
interface ContextCasesStatesI {
    study: AsyncDataI & { data: StudyI | null },
    cases: AsyncDataI & {
        data: null | (AsyncPagesI & {
            result: AsyncPagesI & {
                results: StudyI[]
            }
        }),
    },
    variantGroups: AsyncDataI & { data: any[] },    //TODO especificar data con interface
    filters: FiltersCasesI
    selecteds: string[],

}
interface ContextPropsCasesI extends ContextCasesStatesI {
    dispatch: {
        getCases: (filters?: FiltersCasesI, pageNumber?: number, pageSize?: number) => Promise<void>;
        refreshCases: () => void;
        getCase: (id: string) => Promise<void>;
        setFilters: (key: string, value: string) => boolean;
        cleanFilters: () => void;
        handleSelecteds: (id: string) => void;
        handleSelectAll: () => void;
    }
}

const CasesContext = createContext({} as ContextPropsCasesI);

export default CasesContext;
export type { FiltersCasesI, ContextCasesStatesI, StudyI }