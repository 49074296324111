import React from "react";
import { useContext } from "react";
import ThemeContext from "src/Contexts/Theme/ThemeContext";

interface PredictorsSubtitleI {
    subtitle: string,
    style?: React.CSSProperties,
}
export default function PredictorsSubtitle(props: PredictorsSubtitleI) {
    const { theme } = useContext(ThemeContext);
    return (
        <h6 style={{
            margin: "8px 5px 0px",
            color: theme.grayHeader,
            fontWeight: "bold",
            fontSize: "15px",
            ...(props.style ?? {})
        }}>
            {props.subtitle}
        </h6>
    )

}