import React from 'react';
import { Card, Table } from "react-bootstrap"
import { useEffect, useState } from "react";
import { ModalSamplesIgv, SampleInfoIgvDataI } from "../../../IGV/ModalSamplesIgv";
import { StudyI } from "../../../../Contexts/interfaces";
import Paginator from "src/Components/Common/Paginator/Paginator";
import VariantsClinvarTableRows from "./VariantsClinvarTableRows";
// import VariantsClinvarTableHeaders from "./VariantsClinvarTableHeaders";

type CoverageVariantsColT = "gene" | "variant" | "rsId" | "sampleAlleles" | "zygosity" | "coverage" | "onTarget" | "classification" | "clinvarId" | "sampleInfo"

interface VariantsClinvarTableI {
    variantClinvar: any[],
    study: StudyI,
    cardStyles?: any,
    studyId: string,
    studyName: string,
    settableMounted(val: boolean): void,
    reloadOnFirstPage?: boolean,
    setreloadOnFirstPage(i: boolean): void,
}
export default function VariantsClinvarTable(props: VariantsClinvarTableI) {

    const [variantClinvarRows, setvariantClinvarRows] = useState<any[]>([]);

    const [showModalSamplesIgv, setShowModalSamplesIgv] = useState<{ show: boolean, data?: SampleInfoIgvDataI }>({ show: false, data: undefined });
    const handleCloseModalSamplesIgv = () => setShowModalSamplesIgv({ show: false, data: undefined });
    const handleShowModalSamplesIgv = (i: SampleInfoIgvDataI) => {
        setShowModalSamplesIgv({
            show: true,
            data: {
                ...i,
                igvData: i.igvData ? {
                    ...i.igvData,
                    library: props.study.libraryPrepKit
                } : undefined
            }
        });
    }
    const studyType = (props.study?.type ?? "").toLowerCase().trim()
    const paginator = Paginator({
        reloadCall: () => { },
        onChangePage: (i: any) => {
            paginator.setActualPage(i)
            props.settableMounted(false)
        },
        onItemsPerPageChange: (i: any) => {
            paginator.setActualPage(0)
            props.settableMounted(false)
        }
    })
    useEffect(() => {
        if (props.reloadOnFirstPage) {
            paginator.setActualPage(0);
            props.setreloadOnFirstPage(false);
            props.settableMounted(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reloadOnFirstPage])

    useEffect(() => {
        paginator.pageSize_set(props.variantClinvar.length)
        let newGenesRows = props.variantClinvar.filter((x: any, i: number) => {
            return (
                i >= (paginator.actualPage * paginator.itemsPerPage) &&
                i < (paginator.actualPage + 1) * paginator.itemsPerPage
            )
        })
        setvariantClinvarRows(newGenesRows)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, paginator.actualPage, paginator.itemsPerPage])


    // console.log(props.regions)
    return (
        <>
            {(studyType === "multiple" || studyType === "familiar" || (props.study?.hasBam ?? false)) &&
                (showModalSamplesIgv?.data?.chromosome && showModalSamplesIgv?.data?.position && showModalSamplesIgv?.data?.gen) &&
                <ModalSamplesIgv
                    caseId={`${props.study.id}`}
                    show={showModalSamplesIgv.show}
                    handleClose={handleCloseModalSamplesIgv}
                    mood={showModalSamplesIgv?.data?.mood ?? "sample"}
                    chromosome={showModalSamplesIgv?.data?.chromosome}
                    position={showModalSamplesIgv?.data?.position}
                    gen={showModalSamplesIgv?.data?.gen}
                    sampleData={showModalSamplesIgv.data?.sampleData}
                    igvData={showModalSamplesIgv.data?.igvData}
                />
            }

            {props.variantClinvar.length === 0 ? <></> :
                <Card style={{
                    borderRadius: "0px",
                    border: "none",
                    width: "100%",
                    boxShadow: "rgb(200 200 200) 0px 5px 4px",
                    marginBottom: "25px",
                    ...(props.cardStyles ?? {})
                }}>
                    <Card.Body style={{ padding: "0px", width: "100%", overflowX: "auto" }}>
                        <Table responsive="sm" hover striped className="selectTable"
                            style={{ marginBottom: 0 }}>
                            {/* <VariantsClinvarTableHeaders study={props.study} /> */}
                            <tbody>
                                <VariantsClinvarTableRows data={variantClinvarRows}
                                    study={props.study}
                                    studyId={props.studyId}
                                    handleShowModalSamplesIgv={handleShowModalSamplesIgv}
                                    studyName={props.studyName}
                                    settableMounted={props.settableMounted}

                                />
                            </tbody>
                        </Table >
                    </Card.Body>
                    {(variantClinvarRows ?? []).length > 0 ?
                        <Card.Footer>
                            {paginator.component}
                        </Card.Footer>
                        : <></>}

                </Card>
            }
        </>
    )
}


function getColumnClinvarVariantId(studyName: string, colType: CoverageVariantsColT, index?: number): string {
    return `${studyName}_${colType}_${index || index === 0 ? index : "header"}`;
}

export { getColumnClinvarVariantId }
export type { CoverageVariantsColT }