import axios, { AxiosResponse } from 'axios';
import { RollesT } from '../Contexts/interfaces';
import { AbstractService } from './AbstractService';

export default class UserService extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "user/";
    }
    async updateUser(id: string, username: string, fullName: string, email: string, domainId: string, enableuser: boolean, roll?: RollesT): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}update`
        // id: "93"
        // username: "TTTTTTTT"
        // fullName: "TTTTTTTTTT"
        // email: "ttttt@ttttt.com"
        // enableuser: "on"
        // roles: "ROLE_GUEST"
        // userById: "89"
        // domainId: "26"
        // loggedUserDomainId: 26
        // loggedUserId: 89
        let data: any = {
            "id": id,
            "username": username,
            "fullName": fullName,
            "email": email,
            "domainId": domainId,
            "roles": roll,
            "loggedUserDomainId": +(this.user?.domain??"0"),
            "loggedUserId": +(this.user?.id??"0"),
            "userById": `${this.user?.id}`,
        }
        if (enableuser) data = { ...data, "enableuser": enableuser }
        return axios.post(url, data, this.authHeader)
    }
    async updateLoggedUser(userName: string, fullName: string, email: string): Promise<AxiosResponse<any>> {
        return this.updateUser(`${this.user?.id}`, userName, fullName, email, `${this.user?.domain}`, true, this.user?.rolestoString)
    }
    async updatePass(id: string, domainId: string, newPassword: string, confirmNewPassword: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}updatePassword`
        // id: "93"
        // domainId: "26"
        // newPassword: "Lalala12"
        // confirmNewPassword: "Lalala12"
        const data = {
            "domainId": id,
            "id": domainId,
            "newPassword": newPassword,
            "confirmNewPassword": confirmNewPassword
        }
        return axios.post(url, data, this.authHeader)
    }
    async updateLoggedPass(oldPassword: string, newPassword: string, confirmNewPassword: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}changePassword`
        const data = {
            "domainId": `${this.user?.domain}`,
            "id": `${this.user?.id}`,
            "oldPassword": oldPassword,
            "newPassword": newPassword,
            "confirmNewPassword": confirmNewPassword
        }
        return axios.post(url, data, this.authHeader)
    }
    async getUser(): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getUser`
        const config = {
            ...this.authHeader,
            params: {
                id: this.user?.id,
                userId: this.user?.id,
                domainId: this.user?.domain,
                _: (new Date()).getTime()
            }
        }
        return axios.get(url, config)
    }
    async getUsers(filters?: { username: string, fullname: string, sortColumn: string }, pageNumber: number = 1, pageSize: number = 25): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}search`
        const data = {
            domainId: this.user?.domain.toString(),
            userId: this.user?.id,
            pageNumber: pageNumber,
            pageSize: pageSize,
            usernameSearch: filters?.username ?? "",
            fullnameSearch: filters?.fullname ?? "",
            sortColumn: filters?.sortColumn ?? "",
        }
        return await axios.post(url, data, this.authHeader)
    }

    async deleteUser(userId: string): Promise<AxiosResponse<any>> {
        // id: "85"
        // loggedUserId: 51    
        const url = `${this.basePath}delete`
        const data = {
            "id": userId,
            "loggedUserId": this.user?.id,
        }
        return axios.post(url, data, this.authHeader)
    }
    async createUser(userName: string, fullName: string, email: string, roll: RollesT, domainId?: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}save`
        const data = {
            "username": userName,
            "fullName": fullName,
            "email": email,
            "roles": roll,
            "userById": `${this.user?.id}`,
            "domainId": `${domainId ? domainId : this.user?.domain}`,
        }
        return axios.post(url, data, this.authHeader)
    }
    async getUserAndAllRoles(userId: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getUserAndAllRoles`
        const config = {
            ...this.authHeader,
            params: {
                id: userId,
                _: (new Date()).getTime()
            }
        }
        return axios.get(url, config)
    }
    async blockUser(userId: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}block?id=${userId}`
        return axios.get(url, this.authHeader)
    }
    async unblockUser(userId: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}unblock?id=${userId}`
        return axios.get(url, this.authHeader)
    }


}
