import React from 'react';
import { useContext } from "react";
import { LangContext } from "../../../../../Contexts/Lang";
import { StudyI } from "../../../../../Contexts/interfaces";
import ThemeContext from '../../../../../Contexts/Theme/ThemeContext';
import { CoverageGenesColT, getColumnId } from "./GeneTable";

interface GenesHeadersI {
    study: StudyI,
    geneName: string,
    studyName: string
}

export default function GenesHeaders(props: GenesHeadersI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const getWidthByCol = (colType: CoverageGenesColT): string => {
        let colWidth: any = "auto"
        const colId = getColumnId(props.studyName, props.geneName, colType, 0)
        const element = document.getElementById(colId)
        if (element) {
            // console.log("element")
            colWidth = element.getBoundingClientRect().width ? (`${element.getBoundingClientRect().width}`.split(".")[0] + "px") : colWidth
        }
        return colWidth
    }

    return (
        <thead >
            <tr style={{ backgroundColor: "#EEEEEE", color: theme.grayHeader, fontSize: 10, textTransform: "uppercase" }}>
                <th id={getColumnId(props.studyName, props.geneName, "transcript")}
                    style={{
                        minWidth: getWidthByCol("transcript")
                    }}>
                    {/* {translate("common.transcript")} */}
                    Id Ensembl
                </th>
                <th id={getColumnId(props.studyName, props.geneName, "region")} style={{ minWidth: getWidthByCol("region") }}>
                    {translate("common.region")}
                </th>
                <th id={getColumnId(props.studyName, props.geneName, "meanDepth")} style={{ minWidth: getWidthByCol("meanDepth") }}>
                    {translate("common.meanDepth")}
                </th>
                <th id={getColumnId(props.studyName, props.geneName, "coverage")} style={{ minWidth: getWidthByCol("coverage") }}>
                    {`% ${translate("common.coverage")}`}
                </th>
                {
                    (props.study?.hasBam ?? false) &&
                    <th id={getColumnId(props.studyName, props.geneName, "alignment")} style={{ minWidth: getWidthByCol("alignment") }}>
                        {translate("casesQuery.viewAlignment")}
                    </th>
                }
            </tr>
        </thead>
    )
}
