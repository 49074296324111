import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { VariantsOrderByValueT } from '../utils/BioFunctionalData';
import { AbstractService } from './AbstractService';

export default class ReportServices extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "report/";
    }

    //url='bioflux-platform-gaip-facade/report/downloadCoverageFile?token='+parameters;
    async donwload(sampleId: number): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}downloadCoverageFile`
        const config = {
            ...this.authHeader,
            params: {
                token: this.user?.token,
                domainId: this.user?.domain,
                sample: sampleId
            }
        }
        return await axios.get(url, config)
    }
    get_downloadResultXls_link(urlQueryString: string, sortColumn?: VariantsOrderByValueT, sortDirection?: string) {
        let url = `${this.basePath}downloadResultXls?`
        url += `token=${this.user?.token}`
        url += `&domainId=${this.user?.domain}`
        url += `&selectAllSample=on`
        url += `&sortDirection=${sortDirection ?? "ASC"}`
        url += `&sortColumn=${sortColumn ?? "infoColumnData.INTERVAR_VEREDICT"}`
        url += `&${urlQueryString}`
        return url
    }
    get_downloadGenesToCsv_link(urlQueryString: string, sortColumn?: VariantsOrderByValueT, sortDirection?: string) {
        let url = `${this.basePath}downloadGenesToCsv?`
        url = `${url}token=${this.user?.token}`
        url = `${url}&domainId=${this.user?.domain}`
        url = `${url}&selectAllSample=on`
        url = `${url}&sortDirection=${sortDirection ?? "ASC"}`
        url = `${url}&sortColumn=${sortColumn ?? "infoColumnData.INTERVAR_VEREDICT"}`
        url = `${url}&${urlQueryString}`
        return url
    }
    get_downloadSelectedXls_link(urlQueryString: string, variantIds: string, sortColumn?: VariantsOrderByValueT, sortDirection?: string) {
        let url = `${this.basePath}downloadSelectedXls?`
        url = `${url}token=${this.user?.token}`
        url = `${url}&domainId=${this.user?.domain}`
        url = `${url}&variantIds=${variantIds}`
        url = `${url}&selectAllSample=on`
        url = `${url}&sortDirection=${sortDirection ?? "ASC"}`
        url = `${url}&sortColumn=${sortColumn ?? "infoColumnData.INTERVAR_VEREDICT"}`
        if (urlQueryString !== "")
            url = `${url}&${urlQueryString}`
        return url
    }
    get_downloadSelectedVariantsToVcf_link(studyId: string, variantIds: string) {
        let url = `${this.basePath}downloadSelectedVariantsToVcf?`
        url = `${url}token=${this.user?.token}`
        url = `${url}&domainId=${this.user?.domain}`
        url = `${url}&studyId=${studyId}`
        url = `${url}&type=NGS`
        url = `${url}&variantIds=${variantIds}`
        return url
    }
    get_downloadResultVariantsToVcf_link(urlQueryString: string, sortColumn?: VariantsOrderByValueT, sortDirection?: string) {
        let url = `${this.basePath}downloadResultVariantsToVcf?`
        url = `${url}token=${this.user?.token}`
        url = `${url}&domainId=${this.user?.domain}`
        url = `${url}&type=NGS`
        url = `${url}&sortDirection=${sortDirection ?? "ASC"}`
        url = `${url}&sortColumn=${sortColumn ?? "infoColumnData.INTERVAR_VEREDICT"}` // infoColumnData.INTERVAR_VEREDICT, infoColumnData.EFFECT
        url = `${url}&${urlQueryString}`
        return url
    }

    get_downloadVariantInfoResultXls_link() {
        let url = `${this.basePath}downloadVariantInfoResultXls?`
        url = `${url}token=${this.user?.token}`
        url = `${url}&domainId=${this.user?.domain}`
        url = `${url}&sortDirection=ASC`
        return url
    }
    get_downloadSelectedVariantInfoXls_link(variantIds: string[]) {
        let url = `${this.basePath}downloadSelectedVariantInfoXls?`
        url = `${url}token=${this.user?.token}`
        url = `${url}&domainId=${this.user?.domain}`
        url = `${url}&sortDirection=ASC`
        url = `${url}&variantIds=${variantIds.join(",")}`
        return url
    }

    get_downloadVariantCommentXls_link(studyId: string, variantIds: string[]) {
        let url = `${this.basePath}downloadVariantCommentXls?`
        url = `${url}token=${this.user?.token}`
        url = `${url}&domainId=${this.user?.domain}`
        url = `${url}&studyId=${studyId}`
        url = `${url}&sortDirection=ASC`
        url = `${url}&sortColumn=`
        url = `${url}&variantIds=${variantIds.join(",")}`
        return url
    }
    get_downloadAllVariantCommentXls_link(studyId: string ) {
        let url = `${this.basePath}downloadAllVariantCommentXls?`
        url = `${url}token=${this.user?.token}`
        url = `${url}&domainId=${this.user?.domain}`
        url = `${url}&studyId=${studyId}`
        url = `${url}&sortDirection=ASC`
        return url
    }
    get_downloadCnvToCsv_link(studyId: string ) {
        let url = `${this.basePath}downloadCnvToCsv?`
        url = `${url}token=${this.user?.token}`
        url = `${url}&domainId=${this.user?.domain}`
        url = `${url}&studyId=${studyId}`
        return url
    }
}