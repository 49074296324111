import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '../Tooltip/Tooltip';

interface LinkTooltipI {
    label: string | JSX.Element,
    tooltipLabel: string,
    href: string,
    externalLink?: boolean,
    fontSize?: string,
}
export default function LinkTooltip(props: LinkTooltipI) {
    const externalinnkFont = props.fontSize &&
        (props.fontSize.match(/\d+/g) ?? "").length > 0 ?
        `${(+(props.fontSize.match(/\d+/g) ?? "11")[0] - 2)}px` :
        "auto"
    return (
        <Tooltip label={props.tooltipLabel}>
            <a href={props.href}
                style={{ fontSize: (props.fontSize ?? "auto") }}
                target="_blank"
                rel="noreferrer"
                onClick={(e: any) => e.stopPropagation()}>
                {props.label}
                {
                    (props.externalLink ?? true) &&
                    <FontAwesomeIcon icon={faExternalLinkAlt}
                        style={{
                            fontSize: (props.fontSize ? externalinnkFont : "auto"),
                            margin: "0 2px 0 3px",
                            color: "#009EEA",
                            verticalAlign: externalinnkFont ? "auto" : "text-top"
                        }} />
                }
            </a>
        </Tooltip>
    )
}
