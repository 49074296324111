import React from 'react';
import { Tooltip } from "../Components/Common/Tooltip/Tooltip";

interface urlifyI {
    text: string,
    asParegraph?: boolean,
}

function Urlify(props: urlifyI) {
    function urlify(text: string) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex)
            .map((x: string, i: number) => {
                if (x.match(urlRegex)) { return <a href={x} onClick={(e: any) => { e.stopPropagation() }} key={-i - 1} target="_blank" rel="noreferrer">{x}</a>; }
                return x;
            });
    }
    if (props.asParegraph) {
        const res: any = urlify(props.text).map((x: any, i: number) => {
            if (typeof x === 'string' || x instanceof String) x = x.split("\n").map((y: any) => <span key={i} style={{ padding: 0, margin: 0 }}>{y}<br /></span>)
            return x
        })
        return <p style={{ maxHeight: "150px", overflowX: "auto", margin: 0, padding: "5px" }}>{res}</p>
    }
    return <span>{urlify(props.text)}</span>
}

function Chunkfy(array: any[], max: number) {
    // chunkfy example: x = [1,2,3,4,5,6,7,8,9,10,11,12]
    // Chunkfy(x, 7) => [[7],[6]]
    // Chunkfy(x, 6) => [[5],[4],[4]]
    // Chunkfy(x, 5) => [[5],[4],[4]]
    // Chunkfy(x, 4) => [[4],[3],[3],[3]]
    // Chunkfy(x, 3) => [[3],[3],[3],[2],[2]]
    let
        length = Math.ceil(array.length / max),
        size = Math.ceil(array.length / length);

    return Array.from(
        { length },
        (_, i) => (
            // eslint-disable-next-line no-sequences
            array.length === size * length - length + i && size--,
            array.slice(size * i, size * (i + 1))
        )
    );
}

function checkKeyEnterPress(e: any, handler: Function) {
    if (e.keyCode === 13) handler()
}

function createTxtFile(name: string, content: string) {
    const element = document.createElement("a");
    const file = new Blob([content], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${name}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

function roundTooltip(num: any, decimals: number = 3): JSX.Element | null {
    if (!isNaN(+num)) {
        let numRound = (+num).toFixed(decimals)
        if (`${num}`.length <= 5) return num
        if (`${num}`.indexOf("0.000") > -1) { numRound = "0.0" } return (
            <Tooltip label={num}>
                <label style={{ padding: 0, margin: 0 }}>
                    {numRound}
                </label>
            </Tooltip>
        )
    }
    return null
}
function roundWithTooltipoTo0(num: any, decimals: number = 3): JSX.Element | null {
    if (!isNaN(+num)) {
        let numRound = (+num).toFixed(decimals)
        if (`${num}`.length <= 5) return num
        if (`${num}`.indexOf("0.000") > -1) {
            numRound = "0.0"
            return (
                <Tooltip label={num}>
                    <label style={{ padding: 0, margin: 0 }}>
                        {numRound}
                    </label>
                </Tooltip>
            )
        }
        return (
            <label style={{ padding: 0, margin: 0 }}>
                {numRound}
            </label>
        )
    }
    return null
}


const aminoacidsDict: any = {
    "ala": "A", "arg": "R", "asn": "N", "asp": "D", "cys": "C", "gln": "Q", "glu": "E", "gly": "G", "his": "H", "ile": "I",
    "leu": "L", "lys": "K", "met": "M", "phe": "F", "pro": "P", "pyl": "O", "ser": "S", "sec": "U", "thr": "T", "trp": "W",
    "tyr": "Y", "val": "V", "asx": "B", "glx": "Z", "xaa": "X", "xle": "J"
}

export { Urlify, Chunkfy, aminoacidsDict, checkKeyEnterPress, createTxtFile, roundTooltip, roundWithTooltipoTo0 }