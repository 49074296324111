import React from 'react';
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useContext } from "react";
import { LangContext } from "../../../Contexts/Lang";
import { Popover } from "../Popover";
import { evidenciaAcmg, evidenciaAcmgWeights } from "../../../utils/BioFunctionalData";
import ThemeContext, { BPlatformThemeI } from 'src/Contexts/Theme/ThemeContext';

interface BadgeVariantEvidenceI {
    labelInput: string,
    id: number,
    size?: "small" | "big",
    fontSize?: number,
}

export default function BadgeVariantEvidence(props: BadgeVariantEvidenceI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const size = props.size ?? "small"
    if (props.labelInput.trim().length === 0) return <span key={props.id}></span>
    const labelSpl = props.labelInput.split(":");
    let weight = ""
    let criteria = labelSpl[0].toUpperCase().trim();
    if (labelSpl.length > 1) {
        weight = labelSpl[1].toUpperCase().trim();
    }
    let weightlabelArr = evidenciaAcmgWeights.filter(x => x.value === `${weight}`.trim().toUpperCase())
    let weightlabel = weightlabelArr.length > 0 ? `  ${weightlabelArr[0].label}` : "";
    const label = props.labelInput.trim().toUpperCase();
    let backColor = getColor(theme, criteria, weight);
    const badge = (
        < Badge key={props.id}
            style={{
                fontWeight: "bold",
                fontSize: props.fontSize ?? "13px",
                margin: "1px 2px",
                color: "white",
                alignSelf: "center",
                backgroundColor: backColor
            }}>
            {size === "small" ?
                <>
                    {criteria}
                    <span style={{
                        fontSize: (props.fontSize ? props.fontSize - 2 : 11),
                        padding: 0,
                        margin: 0,
                        verticalAlign: "middle"
                    }}> {weightlabel}</span>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: "10px", margin: "1px 5px", verticalAlign: "text-bottom" }} />
                </> :
                <b>{label}</b>
            }
        </Badge>
    )
    if (size === "small") {
        const labelsplited = label.split(":");
        if (evidenciaAcmg.indexOf(labelsplited[0].trim()) === -1) return <></>/////////// sacar esto de aca poner al principio
        const content = translate(`casesQuery.intervarEvidence.${labelsplited[0].trim()}`)
        return <Popover title={labelsplited[0] + (labelsplited.length > 1 ? ` : ${labelsplited[1]}` : "")}
            key={props.id}
            content={content}
            trigger={"hover"} style={{ fontSize: "10px", zIndex: "1050" }}>
            <span>
                {badge}
            </span>
        </Popover>;
    }
    return badge
}

const getColor = (theme: BPlatformThemeI, criteria: string, weight: string): string => {
    if (weight.length < 2) return getDefaultColor(theme, criteria);
    if (criteria.startsWith("P")) {
        switch (weight.trim().toUpperCase()) {
            case "VERYSTRONG": return theme.acmg_crit_p_vs;
            case "STRONG": return theme.acmg_crit_p_str;
            case "SUPPORTING": return theme.acmg_crit_p_sup;
            case "MODERATE": return theme.acmg_crit_p_mod;
        }
    } else if (criteria.startsWith("B")) {
        switch (weight.trim().toUpperCase()) {
            case "STRONG": return theme.acmg_crit_b_str;
            case "SUPPORTING": return theme.acmg_crit_b_sup;
            case "MODERATE": return theme.acmg_crit_b_mod;
            case "STAND-ALONE": return theme.acmg_crit_b_sa;
            case "STANDALONE": return theme.acmg_crit_b_sa;
        }
    }
    return "gray";
}
const getDefaultColor = (theme: BPlatformThemeI, criteria: string): string => {
    switch (criteria.trim().substring(0, 2).toUpperCase()) {
        case "PV": return theme.acmg_crit_p_vs;
        case "PS": return theme.acmg_crit_p_str;
        case "PM": return theme.acmg_crit_p_mod;
        case "PP": return theme.acmg_crit_p_sup;
        case "BA": return theme.acmg_crit_b_sa;
        case "BS": return theme.acmg_crit_b_str;
        case "BP": return theme.acmg_crit_b_sup;
        case "BM": return theme.acmg_crit_b_mod;
        default: return "gray";
    }
}
