import React from 'react';
import { useRef, useState } from "react";
import { StudyI } from "../../../Contexts/interfaces";
import { Card, Tab, Table, Tabs } from "react-bootstrap";
import CircleLabel from "src/Components/Common/CircleLabel";
import VariantsClinvarTable from "./VariantsClinvarTable/VariantsClinvarTable";
import VariantsClinvarTableHeaders from "./VariantsClinvarTable/VariantsClinvarTableHeaders";

interface CoverageByVariantsResultsI {
    coverageReq: any,
    study: StudyI,
    studyId: string,
    reloadOnFirstPage: boolean,
    setreloadOnFirstPage(i: boolean): void,
}
export default function CoverageByVariantsResults(props: CoverageByVariantsResultsI) {
    const TableBodyRef: React.MutableRefObject<any> = useRef();
    const TableHeadersRef: React.MutableRefObject<any> = useRef();
    const handleScrollTableBody = (scroll: any): void => {
        const thScrollLeft = TableHeadersRef?.current?.scrollLeft;
        if (thScrollLeft || thScrollLeft === 0) TableHeadersRef.current.scrollLeft = scroll.target.scrollLeft;
    };
    const handleScrollHeadersTable = (scroll: any): void => {
        const tbScrollLeft = TableBodyRef?.current?.firstElementChild?.firstElementChild?.scrollLeft;
        if (tbScrollLeft || tbScrollLeft === 0) TableBodyRef.current.firstElementChild.firstElementChild.scrollLeft = scroll.target.scrollLeft;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tableMounted, settableMounted] = useState<boolean>(false)
    if ((props.coverageReq ?? []).length === 0) { return <></> }

    return (
        <div style={{ padding: "10px 15px 25px 15px" }}>
            <Tabs //defaultActiveKey="0"
                onSelect={() => settableMounted(false)}
                id="uncontrolled-tab-example" style={{ margin: 0 }}>
                {Object.keys(props.coverageReq ?? []).map((x: string, i: number) => {
                    return (
                        <Tab eventKey={`${i}`}
                            key={`${i}`}
                            title={<>{`${x} `} &nbsp;<CircleLabel label={props.coverageReq[x].length} size={1} backColor={"lightgray"} /></>}
                            style={{ padding: "10px", "backgroundColor": "#f4f4f4", borderTop: "solid 1px lightgray" }}
                        >
                            <Card id={`${i}`} style={{ borderRadius: 0 }}>
                                <Card.Body style={{ padding: "0px" }}>
                                    <div ref={TableHeadersRef}
                                        onScrollCapture={(e: any) => { if (handleScrollHeadersTable) handleScrollHeadersTable(e); }}
                                        style={{ overflowX: "auto" }}>
                                        <Table responsive="sm" style={{ marginBottom: 0 }}>
                                            <VariantsClinvarTableHeaders study={props.study}
                                                studyName={x}
                                                variantClinvar={props.coverageReq[x] ?? []}
                                                tableMounted={tableMounted}
                                            />
                                        </Table>
                                    </div>
                                    <div ref={TableBodyRef}
                                        onScrollCapture={(e: any) => { if (handleScrollTableBody) handleScrollTableBody(e); }}
                                        style={{ maxHeight: "500px", overflowX: "auto" }}>
                                        <VariantsClinvarTable variantClinvar={props.coverageReq[x] ?? []}
                                            study={props.study}
                                            cardStyles={{ boxShadow: "none", margin: 0 }}
                                            studyId={`${props.studyId}`}
                                            studyName={x}
                                            settableMounted={settableMounted}
                                            reloadOnFirstPage={props.reloadOnFirstPage}
                                            setreloadOnFirstPage={props.setreloadOnFirstPage}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab>
                    )
                })}
            </Tabs>

        </div >
    )
}