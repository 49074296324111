import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { LangContext } from "../../../../../Contexts/Lang";
import { SubMenu } from "react-pro-sidebar";
import 'react-pro-sidebar/dist/css/styles.css';
import "./sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faUndo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from "../../../../Common/Tooltip/Tooltip";
import { FilterInput, FilterSelect, FilterRangeSlider, FilterSlider, FilterRadioButtons, FilterExcludeCheckbox, FilterInformation } from "./FilterInputs"
import FormInput from '../../../../Common/Form/FormInput';
import { Button, Badge, Form, Row, Collapse } from 'react-bootstrap';
import { checkKeyEnterPress } from '../../../../../Helpers/Helpers';
import QueryContext from '../../../../../Contexts/Query/QueryContext';
import { getPredictorFlag } from 'src/Components/Common/Badges/BadgePredictorsVeredict';
import { CivicDbIcon, CosmicDbIcon, HotspotsDbIcon, OncokbDbIcon, PmkbDbIcon } from 'src/Components/Common/Somatic/DbLinks';

// const commonDivider = <div style={{ margin: 0, height: "10px" }}></div>
const CommonDivider = (props: { height?: number }) => <div style={{ margin: 0, height: props.height ?? 10 }}></div>

interface AccordionI {
	state: any,
	setState: any,
	initState: any,
}

interface FilterAccordionI {
	title: string,
	state: any,
	setState: any,
	initState: any,
	children: any,
	close?: boolean,
	cleanFilters?(): void,
}
function FilterAccordion(props: FilterAccordionI) {
	const { dispatch: { translate } } = useContext(LangContext);
	let accordionStyle: any = { padding: 0, fontSize: "14px", fontWeight: "bold" }
	let settedValues = Object.keys(props.state).filter((x: any, i: number) => {
		if (!props.state[x]) return false
		if (x.indexOf("exclude") > -1) return false
		if ((isNaN(props.state[x]) && isNaN(props.initState[x])) || props.state[x].toString() === "") {
			return (JSON.stringify(props.state[x]).trim() !== JSON.stringify(props.initState[x]).trim())
		}
		return parseFloat(props.state[x]) !== parseFloat(props.initState[x])
	})
	const [close, setclose] = useState<boolean | undefined>(undefined)
	const handleClose = () => {
		// console.log("lalal VER COLLAPSE")
		setclose(true)
		setTimeout(() => { setclose(undefined) }, 1000);
	}

	const AccordionSeted = settedValues.length > 0
	if (AccordionSeted) {
		accordionStyle = {
			padding: "0",
			color: "rgb(0,158,234)",
			fontSize: "16px",
			fontWeight: "bold"
		}
	}
	return (
		<>
			{AccordionSeted &&
				<Tooltip label={translate('common.cleanFilter')}>
					<Button
						variant="outline-secondary"
						style={{
							marginTop: "5px",
							position: "absolute",
							border: "none",
							borderRadius: "50px",
							right: "45px",
							zIndex: 1,
							padding: "6px 8px 4px 8px"
						}}
						onClick={(e: any) => {
							e.preventDefault();
							e.stopPropagation();
							// console.log(props.initState)
							if (props.cleanFilters) props.cleanFilters()
							else props.setState(props.initState)
						}}>
						<FontAwesomeIcon
							// icon={faBroom}
							icon={faUndo}
							style={{ color: "lightgray", fontSize: "14px" }} />
					</Button>
				</Tooltip>
			}
			<SubMenu title={
				`${props.title}${AccordionSeted ? ` (${settedValues.length})` : ""}`
			}
				style={{ ...accordionStyle }}
				onKeyDown={(e: any) => { checkKeyEnterPress(e, () => handleClose()) }}
			>
				<div //className={"collapse"}

					style={{
						fontSize: "12px",
						color: "#333333",
						fontWeight: "normal"
					}}>
					<Collapse in={!close ?? true}>
						<div>
							{props.children}
						</div>
					</Collapse>
					{/* </Collapse> */}
				</div>
			</SubMenu >
		</>
	)
}

interface SamplesAccordionI extends AccordionI {
	samples: any[],
	options: {
		diseaseModel: { label: any, value: any }[],
		operations: { label: any, value: any }[]
	},
	isFamiliar: boolean
}
function SamplesAccordion(props: SamplesAccordionI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const diseasModelIsInitialState = JSON.stringify(props.state.diseaseModel).trim() === JSON.stringify(props.initState.diseaseModel).trim();
	const operationIsInitialState = JSON.stringify(props.state.operations).trim() === JSON.stringify(props.initState.operations).trim();
	const [diseaseModelDisabled, setdiseaseModelDisabled] = useState<boolean>(!operationIsInitialState)
	const [operationDisabled, setoperationDisabled] = useState<boolean>(!diseasModelIsInitialState)
	const getSamplesChecked = () => props.state.samples.filter((x: any) => x.checked);
	useEffect(() => {
		if ((props.state?.samples ?? []).length === 0) return
		if (getSamplesChecked().length < 2) {
			if (!operationDisabled || !diseaseModelDisabled) {
				setdiseaseModelDisabled(true)
				setoperationDisabled(true)
			}
			if (!diseasModelIsInitialState || !operationIsInitialState) {
				props.setState({ ...props.state, diseaseModel: props.initState.diseaseModel, operations: props.initState.operations })
			}
		} else {
			if (!diseasModelIsInitialState) {
				if (!operationDisabled) {
					setoperationDisabled(true)
					props.setState({ ...props.state, operations: props.initState.operations })
				}
			} else if (!operationIsInitialState) {
				if (!diseaseModelDisabled) {
					setdiseaseModelDisabled(true)
					props.setState({ ...props.state, diseasModel: props.initState.diseasModel })
				}
			} else if (operationIsInitialState && diseasModelIsInitialState) {
				setdiseaseModelDisabled(false)
				setoperationDisabled(false)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state])

	const diseaseModelOpt = props.isFamiliar ? props.options.diseaseModel :
		props.options.diseaseModel.filter((x: any) =>
			x.value.indexOf("autosomalDominant") === -1 &&
			x.value.indexOf("autosomalRecessive") === -1);
	const getBadgesSamples = () => {
		const getCheckBadgeSample = (s: any, i: number) => {
			return (
				<span style={{ display: "flex" }} key={`${i}`}>
					<Form.Check type="checkbox"
						checked={s.checked}
						onChange={() => {
							props.setState(
								{
									...props.state,
									"samples": [
										...props.state.samples.map((x: any) => {
											if (x.value.name === s.value.name)
												return {
													label: s.label,
													value: s.value,
													checked: !s.checked
												}
											return x
										})
									]
								}
							)
						}}
					/>
					<Badge key={i}
						style={{
							fontWeight: "inherit",
							fontSize: "12px",
							color: "white",
							backgroundColor: s.value.affected ? "#FF4E58" : "darkgray",
							marginRight: "20px",
							marginBottom: "3px",
						}}>
						{s.label}
					</Badge>
				</span>
			)
		}
		return <Row style={{ display: "flex", margin: 0 }}>
			{(props.state.samples).map((s: {
				label: string,
				value: { name: string, value: string, affected: boolean },
				checked: boolean
			}, i: number) => {
				return getCheckBadgeSample(s, i)
			})}
		</Row>
	}
	return (
		<FilterAccordion
			title={translate('common.samples')}
			state={props.state}
			setState={props.setState}
			initState={props.initState}>
			{getBadgesSamples()}
			<div style={{ float: "right" }}>
				<Form.Check type="checkbox"
					checked={getSamplesChecked().length === props.state.samples.length}
					label={translate("common.selectAll")}
					onChange={() => {
						let selectAll = false;
						if (getSamplesChecked().length === props.state.samples.length) {
							selectAll = true
						}
						props.setState(
							{
								...props.state,
								"samples": [
									...props.state.samples.map((x: any) => {
										return {
											...x,
											checked: selectAll ? false : true
										}
									})
								]
							}
						)
					}} />
			</div>

			<hr style={{ marginTop: "25px" }} />
			<FilterSelect options={diseaseModelOpt}
				label={
					<div style={{ display: 'flex' }}>
						{translate('casesQuery.diseaseModels')}
					</div>
				}
				state={props.state}
				setState={props.setState}
				disabled={diseaseModelDisabled}
				name={"diseaseModel"} />
			{
				props.isFamiliar &&
				<>
					<CommonDivider />
					<FilterSelect options={props.options.operations}
						label={
							<div style={{ display: 'flex' }}>
								{translate('casesQuery.operation')}
							</div>
						}
						state={props.state}
						setState={props.setState}
						disabled={operationDisabled}
						name={"operations"} />
				</>
			}
			<CommonDivider />
			<CommonDivider />
			<p style={{ fontSize: "10px", color: "gray", marginLeft: "5px" }}>
				{translate("casesQuery.samplesHelp")}
			</p>
		</FilterAccordion>
	)
}

interface VariantAccordionI {
	isMitochondrialCase: boolean,
}
function VariantAccordion(props: VariantAccordionI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { filters_initialState, filters_variants, filters_variantsParams, queryCombos,
		dispatch: { setfilters_variants, cleanfilters_variants, } } = useContext(QueryContext);
	return (
		<FilterAccordion title={translate('common.variants')}
			state={filters_variants}
			setState={setfilters_variants}
			initState={filters_initialState?.filters_variants}
			cleanFilters={cleanfilters_variants}>
			<FilterInput type={'text'}
				state={filters_variants}
				setState={setfilters_variants}
				name={"code"}
				label={translate('casesQuery.variantCode')}
				description={translate('casesQuery.variantCodeHelp')} />
			<CommonDivider />
			<FilterSelect options={filters_variantsParams.relevance}
				label={
					<div style={{ display: 'flex' }}>
						{translate('casesQuery.relevanceFlag')}
						<Tooltip label={translate('common.relevanceOptions.INFO')}>
							<FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", fontSize: "13px", margin: "2px 5px 0px" }} />
						</Tooltip>
					</div>
				}
				state={filters_variants}
				setState={setfilters_variants}
				name={"relevance"}
				isMulti={true} />
			<FilterExcludeCheckbox
				label={translate('casesQuery.excludeComment')}
				state={filters_variants}
				setState={setfilters_variants}
				name={"excludeComments"} />
			<CommonDivider />
			{
				(queryCombos?.data?.study?.samples ?? []).length < 2 &&
				<FilterSelect options={filters_variantsParams.disease}
					label={translate('casesQuery.diseaseModels')}
					state={filters_variants}
					setState={setfilters_variants}
					name={"disease"} />
			}
			<CommonDivider />
			{!props.isMitochondrialCase &&
				<>
					<FilterSelect options={filters_variantsParams.genotype}
						label={translate('casesQuery.genotype')}
						state={filters_variants}
						setState={setfilters_variants}
						name={"genotype"} />
					<CommonDivider />
				</>
			}
			<FilterInput type={'text'}
				state={filters_variants}
				setState={setfilters_variants}
				name={"cromosome"}
				label={translate('casesQuery.chromosome')}
				description={"e.g: 19:45,409,011-45,412,650 / 19:45409011-45412650."} />
			<CommonDivider />
			<FilterSelect options={filters_variantsParams.variantTypes}
				label={translate('casesQuery.vartype')}
				state={filters_variants}
				setState={setfilters_variants}
				name={"variantTypes"}
				isMulti={true} />
			<CommonDivider />
			<FilterRadioButtons label={translate('casesQuery.multiplesAlleles')}
				state={filters_variants}
				setState={setfilters_variants}
				name={"multipleAlleles"}
				radios={filters_variantsParams.multipleAlleles}
			/>
		</FilterAccordion>
	)
}

function QualityCoverageAccordion(props: { isSomatic: boolean }) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { filters_initialState, filters_qc, filters_qcParams,
		dispatch: { setfilters_qc, cleanfilters_qc, } } = useContext(QueryContext);
	return (
		<FilterAccordion title={translate("casesQuery.filterBysamplequality")}
			state={filters_qc}
			setState={setfilters_qc}
			initState={filters_initialState?.filters_qc}
			cleanFilters={cleanfilters_qc}
		>
			<FilterRadioButtons label={translate("common.filter")}
				state={filters_qc}
				setState={setfilters_qc}
				name={"filter"}
				radios={filters_qcParams.filter}
			/>
			<hr />
			<FilterRangeSlider options={filters_qcParams.depth}
				state={filters_qc}
				setState={setfilters_qc}
				name={"depth"}
				label={
					<div style={{ display: "flex" }}>
						{translate("casesQuery.depthcoverage")}
						<RangeInputTitle stateName={"depth"}
							state={filters_qc}
							setState={setfilters_qc}
							rangeArr={filters_qcParams.depth}
						/>
					</div>
				}
				rangeButtonsInverted={true}
				rangeButtons={[
					{ label: '10X', value: 10 },
					{ label: '20X', value: 20 },
					{ label: '30X', value: 30 }
				]}
			/>
			<hr />
			{!props.isSomatic && <>
				<FilterSlider options={filters_qcParams.ratio}
					state={filters_qc}
					setState={setfilters_qc}
					name={"ratio"}
					label={<div style={{ display: "flex" }}>
						{translate("casesQuery.ratioAdDp")} ≥
						<SliderInputTitle stateName="ratio"
							state={filters_qc}
							setState={setfilters_qc} />
					</div>}
					doublePresicion={100} />
				<hr />
			</>
			}
			<FilterSlider options={filters_qcParams.genotypeQuality}
				state={filters_qc}
				setState={setfilters_qc}
				name={"genotypeQuality"}
				label={
					<div style={{ display: "flex" }}>
						{translate("casesQuery.genotypeQuality")} ≥
						<SliderInputTitle stateName="genotypeQuality"
							state={filters_qc}
							setState={setfilters_qc} />
					</div>
				}
				rangeButtons={[
					{ label: '80', value: 80 },
					{ label: '90', value: 90 },
					{ label: '99', value: 99 }
				]} />
			<hr />
			<FilterSlider options={filters_qcParams.qual}
				state={filters_qc}
				setState={setfilters_qc}
				name={"qual"}
				label={
					<div style={{ display: "flex" }}>
						QUAL ≥
						<SliderInputTitle stateName="qual"
							state={filters_qc}
							setState={setfilters_qc} />
					</div>
				} />
		</FilterAccordion>)
}

function FrequencyAccordion(props: any) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { filters_initialState, filters_frequency, filters_frequencyParams,
		dispatch: { setfilters_frequency, cleanfilters_frequency, } } = useContext(QueryContext);

	const isNullFreq = filters_frequency.frecGnomAdExomes.toString() === "0,0" &&
		filters_frequency.frecGnomAdGenomes.toString() === "0,0"

	return (
		<FilterAccordion title={translate("casesQuery.filterByFrequency")}
			state={filters_frequency}
			setState={setfilters_frequency}
			initState={filters_initialState?.filters_frequency}
			cleanFilters={cleanfilters_frequency}>
			<FilterRangeSlider options={filters_frequencyParams.frecGnomAdExomes}
				state={filters_frequency}
				setState={setfilters_frequency}
				name={"frecGnomAdExomes"}
				label={
					<div style={{ display: "flex" }}>
						{translate("casesQuery.gnomAdExomesFrequency")}
						<RangeInputTitle stateName={"frecGnomAdExomes"}
							state={filters_frequency}
							setState={setfilters_frequency}
							rangeArr={filters_frequencyParams.frecGnomAdExomes}
						/>
					</div>
				}
				rangeButtons={[
					{ label: '0.01', value: 0.01 },
					{ label: '0.03', value: 0.03 },
					{ label: '0.05', value: 0.05 }
				]}
				doublePresicion={filters_frequencyParams.frecGnomAdExomes.length - 1}
			/>
			<hr />
			<FilterRangeSlider options={filters_frequencyParams.frecGnomAdGenomes}
				state={filters_frequency}
				setState={setfilters_frequency}
				name={"frecGnomAdGenomes"}
				label={
					<div style={{ display: "flex" }}>
						{translate("casesQuery.gnomadGenomesFreq")}
						<RangeInputTitle stateName={"frecGnomAdGenomes"}
							state={filters_frequency}
							setState={setfilters_frequency}
							rangeArr={filters_frequencyParams.frecGnomAdGenomes}
						/>
					</div>
				}
				rangeButtons={[
					{ label: '0.01', value: 0.01 },
					{ label: '0.03', value: 0.03 },
					{ label: '0.05', value: 0.05 }
				]}
				doublePresicion={filters_frequencyParams.frecGnomAdGenomes.length - 1}
			/>
			<hr />
			<div id="FilterExcludeCheckbox" style={{ display: 'flex' }}>
				<Form.Check type="checkbox"
					checked={isNullFreq}
					onChange={() => {
						if (isNullFreq)
							setfilters_frequency({ "frecGnomAdExomes": [0, 1], "frecGnomAdGenomes": [0, 1] })
						else
							setfilters_frequency({ "frecGnomAdExomes": [0, 0.0], "frecGnomAdGenomes": [0, 0.0] })
					}}
				/>
				<p style={{ margin: "0 -3px 3px", color: "#333", alignSelf: "flex-end", fontSize: "11px" }}>
					Frecuencia nula
				</p>
			</div>
		</FilterAccordion >
	)
}

interface PredictionScoresAccordionI {
	isMitochondrialCase: boolean
}
function PredictionScoresAccordion(props: PredictionScoresAccordionI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { filters_initialState, filters_predictors, filters_predictorsParams,
		dispatch: { setfilters_predictors, cleanfilters_predictors, } } = useContext(QueryContext);

	return (
		<FilterAccordion title={translate("casesQuery.filterByfiltroscore")}
			state={filters_predictors}
			setState={setfilters_predictors}
			initState={filters_initialState?.filters_predictors}
			cleanFilters={cleanfilters_predictors}>
			{!props.isMitochondrialCase &&
				<>
					<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
						<hr style={{ color: "gray", margin: "0.5rem auto", width: "270px" }} />
						<span style={{ color: "gray" }}>Meta predictors</span>
					</div>
					<FilterSlider options={filters_predictorsParams.revel}
						state={filters_predictors}
						setState={setfilters_predictors}
						name={"revel"}
						label={
							<Tooltip label={getPredictorFlag(translate, "revel_score")}>
								<div style={{ display: "flex" }}>
									Revel Score ≥
									<SliderInputTitle stateName="revel"
										state={filters_predictors}
										setState={setfilters_predictors} />
									<FilterInformation tooltipPosition={"right-start"}
										label={`${translate("common.linkTo")}: Revel Score`}
										link={"https://www.nature.com/articles/s41598-019-49224-8"} />
								</div>
							</Tooltip>
						}
						doublePresicion={filters_predictorsParams.mcap.length - 1}
						rangeButtons={[
							{ label: '0.30', value: 0.30 },
							{ label: '0.65', value: 0.65 }
						]} />
					<CommonDivider />
					<FilterSlider options={filters_predictorsParams.bayesdel}
						state={filters_predictors}
						setState={setfilters_predictors}
						name={"bayesdel"}
						label={
							<Tooltip label={getPredictorFlag(translate, "bayesdel_addaf")}>
								<div style={{ display: "flex" }}>
									BayesDel addAF ≥
									<SliderInputTitle stateName="bayesdel"
										state={filters_predictors}
										setState={setfilters_predictors} />
									<FilterInformation tooltipPosition={"right-start"}
										label={`${translate("common.linkTo")}: Bayesdel Score`}
										link={"https://www.nature.com/articles/s41598-019-49224-8"} />
								</div>
							</Tooltip>
						}
						doublePresicion={filters_predictorsParams.mcap.length - 1}
						rangeButtons={[
							{ label: '-0.36', value: -0.36 },
							{ label: '0.13', value: 0.13 },
						]}
					/>
					<CommonDivider />
					<FilterSlider options={filters_predictorsParams.bayesdelnoaf}
						state={filters_predictors}
						setState={setfilters_predictors}
						name={"bayesdelnoaf"}
						label={
							<Tooltip label={getPredictorFlag(translate, "bayesdel_noaf")}>
								<div style={{ display: "flex" }}>
									BayesDel noAF ≥
									<SliderInputTitle stateName="bayesdelnoaf"
										state={filters_predictors}
										setState={setfilters_predictors} />
								</div>
							</Tooltip>
						}
						rangeButtons={[
							{ label: '-0.18', value: -0.18 },
							{ label: '0.13', value: 0.13 }
						]}
						doublePresicion={filters_predictorsParams.bayesdelnoaf.length - 1}
					/>
					<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
						<hr style={{ color: "gray", margin: "0.5rem auto", width: "305px" }} />
						<span style={{ color: "gray" }}>Predictors</span>
					</div>
					<FilterSlider options={filters_predictorsParams.spliceai}
						state={filters_predictors}
						setState={setfilters_predictors}
						name={"spliceai"}
						label={
							<Tooltip label={translate("casesQuery.predictionFlags.spliceAi")}>
								<div style={{ display: "flex" }}>
									SpliceAI ≥
									<SliderInputTitle stateName="spliceai"
										state={filters_predictors}
										setState={setfilters_predictors} />
									<FilterInformation tooltipPosition={"right-start"}
										label={`${translate("common.linkTo")} : SpliceAI`}
										link={"https://www.cell.com/cell/fulltext/S0092-8674(18)31629-5?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS0092867418316295%3Fshowall%3Dtrue"} />
								</div>
							</Tooltip>
						}
						doublePresicion={filters_predictorsParams.dann.length - 1}
						rangeButtons={[
							{ label: '0.25', value: 0.25 },
							{ label: '0.50', value: 0.50 }
						]}
					/>
					<CommonDivider />
					<FilterSlider options={filters_predictorsParams.mcap}
						state={filters_predictors}
						setState={setfilters_predictors}
						name={"mcap"}
						label={
							<div style={{ display: "flex" }}>
								M-CAP ≥
								<SliderInputTitle stateName="mcap"
									state={filters_predictors}
									setState={setfilters_predictors} />
								<FilterInformation tooltipPosition={"right-start"}
									label={`${translate("common.linkTo")}: Mendelian Clinically Applicable Pathogenicity (M-CAP)`}
									link={"http://great.stanford.edu/help/display/MCAP/M-CAP+Browser"} />
							</div>
						}
						doublePresicion={filters_predictorsParams.mcap.length - 1}
						rangeButtons={[
							{ label: '0.025', value: 0.025 }
						]} />
					<CommonDivider />
					<FilterSlider options={filters_predictorsParams.dann}
						state={filters_predictors}
						setState={setfilters_predictors}
						name={"dann"}
						label={
							<div style={{ display: "flex" }}>
								DANN ≥
								<SliderInputTitle stateName="dann"
									state={filters_predictors}
									setState={setfilters_predictors} />
								<FilterInformation tooltipPosition={"right-start"}
									label={`${translate("common.linkTo")} : Deep learning Approach for aNNotating the pathogenicity of genetic variants - DANN`}
									link={"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4341060/"} />
							</div>
						}
						doublePresicion={filters_predictorsParams.dann.length - 1}
						rangeButtons={[
							{ label: '0.90', value: 0.90 },
							{ label: '0.95', value: 0.95 }
						]} />
					<CommonDivider />
					<FilterSelect options={filters_predictorsParams.mutationTaster}
						label={"Mutation Taster"}
						state={filters_predictors}
						setState={setfilters_predictors}
						name={"mutationTaster"}
						isMulti={true} />
					<br />
					<hr />
				</>
			}
			<FilterSelect options={filters_predictorsParams.sift}
				label={
					<div style={{ display: "flex" }}>
						SIFT
						<FilterInformation tooltipPosition={"right-start"}
							label={`${translate("common.linkTo")}:  Sorting Intolerant From Tolerant - SIFT`}
							link={"https://sift.bii.a-star.edu.sg/"} />
					</div>
				}
				state={filters_predictors}
				setState={setfilters_predictors}
				name={"sift"}
				isMulti={true} />
			<CommonDivider />
			<FilterSelect options={filters_predictorsParams.polyphen}
				label={
					<div style={{ display: "flex" }}>
						Polyphen
						<FilterInformation tooltipPosition={"right-start"}
							label={`${translate("common.linkTo")}:  Polymorphism Phenotyping V2`}
							link={"http://genetics.bwh.harvard.edu/pph2/"} />
					</div>
				}
				state={filters_predictors}
				setState={setfilters_predictors}
				name={"polyphen"}
				isMulti={true} />
			<br />
		</FilterAccordion>
	)
}

function GenesAccordion(props: any) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { filters_initialState, filters_genes, filters_genesParams,
		dispatch: { setfilters_genes, cleanfilters_genes, } } = useContext(QueryContext);

	return (
		<FilterAccordion title={"Genes"}
			state={filters_genes}
			setState={setfilters_genes}
			initState={filters_initialState?.filters_genes}
			cleanFilters={cleanfilters_genes}
		>
			<div style={{ display: "flex" }}>
				<FilterInput type={'text'}
					state={filters_genes}
					setState={setfilters_genes}
					name={"genName"}
					label={
						<div style={{ display: "flex" }}>
							{translate("common.geneName")}
							<FilterInformation tooltipPosition={"right-start"}
								label={"Link to Gen Names"}
								link={"http://www.genenames.org/"} />
						</div>
					}
					description={translate("casesQuery.geneHelp")} />
			</div>
			<CommonDivider />
			<FilterSelect options={filters_genesParams.panel}
				label={translate("common.genesPanel")}
				state={filters_genes}
				setState={setfilters_genes}
				name={"panel"}
				isMulti={true} />
			<FilterExcludeCheckbox
				label={translate("casesQuery.excludePanels")}
				state={filters_genes}
				setState={setfilters_genes}
				name={"excludePanel"} />
			<CommonDivider />
			<span style={{ color: "#333", fontWeight: "bold" }}>
				OMIM genes
			</span>
			<FilterExcludeCheckbox
				label={"OMIM Genes panels"}
				state={filters_genes}
				setState={setfilters_genes}
				name={"omimgenes"} />
			<br />
			<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
				<hr style={{ color: "gray", margin: "0.5rem auto", width: "230px" }} />
				<span style={{ color: "gray" }}>Intolerance to variation</span>
			</div>
			<FilterSlider options={filters_genesParams.gnomadpli}
				state={filters_genes}
				setState={setfilters_genes}
				name={"gnomadpli"}
				doublePresicion={filters_genesParams.gnomadpli.length - 1}
				label={
					<div style={{ display: "flex" }}>
						pLi ≥
						<SliderInputTitle stateName="gnomadpli"
							state={filters_genes}
							setState={setfilters_genes} />
						&nbsp;
						<Tooltip label={translate('casesQuery.pliConditionInfo')}>
							<FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", fontSize: "13px", margin: "2px 5px 0px" }} />
						</Tooltip>
					</div>
				} />
			<CommonDivider />
			<FilterRangeSlider options={filters_genesParams.gnomadzmiss}
				state={filters_genes}
				setState={setfilters_genes}
				name={"gnomadzmiss"}
				label={
					<div style={{ display: "flex" }}>
						Z-Score
						<RangeInputTitle stateName={"gnomadzmiss"}
							state={filters_genes}
							setState={setfilters_genes}
							rangeArr={filters_genesParams.gnomadzmiss}
						/>
						&nbsp;
						<Tooltip label={translate('casesQuery.zScoreConditionInfo')}>
							<FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", fontSize: "13px", margin: "2px 5px 0px" }} />
						</Tooltip>
					</div>
				}
				doublePresicion={filters_genesParams.gnomadzmiss.length - 1}
			// rangeButtons={[
			//     { label: '10X', value: 10 },
			//     { label: '20X', value: 20 },
			//     { label: '30X', value: 30 }
			// ]}
			/>
			<br />
		</FilterAccordion>
	)
}

function PhenotypeAccordion(props: any) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { filters_initialState, filters_phenotypes, filters_phenotypesParams,
		dispatch: { setfilters_phenotypes, cleanfilters_phenotypes, } } = useContext(QueryContext);

	return (
		<FilterAccordion title={translate("casesQuery.filterByPhenotype")}
			state={filters_phenotypes}
			setState={setfilters_phenotypes}
			initState={filters_initialState?.filters_phenotypes}
			cleanFilters={cleanfilters_phenotypes}>
			<FilterSelect options={filters_phenotypesParams.phenotype}
				label={
					<div style={{ display: "flex" }}>
						Fenotipos
						<FilterInformation tooltipPosition={'right-start'}
							label={`${translate("common.linkTo")} Human Phenotype Ontology information`}
							link={"https://hpo.jax.org/app/"}
							styles={{ marginLeft: "8px" }}
						/>
					</div>
				}
				state={filters_phenotypes}
				setState={setfilters_phenotypes}
				name={"phenotype"}
				placeholder={translate("common.enter3Characters")}
				inputNameSearch={"aux_search"}
				isMulti={true} />
			<br />
		</FilterAccordion>
	)
}

function VariantGroupAccordion(props: any) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { filters_initialState, filters_variantgroups, filters_variantgroupsParams,
		dispatch: { setfilters_variantgroups, cleanfilters_variantgroups, } } = useContext(QueryContext);

	return (
		<FilterAccordion title={translate("common.variantGroups")}
			state={filters_variantgroups}
			setState={setfilters_variantgroups}
			initState={filters_initialState?.filters_variantgroups}
			cleanFilters={cleanfilters_variantgroups}>
			<FilterSelect options={filters_variantgroupsParams.groups}
				label={translate("casesQuery.savedGroups")}
				state={filters_variantgroups}
				setState={setfilters_variantgroups}
				name={"groups"}
				isMulti={true} />
			<FilterExcludeCheckbox
				label={translate("casesQuery.excludeVariantGroups")}
				state={filters_variantgroups}
				setState={setfilters_variantgroups}
				name={"excludeGroups"} />
			<br />
		</FilterAccordion>
	)
}

function ConsequenceAccordion(props: any) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { filters_initialState, filters_consequence, filters_consequenceParams,
		dispatch: { setfilters_consequence, cleanfilters_consequence, } } = useContext(QueryContext);
	return (
		<FilterAccordion title={translate("common.consecuence")}
			state={filters_consequence}
			setState={setfilters_consequence}
			initState={filters_initialState?.filters_consequence}
			cleanFilters={cleanfilters_consequence}>
			<FilterSelect options={filters_consequenceParams.impact}
				label={translate("common.potentialImpact")}
				state={filters_consequence}
				setState={setfilters_consequence}
				name={"impact"}
				isMulti={true} />
			<CommonDivider />
			<FilterSelect options={filters_consequenceParams.effect}
				label={translate("casesQuery.variantEffect")}
				state={filters_consequence}
				setState={setfilters_consequence}
				name={"effect"}
				isMulti={true} />
			<FilterExcludeCheckbox
				label={translate("casesQuery.excludeEffect")}
				state={filters_consequence}
				setState={setfilters_consequence}
				name={"excludeEffects"} />
			<br />
		</FilterAccordion>
	)
}

interface EvidenceAccordionI {
	isMitochondrialCase: boolean
}
function EvidenceAccordion(props: EvidenceAccordionI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { filters_initialState, filters_evidence, filters_evidenceParams,
		dispatch: { setfilters_evidence, cleanfilters_evidence, } } = useContext(QueryContext);
	return (
		<FilterAccordion title={translate("common.evidence")}
			state={filters_evidence}
			setState={setfilters_evidence}
			initState={filters_initialState?.filters_evidence}
			cleanFilters={cleanfilters_evidence}>
			{
				!props.isMitochondrialCase &&
				<>
					<CommonDivider />
					<FilterSelect
						id={"filters_evidenceParams.veredict"}
						options={filters_evidenceParams.veredict}
						label={
							<div style={{ display: "flex" }}>
								{translate("casesQuery.acmgVeredict")}
								<FilterInformation tooltipPosition={"right-start"}
									label={`${translate("common.linkTo")} ACMG/AMP 2015 guideline`}
									link={"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4544753/"} />
							</div>
						}
						state={filters_evidence}
						setState={setfilters_evidence}
						name={"veredict"}
						isMulti={true} />
					<FilterExcludeCheckbox
						label={translate("casesQuery.excludeIntervar")}
						state={filters_evidence}
						setState={setfilters_evidence}
						name={"excludeVeredict"} />
					<CommonDivider />
					<FilterSelect options={filters_evidenceParams.acmgBayClasification}
						label={
							<div style={{ display: "flex" }}>
								{translate("casesQuery.acmgBayesianClassif")}
								<FilterInformation tooltipPosition={"right-start"}
									label={`${translate("common.linkTo")} Bayesian Classification`}
									link={"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6336098/"} />
							</div>
						}
						state={filters_evidence}
						setState={setfilters_evidence}
						name={"acmgBayClasification"}
						isMulti={true} />
					<FilterExcludeCheckbox
						label={translate("casesQuery.excludeBayesianClass")}
						state={filters_evidence}
						setState={setfilters_evidence}
						name={"excludeAcmgBayClasification"} />
					<CommonDivider />
					<FilterSlider options={filters_evidenceParams.acmgBayScore}
						state={filters_evidence}
						setState={setfilters_evidence}
						name={"acmgBayScore"}
						label={`${translate("casesQuery.acmgBayesianScore")} ≥ ${filters_evidence.acmgBayScore}`}
						doublePresicion={filters_evidenceParams.acmgBayScore.length - 1}
						rangeButtons={[
							{ label: '0.50', value: 0.50 },
							{ label: '0.90', value: 0.90 },
							{ label: '0.99', value: 0.99 }
						]} />
					<CommonDivider />
					<hr />
				</>
			}
			<FilterSelect options={filters_evidenceParams.clinvar}
				label={
					<div style={{ display: "flex" }}>
						CLINVAR
						<FilterInformation tooltipPosition={"right-start"}
							label={`Link to${translate("common.linkTo")}: Clinical significance value`}
							link={"https://www.ncbi.nlm.nih.gov/clinvar/docs/clinsig/"} />
					</div>
				}
				state={filters_evidence}
				setState={setfilters_evidence}
				name={"clinvar"}
				isMulti={true} />
			<FilterExcludeCheckbox
				label={translate("casesQuery.excludeClinvar")}
				state={filters_evidence}
				setState={setfilters_evidence}
				name={"excludeClinvar"} />
			<CommonDivider />
			<FilterRadioButtons label={"dbSNP"}
				state={filters_evidence}
				setState={setfilters_evidence}
				name={"dbsnp"}
				radios={filters_evidenceParams.dbsnp} />
			<CommonDivider />
		</FilterAccordion>

	)
}

function MitochondrialAccordion(props: any) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { filters_initialState, filters_mitochondrial, filters_mitochondrialParams,
		dispatch: { setfilters_mitochondrial, cleanfilters_mitochondrial } } = useContext(QueryContext);
	return (
		<FilterAccordion title={translate("common.mitochondrial")}
			state={filters_mitochondrial}
			setState={setfilters_mitochondrial}
			initState={filters_initialState?.filters_mitochondrial}
			cleanFilters={cleanfilters_mitochondrial}>
			<FilterRadioButtons label={translate("common.reference")}
				state={filters_mitochondrial}
				setState={setfilters_mitochondrial}
				name={"reference"}
				radios={filters_mitochondrialParams.reference}
			/>
			<CommonDivider />
			<FilterSelect options={filters_mitochondrialParams.scoreInterpretation}
				label={
					<div style={{ display: "flex" }}>
						{translate("casesQuery.scoreInterpretation")}
						<FilterInformation tooltipPosition={"right-start"}
							label={`${translate("common.linkTo")} MitoTip scores`}
							link={"https://www.mitomap.org/MITOMAP/MitoTipScores"} />
					</div>
				}
				state={filters_mitochondrial}
				setState={setfilters_mitochondrial}
				name={"scoreInterpretation"}
				isMulti={true} />
			<CommonDivider />
			<FilterSlider options={filters_mitochondrialParams.apogeescore}
				state={filters_mitochondrial}
				setState={setfilters_mitochondrial}
				name={"apogeescore"}
				label={<div style={{ display: "flex" }}>
					APOGEE score ≥
					<SliderInputTitle stateName="apogeescore"
						state={filters_mitochondrial}
						setState={setfilters_mitochondrial} />
				</div>}
				doublePresicion={100} />
			<CommonDivider />
			<FilterSelect options={filters_mitochondrialParams.diseaseStatus}
				label={
					<div style={{ display: "flex" }}>
						{translate("casesQuery.diseaseStatus")}
						<FilterInformation tooltipPosition={"right-start"}
							label={`${translate("common.linkTo")} MitoMap Disease Status`}
							link={"https://www.mitomap.org/bin/view.pl/MITOMAP/MutationsCodingControlIE"} />
					</div>
				}
				state={filters_mitochondrial}
				setState={setfilters_mitochondrial}
				name={"diseaseStatus"}
				isMulti={true} />
			<CommonDivider />
			<FilterSlider options={filters_mitochondrialParams.heteroplasmy}
				state={filters_mitochondrial}
				setState={setfilters_mitochondrial}
				name={"heteroplasmy"}
				label={<div style={{ display: "flex" }}>
					{translate("casesQuery.heteroplasmy")} ≥
					<SliderInputTitle stateName="heteroplasmy"
						state={filters_mitochondrial}
						setState={setfilters_mitochondrial} />
				</div>}
				doublePresicion={100} />
			<FilterSlider options={filters_mitochondrialParams.afhomoplasmy}
				state={filters_mitochondrial}
				setState={setfilters_mitochondrial}
				name={"afhomoplasmy"}
				label={<div style={{ display: "flex" }}>
					GnomAD homoplasmy ≥
					<SliderInputTitle stateName="afhomoplasmy"
						state={filters_mitochondrial}
						setState={setfilters_mitochondrial} />
				</div>}
				doublePresicion={100} />
		</FilterAccordion>
	)
}
function SomaticAccordion(props: any) {
	const { dispatch: { translate } } = useContext(LangContext);
	// const { theme } = useContext(ThemeContext);
	const { filters_initialState, filters_somatic, filters_somaticParams, dispatch: { setfilters_somatic, cleanfilters_somatic } } = useContext(QueryContext);
	const somaticCommonLabel = (label: string, link: string, dbIcon: any): JSX.Element => {
		// let dbIconJsx = SomaticDbIcon(dbIcon, hasIcon)
		return (<Row style={{ height: "18px", verticalAlign: "bottom", margin: 0, justifyContent: "space-between" }}>
			<label><strong>{label}</strong></label>
			<label>
				{dbIcon}
				<FilterInformation tooltipPosition={"right-start"}
					label={`${translate("common.linkTo")}: ${label}`}
					link={link} />
			</label>
		</Row>)
	}
	return (
		<FilterAccordion title={translate("casesQuery.oncogenicityEvidence")}
			state={filters_somatic}
			setState={setfilters_somatic}
			initState={filters_initialState?.filters_somatic}
			cleanFilters={cleanfilters_somatic}>
			{/* <CommonDivider /> */}
			<FilterExcludeCheckbox
				label={somaticCommonLabel("OncoKB", "https://www.oncokb.org/", <OncokbDbIcon />)}
				state={filters_somatic}
				setState={setfilters_somatic}
				pStyle={{ width: "100%" }}
				name={"oncokb"} />
			<FilterExcludeCheckbox
				label={somaticCommonLabel("COSMIC", "https://cancer.sanger.ac.uk/cosmic", <CosmicDbIcon />,)}
				state={filters_somatic}
				setState={setfilters_somatic}
				name={"cosmic"}
				pStyle={{ width: "100%" }}
			/>
			<FilterExcludeCheckbox
				label={somaticCommonLabel("CIViC", "https://civicdb.org/", <CivicDbIcon />)}
				state={filters_somatic}
				setState={setfilters_somatic}
				pStyle={{ width: "100%" }}
				name={"civic"} />
			<FilterExcludeCheckbox
				label={somaticCommonLabel("Cancer Hotspots", "https://www.cancerhotspots.org/", <HotspotsDbIcon />)}
				state={filters_somatic}
				setState={setfilters_somatic}
				pStyle={{ width: "100%" }}
				name={"hotspots"} />
			<FilterExcludeCheckbox
				label={somaticCommonLabel("PMKB", "https://pmkb.weill.cornell.edu/", <PmkbDbIcon />)}
				state={filters_somatic}
				setState={setfilters_somatic}
				pStyle={{ width: "100%" }}
				name={"pmkb"} />
			<CommonDivider />
			<FilterExcludeCheckbox
				label={
					<div style={{ display: 'flex' }}>
						{translate('casesQuery.somaticEvidence')}
						<Tooltip label={translate('casesQuery.somaticEvidenceInf')}>
							<FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", fontSize: "13px", margin: "2px 5px 0px" }} />
						</Tooltip>
					</div>
				}
				state={filters_somatic}
				setState={setfilters_somatic}
				pStyle={{ width: "100%" }}
				name={"oncoevidence"} />
			<hr />
			<FilterSelect options={filters_somaticParams.pmkbtiers}
				label={'PMKB TIER'}
				state={filters_somatic}
				setState={setfilters_somatic}
				name={"pmkbtiers"} />
			<CommonDivider />
			<FilterSelect options={filters_somaticParams.hotspotsorgantypes}
				label={translate('casesQuery.hotspotsorgantypes')}
				state={filters_somatic}
				setState={setfilters_somatic}
				name={"hotspotsorgantypes"} />
			<CommonDivider />
			<FilterSlider options={filters_somaticParams.vaf}
				state={filters_somatic}
				setState={setfilters_somatic}
				name={"vaf"}
				label={<div style={{ display: "flex" }}>
					{`VAF - ${translate("common.vaf")}`} ≥
					<SliderInputTitle stateName="vaf"
						state={filters_somatic}
						setState={setfilters_somatic} />
				</div>}
				doublePresicion={100} />
			<CommonDivider height={5} />
			<hr />
			<FilterExcludeCheckbox
				label={
					<div style={{ display: 'flex' }}>
						{`${translate('casesQuery.exclude')} - PON (${translate('common.pon')})`}
						<Tooltip label={translate('common.ponDefinition')}>
							<FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", fontSize: "13px", margin: "2px 5px 0px" }} />
						</Tooltip>
					</div>
				}
				state={filters_somatic}
				setState={setfilters_somatic}
				pStyle={{ width: "100%" }}
				name={"pon"} /><CommonDivider />
			{/* <FilterExcludeCheckbox
				label={"Excluir GERMQ (Germline 1000G dataset)"}
				state={filters_somatic}
				setState={setfilters_somatic}
				pStyle={{ width: "100%" }}
				name={"germq"} /><CommonDivider /> */}
		</FilterAccordion>
	)
}

function PharmGkb(props: any) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { filters_initialState, filters_pharmgkb, filters_pharmgkbParams,
		dispatch: { setfilters_pharmgkb, cleanfilters_pharmgkb, } } = useContext(QueryContext);
	return (
		<FilterAccordion title={"PharmGKB"}
			state={filters_pharmgkb}
			setState={setfilters_pharmgkb}
			initState={filters_initialState?.filters_pharmgkb}
			cleanFilters={cleanfilters_pharmgkb}>
			<FilterSelect options={filters_pharmgkbParams.pharmGkb}
				label={"PharmGKB"}
				state={filters_pharmgkb}
				setState={setfilters_pharmgkb}
				name={"pharmGkb"}
				isMulti={true} />
			<CommonDivider />
			<FilterSelect options={filters_pharmgkbParams.evidence}
				label={translate("common.evidence")}
				state={filters_pharmgkb}
				setState={setfilters_pharmgkb}
				name={"evidence"}
				isMulti={true} />
			<CommonDivider />
		</FilterAccordion>
	)
}
interface SliderInputTitleI {
	stateName: string,
	state: any,
	setState: any
}

function SliderInputTitle(props: SliderInputTitleI) {
	return (
		<FormInput value={props.state[props.stateName]}
			type={'number'}
			onChange={(e: any) => {
				var newState = props.state;
				newState[`${props.stateName}`] = e.target.value
				props.setState({ ...newState })
			}}
			// placeHolder={props.state.mcap}
			formControlStyle={{
				height: "20px",
				width: "55px",
				padding: "0 0 0 3px",
				marginLeft: "5px"
			}}
		/>
	)
}
interface RangeInputTitleI {
	stateName: string,
	state: any,
	setState: any,
	rangeArr: number[]
}

function RangeInputTitle(props: RangeInputTitleI) {
	const isNegative = props.state[`${props.stateName}`][0] === props.rangeArr[0]
	return (
		<div style={{ display: "flex" }}>
			<Button style={{ padding: "0px 6px", backgroundColor: "#f4f4f4", color: "black", border: "1px gray", margin: 0, marginLeft: "3px", height: "20px" }}
				onClick={() => {
					var newState = props.state;
					if (isNegative)
						newState[`${props.stateName}`] = [newState[`${props.stateName}`][1], props.rangeArr[props.rangeArr.length - 1]]
					else
						newState[`${props.stateName}`] = [props.rangeArr[0], newState[`${props.stateName}`][0]]
					props.setState({ ...newState })
				}}>
				{isNegative ? "≤" : "≥"}
			</Button>
			<FormInput value={
				isNegative ?
					props.state[props.stateName][1] :
					props.state[props.stateName][0]

			}
				type={'number'}
				onChange={(e: any) => {
					var newState = props.state;
					if (isNegative)
						newState[`${props.stateName}`] = [props.rangeArr[0], e.target.value,]
					else
						newState[`${props.stateName}`] = [e.target.value, props.rangeArr[props.rangeArr.length - 1]]
					props.setState({ ...newState })
				}}
				// placeHolder={props.state.mcap}
				formControlStyle={{
					height: "20px",
					width: "55px",
					padding: "0 0 0 3px",
					marginLeft: "5px"
				}}
			/>
		</div>
	)
}

export {
	VariantAccordion,
	QualityCoverageAccordion,
	PredictionScoresAccordion,
	FrequencyAccordion,
	GenesAccordion,
	PhenotypeAccordion,
	VariantGroupAccordion,
	ConsequenceAccordion,
	EvidenceAccordion,
	SamplesAccordion,
	MitochondrialAccordion,
	SomaticAccordion,
	PharmGkb
}