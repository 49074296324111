import React from 'react';
import { useRef, useEffect } from "react";
import * as d3 from "d3";
import "./styles.css";
import LayoutChart from "./LayoutChart";

export default function DonutChart(props) {
    const width = props.width;
    const height = props.height;
    const outerRadius = height / 2 - 20;
    const innerRadius = outerRadius / 3;
    const color = d3.scaleOrdinal(d3.schemePaired);

    function getData() {
        var data = props.data

        data = data.map(x => {
            return { ...x, color: color(x.value) }
        })
        let pieData = d3.entries(data.map(x => x.value))
        pieData = pieData.map((x, i) => {
            return {
                ...x,
                color: color(x.value),
                label: data[i].label,
                link: data[i].link
            }
        }).filter(x => x.value)
        return pieData
    }
    d3.selectAll(`#${props.id}`).selectAll("svg").remove();
    const pieData = getData()
    var arc = d3.arc()
        .outerRadius(outerRadius)
        .innerRadius(innerRadius)
        .padRadius(outerRadius);

    var hoverArc = d3.arc()
        .innerRadius(innerRadius + innerRadius * 0.2)
        .outerRadius(outerRadius + outerRadius * 0.2);

    const pie = d3.pie().value((d) => d.value)
        .sort(function (a, b) { return b.value < a.value ? -1 : b.value > a.value ? 1 : 0; })

    function tweenIn(data) {
        var interpolation = d3.interpolate({ startAngle: 0, endAngle: 0 }, data);
        this._current = interpolation(0);
        return function (t) {
            return arc(interpolation(t));
        };
    };

    const chartRef = useRef();
    const renderChart = (box) => {
        // d3.select("svg").remove();

        const svg = d3
            .select(box.current)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            // .style("transition", 25000)
            // .transition().duration(500)
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")

        var tooltip = d3.select("body")
            .append("div")
            .attr("class", "venntooltip");

        // eslint-disable-next-line no-unused-vars
        const build = svg
            .attr("id", props.id)
            .selectAll("path")
            .data(pie(pieData))
            .enter()
            .append("path")
            .attr("d", arc)
            .attr("fill", (d) => d.data.color)
            .style("fill-opacity", 0.75)
            .style("stroke", "#FFFFFF")
            .attr("class", (d) => `paths path_${d.index}`)
            .on("mouseover", function (d, i) {
                tooltip.transition().style("opacity", 1);
                tooltip.text(d.data.value.toFixed(0) + "  variants");
                d3.select(this)
                    .transition()
                    .attr("d", hoverArc)
                    .style("fill-opacity", 1)
                setTimeout(() => {
                    tooltip.transition().style("opacity", 0);
                    tooltip.text("");
                    d3.select(this)
                }, 4000);
            })
            .on("mousemove", function () {
                tooltip
                    .style("left", d3.event.pageX + "px")
                    .style("top", d3.event.pageY - 28 + "px");
            })
            .on("mouseout", function () {
                tooltip.transition().style("opacity", 0);
                d3.select(this)
                    .transition()
                    .attr("d", arc)
                    .style("fill-opacity", 0.75)
            })
            .on("click", function (i) {
                props.history.push(i.data.link);
            })
            .transition().duration(600).attrTween("d", tweenIn);
    };
    useEffect(() => {
        renderChart(chartRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    return (
        <LayoutChart history={props.history}
            data={pieData}
            width={props.width}
            height={props.height}
            labels={true}
            title={props.title}
        >
            <div ref={chartRef} id={props.id} key={props.id} />
        </LayoutChart>
    );
}
