import React from 'react';
import { useContext } from "react";
import { Badge } from "react-bootstrap";
import { LangContext } from "../../../Contexts/Lang";
// import { Tooltip } from "../Tooltip/Tooltip";
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

export default function BadgeAbsentInGnomad() {
    const { theme } = useContext(ThemeContext);
    const { dispatch: { translate } } = useContext(LangContext);

    return (
        // <Tooltip label={translate("common.absentInGnomad")}>
        <Badge pill
            variant="secondary"
            style={{ backgroundColor: theme.acmg_vere_vus, marginBottom: 5 }}>
            <span >
                {translate("common.absentInGnomad")}&nbsp;<FontAwesomeIcon icon={faBan} style={{ verticalAlign: "bottom" }} />
            </span>
        </Badge>
        // </Tooltip>
    )
}