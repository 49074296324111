import React from 'react';
import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import FormInput from "../../../Common/Form/FormInput";
import { useEffect, useState, useContext, useRef } from "react";
import VariantServices from '../../../../Services/VariantServices';
import GroupServices from '../../../../Services/GroupService';
import { LangContext } from '../../../../Contexts/Lang';
import './styles.css';
import { Formik } from "formik";
import * as yup from "yup";
import ThemeContext from "../../../../Contexts/Theme/ThemeContext";
type formInputType = "number" | "text" | "textarea" | "password" | "email"
interface ProcessCaseI {
    show: boolean,
    handleClose: any,
    groupId?: number,
    studyId?: number,
    variantsIds?: string,
    groupName?: string,
    groupDescription?: string,
    handleReload?: any
}
export default function ProcessVariantGroups(props: ProcessCaseI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const VariantS = new VariantServices();
    const GroupS = new GroupServices();
    const [initialForm, setInitialForm] = useState<any>("");
    const [variants, setvariants] = useState<any[]>([]);
    const [variantsSelecteds, setvariantsSelecteds] = useState<any[]>([]);
    let formRef: any = useRef();
    useEffect(() => {
        if (props.studyId && props.variantsIds) {
            VariantS.read_variants(props.studyId, props.variantsIds)
                .then((res: any) => {
                    if (res.status === 200) {
                        let newVariantGroup = {
                            name: props.groupName ?? "",
                            description: props.groupDescription ?? ""
                        }
                        formRef?.current?.setValues(newVariantGroup)
                        const v = Object.entries(res.data.variants)
                            .map((x: any) => { return { name: x[0], data: x[1] } })
                        setvariants(v)
                        setvariantsSelecteds(v.map((x: any) => x.data[0].id))
                    }
                })
                .catch((e: any) => {
                    console.log("ERRRORR!!")
                })
        } else {
            formRef?.current?.setValues(initializeValues())
        }
        setInitialForm(initializeValues())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])
    function initializeValues() {
        return {
            name: "",
            description: "",
        }
    }
    function handleVariantsSelecteds(id: number) {
        if (variantsSelecteds.find((y: any) => y === id))
            setvariantsSelecteds(variantsSelecteds.filter((y: any) => y !== id))
        else
            setvariantsSelecteds([...variantsSelecteds, id])
    }
    function getVariantsTable() {
        const columnStyle = { padding: "2px 5px", fontSize: 12, verticalAlign: "middle" }
        const rows = variants.map((x: any, i: number) => {
            return (
                <tr key={i} onClick={() => { handleVariantsSelecteds(x.data[0].id) }}>
                    <td style={{ ...columnStyle, textAlignLast: "center" }}>
                        <Form.Check type="checkbox"
                            checked={variantsSelecteds.find((y: any) => y === x.data[0].id) ?? false}
                            onChange={() => { }}
                        />
                    </td>
                    <td style={columnStyle}>
                        {x.data[0].chromosome} : {x.data[0].position}
                    </td>
                    <td style={columnStyle}>
                        {x.data[0].reference}
                        <FontAwesomeIcon icon={faArrowRight}
                            style={{
                                color: "gray",
                                border: "none",
                                margin: "0 4px",
                                fontSize: "13px",
                                verticalAlign: "text-bottom"
                            }} />
                        {x.data[0].alternative}<br />
                        {x.data[0].infoColumnData.AA_CHANGE}
                    </td>
                    <td style={columnStyle}>
                        {x.data[0].infoColumnData.GENE_NAME}
                    </td>
                </tr>)
        })
        const columnTitleStyle = { padding: 4, fontSize: 14 }
        return (
            <Table bordered hover size="sm">
                <thead>
                    <tr style={{ color: theme.grayHeader, "backgroundColor": theme.backgroundSecondary, margin: 0, padding: "5px" }}>
                        <th style={columnTitleStyle} ></th>
                        <th style={columnTitleStyle} >Chr:position</th>
                        <th style={columnTitleStyle} >Cambio</th>
                        <th style={columnTitleStyle} >Gen</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        )
    }

    function createSchema() {
        const name = yup.string()
            .min(3, translate(`error.toShort`))
            .max(50, translate(`error.toLong`))
            .required(translate(`error.required`));
        const description = yup.string();
        return yup.object().shape({
            name,
            description,
        })
    }
    function createForm(values: any, handleChange: any, errors: any) {
        function commonFormInput(type: formInputType, key: string, name: string, label: string, placeholder?: string, required: boolean = false) {
            return <FormInput type={type}
                labelBold={true}
                labelColor={"gray"}
                // borderRadius={true}
                label={label}
                key={key}
                formGrouplStyle={{ "marginBottom": "15px" }}       // onKeyDown={(e: any) => handleEnter(e)}
                placeHolder={placeholder}
                name={name}
                // disabled={props.mode === "read"}
                required={required}
                requiredFeedback={errors[name]}
                feedbackValidator={!!errors[name]}
                value={values[name]}
                onChange={handleChange} />
        }
        const nameInput = commonFormInput("text", "nameKey", "name", translate(`home.processCase.name`), `${translate(`home.processCase.name`)}...`, true);
        const descriptionInput = commonFormInput("textarea", "descKey", "description", translate(`home.processCase.description`), `${translate(`home.processCase.description`)}...`)
        return (<>
            <Card>
                <Card.Body>
                    {nameInput}
                    {descriptionInput}
                    {/* <hr style={{ margin: "0 0 10px" }} /> */}
                    <p style={{ margin: "0 0 10px 0", padding: 0, color: "gray" }}>
                        <strong>{translate(`common.variants`)}({variants.length})</strong>
                    </p>
                    {getVariantsTable()}
                </Card.Body>
            </Card>
        </>
        )
    }
    function getTitle() {
        return (
            <Modal.Title style={{ margin: "0px 10px", color: theme.backgroundPrimary }} >
                <FontAwesomeIcon icon={faEdit}
                    style={{
                        color: theme.grayHeader,
                        border: "none",
                        marginRight: "7px",
                        fontSize: "27px",
                        verticalAlign: "text-bottom"
                    }} />
                <strong>{translate(`casesDetail.editResult`)}</strong>
            </Modal.Title>)
    }
    const title = getTitle()

    function handleSubmit(data: any) {
        if (props.studyId && props.groupId)
            GroupS.edit(
                props.studyId,
                props.groupId,
                data.name,
                data.description,
                variantsSelecteds.join().replace("[", "").replace("]", ""),
                variantsSelecteds.length
            ).then((res: any) => {
                props.handleClose()
                props.handleReload()
                // console.log("ressssssssss")
                // console.log(res)
            }).catch((e) => {
                console.log("e")
                console.log(e)
            })
    }


    return (
        <Modal show={props.show}
            onHide={props.handleClose}
            dialogClassName="edit_result_modal"
        >
            <Formik initialValues={initialForm}
                validationSchema={createSchema()}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                handleSubmit={console.log}
                innerRef={formRef}
                validateOnChange={false}
                validateOnBlur={true}>
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: theme.grayHeader }}>
                                {title}
                            </Modal.Header>
                            <Modal.Body style={{ padding: "0" }}>
                                {createForm(values, handleChange, errors)}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={props.handleClose}>
                                    {translate(`common.close`)}
                                </Button>
                                <Button variant="primary" type="submit">
                                    {translate(`common.save`)}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                )}
            </Formik>
        </Modal>
    )
}
