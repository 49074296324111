import React, { useContext } from "react";
import { useState } from "react";
import { Popover } from "../../Popover";
import { CoverageProteinI } from "./CoverageProteinBullet";
import { BulletRectsItemProps } from '@nivo/bullet';
import ThemeContext from "src/Contexts/Theme/ThemeContext";
import { LangContext } from "src/Contexts/Lang";

interface CoverageProteinBulletRangeI {
    proteinCovData: CoverageProteinI[],
    bulletProps: BulletRectsItemProps,
    maxMean: number,
    minMean: number,
    height: number,
}
export default function CoverageProteinBulletRange(props: CoverageProteinBulletRangeI) {
    const { theme } = useContext(ThemeContext);
    const { dispatch: { translate } } = useContext(LangContext);

    const { x, y, height, width, index, data } = props.bulletProps,
        isExon = props.bulletProps.index % 2 === 1,
        [selected, setselected] = useState<boolean>(false)
    let minHeight: any = 50,        // el minimo es 60 porque los labels son de 10px, y hay 1 renglon superior de la media y 5 inferiores
        minTranscriptHeight: any = (props.height - minHeight) / 2,
        maxTranscriptHeight: any = (props.height - minHeight) / 2,
        barHeight: any = (props.height - minHeight) / 2000,
        meanCovPercent: any = false,
        rowData: any = false,
        upperText: any = false,
        bottomText: any = false,
        rectBottomHeightRest: number = 0,
        rectBottomHeight: any = false,
        rectTopHeightRest: number = 0,
        rectTopHeight: any = false,
        mean: any = false;
    if (isExon) {
        rowData = props.proteinCovData.find((x: any) => +x.start === data.v0);
        mean = +(+(rowData ?? { mean: `${barHeight}` }).mean).toFixed();
        meanCovPercent = (mean / props.maxMean) * props.height - minHeight;
        barHeight = minTranscriptHeight + meanCovPercent;
        barHeight = barHeight < 10 ? 10 : barHeight
        upperText = `${mean}X`;
        bottomText = `${data?.v1 - data?.v0}bp`;
        rectBottomHeightRest = ((index - 1) / 2 % 4) * 9;
        rectBottomHeight = (minHeight + maxTranscriptHeight) / 2 + rectBottomHeightRest + 5
        rectTopHeightRest = ((index - 1) / 2 % 3) * 9;
        rectTopHeight = (minHeight + maxTranscriptHeight) / 2 + rectTopHeightRest + 5
    }

    const transcriptFillColor = (pastell: boolean = false) => {
        const pallete = ["#66c2a5", "#54a083"]
        const pallPas = ["#b3e2cd", "#91c0ac"]
        const palleteAllert = ["#fc8d62", "#f75a73", "#f73a53"]
        const pallAllertPas = ["#fdcdac", "#f78aa3", "#f75a73"]
        // console.log(mean)
        if (!selected) {
            if (mean) {
                if (mean <= 6) {
                    return pastell ? pallAllertPas[1] : palleteAllert[1]
                }
                if (mean <= 20) {
                    return pastell ? pallAllertPas[0] : palleteAllert[0]
                }
            }
            const newIndex: number = +(props.bulletProps.index / 2).toFixed() % pallete.length;
            // console.log(newIndex)
            return pastell ? pallPas[newIndex] : pallete[newIndex];
        }
        const selectedColor = "rgba(85, 124, 155, 1)";
        return selectedColor
    }
    // console.log(rowData)
    return (
        <g width={2} widths={2} >
            {upperText &&
                <>
                    <rect x={x + width / 2}
                        y={y + height / 2 - rectTopHeight}
                        width={0.5}
                        height={rectTopHeight}
                        // fill={transcriptFillColor(true)}
                        fill={"lightgray"}
                    // fill={!selected ? `rgba(110,110,110,0.6)` : selectedColor}
                    // fill={!selected ? `rgba(110,110,210,1.6)` : selectedColor}
                    />
                    <text x={x + width / 2}
                        y={y + height / 2 - rectTopHeight - 5}
                        textAnchor="middle"
                        fontWeight={!selected ? "auto" : "bold"}
                        style={{ fill: !selected ? "#888899" : transcriptFillColor(), fontSize: "9px" }}
                    >
                        {upperText}
                    </text>
                </>
            }
            {bottomText &&
                <>
                    <rect x={x + width / 2}
                        y={y + height / 2}
                        width={0.5}
                        height={rectBottomHeight}
                        // fill={transcriptFillColor(true)}
                        fill={"lightgray"}
                    // fill={!selected ? `rgba(110,110,110,0.6)` : selectedColor}
                    // fill={!selected ? `rgba(110,110,210,1.6)` : selectedColor}
                    />
                    <text fontWeight={!selected ? "auto" : "bold"}
                        x={x + width / 2}
                        y={y + height / 2 + rectBottomHeight + 12}
                        textAnchor="middle"
                        style={{ fill: !selected ? "#888899" : transcriptFillColor(true), fontSize: "9px" }}>
                        {bottomText}
                    </text>
                </>
            }
            {!isExon ?
                <rect x={x}
                    y={y + height / 2 - barHeight / 2}
                    width={width}
                    height={0}
                    fill={"trasnaparent"} />
                :
                <Popover id={"CoverageProteinBullet-Popover"}
                    content={
                        <div style={{ padding: 10, fontSize: "10px" }}>
                            &nbsp;<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
                                {translate("common.mean")} X
                            </span> : {rowData?.mean ? (+rowData?.mean ?? "0.0").toFixed(2) : "-"}<br />
                            &nbsp;<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
                                {translate("common.coverage")}
                            </span> : {rowData?.coverage}<br />
                            &nbsp;<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
                                {translate("common.start")}
                            </span> : {rowData?.start}<br />
                            &nbsp;<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
                                {translate("common.end")}
                            </span> : {rowData?.end}<br />
                            &nbsp;<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
                                {translate("common.transcript")}
                            </span> : {rowData?.transcript}<br />
                            &nbsp;<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
                                {translate("common.region")}
                            </span> : {rowData?.start}-{rowData?.end}<br />
                        </div>
                    }
                    trigger={"hover"}
                    style={{ minWidth: "200px", fontSize: "9px", zIndex: 2 }}
                    contentStyle={{ padding: "0 0 0 2px" }}
                >
                    <rect x={x}
                        y={y + height / 2 - barHeight / 2}
                        width={width < 0.5 ? 0.5 : width}
                        height={barHeight}
                        style={{ cursor: "pointer" }}
                        fill={transcriptFillColor()}
                        onMouseOver={() => {

                        }}
                        onClick={() => { setselected(!selected) }}
                    />
                </Popover >
            }


        </g>
    );
};
