import React from 'react';
import { useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import PredictorsBar from 'src/Components/Common/Nivo/Predictors/PredictorsBar';
import { LangContext } from "../../../../../Contexts/Lang";
import QueryContext from "../../../../../Contexts/Query/QueryContext";
import { roundWithTooltipoTo0 } from "../../../../../Helpers/Helpers";
import BadgeVeredictPredictors, { getPredictorVeredict, VeredictT } from "../../../../Common/Badges/BadgePredictorsVeredict";
import { LinkMutationTaster } from "../../../../Common/Links/Links";
import PredictorsSubtitle from './PredictorsSubtitle';
import PredictorsTemplateTable, { PredictorsTemplateTableRowI } from './PredictorsTemplateTable';
import { VariantTranscriptI } from 'src/Contexts/interfaces';
import { PredictorsNamesI } from './Predictors';
import BadgeVariantVeredict from 'src/Components/Common/Badges/BadgeVariantVeredict';

interface PredictorsTableI {
    variantTranscript: VariantTranscriptI,
    defaultScoreValue: string,
    getEmptyTableRow(id: string, label?: string): PredictorsTemplateTableRowI,

}
export default function PredictorsTable(props: PredictorsTableI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { variant } = useContext(QueryContext);
    const vt: VariantTranscriptI = props.variantTranscript;
    if (!vt || !vt.predictors) return <></>;
    const predictors: PredictorsNamesI = vt.predictors;
    const defaultScoreValue = "--"

    let progressBarValue: any[] = [];
    function getProgressPredictedValues() {
        const newV = progressBarValue.filter((x: { veredict: VeredictT | null, info: string }) => x.veredict)
        const pCount = newV.filter((x: { veredict: VeredictT, info: string }) => x.veredict === "P").length;
        const bCount = newV.filter((x: { veredict: VeredictT, info: string }) => x.veredict === "B").length;
        const usCount = newV.filter((x: { veredict: VeredictT, info: string }) => x.veredict === "US").length;

        const data = {
            benign: bCount,
            uncertainSignificance: usCount,
            pathogenic: pCount
        }
        return <PredictorsBar data={data} />
    }
    const getPredictorsTableRows = (): PredictorsTemplateTableRowI[] => {

        const getCadd1000G = (): PredictorsTemplateTableRowI => {
            const cadd1000GScore = predictors.CADD_1000G_SCORE ? predictors.CADD_1000G_SCORE : (predictors.CADD_RAW ?? defaultScoreValue)
            let detail = ""
            var score = predictors.CADD_1000G_SCORE || predictors.CADD_RAW;
            if (score) {
                if (score < 11) {
                    detail = `benign`;
                } else {
                    if (score >= 11 && score < 14) {
                        detail = `possibly damaging`;
                    } else {
                        detail = `damaging`;
                    }
                }
            }
            progressBarValue.push(getPredictorVeredict(translate, cadd1000GScore, "cadd"))
            return {
                id: "cadd100g",
                label: "CADD 1000G",
                tooltipLabel: translate("variantDetail.caddLabelInfo"),
                score: roundWithTooltipoTo0(cadd1000GScore),
                conclution: <>{score && <BadgeVeredictPredictors type={"cadd"} value={cadd1000GScore} />}</>,
                detail: detail,
                info: {
                    title: "CADD",
                    body: translate("variantDetail.caddInfo")
                }
            }
        }
        const cadd1000G = getCadd1000G()

        const getMutationTaster = (): PredictorsTemplateTableRowI => {
            var detail: any = "--",
                score = predictors.MUTATIONTASTER_SCORE,
                mt_pred;
            //"MutationTaster prediction, 'A' ('disease_causing_automatic'), 'D' ('disease_causing'), 'N' ('polymorphism') or 'P' ('polymorphism_automatic')
            let mt_score: any = false
            if (predictors.MUTATIONTASTER) {
                mt_pred = predictors.MUTATIONTASTER.split(",")[0].toUpperCase().trim();
                mt_score = score.toString().split(",")[0];
            }
            switch (mt_pred) {
                case "A": detail = `disease_causing_automatic`; break;
                case "D": detail = `disease_causing`; break;
                case "N": detail = `polymorphism`; break;
                case "P": detail = `polymorphism_automatic`; break;
            }
            const linkMt = (
                <span style={{ margin: "0 5px" }}>
                    <LinkMutationTaster
                        chromosome={variant.data?.variant?.chromosome}
                        chPosition={variant.data?.variant?.position}
                        reference={variant.data?.variant?.reference}
                        alternative={variant.data?.variant?.alternative}
                        label={`${variant.data?.variant?.chromosome}-${variant.data?.variant?.position}-${variant.data?.variant?.reference}-${variant.data?.variant?.alternative}`}
                        fontSize={"13px"} />
                </span>
            )
            detail = <>{detail}&nbsp;&nbsp;&nbsp;&nbsp;{linkMt}</>
            if (mt_pred) progressBarValue.push(getPredictorVeredict(translate, mt_pred, "mutation_taster"))
            return {
                id: "mt",
                label: "Mutation Taster",
                score: mt_score ? roundWithTooltipoTo0(mt_score) : defaultScoreValue,
                conclution: mt_pred ? <BadgeVeredictPredictors type={"mutation_taster"} value={mt_pred} /> : "",
                detail: detail,
                info: {
                    title: "Mutation Taster",
                    body: translate("variantDetail.mutationTasterInfo")
                }
            }

        }
        const mutationTaster = getMutationTaster()

        const getSiftValue = (): PredictorsTemplateTableRowI => {                  // VEEEEEEEEEEEEEEEEEEER!!!!
            let siftScore: any = predictors?.SIFT_SCORE;
            if (isNaN(siftScore)) return props.getEmptyTableRow("SIFT", "SIFT")
            else {
                siftScore = roundWithTooltipoTo0(siftScore)
            }
            progressBarValue.push(getPredictorVeredict(translate, siftScore, "sift"))
            return {
                id: "SIFT",
                label: "SIFT",
                tooltipLabel: "Sorting Intolerant From Tolerant",
                score: siftScore,
                conclution: siftScore ? <BadgeVeredictPredictors type={"sift"} value={predictors?.SIFT_SCORE} /> : "",
                detail: "",//detail,
                info: {
                    title: "SIFT",
                    body: translate("variantDetail.siftInfo")
                }
            }


        }
        const sift = getSiftValue()

        const getPloyphen = (): PredictorsTemplateTableRowI => {
            let detail = ""
            const polyph = predictors?.POLYPHEN2_HDIV_PRED;
            const polyphSc = predictors?.POLYPHEN2_HDIV_SCORE;
            if (polyph) {
                detail = `${polyph} ${polyphSc ? ("(" + polyphSc + ")") : ""}`;
            }
            progressBarValue.push(getPredictorVeredict(translate, predictors?.POLYPHEN2_HDIV_SCORE, "polyphen2"))
            return {
                id: "polyphen",
                label: "PolyPhen",
                tooltipLabel: "PolyPhen - Polymorphism Phenotyping",
                score: predictors?.POLYPHEN2_HDIV_SCORE ?? defaultScoreValue,
                conclution: predictors?.POLYPHEN2_HDIV_SCORE ? <BadgeVeredictPredictors type={"polyphen2"} value={predictors.POLYPHEN2_HDIV_SCORE} /> : "",
                detail: detail,
                info: {
                    title: "Polyphen",
                    body: translate("variantDetail.polyphenInfo")
                }
            }
        }
        const polyhen = getPloyphen()

        progressBarValue.push(getPredictorVeredict(translate, predictors?.FATHMM_SCORE, "fathmm"))
        progressBarValue.push(getPredictorVeredict(translate, predictors?.DANN_SCORE, "dann"))
        progressBarValue.push(getPredictorVeredict(translate, predictors?.LRT_PRED, "lrt"))
        progressBarValue.push(getPredictorVeredict(translate, predictors?.PROVEAN_PRED, "provean"))
        progressBarValue.push(getPredictorVeredict(translate, predictors?.M_CAP_PRED, "mcap"))
        progressBarValue.push(getPredictorVeredict(translate, predictors?.VEST4_SCORE, "vest"))
        progressBarValue.push(getPredictorVeredict(translate, predictors?.AM_CLASS, "alpha_missense"))
        let dataPredictors: PredictorsTemplateTableRowI[] = [
            cadd1000G,
            {
                id: "dann",
                label: "DANN",
                tooltipLabel: translate("variantDetail.dannLabelInfo"),
                score: predictors?.DANN_SCORE ? roundWithTooltipoTo0(predictors?.DANN_SCORE) : defaultScoreValue,
                conclution: predictors?.DANN_SCORE ? <BadgeVeredictPredictors type={"dann"} value={predictors?.DANN_SCORE} /> : "",
                detail: "",
                info: {
                    title: "DANN",
                    body: translate("variantDetail.dannInfo")
                }
            },
            {                                                               // VEEEEEEEEEEEEEEEEEEER!!!!
                id: "fathmm_score",
                label: "FATHMM Score",
                tooltipLabel: "FATHHMM - Functional Analysis through Hidden Markov Models Score",
                score: predictors?.FATHMM_SCORE ? roundWithTooltipoTo0(predictors?.FATHMM_SCORE) : defaultScoreValue,
                conclution: predictors?.FATHMM_SCORE ? <BadgeVeredictPredictors type={"fathmm"} value={predictors?.FATHMM_SCORE} /> : "",
                detail: "",
                info: {
                    title: "FATHMM",
                    body: translate("variantDetail.fathmmInfo")
                }
            },
            {
                id: "lrt_score",
                label: "LRT Score",
                tooltipLabel: "LRT - Likelihood Ratio Test Score",
                score: predictors?.LRT_SCORE ? roundWithTooltipoTo0(predictors?.LRT_SCORE) : defaultScoreValue,
                conclution: predictors?.LRT_SCORE ? <BadgeVeredictPredictors type={"lrt"} value={predictors?.LRT_PRED} /> : "",
                detail: "",
                info: {
                    title: "LRT",
                    body: translate("variantDetail.lrtInfo")
                }
            },
            {
                id: "m_cap_score",
                label: "M-CAP Score",
                tooltipLabel: "M-CAP - Mendelian Clinically Applicable Pathogenicity Score",
                score: predictors?.M_CAP_SCORE ? roundWithTooltipoTo0(predictors?.M_CAP_SCORE) : defaultScoreValue,
                conclution: predictors?.M_CAP_SCORE ? <BadgeVeredictPredictors type={"mcap"} value={predictors?.M_CAP_PRED} /> : "",
                detail: "",
                info: {
                    title: "M-CAP",
                    body: translate("variantDetail.mcapInfo")
                }
            },
            mutationTaster,
            {
                id: "provean_score",
                label: "PROVEAN Score",
                tooltipLabel: "PROVEAN - Protein Variation Effect Analyzer",
                score: predictors?.PROVEAN_SCORE ? roundWithTooltipoTo0(predictors?.PROVEAN_SCORE) : defaultScoreValue,
                conclution: predictors?.PROVEAN_SCORE ? <BadgeVeredictPredictors type={"provean"} value={predictors?.PROVEAN_PRED} /> : "",
                detail: "",
                info: {
                    title: "PROVEAN",
                    body: translate("variantDetail.proveanInfo")
                }
            },
            polyhen,
            sift,
            {
                id: "vest4",
                label: "VEST4",
                tooltipLabel: "Variant Effect Sorting Tool",
                score: predictors?.VEST4_SCORE ?? defaultScoreValue,
                conclution: predictors?.VEST4_SCORE ? <BadgeVeredictPredictors type={"vest"} value={predictors?.VEST4_SCORE} /> : "",
                detail: "",
                info: {
                    title: "Variant Effect Scoring Tool (VEST)",
                    body: translate("variantDetail.vest3Info")
                }
            },
            {
                id: "alphaMissense",
                label: "Alpha Missense",
                // tooltipLabel: "Variant Effect Sorting Tool",
                score: predictors?.AM_PATHOGENICITY ?? defaultScoreValue,
                conclution: predictors?.AM_CLASS ? <BadgeVeredictPredictors type={"alpha_missense"} value={predictors?.AM_CLASS} /> : "",
                detail: <span style={{ verticalAlign: "bottom" }}>
                    <BadgeVariantVeredict labelInput={predictors?.AM_CLASS} id={0} size={'small'} />
                </span>,
                // info: {
                //     title: "Variant Effect Scoring Tool (VEST)",
                //     body: translate("variantDetail.vest3Info")
                // }
            },
        ]
        return dataPredictors;
    }
    const predictorsTableRows = getPredictorsTableRows();
    return (
        <Container>
            <Row>
                <Col md={4} style={{ padding: 0 }}>
                    <PredictorsSubtitle subtitle={translate("common.predictors")} />
                </Col>
                <Col md={8}>
                    {/* <span style={{ padding: "10px 0px" }}>
                        
                    </span> */}
                    {getProgressPredictedValues()}
                </Col>
            </Row>
            <Row>
                <PredictorsTemplateTable rowData={predictorsTableRows} />
            </Row>
        </Container>
    )
}
