import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';

export default class HpoGensetServices extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "hpogenset/";
    }
    
    async read_hpogenset(): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}index`
        const config = {
            ...this.authHeader,
            params: {
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }

    async read_candidateGenes(sintomasList : string[], type: "interseccion" | "union"): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}candidateGenes`
        const config = {
            ...this.authHeader,
            params: {
                sintomasList : sintomasList.join(","),
                type: type,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }
}