import React from 'react';
import { useContext, useState, useEffect } from 'react';
import { LangContext } from "../../Contexts/Lang";
import { Row, Col } from 'react-bootstrap';
import Igv from './IGV';
import ReactSelect from '../Common/Form/ReactSelect';
import BadgeVariantImpact from '../Common/Badges/BadgeVariantImpact';
import { LabelValueI } from '../../utils/BioFunctionalData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '../Common/Tooltip/Tooltip';
import BadgeCnv from '../Common/Badges/BadgeCnv';
import { MutationI, AlignmentT } from './types';

type parentT = "modal" | "page";
type MutationT = "cnv" | "snp";
interface IgvBodyI {
    type?: MutationT
    parentT?: parentT,
    caseId?: string,
    gen: string,
    chromosome?: string,
    position?: string,
    viewT?: AlignmentT
    assembly?: string,
    library?: string,
    mutation: MutationI[],
    sampleNameSelected?: string,
}
export default function IgvBody(props: IgvBodyI) {
    const parentT = props.parentT ?? "modal";
    const isCnv = props.type === "cnv";
    const isPage = parentT !== "modal";
    const { actualLang, dispatch: { translate } } = useContext(LangContext);
    const alignmentTypeOptions: LabelValueI[] = [
        isCnv ?
            { label: "CNV", value: "variant" } :
            { label: translate(`common.variant`), value: "variant" },
        { label: translate(`common.gene`), value: "gene" }
    ]
    const getInitialAlignemntType = () => {
        let iAlignemntType: any = alignmentTypeOptions[0];
        if (props.viewT)
            iAlignemntType = alignmentTypeOptions.find((x: any) => x.value === props.viewT) ?? iAlignemntType
        return iAlignemntType
    }
    const [alignmentType, setalignmentType] = useState(getInitialAlignemntType());

    const sampleIgvOptions: LabelValueI[] = (props?.mutation ?? [])
        .map((x: MutationI): LabelValueI => { return ({ label: x.sampleName, value: x.sampleName, ...x }) })
    const getInitialSampleIgv = () => {
        let initialSample: any = sampleIgvOptions[0];
        if (props.sampleNameSelected)
            initialSample = sampleIgvOptions.find((x: any) => x.value === props.sampleNameSelected) ?? initialSample
        return initialSample
    }
    const initialSampleIgv = getInitialSampleIgv();
    const [sampleIgv, setsampleIgv] = useState<any>(initialSampleIgv);

    const data = {
        type: isCnv ? "cnv" : "snp",
        assembly: props.assembly,
        gen: props.gen,
        chromosome: props.chromosome,
        position: props.position,
        sampleName: sampleIgv.sampleName,
        view: alignmentType.value,
        library: props.library,
        cnvType: isCnv && props.mutation.length > 0 ? props.mutation[0].effect : undefined
    }
    let actualSampleIgv = (props?.mutation ?? []).find((x: MutationI) => x.sampleName === sampleIgv.value)

    let igvElementInit = <Igv data={data} />
    const [igvElement, setigvElement] = useState<JSX.Element>(igvElementInit)
    useEffect(() => {
        setsampleIgv(getInitialSampleIgv())
        setalignmentType(getInitialAlignemntType())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sampleNameSelected, props.viewT])
    useEffect(() => {
        setigvElement(<></>)
        setTimeout(() => {
            setigvElement(igvElementInit)
        }, 10);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sampleIgv, alignmentType])
    const selectAlignmentType = (
        <ReactSelect options={alignmentTypeOptions}
            height="29px"
            label={"Alignment type"}
            labelBold={true}
            divStyles={{ minWidth: "130px", marginBottom: "0" }}
            labelStyles={{ color: "#444", fontSize: "12px" }}
            value={alignmentType}
            onChange={(e: any) => {
                setalignmentType(e);
            }}
        />)
    const selectSample = (
        <ReactSelect options={sampleIgvOptions}
            height="29px"
            label={"Sample"}
            divStyles={{ minWidth: "170px", marginBottom: "0", marginLeft: "3px" }}
            labelBold={true}
            labelStyles={{ color: "#444", fontSize: "12px" }}
            value={sampleIgv}
            onChange={(e: any) => {
                setsampleIgv(e);
            }}
        />
    )

    const getNewTabLink = () => {
        if (isPage) return ""
        let samples = props.mutation.map((x: MutationI) => `{"effect":"${x.effect}", "sampleName":"${x.sampleName}"` + (x.impact ? `,"impact":"${x.impact}"` : "") + "}");
        let link = `/cases/_igv/caseId/${props.caseId}/type/${isCnv ? "cnv" : "snp"}`;
        link += `/sampleSelected/${props.sampleNameSelected}/gen/${props.gen}/chPos/${props.chromosome}:${props.position}/ref/${props.assembly}/samples`
        link += `/{"samples":[${samples}]}`
        return link
    }

    return (
        <div id={"IgvBody"}
            style={{
                background: isPage ? "none" : "linear-gradient(to bottom, #f4f4f4, #FFFFFF)",
                padding: isPage ? "10px" : "20px",
                borderTop: "none"
            }}>
            <Row style={{
                display: "flex",
                backgroundColor: isPage ? "none" : "#F4F4F4",
                margin: 0,
                marginBottom: "5px",
                justifyContent: "space-between"
            }}>
                <h4 style={{ color: "darkslategray", fontWeight: "bold", marginBottom: 0, width: "100%" }}>
                    IGV - Integrate Genomic Viewer &nbsp;&nbsp;
                    {
                        !isPage &&
                        <Tooltip label={"Open on new tab"} >
                            <a href={getNewTabLink()} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faExternalLinkAlt} style={{ fontSize: "17px" }} />
                            </a>
                        </Tooltip>
                    }
                    <hr style={{ marginBottom: 0 }} />
                </h4>
            </Row>
            <Row style={{
                display: "flex",
                backgroundColor: isPage ? "none" : "#F4F4F4",
                margin: 0,
                marginBottom: "10px",
                justifyContent: "space-between"
            }}>
                <Col sm={12}
                    md={7}
                    xl={8}
                    style={{ width: "100%", padding: "0 0 0 10px", marginTop: "20px" }}>
                    <div style={{ display: "flex" }}>
                        <h6>
                            {
                                actualLang.language === "ES" ?
                                    <>
                                        Lecturas de la muestra
                                        <span style={{ color: "darkslategray", verticalAlign: "top", margin: "0px 5px 0px 5px" }}>
                                            {actualSampleIgv?.sampleBadge}
                                        </span>
                                        para el gen <strong style={{ color: "darkslategray" }}>{` ${props.gen} `}</strong>
                                        {" - "}
                                        {isCnv ?
                                            <BadgeCnv cnvType={actualSampleIgv?.effect ?? ""} /> :
                                            <BadgeVariantImpact impact={actualSampleIgv?.impact ?? ""} label={actualSampleIgv?.effect} />}
                                    </> :
                                    <>
                                        <span style={{ color: "darkslategray", verticalAlign: "top", margin: "0px 5px 0px 5px" }}>
                                            {actualSampleIgv?.sampleBadge}
                                        </span>sample readout for the <strong style={{ color: "darkslategray" }}>{` ${props.gen} `}</strong> gene
                                        {" - "}
                                        {isCnv ?
                                            <BadgeCnv cnvType={actualSampleIgv?.effect ?? ""} /> :
                                            <BadgeVariantImpact impact={actualSampleIgv?.impact ?? ""} label={actualSampleIgv?.effect} />}
                                    </>
                            }
                        </h6>
                    </div>
                </Col>
                <Col sm={12} md={5} xl={4} style={{ float: "right", padding: "0" }}>
                    {props.mutation && props.mutation.length > 1 ?
                        <Row style={{ margin: 0, padding: 0 }}>
                            <Col sm={12} md={5} xl={5} style={{ padding: "0 5px 0px 5px" }}>
                                {selectAlignmentType}
                            </Col>
                            <Col sm={12} md={6} xl={7} style={{ padding: "0 5px 0px 5px" }}>
                                {selectSample}
                            </Col>
                        </Row>
                        :
                        <Row style={{ margin: 0, padding: 0 }}>
                            <Col sm={12} md={12} xl={12} style={{ padding: "0 5px 0px 5px" }}>
                                {selectAlignmentType}
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            {igvElement}
        </div>
    )
}

export type { MutationI }