import React from 'react';
import { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Paginator from "../Common/Paginator/Paginator";
import PanelGenesTable from "./PanelGenesTable";
import ProcessPanelGene from "./ProcessPanelGenes/ProcessPanelGene";
import HomeFilter, { InputFilterI } from "../Layout/Home/HomeFilter";
import HomeLayout from "../Layout/Home/HomeLayout";
import { LangContext } from "../../Contexts/Lang";
import PanelGenesContext from "../../Contexts/PanelGenes/PanelGenesContext";
import HomeToolkit from "../Layout/Home/HomeToolkit";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import GensetService from "../../Services/GensetService";
// import GensetServices from "../../Services/GensetService"
interface PanelGenesI {
    history: any,
}
export default function PanelGenes(props: PanelGenesI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { panels, selecteds, filters, dispatch: {
        getPanels, setFilters, cleanFilters, handleSelecteds, handleSelectAll } } = useContext(PanelGenesContext);

    const [processGene, setprocessGene] = useState<{
        "open": boolean,
        "id": string | undefined,
        "region": string | undefined
    }>({ open: false, id: undefined, region: undefined })
    const handleCloseProcessGene = () => setprocessGene({ open: false, id: undefined, region: undefined });
    const handleShowProcessGene = (id?: string, region?: string) => {
        setprocessGene({ open: true, id: id ? id : undefined, region: region ? region : undefined })
    };
    const GenesetS = new GensetService();

    const paginator = Paginator({
        reloadCall: () => { },
        onChangePage: (i: any) => {
            handleGetPanels(i + 1);
            paginator.setActualPage(i)
        },
        onItemsPerPageChange: (i: any) => {
            handleGetPanels(1, i);
            paginator.pageSize_set(i)
        }
    })

    function handleGetPanels(page?: number, pageSize?: number, filtersClean?: boolean, then?: any): void {
        getPanels(
            filtersClean ? undefined : filters,
            page ?? paginator.actualPage + 1,
            pageSize ?? paginator.itemsPerPage)
            .then(() => {
                if (page !== undefined) paginator.setActualPage(page - 1)
                handleSelecteds("")
                if (then) then()
            })
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted && !panels.loaded && !panels.loading) handleGetPanels()
        if (isMounted && panels.loaded) {
            paginator.pageSize_set(panels.data?.rowsCount ?? 0)
            paginator.setActualPage(panels.data?.pageNumber ?? 0)
        }
        if (isMounted && panels.refresh) handleGetPanels(1, paginator.itemsPerPage, true)
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panels])

    function handleSortByFilter(value: string) {
        if (value !== filters.sortColumn) {
            setFilters("sortColumn", value)
            handleGetPanels(1);
        }
    }

    const inputFilterData: InputFilterI[] = [
        {
            type: "text",
            stateName: "panelnameSearch",
            placeholder: `${translate('variant.genePanelName')}...`,
        },
        {
            type: "text",
            stateName: "genenameSearch",
            placeholder: translate('common.geneName'),
        },
    ]
    return (
        <>
            {processGene.open && <ProcessPanelGene history={props.history}
                show={processGene.open}
                handleClose={handleCloseProcessGene}
                handleReload={() => handleGetPanels(1)}
                editGenePanel={{
                    id: processGene.id,
                    region: processGene.region
                }}
            />
            }
            <HomeLayout history={props.history}
                filterTitle={translate('common.genesPanel')}
                rowsAmount={{
                    loading: !panels.loaded || panels.loading,
                    amount: panels?.data?.rowsCount ?? 0,
                }}
                Filters={
                    <div style={{ width: "100%" }}>
                        <HomeFilter filters={filters}
                            // reload={() => handleGetPanels(1)}
                            setFilter={setFilters}
                            handleCleanFilter={() => {
                                cleanFilters();
                                handleGetPanels(1, paginator.itemsPerPage, true)
                            }}
                            callFilter={() => {
                                handleGetPanels(1)
                            }}
                            inputFilters={inputFilterData}
                        />
                    </div>
                }
                FiltersEmpty={
                    <div style={{ width: "100%" }}>
                        <HomeFilter inputFilters={inputFilterData} />
                    </div>
                }
                Toolkit={
                    <HomeToolkit rowData={(panels.data?.results ?? [])}
                        rowDataSelected={selecteds}
                        handleSelectAll={handleSelectAll}
                        multipleActionsOptions={[
                            {
                                icon: faTrash,
                                label: translate('home.delete'),
                                handleClick: () => {
                                    if (panels.data?.results.length > 0) {
                                        const panelsToDelete = panels.data.results
                                            .filter((x: any) => selecteds.indexOf(x.key) > -1)
                                            .map((x: any) => { return { id: `${x.key}`, regionKey: x.regionKey } })
                                        console.log(panelsToDelete)
                                        GenesetS.delete_panels(panelsToDelete).then(() => {
                                            handleSelecteds("")
                                            handleGetPanels(1);
                                        })
                                    }
                                },
                                modalWarning: {
                                    label: selecteds.length > 1 ?
                                        translate("genesPanel.confirmDeleteAllSelectedGenesPanels") :
                                        translate("genesPanel.confirmDeleteSelectedGenPanel"),
                                    buttonLabel: translate('home.delete')
                                }
                            },
                        ]}
                        addElement={{
                            tooltipLabel: translate('genesPanel.addGenesPanel'),
                            handleCreate: handleShowProcessGene,
                        }}
                        sortBy={{
                            value: filters.sortColumn ?? "",
                            options: "default",
                            handleSortBy: handleSortByFilter
                        }}
                    />
                }
                Table={
                    <Card style={{ borderRadius: "0px" }}>
                        <Card.Body style={{ padding: "0px" }}>
                            {(!panels.loading && (panels?.data?.results ?? []).length === 0) ?
                                <div>
                                    <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                        {translate('genesPanel.noGenesWereFound')}
                                    </p>
                                </div> :
                                <PanelGenesTable genesSelecteds={selecteds}
                                    handleGenesSelecteds={handleSelecteds}
                                    handleShowProcessGene={handleShowProcessGene}
                                    handleReload={() => handleGetPanels(1)}
                                    history={props.history}
                                />
                            }
                        </Card.Body>
                        {(panels?.data?.results ?? []).length > 0 ?
                            <Card.Footer>
                                {paginator.component}
                            </Card.Footer>
                            : <></>}
                    </Card>
                }
            />
        </>
    )
}
