import React from 'react';
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import UserContext from "../../../Contexts/User/UserContext";
import UserPassForm from "./UserPassForm";
import { UserForm } from "./UserForm";

interface UserI {
    history: any,
    editMode?: boolean
}
function User(props: UserI) {
    const { user } = useContext(UserContext);
    return (
        <div style={{ marginTop: "10px", padding: "20px 30px 0" }}>
            <h3>&nbsp;
                <FontAwesomeIcon icon={faAddressCard} style={{ color: "lightgray", marginRight: "10px" }} />
                {user.data.fullName} ({user.data.username})
            </h3>
            <hr />
            <div style={{}}>
                <UserForm history={props.history}
                    editMode={props.editMode}
                />
                <br />
                <UserPassForm history={props.history}
                    editMode={props.editMode}
                    showTitle={true}
                />
            </div>
            <br />
        </div>
    )
}



export { User }