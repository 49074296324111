import React from 'react';
import { useContext, useState } from 'react'
import { Col, Row, Table, Form, Dropdown } from 'react-bootstrap'
import { LangContext } from '../../Contexts/Lang'
import '../Common/table.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCheckCircle, faCalendarDay, faEdit, faTrash, faTimesCircle, faCog, faKey } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from '../Common/Tooltip/Tooltip'
import UserService from '../../Services/UserServices'
import CopyToClipboard from '../Common/CopyToClipboard'
import { ModalWarning } from '../Common/ModalWarning'
import EnableDisableBadge from '../Common/Badges/EnableDisable'
import { UserI } from '../../Contexts/interfaces'
import { EditT } from './ProcessUser/ProcessUser'

interface UsersTableI {
  history: any,
  users: UserI[],
  usersSelecteds: any[],
  handleShowProcessUser(editT: EditT, id?: number | undefined): void,
  handleReload(): void,
  handleUsersSelecteds(userId: string): void
}
export default function UsersTable(props: UsersTableI) {
  const { dispatch: { translate } } = useContext(LangContext);
  const [showWarningDelete, setshowWarningDelete] = useState<{ show: boolean, id: string | undefined }>({ show: false, id: undefined });
  const handleCloseWarningDelete = () => setshowWarningDelete({ show: false, id: undefined });
  const handleShowWarningDelete = (id: string) => setshowWarningDelete({ show: true, id: id });

  const rows = (props.users ?? []).map((x: any, i: number) => (
    <TableRow
      key={i}
      actualRow={x}
      handleReload={props.handleReload}
      handleShowProcessUser={props.handleShowProcessUser}
      history={props.history}
      usersSelecteds={props.usersSelecteds}
      handleUsersSelecteds={props.handleUsersSelecteds}
      handleShowWarningDelete={handleShowWarningDelete}
    />
  ))
  return (
    <>
      <ModalWarning labelTitle={translate('common.confirm')}
        label={translate('user.confirmDeleteUser')}
        labelButton={translate('common.delete')}
        showState={showWarningDelete.show}
        handleClose={handleCloseWarningDelete}
        onClick={() => {
          if (showWarningDelete.id) {
            const US = new UserService();
            // props.handleReload();
            US.deleteUser(showWarningDelete.id)
              .then(() => {
                props.handleReload()
              })
              .catch((e: any) => {
                props.handleReload()
              })

          }
        }}
      />
      <div style={{}}>
        <Table
          responsive="md"
          className="selectTable"
          style={{ marginBottom: '-1px', overflowY: 'hidden' }}
        >
          <tbody>{rows}</tbody>
        </Table>
      </div>
    </>
  )
}

interface TableRowI {
  history: any
  actualRow: UserI
  handleReload: any
  usersSelecteds: any[]
  handleUsersSelecteds(userId: string): void,
  handleShowProcessUser(editT: EditT, id?: number | undefined): void,
  handleShowWarningDelete(id: string): void
}

function TableRow(props: TableRowI) {
  const { dispatch: { translate } } = useContext(LangContext)
  const US = new UserService()
  // console.log("props.handleReload")
  // console.log(props.handleReload)
  const getFirstColumn = () => {
    return (
      <div style={{ margin: '5px 0 0 0' }}>
        <Row style={rowStyles}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faUser} style={{ border: 'none', marginRight: '5px', color: 'darkgray', }} />
            <Tooltip label={translate('user.fullName')}>
              <span style={{ color: 'darkslategray', marginBottom: 0, fontSize: '13px', fontWeight: 'bold', marginTop: '4px' }}>
                {props.actualRow.fullName}
              </span>
            </Tooltip>
            <CopyToClipboard valueToCopy={props.actualRow.fullName} />
          </div>
        </Row>
        <Row style={{ ...rowStyles, color: 'gray', marginBottom: 0, fontSize: '12px', display: 'flex', alignItems: 'center' }}>
          <Tooltip label={translate('common.username')}>
            <strong style={{ marginTop: '5px' }}>
              {props.actualRow.username}
            </strong>
          </Tooltip>
          <CopyToClipboard valueToCopy={props.actualRow.username} />
          {props.actualRow?.email && <>
            <span style={{ marginTop: '3px' }}>
              -&nbsp;&nbsp;{props.actualRow.email}
            </span>
            <CopyToClipboard valueToCopy={props.actualRow.email} />
          </>}
        </Row>
      </div>
    )
  }
  const getSecondColumn = () => {
    return (
      <>
        <Row style={{ margin: 0, padding: 0 }}>
          <Tooltip label={translate('common.domain')}>
            <p
              style={{
                color: 'darkslategray',
                marginBottom: 0,
                fontSize: '13px',
              }}
            >
              {props.actualRow?.domainName}
            </p>
          </Tooltip>
        </Row>
        <Row style={{ margin: 0, padding: 0 }}>
          <Tooltip label={translate('common.roll')}>
            <p style={{ color: 'gray', marginBottom: 0, fontSize: '13px' }}>
              {(props.actualRow?.rolestoString ?? '')
                .replaceAll('ROLE_', '')
                .toLowerCase()}
            </p>
          </Tooltip>
        </Row>
      </>
    )
  }
  const getLastLoginColumn = () => {
    const dateSplited = props.actualRow.lastLoginToString.split(' ')
    const createdDateSplited = props.actualRow.simpleDateFormatCreated.split(
      ' ',
    )
    return (
      <>
        <Row style={{ margin: 0, padding: 0, fontSize: '11px', color: 'gray', alignItems: 'baseline', }}>
          Last Login : &nbsp;
          <FontAwesomeIcon icon={faCalendarDay} style={{ border: 'none', marginRight: '5px', color: 'darkgray', }} />
          <span style={{ color: 'gray', marginBottom: 0 }}>
            {dateSplited && dateSplited.length > 0 ? dateSplited[0] : '-'}
          </span>
          &nbsp;-&nbsp;
          <span style={{ color: 'gray', marginBottom: 0 }}>
            {dateSplited && dateSplited.length > 1 ? dateSplited[1] : '-'}
          </span>
        </Row>
        <Row style={{ margin: 0, padding: 0, fontSize: '11px', color: 'gray' }}>
          {props.actualRow.sessionState}
        </Row>
        <Row style={{ margin: 0, padding: 0, fontSize: '11px', color: 'gray' }}>
          Creation Date :&nbsp;
          <FontAwesomeIcon icon={faCalendarDay} style={{ alignSelf: 'center', border: 'none', marginRight: '5px', color: 'darkgray' }}
          />
          {createdDateSplited && createdDateSplited.length > 0
            ? createdDateSplited[0]
            : '-'}
          &nbsp;-&nbsp;
          {createdDateSplited && createdDateSplited.length > 1
            ? createdDateSplited[1]
            : '-'}
        </Row>
      </>
    )
  }
  const getEnableColumn = () => {
    return (
      <Row style={{ margin: 0, justifyContent: 'center' }}>
        <EnableDisableBadge enable={props.actualRow?.enabled} />
      </Row>
    )
  }
  const getDropdown = () => {
    return (
      <Dropdown alignRight={true}>
        <div style={{ display: 'flex', placeContent: 'center' }}>
          <Tooltip label={translate('user.user-actions')}>
            <Dropdown.Toggle
              variant="outline-light"
              id="dropdown-basic"
              style={{ boxShadow: 'none', border: 'none', borderRadius: '50px', fontSize: '15px', marginRight: '5px', }}>
              <FontAwesomeIcon
                icon={faCog}
                style={{ border: 'none', color: 'gray' }}
                data-tip="Add case"
              />
            </Dropdown.Toggle>
          </Tooltip>
        </div>
        <Dropdown.Menu style={{ minWidth: '170px', borderRadius: '0', overflow: 'visible', width: "max-content" }} >
          <Dropdown.Item
            style={{ minWidth: '100px', borderRadius: '0' }}
            variant="secondary"
            onClick={() => {
              if (props.actualRow.enabled)
                US.blockUser(`${props.actualRow.id}`).then(() => props.handleReload())
              else
                US.unblockUser(`${props.actualRow.id}`).then(() => props.handleReload())
            }}
          >
            <Row>
              <Col sm={2} style={{ padding: '0 0 0 3px' }}>
                {props.actualRow.enabled ? (
                  <FontAwesomeIcon icon={faTimesCircle} style={{ border: 'none', color: 'gray' }} />
                ) : (
                  <FontAwesomeIcon icon={faCheckCircle} style={{ border: 'none', color: 'gray' }} />
                )}
              </Col>
              <Col sm={9} style={{ padding: '0 12px 0 0' }}>
                {props.actualRow.enabled
                  ? translate('common.disable')
                  : translate('common.enable')}
              </Col>
            </Row>
          </Dropdown.Item>
          <Dropdown.Item style={{ borderRadius: '0' }}
            variant="secondary"
            onClick={() => { props.handleShowProcessUser("user", props.actualRow.id) }}
          >
            <Row>
              <Col sm={2} style={{ padding: '0 0 0 3px' }}>
                <FontAwesomeIcon icon={faEdit} style={{ border: 'none', color: 'gray' }} />
              </Col>
              <Col sm={9} style={{ padding: '0 12px 0 0' }}>
                {translate('home.table.edit')}
              </Col>
            </Row>
          </Dropdown.Item>
          <Dropdown.Item style={{ borderRadius: '0' }}
            variant="secondary"
            onClick={() => {
              props.handleShowProcessUser("password", props.actualRow.id)  //handleShowUserPassForm
              // props.handleShowUserPassForm(props.actualRow.id) //Algo asi
            }}
          >
            <Row>
              <Col sm={2} style={{ padding: '0 0 0 3px' }}>
                <FontAwesomeIcon icon={faKey} style={{ border: 'none', color: 'gray' }} />
              </Col>
              <Col sm={9} style={{ padding: '0 12px 0 0' }}>
                {translate('user.change-password')}&nbsp;&nbsp;&nbsp;
              </Col>
            </Row>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item style={{ minWidth: '100px', borderRadius: '0' }} variant="secondary"
            onClick={(e: any) => {
              // console.log(props.actualRow)
              props.handleShowWarningDelete(`${props.actualRow.id}`)
            }}
          >
            <Row>
              <Col sm={2} style={{ padding: '0 0 0 3px' }}>
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ border: 'none', color: 'gray' }}
                />
              </Col>
              <Col sm={9} style={{ padding: '0 12px 0 0' }}>
                {translate('home.delete')}
              </Col>
            </Row>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
  const columnStyles = { border: 'none', verticalAlign: 'middle', cursor: 'pointer' }
  const rowStyles = { margin: 0 }
  const handleRowClick = () => {
    // props.history.push(`/cases/caseId/${props.actualRow.id}/`);
  }

  return (
    <tr style={{ borderBottom: '1px solid lightgray' }} className="tr">
      <td style={{ border: 'none', width: '5px', verticalAlign: 'middle', textAlign: 'center', }} >
        <Form.Check type="checkbox"
          checked={props?.usersSelecteds?.find((y: any) => `${y}` === `${props.actualRow.id}`) ?? false}
          onChange={() => { props.handleUsersSelecteds(`${props.actualRow.id}`) }}
        />
      </td>
      <td style={columnStyles} onClick={handleRowClick}>
        {getFirstColumn()}
      </td>
      <td style={columnStyles} onClick={handleRowClick}>
        {getSecondColumn()}
      </td>
      <td style={{ ...columnStyles, width: "100px" }} onClick={handleRowClick}>
        {getEnableColumn()}
      </td>
      <td style={{ ...columnStyles, width: "250px" }} onClick={handleRowClick}>
        {getLastLoginColumn()}
      </td>
      <td style={{ border: 'none', verticalAlign: 'middle', width: '50px' }}>
        {getDropdown()}
      </td>
    </tr>
  )
}
