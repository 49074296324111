import React from 'react';
import { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { LangContext } from "../../../../../Contexts/Lang";
import { Menu, ProSidebar, SidebarHeader } from "react-pro-sidebar";
import 'react-pro-sidebar/dist/css/styles.css';
import "./sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import {
	SamplesAccordion,
	VariantAccordion,
	QualityCoverageAccordion,
	PredictionScoresAccordion,
	FrequencyAccordion,
	GenesAccordion,
	PhenotypeAccordion,
	VariantGroupAccordion,
	ConsequenceAccordion,
	EvidenceAccordion,
	MitochondrialAccordion,
	PharmGkb,
	SomaticAccordion
} from './FilterAccordions';
import { ModalLoadFilter, ModalSaveFilter } from './FilterModals';
import PanelHeader from './PanelHeader';
import PanelFooter from './PanelFooter';
import QuerySheetServices from '../../../../../Services/QuerySheetService';
import { checkKeyEnterPress } from '../../../../../Helpers/Helpers';
import QueryContext from '../../../../../Contexts/Query/QueryContext';

const AccordionDivider = () => (<div style={{ height: "1px" }}></div>)


interface FilterPanelI {
	history: any,
	filterCollapsed: any,
	setFilterCollapsed: any,
	handleFilterRequest?: any,
	handleReload: any,
	handleLoadFilter: any,
	handleCleanAllFilters(withUrl?: boolean): void,
	filtersTabs: { urlData: string, filterName: string, id: number }[],
	handleCreateFilter: any
}
export default function FilterPanel(props: FilterPanelI) {
	const { studyId, queryCombos,
		// filters states
		filters_initialState, state_request_dictParser,
		filters_samples, filters_variants, filters_qc, filters_frequency, filters_predictors, filters_genes, filters_phenotypes,
		filters_variantgroups, filters_consequence, filters_evidence, filters_mitochondrial, filters_pharmgkb, filters_somatic,
		// filters params
		filters_samplesParams,
		// methods
		dispatch: { setfilters_samples, parseFilters_RequestToState, parseFilters_StateToRequest, getQueryCombos, refreshQueryVariants } } = useContext(QueryContext);
	const { dispatch: { translate } } = useContext(LangContext);
	const QuerySheetS = new QuerySheetServices();

	const [modalSave, setmodalSave] = useState(false)
	const handleClosemodalSave = () => setmodalSave(false);
	const handleShowmodalSave = () => setmodalSave(true);

	const [modalLoad, setmodalLoad] = useState(false)
	const handleClosemodalLoad = () => setmodalLoad(false);
	const handleShowmodalLoad = () => setmodalLoad(true)


	let filters_initialState2 = { ...filters_initialState }

	//---------------Filter Accordion States-----------------------------------------------

	function getFilterStateSeted(state: any) {
		const stateName = Object.getOwnPropertyNames(state)[0];
		state = state[stateName];
		let aa: any = filters_initialState2
		const fieldsSeted = Object.keys(state)
			.filter((x: string) => {
				return JSON.stringify(state[x]) !== JSON.stringify(aa[stateName][x]);
			})
		return fieldsSeted
	}
	function getAllFilterStateSeted() {                                                                             // CREO QUE VA A QUERY STATE
		const myStates: any = [
			{ filters_samples: filters_samples },
			{ filters_variants: filters_variants },
			{ filters_qc: filters_qc },
			{ filters_frequency: filters_frequency },
			{ filters_predictors: filters_predictors },
			{ filters_genes: filters_genes },
			{ filters_phenotypes: filters_phenotypes },
			{ filters_variantgroups: filters_variantgroups },
			{ filters_consequence: filters_consequence },
			{ filters_evidence: filters_evidence },
			{ filters_mitochondrial: filters_mitochondrial },
			{ filters_pharmgkb: filters_pharmgkb },
			{ filters_somatic: filters_somatic },
		];
		return [].concat(...myStates.map((x: any) => getFilterStateSeted(x)))
	}

	function handleTogglePanelFilter() {
		props.setFilterCollapsed(!props.filterCollapsed)
	}
	function handleCollapsePanelFilter() {
		props.setFilterCollapsed(true)
	}
	function handleLoadFilter(urlData: string, filterName: string, id: number, description?: string) {
		props.handleCleanAllFilters(false)
		handleCollapsePanelFilter()
		props.handleLoadFilter(urlData, filterName, id, description)
		urlData += `&studyId=${studyId}`
		props.history.push(urlData)
		refreshQueryVariants()
	}
	function handleFilterRequest() {
		let req: any = parseFilters_StateToRequest();
		// if (!createQueryTab)
		req["studyId"] = studyId
		let myQuery: string = "";
		Object.keys(req).forEach((x: any) => {
			if (Array.isArray(req[x])) {
				myQuery += req[x].map((y: string, i: number) => {
					return (
						`${encodeURIComponent(x)}[]=${encodeURIComponent(y)}&`
					);
				}).join("");
			} else {
				myQuery += `${x}=${req[x]}&`
			}
		})
		myQuery = myQuery.substring(0, myQuery.length - 1)
		const createQueryTab = props.filtersTabs && props.filtersTabs.length === 0
		if (createQueryTab)
			props.handleCreateFilter(myQuery)
		props.history.push(myQuery)
		refreshQueryVariants()
		handleCollapsePanelFilter()
	}

	function handleSaveFilterRequest(name: string, description: string) {
		let req = parseFilters_StateToRequest();

		req = Object.fromEntries(Object.entries(req).filter(xx => xx[0].toLowerCase().indexOf("sample_") === -1))
		// console.log("req2")
		// console.log(req2)
		return QuerySheetS.save_results(studyId, req, name, description)
			.then((r: any) => {
				if (r.data && r.data.errors !== null) {
					return ({ error: r.data.errors })
				} else {
					getQueryCombos(+studyId)
				}
				return
			}).catch((e: any) => {
				console.log("e")
				console.log(e)
			});
	}
	const isMitochondrialCase = (queryCombos.data?.study?.subtype ?? "").toUpperCase().trim() === "MT-DNA"
	const isSomaticCase = (queryCombos.data?.study?.subtype ?? "").toUpperCase().trim() === "SOMATIC"
	const isArray = (queryCombos.data?.study?.subtype ?? "").toUpperCase().trim() === "ARRAY"
	const getSavedFiltersByTypeAndSubtype = () => {
		return (queryCombos.data?.savedFilters ?? []).filter((x: any) => {
			const typeAndSubtypes = [queryCombos.data?.study.type.toLowerCase().trim(), queryCombos.data?.study.subtype.toLowerCase().trim()]
			if (x.filters) {
				const urlDataParams = (parseFilters_RequestToState(x.filters, true) ?? []).map(x => Object.keys(x)[0]);
				const filterArray = (urlDataParams ?? []).filter((y: string) => {
					const dictParsParam = state_request_dictParser.find((z: any) => z.request === y)
					let belong = true;
					(dictParsParam?.excludeTypes ?? []).forEach((z: any) => {
						if (typeAndSubtypes.indexOf(z) > -1) {
							belong = false;
						}
					})
					return belong
				})
				return filterArray.length === (urlDataParams ?? []).length
			}
			return false
		})
	}
	return (
		<>
			{modalSave && <ModalSaveFilter showState={modalSave}
				handleClose={handleClosemodalSave}
				handleSaveFilter={handleSaveFilterRequest}
			/>}
			{modalLoad && <ModalLoadFilter showState={modalLoad}
				handleClose={handleClosemodalLoad}
				savedFilters={getSavedFiltersByTypeAndSubtype()}
				handleLoadFilter={handleLoadFilter}
				filterTabs={props.filtersTabs}
			/>}

			<ProSidebar style={{ height: "calc(100vh - 80px)", backgroundColor: "#F4F4F4" }}
				collapsed={props.filterCollapsed}
				onKeyDown={(e: any) => { checkKeyEnterPress(e, () => handleFilterRequest()) }}>
				<SidebarHeader>
					<PanelHeader handleShowSaveModal={handleShowmodalSave}
						handleShowmodalLoad={handleShowmodalLoad}
						enableSaveFilter={getAllFilterStateSeted().length > 0}
					/>
				</SidebarHeader>
				<Menu iconShape="square"
					onKeyDown={(e: any) => { checkKeyEnterPress(e, () => handleFilterRequest()) }}>
					{
						queryCombos.data?.study.samples &&
						queryCombos.data?.study.samples.length > 1 &&
						<>
							<SamplesAccordion state={filters_samples}
								setState={setfilters_samples}
								initState={filters_initialState?.filters_samples}
								options={filters_samplesParams}
								samples={queryCombos.data?.study?.samples ?? []}
								isFamiliar={queryCombos.data?.study.type.trim().toLowerCase() === "familiar"} />
							<AccordionDivider />
						</>
					}
					<VariantAccordion isMitochondrialCase={isMitochondrialCase} />
					<AccordionDivider />
					{!isMitochondrialCase &&
						<>
							<QualityCoverageAccordion isSomatic={isSomaticCase} />
							<AccordionDivider />
							<FrequencyAccordion />
							<AccordionDivider />
						</>
					}
					<PredictionScoresAccordion isMitochondrialCase={isMitochondrialCase} />
					<AccordionDivider />
					<GenesAccordion />
					<AccordionDivider />
					{!isMitochondrialCase && <PhenotypeAccordion />}
					<AccordionDivider />
					<VariantGroupAccordion />
					<AccordionDivider />
					<ConsequenceAccordion />
					<AccordionDivider />
					<EvidenceAccordion isMitochondrialCase={isMitochondrialCase} />
					{isSomaticCase &&
						<>
							<AccordionDivider />
							<SomaticAccordion />
						</>
					}
					{isMitochondrialCase &&
						<>
							<AccordionDivider />
							<MitochondrialAccordion />
						</>
					}
					{isArray &&
						<>
							<AccordionDivider />
							<PharmGkb />
						</>
					}
					<hr />
					<br />
				</Menu>
				<PanelFooter handleFilter={handleFilterRequest}
					handleCleanFilter={() => props.handleCleanAllFilters(true)}
					enableFilter={getAllFilterStateSeted().length > 0}
				/>
			</ProSidebar>
			<Button className="sidebar_btn"
				variant="danger"
				style={{
					backgroundColor: "#FF4E58",
					fontSize: "11px",
					height: "28px",
					transform: "rotate(-90deg)",
					width: "83px",
					marginTop: "81px",
					marginLeft: "-28px",
					display: "flex",
					boxShadow: "0px 2px 20px rgb(0 0 0 / 85%)"
				}}
				onClick={handleTogglePanelFilter}
			>
				{translate("common.filters").toUpperCase()}
				<FontAwesomeIcon icon={faAngleUp} style={{ color: "white", fontSize: "15px", marginLeft: "6px" }} />
			</Button>
		</>
	)
}




