import React from 'react';
import { useContext } from 'react';
import { LangContext } from "../../../../Contexts/Lang";
import { Form, Badge, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { roundWithTooltipoTo0 } from '../../../../Helpers/Helpers';
import CopyToClipboard from '../../../Common/CopyToClipboard';
import MoreInfoPopoverButton from '../../../Common/MoreInfoPopoverButton';
import { SampleInfoIgvDataI } from '../../../IGV/ModalSamplesIgv';
import { FrequencyMitochondrial } from '../../../Common/VariantFrequency';
import { LinkGoogle, LinkGoogleScholar } from '../../../Common/Links/LinksGoogle';
import BadgeVariantImpact from '../../../Common/Badges/BadgeVariantImpact';
import BadgeVariantMitomap from '../../../Common/Badges/BadgeVariantMitomap';
import { ModalCommentGroupT } from '../../../Common/ModalCommentGroup/ModalCommentGroup';
import LinkReportedVariant from '../../../Common/Links/LinkReportedVariant';
import QueryContext, { ColumnIdT, QueryVariantsTableT } from '../../../../Contexts/Query/QueryContext';
import { Tooltip } from '../../../Common/Tooltip/Tooltip';
import WarningTrianglePopover from '../../../Common/WarningTrianglePopover';
import { StudyI } from '../../../../Contexts/Cases/CasesContext';
import { ListaCigotasI } from '../../../../Contexts/interfaces';
import LiftOverGRChSwitcher from '../../../Common/LiftOverGRChSwitcher';
import ThemeContext from '../../../../Contexts/Theme/ThemeContext';
import LinkTooltip from '../../../Common/Links/LinkTooltip';
import BadgeVeredictPredictors from '../../../Common/Badges/BadgePredictorsVeredict';
import CommentIconPopover from 'src/Components/Common/ModalCommentGroup/CommentIconPopovert';
import { PLiBadge, ZMissBadge } from 'src/Components/Common/Badges/BadgesPliZscore';
import {
	LinkENSEMBL,
	LinkGeneCards,
	LinkGnomAd,
	LinkLocalGen,
	LinkMutalizer,
	LinkMutalizer_validator,
	LinkMutationTaster,
	LinkNCBI,
	LinkOMIM,
	LinkUniprot,
	LinkClinGen,
	NcbiDbsnpLinksByVcfId,
	NcbiClinvarLinkByVcfId,
	LinkDecipher
} from '../../../Common/Links/Links';
import PopOverOmimGenes from 'src/Components/Common/PopOverOmimGenes';
import CustomAcmgGuideByGene from 'src/Components/Common/CustomAcmgGuideByGene/CustomAcmgGuideByGene';
import { getBadgeSpliceAi } from '../Detail/Predictors/SplicingTable';
import BadgeVariantVeredict from 'src/Components/Common/Badges/BadgeVariantVeredict';
import BadgeVariantEvidence from 'src/Components/Common/Badges/BadgeVariantEvidence';
import { LinkUcsc } from 'src/Components/Common/Links/LinksCnv';
import NucleotidChange from 'src/Components/Common/NucleotidChange';
import TruncatedTextWithPopover from 'src/Components/Common/TruncatedTextWithPopover';
import VariantFrequencyNew from 'src/Components/Common/VariantFrequencyNew';
import { getOncoKbLinkFromInfoCD, getCosmicLinkFromInfoCD, getCivicLinkFromInfoCD, getHotspotsLinkFromInfoCD } from 'src/Components/Common/Somatic/DbLinks';
import { SampleInfoMultipleComponent, SampleInfoSimpleOrFamiliarComponent } from 'src/Components/Common/SampleInfo';
import { BadgeTier } from 'src/Components/Common/Badges/BadgeTier';


interface TableBodyI {
	history: any,
	studyId: string,
	study: StudyI,
	variants: {
		value0: any,
		value1: any[]
	}[],
	listaCigotas: ListaCigotasI[],
	studyType: string,
	isMitochondrial: boolean,
	isSomatic: boolean,
	columnsShowIds: ColumnIdT[],
	variantSelecteds?: string[],
	handleVariantSelecteds?: any,
	QVTType?: {
		type: QueryVariantsTableT,
		data?: any
	}
	handleShowCommentGroupModal(type: ModalCommentGroupT, data: any, editData?: any): any,
	handleShowRemoveCommentModal(variants: string[]): any,
	handleShowModalSamplesIgv(i: SampleInfoIgvDataI): void,
	handleShowacmgCalculator(veredict: string, evidence: string[], variantId: string, acmgBayScore: string, acmgBayVeredict: string, title: JSX.Element): void
	setScrollYPosition?(position: number | null): void
}
export default function VariantsTableBody(props: TableBodyI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { theme } = useContext(ThemeContext);
	const { queryVariants } = useContext(QueryContext);
	const isQueryGenes = props?.QVTType?.type === "queryGenes";

	const rowStyle = { margin: 0, padding: 0 }
	//----------------------Columns-----------------------------------
	const get_Column_Variant = (rv: any, key: number) => {
		const firstRowValue = Array.isArray(rv) ? rv[0] : rv
		const hasComment = firstRowValue?.comment?.state ? true : false
		const id: ColumnIdT = "variant";
		return (
			<td key={`${id}_${key}`} id={`${id}_${key}`}
				style={{ wordBreak: "break-all", maxWidth: "300px", minWidth: "100px" }}
				onClick={() => handleGoVariantsDetails(firstRowValue.id)}>
				<label style={{ cursor: "text", marginTop: 2 }} onClick={(e: any) => { e.preventDefault(); e.stopPropagation() }}>
					<LinkENSEMBL genomeRef={props.study.genomeReference} chromosome={firstRowValue.chromosome} position={+firstRowValue.chPosition} fontSize={"13px"} notBold={true} /> &nbsp;&nbsp;
					<NucleotidChange clinvarAlleles={`${firstRowValue?.reference}/${firstRowValue?.alternative}`} />
					<CopyToClipboard valueToCopy={`${firstRowValue.wholeChPosition} ${firstRowValue.variantTranscripto}`} />
					<CommentIconPopover placement={"top-start"}
						hasComment={hasComment}
						variantId={firstRowValue.id}
						variant_IdPosChangeGen={{
							id: Array.isArray(rv) ? rv.map((x: any) => x.id).join(",") : firstRowValue.id,
							position: firstRowValue.wholeChPosition,
							change: `${firstRowValue?.reference}${" > "}${firstRowValue?.alternative}`,
							gen: firstRowValue.geneName ?? "-",
						}}
						comment={firstRowValue.comment}
						handleShowCommentGroupModal={props.handleShowCommentGroupModal}
						handleShowRemoveCommentModal={props.handleShowRemoveCommentModal}
					/>
					{firstRowValue.reportedVariant &&
						<LinkReportedVariant
							studyId={props.studyId}
							chromosome={firstRowValue.chromosome}
							position={firstRowValue.chPosition}
							change={`${firstRowValue.reference}${firstRowValue.alternative}`}
							gene={firstRowValue.geneName}
						/>
					}
					{!props.isMitochondrial && firstRowValue.chromosome && firstRowValue.chPosition && firstRowValue.reference && firstRowValue.alternative && props.study?.genomeReference &&
						<LiftOverGRChSwitcher genomeReference={props.study.genomeReference.toUpperCase().trim() === "GRCH38" ? "38" : "19"}
							dbsnp={{
								chromosome: firstRowValue.chromosome,
								position: firstRowValue.chPosition,
								reference: firstRowValue.reference,
								alternative: firstRowValue.alternative
							}}
						/>
					}
				</label>
			</td >
		)
	}

	let get_Column_Gene: any = () => { }
	if (!isQueryGenes) {
		get_Column_Gene = (rv: any, key: number) => {
			const firstRowValue = Array.isArray(rv) ? rv[0] : rv
			let isPseudogene: boolean = false
			if (Object.keys(queryVariants?.data?.hugoGenes ?? {}).indexOf(firstRowValue.geneName) > -1 &&
				queryVariants.data?.hugoGenes[firstRowValue.geneName]?.locusType.toString().trim().toLowerCase() === "pseudogene") {
				isPseudogene = true
			}
			if (!firstRowValue.geneName) return <td>--</td>
			const id: ColumnIdT = "gene"; // }
			return (
				<td key={`${id}_${key}`} id={`${id}_${key}`} style={{ minWidth: "100px" }}
					onClick={() => handleGoVariantsDetails(firstRowValue.id)}>
					<span style={{ cursor: "text" }} onClick={(e: any) => { e.preventDefault(); e.stopPropagation() }}>
						<LinkLocalGen geneName={firstRowValue.geneName}
							caseId={props.studyId}
							fontSize={"14px"}
							variantId={firstRowValue.id}
							onClick={() => { if (props.setScrollYPosition) props.setScrollYPosition(window.pageYOffset); }} />
						{isPseudogene &&
							<WarningTrianglePopover type="warning" bodyContent={translate("casesQuery.pseudogeneInfo")} title={"Pseudogene"} />
						}
						<CopyToClipboard valueToCopy={firstRowValue.geneName} />
						<PopOverOmimGenes
							omimId={firstRowValue.omimId}
							geneName={firstRowValue.geneName}
							gene={queryVariants.data?.hugoGenes[`${firstRowValue.geneName}`]} />
						{(firstRowValue?.infoColumnData?.TRANSCRIPT_ID ?? "").length > 1 && <><br />
							<span style={{ color: theme.grayHeader }}>
								{firstRowValue?.infoColumnData?.TRANSCRIPT_ID}&nbsp;
								<CopyToClipboard valueToCopy={firstRowValue?.infoColumnData?.TRANSCRIPT_ID} />
							</span>
						</>
						}
						{
							// (firstRowValue?.effect === "stop_gained" || firstRowValue?.effect === "frameshift_variant") &&
							firstRowValue?.infoColumnData.GNOMAD_PLI &&
							<>
								<br />
								<span>
									<Tooltip label={translate("casesQuery.pliInfo")}>
										<span>
											<strong style={{ color: theme.grayHeader }}>pLi</strong>&nbsp;:&nbsp;
										</span>
									</Tooltip>
									{<PLiBadge value={firstRowValue?.infoColumnData.GNOMAD_PLI} />}
								</span>
							</>
						}
						{
							((firstRowValue?.effect ?? "").toLowerCase().indexOf("missense") > -1 && firstRowValue?.infoColumnData?.GNOMAD_ZMISS) &&
							<>
								<br />
								<span>
									<Tooltip label={translate("casesQuery.zScoreInfo")}>
										<span>
											<strong style={{ color: theme.grayHeader }}>Z-Miss</strong>&nbsp;:&nbsp;
										</span>
									</Tooltip>
									{<ZMissBadge value={firstRowValue?.infoColumnData.GNOMAD_ZMISS} />}
								</span>
							</>
						}
						{
							((firstRowValue?.effect ?? "").indexOf("synonims") > -1 && firstRowValue?.infoColumnData?.GNOMAD_ZSYN) &&
							<>
								<br />
								<span>
									<Tooltip label={translate("casesQuery.zScoreInfo")}>
										<label>
											<strong style={{ color: theme.grayHeader }}>Z-Score-Syn</strong>&nbsp;:&nbsp;
										</label>
									</Tooltip>
									{roundWithTooltipoTo0(firstRowValue?.infoColumnData?.GNOMAD_ZSYN)}
								</span>
							</>
						}
					</span>
					<span style={{ cursor: "text" }} onClick={(e: any) => { e.stopPropagation() }}>
						<CustomAcmgGuideByGene geneName={firstRowValue.geneName} />
					</span>
				</td>
			)
		}
	}

	const get_Column_Consecuence = (rv: any, key: number) => {
		rv = Array.isArray(rv) ? rv[0] : rv
		const id: ColumnIdT = "consecuence";
		return (
			<td key={`${id}_${key}`} id={`${id}_${key}`}
				style={{ wordBreak: "break-word", maxWidth: "300px", color: theme.grayHeader, fontWeight: "bold", minWidth: "100px" }}
				onClick={() => handleGoVariantsDetails(rv.id)}>
				<span style={{ cursor: "text" }} onClick={(e: any) => { e.preventDefault(); e.stopPropagation() }}>
					<Row style={{ margin: 0, padding: 0, alignItems: "center" }}>
						<TruncatedTextWithPopover popoveContent={rv.codonChange} popoveTitle={"Codon change"} treshold={20} />
						<CopyToClipboard valueToCopy={rv.codonChange} />
					</Row>
					{rv.aaChange && <Row style={{ margin: 0, padding: 0, alignItems: "center" }}>
						<TruncatedTextWithPopover popoveContent={rv.aaChange} popoveTitle={"Aminoacid change"} treshold={20} />
						<CopyToClipboard valueToCopy={rv.aaChange} />
					</Row>}
				</span>
			</td>
		)
	}

	const get_Column_ImpactEffect = (rv: any, key: number) => {
		rv = Array.isArray(rv) ? rv[0] : rv
		const infoCd = rv?.infoColumnData;
		const id: ColumnIdT = "impactoEfecto";
		const checkSpliceValues = (value: any): boolean => {
			const result = (+Array.from(new Set((value ?? "").split(","))).toString()) !== 0;
			return result;
		}
		return (
			<td key={`${id}_${key}`} id={`${id}_${key}`}
				style={{ minWidth: "130px" }}
				onClick={() => handleGoVariantsDetails(rv.id)}>
				<span style={{ cursor: "text" }} onClick={(e: any) => { e.preventDefault(); e.stopPropagation() }}>
					<Row style={rowStyle}>
						<BadgeVariantImpact impact={rv.impact} />
					</Row>
					<Row style={rowStyle}>
						{rv.effect}
						{/* &nbsp;&nbsp;{spliceAi && spliceAi} */}
					</Row>
					{infoCd &&
						<>
							{
								(infoCd.BAYESDEL_ADDAF_SCORE || rv.revelScore) &&
								<hr style={{ margin: "4px 0px" }} />
							}
							{
								rv.revelScore &&
								< Row style={rowStyle}>
									<strong style={{ color: theme.grayHeader }}>Revel score:</strong>&nbsp;
									{roundWithTooltipoTo0(rv.revelScore)}&nbsp;&nbsp;
									<BadgeVeredictPredictors type={"revel_score"} value={rv.revelScore} />

								</Row>
							}
							{(infoCd.SAI_DS_AG || infoCd.SAI_DS_AL || infoCd.SAI_DS_DG || infoCd.SAI_DS_DL) &&
								// <Row style={{ color: "dimgray", margin: 0, padding: 0 }}>
								<Row style={{ margin: 0, padding: 0, display: "block" }}>
									<strong style={{ color: theme.grayHeader }}>Splice AI:</strong> &nbsp;<br />
									{
										+infoCd.SAI_DS_AG === 0 && +infoCd.SAI_DS_AL === 0 && +infoCd.SAI_DS_DG === 0 && +infoCd.SAI_DS_DL === 0 ?
											<span style={{ color: theme.grayHeader }}>
												<Badge pill
													variant="secondary"
													style={{ backgroundColor: theme.acmg_vere_vus }}>
													{translate("common.noConsequence")}
												</Badge>
												<br />
											</span> :
											<span style={{ fontSize: 10, opacity: "0.9" }}>
												{checkSpliceValues(infoCd.SAI_DS_AG) && <>
													&nbsp;&nbsp;AG:&nbsp;{getBadgeSpliceAi(infoCd.SAI_DS_AG, 10)}<br />
												</>}
												{checkSpliceValues(infoCd.SAI_DS_AL) && <>
													&nbsp;&nbsp;AL:&nbsp;{getBadgeSpliceAi(infoCd.SAI_DS_AL, 10)}<br />
												</>}
												{checkSpliceValues(infoCd.SAI_DS_DG) && <>
													&nbsp;&nbsp;DG:&nbsp;{getBadgeSpliceAi(infoCd.SAI_DS_DG, 10)}<br />
												</>}
												{checkSpliceValues(infoCd.SAI_DS_DL) && <>
													&nbsp;&nbsp;DL:&nbsp;{getBadgeSpliceAi(infoCd.SAI_DS_DL, 10)}<br />
												</>}
											</span>
									}
								</Row>
							}
							{
								// (infoCd.BAYESDEL_ADDAF_SCORE && infoCd.BAYESDEL_NOAF_SCORE) ?
								//     <>
								//         < Row style={rowStyle}>
								//             <strong >BayesDel </strong> &nbsp;
								//         </Row>
								//         < Row style={rowStyle}>
								//             &nbsp;• &nbsp;<strong style={{ color: theme.grayHeader }}>addAF</strong>
								//             &nbsp;:&nbsp;{roundWithTooltipoTo0(infoCd.BAYESDEL_ADDAF_SCORE)}&nbsp;&nbsp;
								//             <BadgeVeredictPredictors type={"bayesdel_addaf"} value={infoCd.BAYESDEL_ADDAF_SCORE} />
								//         </Row>
								//         < Row style={rowStyle}>
								//             &nbsp;• &nbsp;<strong style={{ color: theme.grayHeader }}>noAF</strong>
								//             &nbsp;:&nbsp;{roundWithTooltipoTo0(infoCd.BAYESDEL_NOAF_SCORE)}&nbsp;&nbsp;
								//             <BadgeVeredictPredictors type={"bayesdel_noaf"} value={infoCd.BAYESDEL_NOAF_SCORE} />
								//         </Row>
								//     </>
								//     :
								//     <>
								//         {
								//             infoCd.BAYESDEL_ADDAF_SCORE &&
								//             < Row style={rowStyle}>
								//                 <strong >BayesDel addAF:</strong>
								//                 &nbsp;{roundWithTooltipoTo0(infoCd.BAYESDEL_ADDAF_SCORE)}
								//             </Row>
								//         }
								//         {
								//             infoCd.BAYESDEL_NOAF_SCORE &&
								//             < Row style={rowStyle}>
								//                 <strong >BayesDel noAF:</strong>
								//                 &nbsp;{roundWithTooltipoTo0(infoCd.BAYESDEL_NOAF_SCORE)}
								//             </Row>
								//         }
								//     </>
							}
						</>
					}
				</span>
			</td >)
	}

	const get_Column_Classification = (rv: any, key: number) => {
		rv = Array.isArray(rv) ? rv[0] : rv
		const intervarEvi = rv.intervar_evidence && rv.intervar_evidence !== "" ? rv.intervar_evidence.split(",") : []
		const getEvidenceData = (rv: any) => {
			let result: any = ""
			if (rv.intervar_veredict || rv.intervar_evidence) {
				result = (<div key={"commonSource"}>
					ACMG/AMP 2015:<br />
					{rv.intervar_veredict &&
						<span key={"lalala"}>
							<BadgeVariantVeredict labelInput={rv.intervar_veredict}
								id={0}
								size={"big"}
							/>
							<br />
						</span>
					}
					{intervarEvi.map((x: any, i: number) => {
						return (
							<span key={`${i + 100}`}>
								<BadgeVariantEvidence labelInput={x}
									id={i + 1}
									fontSize={10}
								/>
							</span>
						)
					})}
				</div>);
			}
			if (rv.acmg_bayesian_veredict || rv.acmg_bayesian_score) {
				result = (<>
					{result}
					{result !== "" && <hr style={{ margin: "3px 0" }} />}
					{translate("common.bayesian")}: {rv.acmg_bayesian_score}<br />
					{rv.acmg_bayesian_veredict &&
						<span key={5747}>
							<BadgeVariantVeredict labelInput={rv.acmg_bayesian_veredict}
								id={9999}
								size={"big"} />
							<br />
						</span>}
					{/* {rv.acmg_bayesian_score &&
                        rv.acmg_bayesian_score !== "" && <span style={{ marginLeft: "5px" }}>
                            {translate("common.score")}: {rv.acmg_bayesian_score}
                        </span>
                    } */}
				</>
				);
			}
			return result
		}
		// const change = (rv.codonChange ?? "").toUpperCase().replaceAll(/[0-9]/g, '').replaceAll("+", "").replace("C.", "").replace("-", "")
		// const rsArray = (rv.vcfId ?? "").split(";")
		//     .filter((x: string) => x.toLowerCase().trim().startsWith("rs"))
		const id: ColumnIdT = "classification";
		return (
			<td key={`${id}_${key}`} id={`${id}_${key}`}
				style={{ minWidth: "100px" }}
				onClick={() => handleGoVariantsDetails(rv.id)}>
				<span style={{ cursor: "text" }} onClick={(e: any) => { e.preventDefault(); e.stopPropagation() }}>
					{(rv.intervar_veredict || rv.intervar_evidence) && <>
						{/* <ButtonAcmgCalc handleShowacmgCalculator={props.handleShowacmgCalculator}
                            intervar_veredict={rv.intervar_veredict}
                            intervar_evidence={intervarEvi}
                            variantId={rv.id}
                            acmgBayScore={rv.acmg_bayesian_score}
                            acmgBayVeredict={rv.acmg_bayesian_veredict}
                            chromosome={rv.chromosome}
                            position={rv.chPosition}
                            change={change}
                            geneName={rv.geneName}
                            studyId={props.studyId}
                            rsArray={rsArray}
                        /> */}
						{getEvidenceData(rv)}
					</>
					}
				</span>
			</td>
		)
	}

	const get_Column_MitoMap = (rv: any, key: number) => {
		rv = Array.isArray(rv) ? rv[0] : rv
		// if (key === 0) {
		// console.log(rv)
		// }
		const getMitoMap = (rv: any) => {
			const getDiseaseAndStatus = () => {
				if (rv.infoColumnData?.MITOMAP_DISEASE) {
					const diseasStatus = rv.infoColumnData?.MITOMAP_DISEASESTATUS
					return rv.infoColumnData?.MITOMAP_DISEASE.split(",")
						.map((x: string, i: number) => {

							return (
								<span key={i}>
									<span style={{ fontStyle: 'italic' }}>
										-&nbsp;{x.replaceAll("-", " ")}
									</span>
									{
										diseasStatus.split(",").length > i &&
										<span style={{ fontWeight: 'bold' }}>
											: &nbsp; [{diseasStatus.split(",")[i]}]
										</span>
									}<br />
								</span>
							)
						})
				}
				return ""
			}
			const getMitoMapBadges = () => {
				let mi: any = rv?.infoColumnData?.MITOTIP_INTERPRETATION;
				if (!mi) return (<></>)
				mi = Array.from(new Set(mi.split('/').map((x: string) => x.toLowerCase())))
				return mi.filter((x: string) => x.trim() !== "")
					.map((x: string, i: number) =>
						<BadgeVariantMitomap labelInput={x}
							id={i}
							key={i}
							size={"big"} />
					)
			}
			return (
				<div>
					{
						rv?.infoColumnData?.MITOTIP_INTERPRETATION && <>
							<span style={{ fontWeight: 'bold' }}>
								MitoTIP Scores: &nbsp;
								{getMitoMapBadges()}
							</span>
							<hr style={{ margin: "3px 0px" }} />
						</>
					}
					{
						(rv.infoColumnData?.MITOMAP_DISEASE || rv.infoColumnData?.MITOMAP_DISEASESTATUS) && <>
							<span style={{ fontWeight: 'bold' }}>
								MitoMap Disease:&nbsp;
							</span><br />
							{getDiseaseAndStatus()}
						</>
					}
				</div>
			)
		}
		const id: ColumnIdT = "mitoMap"
		return (
			<td key={`${id}_${key}`} id={`${id}_${key}`}
				style={{ minWidth: "100px" }}
				onClick={() => handleGoVariantsDetails(rv.id)}>
				<span style={{ cursor: "text" }} onClick={(e: any) => { e.preventDefault(); e.stopPropagation() }}>
					{getMitoMap(rv)}
				</span>
			</td>
		)
	}

	const get_Column_Frequency = (rv: any, key: number) => {
		const id: ColumnIdT = "frecuence";
		rv = Array.isArray(rv) ? rv[0] : rv
		return (
			<td key={`${id}_${key}`} id={`${id}_${key}`}
				style={{ minWidth: "132px" }}
				onClick={() => handleGoVariantsDetails(rv.id)}>
				<div style={{ cursor: "text" }} onClick={(e: any) => { e.preventDefault(); e.stopPropagation() }}>
					{!props.isMitochondrial ?
						// <TableVariantFrequency infoCd={rv?.infoColumnData} type={"advancedQuerys"} /> :
						<VariantFrequencyNew infoCD={rv?.infoColumnData} /> :
						<FrequencyMitochondrial infoCD={rv?.infoColumnData} />
					}
				</div>
				{rv.chromosome && rv.chPosition && rv.reference && rv.alternative &&
					<span style={{}}>
						< LinkGnomAd variant={{
							chromosome: rv.chromosome,
							chPosition: rv.chPosition,
							reference: rv.reference,
							alternative: rv.alternative,
							genomeRef: props.study?.genomeReference
						}} />
					</span>
				}
			</td>
		)
	}

	const get_Column_Evidence = (rv: any, key: number) => {
		rv = Array.isArray(rv) ? rv[0] : rv
		const getMutationTasterWarning = (mt: string) => {
			if (mt.indexOf("A") > -1)
				return (<span style={{ margin: "0 3px" }}>
					<WarningTrianglePopover type="danger" bodyContent={"Disease causing automatic"} />
				</span>)
			if (mt.indexOf("D") > -1)
				return (<span style={{ margin: "0 3px" }}>
					<WarningTrianglePopover type="warning" bodyContent={"Disease causing"} />
				</span>)
			return ""
		}
		const getClinvarPopover = (clinvarIndex: string) => {
			switch (clinvarIndex.trim()) {
				case "0": {
					return (<span style={{ margin: "0 3px" }}>
						<WarningTrianglePopover type="advice" bodyContent={"Uncertain significance"} />
					</span>)
				}
				case "4": {
					return (<span style={{ margin: "0 3px" }}>
						<WarningTrianglePopover type="warning" bodyContent={"Likely Pathogenic"} />
					</span>)
				}
				case "5": {
					return (<span style={{ margin: "0 3px" }}>
						<WarningTrianglePopover type="danger" bodyContent={"Pathogenic"} />
					</span>)
				}
				default: {
					return ""
				}
			}
		}
		// const getClinMutTWarning = (clinvarIndex?: string, mt?: string) => {
		//     let warningType: WarningT | undefined;
		//     let content: LabelValueI[] = [];
		//     if ((mt && mt.indexOf("A") > -1) || (clinvarIndex && clinvarIndex.trim() === "5")) warningType = "danger"
		//     else if ((mt && mt.indexOf("D") > -1) || (clinvarIndex && clinvarIndex.trim() === "4")) warningType = "warning"
		//     else if (clinvarIndex && clinvarIndex.trim() === "0") warningType = "advice"

		//     if (mt && mt.indexOf("A") > -1) content.push({ label: "Mutation Taster", value: "Disease causing automatic" })
		//     if (clinvarIndex && clinvarIndex.trim() === "5") content.push({ label: "Clinvar", value: "Pathogenic" })
		//     if (mt && mt.indexOf("D") > -1) content.push({ label: "Mutation Taster", value: "Disease causing" })
		//     if (clinvarIndex && clinvarIndex.trim() === "4") content.push({ label: "Clinvar", value: "Likely Pathogenic" })
		//     if (clinvarIndex && clinvarIndex.trim() === "0") content.push({ label: "Clinvar", value: "Uncertain significance" })
		//     const newContent = <>{content.map((x: any, i: number) => (
		//         <div key={i}>
		//             <strong>{x.label}</strong> : {x.value}
		//         </div>
		//     ))}</>
		//     if (warningType) {
		//         return (
		//             <span style={{ margin: "0 3px" }}>
		//                 <WarningTrianglePopover type={warningType} bodyContent={newContent} />
		//             </span>
		//         )
		//     }
		//     return ""
		// }
		const getLabelLink = (label: string, link: any) => {
			return (
				<div>
					<span style={{ color: "darkslategray", fontWeight: "bold" }}>
						{label}
					</span>
					{link}
				</div>
			)
		}
		const rsArray = (rv.vcfId ?? "").split(";")
			.filter((x: string) => x.toLowerCase().trim().startsWith("rs"))
		const masterMindLink = decodeURIComponent(rv.infoColumnData?.MMURI3 ?? "")
		const id: ColumnIdT = "evidence";

		const getGene_OncogeneOrTSG = () => {
			if (!is_oncogene && !is_tsg) return (<span style={{ marginLeft: "5px" }}>{rv.geneName}</span>)
			const tooltipLabel = is_oncogene && is_tsg ?
				`${translate("common.oncogene")} ${translate("common.and")} ${translate("common.tumorSuppressorGene")}` :
				is_oncogene ? translate("common.oncogene") : is_tsg ? translate("common.tumorSuppressorGene") : "";
			return (
				<span style={{ marginLeft: "5px" }}>
					<Tooltip label={tooltipLabel}>
						<Badge style={{ backgroundColor: "#EEE", fontSize: 12, fontWeight: 100 }}>
							{rv.geneName}
						</Badge>
					</Tooltip>
				</span>
			)
		}
		const oncokbLink: any = !props.isSomatic ? undefined : getOncoKbLinkFromInfoCD(rv.infoColumnData, rv.geneName)
		const cosmicLink: any = !props.isSomatic ? undefined : getCosmicLinkFromInfoCD(rv.infoColumnData)
		const civicLink: any = !props.isSomatic ? undefined : getCivicLinkFromInfoCD(rv.infoColumnData)
		const hotspotsLink: any = !props.isSomatic ? undefined : getHotspotsLinkFromInfoCD(rv.infoColumnData)
		const is_oncogene = !props.isSomatic ? undefined : rv.infoColumnData?.ONCOKB_IS_ONCOGENE ? true : false;
		const is_tsg = !props.isSomatic ? undefined : rv.infoColumnData?.ONCOKB_IS_TUMOR_SUPPRESSOR ? true : false;
		const getPmkbTiers = (pmkbTiers: string[] | undefined): JSX.Element[] | undefined => {
			if (pmkbTiers && pmkbTiers.length > 0) {
				pmkbTiers = pmkbTiers.map((x: string) => { x = x.replace("_", ""); return x; });
			}
			pmkbTiers = Array.from(new Set(pmkbTiers))
			const pmkbTiersNumber: number[] = pmkbTiers
				? pmkbTiers.map(x => parseInt(x))
					.sort((a, b) => a - b)
				: [];
			if (pmkbTiersNumber && pmkbTiersNumber.length > 0) {
				let pmkbElements: JSX.Element[] = pmkbTiersNumber.map((x: any, i: number) => {
					return <BadgeTier key={i} tier={x} size={"small"} style={{ "marginRight": 5 }} variantUndefined={false} />
				});
				return pmkbElements
			}
			return []
		}
		let pmkbTiers: JSX.Element[] | undefined = !props.isSomatic ? undefined : getPmkbTiers(rv.infoColumnData?.PMKB_TIER?.split(","))

		return (
			<td key={`${id}_${key}`} id={`${id}_${key}`}
				style={{ minWidth: "100px" }}
				onClick={() => handleGoVariantsDetails(rv.id)}>
				<span style={{ cursor: "text" }} onClick={(e: any) => { e.preventDefault(); e.stopPropagation() }}>
					{rv.geneName && <>
						<Row style={{ margin: 0, padding: 0, alignItems: "center", display: "flex", justifyContent: "space-between" }}>
							<div>
								<strong>{`${translate("common.gene")} : `}</strong>
								{props.isSomatic ?
									getGene_OncogeneOrTSG() :
									<span style={{ marginLeft: "5px" }}>{rv.geneName}</span>
								}
								<CopyToClipboard valueToCopy={rv.geneName} />
							</div>
							<span>
								<MoreInfoPopoverButton trigger={"click"}>
									<div>
										<div key={"gnomAD"}><LinkGnomAd geneName={rv.geneName} /></div>
										<div key={"OMIM"}><LinkOMIM omimId={rv.omimId} /></div>
										<div key={"GeneCards"}><LinkGeneCards geneName={rv.geneName} /></div>
										<div key={"NCBI"}><LinkNCBI geneName={rv.geneName} /></div>
										<div key={"clingen"}><LinkClinGen clingenId={rv.gene?.hgncId} label={"ClinGen"} /></div>
										{
											props.isSomatic && (is_oncogene || is_tsg) &&
											<div key={"OncoKB"}>{getOncoKbLinkFromInfoCD(rv.infoColumnData, rv.geneName, "10", "gene", "OncoKB")}</div>
										}
									</div>
								</MoreInfoPopoverButton>
							</span>
						</Row>
						<hr style={{ margin: "3px 0px" }} />
					</>
					}
					<Row style={{ margin: 0, padding: 0, alignItems: "center", display: "flex", justifyContent: "space-between" }}>
						<div>
							<strong>{`${translate("common.variant")} : `}</strong>
							{(rv.vcfId ?? "").split(";").length === 0 && " -- "}
							{oncokbLink ?? ""}
							{cosmicLink ?? ""}
							{civicLink ?? ""}
							{hotspotsLink ?? ""}
							{pmkbTiers ? <>&nbsp;{pmkbTiers}</> : ""}
							{(rv.vcfId ?? "").length > 0 &&
								<div key={"VCFIDs"} style={{ marginLeft: "3px" }}>
									<NcbiDbsnpLinksByVcfId vcfIds={rv.vcfId} copyToClipboard={true} />
									{rv.infoColumnData?.CLNSIG && <NcbiClinvarLinkByVcfId vcfIds={rv.vcfId} copyToClipboard={true} />}
									{rv.infoColumnData?.CLNSIG && Array.isArray(rv.infoColumnData.CLNSIG) && rv.infoColumnData.CLNSIG.length > 0 &&
										getClinvarPopover(rv.infoColumnData.CLNSIG[0].toString())}

								</div>
							}
						</div>
						<div>
							<MoreInfoPopoverButton trigger={"click"}>
								<div>
									<LinkGnomAd variant={{
										chromosome: rv.chromosome,
										chPosition: rv.chPosition,
										reference: rv.reference,
										alternative: rv.alternative,
										genomeRef: props.study?.genomeReference
									}} />
									<div key={"NCBI"}>
										<LinkMutationTaster chromosome={rv.chromosome}
											chPosition={rv.chPosition}
											reference={rv.reference}
											alternative={rv.alternative} />
										{rv.infoColumnData?.MUTATIONTASTER && getMutationTasterWarning(rv.infoColumnData?.MUTATIONTASTER)}
									</div>
									{/* {(rv.vcfId ?? "").length > 0 &&
                                        <div key={"VCFIDs"} style={{ }}>
                                            {rv.infoColumnData?.CLNSIG && <NcbiClinvarLinkByVcfId vcfIds={rv.vcfId} copyToClipboard={true} />}
                                            {rv.infoColumnData?.CLNSIG &&
                                                Array.isArray(rv.infoColumnData.CLNSIG) &&
                                                rv.infoColumnData.CLNSIG.length > 0 &&
                                                getClinvarPopover(rv.infoColumnData.CLNSIG[0].toString())
                                            }
                                        </div>
                                    } */}
									{rv.uniprot &&
										<div key={"Uniprot"}>
											<LinkUniprot uniprotId={rv.uniprot} />
											{rv.infoColumnData?.MUTATIONTASTER && getMutationTasterWarning(rv.infoColumnData?.MUTATIONTASTER)}
										</div>}
									{
										(rv.vcfId ?? "").trim().length > 2 &&
										<>
											<NcbiClinvarLinkByVcfId vcfIds={rv.vcfId} copyToClipboard={true} />
											{rv.infoColumnData?.CLNSIG &&
												Array.isArray(rv.infoColumnData.CLNSIG) &&
												rv.infoColumnData.CLNSIG.length > 0 &&
												getClinvarPopover(rv.infoColumnData.CLNSIG[0].toString())
											}
											< br />
										</>
										// }))
									}
									{rv.infoColumnData?.MMURI3 && <>
										<LinkTooltip label={"Mastermind"} tooltipLabel={"Go to Mastermind"} href={masterMindLink} externalLink={true} />
										<br />
									</>
									}
									{rv.chromosome && rv.chPosition &&
										rv?.infoColumnData?.GENE_NAME &&
										rv.reference && rv.alternative &&
										<>
											<LinkDecipher chrom={`${rv.chromosome}`}
												position={`${rv.chPosition}`}
												gene={rv?.infoColumnData?.GENE_NAME}
												reference={rv.reference}
												alt={rv.alternative}
											/>
											<br />
										</>
									}
									<LinkUcsc fontSize={"12px"}
										genomeRef={props.study?.genomeReference}
										chromosome={rv.chromosome}
										start={rv.chPosition}
										end={""} />
									{
										rv?.infoColumnData?.TRANSCRIPT_ID && rv?.infoColumnData?.CODON_CHANGE &&
										getLabelLink("ClinGen : ",
											<div key={"clingen"}>
												<LinkClinGen variant={{
													hgvsRefSeq: rv?.infoColumnData?.TRANSCRIPT_ID,
													change: rv?.infoColumnData?.CODON_CHANGE,
													genename: rv?.infoColumnData?.GENE_NAME
												}} />
											</div>
										)
									}
									{
										(rv.vcfId ?? "").trim().length > 2 &&
										<>
											{rsArray.length > 0 && getLabelLink("dbSNP : ",
												<span key={`vcfIdLinksNcbi`} style={{ marginLeft: "5px" }}>
													<NcbiDbsnpLinksByVcfId vcfIds={rv.vcfId} copyToClipboard={true} />
												</span>
											)}
										</>
									}
									{
										rsArray.length > 0 && getLabelLink("Litvar : ", rsArray.map((x: string, i: number) => {
											return (
												<span key={`${i}_A`} style={{ marginLeft: "5px" }}>
													<LinkNCBI litvarRs={x} />
													{i !== rsArray.length - 1 ? ", " : ""}
												</span>)
										}).filter((x: string, i: number) => x)
										)
									}
									{
										LinkMutalizer_validator(rv?.infoColumnData?.TRANSCRIPT_ID, rv?.infoColumnData?.CODON_CHANGE, rv.effect) &&
										getLabelLink("Mutalyzer : ",
											<LinkMutalizer effect={rv.effect}
												maneSelect={rv?.infoColumnData?.TRANSCRIPT_ID}
												codonChange={rv?.infoColumnData?.CODON_CHANGE} />)
									}

									<hr style={{ margin: "3px" }} />
									{rsArray.length > 0 ?
										<>
											{getLabelLink("Google : ", rsArray.map((x: string, i: number) =>
												<span key={`${i}:${i}`}>
													<LinkGoogle geneName={rv.geneName}
														codonChange={rv.codonChange}
														chPosition={rv.chPosition}
														aaChange={rv.aaChange}
														rs={x} />
													{i !== rsArray.length - 1 ? ", " : ""}
												</span>
											))}
											{getLabelLink("Google schoolar : ", rsArray.map((x: string, i: number) =>
												<span key={`${i}:${i}`}>
													<LinkGoogleScholar key={i}
														geneName={rv.geneName}
														codonChange={rv.codonChange}
														chPosition={rv.chPosition}
														aaChange={rv.aaChange}
														rs={x} />
													{i !== rsArray.length - 1 ? ", " : ""}
												</span>
											))}
										</> : <>
											{getLabelLink("Google : ",
												<LinkGoogle geneName={rv.geneName} codonChange={rv.codonChange} chPosition={rv.chPosition} aaChange={rv.aaChange} />)}
											{getLabelLink("Google schoolar : ",
												<LinkGoogleScholar geneName={rv.geneName} codonChange={rv.codonChange} chPosition={rv.chPosition} aaChange={rv.aaChange} />)}
										</>
									}
								</div>
							</MoreInfoPopoverButton>
						</div>
					</Row>
				</span>
			</td>
		)
	}

	const get_Column_SampleInformation = (rv: any, key: number) => {
		const allRv = rv
		rv = Array.isArray(rv) ? rv[0] : rv
		const id: ColumnIdT = "sampleInfo";
		let sampleInfo;
		if (props.studyType === "simple" || props.studyType === "familiar")
			sampleInfo = <SampleInfoSimpleOrFamiliarComponent
				key={key}
				currentKey={key}
				rv={rv}
				id={id}
				isSomatic={props.isSomatic}
				isMitochondrial={props.isMitochondrial}
				study={props.study}
				studyType={props.studyType}
				studyId={props.studyId}
				allRv={allRv}
				handleShowModalSamplesIgv={props.handleShowModalSamplesIgv}
				handleGoVariantsDetails={handleGoVariantsDetails}
			/>;
		else
			sampleInfo = <SampleInfoMultipleComponent
				key={key}
				currentKey={key}
				rv={rv}
				id={id}
				isSomatic={props.isSomatic}
				isMitochondrial={props.isMitochondrial}
				study={props.study}
				studyType={props.studyType}
				studyId={props.studyId}
				allRv={allRv}
				handleShowModalSamplesIgv={props.handleShowModalSamplesIgv}
				handleGoVariantsDetails={handleGoVariantsDetails}
				listaCigotas={props.listaCigotas}
			/>;
		return <td key={`SampleInformation_${key}`} id={`${id}_${key}`}
			style={{
				maxWidth: "180px", minWidth: "100px", textAlign: "center", paddingTop: "3px", display: "content"
			}}
			onClick={() => handleGoVariantsDetails(rv.id)}>
			{sampleInfo}
		</td>
	}
	//----------------------Columns-----------------------------------
	const getColumnsToShow = (i: number, rv: any[]) => {
		return props.columnsShowIds.map((x: ColumnIdT) => {
			switch (x) {
				case "variant": return get_Column_Variant(rv, i);
				case "gene": return get_Column_Gene(rv, i);
				case "consecuence": return get_Column_Consecuence(rv, i);
				case "impactoEfecto": return get_Column_ImpactEffect(rv, i);
				case "classification": return !props.isMitochondrial ? get_Column_Classification(rv, i) : "";
				case "mitoMap": return props.isMitochondrial ? get_Column_MitoMap(rv, i) : "";
				case "frecuence": return get_Column_Frequency(rv, i);
				case "evidence": return get_Column_Evidence(rv, i);
				case "sampleInfo": return get_Column_SampleInformation(rv, i);
				default: return ""
			}
		})
	}

	const handleGoVariantsDetails = (variantId: number) => {
		if (props.setScrollYPosition) props.setScrollYPosition(window.pageYOffset);
		props.history.push(`/cases/query/variantDetail/${props.studyId}/${variantId}`);
	}

	return (
		<tbody>
			{props.variants?.map((x: any, i: number) => {
				const rv = GetRowValues(x, queryVariants.data?.hugoGenes, props.study?.samples)
				return (
					<tr style={{ fontSize: "12px", cursor: "pointer" }} key={i} >
						<td style={{ textAlign: "center", width: "37px", maxWidth: "37px" }}>
							<Form.Check type="checkbox"
								style={{ margin: "0 0 2px" }}
								checked={props?.variantSelecteds?.find((y: any) => y === rv.map((x: any) => x?.id).join(",")) ? true : false}
								onChange={() => { props.handleVariantSelecteds(rv.map((x: any) => x?.id).join(",")) }}
							/>
							<Tooltip label={translate("casesQuery.openVariantInNewTab")}>
								<a href={`/cases/query/variantDetail/${props.studyId}/${rv[0].id}`} target="_blank" rel="noreferrer">
									<Button variant="light" style={{ width: "23px", height: "23px", borderRadius: "50px" }}>
										<FontAwesomeIcon icon={faExternalLinkAlt} style={{ fontSize: "10px", verticalAlign: "top", marginLeft: "-4px", color: theme.link }} />
									</Button>
								</a>
							</Tooltip>
							{isQueryGenes && `${props?.QVTType?.data?.variantIds}` === `${rv.map((x: any) => x?.id).join(",")})` && <>
								<br />
								<Tooltip label={"variant selected"}>
									<FontAwesomeIcon icon={faCheckCircle}
										style={{ color: theme.grayHeader, fontSize: "15px", marginTop: "7px" }} />
								</Tooltip>
							</>}
						</td>
						{getColumnsToShow(i, rv)}
					</tr>
				)
			})}
		</tbody>
	)
}

function GetRowValues(row: { value0: any, value1: any[], comment?: any }, allGenes: any, samples: any): any[] {
	const value1 = row.value1[0];
	const comment = row.comment
	let result: any[] = [];
	if (row.value1.length > 0) {
		result = row.value1.map((x: any) => {
			return rowValuesAdapter(x, value1.id, comment, allGenes, samples);
		})
	}
	return result
}


function rowValuesAdapter(x: any, id: number, comment?: any, allGenes?: any, samples?: any) {
	const chromosome = x.chromosome
	const chPosition = x.position
	const wholeChPosition = `${chromosome}:${chPosition}`;
	const geneName = x.infoColumnData.GENE_NAME;
	const vcfId = x.vcfId ?? "";
	const gene = (geneName && allGenes && Object.keys(allGenes).indexOf(geneName) > -1) ? allGenes[`${geneName}`] : undefined;
	const omimId = gene ? gene.omimid : undefined;
	const reference = x.reference;
	const alternative = x.alternative;
	const impact = x.infoColumnData?.IMPACT?.toLowerCase().trim();
	let effect = x.infoColumnData?.EFFECT?.replaceAll("_", " ") ?? "";
	effect = effect.length > 0 ? `${effect}`.charAt(0).toUpperCase() + effect.slice(1).toLowerCase() : "";
	const variantTranscripto = `${reference} > ${alternative}`
	const codonChange = x.infoColumnData.CODON_CHANGE
	const intervar_veredict = x.infoColumnData?.INTERVAR_VEREDICT ?? "";
	const intervar_evidence = x.infoColumnData?.INTERVAR_EVIDENCE ?? "";
	const acmg_bayesian_veredict = x.infoColumnData.ACMG_BAYESIAN_VEREDICT ?? "";
	const acmg_bayesian_score = x.infoColumnData.ACMG_BAYESIAN_SCORE ?? "";
	const uniprot = x.infoColumnData.UNIPROT
	const sampleName = x.genoInfo?.sampleName ?? "";
	const revelScore = x.infoColumnData?.REVELSCORE ? `${x.infoColumnData?.REVELSCORE}`.split(",")[0] : undefined;
	let aaChange = x.infoColumnData?.AA_CHANGE
	if (aaChange) {
		if (aaChange.toLowerCase().indexOf("p.") > -1)
			aaChange = `p.(${aaChange.split("p.")[1]})`
	}
	const data = {
		...x, aaChange, acmg_bayesian_score, acmg_bayesian_veredict, alternative, chPosition, chromosome,
		codonChange, effect, geneName, id, impact, intervar_evidence, intervar_veredict, gene, omimId,
		reference, sampleName, variantTranscripto, vcfId, wholeChPosition, comment, uniprot, revelScore
	}
	return data;
}

export { rowValuesAdapter }