import React from 'react';
import { Badge } from 'react-bootstrap';
import "../../Common/table.css"
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import ThemeContext from '../../../Contexts/Theme/ThemeContext';
import { LinkCnvCalc } from 'src/Components/Common/Links/LinksCnv';

interface UBadgeCnvI {
    cnvType: string //"deletion" | "duplication" | "no-cnv" 
}
export default function BadgeCnv(props: UBadgeCnvI) {
    const { theme } = useContext(ThemeContext);
    let cnvType = (props.cnvType ?? "").toLowerCase()
    let badgeStyle: any = { fontSize: "10px", height: "20px", display: "inline-flex", alignItems: "center" }
    let result: any = ""
    if (cnvType.indexOf("deletion") > -1) {
        result = (
            <Badge pill style={{ ...badgeStyle, backgroundColor: theme.cnvDeletion, }}
                variant='info'
            >
                <FontAwesomeIcon icon={faMinus} style={{}} />
                &nbsp;&nbsp;Loss
            </Badge>
        )
    }
    if (cnvType.indexOf("duplication") > -1) {
        result = (
            <>
                {result !== "" && <>{result}&nbsp;</>}
                <Badge pill
                    variant='success'
                    style={{ ...badgeStyle, backgroundColor: theme.cnvDuplication }}
                    >
                    <FontAwesomeIcon icon={faPlus} style={{ color: "white" }} />
                    &nbsp;&nbsp;Gain
                </Badge>
            </>
        )
    }
    if (cnvType.indexOf("no-cnv") > -1) {
        result = (
            <>
                {result !== "" && <>{result}&nbsp;</>}
                <Badge pill
                    variant='secondary'
                    style={{ ...badgeStyle, backgroundColor: theme.cnvNone }}
                >
                    no-cnv
                </Badge>
            </>
        )
    }
    return (
        <>
            {result}
            <br></br>
            {(props.cnvType.indexOf("duplication") > -1 || props.cnvType.indexOf("deletion") > -1) &&
                <LinkCnvCalc fontSize={"11px"} gain={props.cnvType.indexOf("duplication") > -1} />
            }
        </>
    )
}
