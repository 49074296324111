import { encryptLocalStorage, encrypt } from '../utils'
import { UserI } from '../Contexts/interfaces';

export abstract class AbstractService {
    authHeader: any = {};
    user?: UserI;
    REACT_APP_API_URL: string | undefined;
    baseHeadersCredentials: any;
    constructor() {
        this.user = encryptLocalStorage.getItem("_Buser") || {}
        if(!encrypt) this.user = JSON.parse(encryptLocalStorage.getItem("_Buser")??"{}")
        // console.log(this.user)
        this.setAuthorizationHeader()
        this.REACT_APP_API_URL = process.env.REACT_APP_API_URL;
        // this.REACT_APP_API_URL = "http://192.168.210.77/bioflux-platform-gaip-facade/"
        // this.REACT_APP_API_URL = "http://10.100.100.55/bioflux-platform-gaip-facade/";
        // this.REACT_APP_API_URL = "http://192.168.170.103:8080/bioflux-platform-gaip-facade/"; // machine-Ger
    }

    setAuthorizationHeader() {
        if (this.user) {
            let authorization = `Digest`;
            authorization = `${authorization} username=${this.user.username},`;
            authorization = `${authorization}realm=bitgenia,`;
            authorization = `${authorization}nonce=${this.user.token},uri=/,`;
            authorization = `${authorization}response=${this.user.digest}`;
            this.authHeader = {
                headers: {
                    'Authorization': authorization,
                    'Content-Type': 'application/json; charset=UTF-8'
                    // 'Content-Type': 'charset=UTF-8'
                }
            }
        }
    }
}
