//api taken from: https://liftover.broadinstitute.org/#input=16%3A21736318%20G%20%20%3E%20%20T&hg=hg38-to-hg19 
import React from 'react';
import { useContext, useState } from "react";
import { faExchangeAlt, faInfoCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Button, Row, Spinner } from "react-bootstrap";
import { LangContext } from "../../Contexts/Lang";
import { Popover } from "./Popover";
import { Tooltip } from "./Tooltip/Tooltip";
import { AsyncDataI } from "../../Contexts/interfaces";
import CopyToClipboard from "./CopyToClipboard";
import ThemeContext from "../../Contexts/Theme/ThemeContext";
import CloseButton from "./CloseButton";
import { LinkGnomAd } from './Links/Links';

interface GrchVersionSwitcherI {
    genomeReference: "19" | "38"
    dbsnp?: {
        chromosome: string,
        position: string,
        reference: string,
        alternative: string
    }
    region?: {
        chromosome: string,
        start: string,
        end: string
    }
}
// https://liftover.broadinstitute.org/
export default function LiftOverGRChSwitcher(props: GrchVersionSwitcherI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const [show, setshow] = useState<boolean>(false)
    const [timeOut, settimeOut] = useState<boolean>(false)
    const [response, setresponse] = useState<AsyncDataI>({ data: null, loaded: false, loading: false, error: false });
    const handleLaunchRequestEnsembl = () => {
        const genomeVersion = props.genomeReference === "19" ? "GRCh38" : "GRCh37";
        const TIMEOUT = 10000;
        const handleLaunchRequestByDbsnp = () => {
            settimeOut(false)
            setresponse({ data: null, loaded: false, loading: true, error: false })
            // Request URL: https://rest.ensembl.org/map/human/GRCh38/17:58270865..58270865:1/GRCh38?content-type=application/json
            let ensemblUrl = `https://rest.ensembl.org/map/human/${props.genomeReference === "19" ? "GRCh37" : "GRCh38"}/`;
            ensemblUrl = `${ensemblUrl}${props.dbsnp?.chromosome}:${props.dbsnp?.position}..${props.dbsnp?.position}:1/${genomeVersion}`
            ensemblUrl = `${ensemblUrl}?content-type=application/json`
            let didTimeOut = false;
            return new Promise(function (resolve, reject) {
                const timeout = setTimeout(() => {
                    didTimeOut = true;
                    settimeOut(true);
                }, TIMEOUT);

                return axios.get(ensemblUrl)
                    .then((res: any) => {
                        clearTimeout(timeout);
                        if (!didTimeOut) {
                            const responseMapping = res?.data?.mappings
                            if (responseMapping && Array.isArray(responseMapping) && responseMapping.length > 0) {
                                const chPosAltRef = `${props.dbsnp?.chromosome}:${responseMapping[0].mapped.start} ${props.dbsnp?.reference} > ${props.dbsnp?.alternative}`
                                const variant = {
                                    chromosome: props.dbsnp?.chromosome ?? "",
                                    chPosition: `${responseMapping[0].mapped.start}`,
                                    reference: props.dbsnp?.reference ?? "",
                                    alternative: props.dbsnp?.alternative ?? "",
                                    genomeRef: props.genomeReference === "38" ? "37" : "38",
                                }
                                const response = <>
                                    {chPosAltRef}&nbsp;<CopyToClipboard valueToCopy={chPosAltRef} />
                                    <>
                                        <br style={{ height: "5px" }} />
                                        <LinkGnomAd fontSize={"12px"}
                                            variant={variant} />
                                        <br style={{ height: "5px" }} />
                                    </>
                                </>
                                setresponse({ data: response, loaded: true, loading: false, error: false })
                            } else {
                                setresponse({ data: null, loaded: true, loading: false, error: true })
                            }
                            resolve(res);
                        } else {
                            settimeOut(true);
                        }
                    })
                    .catch((err: any) => {
                        setresponse({ data: null, loaded: false, loading: false, error: err })
                        reject(err);
                    });
            })
        }
        const handleLaunchRequestByCnv = () => {
            // https://spliceailookup-api.broadinstitute.org/liftover/?hg=hg19-to-hg38&format=interval&chrom=8&start=999&end=1231231
            // https://spliceailookup-api.broadinstitute.org/liftover/?hg=hg19-to-hg38&format=interval&chrom=2&start=43553949&end=43553950/

            let ensemblUrl = `https://spliceailookup-api.broadinstitute.org/liftover/?hg=${props.genomeReference === "19" ? "hg19-to-hg38" : "hg38-to-hg19"}`;
            ensemblUrl += `&format=interval&chrom=${props.region?.chromosome}&start=${props.region?.start}&end=${props.region?.end}`;
            let didTimeOut = false;
            return new Promise(function (resolve, reject) {
                const timeout = setTimeout(() => {
                    didTimeOut = true;
                    settimeOut(true);
                }, TIMEOUT);

                return axios.get(ensemblUrl)
                    .then((res: any) => {
                        clearTimeout(timeout);
                        if (!didTimeOut) {
                            if (res?.status === 200 && res?.data && res.data?.output_start && res.data?.output_end) {
                                const chromRegion = `${props.region?.chromosome}:${res.data.output_start}-${res.data.output_end}`;
                                const region = {
                                    chromosome: props.region?.chromosome ?? "",
                                    start: res.data.output_start,
                                    end: res.data.output_end,
                                    reference: props.genomeReference
                                }
                                const response = <>
                                    {chromRegion}
                                    <CopyToClipboard valueToCopy={chromRegion} />
                                    <>
                                        <br style={{ height: "5px" }} />
                                        <LinkGnomAd fontSize={"12px"}
                                            region={region} />
                                        <br style={{ height: "5px" }} />
                                    </>
                                </>
                                setresponse({ data: response, loaded: true, loading: false, error: false })
                            } else {
                                setresponse({ data: null, loaded: true, loading: false, error: true })
                            }
                            resolve(res);
                        } else {
                            settimeOut(true);
                        }
                    })
                    .catch((err: any) => {
                        setresponse({ data: null, loaded: false, loading: false, error: err })
                        reject(err);
                    });
            })
        }
        if (props.dbsnp) handleLaunchRequestByDbsnp()
        else if (props.region) handleLaunchRequestByCnv()
    }
    let output: string = ""
    if (response.loaded) output = response.data;

    return (
        <Popover title={<span style={{ fontSize: "14px", color: theme.grayHeader }}>
            GRCh38&nbsp;<FontAwesomeIcon icon={faExchangeAlt} style={{ border: "none", fontSize: "13px", margin: "0 3px" }} />&nbsp;GRCh37
            <CloseButton onClose={() => setshow(false)} />
            <Tooltip label={translate("casesQuery.liftOverVariantsInfo")}>
                <span style={{ color: "lightgray", float: "right", fontSize: "12px", }}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ margin: "3px" }} />&nbsp;
                </span>
            </Tooltip>
        </span>}
            content={
                <div style={{ fontFamily: "monospace", color: "gray", fontSize: "11px" }}>
                    <Row style={{ margin: 0, color: "gray" }}>
                        <Tooltip label={translate("common.reference")}>
                            <span style={{ wordBreak: "break-word" }}>
                                <strong>{props.genomeReference === "19" ? "GRCh37" : "GRCh38"} :</strong>&nbsp;
                                {props.dbsnp ?
                                    `${props.dbsnp?.chromosome}:${props.dbsnp?.position} ${props.dbsnp?.reference} > ${props.dbsnp?.alternative}` :
                                    `${props.region?.chromosome}:${props.region?.start}-${props.region?.end}`
                                }
                            </span>
                        </Tooltip>
                    </Row>
                    {
                        response.error ?
                            <span style={{ color: theme.error, fontFamily: "Raleway, sans-serif" }}>
                                <hr style={{ margin: "5px 0" }} />
                                {translate("error.ERRORAnInknownErrorOccurredTryLater")}
                            </span> :
                            timeOut ?
                                <span style={{ color: theme.error, fontFamily: "Raleway, sans-serif" }}>
                                    <hr style={{ margin: "5px 0" }} />
                                    Lift Over server has problems, please retry
                                    <Button variant="outline-light" style={{ border: "none", padding: "2px 5px", borderRadius: "50px" }}
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleLaunchRequestEnsembl()
                                        }}>
                                        <FontAwesomeIcon icon={faSyncAlt} style={{ border: "none", fontSize: "13px", color: "gray", margin: "0 3px" }} />
                                    </Button>
                                </span> :
                                !response.loaded ?
                                    !response.loading ?
                                        <></> :
                                        <>
                                            <Spinner animation="border" variant="secondary" size="sm" style={{ marginRight: "5px", color: "darkgray !important" }} />
                                        </> :
                                    <Row style={{ margin: 0, alignItems: "baseline", color: theme.grayHeader, display: "unset" }}>
                                        <hr style={{ margin: "5px 0" }} />
                                        <Tooltip label={translate("common.result")}>
                                            <span>
                                                <strong>{props.genomeReference === "38" ? "GRCh37" : "GRCh38"} :</strong>&nbsp;
                                                {output}
                                            </span>
                                        </Tooltip>
                                    </Row>
                    }
                </div>
            }
            trigger={"click"} style={{ fontSize: "11px" }}
            show={show}
        >
            <Button variant="outline-light" style={{ border: "none", fontFamily: "monospace", color: "gray", fontSize: "11px", fontWeight: "bold", padding: "6px 4px 3px", borderRadius: "50px" }}
                onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleLaunchRequestEnsembl()
                    setshow(true)
                }}
            >
                <Tooltip label={"Lift over"}>
                    <FontAwesomeIcon icon={faExchangeAlt} style={{ border: "none", fontSize: "13px", color: "gray", margin: "0 3px" }} />
                </Tooltip>
            </Button>
        </Popover >
    )
}