import React from 'react';
import { useContext, useState } from 'react';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import IconButtonTooltipPopover from './IconButtonTooltipPopover';
import { LangContext } from "../../Contexts/Lang";
import { Table } from 'react-bootstrap';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import CloseButton from './CloseButton';
import { LinkOMIM } from './Links/Links';
import { bootstrapVariantType } from './Interfaces';


const getBackgroundColor = (vartype: bootstrapVariantType) => {
    let color = "#999999"
    switch (vartype) {
        case "danger": color = "#FF4E58"; break;
        case "warning": color = "#FFB111"; break;
        case "info": color = "#3CC9FF"; break;
    }
    return color;
}

interface PopOverOmimGenesI {
    omimId: string,
    geneName: string
    gene?: any,
}

export default function PopOverOmimGenes(props: PopOverOmimGenesI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const [show, setshow] = useState(false)
    const [notclick, setnotclick] = useState(false)
    return (
        <IconButtonTooltipPopover icon={faListUl}
            tooltipLabel={"OMIM"}
            popoverBodyStyle={{ maxHeight: "251px", overflowY: "auto" }}
            placement="bottom"
            show={show}
            onClick={() => {
                if (!notclick) {
                    setshow(true); setnotclick(true)
                } else { setshow(false); setnotclick(false) }
            }}
            popoverStyle={{ padding: 0 }}
            popOver={{
                content: (<>
                    {props.gene &&
                        props.gene.diseases.length > 0 &&
                        <Table>
                            <thead>
                                <tr style={{ backgroundColor: theme.backgroundSecondary, color: "dimgray" }}>
                                    <th>{translate("common.phenotype")}</th>
                                    <th style={{ display: "flex", marginBottom: "-1px", marginTop: "-1px" }}>
                                        {translate("common.inheritance")}&nbsp;&nbsp;
                                        <CloseButton styles={{ marginTop: "1px" }}
                                            onClose={() => { setnotclick(true) }} />
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: "11px" }}>
                                {props.gene.diseases.map((x: any, i: number) => {
                                    return (
                                        <tr key={`${i}_${i}`}>
                                            <td>{x.title.toLowerCase()}</td>
                                            <td>{x.inheritanceModel}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                    <LinkOMIM omimId={props.omimId} label={`${translate("common.see")} ${props.geneName} OMIM`} />
                </>)
            }}
        />
    )
}
export { getBackgroundColor }
