import React from 'react';
import { useState, useEffect, useContext } from 'react';
import PanelGenesBody from '../../Components/PanelGenes/PanelGenes';
import { Layout } from '../../Components/Layout/Layout';
import DetailPanelGenes from '../../Components/PanelGenes/Detail/DetailPanelGenes';
import PanelGenesContextState from '../../Contexts/PanelGenes/PanelGenesState';
import UserContext from '../../Contexts/User/UserContext';
import { Redirect } from 'react-router-dom';
import UserService from '../../Services/UserServices';

interface GenesPanelPageI {
    history: any,
    match: any,
}
export default function GenesPanelPage(props: GenesPanelPageI) {
    const { user } = useContext(UserContext);
    const [body, setbody] = useState<any>(<></>);
    const UserS = new UserService();

    const cuRoll: any = user.data?.rolestoString ?? ""

    useEffect(() => {
        if ((cuRoll === "") ||
            (cuRoll !== "" && (cuRoll !== UserS.user?.rolestoString ?? "-")))
            setbody(<Redirect to='/' />)
        else {
            if (props.match?.params?.panelKey && props.match?.params?.region)
                setBodyDetails(props.match?.params?.panelKey, props.match?.params?.region)
            else
                setBodyGenesPanels()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, user])

    function setBodyDetails(panelKey: number, region: string) {
        setbody(
            <DetailPanelGenes history={props.history}
                panelGenesId={panelKey}
                region={region}
            />
        )
    }
    function setBodyGenesPanels() {
        setbody(
            <PanelGenesBody history={props.history} />
        )
    }

    return (
        <PanelGenesContextState>
            <Layout history={props.history}>
                {body}
            </Layout>
        </PanelGenesContextState>
    )
}
