import React from 'react';
import { useContext } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { LangContext } from "../../../Contexts/Lang";

interface FormCardI {
    title?: JSX.Element,
    body: JSX.Element,
    submit?(e: any): void,
    enableSubmit?: boolean,
    enableBody?: boolean,
    handleCancel?(): void,
    saveLabel?: string,
}
function FormCard(props: FormCardI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const enableSubmit = props.enableSubmit ?? true;
    const enableBody = props.enableBody ?? true;
    return (
        <Card id={"FormCard"} style={{}}>
            {props.title &&
                <Card.Header style={{ padding: "10px 25px" }}>
                    <div style={{ color: "#444B59", fontSize: "17px", fontWeight: "bold" }}>
                        {props.title}
                    </div>
                </Card.Header>
            }
            {enableBody && <Card.Body>
                {props.body}
            </Card.Body>}
            {enableSubmit &&
                <Card.Footer className="text-muted">
                    <div style={{ marginLeft: "10px" }}>
                        <Button variant="outline-primary" style={{ float: "right" }}
                            onClick={props.submit}>
                            {props.saveLabel ?? translate(`common.save`)}
                        </Button>
                        {props.handleCancel &&
                            <Button variant="outline-secondary" style={{ float: "right", marginRight: "10px" }}
                                onClick={props.handleCancel}>
                                {translate(`common.cancel`)}
                            </Button>}
                    </div>
                </Card.Footer>}
        </Card >
    )
}

interface FormRowI {
    label: string,
    input: JSX.Element,
    subInput?: string,
    required?: boolean
}

function FormRow(props: FormRowI) {
    const required = props.required ?? false
    return (
        <Row style={{ marginRight: 0, marginLeft: 0 }}>
            <Col sm={3} style={{ padding: "0 10px 0 0", textAlign: "end", fontWeight: "bold", color: "dimgray" }}>
                {props.label}
                {required && <span style={{ color: "#DD3D4D" }}> *</span>}
            </Col>
            <Col sm={9} style={{ padding: "0 12px 0 0", alignSelf: "center" }}>
                {props.input}
                {props.subInput && <div style={{ fontSize: "11px", color: "gray" }}>
                    {props.subInput}
                </div>}
            </Col>
        </Row>
    )
}

export { FormCard, FormRow }