import React from 'react';
import { Button, Modal } from "react-bootstrap";

interface ModalI {
    handleClose: Function,
    showState: boolean,
    label?: string,
    error?: string,
    labelButton?: string,
    labelTitle?: string,
    labelClose?: string,
    onClick?: Function,
    showClose?: boolean,
    variantConfirm?: string,
    children?: any,
}
export function ModalWarning(props: ModalI) {
    const label = props.label ?? "-";
    const labelTitle = props.labelTitle ?? "-";
    const labelButton = props.labelButton ?? "Yes";
    const labelClose = props.labelClose ?? "Cancel";
    const showClose = props.showClose ?? true;
    const variantConfirm = props.variantConfirm ?? "outline-danger";
    const onClick = props.onClick ?? (() => { })
    return (
        <>
            <Modal show={props.showState} onHide={() => props.handleClose()} animation={false}>
                <Modal.Header closeButton style={{ backgroundColor: '#444B59', color: "#fff" }}>
                    <Modal.Title >{labelTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children ?
                        props.children :
                        label
                    }
                    {props.error &&
                        <>
                            <br /><br />
                            <span style={{ color: "red", fontWeight: "bold" }}>Error:&nbsp;</span>
                            <span style={{ color: "red" }}>{props.error}</span>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {showClose ?
                        <Button variant="secondary" onClick={() => props.handleClose()}>
                            {labelClose}
                        </Button> : <></>}
                    <Button variant={variantConfirm} onClick={() => { onClick(); props.handleClose(); }}>
                        {labelButton}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

