import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoginPage from "./Pages/Login/LoginPage";
import ErrorPage from "./Pages/Error/ErrorPage";
import DomainsPage from './Pages/Home/DomainsPage';
import HealthCheckPage from './Pages/Home/HealthCheckPage';
import CasesPage from './Pages/Home/CasesPage';
import Genes from './Pages/Home/PanelGenesPage';
import HomePage from './Pages/Home/HomePage';
import ReportedVariantsPage from './Pages/Home/ReportedVariantsPage';
import Query from './Components/Cases/Query/Query';
import UserPage from './Pages/Home/UserPage';
import IgvPage from './Pages/IgvPage';
import MaintenancePage from "./Pages/Maintenance/MaintenancePage";
import ChangelogPage from './Pages/Home/ChangelogPage';

function Router() {
	// console.log("routes")
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path='/login' component={LoginPage} />
				<Route exact path='/login/:sys' component={LoginPage} />

				<Route exact path='/' component={HomePage} />
				<Route exact path='/cases' component={CasesPage} />
				<Route exact path='/cases/caseId/:caseId' component={CasesPage} />

				<Route exact path='/cases/_igv/caseId/:caseId/type/:type/sampleSelected/:sampleSelected/gen/:gen/chPos/:chPos/ref/:ref/samples/:samples' component={IgvPage} />

				<Route exact path='/cases/comments/:caseId' component={CasesPage} />

				<Route exact path='/cases/cnv/:caseId' component={CasesPage} />

				<Route exact path='/cases/coverage/gene/caseId/:caseId/' component={CasesPage} />
				<Route exact path='/cases/coverage/gene/caseId/:caseId/:query' component={CasesPage} />
				<Route exact path='/cases/coverage/variant/caseId/:caseId/' component={CasesPage} />
				<Route exact path='/cases/coverage/variant/caseId/:caseId/:query' component={CasesPage} />


				<Route exact path='/cases/query/caseId/:caseId/*' component={Query} />
				<Route exact path='/cases/query/variantDetail/:caseId/:variantId' component={Query} />
				<Route exact path='/cases/query/gene/:caseId/:geneId/:variantId' component={Query} />
				<Route exact path='/cases/query/gene/:caseId/:geneId/' component={Query} />

				<Route exact path='/cases/query/*' component={ErrorPage} />



				<Route exact path='/cases/*' component={ErrorPage} />


				<Route exact path='/domains' component={DomainsPage} />
				<Route exact path='/domains/domainId/:domainId' component={DomainsPage} />

				<Route exact path='/genes' component={Genes} />
				<Route exact path='/genes/:region/:panelKey' component={Genes} />
				<Route exact path='/genes/*' component={ErrorPage} />


				<Route exact path='/variants' component={ReportedVariantsPage} />
				<Route exact path='/variants/:variantId' component={ReportedVariantsPage} />
				<Route exact path='/variants/*' component={ErrorPage} />


				<Route exact path='/user' component={UserPage} />
				<Route exact path='/user/edit' component={UserPage} />
				<Route exact path='/users' component={UserPage} />

				<Route exact path='/changelog' component={ChangelogPage} />

				<Route exact path='/healthcheck' component={HealthCheckPage} />

				<Route exact path='/maintenance' component={MaintenancePage} />

				<Route exact path='*' component={ErrorPage} />
				<Route exact path='/error/:type' component={ErrorPage} />
			</Switch>
		</BrowserRouter>
	)
}

export default Router