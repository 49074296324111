import React, { useReducer, FC, ReactNode, createContext } from 'react';

import english from '../i18n/en.json';
import spanish from '../i18n/es.json';
import { LangActionType } from "./actionsTypes";

type languageT = "EN" | "ES"
interface LangStateI {
    language: languageT;
}

interface SetLanguageActionI {
    type: typeof LangActionType.SET_LANGUAGE;
    payload: languageT;
}


interface ContextPropsI {
    actualLang: LangStateI;
    dispatch: {
        setLanguage: (lang: languageT) => void;
        translate: (key: string) => string;
    }
}

const langReducer = (state: LangStateI, action: SetLanguageActionI): LangStateI => {
    switch (action.type) {
        case LangActionType.SET_LANGUAGE:
            return {
                language: action.payload
            }
        default:
            return state;
    }
}
const getInitialLanguage = (): languageT => {
    let localLang = localStorage.getItem('language')
    if (localLang) {
        return localLang.trim().toUpperCase() === "ES" ? "ES" : "EN";
    } else if (window.navigator.language) {
        localLang = window.navigator.language.trim().toLowerCase().indexOf("es") > -1 ? "ES" : "EN";
        return localLang as languageT;
    }
    return "ES"
}


// let localStorageLangA = localStorage.getItem('language');
// const localStorageLang: languageT = localStorageLangA === "ES" ? localStorageLangA : "EN"
const initialState = {
    language: getInitialLanguage()
}

export const LangContext = createContext({} as ContextPropsI);

interface LangStateProps {
    children: ReactNode;
}

const LangState: FC<LangStateProps> = ({ children }) => {
    const [actualLang, dispatch] = useReducer(langReducer, initialState);

    const setLanguage = (lang: languageT) => {
        localStorage.setItem('language', lang);
        dispatch({
            type: LangActionType.SET_LANGUAGE,
            payload: lang
        });
    }

    const translate = (key: string) => {
        const { language } = actualLang;
        let langData: any;

        if (language === 'EN') {
            langData = english;
        } else if (language === 'ES') {
            langData = spanish;
        }
        let data: any = "-"
        key.split('.').forEach((x: string, i: number) => {
            if (data === undefined) alert(`Error - Langugage Dict: Unexisting key ***${key}***`);
            if (i === 0) data = langData[x]
            else data = data[x]
        });
        return data;
    }


    return (
        <LangContext.Provider
            value={{
                actualLang: actualLang,
                dispatch: {
                    setLanguage,
                    translate
                }
            }}>
            {children}
        </LangContext.Provider>
    );
}

export default LangState;