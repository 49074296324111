//https://www.telerik.com/blogs/react-hooks-and-component-composition
import React from 'react';
import { useEffect, useState, useContext } from 'react'
import './Paginator.css'
import Pagination from '@vlsergey/react-bootstrap-pagination';
import { Form } from 'react-bootstrap';
import { LangContext } from '../../../Contexts/Lang';
interface PaginatorI {
    reloadCall: Function,
    onChangePage?(i?: any): void,
    onItemsPerPageChange?(i?: any): void,
    pageSizeOptions?: number[],
    id?: string,
    name?: string,
}
export default function Paginator(props: PaginatorI) {
    const { dispatch: { translate } } = useContext(LangContext);
    let pageSizeOptions = [25, 50, 100, 250, 500]
    const id = props.id ?? "";
    if (props.pageSizeOptions && props.pageSizeOptions.length > 0)
        pageSizeOptions = props.pageSizeOptions
    const [pagesAmount, setPagesAmount] = useState<number>(0);
    const [actualPage, setActualPage] = useState<number>(0);
    let itemsPerPageInit = JSON.parse(localStorage.getItem(`pagination${"_" + id}`) ?? `{"itemsPerPage":${pageSizeOptions[0]}}`).itemsPerPage
    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(itemsPerPageInit));
    useEffect(() => {
        setActualPage(0)
    }, [itemsPerPage, setItemsPerPage])
    function handleChangePage(i: any) {
        setActualPage(i.target.value)
        if (props.onChangePage) {
            props.onChangePage(i.target.value)
        }
        props.reloadCall()
    }
    function pageSize_set(totalRows: any) {
        let amount = (totalRows + itemsPerPage) / itemsPerPage;
        if ((totalRows + itemsPerPage) % itemsPerPage === 0)
            amount -= 1
        amount = Math.floor(amount)
        setPagesAmount(amount);
    }
    const options = pageSizeOptions.map(i => (<option key={i} value={i}>{i}</option>))
    const paginatorComponent = (
        <Pagination showFirstLast={false}
            atBeginEnd={1}
            value={actualPage}
            onChange={handleChangePage}
            totalPages={pagesAmount}
            style={{ margin: 0, height: "28px" }} />)
    const itemsPerPagecomponent = (
        <div style={{ display: "flex" }}>
            <p style={{
                width: "114px",
                height: "30px",
                color: "dimgray",
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                margin: 0,
            }}>
                {translate(`home.itemsPerPage`)}
            </p>
            <Form.Control as="select"
                onChange={(e: any) => {
                    setItemsPerPage(parseInt(e.target.value));
                    localStorage.setItem(`pagination${"_" + id}`, JSON.stringify({ itemsPerPage: e.target.value }))
                    setActualPage(0)
                    if (props.onItemsPerPageChange) {
                        props.onItemsPerPageChange(e.target.value)
                    }
                }}
                value={itemsPerPage}
                style={{ height: "28px", width: "70px", border: "1px solid #DEE2E6", marginRight: "2px", fontSize: "12px", borderRadius: 0 }} >
                {options}
            </Form.Control>
        </div>
    )
    const component = (
        <div style={{ justifyContent: "space-between", display: "flex" }}>
            {itemsPerPagecomponent}
            {paginatorComponent}
        </div>
    )
    return {
        actualPage,
        setActualPage,
        handleChangePage,
        pageSize_set,
        itemsPerPage,
        component,
        paginatorComponent,
        itemsPerPagecomponent
    }
}


