import React from 'react';
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Badge } from "react-bootstrap";
import { LangContext } from "../../../Contexts/Lang";
import { Tooltip } from "../Tooltip/Tooltip";

interface EnableDisableBadgeI {
    enable: boolean,
    styles?: React.CSSProperties,
}
export default function EnableDisableBadge(props: EnableDisableBadgeI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const styles = props.styles ?? {}
    const enable = (
        <Tooltip label={translate('common.enable')}>
            <FontAwesomeIcon icon={faCheckCircle}
                style={{
                    color: 'gray',
                    ...styles
                }} />
        </Tooltip>
    )
    const disable =
        <Badge style={{
            backgroundColor: '#FF4E58',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            ...styles
        }}>
            <FontAwesomeIcon icon={faTimesCircle} style={{}} /> &nbsp;
            <label style={{ margin: 0 }}>{translate('common.disableAdo')}</label>
        </Badge>
    return (<>
        {props.enable ? enable : disable}
    </>
    )
}