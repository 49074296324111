import React, { useReducer } from "react";
import CasesContext, { ContextCasesStatesI, FiltersCasesI } from "./CasesContext";
import CasesReducer from "./CasesReducer";
import { CasesActionType } from "../actionsTypes";
import StudyService from "../../Services/StudyServices";
import { ContextStateManager } from "../ContextStateManager";

const CasesContextState = (props: any) => {
    const StudyS = new StudyService();
    const initialState: ContextCasesStatesI = {
        study: { data: null, loaded: false, loading: false, error: false },
        cases: { data: null, loaded: false, loading: false, error: false, refresh: false },
        variantGroups: { data: [], loaded: false, loading: false, error: false },  //TODO: VariantGroups deberia estar dentro de cases.data, ya que viene dentro de este, modificar junto con CasesBody
        selecteds: [],
        filters: {
            studyType: null,
            studyState: "",
            dateCreatedFromSearch: "",
            dateCreatedToSearch: "",
            descriptionSearch: "",
            nameSearch: "",
            sortColumn: ""
        }
    };
    const [state, dispatch] = useReducer(CasesReducer, initialState);
    const CSM = new ContextStateManager(state, initialState, CasesActionType, dispatch)
    const getCases = async (filters?: FiltersCasesI, pageNumber?: number, pageSize?: number) => CSM.GetAsyncData("GET_CASES_", { filters, pageNumber, pageSize }, StudyS, "search")
    const refreshCases = () => dispatch({ type: CasesActionType.GET_CASES_REFRESH })
    const getCase = async (id: string) => {
        dispatch({ type: CasesActionType.GET_VARIANT_GROUPS_LOADING, payload: null });
        const setNewCase = (resData: any) => {
            dispatch({ type: CasesActionType.GET_VARIANT_GROUPS_SUCCESS, payload: resData.groups });
            let newCase = resData.study;
            newCase.code = newCase.code ?? "";
            if (!newCase.description || newCase.description.trim() === "")
                newCase.description = " - ";
            if (!newCase.professional || newCase.professional.trim() === "")
                newCase.professional = " - ";
            if (!newCase.institution || newCase.institution.trim() === "")
                newCase.institution = " - ";
            newCase.subtype = newCase.subtype ?? "";
            newCase.customLibraryPrepKit = newCase.customLibraryPrepKit ?? "";
            newCase.genomeReference = newCase.genomeReference ?? "";
            newCase.studyType = newCase.type ?? "";
            if (newCase.dateCreated && newCase.dateCreated !== "")
                newCase.dateCreated = new Date(newCase.dateCreated)
            if (newCase.dateCreated.toString() === "Invalid Date")
                newCase.dateCreated = " - "
            else
                newCase.dateCreated = newCase.dateCreated.toISOString().replaceAll("-", "/").replace("T", " ").split(".")[0]
            return newCase
        }
        CSM.GetAsyncData("GET_CASE_", { id }, StudyS, "read_case", setNewCase)
    };

    const setFilters = (key: string, value: string): boolean => CSM.SetFilters("filters", "SET_FILTERS", key, value)
    const cleanFilters = () => CSM.CleanFilters("filters", "SET_FILTERS")

    const handleSelecteds = (id: string) => CSM.HandleSelecteds("selecteds", "SET_SELECTEDS", state.cases.data?.result?.results ?? [], id)
    const handleSelectAll = () => CSM.HandleSelectAll("selecteds", "SET_SELECTEDS", state.cases.data?.result?.results ?? [])

    return (
        <CasesContext.Provider
            value={{
                study: state.study,
                cases: state.cases,
                variantGroups: state.variantGroups,
                filters: state.filters,
                selecteds: state.selecteds,
                dispatch: {
                    getCase,
                    getCases,
                    refreshCases,
                    setFilters,
                    cleanFilters,
                    handleSelecteds,
                    handleSelectAll
                }
            }}
        >
            {props.children}
        </CasesContext.Provider>
    );
};

export default CasesContextState;