import React from 'react';
import { Badge } from "react-bootstrap";
import { bootstrapVariantType } from "../Interfaces";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useContext } from "react";
import { LangContext } from "../../../Contexts/Lang";
import { Popover } from "../Popover";
import { evidenciaAcmg } from "../../../utils/BioFunctionalData";
import ThemeContext, { BPlatformThemeI } from 'src/Contexts/Theme/ThemeContext';

interface BadgeVariantEvidenceI {
    labelInput: string,
    id: number,
    size?: "small" | "big",
    fontSize?: string,
}

export default function BadgeVariantMitomap(props: BadgeVariantEvidenceI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const size = props.size ?? "small"
    if (props.labelInput.trim().length === 0) return <span key={props.id}></span>
    const label = size === "small" ?
        props.labelInput.trim().toUpperCase() :
        (props.labelInput[0].toUpperCase() + props.labelInput.substring(1, props.labelInput.length)).replaceAll("_", " ")
    let backColor = getMitomapTypeVariantType(props.labelInput);
    const badge = (
        < Badge key={props.id}
            style={{
                fontWeight: "inherit",
                fontSize: props.fontSize ?? "13px",
                margin: "1px 2px",
                color: "white",
                alignSelf: "center",
                backgroundColor: getBackgroundColor(theme, backColor as bootstrapVariantType),
            }}>
            {size === "small" ?
                <>
                    {label}
                    <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: "10px", margin: "1px 5px", verticalAlign: "text-bottom" }} />
                </> :
                <b>{label}</b>
            }
        </Badge>
    )
    if (size === "small") {
        const labelsplited = label.split(":");
        if (evidenciaAcmg.indexOf(labelsplited[0].trim()) === -1) return <></>
        const content = translate(`casesQuery.intervarEvidence.${labelsplited[0].trim()}`)
        return <Popover title={labelsplited[0] + (labelsplited.length > 1 ? ` : ${labelsplited[1]}` : "")}
            key={props.id}
            content={content}
            trigger={"hover"} style={{ fontSize: "10px", zIndex: "1050" }}>
            <span>
                {badge}
            </span>
        </Popover>;
    }
    return badge
}

const getMitomapTypeVariantType = (veredict: string): bootstrapVariantType => {
    switch (veredict.trim().toUpperCase()) {
        case "LIKELY_PATHOGENIC": return "danger";
        case "POSSIBLY_PATHOGENIC": return "warning";
        default: return "secondary"
    }
}

const getBackgroundColor = (theme : BPlatformThemeI, vartype: bootstrapVariantType) => {
    let color = "#999999"
    switch (vartype) {
        case "danger": color = theme.redBitgenia; break;
        case "warning": color = theme.warning; break;
        case "info": color = theme.info; break;
    }
    return color;
}

