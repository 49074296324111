import React from 'react';
import { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import ReportedVariantService from "../../../Services/ReportedVariantService";
import Loader from "../../Common/Loader/Loader";
import DetailLayout from "../../Layout/Detail/DetailLayout"
import DetailVariantsHeader from "./DetailReportedVariantsHeader"
import { useWindowSize } from '../../../Helpers/WindowsSizeHelper';
import ProcessReportedVariant from "../ProcessReportedVariants/ProcessReportedVariant";
import { DetailTableReport, DetailTableStudyCase, DetailTableVariant } from "./DetailReportedVariantsTables";
import CardReportedBy from "./CardReportedBy";
import { ModalWarning } from "../../Common/ModalWarning";
import { LangContext } from "../../../Contexts/Lang";
import ReportedVariantsContext from "../../../Contexts/ReportedVariants/ReportedVariantsContext";

interface DetailVariantsI {
    history: any,
    variantId: string
}
export default function DetailVariants(props: DetailVariantsI) {
    const { variant, dispatch: { getVariant, refreshVariants } } = useContext(ReportedVariantsContext);
    const { dispatch: { translate } } = useContext(LangContext);
    const ReportedVariantS = new ReportedVariantService();

    const widthType = useWindowSize().widthType;

    const [showProcessVariant, setshowProcessVariant] = useState<boolean>(false)
    const handleCloseProcessVariant = () => setshowProcessVariant(false);
    const handleShowProcessVariant = () => setshowProcessVariant(true);

    const [showDeleteVariant, setshowDeleteVariant] = useState<boolean>(false)
    const handleCloseDeleteVariant = () => setshowDeleteVariant(false);
    const handleShowDeleteVariant = () => setshowDeleteVariant(true);

    function handleGetVariant() {
        getVariant(props.variantId)
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted && props.variantId) handleGetVariant()
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loaderElement = (
        <div style={{
            display: "block",
            minHeight: "70vh",
            background: "linear-gradient(to top, rgba(0,0,0, 0), rgba(255,255,255,1), rgba(255,255,255,1))",
            width: "100%",
            paddingTop: "70px",
        }}>
            <Loader onContainer={true} type={'DNA'} />
        </div>
    )
    const repVar = variant.data?.reportedVariantInfo

    const detailTableReport = (
        <DetailTableReport
            decision={repVar?.decision}
            diagnostic={repVar?.diagnostic}
            confirmation={repVar?.confirmation}
            genomeReference={repVar?.genomeReference}
            library={repVar?.libraryPrepKit}
            lineage={repVar?.lineage}
            observation={repVar?.observation} />
    )

    const detailTableStudyCase = (
        <DetailTableStudyCase
            studyName={repVar?.studyName}
            samples={variant.data?.study?.samples}
            zygosity={repVar?.zygosity}
        />
    );

    const cardReportedBy = (
        <CardReportedBy
            institution={repVar?.institution}
            email={repVar?.email}
            contact={repVar?.contact}
            createdDate={repVar?.simpleDateFormatCreated}
            shared={repVar?.shared}
        />
    )
    const detailTableVariant = (
        <DetailTableVariant
            vcfId={repVar?.vcfId}
            genomeReference={variant.data?.study}
            chromosome={repVar?.chromosome}
            position={repVar?.position}
            genename={repVar?.reportedVariant?.genename}
            reference={repVar?.reference}
            alternative={repVar?.alternative}
            impact={repVar?.reportedVariant?.impact}
            effect={repVar?.reportedVariant?.effect}
            codon={repVar?.codon}
            classification={repVar?.classification}
        />
    )

    return (
        <>
            {showDeleteVariant &&
                < ModalWarning labelTitle={translate('common.confirm')}
                    label={translate("variant.confirmDeleteSelectedReportedVariant")}
                    labelButton={translate('home.delete')}
                    showState={showDeleteVariant}
                    handleClose={handleCloseDeleteVariant}
                    onClick={() => {
                        ReportedVariantS.delete([props.variantId]).then(() =>{
                            refreshVariants();
                            props.history.goBack()
                        })
                    }}
                />
            }
            {showProcessVariant &&
                <ProcessReportedVariant history={props.history}
                    editVariantId={props.variantId}
                    show={showProcessVariant}
                    handleClose={handleCloseProcessVariant}
                    handleReload={() => {
                        handleGetVariant();
                        handleCloseProcessVariant()
                        refreshVariants();
                    }}
                />}
            <DetailLayout DetailHeader={
                <DetailVariantsHeader history={props.history}
                    handleShowProcessVariant={handleShowProcessVariant}
                    reportedBy={repVar?.createdBy ?? ""}
                    handleShowDeleteVariant={handleShowDeleteVariant}
                />
            }>
                {variant.loading ? loaderElement :
                    (widthType === "xl" || widthType === "xxl") ?
                        <Row style={{ margin: 0 }}>
                            <Col xl={5} style={{ padding: "0 10px" }}>
                                {detailTableVariant}
                            </Col>
                            <Col xl={4} style={{ padding: "0 10px" }} >
                                {detailTableReport}
                            </Col>
                            <Col xl={3} style={{ padding: "0 10px" }}>
                                {detailTableStudyCase}
                                {cardReportedBy}
                            </Col>
                        </Row>
                        :
                        widthType !== "sm" ?
                            <Row style={{ margin: 0 }}>
                                <Col md={6} style={{ padding: "0 10px" }} >
                                    {detailTableReport}
                                    {cardReportedBy}
                                </Col>
                                <Col md={6} style={{ padding: "0 10px" }}>
                                    {detailTableStudyCase}
                                    {detailTableVariant}
                                </Col>
                            </Row> :
                            <Row style={{ margin: 0 }}>
                                <Col sm={12} style={{ padding: "0 10px" }} >
                                    {detailTableStudyCase}
                                    {detailTableReport}
                                    {detailTableVariant}
                                    {cardReportedBy}
                                </Col>
                            </Row>
                }
            </DetailLayout >
        </>
    )
}
