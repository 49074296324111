import React, { useContext } from "react";
import { BulletRectsItemProps, ResponsiveBullet } from "@nivo/bullet";
import { compareToSort } from "src/utils/helpers";
import CoverageProteinBulletRange from "./CoverageProteinBulletRange";
import { LangContext } from "src/Contexts/Lang";
import { Col, Row } from "react-bootstrap";

interface CoverageProteinI {
    genename: string,
    transcript: string,
    chrom: string,
    start: string,
    end: string,
    mean: string,
    coverage: string
}

interface CoverageProteinBulletI {
    geneName: string,
    dataCoverage: CoverageProteinI[],
    height: number,
}
export default function CoverageProteinBullet(props: CoverageProteinBulletI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const colors = ["rgb(255, 255, 255, 0)", "#00D2C3"];
    const markerColors = ["rgba(239, 211, 65, 0.2)"];
    let chromosomeByGenes: any = props.dataCoverage.reduce((group: any, product: any) => { // los genes como por ejemplo el gen SHOX, estan en mas de un cromosoma, en este caso cromosoma X e Y
        const { chrom } = product;
        group[chrom] = group[chrom] ?? { data: [] };
        group[chrom].data.push(product);
        return group;
    }, {});
    chromosomeByGenes = (Object.values(chromosomeByGenes) ?? []).map((x: any) => {
        let rangesData: number[] = []
        let maxMean: number = +x.data.sort((a: any, b: any) => compareToSort(a, b, "mean", true, true))[0].mean;
        let minMean: number = +x.data.sort((a: any, b: any) => compareToSort(a, b, "mean", false, true))[0].mean;
        let dataCovSorted = x.data.sort((a: any, b: any) => compareToSort(a, b, "start", false, true))
        dataCovSorted.forEach((x: any) => { rangesData.push(+x.start); rangesData.push(+x.end); });
        // console.log(rangesData)
        const newData: any = [
            {
                id: props.geneName,
                geneName: props.geneName,
                ranges: rangesData,
                measures: +rangesData[rangesData.length - 1],
                markers: []
            }
        ]
        const minValue = +dataCovSorted[0].start ?? "auto"
        const maxValue = +dataCovSorted[dataCovSorted.length - 1].end;
        return ({
            rangesData,
            data: newData,
            dataCovSorted,
            minMean,
            maxMean,
            minValue,
            maxValue
        })
    })

    return (
        <Row id="CoverageProteinBullet">
            {chromosomeByGenes.map((x: any) => {
                return (
                    // <Col lg={12} xl={6} >
                    <Col lg={12} >
                        <div style={{ display: "grid" }}>
                            <div style={{ overflowX: "auto" }}>
                                <div style={{ height: props.height, width: "100%", padding: "5px", backgroundColor: "white", minWidth: x.rangesData.length * 15 }}>
                                    <ResponsiveBullet
                                        margin={{ top: 40, right: 20, bottom: 50, left: 20 }}
                                        spacing={46}
                                        titleAlign="start"
                                        axisPosition={undefined}
                                        isInteractive={true}
                                        titleOffsetY={-120}
                                        titleOffsetX={-111110}
                                        rangeBorderColor={{ from: "color", modifiers: [] }}
                                        minValue={x.minValue}
                                        maxValue={x.maxValue}
                                        rangeColors={colors}
                                        measureColors={["rgba(10,110,095,0.20)"]}
                                        measureSize={0.01}
                                        markerSize={3}
                                        markerColors={markerColors}
                                        tooltip={() => { return <></> }}

                                        theme={{
                                            axis: {
                                                domain: { line: { stroke: "transparent", strokeWidth: 0 } },
                                                legend: { text: { fill: "rgba(0,0,0,0)", fontSize: 0 } },
                                                ticks: {
                                                    line: { stroke: "rgba(0,0,0,0)", strokeWidth: 0 },
                                                    text: { fill: "rgba(0,0,0,0)", fontSize: 0 }
                                                }
                                            }
                                        }}
                                        data={x.data
                                            .map((d: any) => ({
                                                ...d,
                                                title: (
                                                    <text dy={12}>
                                                        <tspan
                                                            x={0}
                                                            dy={118}
                                                            style={{
                                                                fill: "#ADB3C3",
                                                                fontWeight: 500,
                                                                fontSize: "30px",
                                                                lineHeight: "16px"
                                                            }}
                                                        >
                                                            &nbsp;{d.geneName}
                                                        </tspan>
                                                        <tspan
                                                            x={0}
                                                            dy={18}
                                                            style={{
                                                                fill: "#ADB3C3",
                                                                fontWeight: 500,
                                                                fontSize: "12px",
                                                                lineHeight: "16px"
                                                            }}
                                                        >
                                                            &nbsp;&nbsp;Chr:7
                                                        </tspan>
                                                    </text>
                                                )
                                            }))
                                        }
                                        rangeComponent={(i: BulletRectsItemProps) => {
                                            return (
                                                <CoverageProteinBulletRange
                                                    proteinCovData={x.dataCovSorted}
                                                    bulletProps={i}
                                                    maxMean={x.maxMean}
                                                    minMean={x.minMean}
                                                    height={props.height - 65}
                                                />
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <span style={{ color: "gray", marginRight: "20px" }}>
                            &nbsp;&nbsp;
                            {
                                Array.isArray(x.dataCovSorted) && x.dataCovSorted.length > 0 &&
                                <>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>{x.dataCovSorted[0].genename}&nbsp;</span>
                                    <span style={{ fontSize: "10px" }}>-&nbsp;Chrom&nbsp;</span>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>{x.dataCovSorted[0].chrom.replace("chr", "")}&nbsp;:&nbsp;&nbsp;</span>
                                </>
                            }
                            <span style={{ fontSize: "10px" }}>
                                &nbsp;
                                <span style={getDotReferenceS("#54a083", "10px")} />
                                <span style={getDotReferenceS("#66c2a5", "10px")} />
                                Coberturas promedio obtenidas por regiones target segun kit de captura empleado;
                                &nbsp;
                                <span style={{ height: 1, width: 25, backgroundColor: "#66c2a5", display: "inline-block", marginRight: "5px", marginBottom: 3 }} />
                                {translate("common.uncoveredIntronicRegionOutOfTarget")}
                                {+(+x.minMean).toFixed() <= 6 && <>
                                    ;&nbsp;&nbsp;
                                    <span style={getDotReferenceS("#f75a73", "10px")} />
                                    {"Coberturas promedio menor o igual 6 X;"}
                                    &nbsp;&nbsp;
                                </>
                                }
                                {+(+x.minMean).toFixed() <= 20 && <>
                                    ;&nbsp;&nbsp;
                                    <span style={getDotReferenceS("#fc8d62", "10px")} />
                                    {"Coberturas promedio menor o igual 20 X;"}
                                </>
                                }
                            </span>
                        </span>

                    </Col>
                )
            }
            )}
        </Row >
    );
};

function getDotReferenceS(color: string, size: string) {
    return {
        height: size,
        width: size,
        backgroundColor: color,
        borderRadius: 0,
        display: "inline-block",
        marginRight: "5px"
    }
}

export type { CoverageProteinI }

// interface BulletChartProps {
//     data: Datum[];
//     colors: string[];
//     minValue?: number | "auto";
//     maxValue?: number | "auto";
//     markerColors?: string[];
// }
