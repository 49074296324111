import React from 'react';
import { useContext, useEffect } from "react";
import { Col, Row } from 'react-bootstrap';
import DetailCaseHeader from "./DetailCaseHeader";
import DetailCaseStatistics from "./Statistics/Statistics";
import DetailCaseData from "./DetailCaseData";
import { useWindowSize } from "../../../Helpers/WindowsSizeHelper";
import PanelRight from "./DetailCasePanelRight";
import DetailLayout from "../../Layout/Detail/DetailLayout";
import CasesContext from "../../../Contexts/Cases/CasesContext";
import Loader from "../../Common/Loader/Loader";

interface DetaliCaseI {
    history: any
    caseId: number
}

export default function DetaliCase(props: DetaliCaseI) {
    const { study, variantGroups, dispatch: { getCase, refreshCases } } = useContext(CasesContext);
    const { widthType } = useWindowSize()
    function handleGetCase() { getCase(props.caseId.toString().trim()) }
    useEffect(() => {
        let isMounted = true
        if (isMounted && props.caseId) handleGetCase()
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const DetailsAndStatisticsPanel = (
        <Col sm={12} md={9} style={{ padding: "0 25px" }}>
            <Row>
                <DetailCaseData actualCase={study} />
            </Row>
            <br />
            <Row>
                <DetailCaseStatistics history={props.history}
                    actualCase={study.data} />
            </Row>
        </Col>)
    const panelRight = (
        <Col sm={12} md={3} style={{ padding: "0 10px", marginBottom: "20px" }}>
            <PanelRight
                history={props.history}
                caseId={props.caseId}
                case={study.data}
                variantGroups={variantGroups.data}
                reloadCall={() => {
                    handleGetCase()
                    refreshCases()
                }} />
        </Col>
    )
    return (
        <DetailLayout DetailHeader={
            <DetailCaseHeader history={props.history}
                actualCase={study.data}
                setReload={() => {
                    handleGetCase()
                    refreshCases()
                }}
            />
        }>
            {study.loading ?
                <Loader onContainer={true} type={'DNA'} /> :
                <Row style={{ width: "100%", margin: 0 }}>
                    {(widthType === "xs" || widthType === "sm") ?
                        <>
                            {panelRight}
                            {DetailsAndStatisticsPanel}
                        </> :
                        <>
                            {DetailsAndStatisticsPanel}
                            {panelRight}
                        </>
                    }
                </Row>
            }
        </DetailLayout>
    )
}
