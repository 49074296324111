import React from 'react';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { LangContext } from "../../../../../Contexts/Lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faFolderOpen, faSave } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from "../../../../Common/Tooltip/Tooltip";
interface PanelHeaderI {
    handleShowSaveModal: any,
    handleShowmodalLoad: any,
    enableSaveFilter: boolean
}

export default function PanelHeader(props: PanelHeaderI) {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <div style={{
            display: "flex",
            padding: "15px 18px 8px 19px",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <h6 style={{ color: "darkslategray", margin: 0, fontSize: "15px" }}>
                <FontAwesomeIcon icon={faFilter} style={{ color: "gray", fontSize: "15px", marginRight: "7px" }} />
                {translate('common.filters')}
            </h6>
            <div style={{ display: "flex" }}>
                <Tooltip label={translate('casesQuery.loadFilters')} placement={"bottom"} autoClose={1500}>
                    <Button variant="light"
                        style={{
                            border: "solid 1px lightgray", height: "30px", width: "33px", borderRadius: 0, justifyContent: "center",
                            alignItems: "center", display: "flex", marginLeft: "5px"
                        }}
                        onClick={props.handleShowmodalLoad}>
                        <FontAwesomeIcon icon={faFolderOpen} style={{ color: "gray", fontSize: "15px" }} />
                    </Button>
                </Tooltip>
                <Tooltip label={translate('casesQuery.saveFilters')} placement={"bottom"} autoClose={1500}>
                    <Button variant="light"
                        disabled={!props.enableSaveFilter}
                        style={{
                            border: "solid 1px lightgray", height: "30px", width: "33px", borderRadius: 0, justifyContent: "center",
                            alignItems: "center", display: "flex", marginLeft: "5px"
                        }}
                        onClick={props.handleShowSaveModal}
                    >
                        <FontAwesomeIcon icon={faSave} style={{ color: "gray", fontSize: "15px" }} />
                    </Button>
                </Tooltip>
            </div>
        </div>
    )
}
