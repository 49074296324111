import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import "./layout.css"
import { LangContext } from '../../Contexts/Lang';
import { BodysT } from '../Common/Interfaces'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faComment } from '@fortawesome/free-solid-svg-icons';
import UserContext from '../../Contexts/User/UserContext';
import ThemeContext from '../../Contexts/Theme/ThemeContext';

type navbarT = "default" | "system";
interface NavbarSecondaryI {
  history: any,
}
export default function NavbarSecondary(props: NavbarSecondaryI) {
  const { dispatch: { translate } } = useContext(LangContext);
  const { user } = useContext(UserContext);
  let configType: navbarT = "default";
  // console.log(user.data)
  if (user.loaded && user.data.rolestoString.indexOf("SYSTEM") > -1) configType = "system";

  const splitedUrl = props.history.location?.pathname.split("/")
  const initializeNavbar = () => {
    if (configType === "system") return (splitedUrl.length > 1 && splitedUrl[1].trim() !== "") ? splitedUrl[1] : "domains"
    else return (splitedUrl.length > 1 && splitedUrl[1].trim() !== "") ? splitedUrl[1] !== "query" ? splitedUrl[1] : "cases" : "cases"
  }
  const [navbar, setnavbar] = useState<BodysT>(initializeNavbar())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setnavbar(initializeNavbar()), [user])


  if (configType === "default") {
    const casesLabel = splitedUrl.indexOf("query") > -1 ?
      <>
        {translate("home.cases").toUpperCase()} &nbsp;&nbsp;
        <FontAwesomeIcon icon={faSearchPlus} style={{ border: "none", fontSize: "15px" }} />
      </> : splitedUrl.indexOf("comments") > -1 ?
        <>
          {translate("home.cases").toUpperCase()} &nbsp;&nbsp;
          <FontAwesomeIcon icon={faComment} style={{ border: "none", fontSize: "15px" }} />
        </> : translate("home.cases").toUpperCase();
    return (
      <div className="secondary-navbar">
        <TabNavbar bodyType="cases"
          label={casesLabel}
          navbar={navbar}
          setnavbar={setnavbar} />
        <TabNavbar bodyType="genes"
          label={translate('common.genesPanel').toUpperCase()}
          navbar={navbar}
          setnavbar={setnavbar} />
        <TabNavbar bodyType="variants"
          label={translate('home.reportedVariants').toUpperCase()}
          navbar={navbar}
          setnavbar={setnavbar} />
      </div >
    )
  } else {
    return (
      <div className="secondary-navbar">
        <TabNavbar bodyType="domains"
          label={translate("common.domains").toUpperCase()}
          navbar={navbar}
          setnavbar={setnavbar} />
        <TabNavbar bodyType="genes"
          label={translate('common.genesPanel').toUpperCase()}
          navbar={navbar}
          setnavbar={setnavbar} />
        <TabNavbar bodyType="users"
          label={translate('common.users').toUpperCase()}
          navbar={navbar}
          setnavbar={setnavbar} />
        <TabNavbar bodyType="healthcheck"
          label={"HEALTHCHECK"}
          navbar={navbar}
          setnavbar={setnavbar} />
      </div >
    )
  }
}

interface TabNavbarI {
  bodyType: BodysT,
  label: string | JSX.Element,
  navbar: BodysT,
  setnavbar(i: BodysT): void
}
function TabNavbar(props: TabNavbarI) {
  const { theme } = useContext(ThemeContext);
  const spanStyles = {
    margin: "5px 5px 3px 5px",
    color: "#ccc",
    borderRadius: "0",
    padding: "0px 15px 0px",
    border: "none",
    fontSize: "16px",
    postition: "fixed"
  }
  return (
    <Link to={`/${props.bodyType}`} style={{ textDecoration: "none", color: theme.backgroundPrimary }}>
      <Button variant="outline-light"
        style={{ ...spanStyles, color: (props.navbar === props.bodyType ? "darkslategray" : "#ccc") }}
        active={props.navbar === props.bodyType ? true : false}
        onClick={() => { props.setnavbar(props.bodyType) }}
      >
        {props.label}
      </Button >
    </Link>
  )
}