import React from 'react';
import { useContext } from 'react';
import { Form, Table, Card } from "react-bootstrap";
import { LangContext } from "../../../Contexts/Lang";

interface VariantIdentificationTableRowI {
    id: string,
    checked: boolean,
    position: string,
    change: string,
    gen: string
}
interface VariantIdentificationTableI {
    variants: VariantIdentificationTableRowI[],
    setvariants(variants: VariantIdentificationTableI[]): void,
}
export default function VariantIdentificationTable(props: VariantIdentificationTableI) {
    const { dispatch: { translate } } = useContext(LangContext);

    return (<Card style={{
        borderRadius: "0px",
        maxHeight: "400px", overflowY: "auto"
    }}>
        <Card.Body style={{ padding: "0px" }}>
            <Table bordered hover size="sm" style={{ marginBottom: 0 }}>
                <thead>
                    <tr style={{ color: "dimgray", "backgroundColor": "#FAFAFA", margin: 0, padding: "5px", fontSize: "10px", textTransform: "uppercase" }}>
                        <th style={{}}>  </th>
                        <th style={{}}>  {translate("casesQuery.chrPosition")}</th>
                        <th style={{}}>  {translate("common.change")}</th>
                        <th style={{}}>  {translate("common.gene")}</th>
                    </tr>
                </thead>
                <tbody>
                    {(props.variants).map((x: VariantIdentificationTableRowI, i: number) => {
                        return (
                            <tr key={i} onClick={() => {
                                // handleVariantsSelecteds(x.data[0].id) 
                            }}>
                                <td style={{ fontSize: "10px", padding: "1px 5px", verticalAlign: "middle", width: "15px", textAlign: "center" }}>
                                    <Form.Check type="checkbox"
                                        checked={x.checked ?? false}
                                        onChange={() => {
                                            props.setvariants(props.variants.map((y: any) => {
                                                if (y.id === x.id) y = { ...y, checked: !y.checked }
                                                return y
                                            })
                                            )
                                        }}
                                    />
                                </td>
                                <td style={{ fontSize: "10px", padding: "1px 5px", verticalAlign: "middle" }}>
                                    {x.position}
                                </td>
                                <td style={{ fontSize: "10px", padding: "1px 5px", verticalAlign: "middle", wordBreak: "break-word" }}>
                                    {x.change}
                                </td>
                                <td style={{ fontSize: "10px", padding: "1px 5px", verticalAlign: "middle" }}>
                                    {x.gen}
                                </td>
                            </tr>)

                    })}
                </tbody>
            </Table>
        </Card.Body>
    </Card>
    )
}
const getVariantsSelecteds_IdsPosChangeGene = (variants: any[] = [], variantsSelecteds: string[]): VariantIdentificationTableRowI[] => {
    const variantIdsPosChangeGen: VariantIdentificationTableRowI[] = variants
        .map((x: any): VariantIdentificationTableRowI => {
            const firstVariant = x.value1[0];
            const chPosition = `${firstVariant.chromosome}:${firstVariant.position}`
            return ({
                id: x.value1.map((x: any) => x.id).join(','),
                position: chPosition,
                change: `${firstVariant.reference} > ${firstVariant.alternative}`,
                gen: firstVariant.infoColumnData.GENE_NAME,
                checked: true,
            })
        });

    return variantIdsPosChangeGen
        .filter((x: VariantIdentificationTableRowI) => {
            return variantsSelecteds.find((y: any) => y === x.id);
        })
}

export { VariantIdentificationTable, getVariantsSelecteds_IdsPosChangeGene }
export type { VariantIdentificationTableRowI, VariantIdentificationTableI }