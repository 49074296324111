import React from 'react';
import { Badge, Table, Form, Dropdown, Row, Col } from 'react-bootstrap';
import "../Common/table.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faCog, faUpload, faEdit, faEnvelope, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Common/Loader/Loader';
import { LinkENSEMBL, NcbiDbsnpLinksByVcfId } from '../Common/Links/Links';
import BadgeVariantImpact from '../Common/Badges/BadgeVariantImpact';
import { BadgeZygosity } from '../Common/Badges/BadgeZygosity';
import CopyToClipboard from '../Common/CopyToClipboard';
import { useContext } from 'react';
import { LangContext } from '../../Contexts/Lang';
import { Tooltip } from '../Common/Tooltip/Tooltip';
import ReportService from '../../Services/ReportService';
import ReportedVariantsContext from '../../Contexts/ReportedVariants/ReportedVariantsContext';
import { Urlify } from '../../Helpers/Helpers';
import BadgeVariantVeredict from '../Common/Badges/BadgeVariantVeredict';

interface ReportedVarintsTableI {
    history: any,
    // variants: any,
    // handleFilter: any,
    // variantsLoading: any,
    variantsSelecteds: any,
    handleVariantsSelecteds?: any,
    handleReload(): void,
    handleDelete?(variantId: string): void,
    handleEdit?(variantId: string): void,

}
export default function ReportedVarintsTable(props: ReportedVarintsTableI) {
    const { variants } = useContext(ReportedVariantsContext);
    const { dispatch: { translate } } = useContext(LangContext);
    let rows = []
    if (variants.data?.reports && variants.data?.reports.length > 0)
        rows = variants.data?.reports.map((x: any, i: number) =>
            <TableRow key={i}
                actualRow={x}
                variantsSelecteds={props.variantsSelecteds}
                handleVariantsSelecteds={props.handleVariantsSelecteds}
                handleReload={props.handleReload}
                history={props.history}
                handleEdit={props.handleEdit}
                handleDelete={props.handleDelete}
            />
        )
    const columnTitleStyle = { padding: 4, fontSize: 14 }
    return (
        <>
            {variants.loading ?
                <Loader onContainer={true} type={'DNA'} /> :
                <div style={{ overflowY: "hidden", overflowX: "auto" }}>
                    <Table responsive="md" className="selectTable" style={{ marginBottom: "-1px" }}>
                        <thead>
                            <tr style={{ color: "dimgray", "backgroundColor": "#FAFAFA", margin: 0, padding: "5px" }}>
                                <th style={columnTitleStyle} ></th>
                                <th style={columnTitleStyle} >{translate("common.variant")}</th>
                                <th style={columnTitleStyle} >{translate("common.genes")}</th>
                                <th style={columnTitleStyle} >{translate("common.diagnostic")}</th>
                                <th style={columnTitleStyle} >{translate("common.decision")}</th>
                                <th style={columnTitleStyle} >{translate("common.classification")}</th>
                                <th style={columnTitleStyle} >{translate("common.sample")}</th>
                                <th style={columnTitleStyle} >{translate("common.reportedBy")}</th>
                                <th style={columnTitleStyle} >{translate("common.actions")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}

interface TableRowI {
    actualRow: any,
    variantsSelecteds: any[],
    handleVariantsSelecteds?: any,
    handleReload: any,
    history: any,
    handleEdit?(variantId: string): void,
    handleDelete?(variantId: string): void,
}

function TableRow(props: TableRowI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const ReportS = new ReportService();

    const getDecisionLabel = (decision: string) => {
        let type = "normal";
        decision = decision.length > 0 ? (decision[0].toUpperCase() + decision.substring(1)) : "-"
        switch (decision.trim().toLowerCase()) {
            case "reported": type = "bold"; break;
            case "discarded": type = "italic"; break;
        }
        return (
            <label style={{
                fontWeight: type === "bold" ? "bold" : undefined,
                fontStyle: type === "italic" ? "italic" : undefined,
            }}>
                {decision}
            </label>
        )
    }
    const handleRowClick = () => {
        props.history.push(`/variants/${props.actualRow.id}`);
    }
    const variantColumn = (
        <td onClick={handleRowClick} >
            <LinkENSEMBL chromosome={props.actualRow.chromosome}
                genomeRef={props.actualRow.genomeReference}
                position={props.actualRow.position}
                fontSize={"12px"} />
            <CopyToClipboard valueToCopy={`${props.actualRow.chromosome}:${props.actualRow.position}`} /> <br />
            <strong style={{ fontSize: "11px" }}>
                {props.actualRow.reference} {" > "} {props.actualRow.alternative}
            </strong>
            <CopyToClipboard valueToCopy={`${props.actualRow.reference} > ${props.actualRow.alternative}`} /> <br />
            {/* <hr style={{ margin: "3px 0" }} /> */}
            <label style={{ fontSize: "11px" }}>
                {
                    props.actualRow?.codon && <>
                        {props.actualRow.codon}
                        <CopyToClipboard valueToCopy={props.actualRow.codon} /> <br />
                    </>
                }
                {
                    props.actualRow?.reportedVariant?.achange && <>
                        {props.actualRow.reportedVariant.achange}
                        <CopyToClipboard valueToCopy={props.actualRow.reportedVariant.achange} /> <br />
                    </>
                }
            </label>
        </td>
    );
    const genColumn = (
        <td onClick={handleRowClick}>
            <strong>
                {props.actualRow.reportedVariant.genename}
            </strong>
            <CopyToClipboard valueToCopy={props.actualRow.reportedVariant.genename} />
            <br />
            {(props.actualRow.vcfId ?? "").trim().length > 2 ?
                <NcbiDbsnpLinksByVcfId vcfIds={props.actualRow.vcfId ?? ""} copyToClipboard={true} /> :
                " - "
            } <br />
        </td>
    );
    const diagnosticColumn = (
        <td onClick={handleRowClick} style={{ wordBreak: "break-word"}}>
            <Urlify text={props.actualRow.diagnostic ?? ""} />
        </td>

    );
    const decisionColumn = (
        <td onClick={handleRowClick}>
            {getDecisionLabel(props.actualRow.decision)}
        </td>

    );
    const classificationColumn = (
        <td onClick={handleRowClick}>
            <BadgeVariantVeredict labelInput={props.actualRow.classification}
                id={0}
                size={"big"}
                fontSize={11}
            /> <br />
            <BadgeVariantImpact impact={props.actualRow.reportedVariant.impact}
                fontSize={"11px"}
            /> <br />
            {(props.actualRow?.reportedVariant?.effect ?? "").replaceAll("_", " ")}
        </td>

    );
    const sampleColumn = (
        <td onClick={handleRowClick}>
            <label style={{ fontWeight: "bold", marginBottom: 0 }}>
                {(props.actualRow?.studyName ?? " - ")}
            </label>
            <br />
            {
                props.actualRow?.samples && props.actualRow?.samples.trim() !== "" ?
                    <>
                        {
                            props.actualRow?.samples.trim().split(",").map((x: any, i: number) => {
                                return (
                                    <Badge key={i} variant="secondary" style={{ fontSize: "11px", margin: "1px 2px" }}>
                                        {x}
                                    </Badge>
                                )
                            })
                        }
                        < br />
                    </>
                    : <></>
            }

            <BadgeZygosity fontSize={"12px"}
                type={((props.actualRow?.zygosity ?? "").toLowerCase().trim().replaceAll("_", "").replaceAll(" ", ""))} />
            <br />
        </td>
    );
    const reportedByColumn = (
        <td onClick={handleRowClick}>
            <strong >
                {(props.actualRow?.institution ?? " - ")}<br />
            </strong>
            <hr style={{ margin: "2px 0" }} />
            {props.actualRow?.email ?
                <div style={{ display: "flex", alignItems: "center", fontSize: "10px" }}>

                    <FontAwesomeIcon icon={faEnvelope} style={{ border: "none", marginRight: "5px", fontSize: "10px" }} />
                    <a href={`mailto:${props.actualRow?.email}`} style={{}}>
                        {(props.actualRow?.email)}
                    </a>
                    <CopyToClipboard valueToCopy={props.actualRow?.email} /> <br />

                </div>
                : " - "}
            {props.actualRow?.contact ?
                <>

                    <FontAwesomeIcon icon={faUser} style={{ border: "none", marginRight: "5px", fontSize: "10px" }} />
                    {props.actualRow?.contact}
                </>
                : " - "}
            <br />

            <span style={{ fontSize: "10px", marginTop: "11px" }}>
                {(props.actualRow?.simpleDateFormatCreated ?
                    <>
                        <FontAwesomeIcon icon={faCalendarDay} style={{ border: "none", marginRight: "5px" }} />
                        {props.actualRow?.simpleDateFormatCreated}
                    </>
                    : " - ")}

            </span>
        </td>
    );
    const getDropdownActions = () => {
        const handleDelete = () => {
            if (props?.actualRow?.id && props?.handleDelete)
                props.handleDelete(props?.actualRow?.id);
        }
        const handleEdit = () => {
            if (props?.actualRow?.id && props?.handleEdit)
                props.handleEdit(props?.actualRow?.id);
        }
        return (
            <Dropdown alignRight={true} >
                <div style={{ display: "flex", placeContent: "center" }}>
                    <Tooltip label={translate('variant.reportedVariantsActions')}>
                        <Dropdown.Toggle variant="outline-light" id="dropdown-basic"
                            style={{
                                padding: "6px ​20px",
                                boxShadow: "none",
                                border: "none",
                                borderRadius: "50px",
                                fontSize: "15px",
                                marginRight: "5px"
                            }}>
                            <FontAwesomeIcon icon={faCog}
                                style={{ border: "none", color: "gray" }}
                                data-tip="Add case"
                            />
                        </Dropdown.Toggle>
                    </Tooltip>
                </div>
                <Dropdown.Menu style={{ minWidth: "170px", borderRadius: "0", overflow: "visible" }} >
                    <Dropdown.Item style={{ borderRadius: "0" }} variant="secondary" onClick={handleEdit}>
                        <Row>
                            <Col sm={3} style={{ padding: "0 0 0 3px" }}>
                                <FontAwesomeIcon icon={faEdit} style={{ border: "none", color: "gray" }} />
                            </Col>
                            <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                {translate('home.table.edit')}
                            </Col>
                        </Row>
                    </Dropdown.Item>
                    <Dropdown.Item style={{ minWidth: "100px", borderRadius: "0" }} variant="secondary" onClick={handleDelete}>
                        <Row>
                            <Col sm={3} style={{ padding: "0 0 0 3px" }}>
                                <FontAwesomeIcon icon={faTrash} style={{ border: "none", color: "gray" }} />
                            </Col>
                            <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                {translate('home.delete')}
                            </Col>
                        </Row>
                    </Dropdown.Item>
                    <Dropdown.Item style={{ minWidth: "100px", borderRadius: "0" }}
                        variant="secondary"
                        href={ReportS.get_downloadSelectedVariantInfoXls_link([props?.actualRow?.id])}
                        target="_blank">
                        <Row>
                            <Col sm={3} style={{ padding: "0 0 0 3px" }}>
                                <FontAwesomeIcon icon={faUpload} style={{ border: "none", color: "gray" }} />
                            </Col>
                            <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                {translate('common.export')}
                            </Col>
                        </Row>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown >)
    }

    return (
        <tr style={{
            borderBottom: "1px solid lightgray",
            fontSize: "12px",
            color: "dimgray",
            cursor: "pointer"
        }}>
            <td style={{ border: "none", width: "35px", verticalAlign: "middle", textAlign: "center" }}>
                <Form.Check type="checkbox"
                    // value={props?.variantsSelecteds?.find((y: any) => y === props.actualRow.id)}
                    checked={props?.variantsSelecteds?.find((y: any) => `${y}` === `${props.actualRow.id}`) ?? false}
                    onChange={() => {
                        props.handleVariantsSelecteds(`${props.actualRow.id}`)
                    }}
                />
            </td>
            {variantColumn}
            {genColumn}
            {diagnosticColumn}
            {decisionColumn}
            {classificationColumn}
            {sampleColumn}
            {reportedByColumn}
            <td style={{ border: "none", verticalAlign: "middle", width: "60px" }}>
                {getDropdownActions()}
            </td>
        </tr>
    )
}

