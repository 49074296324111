import React from 'react';
import { useContext } from 'react';
import { LangContext } from "../../../Contexts/Lang";
import { Tooltip } from '../../Common/Tooltip/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import BadgeTypeSubtype from '../../Common/Badges/BadgeSampleTypeSubtype';
import { Button } from "react-bootstrap";

interface QueryTitleI {
    history: any,
    study: any,
    subtitle?: string,
    refreshOnGoBack?: boolean,
    goToCasesDetail?: boolean
}

export default function QueryTitle(props: QueryTitleI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const handleGoBack = () => {
        if (props.goToCasesDetail) props.history.push(`/cases/caseId/${props.study.id}/`);
        else props.history.goBack();
    }
    return (
        <div id="QueryTitle" style={{ display: "flex" }}>
            <Tooltip label={
                props.goToCasesDetail ?
                    translate("casesQuery.goToCasesDetail") :
                    translate("common.goToPreviousPage")
            } placement={"bottom"}>
                <h4 style={{
                    cursor: "pointer",
                    minWidth: "max-content",
                    marginBottom: 0,
                    fontSize: "22px"
                }} onClick={() => { handleGoBack() }}>
                    <Button variant="outline-ligth"
                    >
                        <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "15px" }} />
                    </Button>
                    {props.study?.name}
                </h4>
            </Tooltip>
            <div style={{ minWidth: "max-content", marginLeft: "10px" }}>
                <label style={{ fontSize: "10px", color: "gray" }}>
                    {props.study?.code ? ` - ${props.study?.code}` : " - "}<br />
                    <BadgeTypeSubtype type={props.study?.type ?? "simple"}
                        subtype={props.study?.subtype?.toLowerCase() ?? "wes"}
                        fontSize="9px"
                        style={{ "marginRight": 0, "verticalAlign": "middle" }}
                    />
                </label>
            </div>
            {
                props.subtitle &&
                <div style={{ marginLeft: "20px" }}>
                    <h6 style={{ minWidth: "max-content", marginBottom: 0 }}>
                        {props.subtitle}
                    </h6>
                </div>
            }
        </div>)
}