import React from 'react';
import { useContext, useEffect } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import { LangContext } from '../../../Contexts/Lang';
import Loader from '../../Common/Loader/Loader';
import { LinkENSEMBL, LinkOMIM, LinkUniprot } from '../../Common/Links/Links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

interface DetailPanelGenesTableI {
    panelGenes: any[],
    panelGenesLoading: any,
    genesSelecteds: any,
    handleGenesSelecteds?: any,
    handleDeleteGen(genId: string[]): void,
}
export default function DetailPanelGenesTable(props: DetailPanelGenesTableI) {

    const { dispatch: { translate } } = useContext(LangContext);
    useEffect(() => { }, [props])
    const rows = props.panelGenes.map((x: any, i: number) =>
        <TableRow key={i}
            actualRow={x}
            genesSelecteds={props.genesSelecteds}
            handleGenesSelecteds={props.handleGenesSelecteds}
            handleDeleteGen={props.handleDeleteGen}
        />
    )
    const columnTitleStyle = { padding: 4, fontSize: 14 }
    return (
        <>
            {props.panelGenesLoading ?
                <Loader onContainer={true} type={'DNA'} /> :
                <div style={{}}>
                    <Table responsive="md" className="selectTable" style={{ marginBottom: "-1px", fontSize: "12px" }}
                    >
                        <thead>
                            <tr style={{ color: "dimgray", "backgroundColor": "#FAFAFA", margin: 0, padding: "5px" }}>
                                <th style={columnTitleStyle} ></th>
                                <th style={{ ...columnTitleStyle, minWidth: "135px" }}>{translate("genesPanel.approvedSymbol")}</th>
                                <th style={{ ...columnTitleStyle, minWidth: "110px" }}>{translate("common.previoussymbols")}</th>
                                <th style={columnTitleStyle}>{translate("common.synonyms")}</th>
                                <th style={{ ...columnTitleStyle, minWidth: "65px" }}>OMIM</th>
                                <th style={{ ...columnTitleStyle, minWidth: "70px" }}>Uniprot</th>
                                <th style={{ ...columnTitleStyle, minWidth: "140px" }}>Ensembl</th>
                                <th style={columnTitleStyle}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}

interface TableRowI {
    actualRow: any,
    genesSelecteds: any[],
    handleGenesSelecteds?: any,
    handleDeleteGen(genId: string[]): void,
}

function TableRow(props: TableRowI) {
    const sysPanel = props.actualRow.systemPanel;

    const columnStyles = { border: "none", verticalAlign: "middle" }

    return (
        <tr style={{
            borderBottom: "1px solid lightgray",
        }}
        >
            <td style={{ border: "none", width: "35px", verticalAlign: "middle", textAlign: "center" }}>
                <Form.Check type="checkbox"
                    checked={!sysPanel && (props?.genesSelecteds?.find((y: any) => `${y}` === `${props.actualRow.name}`) ?? false)}
                    disabled={sysPanel}
                    onChange={() => {
                        props.handleGenesSelecteds(props.actualRow.name)
                    }}
                />

            </td>
            <td style={columnStyles}>
                {props.actualRow.approvedsymbol ?? " - "}
            </td>
            <td style={columnStyles}>
                {props.actualRow.previoussymbols ?? " - "}
            </td>
            <td style={columnStyles}>
                {props.actualRow.synonyms ?? " - "}
            </td>
            <td style={columnStyles}>
                <LinkOMIM omimId={props.actualRow.omimid} />
            </td>
            <td style={columnStyles}>
                <LinkUniprot uniprotId={props.actualRow.uniprotid} />
            </td>
            <td style={columnStyles}>
                <LinkENSEMBL transcriptEnst={props.actualRow.ensemblid} />
            </td>
            <td style={columnStyles}>
                <Button variant="outline-light"
                    style={{ border: "none", borderRadius: "50px", padding: "5px 7px 2px" }}
                    onClick={() => { props.handleDeleteGen([props.actualRow.name]) }}
                >
                    <FontAwesomeIcon icon={faTrash} style={{ border: "none", color: "gray", fontSize: "13px" }} />
                </Button>
            </td>
        </tr>
    )
}

