import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import CoverageByGeneBarLayer from './CoverageByGeneBarLayer';

interface CoverGeneRowI {
    geneName: string,
    coverage: string,
    cov20XTargets: string,
    cov20XExons: string
}
interface CoverageByGeneBarI {
    chartData: CoverGeneRowI[]
    handleSelect(i: string): void,
    coverageFilteredData: any,

    // chartData: {
    //     geneName: string,
    //     coverage: string,
    //     cov20XTargets: string,
    //     cov20XExons: string
    // }[]
}

const CoverageByGeneBar = (props: CoverageByGeneBarI) => {
    const data = props.chartData.map((x: CoverGeneRowI, i: number) => { return { ...x, index: i } });
    function Rectangle20XTargets(i: { bars: any[], xScale(i: any): any, yScale(i: any): any }) {
        return (
            <>
                {i.bars.map((bar: any, index: number) => {
                    return (
                        <rect x={i.xScale(bar.data.index)}
                            y={i.yScale(+bar.data.data.cov20XTargets)}
                            width={bar.width}
                            key={index}
                            height={5}
                            fill="rgba(244,117,96,0.7)"
                        />
                    )
                })}
            </>
        );
    };
    function Rectangle20XExons(i: { bars: any[], xScale(i: any): any, yScale(i: any): any }) {
        return (
            <>
                {i.bars.map((bar: any, index: number) => {
                    return (
                        <rect x={i.xScale(bar.data.index)}
                            y={i.yScale(+bar.data.data.cov20XExons)}
                            width={bar.width}
                            height={10}
                            fill={"rgba(92, 135, 135, 0.7)"}
                            key={index}
                        />
                    )
                })}
            </>
        );
    };
    const findGeneNameByIndex = (index: string): string => {
        return (data.find((x: any) => x.index === index) ?? { geneName: "" }).geneName
    }
    const labelsWidth = 100
    return (
        <div style={{ height: "150px", width: "100%", display: "flex" }}>
            <div style={{ width: `calc(100vw - ${labelsWidth}px)` }}>
                <ResponsiveBar
                    data={data}
                    keys={['coverage',]}
                    indexBy="index"
                    // onClick={(a: any) => { props.handleSelect(a.data.geneName); }}
                    margin={{ top: 15, right: 10, bottom: 50, left: 30 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={{ scheme: 'set2' }}
                    // defs={[                ]}
                    fill={[]}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                5
                            ]
                        ]
                    }}
                    enableLabel={false}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        renderTick: (aa: any) => {
                            let geneName = findGeneNameByIndex(aa.tickIndex);
                            return (
                                <g transform={`translate(${aa.x},${aa.y})`}>
                                    <line x1="0" x2="0" y1="0" y2="5" style={{ stroke: "rgb(119, 119, 119)", strokeWidth: 1 }}></line>
                                    <text
                                        alignmentBaseline={"central"}
                                        textAnchor={"end"}
                                        transform={"translate(0,10) rotate(-30)"}
                                        style={{ fontSize: "8px" }}>
                                        {geneName}
                                    </text>
                                </g>
                            )
                        }
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    role="application"
                    ariaLabel="Nivo bar chart demo"
                    barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in geneName: " + findGeneNameByIndex(`${e.indexValue}`) }}
                    layers={
                        [
                            "grid",
                            "axes",
                            "bars",
                            (i: any) => CoverageByGeneBarLayer(i, props.handleSelect, props.coverageFilteredData.map((x: any) => x.genename)),
                            Rectangle20XExons,
                            Rectangle20XTargets,
                        ]}
                />
            </div>
            <div style={{ width: labelsWidth, fontSize: 10 }}>
                <br />
                <label style={{ marginBottom: 0 }}>
                    <span style={getDotReferenceS("rgba(244,117,96,0.7)", "10px")} />
                    % Targets 20X
                </label>
                <label>
                    <span style={getDotReferenceS("rgba(92, 135, 135, 0.7)", "10px")} />
                    % Exons 20X
                </label >
            </div >
        </div >
    )

}

function getDotReferenceS(color: string, size: string) {
    return {
        height: size,
        width: size,
        backgroundColor: color,
        borderRadius: 0,
        display: "inline-block",
        marginRight: "5px"
    }
}


export default CoverageByGeneBar


// Compose chart --> https://www.dtreelabs.com/blog/composable-charts-using-nivo-in-react
// Compose chart --> https://codesandbox.io/s/kkqmx1rw17
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar


// https://codesandbox.io/s/nivo-bar-extra-layers-forked-mv2zxo?file=/src/index.js

// CUSTOM LABELS CON ESO!!!!!
// https://codesandbox.io/s/nifty-morning-qdpb50?file=/src/SliceLayer.js:0-1558
