import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';

export default class HealthCheckService extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "healthcheck/";
    }

    async getCheck(): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}healthcheck`
        return await axios.post(url, {})
    }

    check(res: any): boolean {
        if (res.status === 200 &&
            res.data["ActiveMQ Connection"] === "Open" &&
            res.data["Hbase Connection"] === "Open" &&
            // res.data["Hbase dead server"] === "0" &&
            +res.data["Hbase region server"] > 0 &&
            res.data["MySql connection"] === "Open" &&
            res.data["Redis Connection"] === "Open")
            return true;
        return false;
    }
}