import React from 'react';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { LangContext } from "../../Contexts/Lang";
import UserContext from "../../Contexts/User/UserContext";
import UserService from "../../Services/UserServices";
import Loader from "../Common/Loader/Loader";
import Paginator from "../Common/Paginator/Paginator";
import HomeFilter, { InputFilterI } from "../Layout/Home/HomeFilter";
import HomeLayout from "../Layout/Home/HomeLayout";
import HomeToolkit from "../Layout/Home/HomeToolkit";
import ProcessUser, { EditT } from "./ProcessUser/ProcessUser";
import UsersTable from "./UsersTable";

// type sortColumnType = "" | "name"
interface UsersI {
    history: any,
}
function Users(props: UsersI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { users, filters, selecteds, dispatch: {
        getUsers, setFilters, cleanFilters, handleSelecteds, handleSelectAll } } = useContext(UserContext);
    const UserS = new UserService();

    const [processUser, setprocessUser] = useState<{
        "open": boolean,
        "editT": EditT,
        "id": number | undefined
    }>({ open: false, id: undefined, editT: "user" })
    const handleCloseProcessUser = () => setprocessUser({ open: false, id: undefined, editT: "user" });
    const handleShowProcessUser = (editT: EditT, id?: number) => { setprocessUser({ open: true, editT: editT, id: id ? id : undefined }) };

    const paginator = Paginator({
        reloadCall: () => { },
        onChangePage: (i: any) => {
            handleGetUsers(i + 1);
            paginator.setActualPage(i)
        },
        onItemsPerPageChange: (i: any) => {
            handleGetUsers(1, i);
            paginator.pageSize_set(i)
        }
    })

    function handleGetUsers(page?: number, pageSize?: number, filtersClean?: boolean, then?: any): void {
        getUsers(
            filtersClean ? undefined : filters,
            page ?? paginator.actualPage + 1,
            pageSize ?? paginator.itemsPerPage)
            .then(() => {
                if (page !== undefined) paginator.setActualPage(page - 1)
                handleSelecteds("")
                if (then) then()
            })
    }
    useEffect(() => {
        let isMounted = true;
        if (isMounted && !users.loaded && !users.loading) handleGetUsers()
        if (isMounted && users.loaded) {
            paginator.pageSize_set(users.data?.rowsCount ?? 0)
            paginator.setActualPage(users.data?.pageNumber - 1 ?? 0)
        }
        if (isMounted && users.refresh) handleGetUsers(1, paginator.itemsPerPage, true)
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])


    function handleSortByFilter(value: string) {
        if (value !== filters.sortColumn) {
            setFilters("sortColumn", value)
            handleGetUsers(1);
        }
    }

    const inputFilterData: InputFilterI[] = [
        {
            type: "text",
            stateName: "username",
            placeholder: `${translate('common.username')}...`,
        },
        {
            type: "text",
            stateName: "fullname",
            placeholder: translate('user.fullName'),
        },
    ]

    return (
        <>
            <ProcessUser history={props.history}
                show={processUser.open}
                editT={processUser.editT}
                handleClose={handleCloseProcessUser}
                handleReload={() => { handleGetUsers(1) }}
                editUserId={processUser.id ? `${processUser.id}` : undefined}
                createUser={processUser.id ? false : true}
            />
            <HomeLayout history={props.history}
                filterTitle={translate('user.users')}
                rowsAmount={{
                    loading: !users.loaded || users.loading,
                    amount: users?.data?.rowsCount ?? 0,
                }}
                Filters={
                    <div style={{ width: "100%" }}>
                        <HomeFilter
                            filters={filters}
                            // reload={handleReload}
                            setFilter={setFilters}
                            handleCleanFilter={() => {
                                cleanFilters();
                                handleGetUsers(1, paginator.itemsPerPage, true);
                            }}
                            callFilter={() => {
                                handleGetUsers(1);
                            }}
                            inputFilters={inputFilterData}
                        />
                    </div>
                }
                FiltersEmpty={
                    <div style={{ width: "100%" }}>
                        <HomeFilter inputFilters={inputFilterData} />
                    </div>
                }
                Toolkit={
                    <HomeToolkit rowData={(users?.data?.results ?? [])}
                        rowDataSelected={selecteds}
                        handleSelectAll={handleSelectAll}
                        multipleActionsOptions={[
                            {
                                icon: faTrash,
                                label: translate('home.delete'),
                                handleClick: () => {
                                    if (users?.data?.results.length > 0) {
                                        UserS.deleteUser(`${selecteds[0]}`).then(() => {//Este servicio falta ser terminado desde el backend
                                            handleGetUsers(1);
                                        })
                                    }
                                },
                                modalWarning: {
                                    label: translate("user.confirmDeleteAllSelectedUsers"),
                                    buttonLabel: translate('home.delete')
                                }
                            },
                        ]}
                        sortBy={{
                            value: filters.sortColumn,
                            options: "default",
                            handleSortBy: handleSortByFilter
                        }}
                        addElement={{
                            tooltipLabel: translate('user.addUsers'),
                            handleCreate: () => { handleShowProcessUser("user") },
                        }}

                    />
                }
                Table={
                    <Card style={{ borderRadius: "0px" }}>
                        <Card.Body style={{ padding: "0px" }}>
                            {!users.loaded || users.loading ?
                                <Loader onContainer={true} type={'DNA'} /> :
                                (users?.data?.results && users?.data?.results.length !== 0) ?
                                    <UsersTable users={users?.data?.results}
                                        handleShowProcessUser={handleShowProcessUser}
                                        handleReload={() => handleGetUsers(1)}
                                        history={props.history}
                                        usersSelecteds={selecteds}
                                        handleUsersSelecteds={handleSelecteds}
                                    />
                                    :
                                    <div>
                                        <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                            {translate('home.noCasesFound')}
                                        </p>
                                    </div>
                            }
                        </Card.Body>
                        {(users?.data?.results ?? []).length > 0 ?
                            <Card.Footer>
                                {paginator.component}
                            </Card.Footer>
                            : <></>}
                    </Card>
                }
            />
        </>

    )
}



export { Users }