import React from 'react';
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Popover } from "../../../Common/Popover";

interface DetailQueryTableRowI {
    id: string,
    label: string | JSX.Element,
    value: string | JSX.Element,
    info?: {
        title?: string,
        body: string
    }
}
interface DetailQueryTableI {
    title?: string | JSX.Element,
    titleStyle?: any,
    tableStyle?: any,
    data?: DetailQueryTableRowI[],
    id?: string,
    striped?: boolean,
    maxLabelColumnWidth?: string,
}
function DetailQueryTable(props: DetailQueryTableI) {
    const rows = (props.data ?? []).map((x: any, i: number) => {

        return (
            <tr key={i} style={{ wordBreak: "break-word", fontSize: "12px" }}>
                <td style={{ color: "dimgray", wordWrap: "break-word", maxWidth: props.maxLabelColumnWidth, minWidth: 95 }}>
                    {x.label}
                </td>
                <td style={{}}>
                    {x.value}
                    {x.info &&
                        <Popover title={x.info.title}
                            content={x.info.body}
                            trigger={"hover"}
                            style={{ fontSize: "10px" }}>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "13px" }} />
                            {/* <label style={{ marginBottom: 0 }}>
                            </label> */}
                        </Popover>
                    }
                </td>
            </tr>

        )
    })
    return (
        <>
            {props.title &&
                <div style={{
                    color: "darkslategray",
                    backgroundColor: "#FAFAFA",
                    margin: 0,
                    padding: "5px",
                    fontSize: "17px",
                    fontWeight: "lighter",
                    border: "solid 1px #DEE2E6",
                    ...(props.titleStyle ?? {})
                }}>
                    {props.title}
                </div>
            }
            <Table id={props.id} responsive hover striped={props.striped ?? true} style={{ border: "solid 1px #E4E4E4", ...(props.tableStyle ?? {}) }}>
                <tbody style={{ fontSize: "13px" }}>
                    {rows}
                </tbody>
            </Table>
        </>
    )
}

export default DetailQueryTable
export type { DetailQueryTableRowI, DetailQueryTableI }