import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';
import LangState from './Contexts/Lang';
import UserContextState from './Contexts/User/UserState';
import ThemeContextState from './Contexts/Theme/ThemeState';


ReactDOM.render(
  <ThemeContextState>
    <UserContextState>
      <LangState>
        <Routes />
      </LangState>
    </UserContextState>
  </ThemeContextState>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
