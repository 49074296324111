import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Layout } from '../../Components/Layout/Layout';
import DomainsContextState from '../../Contexts/Domains/DomainsState';
import Domains from '../../Components/Domains/Domains';
import DetailDomain from '../../Components/Domains/Detail.tsx/DetailDomain';
import UserContext from '../../Contexts/User/UserContext';
import { Redirect } from 'react-router-dom';
import UserService from '../../Services/UserServices';

interface DomainsPageI {
    history: any,
    match?: any,
}
export default function DomainsPage(props: DomainsPageI) {
    const [body, setbody] = useState<any>(<></>)
    const { user } = useContext(UserContext);
    const UserS = new UserService();
    const cuRoll: any = user.data?.rolestoString ?? ""
    const usRoll: any = UserS.user?.rolestoString ?? ""

    useEffect(() => {
        if (usRoll !== "ROLE_SYSTEM" ||
            (cuRoll !== "" && (cuRoll !== UserS.user?.rolestoString ?? "-")))
            setbody(<Redirect to='/' />)
        else {
            const domainId = props.match?.params?.domainId;
            if (domainId) setBodyDetails(domainId)
            else setBodyDomains()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, user])

    function setBodyDetails(domainId: number) {
        setbody(
            <DetailDomain history={props.history}
                domainId={domainId} />
        )
    }
    function setBodyDomains() {
        setbody(
            <Domains history={props.history} />
        )
    }

    return (
        <DomainsContextState>
            <Layout history={props.history}>
                {body}
            </Layout>
        </DomainsContextState>

    )
}
