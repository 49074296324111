import React from 'react';
import { LinkENSEMBL } from 'src/Components/Common/Links/Links';
import MoreInfoPopoverButton from 'src/Components/Common/MoreInfoPopoverButton';
interface TrascriptComponentI {
    transcript: String
}

export default function TrascriptComponent(props: TrascriptComponentI) {
    const transcript = props.transcript;
    if (transcript && transcript.length <= 1) return <>&nbsp;-&nbsp;</>
    const transcriptSplited = transcript.split(",");
    const transcriptSplitedComponents = transcriptSplited.map((xx: string, i: number) => {
        return <span key={i}>
            <LinkENSEMBL transcriptEnst={xx.trim()} />
            {i !== transcriptSplited.length - 1 ? ", " : ""}
        </span>
    })
    if (transcriptSplitedComponents.length > 5)
        return (
            <>
                {transcriptSplitedComponents.filter((x: JSX.Element, i: number) => i <= 3)}...&nbsp;&nbsp;&nbsp;&nbsp;
                <MoreInfoPopoverButton trigger={"click"} buttonStyles={{ float: "right" }}>
                    <div>
                        {transcriptSplitedComponents}
                    </div>
                </MoreInfoPopoverButton >

                <></>
            </>
        )
    else
        return (<>{transcriptSplitedComponents}</>)
}