import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { Card } from 'react-bootstrap';
import Paginator from '../../Common/Paginator/Paginator';
import { LangContext } from '../../../Contexts/Lang';
import HomeLayout from '../../Layout/Home/HomeLayout';
// import HomeToolkit from '../Layout/Home/HomeToolkit';
import HomeFilter, { InputFilterI } from '../../Layout/Home/HomeFilter';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import HomeToolkit from '../../Layout/Home/HomeToolkit';
import DomainsContext, { FiltersCasesDomainReqI, FiltersUserDomainReqI } from '../../../Contexts/Domains/DomainsContext';
import Loader from '../../Common/Loader/Loader';
import UsersTable from '../../Users/UsersTable';
import ProcessUser, { EditT } from '../../Users/ProcessUser/ProcessUser';
import UserService from 'src/Services/UserServices';

interface DetailDomainUsersI {
    history: any,
    domainId: number
    filterTitleTabs: JSX.Element,
    handleGetDomain(
        page?: number, pageSize?: number, tabSelected?: "study" | "user", filtersDomain?: FiltersCasesDomainReqI, filtersUsers?: FiltersUserDomainReqI, then?: any
    ): void
}
export default function DetailDomainUsers(props: DetailDomainUsersI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { domain, filters_users, selectedsUsers, dispatch: {
        setFiltersUsers, cleanFiltersUsers, handleSelectedsUsers, handleSelectAllUsers } } = useContext(DomainsContext);

    const UserS = new UserService();

    const [processUser, setprocessUser] = useState<{
        "open": boolean,
        "editT": EditT,
        "id": number | undefined
    }>({ open: false, editT: "user", id: undefined })
    const handleCloseProcessUser = () => setprocessUser({ open: false, id: undefined, editT: "user" });
    const handleShowProcessUser = (editT: EditT, id?: number) => { setprocessUser({ open: true, editT: editT, id: id ? id : undefined }) };

    const paginator = Paginator({
        reloadCall: () => { },
        onChangePage: (i: any) => {
            paginator.setActualPage(i)
            props.handleGetDomain(i + 1, paginator.itemsPerPage, "user", undefined, filters_users)
        },
        onItemsPerPageChange: (i: any) => {
            paginator.setActualPage(0)
            props.handleGetDomain(1, i, "user", undefined, filters_users)
        }
    })

    useEffect(() => {
        paginator.pageSize_set(domain.data?.users?.rowsCount ?? 0)
        paginator.setActualPage((domain.data?.users?.pageNumber ?? 1) - 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain])

    function handleSortByFilter(value: string) {
        if (value !== filters_users.sortColumn) {
            handleSelectedsUsers("")
            setFiltersUsers("sortColumn", value)
            props.handleGetDomain(1, paginator.itemsPerPage, "user", undefined, { ...filters_users, sortColumn: value })
        }
    }

    //----------------------------Cases Filters--------------------------------------
    const inputFilterData: InputFilterI[] = [
        {
            type: "text",
            stateName: "fullnameSearch",
            placeholder: `${translate('common.username')}...`,
        },
        {
            type: "text",
            stateName: "usernameSearch",
            placeholder: translate('user.fullName'),
        },
    ]
    //----------------------------Cases Filters--------------------------------------
    return (
        <>
            <ProcessUser history={props.history}
                show={processUser.open}
                editT={processUser.editT}
                handleClose={handleCloseProcessUser}
                handleReload={() => {
                    props.handleGetDomain(1, paginator.itemsPerPage, "user", undefined, filters_users)
                    handleSelectedsUsers("")
                }}
                editUserId={processUser.id ? `${processUser.id}` : undefined}
                createUser={processUser.id ? false : true}
                domain={{
                    id: `${props.domainId}`,
                    name: domain.data?.domain?.name
                }}
            />
            <HomeLayout history={props.history}
                HLType="domainDetail"
                filterTitleCustom={props.filterTitleTabs}
                filtersBorder={true}
                rowsAmount={{
                    loading: !domain.loaded || domain.loading,
                    amount: domain?.data?.cases?.rowsCount ?? 0,
                }}
                Filters={
                    <div style={{ width: "100%" }}>
                        <HomeFilter HLType="domainDetail"
                            filters={filters_users}
                            setFilter={setFiltersUsers}
                            handleCleanFilter={() => {
                                cleanFiltersUsers();
                                props.handleGetDomain(1, paginator.itemsPerPage, "user")
                            }}
                            callFilter={() => {
                                props.handleGetDomain(1, paginator.itemsPerPage, "user", undefined, filters_users)
                            }}
                            inputFilters={inputFilterData}
                        />
                    </div>
                }
                FiltersEmpty={
                    <div style={{ width: "100%" }}>
                        <HomeFilter inputFilters={inputFilterData} HLType="domainDetail" />
                    </div>
                }
                Toolkit={
                    <HomeToolkit
                        rowData={(domain.data?.users?.results ?? [])}
                        rowDataSelected={selectedsUsers}
                        handleSelectAll={handleSelectAllUsers}
                        multipleActionsOptions={[
                            {
                                icon: faTrash,
                                label: translate('home.delete'),
                                handleClick: () => {
                                    UserS.deleteUser(`${selectedsUsers.join(",")}`).then(() => {
                                        handleSelectedsUsers("")
                                        props.handleGetDomain(1, paginator.itemsPerPage, "user");
                                    })
                                },
                                modalWarning: {
                                    label: translate('home.tableManager.confirmDeleteAllSelectedStudy'),
                                    buttonLabel: translate('home.delete')
                                }
                            },
                        ]}
                        addElement={{
                            tooltipLabel: translate('user.addUsers'),
                            handleCreate: () => { handleShowProcessUser("user") },
                        }}
                        sortBy={{
                            value: filters_users.sortColumn,
                            options: "default",
                            handleSortBy: handleSortByFilter
                        }}
                    />
                }
                Table={
                    <Card style={{ borderRadius: "0px" }}>
                        <Card.Body style={{ padding: "0px" }}>
                            {!domain.loaded || domain.loading ?
                                <Loader onContainer={true} type={'DNA'} /> :
                                (domain?.data?.users?.results && domain?.data?.users?.results.length !== 0) ?
                                    <UsersTable users={domain.data.users.results}
                                        usersSelecteds={selectedsUsers}
                                        handleUsersSelecteds={handleSelectedsUsers}
                                        handleShowProcessUser={handleShowProcessUser}
                                        handleReload={() => {
                                            props.handleGetDomain(1, paginator.itemsPerPage, "user", undefined, filters_users)
                                        }}
                                        history={props.history} />
                                    :
                                    <div>
                                        <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                            {translate('home.noCasesFound')}
                                        </p>
                                    </div>
                            }
                        </Card.Body>
                        {(domain?.data?.users?.results ?? []).length > 0 ?
                            <Card.Footer>
                                {paginator.component}
                            </Card.Footer>
                            : <></>}
                    </Card>
                }
            />
        </>
    )
}