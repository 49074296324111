import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { VariantsOrderByValueT } from '../utils/BioFunctionalData';
import { AbstractService } from './AbstractService';

export default class VariantQueryServices extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "queryVariant/";
    }

    async read_combos(caseId: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}combos`
        const config = {
            ...this.authHeader,
            params: {
                userId: this.user?.id,
                domainId: this.user?.domain,
                id: +caseId,
                resultId: "",
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }
    async read_variants(
        filter_input: read_variantsI,
        pageSize?: number,
        pageNumber?: number,
        sortDirection?: string,
        sortColumn?: VariantsOrderByValueT): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}queryVariants`
        const params = {
            domainId: this.user?.domain,
            ...filter_input,
            resultId: filter_input.resultId ?? -1, //Consultas predeterminadas o predefinidas! este valor (-1) es de las consultas avanzadas
            pageSize: pageSize ?? 25,
            pageNumber: pageNumber ?? 1,
            sortDirection: sortDirection ?? "ASC",
            sortColumn: sortColumn ?? "infoColumnData.INTERVAR_VEREDICT", // infoColumnData.INTERVAR_VEREDICT, infoColumnData.EFFECT
            _: (new Date()).getTime()
        }
        const config = { ...this.authHeader, params: params }
        return await axios.get(url, config)
    }
    async filter_variants_by_urlQueryString(
        urlQueryString: string,
        pageNumber?: number,
        pageSize?: number,
        sortDirection?: string,
        sortColumn?: VariantsOrderByValueT[]): Promise<AxiosResponse<any>> {
        const sortCol = sortColumn ? sortColumn.join(",") : "infoColumnData.INTERVAR_VEREDICT";
        let url = `${this.basePath}queryVariants?`
        url += `domainId=${this.user?.domain}`
        url += `&resultId=-1` //Consultas predeterminadas o predefinidas! este valor (-1) es de las consultas avanzadas
        url += `&pageSize=${pageSize ?? 25}`
        url += `&pageNumber=${pageNumber ?? 1}`
        url += `&sortDirection=${sortDirection ?? "ASC"}`
        url += `&sortColumn=${sortCol}` // infoColumnData.INTERVAR_VEREDICT, infoColumnData.EFFECT
        url += `&_=${(new Date()).getTime()}`
        url += `&${urlQueryString}`
        // console.log(url)
        return await axios.get(url, this.authHeader)
    }
    async read_filters(caseId: number) {
        // // Request URL: http://10.100.100.55/bioflux-platform-gaip-facade/queryVariant/filters?
        // userId=51&
        // domainId=26&
        // id=687&
        // _=1643017632494
        const url = `${this.basePath}filters`
        const config = {
            ...this.authHeader,
            params: {
                userId: this.user?.id,
                domainId: this.user?.domain,
                id: caseId,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }
}

interface read_variantsI {
    studyId: number,
    resultId?: number,
    pageSize?: number
    pageNumber?: number
    sortDirection?: "ASC" | "DESC",
    //---------------------variants acordion-----------------------------
    rs?: string,
    comment?: string[],
    "comment[]_exclude"?: string,
    variants?: string,
    zygosity?: string,     //string as boolean, ex:       zygosity = true &                                  
    chromosome?: string,    //ex:       chromosome = 19: 45 &                              
    vartype?: string[],     //ex:       vartype[] = SNP &  vartype[] = MNP & vartype[] = INS &                                  
    maa?: "" | "isNull" | "isNotNull",       //ex:       maa = isNotNull &
    //---------------------QC acordion-----------------------------
    filter?: string,
    readdepth?: number,
    readdepth_exclude?: string,
    ratiodepthgeno?: number,
    quality?: number,
    qual?: number,
    //---------------------Frequency acordion-----------------------------
    exacfrequency?: number,
    exacfrequency_exclude?: string
    gnomaddGenomefrequency?: number,
    gnomaddGenomefrequency_exclude?: string
    frequency?: number,
    frequency_exclude?: string,
    //---------------------Predictors acordion-----------------------------
    mcap?: number,
    dann?: number,
    mutationtaster?: string[],
    sift?: string[],
    polyphen?: string[]
    //---------------------Genes acordion-----------------------------
    geneName?: string,
    panels?: string[],
    "panels[]_exclude"?: string,
    //---------------------Phenotype acordion-----------------------------
    hpoId?: string,
    //---------------------Phenotype acordion-----------------------------
    groups: number[],
    "groups[]_exclude": string,
    //---------------------Consecuence acordion-----------------------------
    impact: string[],
    effect: string[],
    "effect[]_exclude": string,
    //---------------------Consecuence acordion-----------------------------
    intervarVeredict: string[],
    "intervarVeredict[]_exclude": string,
    bayesVeredict: string[],
    "bayesVeredict[]_exclude": string,
    bayesscore: number,
    clnsig: number[],
    "clnsig[]_exclude": string,
    unknown: string
}

export type { read_variantsI }