import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { Card, Button, Accordion, Table, Row } from 'react-bootstrap';
import { faAngleUp, faAngleDown, faInfoCircle, faSearch, faSearchPlus, faEdit, faTrash, faComment } from '@fortawesome/free-solid-svg-icons';
import { LangContext } from "../../../Contexts/Lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../../Common/Tooltip/Tooltip";
import ProcesVariantGroups from "./Statistics/ProcessVariantGroups"
import { ModalWarning } from "../../Common/ModalWarning";
import GroupServices from "../../../Services/GroupService";
import ModalPredeterminedQueries from "./ModalPredeterminedQueries";
import ThemeContext from "../../../Contexts/Theme/ThemeContext";
import { getSampleId_NameTagAffSex } from "src/utils/BioHelpers";
interface PanelRightI {
    history: any,
    caseId: any,
    case: any,
    variantGroups?: any,
    reloadCall: any,
    // handleShowPredeterminedQueries(data: any): void
}

export default function PanelRight(props: PanelRightI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const [openAccordion, setopenAccordion] = useState<string | undefined>("0")

    const GroupS = new GroupServices();

    const [warningDelete, setWarningDelete] = useState<{ open: boolean, groupId: number | undefined }>({ open: false, groupId: undefined });

    const processVariantGroupsInit = {
        open: false,
        id: undefined,
        studyId: props?.case?.id,
        variantsIds: undefined,
        groupName: undefined,
        groupDescription: undefined
    }
    const [processVariantGroup, setprocessVariantGroup] = useState<{
        "open": boolean,
        "id": number | undefined,
        "studyId": number | undefined,
        "variantsIds": string | undefined,
        "groupName": string | undefined,
        "groupDescription": string | undefined
    }>(processVariantGroupsInit)
    const handleCloseprocessVariantGroup = () => setprocessVariantGroup(processVariantGroupsInit);
    const handleShowprocessVariantGroup = (
        id?: number,
        variantsIds?: string,
        groupName?: string,
        groupDescription?: string
    ) =>
        setprocessVariantGroup({
            open: true,
            id: id ?? undefined,
            studyId: props.case.id ?? undefined,
            variantsIds: variantsIds ?? undefined,
            groupName: groupName ?? undefined,
            groupDescription: groupDescription ?? undefined
        });

    const [showPredeterminedQueries, setshowPredeterminedQueries] = useState(false)
    const handleShowPredeterminedQueries = () => setshowPredeterminedQueries(true);
    const handleClosePredeterminedQueries = () => setshowPredeterminedQueries(false);



    function handleOpenAcordion(eventKey: string) {
        if (openAccordion === eventKey)
            setopenAccordion(undefined)
        else
            setopenAccordion(eventKey)
    }
    function getAcordionToogle(eventKey: string, label: string, tooltipLabel?: string) {
        const getIcon = (icon: any) => <FontAwesomeIcon icon={icon} style={{ color: "#aaa", float: "left", marginRight: "5px" }} />
        return (
            <Accordion.Toggle
                as={Card.Header}
                eventKey={eventKey}
                style={{ cursor: "pointer" }}
                onClick={() => { handleOpenAcordion(eventKey) }}>
                <h5 style={{ color: theme.grayHeader, fontSize: "17px", padding: 0, margin: 0 }}>
                    {openAccordion === eventKey ? getIcon(faAngleUp) : getIcon(faAngleDown)}
                    {label}
                    {tooltipLabel ?
                        <Tooltip label={tooltipLabel}>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "17px" }} />
                        </Tooltip>
                        : <></>
                    }
                </h5>
            </Accordion.Toggle>
        )
    }
    function getAcordionItem(eventKey: string, label: string, tooltipLabel: string, body: any) {
        return (
            <Card>
                {getAcordionToogle(eventKey, label, tooltipLabel !== "" ? tooltipLabel : undefined)}
                <Accordion.Collapse eventKey={eventKey}>
                    <Card.Body>
                        {body}
                    </Card.Body>
                </Accordion.Collapse>
            </Card >
        )
    }

    function getButton(children: any, onClick?: any, styles?: any) {
        styles = styles ?? {}
        return (
            <Button onClick={onClick ? onClick : undefined}
                style={{ borderRadius: "5px", fontSize: "10px", minHeight: "50px", textAlign: "left", marginTop: "5px", textTransform: "uppercase", ...styles }}
                variant="secondary"
                block>
                {children}
            </Button>
        )
    }
    function handleDeleteVariantGroup() {
        if (warningDelete.groupId)
            GroupS.delete(props.case.id, warningDelete.groupId)
        props.reloadCall()
    }

    function getVariantGroups() {
        const getBaseLink = () => {
            let link = `/cases/query/caseId/${props.case.id}/`
            props.case.samples.forEach((x: any, i: number) => {
                link += `sample_${x.id}=${getSampleId_NameTagAffSex(x.sample.name, x.tag, x.afectado, x.sample.sexo)}&`
            });
            return link
        }
        const rows = (props.variantGroups ?? []).map((x: any, i: number) => {
            const date = new Date(x.dateCreated).toISOString().replaceAll("-", "/").replace("T", " ").split(".")[0];
            return (
                <tr key={i} style={{ fontSize: "12px" }}>
                    <td>
                        <div style={{ display: "flex" }}>
                            <Tooltip label={x.description} placement={"top"}>
                                <a href={`${getBaseLink()}groups[]=${x.id}&studyId=${props.case.id}`}>
                                    {`${x.name} (${x.variantsCount})`}
                                </a>
                            </Tooltip>
                        </div>
                        <p style={{ fontSize: "11px", color: "gray", padding: "0", margin: "0" }}>
                            {date}
                        </p>
                    </td>
                    <td style={{}}>
                        <Row style={{ float: "right" }}>
                            <Tooltip label={translate('casesDetail.editGroup')} placement={"bottom"}>
                                <Button variant="light"
                                    style={{
                                        border: "none", display: "flex", height: "28px", width: "30px", borderRadius: "50px", justifyContent: "center", alignItems: "center", marginLeft: "5px"
                                    }}
                                    onClick={() => handleShowprocessVariantGroup(
                                        x.id,
                                        x.variantIds,
                                        x.name,
                                        x.description
                                    )}
                                >
                                    <FontAwesomeIcon icon={faEdit} style={{ color: "gray", fontSize: "13px" }} />
                                </Button>
                            </Tooltip>
                            <Tooltip label={translate('casesDetail.deleteGroup')} placement={"bottom"}>
                                <Button variant="light"
                                    onClick={() => { setWarningDelete({ open: true, groupId: x.id }) }}
                                    style={{
                                        border: "none", display: "flex", height: "28px", width: "30px", borderRadius: "50px", justifyContent: "center", alignItems: "center", marginLeft: "5px"
                                    }}>
                                    <FontAwesomeIcon icon={faTrash} style={{ color: "gray", fontSize: "13px" }} />
                                </Button>
                            </Tooltip>
                        </Row>
                    </td>
                </tr>
            )
        })
        return <Table>
            <tbody>
                {rows}
            </tbody>
        </Table>
    }


    return (
        <>
            {showPredeterminedQueries &&
                <ModalPredeterminedQueries show={showPredeterminedQueries}
                    handleClose={handleClosePredeterminedQueries}
                    caseId={props.caseId}
                    history={props.history} />}
            <ModalWarning labelTitle={translate('home.tableManager.confirm')}
                label={translate('casesDetail.confirmDeleteVariantGroup')}
                labelButton={translate('home.delete')}
                showState={warningDelete.open}
                handleClose={() => setWarningDelete({ ...warningDelete, open: false })}
                onClick={handleDeleteVariantGroup}
            />
            {processVariantGroup.open &&
                <ProcesVariantGroups show={processVariantGroup.open}
                    handleClose={handleCloseprocessVariantGroup}
                    groupId={processVariantGroup.id}
                    studyId={processVariantGroup.studyId}
                    variantsIds={processVariantGroup.variantsIds}
                    groupName={processVariantGroup.groupName}
                    groupDescription={processVariantGroup.groupDescription}
                    handleReload={() => props.reloadCall()}
                />}
            <Accordion defaultActiveKey={openAccordion} style={{ width: "100%" }}>
                {getAcordionItem("0", translate('casesDetail.queries'), "",
                    <>
                        <label style={{ float: "right", color: "gray", fontSize: "10px", marginTop: "-8px", marginRight: "5px" }}>
                            {translate("common.sequenceVariants")}&nbsp;
                            <Tooltip label={translate('casesDetail.queryInfo')}>
                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "15px" }} />
                            </Tooltip>
                        </label>
                        <Link to={`/cases/query/caseId/${props?.case?.id}/`} style={{ textDecoration: "none", color: theme.backgroundPrimary }}>
                            {getButton(
                                <>
                                    {translate('casesDetail.advancedQueries')}
                                    <FontAwesomeIcon icon={faSearchPlus} style={{ color: theme.backgroundPrimary, float: "right", fontSize: "15px" }} />
                                </>,
                                () => { },
                                ({ backgroundColor: theme.grayHeader, marginTop: "0" }))
                            }
                        </Link>
                        {getButton(
                            <>
                                {translate('casesDetail.predeterminedQueries')}
                                <FontAwesomeIcon icon={faSearch} style={{ color: theme.backgroundPrimary, float: "right", fontSize: "15px" }} />
                            </>,
                            () => handleShowPredeterminedQueries(),
                            ({ backgroundColor: "darkgray", border: "none" })
                        )}
                        {
                            (props?.case?.type ?? "").toLowerCase() === "simple" &&
                            <Link to={`/cases/comments/${props?.case?.id}`} style={{ textDecoration: "none", color: theme.backgroundPrimary }}>
                                {getButton(
                                    <>
                                        {translate('commentedVariants.commentedVariants')}
                                        <FontAwesomeIcon icon={faComment} style={{ color: theme.backgroundPrimary, float: "right", fontSize: "15px" }} />
                                    </>,
                                    () => { },
                                    ({ backgroundColor: "darkgray", border: "none" })
                                )}
                            </Link>
                        }
                        {props?.case?.hasCnv &&
                            <>
                                <hr style={{ margin: "10px -20px" }} />
                                <label style={{ float: "right", color: "gray", fontSize: "10px", marginRight: "5px" }}>
                                    Copy Number Variants&nbsp;
                                    <Tooltip label={translate('casesDetail.cnvTooltip')}>
                                        <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "15px" }} />
                                    </Tooltip>
                                </label>
                                <Link to={`/cases/cnv/${props?.case?.id}`} style={{ textDecoration: "none", color: theme.backgroundPrimary }}>
                                    {getButton(
                                        <>
                                            {translate('casesDetail.cnvList')}
                                            <FontAwesomeIcon icon={faSearch} style={{ color: theme.backgroundPrimary, float: "right", fontSize: "15px" }} />
                                        </>,
                                        () => { },
                                        ({ backgroundColor: theme.grayHeader, border: "none" })
                                    )}
                                </Link>
                            </>
                        }
                    </>)
                }
                {props.case?.subtype?.trim().toLowerCase() === "array" &&
                    getAcordionItem("1", "BTest", translate('casesDetail.btestQueries'),
                        <>
                            {getButton(
                                <>
                                    {translate('casesDetail.carrier')}
                                </>
                            )}
                            {getButton(
                                <>
                                    {translate('casesDetail.pharmacogenomics')}
                                </>
                            )}
                            {getButton(
                                <>
                                    {translate('casesDetail.risk')}
                                </>
                            )}
                        </>)
                }
                {getAcordionItem("2", translate('common.coverage'), translate('casesDetail.groupStoredVariants'),
                    <>
                        <Link to={`/cases/coverage/gene/caseId/${props?.case?.id}/`} style={{ textDecoration: "none", color: theme.backgroundPrimary }}>
                            {getButton(
                                <>
                                    {translate('common.genes')}
                                </>,
                                () => { },
                                ({ marginTop: "5px", border: "none" }))
                            }
                        </Link>
                        <Link to={`/cases/coverage/variant/caseId/${props?.case?.id}/`} style={{ textDecoration: "none", color: theme.backgroundPrimary }}>
                            {getButton(
                                <>
                                    {translate('common.variantsInClinvar')}&nbsp;P/LP
                                </>,
                                () => { },
                                ({ marginTop: "5px", border: "none" }))
                            }
                        </Link>

                    </>)
                }
                {getAcordionItem("3", translate('common.variantGroups'), translate('casesDetail.groupStoredVariants'),
                    <>
                        <Card.Text style={{ fontSize: "12px" }}>
                            <strong>
                                {translate('casesDetail.savedResults')} : {(props.variantGroups ?? []).length}
                            </strong>
                        </Card.Text>
                        {
                            (props.variantGroups ?? []).length === 0 ?
                                <>
                                    <br />
                                    <p style={{ color: "gray", textAlignLast: "center", fontSize: "12px" }}>
                                        {translate('casesDetail.noResultsWereSaved')}
                                    </p>
                                </>
                                :
                                getVariantGroups()
                        }
                    </>)
                }
                {/* {getAcordionItem("3", translate('casesDetail.reports'), "TODO: Reports description",
                    <>
                        <Card.Text style={{ fontSize: "12px" }}>
                            <strong>
                                Reports goes here
                            </strong>
                        </Card.Text>

                    </>)
                }
                {getAcordionItem("4", "Tags", "TODO: Tags description",
                    <>
                        <Card.Text style={{ fontSize: "12px" }}>
                            <strong>
                                TAGS manager goes here
                            </strong>
                        </Card.Text>

                    </>)
                } */}
            </Accordion>
        </>
    )
}