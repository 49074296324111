import React from 'react';
import { useContext } from 'react';
import { LangContext } from "../../Contexts/Lang";
import { Button, Modal } from 'react-bootstrap';
import IgvBody from './IgvBody';
import { SampleIgvDataI } from './types';

interface ModalCnvIgvI extends SampleIgvDataI {
    show: boolean,
    handleClose: any,
}
function ModalCnvIgv(props: ModalCnvIgvI) {
    // const getSampleSelected = () => {
    //     if (props.igvData && props.igvData?.alignments && props.igvData?.alignments.length > 0) {
    //         let sampleSelected = props.igvData.alignments[0].sampleName;
    //         if (props.igvData?.sampleName)
    //             sampleSelected = props.igvData?.sampleName
    //         return { sampleSelected: sampleSelected, alignmentType: props.igvData.view ?? "variant" }
    //     }
    //     return undefined;
    // }
    // let sampleSelectedInit = getSampleSelected()
    let sampleIgvData = props.igvData

    return (
        <Modal id="ModalCnvIgv1" dialogClassName="my-modal-85"
            style={{ maxWidth: "none", width: "100" }}
            show={props.show} onHide={props.handleClose}>
            <ModalHeader />
            {
                !props.igvData ?
                    <></> :
                    <Modal.Body style={{ padding: 0 }}>
                        <IgvBody type={"cnv"}
                            caseId={props.caseId}
                            gen={props.gen ?? ""}
                            chromosome={props.chromosome}
                            position={props.position}
                            assembly={props.igvData.assembly}
                            library={props.igvData?.library}
                            viewT={props.igvData.view}
                            sampleNameSelected={sampleIgvData?.sampleNameSelected}
                            mutation={props.igvData.alignments}
                        />
                    </Modal.Body >
            }
            <ModalFooter handleClose={props.handleClose} />
        </Modal>
    )

}


function ModalHeader(props: any) {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: "#444B59" }}>
            <Modal.Title style={{ margin: "0px 10px", color: "#FFF", fontSize: "17px" }}>
                {translate(`casesQuery.sampleInformation`)}
            </Modal.Title>
        </Modal.Header>
    )
}
function ModalFooter(props: { handleClose(): void }) {
    return (
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.handleClose}>
                Close
            </Button>
        </Modal.Footer>
    )
}


export { ModalCnvIgv }
export type { ModalCnvIgvI }
