import React, { useContext } from 'react';
import LabelPopover from '../LabelPopover';
import { LangContext } from 'src/Contexts/Lang';
import { Row } from 'react-bootstrap';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';


//--------------Levels------------------------------------------
const therapeuticLevels = ["1", "2", "3", "4", "R1", "R2"]
const diagnosticLevels = ["Dx1", "Dx2", "Dx3"]
const prognosticLevels = ["Px1", "Px2", "Px3"]
const evidenceLevels = [...therapeuticLevels, ...diagnosticLevels, ...prognosticLevels]
interface EvidenceIconI {
	level: string,
	size?: "small" | "big"
}
function EvidenceIcon(props: EvidenceIconI) {
	const level = props.level;
	const size = props.size ?? "small";
	const colorsSet = ["#30A248", "#1679B6", "#984E9E", "#434343", "#EF3A26", "#F69A92"]
	const therapeuticLevelsColors: { [name: string]: string } = {
		"1": colorsSet[0], "2": colorsSet[1], "3": colorsSet[2], "4": colorsSet[3], "R1": colorsSet[4], "R2": colorsSet[5]
	}
	const diagnosticLevelsColors: { [name: string]: string } = { "Dx1": colorsSet[0], "Dx2": colorsSet[1], "Dx3": colorsSet[2] }
	const prognosticLevelsColors: { [name: string]: string } = { "Px1": colorsSet[0], "Px2": colorsSet[1], "Px3": colorsSet[2] }
	const evidenceColors: { [name: string]: string } = { ...therapeuticLevelsColors, ...diagnosticLevelsColors, ...prognosticLevelsColors }

	const width = size === "small" ? 17 : 40
	const fontSize = size === "small" ? 9 : 12
	const color = evidenceColors[level]

	const getCircle = () => {
		return (
			<div style={{ display: "flex" }}>
				<div style={{
					width: width,
					height: width,
					borderRadius: width,
					backgroundColor: color,
					alignItems: "center"
				}}>
					<span style={{
						width: width,
						fontSize: size === "big" ? 16 : fontSize,
						color: "white",
						display: "flex",
						justifyContent: "center",
						marginTop: size === "big" ? 10 : 1
					}}><strong>{level}</strong></span>
				</div>
			</div>)

	}
	const getSquare = () => {
		return (
			<div style={{ display: "flex" }}>
				<div style={{
					width: width - 1,
					height: width - 1,
					backgroundColor: color,
					borderRadius: "2px", /* Adjust width/2 here */
					display: "flex",
					alignItems: "center"
				}}>
					<span style={{
						width: width,
						fontSize: fontSize,
						color: "white",
						display: "flex",
						justifyContent: "center",
						marginTop: 1

					}}><strong>{size === "big" ? level : level.toLowerCase().replace("dx", "")}</strong></span>
				</div>
			</div>
		)
	}
	const getTriangle = () => {
		return (
			<div style={{ display: "flex" }}>
				<div style={{
					width: width,
					height: 0,
					borderLeft: `${width / 2}px solid transparent`, /* Adjust width/2 here */
					borderRight: `${width / 2}px solid transparent`,
					borderBottom: `${width}px solid ${color}`,
				}}>
				</div>
				<span style={{
					width: width - 2,
					fontSize: fontSize,
					color: "white",
					marginLeft: size === "small" ? -16 : -38,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginTop: size === "small" ? 4 : 18
				}}><strong>{size === "big" ? level : level.toLowerCase().replace("px", "")}</strong></span>
			</div>)
	}
	return therapeuticLevels.includes(level) ? getCircle() : diagnosticLevels.includes(level) ? getSquare() : getTriangle()
}

interface EvidenceIconPopupI {
	level: string,
	size?: "small" | "big",
	style?: any
}
function EvidenceIconPopup(props: EvidenceIconPopupI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { theme } = useContext(ThemeContext);
	const levelString = therapeuticLevels.includes(props.level) ?
		translate("common.evidenceLevels.therapeuticLevel") :
		diagnosticLevels.includes(props.level) ?
			translate("common.evidenceLevels.diagnosticLevel") :
			translate("common.evidenceLevels.prognosticLevel");
	return (
		<LabelPopover popoverTitle={
			<Row style={{ margin: 0, padding: 0 }}>
				<EvidenceIcon level={props.level} size='big' />&nbsp;&nbsp;&nbsp;
				<div style={{
					display: "flex",
					color: theme.grayHeader,
					alignItems: "center",
					fontSize: 17
				}}>{levelString}</div>
			</Row>}
			trigger={'hover'}
			popoverBody={`${translate(`common.evidenceLevels.${props.level}`)}`}
			style={props.style ? { ...props.style } : {}}
			label={<EvidenceIcon level={props.level} />} />
	)
}

export { EvidenceIcon, EvidenceIconPopup, evidenceLevels }

