import React from 'react';
import { faInfoCircle, faCheck, faTimes, faBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Table } from "react-bootstrap";
import { Urlify } from "src/Helpers/Helpers";
import { LangContext } from "../../../Contexts/Lang";
import { checkValueAndReturnAnother } from "../../../utils/helpers";
import BadgeSample from "../../Common/Badges/BadgeSample";
import BadgeVariantImpact from "../../Common/Badges/BadgeVariantImpact";
import { BadgeZygosity } from "../../Common/Badges/BadgeZygosity";
import CopyToClipboard from "../../Common/CopyToClipboard";
import { LinkENSEMBL, NcbiDbsnpLinksByVcfId } from "../../Common/Links/Links";
import { Popover } from "../../Common/Popover";
import BadgeVariantVeredict from 'src/Components/Common/Badges/BadgeVariantVeredict';

interface DetailTableRowI {
    id: string,
    label: string | JSX.Element,
    value: string | JSX.Element,
    info?: {
        title?: string,
        body: string
    }
}
interface DetailTableI {
    title?: string | JSX.Element,
    data?: DetailTableRowI[]
}
function DetailTable(props: DetailTableI) {
    const rows = (props.data ?? []).map((x: any, i: number) => {

        return (
            <tr key={i} >
                <td style={{ color: "dimgray", wordWrap: "break-word" }}>
                    {x.label}
                </td>
                <td style={{wordBreak: "break-all"}}>
                    {x.value}
                    {x.info &&
                        <Popover title={x.info.title}
                            content={x.info.body}
                            trigger={"hover"}
                            style={{ fontSize: "10px" }}>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "lightgray", float: "right", fontSize: "13px" }} />
                            {/* <label style={{ marginBottom: 0 }}>
                            </label> */}
                        </Popover>
                    }
                </td>
            </tr>

        )
    })
    return (
        <Table responsive striped style={{ border: "solid 1px #E4E4E4" }}>
            {props.title &&
                <thead>
                    <tr style={{ color: "darkslategray", "backgroundColor": "#FAFAFA", margin: 0, padding: "5px", fontSize: "16px" }}>
                        <th style={{ fontWeight: "lighter", width: "150px", wordWrap: "break-word" }} >
                            {props.title}
                        </th>
                        <th style={{}} ></th>
                    </tr>
                </thead>
            }
            <tbody style={{ fontSize: "13px" }}>
                {rows}
            </tbody>
        </Table>
    )
}
interface DetailTableVariantI {
    vcfId?: string,
    chromosome?: string,
    genomeReference?: string,
    position?: number,
    genename?: string,
    reference?: string,
    alternative?: string,
    impact?: string,
    effect?: string,
    codon?: string,
    classification?: string,
}
function DetailTableVariant(props: DetailTableVariantI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const getVariantTableData = (): DetailTableRowI[] => {
        const getRsIds = () => {
            if ((props.vcfId ?? "").trim().length < 2) return " - "
            return (<>
                <NcbiDbsnpLinksByVcfId vcfIds={props.vcfId ?? ""} copyToClipboard={true} />
            </>)
        }
        let dataTableVariant: DetailTableRowI[] = [
            {
                id: "chposition",
                label: translate("variantDetail.chromosomePosition"),
                value: checkValueAndReturnAnother(props.chromosome && props.position,
                    <>
                        <LinkENSEMBL genomeRef={props.genomeReference} chromosome={props.chromosome} position={props.position} fontSize={"13px"} />
                        <CopyToClipboard valueToCopy={`${props.chromosome}: ${props.position}`} />
                    </>
                )
            },
            {
                id: "gene",
                label: translate("common.gene"),
                value: <strong style={{ color: "dimgray" }}>
                    {props.genename ?? "-"}
                </strong>
            },
            {
                id: "change",
                label: translate("common.change"),
                value: <>
                    <strong style={{ color: "dimgray" }}>
                        {`${props.reference ?? " - "} -> ${props.alternative ?? " - "}`}
                    </strong>
                    <CopyToClipboard valueToCopy={`${props.reference ?? " - "} > ${props.alternative ?? " - "}`} />
                    <br />
                    <span>
                        {`${props.codon ?? " - "}`}
                    </span>
                    <CopyToClipboard valueToCopy={`${props.codon ?? " - "} > ${props.alternative ?? " - "}`} />
                </>
            },
            {
                id: "rsid",
                label: "RSID",
                value: getRsIds()
            },
            {
                id: "impact",
                label: `${translate("common.impact")} / ${translate("common.effect")}`,
                value: <>
                    {props.impact ?
                        <BadgeVariantImpact impact={props.impact} />
                        : "-"}<br />
                    {(props.effect ?? "-").replaceAll("_", " ")}
                </>
            },
            {
                id: "acmg",
                label: "ACMG",
                value: <>
                    {props.classification &&
                        <BadgeVariantVeredict labelInput={props.classification}
                            id={0}
                            size={"big"}
                        />}
                </>
            }
        ]
        // if (reportedVariant?.achange) {
        //     dataTableVariant = dataTableVariant.filter((x: any) => x.id !== "ensemblgene" && x.id !== "enembltranscript")
        // } else {
        //     dataTableVariant = dataTableVariant.filter((x: any) => x.id !== "ensembl")
        // }
        return dataTableVariant;
    }
    const dataTableVariant = getVariantTableData()
    return (
        <DetailTable title={translate("common.variant")}
            data={dataTableVariant} />
    )
}
interface DetailTableReportI {
    decision?: string,
    diagnostic?: string,
    confirmation?: string,
    genomeReference?: string,
    library?: string,
    lineage?: string,
    observation?: string,
}
function DetailTableReport(props: DetailTableReportI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const getReportedTableData = (): DetailTableRowI[] => {
        let reportedTableData: DetailTableRowI[] = [
            {
                id: "decision",
                label: translate("common.decision"),
                value: props.decision ?? "-"
            },
            {
                id: "diagnostic",
                label: translate("common.diagnostic"),
                value: props.diagnostic ?? "-"
            },
            {
                id: "confirm",
                label: translate("common.confirm"),
                value: props.confirmation ?
                    <FontAwesomeIcon icon={faCheck} style={{ border: "none", fontSize: "12px" }} /> :
                    <FontAwesomeIcon icon={faTimes} style={{ border: "none", fontSize: "12px" }} />
            },
            {
                id: "referenceGenome",
                label: translate("common.referenceGenome"),
                value: props.genomeReference ?? "-"
            },
            {
                id: "library",
                label: translate("common.library"),
                value: props.library ?? "-"
            },
            {
                id: "lineage",
                label: translate("variant.lineage"),
                value: props.lineage ?? "-"
            },
            {
                id: "observation",
                label: translate("common.observation"),
                value: <span style={{ wordBreak: "break-all" }}>
                    <Urlify text={props.observation ?? "-"} />
                </span>
            },
        ]
        return reportedTableData;
    }
    const reportedTableData = getReportedTableData();
    return (
        <DetailTable data={reportedTableData}
            title={
                <span>
                    &nbsp;
                    <FontAwesomeIcon icon={faBookmark}
                        style={{ border: "none", color: "gray" }} />
                    &nbsp;
                    {translate("common.report")}
                </span>}
        />
    )
}
interface DetailTableStudyCaseI {
    studyName?: string,
    samples?: any[],
    zygosity?: string,
}
function DetailTableStudyCase(props: DetailTableStudyCaseI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const getCaseSampleTableData = (): DetailTableRowI[] => {
        let caseSampleTableData: DetailTableRowI[] = [
            {
                id: "sample",
                label: translate("common.case"),
                value: <>
                    {props?.studyName}
                </>
            },
            {
                id: "case",
                label: translate("common.samples"),
                value: <>
                    {(props?.samples ?? []).map((x: any, i: number) => {

                        return (<><BadgeSample key={i}
                            name={x.sample.name}
                            afected={x.afectado}
                            fontSize="12px" />
                            &nbsp;
                        </>
                        )
                    })}
                </>
            },
            {
                id: "zygosity",
                label: translate("common.zygosity"),
                value: <>
                    {props?.zygosity &&
                        <BadgeZygosity type={props?.zygosity.trim().replaceAll("_", "").toLowerCase()} />
                    }
                </>,
            }
        ]
        return caseSampleTableData;
    }
    const caseSampleTableData = getCaseSampleTableData();
    return (
        <DetailTable title={translate("variant.studyCase")}
            data={caseSampleTableData}
        />
    )
}

export { DetailTable, DetailTableVariant, DetailTableReport, DetailTableStudyCase }
export type { DetailTableRowI, DetailTableI }