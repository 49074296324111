import React from 'react';
import { useContext } from "react";
import { Badge } from "react-bootstrap";
import { LangContext } from "src/Contexts/Lang";
import ThemeContext from "../../../Contexts/Theme/ThemeContext";
import { Tooltip } from "../Tooltip/Tooltip";
import { PredictorsVeredictI, PredictorsAllT, VeredictT, predictorsVeredictMap } from 'src/Components/Cases/Query/Detail/Predictors/Predictors';

interface BadgeVeredictPredictorsI {
    type: PredictorsAllT,
    value: any
}
export default function BadgeVeredictPredictors(props: BadgeVeredictPredictorsI) {
    const { theme } = useContext(ThemeContext);
    const { dispatch: { translate } } = useContext(LangContext);
    if ((props.type === "bayesdel_addaf" || props.type === "revel_score") && isNaN(+props.value)) return <></>;
    if (props.type === undefined || props.value === undefined) return <></>
    const confT: { veredict: string | null, info: string } = getPredictorVeredict(translate, props.value, props.type);
    const color = confT.veredict === "B" ? theme.success : confT.veredict === "P" ? theme.error : "gray";
    const badge = (<Badge style={{ backgroundColor: color, color: "white", alignSelf: "center" }}>
        {confT.veredict}
    </Badge>)
    if ((confT.info ?? "").length > 2)
        return (
            <Tooltip label={confT.info}>
                {badge}
            </Tooltip>
        )
    return badge
}

function getPredictor(type: PredictorsAllT): PredictorsVeredictI | undefined {
    // return predictorsMap.get(type)?.veredict;
    return predictorsVeredictMap.get(type);
    // if (predictorFiltered.length === 0) return null
    // return predictorFiltered[0];
}

function getPredictorVeredict(translate: any, value: any, type: PredictorsAllT): { type: PredictorsAllT, veredict: VeredictT | null, info: string } {
    const predictor: PredictorsVeredictI | undefined = getPredictor(type);
    if (!predictor || (value !== 0 && !value)) return ({ type, veredict: null, info: "" })
    const intValue = +value;
    let veredict: VeredictT = "US";
    if (predictor.benign) {
        if (!Array.isArray(predictor.benign.value)) {
            // eslint-disable-next-line no-eval
            if (eval(`${intValue} ${predictor.benign.condition} ${predictor.benign.value}`)) {
                veredict = "B"
            }
        } else if (predictor.benign.value.indexOf(value.trim().toUpperCase()) > -1) veredict = "B"
    }
    if (predictor.pathogenic) {
        if (!Array.isArray(predictor.pathogenic.value)) {
            // eslint-disable-next-line no-eval
            if (eval(`${intValue} ${predictor.pathogenic.condition} ${predictor.pathogenic.value}`)) veredict = "P"
        } else if (predictor.pathogenic.value.indexOf(value.trim().toUpperCase()) > -1) veredict = "P"
    }
    const info = getPredictorFlag(translate, predictor.type)
    return ({ type, veredict, info })
}

function getPredictorFlag(translate: any, predictorType: PredictorsAllT): string {
    let info = ""
    const predictor: PredictorsVeredictI | undefined = getPredictor(predictorType);
    if (predictor && !Array.isArray(predictor?.pathogenic?.value)) {
        if (predictor.benign) info += `${translate("casesQuery.predictionFlags.isBenignIfScore")} ${predictor.benign.condition} ${predictor.benign.value}. `;
        if (predictor.pathogenic) info += `${translate("casesQuery.predictionFlags.isPathogenicIfScore")} ${predictor.pathogenic.condition} ${predictor.pathogenic.value}. `;
    }
    return info;
}

export { getPredictorVeredict, getPredictorFlag }
export type { VeredictT }
