import axios from 'axios';
import React from 'react';
import { Redirect } from 'react-router-dom'
import { encryptLocalStorage, encrypt } from '../utils';
import LoginService from './LoginService';
const LS = new LoginService();
// import { AbstractService, ActualUser } from './AbstractService';

axios.interceptors.response.use(function (res: any) {
    if (res.headers?.token && res.headers?.digest) {
        let us = encryptLocalStorage.getItem('_Buser');
        if (us) {
            if (!encrypt) us = JSON.parse(us)
            us.token = res.headers?.token
            us.digest = res.headers?.digest
            encryptLocalStorage.setItem('_Buser', encrypt ? us : JSON.stringify(us));
        }
    }

    if (res.status === 401) {
        console.log("401 - session end")
        cleanLocalStorageAndRedirectToLogin(res);
    }
    if (res.status === 403) {
        console.log("403 - forbiden")
        return <Redirect to='/' />
    }
    if (res.status === 404) {
        console.log("404 - not found")
        return <Redirect to='/404' />
    }
    if (res.status === 503) {
        console.log("503 - Service Temporarily Unavailable")
        return <Redirect to='/503' />
    }
    return res;
}, function (error) {
    console.log(error)
    if (error.toString().indexOf("Request failed with status code 401") > -1 ||
        error.message.trim().toLocaleLowerCase() === "network error" ||
        error?.response?.status === 401) {
        console.log("redirecting")
        if (error.config.url.indexOf("healthcheck") === -1) {
            console.log("error")
            console.log(error)
            cleanLocalStorageAndRedirectToLogin(error);
        }
        // else {
        //     cleanLocalStorageAndRedirectToLogin();
        // }
    }
    if (error.config.url.indexOf(process.env.REACT_APP_API_URL)) return Promise.reject(error)
    return Promise.reject(error);
});


const cleanLocalStorageAndRedirectToLogin = (res: any) => {
    if (res?.config?.url.indexOf('bioflux-platform-gaip-facade') > -1)
        if (LS.user && Object.keys(LS.user).length > 0)
            LS.logout()
                .then(() => window.location.href = "/login")
                .catch(() => {
                    // return setTimeout(() => {
                    localStorage.removeItem("_Buser")
                    return window.location.href = "/login"
                    // }, 1000);
                })
        else {
            // return setTimeout(() => {
            localStorage.removeItem("_Buser")
            return window.location.href = "/login"
            // }, 1000);
        }
}

export default axios;

