// Compose chart --> https://www.dtreelabs.com/blog/composable-charts-using-nivo-in-react
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar'
import React, { useContext } from 'react'
import { LangContext } from 'src/Contexts/Lang'
import ThemeContext from 'src/Contexts/Theme/ThemeContext'
import { OncotTreeDict } from 'src/utils/BioFunctionalData'
import CancerHotspotsToast from '../../Somatic/CancerHotspotsToast'



interface BarDataI {
	id: string,
	label: string,
	totalCount: number,
	count: number,
	color: string
}
type HotspotsT = "cancer" | "organ";
interface HotspotsBarI {
	data: string | BarDataI[],
	type: HotspotsT
}
export default function HotspotsBar(props: HotspotsBarI) {
	const { theme } = useContext(ThemeContext);
	const { dispatch: { translate } } = useContext(LangContext);
	const colorSet = props.type === "organ" ? theme.chart_nivo_nivo : theme.chart_nivo_set3;
	let data: BarDataI[] = (typeof props.data === "string") ? getBarDataI(props.data, props.type, colorSet, translate) : props.data;
	data = data.filter((x: BarDataI, i: number) => {
		if (data.length > 10) {
			return x.label !== "" && i < 10
		} else {
			return x.label !== ""
		}
	})
	const dataNames = data.map((x: BarDataI): string => {
		return x.label
	})
	const dataValues = data.reduce((acc, item) => {
		acc[item.label] = item.count;
		return acc;
	}, {} as Record<string, number>);
	const dataValuesTotalCount = data.reduce((acc, item) => {
		acc[item.label] = item.totalCount;
		return acc;
	}, {} as Record<string, number>);
	return (
		<div style={{ padding: 5 }}>
			<div style={{ height: "20px", width: "100%" }}>
				<ResponsiveBar
					data={[dataValues]}
					layout={"horizontal"}
					keys={dataNames}
					indexBy=""
					innerPadding={0}
					margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
					padding={0.1}
					valueScale={{ type: 'linear' }}
					indexScale={{ type: 'band', round: true }}
					colors={props.type === "cancer" ? theme.chart_nivo_set3 : theme.chart_nivo_nivo}
					axisTop={null}
					axisRight={null}
					axisBottom={null}
					axisLeft={null}
					tooltip={({ id, value, color }) => {
						let subtitle;
						let title;
						if (props.type === "cancer") {
							title = OncotTreeDict.getOncoTreeDictLabel(`${id}`.split(":")[0]);
							subtitle = `${id}`.split(":")[0]
						} else {
							title = `${id}`;
						}
						return <CancerHotspotsToast id={id}
							value={value}
							title={title}
							subtitle={subtitle}
							color={color}
							percentage={dataValuesTotalCount[id]} />
					}}
				/>
			</div>
		</div>
	)
}

const getBarDataI = (inputData: string, type: HotspotsT, themeSetColors: string[], translate: Function): BarDataI[] => {
	let data: BarDataI[];
	if (type === "organ") {
		data = inputData.split(",").map((x: string, i: number): BarDataI => {
			const colorIndex = i % themeSetColors.length
			const color = themeSetColors[colorIndex];
			const xsplitted = x.split(":")
			let data: BarDataI = { id: "", label: "", totalCount: 0, count: 0, color: "" }
			if (xsplitted.length === 3) {
				data = {
					id: xsplitted[0],
					label: translate(`casesQuery.hotspotsOrganTypesOptions.${xsplitted[0].toLowerCase()}`),
					totalCount: +xsplitted[1],
					count: +xsplitted[2],
					color: color
				}
			}
			return data
		})
	} else {
		data = inputData.split(",").map((x: string, i: number): BarDataI => {
			const xsplitted = x.split(":")
			let data: BarDataI = { id: "", label: "", totalCount: 0, count: 0, color: "" }
			if (xsplitted.length === 3) {
				let label = `${xsplitted[0].toUpperCase()} : ${OncotTreeDict.getOncoTreeDictLabel(xsplitted[0])}`
				if (label === "") return data;
				const colorIndex = i % themeSetColors.length
				data = {
					id: xsplitted[0].toUpperCase(),
					label: label,
					totalCount: +xsplitted[1],
					count: +xsplitted[2],
					color: themeSetColors[colorIndex]
				}
			}
			return data
		})
	}
	return data;
}

export { getBarDataI }
export type { BarDataI, HotspotsT }
