import React from 'react';
import { Form } from 'react-bootstrap';
import FormI from './FormI';
import './styles.css';
type FormInputType = "number" | "text" | "textarea" | "password" | "email"
interface FormInputI extends FormI {
    type?: FormInputType,
    placeHolder?: string,
    value?: string | number,
    onKeyDown?: any,
    borderRadius?: Boolean,
    labelBold?: Boolean,
    name?: string,
    labelColor?: string
    formControlStyle?: any,
    formGrouplStyle?: any,
    formLabelStyle?: any,
    textAreaRows?: number
}
export default function FormInput(props: FormInputI) {
    const onChange = props.onChange ?? (() => { })
    const type = props.type ?? "text"
    const required = props.required ?? false
    const requiredFeedback = props.requiredFeedback ?? ""
    const placeHolder = props.placeHolder ?? undefined;
    const value = props.value ?? "";
    const disabled = props.disabled ?? undefined;
    const feedbackValidator = props.feedbackValidator ?? false;
    const onKeyDown = props.onKeyDown ?? undefined;
    const borderRadius = props.borderRadius ?? undefined;
    const labelBold = props.labelBold ?? undefined;
    const labelColor = props.labelColor ?? undefined;
    const name = props.name ?? undefined;
    const formControlStyle = props.formControlStyle ?? {};
    const formGrouplStyle = props.formGrouplStyle ?? {};
    const formLabelStyle = props.formLabelStyle ?? {};
    const textAreaComponent = (
        <Form.Control as="textarea"
            value={value}
            name={name}
            required={required}
            onChange={onChange}
            placeholder={placeHolder}
            className="common_styles"
            disabled={disabled}
            isInvalid={feedbackValidator}
            rows={props.textAreaRows ?? 3} />
    )
    const inputComponent = (
        <Form.Control type={type}
            value={value}
            className="common_styles"
            style={{
                borderRadius: (borderRadius ? "50px" : undefined),
                ...formControlStyle
            }}
            name={name}
            required={required}
            onChange={onChange}
            placeholder={placeHolder}
            disabled={disabled}
            onKeyDown={onKeyDown}
            isInvalid={feedbackValidator}
            autoComplete="new-password"
        />
    )
    const component = type === "textarea" ? textAreaComponent : inputComponent
    return (
        <Form.Group style={{ marginBottom: 0, ...formGrouplStyle }}>
            {props.label && <Form.Label style={{
                display: required ? "flex" : undefined,
                color: labelColor ? labelColor : undefined,
                ...formLabelStyle
            }}>
                {labelBold ?
                    <strong>{props.label}</strong> :
                    props.label
                }
                {required && <span style={{ color: "#DD3D4D" }}>&nbsp;*</span>}
            </Form.Label>}
            {component}
            {requiredFeedback === "" ? <></> :
                <Form.Control.Feedback type="invalid">
                    {requiredFeedback}
                </Form.Control.Feedback>
            }
        </Form.Group>)
}

export type { FormInputType }