import React, { useLayoutEffect } from 'react';
import { faAngleLeft, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
import LinkTooltip from "src/Components/Common/Links/LinkTooltip";
import { Tooltip } from "src/Components/Common/Tooltip/Tooltip";
import HomeFilter, { InputFilterI } from "src/Components/Layout/Home/HomeFilter";
import HomeLayout from "src/Components/Layout/Home/HomeLayout"
import { LangContext } from "src/Contexts/Lang";
import VariantService from "../../../Services/VariantServices";
import { AsyncDataI } from "src/Contexts/interfaces";
import Loader from "src/Components/Common/Loader/Loader";
import StudyService from "src/Services/StudyServices";
import { AxiosResponse } from 'axios';
import CoverageByGenesResults from "./CoverageByGenes/CoverageByGenesResults";
import CoverageByVariantsResults from "./CoverageByVariantsResults";
import CoverageBodyToolkit from "./CoverageBodyToolkit";
import { compareToSort } from "src/utils/helpers";
import { useWindowSize } from 'src/Helpers/WindowsSizeHelper';
import GensetServices from 'src/Services/GensetService';


type CoverageBodyT = "gene" | "variant";
type OrderByT = "gene" | "coverage" | "onTarget" | "classification" | "zygosity"
interface OrderByOptionsI { label: string, value: OrderByT }
interface GenesCoverageI {
    genename: string,
    pct20xAllExons: string,
    pct20xAllTargets: string,
    pctgeneCoverage: string,
}
interface CoverageBodyI {
    history: any,
    match: any,
    caseId: number,
    type: CoverageBodyT
}
interface SearchI {
    search: string,
    panel: string
}
export default function CoverageBody(props: CoverageBodyI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const VS = new VariantService();
    const SS = new StudyService();
    const GS = new GensetServices();
    const { windowSize } = useWindowSize();
    const urlSearchParamName = "search="
    const urlPanelParamName = "panel="
    const orderByOptions: OrderByOptionsI[] = [
        { label: translate("common.zygosity"), value: "zygosity" },
        { label: translate("common.gene"), value: "gene" },
        { label: translate("common.coverage"), value: "coverage" },
        { label: "On target", value: "onTarget" },
        { label: translate("common.classification"), value: "classification" },
    ]
    const searchInitParams: { search: string, panel: string } = { search: "", panel: "" }
    let params = false;
    if (props?.match?.params?.query) {
        const query = props?.match?.params?.query;
        // console.log(query)
        if (query.length > 0) {
            const parameters = query.split("&");
            for (let parameter in parameters) {
                const param = parameters[parameter].toLowerCase();
                const indexOfSearch = param.indexOf(urlSearchParamName)
                if (indexOfSearch > -1)
                    searchInitParams.search = parameters[parameter].substring(indexOfSearch + urlSearchParamName.length, parameters[parameter].length)
                const indexOfPanel = param.indexOf(urlPanelParamName)
                if (indexOfPanel > -1)
                    searchInitParams.panel = parameters[parameter].substring(indexOfPanel + urlPanelParamName.length, parameters[parameter].length)
                params = true;
            }
        }
    }
    const searchInit: { search: string, panel: string } = { search: "", panel: "" }
    const [search, setsearch] = useState<SearchI>(params ? searchInitParams : searchInit);
    const [filter, setfilter] = useState<string>("");
    const [filterNotOnTarget, setfilterNotOnTarget] = useState<boolean>(false);
    const [filterNotCover, setfilterNotCover] = useState<boolean>(false);
    const [orderBy, setorderBy] = useState<OrderByOptionsI | undefined>(orderByOptions[0]);
    const [reloadOnFirstPage, setreloadOnFirstPage] = useState<boolean>(false);
    const asyncDataInit: AsyncDataI = { loaded: false, loading: false, data: null, error: false }
    const [studyReq, setstudyReq] = useState<AsyncDataI>(asyncDataInit);
    const [genepanels, setGenePanels] = useState<AsyncDataI>({ ...asyncDataInit, data: [] });
    const [coverageReq, setcoverageReq] = useState<AsyncDataI>(asyncDataInit);
    const coverageDataInit = { filteredData: {}, filteredDataToDownload: {} };
    const [coverageData, setcoverageData] = useState<{ filteredData: any, filteredDataToDownload: any }>(coverageDataInit);

    const handlefilterNotOnTarget = () => {
        setfilterNotOnTarget(!filterNotOnTarget)
    }
    const handlefilterNotCover = () => {
        setfilterNotCover(!filterNotCover)
    }
    const handleSetsearch = (fieldName: string, value: any) => {
        if (value.length > 3000) value = value.substring(0, 3000)
        let newF: any = search;
        newF[fieldName] = value.replaceAll("\n", " ");
        setsearch({ ...newF })
    }

    const cleanSearch = () => setsearch(searchInit)


    const handleSetOrderBy = (i?: OrderByOptionsI): void => {
        if (i && i.label && i.value) setorderBy(i)
        else setorderBy(undefined)
    }

    const launchGetCoverage = (input?: string, panel?: string) => {
        const callRequest = (): Promise<AxiosResponse<any>> => {
            setcoverageReq({ loading: true, loaded: false, data: null, error: false })
            if (props.type === "gene") {
                return VS.getGenesCoverage((studyReq.data?.study?.samplesNames ?? "").join(", "), `${props.caseId}`, input, panel ?? "",  1, 100000)
            } else {
                return VS.getClinvarCoverage(input ?? "", panel ?? "", (studyReq.data?.study?.samplesNames ?? "").join(", "), `${props.caseId}`)
            }
        }
        callRequest().then((res: AxiosResponse) => {
            if (res.status === 200) {
                // console.log(res.data)
                // console.log(res.data.P2268)
                // res.data.P2268[0].gt = "1/1";
                // res.data.P2268[1].gt = "0/1";
                // res.data.P2268[2].gt = "0/0";
                // res.data.P2268[2].gt = "./.";
                setcoverageReq({ loading: false, loaded: true, data: res.data, error: false })
            } else {
                setcoverageReq({ loading: false, loaded: false, data: null, error: res })
            }
        })
            .catch((e: any) => {
                setcoverageReq({ loading: false, loaded: false, data: null, error: e })
            })
    }

    const launchGetStudy = (): void => {
        setstudyReq({ loading: true, loaded: false, data: null, error: false })
        SS.read_case(`${props.caseId}`)
            .then((res: any) => {
                if (res.status === 200) {
                    setstudyReq({ loading: false, loaded: true, data: res.data, error: false })
                }
            })
            .catch((e: any) => {
                setstudyReq({ loading: false, loaded: false, data: null, error: e })
            })
    }


    const launchGetPanels = (): void => {
        // console.log("Paneles")
        setGenePanels({ loaded: false, loading: true, data: null, error: false })
        GS.search().then((res: any) => {
            if (res.status === 200) {
                let genesPanel = []
                if (res.data?.results) {
                    genesPanel = res.data?.results.map((x: any, i: number) => {
                        const description = x.description.length > 100 ? `${x.description.substr(0, 90)}...` : x.description
                        return {
                            label: `${x.name} (${x.totalGenes}) : ${description}`,
                            value: `${x.key}:${x.regionKey}:${x.name}`
                        }
                    })

                    setGenePanels({ loaded: true, loading: false, data: genesPanel, error: false })
                }
            }
        }).catch((e: any) => {
            setGenePanels({ loaded: false, loading: false, data: null, error: e })
            console.log(e)
        })
    }

    const handleCallFilter = () => {
        if (search?.search.length > 2) {
            props.history.push(`${params ? "&" : ""}search=${search?.search}`)
        }
        if (search?.panel) {
            props.history.push(`${params ? "&" : ""}panel=${search?.panel}`)
        }
        launchGetCoverage(search?.search ?? "", search?.panel ?? "")
    }

    const handleCleanFilter = () => {
        const newUrl = props.history.location.pathname.replace(props.history.location.pathname.split("/")[6], "")
        props.history.push(newUrl);
        setcoverageReq({ loading: false, loaded: false, data: null, error: false })
        setcoverageData(coverageDataInit)
        cleanSearch();
    }
    useEffect(() => {
        launchGetStudy()
        launchGetPanels()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (studyReq.loaded && (props?.match?.params?.query ?? "").length > 2) {
            launchGetCoverage(search?.search ?? "", search?.panel ?? "")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studyReq])

    useLayoutEffect(() => {
        if (coverageReq.loading || !coverageReq.loaded) return
        let newCoverageData = {
            filteredData: {},
            filteredDataToDownload: {}
        }
        if (coverageReq.data && props.type === "gene") {
            const getFilteredDataByGene = () => {
                let filteredData: any = {},
                    filteredDataToDownload: any = {}
                Object.keys(coverageReq.data).forEach((sampleName: string) => {//filtrar por gen, transcripto o cromosoma.
                    let sample = coverageReq.data[sampleName];
                    filteredDataToDownload[sampleName] = [];
                    filteredData[sampleName] = [];
                    if (filter.trim().length >= 2) {
                        const filterSplitted = Array.from(new Set(filter.toLowerCase().trim().split(/[\s,;.]+/))).filter((x: any) => x !== "")
                        // console.log(filterSplitted)
                        sample = coverageReq.data[sampleName].filter((x: any) => {
                            return (
                                filterSplitted.some((y: any) => {
                                    return (
                                        x.genename.toLowerCase().indexOf(y.toLowerCase()) > -1 ||
                                        x.regions.find((z: any) => {
                                            return (
                                                z.transcript.toLowerCase().indexOf(y.toLowerCase()) > -1 ||
                                                z.chrom.toLowerCase().indexOf(y.toLowerCase()) > -1
                                            )
                                        })
                                    )
                                })
                            )
                        })
                    }
                    if (sample && sample.length > 0) {
                        filteredData[sampleName] = sample;
                    }
                })
                if (Object.values(filteredData) && Object.values(Object.values(filteredData as any)[0] as any).length !== 0)
                    Object.keys(filteredData ?? {}).forEach((sampleName: any) => {
                        filteredData[sampleName].map((x: any) => x.regions).forEach((x: any) => {
                            filteredDataToDownload[sampleName] = [...filteredDataToDownload[sampleName], ...x];
                        })
                        filteredDataToDownload[sampleName] = filteredDataToDownload[sampleName].map((x: any) => Object.values(x))
                    });
                return { filteredData, filteredDataToDownload }
            }
            newCoverageData = getFilteredDataByGene()
        } else if (coverageReq.data && props.type === "variant") {
            const getFilteredDataByVariant = () => {
                let filteredData: any = {},
                    filteredDataToDownload: any = {}
                Object.keys(coverageReq.data).forEach((sampleName: string) => {//filtrar por gen, transcripto o cromosoma.
                    let sample = coverageReq.data[sampleName];
                    filteredDataToDownload[sampleName] = [];
                    filteredData[sampleName] = [];
                    if (filter.trim().length > 2) {
                        const filterSplitted = Array.from(new Set(filter.toLowerCase().trim().split(/[\s,;.]+/))).filter((x: any) => x !== "")

                        sample = coverageReq.data[sampleName].filter((xx: any) => {
                            return (
                                filterSplitted.some((x: any) => {
                                    return (
                                        xx.genename.trim().toLowerCase().indexOf(x) > -1 ||
                                        xx.rs.trim().toLowerCase().indexOf(x) > -1 ||
                                        xx.chrom.trim().toLowerCase().indexOf(x) > -1
                                    )
                                })
                            )
                        })
                    }
                    if (filterNotOnTarget) sample = sample.filter((xx: any) => !xx.ontarget)
                    if (filterNotCover) sample = sample.filter((xx: any) => !xx.covered)
                    if (sample && sample.length > 0) { filteredData[sampleName] = sample; }
                })
                Object.keys(filteredData ?? {}).forEach((sampleName: any) => {
                    filteredDataToDownload[sampleName] = filteredData[sampleName].map((x: any) => Object.values(x))
                });
                if (orderBy && orderBy.value) {
                    switch (orderBy.value) {
                        case "classification":
                            Object.keys(filteredData).forEach((sampleName: string) => {
                                filteredData[sampleName] = filteredData[sampleName].sort((a: any, b: any) => compareToSort(a, b, "classification", true))
                            })
                            break;
                        case "gene":
                            Object.keys(filteredData).forEach((sampleName: string) => {
                                filteredData[sampleName] = filteredData[sampleName].sort((a: any, b: any) => compareToSort(a, b, "genename"))
                            })

                            break;
                        case "onTarget":
                            Object.keys(filteredData).forEach((sampleName: string) => {
                                filteredData[sampleName] = filteredData[sampleName].sort((a: any, b: any) => compareToSort(a, b, "ontarget"))
                            })

                            break;
                        case "coverage":
                            Object.keys(filteredData).forEach((sampleName: string) => {
                                filteredData[sampleName] = filteredData[sampleName].sort((a: any, b: any) => compareToSort(a, b, "covered"))
                            })

                            break;
                        case "zygosity":
                            Object.keys(filteredData).forEach((sampleName: string) => {
                                filteredData[sampleName] = filteredData[sampleName].sort((a: any, b: any) => compareToSort(b, a, "gt"))
                            })

                            break;
                    }
                    setreloadOnFirstPage(true)
                    // console.log(filteredData)
                }
                return { filteredData, filteredDataToDownload }
            }
            newCoverageData = getFilteredDataByVariant()
        }

        setcoverageData(newCoverageData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, coverageReq, orderBy, filterNotOnTarget, filterNotCover, windowSize])

    const inputSearchData: InputFilterI[] = [
        {
            type: "textarea",
            stateName: "search",
            placeholder: props.type === "gene" ? translate('common.candidateGenes') : translate("common.variantsOrCandidateGenes"),
        },
        {
            type: "select",
            stateName: "panel",
            options: genepanels.data,
        },
    ]
    const homeFilterFooter = <p style={{ fontSize: "11px", color: "dimGray", margin: "2px" }}>
        &nbsp;{
            props.type === "gene" ? translate("genesPanel.processGeneInputGeneNomenclature") :
                translate("genesPanel.processGeneInputGeneNomenclature2")
        }
        <LinkTooltip tooltipLabel={`HGNC - genenames.org`}
            href={`http://www.genenames.org/`}
            label={<strong>{translate("genesPanel.hgncApprovedNomenclature")}</strong>} />
    </p>

    return (
        <HomeLayout history={props.history}
            filterTitle={
                <span onClick={() => {
                    if (studyReq.loaded && studyReq.data?.study?.id) props.history.push(`/cases/caseId/${studyReq.data?.study?.id}/`);
                    else props.history.goBack();
                }
                } style={{ cursor: 'pointer', fontSize: '22px' }}>
                    <Tooltip label={translate("common.goToPreviousPage")} placement={"bottom"}>
                        <span>
                            <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "17px" }} />&nbsp;
                            {/* {variantsComments.loaded && <>{variantsComments.data.study.name} &nbsp;- &nbsp;</>} */}
                            <>{studyReq.loaded ? studyReq.data?.study?.name : ""} &nbsp;- &nbsp;</>
                            {props.type === "gene" ? translate('common.geneCoverage') : translate('common.variantsCoverageClinvar')}
                        </span>
                    </Tooltip>
                </span>
            }
            filterTitleLeft={
                <span style={{ verticalAlign: "sub" }}>
                    <Tooltip placement='right-start'
                        label={props.type === "gene" ? "Coberturas promedio por gen segun regiones target del kit de captura. Maximo de 600 caracteres por busqueda" :
                            translate("common.coverageByVariantsInfo")}>
                        <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: "20px", color: "lightgray" }} />
                    </Tooltip>
                    &nbsp;&nbsp;
                </span >
            }
            Filters={
                < div style={{ width: "100%" }}>
                    <HomeFilter
                        filters={search}
                        setFilter={handleSetsearch}
                        handleCleanFilter={handleCleanFilter}
                        callFilter={handleCallFilter}
                        inputFilters={inputSearchData}
                        submitType={"search"}
                        footer={homeFilterFooter}
                    />
                </div >
            }
            FiltersEmpty={
                < div style={{ width: "100%" }}>
                    <HomeFilter inputFilters={inputSearchData} footer={homeFilterFooter} />
                </div >
            }
            Toolkit={
                < CoverageBodyToolkit type={props.type}
                    filter={filter}
                    setfilter={setfilter}
                    studyReq={studyReq}
                    coverageReq={coverageReq}
                    coverageData={coverageData}
                    orderBy={orderBy}
                    orderByOptions={orderByOptions}
                    handleSetOrderBy={handleSetOrderBy}
                    filterNotOnTarget={filterNotOnTarget}
                    handlefilterNotOnTarget={handlefilterNotOnTarget}
                    filterNotCover={filterNotCover}
                    handlefilterNotCover={handlefilterNotCover}
                />
            }
            Table={
                < Card id="tableCard" style={{ borderRadius: "0px", border: "none" }}>
                    <Card.Body style={{ padding: "0px" }}>
                        {coverageReq.loading ?
                            <Loader onContainer={true} type={'DNA'} /> :
                            (coverageReq.loaded && coverageData.filteredData && Object.keys(coverageData.filteredData).length !== 0 ?
                                (Object.values(coverageData.filteredData) as Array<any>)[0].length === 0 ?
                                    <div>
                                        <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                            {translate('common.noResultsFound')}
                                        </p>
                                    </div>
                                    :
                                    props.type === "gene" ?
                                        <CoverageByGenesResults studyId={`${props.caseId}`}
                                            study={(studyReq.data?.study ?? {})}
                                            coverageFilteredData={coverageData.filteredData}
                                            coverageRawData={coverageReq.data}
                                            filter={filter}
                                            setfilter={setfilter}
                                        />
                                        :
                                        <CoverageByVariantsResults
                                            coverageReq={coverageData.filteredData}
                                            study={(studyReq.data?.study ?? {})}
                                            studyId={`${props.caseId}`}
                                            reloadOnFirstPage={reloadOnFirstPage}
                                            setreloadOnFirstPage={setreloadOnFirstPage}
                                        />
                                :
                                <div>
                                    <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                        {translate('common.noSearchWasDone')}
                                    </p>
                                </div>)
                        }
                    </Card.Body>
                </Card >
            }
        />
    )
}

export type { GenesCoverageI, CoverageBodyT, OrderByOptionsI }
