import React from 'react';
import { Popover } from "../../Popover";
import CancerHotspotsToast from "../../Somatic/CancerHotspotsToast";
import { BarDataI, HotspotsT } from "./HotspotsBar";

interface HotspotsBarLabelsI {
	x: BarDataI,
	i: number,
	percentage: number,
	type: HotspotsT
}
export default function HotspotsBarLabels(props: HotspotsBarLabelsI) {
	const x = props.x;
	const i = props.i;
	const hideOn = i >= 10;
	const title = props.type === "organ" ? x.label : x.label.split(":")[0];
	const subtitle = props.type === "organ" ? undefined : x.label.split(":")[1];
	const label = props.type === "organ" ? title : subtitle;
	return (
		<Popover key={i}
			content={
				<CancerHotspotsToast id={x.id}
					value={x.count}
					title={title}
					subtitle={subtitle}
					color={hideOn ? undefined : x.color}
					percentage={props.percentage} />
			}
			trigger={"hover"}
			style={{ minWidth: "200px", fontSize: "9px", zIndex: 2 }}
			contentStyle={{ padding: "0 0 0 2px" }}
		><span style={{ display: "inline-flex", minWidth: "fit-content" }}>
				<span style={{ display: "contents" }}>
					<div style={{
						backgroundColor: hideOn ? undefined : x.color,
						width: hideOn ? 0 : 15,
						height: 15,
						borderRadius: 3
					}} /> &nbsp;
				</span>
				{label}&nbsp;({x.count}/{x.totalCount}),&nbsp;
			</span>
		</Popover >
	)
}