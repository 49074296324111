import React from 'react';
import { Accordion, Badge, Card, Form, Spinner, Table } from "react-bootstrap";
import FormInput from "../../Common/Form/FormInput";
import { useEffect, useState, useContext } from "react";
import SampleServices from '../../../Services/SampleServices';
import { LangContext } from '../../../Contexts/Lang';
import './styles.css';
import CopyToClipboard from "../../Common/CopyToClipboard";
import ThemeContext from "../../../Contexts/Theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

interface AsociateSamplesI {
	studyType: string,
	samplesSelecteds: string[],
	setsamplesSelecteds: any,
	samplesError: boolean,
	setsamplesError: any,
	samplesFamiliarMultipleError: boolean,
	setsamplesFamiliarMultipleError: any,
	domainId?: string
}
export default function AsociateSamples(props: AsociateSamplesI) {
	const { theme } = useContext(ThemeContext);
	const [samplesFilter, setsamplesFilter] = useState<string>("")
	const [samples, setsamples] = useState<any>([])
	const { dispatch: { translate } } = useContext(LangContext);
	useEffect(() => {
		let isMounted = true
		const SampleS = new SampleServices();
		SampleS.get_sampleFiles(props.domainId)
			.then((res: any) => {
				if (isMounted && res.status === 200) {
					const convertedData = res.data.samples.map((item: any) => {
						const dateParts = item.value1.split("/");
						const date = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));
						return { ...item, value2: date };
					});
					const sortedData = convertedData.sort((a: any, b: any) => b.value2.getTime() - a.value2.getTime());
					setsamples(sortedData)
				}
			})
			.catch()
		return () => { isMounted = false };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function getSamplesRows() {
		function handleSampleSelecteds(id: string) {
			const st = props.studyType
			const found = props.samplesSelecteds.find((x: string) => x === id)
			if (st !== "simple") {
				if (found) {
					const samSel = props.samplesSelecteds.filter((x: string) => x !== id)
					props.setsamplesSelecteds([...samSel])
				} else {
					if (props.studyType === "familiar") {
						if (props.samplesSelecteds.length < 5)
							props.setsamplesSelecteds([...props.samplesSelecteds, id])
					} else
						props.setsamplesSelecteds([...props.samplesSelecteds, id])
				}
			} else {
				props.setsamplesSelecteds(found ? [] : [id])
			}
			props.setsamplesError(false)
			props.setsamplesFamiliarMultipleError(false)
		}
		let s = samples;
		if (samplesFilter.length > 2)
			s = s.filter((x: any) => x.value0.indexOf(samplesFilter) > -1)
		return s.map((x: any, i: number) => {
			return (
				<tr key={i} onClick={() => { handleSampleSelecteds(x.value0) }}>
					<td style={{ padding: "2px" }}>
						<Form.Check type="checkbox"
							onChange={() => { }}
							checked={props.samplesSelecteds?.find((y: any) => y === x.value0) ? true : false}
						/>
					</td>
					<td style={{ padding: "2px" }}>{x.value0}</td>
					<td style={{ padding: "2px" }}>{x.value1}</td>
				</tr>)
		})
	}
	const samplesRows = getSamplesRows()
	const thStyles = { padding: "5px" }
	const samplesTable = (
		<div style={{ overflowY: "scroll", maxHeight: "55vh", overflowX: "hidden", fontSize: "12px" }}>
			<Table responsive="sm" className="myTable" >
				<thead style={{ backgroundColor: "#EEEEEE" }}>
					<tr>

						<th style={thStyles}>
							{(props.studyType !== "simple") &&
								<Form.Check type="checkbox"
									onClick={() => {
										if ((props.samplesSelecteds ?? []).length === (samples ?? []).length) {
											props.setsamplesSelecteds([])
										} else {
											props.setsamplesSelecteds(samples.map((x: any) => x.value0))
										}
										// handleSampleSelecteds(x.value0)
									}}
									checked={(props.samplesSelecteds ?? []).length === (samples ?? []).length}
								/>
							}
						</th>
						<th style={thStyles}>{translate(`home.processCase.samples`)}</th>
						<th style={thStyles}>{translate(`home.processCase.creationDate`)}</th>
					</tr>
				</thead>
				<tbody>
					{samplesRows}
				</tbody>
			</Table >
		</div>
	)
	function getSamplesBadges() {
		return props.samplesSelecteds.map((x: any, i: number) => {
			return (
				<span key={i}>
					<Badge variant="secondary" key={i}
						style={{ fontWeight: "inherit", fontSize: "10px", backgroundColor: "darkgray", marginRight: "5px" }}>
						{x}
					</Badge>
					<CopyToClipboard valueToCopy={x} />
				</span>
			)
		})
	}
	return (
		<>
			<Accordion.Toggle as={Card.Header}
				eventKey="1"
				style={{ height: props.samplesSelecteds.length > 0 ? "auto" : "50px" }}>
				<div>
					<span>
						{samples.length === 0 && <>
							&nbsp;<Spinner animation="grow" role="contentinfo" style={{ color: 'gray', width: 20, height: 20 }} />&nbsp;&nbsp;
						</>}
						{translate(`home.processCase.associateSamples`)}
						<span style={{ color: theme.error }}>*</span>
					</span>
					<FontAwesomeIcon icon={faAngleDown} style={{ float: "right", fontSize: "16px", color: "gray" }} />
					{props.samplesError &&
						<span style={{ color: theme.error }}>
							&nbsp;&nbsp;{translate(`error.required`)}
						</span>
					}
					{props.samplesFamiliarMultipleError &&
						<>
							<br />
							<span style={{ color: theme.error }}>
								{translate(`home.processCase.errors.twoSamplesAtLeast`)}
							</span>
						</>
					}
				</div>
				<div style={{ display: "inline-table", marginLeft: "10px" }}>{getSamplesBadges()}</div>
			</Accordion.Toggle>
			<Accordion.Collapse eventKey="1">
				<Card.Body >
					<FormInput type={"text"}
						labelBold={true}
						formGrouplStyle={{ "marginBottom": "5px" }}       // onKeyDown={(e: any) => handleEnter(e)}
						placeHolder={"Filter sample by name"}
						value={samplesFilter}
						onChange={(e: any) => { setsamplesFilter(e.target.value) }}
					/>
					{samplesTable}
				</Card.Body>
			</Accordion.Collapse>
		</>
	)
}