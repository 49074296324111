import React from 'react';
import { useContext } from 'react';
import { Col, Row, Table, Button } from 'react-bootstrap';
import "../Common/table.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faFolder, faEdit } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Common/Loader/Loader';
import DomainsContext from '../../Contexts/Domains/DomainsContext';
import { EditDomainI } from './ProcessDomain/ProcessDomain';
import EnableDisableBadge from '../Common/Badges/EnableDisable';

interface DomainsTableI {
    history: any
    handleReload: any,
    handleShowprocesDomain?(data?: EditDomainI): void,
    // domainsSelecteds: string[],
    // handleDomainsSelecteds(i: string): void
}
export default function DomainGenesTable(props: DomainsTableI) {
    const { domains } = useContext(DomainsContext);
    const rows = (domains?.data?.results ?? []).map((x: any, i: number) =>
        <TableRow history={props.history}
            key={i}
            actualRow={x}
            handleReload={props.handleReload}
            handleShowprocesDomain={props.handleShowprocesDomain}
            // domainsSelecteds={props.domainsSelecteds}
            // handleDomainsSelecteds={props.handleDomainsSelecteds}
        />
    )
    return (
        <>
            {domains.loading ?
                <Loader onContainer={true} type={'DNA'} /> :
                <div style={{}}>
                    <Table responsive="md" className="selectTable" style={{ marginBottom: "-1px" }}
                    >
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}

interface TableRowI {
    history: any,
    actualRow: any,
    handleReload: any,
    handleShowprocesDomain?(data?: EditDomainI): void,
    // domainsSelecteds: string[],
    // handleDomainsSelecteds(i: string): void
}

function TableRow(props: TableRowI) {
    const rowStyles = { margin: 0 }
    const getFirstColumn = () => {
        const getDescription = () => {
            if (props.actualRow.description && props.actualRow.description.trim() !== "")
                return ` - ${props.actualRow.description}`
            return `--`
        }
        const iconStyle = { border: "none", color: "darkgray", alignSelf: "center", fontSize: "14px" }
        return (
            <>
                <Row style={rowStyles}>
                    <FontAwesomeIcon icon={faFolder} style={iconStyle} />&nbsp;&nbsp;
                    <p style={{ color: "darkslategray", marginBottom: 0 }}>
                        {props.actualRow.id}&nbsp;-&nbsp;{props.actualRow.name}
                    </p>
                </Row>
                <Row style={rowStyles}>
                    <Col style={{ padding: 0, maxWidth: "fit-content" }}>
                        <span style={{ color: "gray", marginBottom: 0, fontSize: "13px" }}>
                            {getDescription()}
                        </span>
                    </Col>
                </Row>
            </>
        )
    }
    const getEnableColumn = () => {
        return (
            <Row style={{ margin: 0, justifyContent: 'center' }}>
                <EnableDisableBadge enable={props.actualRow?.enabled} />
            </Row>
        )
    }
    const dateSplited = props.actualRow.simpleDateFormatCreated.split(" ")
    const getDateColumn = () => {
        return (
            <>
                <Row style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
                    <FontAwesomeIcon icon={faCalendarDay} style={{ border: "none", marginRight: "5px", color: "darkgray" }} />
                    <p style={{ color: "gray", marginBottom: 0, fontSize: "13px" }}>
                        {dateSplited && dateSplited.length > 0 ? dateSplited[0] : "-"}
                    </p>
                </Row>
                <Row style={{ margin: 0, padding: 0, paddingLeft: "5px", justifyContent: 'center' }}>
                    <p style={{ color: "gray", marginBottom: 0, fontSize: "13px" }}>
                        {dateSplited && dateSplited.length > 1 ? dateSplited[1] : "-"}
                    </p>
                </Row>
            </>
        )
    }
    const columnStyles = { border: "none", verticalAlign: "middle", cursor: "pointer" }
    const handleRowClick = () => {
        props.history.push(`/domains/domainId/${props.actualRow.id}`);
    }

    return (
        <tr style={{ borderBottom: "1px solid lightgray" }}>
            {/* <td style={{ border: "none", width: "5px", verticalAlign: "middle", textAlign: "center" }}>
                <Form.Check type="checkbox"
                    checked={props?.domainsSelecteds?.find((y: any) => `${y}` === `${props.actualRow.id}`) ? true : false}
                    onChange={() => { props.handleDomainsSelecteds(`${props.actualRow.id}`) }}
                />
            </td> */}

            <td style={{ ...columnStyles, paddingLeft: "15px" }} onClick={handleRowClick}>
                {getFirstColumn()}
            </td>
            <td style={columnStyles} onClick={handleRowClick}>
                {getEnableColumn()}
            </td>
            <td style={{ border: "none", verticalAlign: "middle" }}>
                {getDateColumn()}
            </td>
            <td style={{ border: "none", verticalAlign: "middle", width: "60px", textAlign: "center" }}>
                <Button variant="light"
                    style={{
                        border: "none",
                        height: "28px",
                        width: "30px",
                        borderRadius: "50px",
                        alignItems: "center",
                        paddingLeft: "9px"
                    }}
                    onClick={() => {
                        if (props.handleShowprocesDomain) {
                            const data = { id: props.actualRow.id, name: props.actualRow.name, description: props.actualRow.description, enable: props.actualRow.enabled }
                            props.handleShowprocesDomain(data)
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faEdit} style={{ border: "none", color: "darkgray" }} />
                </Button>

            </td>
        </tr>
    )
}

