import React, { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { Tooltip } from '../Tooltip/Tooltip';

interface BadgeTierI {
	tier: number // 1 | 2 | 3,
	variantUndefined?: boolean,
	size?: "small" | "normal",
	style?: any

}
export function BadgeTier(props: BadgeTierI) {
	const { theme } = useContext(ThemeContext);
	const style = {
		borderRadius: "3px",
		...(props.style ? props.style : {})
	};
	const size = props.size ?? "normal";
	const tierColor = {
		1: theme.acmg_crit_p_vs,
		2: theme.acmg_crit_p_str,
		3: theme.acmg_crit_p_mod,
	}
	let color = "";
	if (props.tier === 1) {
		color = tierColor[1];
	} else if (props.tier === 2) {
		color = tierColor[2];
	} else if (props.tier === 3) {
		color = tierColor[3];
	} else {
		return <></>
	}
	const badgeResult = <Badge key={props.tier} variant="secondary"
		style={{
			fontWeight: "bold",
			fontSize: size === "normal" ? 11 : 10,
			color: "white",
			backgroundColor: props.variantUndefined ? "#999999" : color,
			padding: size === "small" ? "3px 5px 1px 4px" : "auto",
			height: "fit-content",
			verticalAlign: "middle",
			...style
		}}>
		{size === "normal" ? "TIER" : "T"}&nbsp;{props.tier}
	</Badge>

	return (size === "normal" ?
		badgeResult :
		<Tooltip label={"Tier: " + props.tier}>{badgeResult}</Tooltip>
	)
} 