import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';

export default class StatisticsService extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "stats/";
    }
    async read_stats_for_sample(studyId: number, sampleName: string, domainId?: number): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getStatsForSample`
        const config = {
            ...this.authHeader,
            params: {
                domain: domainId ? domainId : this.user?.domain,
                study: studyId,
                sample: sampleName,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }
    async read_stats_chart(studyId: number, domainId?: number): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getStatsChart`
        const config = {
            ...this.authHeader,
            params: {
                study: studyId,
                domain: domainId ? domainId : this.user?.domain,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }
}