import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import "../../Common/table.css"
import { useContext, useState } from 'react';
import { LangContext } from '../../../Contexts/Lang';
import { Tooltip } from '../../Common/Tooltip/Tooltip';
import { SamplesI, StudyI } from '../../../Contexts/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import ThemeContext from '../../../Contexts/Theme/ThemeContext';
import CloseButton from 'src/Components/Common/CloseButton';
import IconButtonTooltipPopover from 'src/Components/Common/IconButtonTooltipPopover';
import MoreInfoPopoverButton from '../../Common/MoreInfoPopoverButton';
import BadgeSample from '../../Common/Badges/BadgeSample';
import PopOverOmimGenes from 'src/Components/Common/PopOverOmimGenes';
import CopyToClipboard from 'src/Components/Common/CopyToClipboard';
import { LinkDgv, LinkUcsc, LinkDecipherGenomics, LinkClinicalGenome } from 'src/Components/Common/Links/LinksCnv';
import { LinkLocalGen } from 'src/Components/Common/Links/Links';
import LiftOverGRChSwitcher from 'src/Components/Common/LiftOverGRChSwitcher';
import BadgeCnv from 'src/Components/Common/Badges/BadgeCnv';
import { SampleIgvDataI } from 'src/Components/IGV/types';

interface TableRowI {
	studyId: any,
	study: StudyI,
	actualRow: any,
	handleReload: any,
	history: any,
	hugoGene: any,
	rowRef?: any,
	handleShowModalSamplesIgv(i: SampleIgvDataI): void
}

export default function TableRow(props: TableRowI) {
	const { theme } = useContext(ThemeContext);
	const showSamplesColumn = (props.study?.samples ?? []).length > 1
	const tdStyle = { border: "none", width: "35px", verticalAlign: "middle" }
	return (
		<tr ref={props.rowRef ?? undefined}
			style={{
				borderBottom: "1px solid lightgray",
				fontSize: "12px",
				color: theme.grayHeader,
				cursor: "pointer"
			}}>
			{showSamplesColumn && <td style={tdStyle}>
				<BadgeSample name={props.actualRow.sample} />
			</td>}
			<td style={tdStyle}>
				<span style={{ color: theme.grayHeader, fontWeight: "bold" }}>
					<LinkLocalGen geneName={props.actualRow.gene}
						caseId={props.studyId}
						fontSize={"14px"}
						variantId={""}
					/>
					<CopyToClipboard valueToCopy={props.actualRow.gene} fontSize={"10px"} />
				</span>
				{
					props.hugoGene && <PopOverOmimGenes omimId={props.hugoGene?.omimid}
						geneName={props.actualRow.gene}
						gene={props.hugoGene} />
				}
				<br></br>
				<span>
					<LinkClinicalGenome fontSize={"12px"} geneName={props.actualRow.gene} />
				</span>
			</td>
			<td style={{ ...tdStyle, minWidth: "45px" }}>
				<BadgeCnv cnvType={props.actualRow?.cnvtype} />
			</td>
			<td style={{ ...tdStyle, verticalAlign: "top", minWidth: "160px" }}>
				{props.actualRow?.decon &&
					<div>
						<RegionLabel type="decon"
							actualRow={props.actualRow}
							study={props.study}
							studyId={props.studyId}
							handleShowModalSamplesIgv={props.handleShowModalSamplesIgv}
						/>
						<ValorationCol actualRow={props.actualRow} decon={true} onco={false} />

					</div>
				}
			</td>
			<td style={{ ...tdStyle, verticalAlign: "top", minWidth: "160px" }}>
				{props.actualRow?.onco &&
					<div>
						<RegionLabel type="onco"
							actualRow={props.actualRow}
							study={props.study}
							studyId={props.studyId}
							handleShowModalSamplesIgv={props.handleShowModalSamplesIgv}
						/>
						<ValorationCol actualRow={props.actualRow} decon={false} onco={true} />
					</div>
				}
			</td>
		</tr >
	)
}
interface RegionLabelI {

	type: "onco" | "decon",
	actualRow: any,
	study: any,
	studyId: any,
	handleShowModalSamplesIgv: any
}

function RegionLabel(props: RegionLabelI) {
	const { theme } = useContext(ThemeContext);

	const start = props.type === "onco" ? props.actualRow.startOnco : props.actualRow.startDecon;
	const end = props.type === "onco" ? props.actualRow.endOnco : props.actualRow.endDecon;
	const kbRegion = props.type === "onco" ? props.actualRow.KbOncoByCnv : props.actualRow.KbDeconByCnv;
	const region = props.type === "onco" ? props.actualRow.regionOnco : props.actualRow.regionDecon;
	return (
		<div>
			<span style={{ fontWeight: "bold", fontSize: "13px", color: theme.grayHeader }}>
				{region}
				<CopyToClipboard valueToCopy={region} fontSize={"10px"} />
			</span>
			{props.actualRow?.chromosome && start && end && props.study?.genomeReference &&
				<LiftOverGRChSwitcher genomeReference={props.study?.genomeReference.toUpperCase().trim() === "GRCH38" ? "38" : "19"}
					region={{
						chromosome: props.actualRow?.chromosome,
						start: start,
						end: end
					}}
				/>
			}
			&nbsp;
			<Tooltip label={`${kbRegion * 1000} bp`}>
				{/* <span>{props.actualRow?.KbDeconByCnv} kb</span> */}
				<span>{kbRegion} kb</span>
			</Tooltip>
			&nbsp;&nbsp;
			<Button style={{ cursor: "pointer", border: "none", padding: "2px 5px" }} variant="outline-primary"
				onClick={(e: any) => {
					const getBageSample = () => {
						const s = (props.study?.samples).find((x: SamplesI) =>
							(x.sample?.name ?? "").toLowerCase() === (props.actualRow?.sample ?? "-").toLowerCase()
						)
						if (s) {
							return <BadgeSample name={s.sample.name}
								afected={s.afectado}
								fontSize={"14px"} />
						}
						return false;
					}
					const i: SampleIgvDataI = {
						caseId: props.studyId,
						chromosome: props.actualRow.chromosome,
						gen: props.actualRow.gene,
						position: `${start}-${end}`,
						igvData: {
							caseId: props.studyId,
							assembly: props.study?.genomeReference,
							sampleNameSelected: props.actualRow?.sample,
							view: "variant",
							alignments: [
								{
									effect: props.actualRow?.cnvtype,
									sampleName: props.actualRow?.sample,
									sampleBadge: getBageSample()
								}
							]
						}
					}
					props.handleShowModalSamplesIgv(i);
				}}
			>
				<FontAwesomeIcon icon={faSearch} style={{}} />&nbsp;IGV
			</Button>
			&nbsp;&nbsp;
			<span>
				<MoreInfoPopoverButton trigger={"click"}>
					<div>
						<div key={"Dgv"}><LinkDgv fontSize={"12px"}
							genomeRef={props.study.genomeReference}
							chromosome={props.actualRow.chrNumber}
							start={props.actualRow.startDecon}
							end={props.actualRow.endDecon} /></div>
						<div key={"Ucsc"}><LinkUcsc fontSize={"12px"}
							genomeRef={props.study.genomeReference}
							chromosome={props.actualRow.chrNumber}
							start={props.actualRow.startDecon}
							end={props.actualRow.endDecon} /></div>
						<div key={"Decipher"}><LinkDecipherGenomics fontSize={"12px"}
							genomeRef={props.study.genomeReference}
							chromosome={props.actualRow.chrNumber}
							start={props.actualRow.startDecon}
							end={props.actualRow.endDecon} /></div>
					</div>
				</MoreInfoPopoverButton>
			</span>
		</div>
	)
}


interface ValorationColI {
	actualRow: any,
	decon: boolean,
	onco: boolean,
}
function ValorationCol(props: ValorationColI) {
	const { theme } = useContext(ThemeContext);
	const { dispatch: { translate } } = useContext(LangContext);
	let result = <></>;
	let expectedVsObservedRatio = (props.actualRow.readsExpected || props.actualRow.readsObserved) ?
		<>
			<Tooltip label={translate("common.expectedReadings")}>
				<span>Reads.expected: {props.actualRow.readsExpected}</span>
			</Tooltip>
			&nbsp;-&nbsp;
			<Tooltip label={translate("common.observedReadings")}>
				<span>Reads.observed: {props.actualRow.readsObserved}</span>
			</Tooltip>
		</> : ""

	if (props.decon) {
		result = <>
			<Badge variant="light" style={{ fontSize: "12px", color: theme.grayHeader }}>
				BF&nbsp;:&nbsp;{props.actualRow.bayesFactor}
			</Badge>
			<InfoPopoverButton>
				<div>
					<h5 style={{ fontSize: "14px" }}>
						Bayes Factors:
					</h5>
					<p style={{ fontSize: "11px" }}>
						{translate("common.bayesfactorInfo")}
					</p>
					<a href="https://www.stat.washington.edu/raftery/Research/PDF/kass1995.pdf"
						title="Bayes Factor"
						target="_blank"
						style={{ float: "right", fontSize: "11px" }}
						rel="noreferrer">
						Kass and Raftery(1995)
					</a>
				</div>
			</InfoPopoverButton>
			{expectedVsObservedRatio}
			{
				props.actualRow.nExons &&
				<Tooltip label={translate("common.quantityOfExons")}>
					<span style={{ color: "gray" }}>
						&nbsp;&nbsp;nExons:&nbsp;{props.actualRow.nExons}
					</span>
				</Tooltip>
			}

		</>
	}
	if (props.onco)
		result = <>
			<Badge variant="light" style={{ fontSize: "12px", color: theme.grayHeader }}>
				qValue&nbsp;:&nbsp;{props.actualRow.qValue.toPrecision(3)}
			</Badge>
			<InfoPopoverButton>
				<div>
					<h5 style={{ fontSize: "14px" }}>
						qValue:
					</h5>
					<p style={{ fontSize: "11px" }}>
						{translate("common.qValueInfo")}
					</p>
					<a href="https://www.stat.washington.edu/raftery/Research/PDF/kass1995.pdf"
						title="Bayes Factor"
						target="_blank"
						style={{ float: "right", fontSize: "11px" }}
						rel="noreferrer">
						Kass and Raftery(1995)
					</a>
				</div>
			</InfoPopoverButton>
			<Badge variant="light" style={{ fontSize: "12px", color: theme.grayHeader }}>
				pValue&nbsp;:&nbsp;{props.actualRow.pValue.toPrecision(3)}
			</Badge>

		</>
	return result
}


interface InfoPopoverButtonI {
	children?: JSX.Element,
}
function InfoPopoverButton(props: InfoPopoverButtonI) {
	const [show, setshow] = useState(false)
	const [notclick, setnotclick] = useState(false)
	return (
		<IconButtonTooltipPopover icon={faInfoCircle}
			// tooltipLabel={"OMIM"}
			popoverBodyStyle={{ maxHeight: "251px", overflowY: "auto" }}
			placement="bottom"
			show={show}
			onClick={() => {
				if (!notclick) {
					setshow(true); setnotclick(true)
				} else { setshow(false); setnotclick(false) }
			}}
			popoverStyle={{ padding: 0 }}
			popOver={{
				content: (<>
					<CloseButton styles={{ marginTop: "1px" }}
						onClose={() => { setnotclick(true) }} />

					{props.children ?? "--"}
				</>)
			}}
		/>
	)
}
