import React from 'react';
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { LinkClinGen, NcbiClinvarLinkByVcfId, NcbiDbsnpLinksByVcfId } from "src/Components/Common/Links/Links";
import { AsyncDataI } from "src/Contexts/interfaces";
import { LangContext } from "src/Contexts/Lang";
import { evidenceClinvarValues } from "src/utils/BioFunctionalData";
import DetailQueryTable, { DetailQueryTableRowI } from "./DetailQueryTable";
import { LinkGeneCards, LinkLocalGen, LinkNCBI, LinkOMIM } from '../../../Common/Links/Links';
import BadgeVariantImpact from "src/Components/Common/Badges/BadgeVariantImpact";
import LinkTooltip from "src/Components/Common/Links/LinkTooltip";
import { Tooltip } from 'src/Components/Common/Tooltip/Tooltip';
import CustomAcmgGuideByGene from 'src/Components/Common/CustomAcmgGuideByGene/CustomAcmgGuideByGene';
import { Badge } from 'react-bootstrap';

interface AnnotationTableI {
	studyId: number,
	variantId: string,
	variant: AsyncDataI,
	isMitochondrial: boolean,
	isSomatic: boolean,
}

export default function AnnotationTable(props: AnnotationTableI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const variant = props.variant.data;
	const gene = props.variant.loaded ? variant?.hugoGenes[`${Object.keys(variant?.hugoGenes)[0]}`] : undefined;
	const rsArray = variant?.variant?.vcfId ? variant?.variant?.vcfId.split(";").filter((x: any) => x.length > 3) : undefined;
	let infoCd: any = variant?.variant?.infoColumnData;
	const getAnottationTableData = (): DetailQueryTableRowI[] => {
		const getClnSigValue = () => {
			let response: any = " -- "
			if (infoCd?.CLNSIG) {
				response = infoCd?.CLNSIG.map((x: number) =>
					(evidenceClinvarValues.find((y: any) => y.value === `${x}`)?.label) ?? " -- "
				).join(", ")
			}
			return (<div>
				<NcbiDbsnpLinksByVcfId vcfIds={variant?.variant?.vcfId ?? ""} copyToClipboard={true} /> &nbsp;
				<NcbiClinvarLinkByVcfId vcfIds={variant?.variant?.vcfId ?? ""} copyToClipboard={true} />
				&nbsp;&nbsp;<span style={{ color: "lightgray" }}>|</span>&nbsp;&nbsp;
				<span style={{ fontWeight: "bold" }}>ClnSig&nbsp;:&nbsp;</span>
				{response};
			</div>)
		}
		let effect = (infoCd?.EFFECT ?? "").toLowerCase().replaceAll("_", " ") ?? " -- ";
		effect = effect.charAt(0).toUpperCase() + effect.slice(1).toLowerCase();
		const getGene_OncogeneOrTSG = () => {
			const is_oncogene = infoCd?.ONCOKB_IS_ONCOGENE ? true : false;
			const is_tsg = infoCd?.ONCOKB_IS_TUMOR_SUPPRESSOR ? true : false;
			if (!is_oncogene && !is_tsg) return ("")
			const label = is_oncogene && is_tsg ? `OG/${translate("common.tsg")}` : is_oncogene ? "OG" : is_tsg ? translate("common.tsg") : ""
			const tooltipLabel = is_oncogene && is_tsg ?
				`${translate("common.oncogene")} ${translate("common.and")} ${translate("common.tumorSuppressorGene")}` :
				is_oncogene ? translate("common.oncogene") : is_tsg ? translate("common.tumorSuppressorGene") : "";
			return (
				<span style={{ marginLeft: "5px" }}>
					<Tooltip label={tooltipLabel}>
						<Badge style={{ backgroundColor: "#EEE", fontSize: 9, fontWeight: 100 }}>
							{label}
						</Badge>
					</Tooltip>
				</span>
			)
		}

		let dataTableAnottation: DetailQueryTableRowI[] = [
			{
				id: "gene",
				label: translate("common.gene"),
				value: <>
					<span style={{ margin: "0 5px" }}>
						<LinkLocalGen geneName={gene?.approvedsymbol}
							caseId={`${props.studyId}`}
							variantId={`${props.variantId}`}
							fontSize={"13px"} />
					</span>
					|
					<span style={{ margin: "0 5px" }}>
						<LinkOMIM fontSize={"13px"} omimId={gene?.omimid} />
					</span>
					|
					<span style={{ margin: "0 5px" }}>
						<LinkGeneCards fontSize={"13px"} geneName={infoCd?.GENE_NAME} />
					</span>
					|
					<span style={{ margin: "0 5px" }}>
						<LinkNCBI fontSize={"13px"} geneName={infoCd?.GENE_NAME} />
					</span>
					<span style={{ float: "right", margin: "-3px" }}>
						{props.isSomatic && getGene_OncogeneOrTSG()}
						<CustomAcmgGuideByGene geneName={gene?.approvedsymbol} />
						&nbsp;
					</span>
				</>
			},
			{
				id: "effect",
				label: translate("common.effect"),
				value: <>
					<span style={{ margin: "0 5px" }}>
						{effect}
					</span>
				</>
			},
			{
				id: "impact",
				label: translate("common.impact"),
				value: <>
					<BadgeVariantImpact impact={(infoCd?.IMPACT ?? "").trim().toLowerCase()} />
				</>
			},
			{
				id: "clnsig",
				label: "Clinvar",
				value: getClnSigValue(),
				info: {
					title: "ClnSig",
					body: translate("variantDetail.clnSigInfo")
				}
			}
		]
		const masterMindLink = decodeURIComponent(infoCd?.MMURI3 ?? "")
		dataTableAnottation = [
			...dataTableAnottation,
			{
				id: "clingen",
				label: "Clingen",
				value: <>
					{
						gene?.refseqManeSelect && infoCd?.CODON_CHANGE ?
							<LinkClinGen variant={{
								hgvsRefSeq: gene?.refseqManeSelect,
								change: infoCd?.CODON_CHANGE,
								genename: infoCd?.GENE_NAME
							}} /> :
							"--"
					}
				</>
			},
			{
				id: "mastermind",
				label: "Mastermind",
				value: <>
					{
						infoCd?.MMURI3 ?
							<LinkTooltip label={"Mastermind"} tooltipLabel={"Go to Mastermind"} href={masterMindLink} externalLink={true} /> :
							"--"
					}
				</>
			},
			{
				id: "litvar",
				label: "Litvar",
				value: <>
					{
						rsArray && rsArray.length > 0 ?
							rsArray.map((x: string, i: number) => {
								return (
									<span key={`${i}_A`} style={{ marginRight: "5px" }}>
										<LinkNCBI litvarRs={x} />
										{i !== rsArray.length - 1 ? ", " : ""}
									</span>)
							}) :
							"--"
					}
				</>
			}
		]
		return dataTableAnottation
	}
	const dataTableAnottation = getAnottationTableData()

	return (
		<DetailQueryTable id="DetailQueryTable_annotations"
			title={
				<>
					<FontAwesomeIcon icon={faPenAlt} style={{ color: "lightgray", float: "right", margin: "4px 5px 0 0", fontSize: "15px" }} />
					&nbsp;{translate("variantDetail.annotation")}
				</>
			}
			data={dataTableAnottation}
		/>
	)
}