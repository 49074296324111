
import React from 'react';
import { useState, useContext } from "react";
import { LangContext } from "../../../Contexts/Lang";
import StudyService from '../../../Services/StudyServices';
import ProcessCase from './../ProcessCase/ProcessCase';
import { ModalWarning } from "../../Common/ModalWarning";
import DetailHeader from "../../Layout/Detail/DetailHeader";
import UserContext from "src/Contexts/User/UserContext";


interface DetaiCaseHaderI {
    history: any,
    actualCase?: any,
    setReload: any
}

export default function DetaiCaseHader(props: DetaiCaseHaderI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { user } = useContext(UserContext);
    const isAdmin = (user.data?.authorities ?? []).find((x) => (x?.authority ?? "").toLowerCase().indexOf("admin") > -1)
    const isOperator = (user.data?.authorities ?? []).find((x) => (x?.authority ?? "").toLowerCase().indexOf("operator") > -1)

    const [warning, setWarning] = useState<{ open: boolean, type: "active" | "archive" | "delete" }>({ open: false, type: "active" })
    const handleCloseWarning = () => setWarning({ open: false, type: warning.type });

    const [showProcessCase, setshowProcessCase] = useState<boolean>(false)
    const handleCloseProcessCase = () => setshowProcessCase(false);
    const handleShowProcessCase = () => setshowProcessCase(true);

    const studyTypeOptions = [
        { label: translate('home.filter.studyType.all'), value: null },
        { label: translate('home.filter.studyType.singles'), value: "simple" },
        { label: translate('home.filter.studyType.familiares'), value: "familiar" },
        { label: translate('home.filter.studyType.multiples'), value: "multiple" }
    ]

    const SS = new StudyService();

    const warningConf = {
        "active": {
            label: translate('casesDetail.confirmActiveStudy'),
            buttonLabel: translate('common.activate'),
            handler: () => {
                SS.active([props.actualCase.id])
                props.setReload()
                handleCloseWarning()
            }
        },
        "archive": {
            label: translate('casesDetail.confirmArchiveStudy'),
            buttonLabel: translate('home.archive'),
            handler: () => {
                SS.archive([props.actualCase.id])
                props.setReload()
                handleCloseWarning()
            }
        },
        "delete": {
            label: translate('casesDetail.confirmDeleteStudy'),
            buttonLabel: translate('home.delete'),
            handler: () => {
                SS.delete([props.actualCase.id])
                props.setReload()
                handleCloseWarning()
                props.history.push("/")
            }
        },
    }
    const stateCase = props.actualCase?.state?.trim().toLowerCase()
    const getButtonActions = () => {
        var buttonActions: any = {}
        if (isAdmin || isOperator) buttonActions = {
            edit: () => handleShowProcessCase()
        }
        buttonActions = {
            ...buttonActions,
            archive: () => setWarning({ open: true, type: "archive" }),
        }
        if (isAdmin) buttonActions = {
            ...buttonActions,
            delete: () => setWarning({ open: true, type: "delete" }),
        }
        return buttonActions
    }
    var buttonActions = getButtonActions()
    
    return (
        <>
            <ProcessCase show={showProcessCase}
                handleClose={handleCloseProcessCase}
                handleReload={() => props.setReload()}
                caseId={props.actualCase?.id}
                studyTypeOptions={studyTypeOptions}
            />
            <ModalWarning labelTitle={translate('common.confirm')}
                label={warningConf[warning.type].label}
                labelButton={warningConf[warning.type].buttonLabel}
                showState={warning.open}
                handleClose={handleCloseWarning}
                onClick={() => warningConf[warning.type].handler()}
            />
            <DetailHeader history={props.history}
                titleTooltipLabel={"Go back"}
                titleLabel={props.actualCase?.name}
                buttonsActions={buttonActions}
                handleGoBack={() => {
                    if (stateCase === "active") props.history.push(`/cases`)
                    else props.history.goBack()
                }}
            />
        </>
    )
}