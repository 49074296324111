import React, { useContext } from 'react'
import { Toast } from 'react-bootstrap'
import { LangContext } from 'src/Contexts/Lang'



interface CancerHotspotsToastI {
	id: any,
	value: number,
	percentage: number
	color?: string,
	title?: any,
	subtitle?: any,
}
export default function CancerHotspotsToast(props: CancerHotspotsToastI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const thereIsColor = props.color !== undefined && props.color.length > 2;
	const color = props.color;
	const value = props.value;
	const percentage = props.percentage;
	return (
		<Toast style={{ maxWidth: 300 }} >
			<Toast.Header closeButton={false}>
				{!thereIsColor ? "" : <div style={{ backgroundColor: color, width: 15, height: 15, borderRadius: 3 }} />}
				&nbsp;&nbsp;
				{props.title &&
					<strong className="mr-auto">
						{`${props.title}`}
					</strong>
				}
				{props.subtitle &&
					<small>
						{`${props.subtitle}`}
					</small>
				}
			</Toast.Header>
			<Toast.Body>
				{value} {translate("variantDetail.samplesWithThisAminoAcidChangeFrom")}  {percentage} ({((value / percentage) * 100).toFixed(1)}%)
			</Toast.Body>
		</Toast>
	)
}