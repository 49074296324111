import React from 'react';
import { StudyI } from "../../../../Contexts/interfaces";
import { Tab, Tabs } from "react-bootstrap";
import CircleLabel from "src/Components/Common/CircleLabel";
import CoverageByGenesTabBySample from './CoverageByGenesTabBySample';

interface CoverageByGenesResultsI {
    studyId: string,
    study: StudyI,
    coverageFilteredData: any,
    coverageRawData: any,
    filter: string,
    setfilter(i: string): void,
}
export default function CoverageByGenesResults(props: CoverageByGenesResultsI) {
    const handleFilter = (i: string) => {
        i = i.toUpperCase()
        const splittedFilter = props.filter.toUpperCase().split(/[\s,;.]+/);
        if (splittedFilter.includes(i)) {
            const filteredLess = splittedFilter.filter((x: string) => x !== i).join(", ");
            props.setfilter(filteredLess)
        } else {
            const filteredAdd = `${splittedFilter[0].length > 1 ? (props.filter + ", ") : ""}${i}`;
            props.setfilter(filteredAdd)
        }
    }
    return (
        <div style={{ padding: "10px 15px 25px 15px" }}>
            <Tabs defaultActiveKey="0" id="uncontrolled-tab-example" style={{ margin: 0 }}>
                {Object.keys(props.coverageFilteredData).map((x: string, i: number) => {
                    return (
                        <Tab eventKey={`${i}`}
                            key={`${i}`}
                            title={<>{`${x} `} &nbsp;<CircleLabel label={props.coverageFilteredData[x].length} size={1} backColor={"gray"} /></>}
                            style={{
                                padding: "10px",
                                background: "linear-gradient(to bottom, #F4F4F4, #FFF, #FFF)",
                                borderTop: "solid 1px lightgray",
                            }}
                        >
                            <CoverageByGenesTabBySample study={props.study}
                                studyId={props.studyId}
                                studyName={x}   //x
                                coverageFilteredData={props.coverageFilteredData}
                                coverageRawData={props.coverageRawData}
                                handleFilter={handleFilter}
                                filter={props.filter}
                            />
                        </Tab>
                    )
                })}
            </Tabs>
        </div >
    )
}
