import React from 'react';
import { useContext, useEffect, useRef, useState } from "react";
import { LangContext } from "../../../Contexts/Lang";
import { Form } from "react-bootstrap";
import FormInput from "../../Common/Form/FormInput";
import { Formik } from "formik";
import * as yup from "yup";
import { formInputType } from "../../Common/Interfaces";
import DomainService from "../../../Services/DomainService";
import { FormCard, FormRow } from "../../Common/Form/FormCard";
import { EditDomainI } from "./ProcessDomain";

interface DomainFormI {
    history: any,
    editDomain?: EditDomainI,
    handleClose?(): void,
    handleReload?(): void,
}

export default function DomainForm(props: DomainFormI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const [initialForm, setInitialForm] = useState<any>("");
    const DS = new DomainService();
    let formRef: any = useRef();
    useEffect(() => {
        let userForm: EditDomainI = {
            id: "",
            name: "",
            description: "",
            enable: true,
        }
        if (props.editDomain) {
            userForm = props.editDomain
        }
        setInitialForm(userForm)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editDomain])

    ///.---------------------------------------------------------------------------------------------
    function createSchema() {
        const name = yup.string().min(3, translate(`error.toShort`)).max(50, translate(`error.toLong`)).required(translate(`error.required`));
        const description = yup.string().required(translate(`error.required`));
        const enable = yup.boolean()
        return yup.object().shape({ name, description, enable })
    }
    function createForm(values: any, handleChange: any, errors: any) {
        function commonFormInput(type: formInputType, key: string, name: string, required: boolean = false) {
            return <FormInput type={type}
                labelBold={true}
                labelColor={"gray"}
                key={key}
                formGrouplStyle={{ "marginBottom": 0 }}       // onKeyDown={(e: any) => handleEnter(e)}
                name={name}
                // disabled={readMode}
                required={required}
                requiredFeedback={errors[name]}
                feedbackValidator={!!errors[name]}
                value={values[name]}
                onChange={handleChange} />
        }
        const nameInput = commonFormInput("text", "nameKey", "name", true);
        const descriptionInput = commonFormInput("textarea", "fulldescription", "description", true);
        return (
            <>
                <FormRow label={translate(`common.name`)}
                    input={nameInput}
                    required={true} /><br />
                <FormRow label={translate(`common.description`)}
                    input={descriptionInput}
                    required={true} /><br />

                <FormRow label={"Enable"}
                    input={
                        <Form.Check type="checkbox"
                            checked={values?.enable ?? false}
                            name={"enable"}
                            onChange={handleChange}
                        />
                    } />
            </>
        )
    }
    function handleSubmit(data: EditDomainI): void {
        if (props.editDomain) {
            DS.update(data.id, data.description, data.name, data.enable)
                .then(() => {
                    if (props.handleReload) props.handleReload()
                    if (props.handleClose) props.handleClose()
                })
                .catch((e: any) => { console.log(e) })
        } else {
            DS.create(data.description, data.name, data.enable)
                .then(() => {
                    if (props.handleReload) props.handleReload()
                    if (props.handleClose) props.handleClose()
                })
                .catch((e: any) => { console.log(e) })
        }
    }

    ///.---------------------------------------------------------------------------------------------
    return (
        <Formik initialValues={initialForm}
            validationSchema={createSchema()}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            handleSubmit={console.log}
            innerRef={formRef}
            validateOnChange={false}
            validateOnBlur={true}>
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                <FormCard body={createForm(values, handleChange, errors)}
                    // enableSubmit={!readMode}
                    submit={handleSubmit}
                    handleCancel={props.handleClose}
                />
            )}
        </Formik>
    )
}

export type { EditDomainI }