import React from 'react';
import { Card, Table } from "react-bootstrap"
import { useState, useEffect } from "react";
import { ModalSamplesIgv, SampleInfoIgvDataI } from "../../../../IGV/ModalSamplesIgv";
import { StudyI } from "../../../../../Contexts/interfaces";
import Paginator from "src/Components/Common/Paginator/Paginator";
import GenesTableRows from "./GeneTableRows";

type CoverageGenesColT = "transcript" | "region"  | "meanDepth" | "coverage" | "alignment"

interface RegionsTableI {
    regions: any[],
    study: StudyI,
    studyId: string,
    cardStyles?: any,
    geneName: string,
    settableMounted(val: boolean): void,
    studyName: string,
}
export default function CoverageGenTable(props: RegionsTableI) {
    const [genesRows, setgenesRows] = useState<any[]>([]);

    const [showModalSamplesIgv, setShowModalSamplesIgv] = useState<{ show: boolean, data?: SampleInfoIgvDataI }>({ show: false, data: undefined });
    const handleCloseModalSamplesIgv = () => setShowModalSamplesIgv({ show: false, data: undefined });
    const handleShowModalSamplesIgv = (i: SampleInfoIgvDataI) => {
        setShowModalSamplesIgv({
            show: true,
            data: {
                ...i,
                igvData: i.igvData ? {
                    ...i.igvData,
                    library: props.study.libraryPrepKit
                } : undefined
            }
        });
    }

    const paginator = Paginator({
        reloadCall: () => { },
        onChangePage: (i: any) => {
            paginator.setActualPage(i)
            props.settableMounted(false)
        },
        onItemsPerPageChange: (i: any) => {
            paginator.setActualPage(0)
            props.settableMounted(false)
        }
    })
    useEffect(() => {
        paginator.pageSize_set(props.regions.length)
        let newGenesRows = props.regions.filter((x: any, i: number) => {
            return (
                i >= (paginator.actualPage * paginator.itemsPerPage) &&
                i < (paginator.actualPage + 1) * paginator.itemsPerPage
            )
        })
        setgenesRows(newGenesRows)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, paginator.actualPage, paginator.itemsPerPage])

    const studyType = (props.study?.type ?? "").toLowerCase().trim()
    return (
        <>
            {(studyType === "multiple" || studyType === "familiar" || (props.study?.hasBam ?? false)) &&
                (showModalSamplesIgv?.data?.chromosome && showModalSamplesIgv?.data?.position && showModalSamplesIgv?.data?.gen) &&
                <ModalSamplesIgv
                    caseId={`${props.study.id}`}
                    show={showModalSamplesIgv.show}
                    handleClose={handleCloseModalSamplesIgv}
                    mood={showModalSamplesIgv?.data?.mood ?? "sample"}
                    chromosome={showModalSamplesIgv?.data?.chromosome}
                    position={showModalSamplesIgv?.data?.position}
                    gen={showModalSamplesIgv?.data?.gen}
                    sampleData={showModalSamplesIgv.data?.sampleData}
                    igvData={showModalSamplesIgv.data?.igvData}
                />
            }

            {props.regions.length === 0 ? <></> :
                <Card style={{
                    borderRadius: "0px",
                    border: "none",
                    width: "100%",
                    boxShadow: "rgb(200 200 200) 0px 5px 4px",
                    marginBottom: "25px",
                    ...(props.cardStyles ?? {})
                }}>
                    <Card.Body style={{ padding: "0px", width: "100%" }}>
                        <Table responsive="sm" style={{ marginBottom: 0 }}
                        >
                            {/* <GeneTableHeaders study={props.study}
                                geneName={props.geneName}
                            /> */}
                            <tbody style={{ maxHeight: "600px", overflowX: "auto" }}>
                                <GenesTableRows regions={genesRows}
                                    study={props.study}
                                    handleShowModalSamplesIgv={handleShowModalSamplesIgv}
                                    studyId={props.studyId}
                                    geneName={props.geneName}
                                    studyName={props.studyName}
                                    settableMounted={props.settableMounted}
                                />
                            </tbody>
                        </Table >
                    </Card.Body>
                    {(genesRows ?? []).length > 0 ?
                        <Card.Footer style={{ zIndex: 1 }}>
                            {paginator.component}
                        </Card.Footer>
                        : <></>}
                </Card>
            }
        </>
    )
}

function getColumnId(studyName: string, geneName: string, colType: CoverageGenesColT, index?: number): string {
    return `${studyName}_${geneName}_${colType}_${index || index === 0 ? index : "header"}`;
}

export { getColumnId }
export type { CoverageGenesColT }