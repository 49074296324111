import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';
import { RemoveSpecialCharacters } from 'src/utils/helpers';
// import qs from 'qs';
export default class GensetServices extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "genset/";
    }

    async search(
        filters?: {
            panelnameSearch?: string,
            genenameSearch?: string,
            sortColumn?: string
        },
        pageNumber: number = 1,
        pageSize: number = 25): Promise<AxiosResponse<any>> {
        filters = {
            panelnameSearch: filters?.panelnameSearch ?? "",
            genenameSearch: filters?.genenameSearch ?? "",
            sortColumn: filters?.sortColumn ?? "",
        }
        const url = `${this.basePath}search`
        const data = {
            domainId: (this.user?.domain ?? "").toString(),
            userId: this.user?.id,
            pageNumber: pageNumber,
            pageSize: pageSize,
            ...filters
        }
        return await axios.post(url, data, this.authHeader)
    }

    async create_panel(name: string, description: string, geneNames: string, author?: string, reference?: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}save`
        name = RemoveSpecialCharacters(name)
        const reqParams = {
            author: author ?? "",
            references: reference ?? ""
        }
        const data = {
            domainId: (this.user?.domain ?? "").toString(),
            userId: this.user?.id,
            user: this.user?.id.toString(),
            name: name,
            description: description,
            genesName: geneNames,
            key: "",
            autocompleteSintomas: "",
            ...reqParams
        }
        return await axios.post(url, data, this.authHeader)
    }
    // key: "26-1676657068"
    // region: "GenePanelDescriptor26"
    async update_panel(key: string, region: string, name: string, description: string, geneNames: string, author?: string, reference?: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}update`
        name = RemoveSpecialCharacters(name)
        const reqParams = {
            author: author ?? "",
            references: reference ?? ""
        }
        const data = {
            domainId: (this.user?.domain ?? "").toString(),
            user: this.user?.id.toString(),
            key: key,
            region: region,
            name: name,
            description: description,
            genesName: geneNames,
            autocompleteSintomas: "",
            ...reqParams
        }
        return await axios.post(url, data, this.authHeader)
    }

    async delete_panels(panels: { id: string, regionKey: string }[]): Promise<AxiosResponse<any>> {
        let url = `${this.basePath}deletePanels`
        const panelIdsReq = panels.map((x: any) => { return `${x.id};${x.regionKey}` }).join(",")
        const config = {
            ...this.authHeader,
            params: {
                panelids: panelIdsReq,
                _: (new Date()).getTime()
            },
        }
        return await axios.get(url, config)
    }

    async get_panel(panelId: string, region: string, pageNumber: number = 1, pageSize: number = 25): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}getGenset`
        const config = {
            ...this.authHeader,
            params: {
                key: panelId,
                region: region,
                pageNumber: pageNumber,
                pageSize: pageSize,
                sortDirection: "ASC",
                sortColumn: "name",
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }
    async delete_gene(panelKey: string, region: string, genId: string[]): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}deleteGenes`
        const config = {
            ...this.authHeader,
            params: {
                key: panelKey,
                region: region,
                geneids: genId.join(","),
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }

}
