import React from 'react';
import Select from 'react-select'

interface ColourOptionI {
    value: string | null;
    label: string | JSX.Element,
    color?: string;
    isDisabled?: boolean;
}

interface ReactSelectI {
    id?: string,
    options?: ColourOptionI[],
    onChange?: any,
    value?: any,
    label?: any,
    labelBold?: boolean,
    labelStyles?: any,
    width?: any,
    onKeyDown?: any,
    highlight?: boolean,
    fontSize?: string,
    height?: string,
    divStyles?: any,
    isMulti?: boolean,
    onInputChange?: any,
    placeholder?: string,
    disabled?: boolean
}
export default function ReactSelect(props: ReactSelectI) {
    const options = props.options ?? []
    const onChange = props.onChange ?? {}
    const width = props.width ?? "auto"
    const stylesss = {
        width: "100%", minWidth: "100%",
        color: "#111",
        control: (base: any) => ({
            ...base,
            fontSize: (props.fontSize ?? "12px"),
            minHeight: (props.height ?? "32px"),
            padding: 0,
            width: width,
            border: props.highlight ? "solid 2px #009eea" : "auto"
        }),
        menu: (base: any) => ({
            ...base,
            zIndex: 2,
            padding: (props.isMulti ? 0 : "auto"),
            fontSize: (props.fontSize ?? "12px")
        }),
        valueContainer: (base: any) => ({
            ...base,
            padding: "0 8px",
        }),
        // IndicatorsContainer: (base: any) => ({
        //     ...base,
        //     padding: "0",
        //     margin: "0",
        // }),
        // indicatorContainer: (base: any) => ({
        //     ...base,
        //     padding: "0",
        //     margin: "0",
        // }),
        clearIndicator: (base: any) => ({
            ...base,
            padding: "0",
            margin: "0",
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            // padding: "4px",
            padding: "2px",
        }),
        option: (base: any) => ({
            ...base,
            // color: props.options.color?"#333",
        })
    };
    return (
        <div style={{ ...(props.divStyles ?? {}) }}>
            {props.label && <div style={{
                marginBottom: 0,
                fontWeight: (props.labelBold ? "bold" : "normal"),
                ...(props.labelStyles ?? {})
            }}>
                {props.label}
            </div>}
            <Select id={props.id}
                options={options}
                styles={stylesss}
                captureMenuScroll={false} // este parametro es solo para eliiminar [violation] warning
                onInputChange={props.onInputChange}
                onChange={onChange}
                isDisabled={props.disabled ?? false}
                placeholder={props.placeholder}
                isMulti={props.isMulti ?? false}
                closeMenuOnSelect={props.isMulti ? false : true}
                onKeyDown={props.onKeyDown ? props.onKeyDown : () => { }}
                value={props.value}
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'lightskyblue',
                        primary: 'lightskyblue',
                    },
                })}
            />
        </div>
    )
}