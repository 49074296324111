export enum UserActionType {
    LOGIN_SUCCESS = 'LOGIN_SUCCESS', LOGIN_LOADING = 'LOGIN_LOADING', LOGIN_ERROR = 'LOGIN_ERROR',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS', LOGOUT_LOADING = 'LOGOUT_LOADING', LOGOUT_ERROR = 'LOGOUT_ERROR',
    UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS', UPDATE_USER_LOADING = 'UPDATE_USER_LOADING', UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',
    UPDATE_PASS_SUCCESS = 'UPDATE_PASS_SUCCESS', UPDATE_PASS_LOADING = 'UPDATE_PASS_LOADING', UPDATE_PASS_ERROR = 'UPDATE_PASS_ERROR',
    GET_USER_SUCCESS = 'GET_USER_SUCCESS', GET_USER_LOADING = 'GET_USER_LOADING', GET_USER_ERROR = 'GET_USER_ERROR',
    GET_USERS_SUCCESS = 'GET_USERS_SUCCESS', GET_USERS_LOADING = 'GET_USERS_LOADING', GET_USERS_ERROR = 'GET_USERS_ERROR', GET_USERS_REFRESH = 'GET_USERS_REFRESH',
    // Sync values
    SET_FILTERS = "SET_FILTERS", CLEAN_FILTERS = "CLEAN_FILTERS",
    SET_SELECTEDS = "SET_SELECTEDS"
}
export enum CasesActionType {
    // Async values
    GET_CASES_SUCCESS = 'GET_CASES_SUCCESS', GET_CASES_LOADING = 'GET_CASES_LOADING', GET_CASES_ERROR = 'GET_CASES_ERROR', GET_CASES_REFRESH = 'GET_CASES_REFRESH',
    GET_CASE_SUCCESS = 'GET_CASE_SUCCESS', GET_CASE_LOADING = 'GET_CASE_LOADING', GET_CASE_ERROR = 'GET_CASE_ERROR',
    GET_VARIANT_GROUPS_SUCCESS = 'GET_VARIANT_GROUPS_SUCCESS', GET_VARIANT_GROUPS_LOADING = 'GET_VARIANT_GROUPS_LOADING', GET_VARIANT_GROUPS_ERROR = 'GET_VARIANT_GROUPS_ERROR',

    // Sync values
    SET_FILTERS = "SET_FILTERS", CLEAN_FILTERS = "CLEAN_FILTERS",
    SET_SELECTEDS = "SET_SELECTEDS"
}
export enum DomainsActionType {
    // Async values
    GET_DOMAINS_SUCCESS = 'GET_DOMAINS_SUCCESS', GET_DOMAINS_LOADING = 'GET_DOMAINS_LOADING', GET_DOMAINS_ERROR = 'GET_DOMAINS_ERROR', GET_DOMAINS_REFRESH = 'GET_DOMAINS_REFRESH',
    GET_DOMAIN_SUCCESS = 'GET_DOMAIN_SUCCESS', GET_DOMAIN_LOADING = 'GET_DOMAIN_LOADING', GET_DOMAIN_ERROR = 'GET_DOMAIN_ERROR',
    // Sync values
    SET_FILTERS_DOMAINS = "SET_FILTERS_DOMAINS", CLEAN_FILTERS_DOMAINS = "CLEAN_FILTERS_DOMAINS",
    SET_FILTERS_CASES = "SET_FILTERS_CASES", CLEAN_FILTERS_CASES = "CLEAN_FILTERS_CASES",
    SET_FILTERS_USERS = "SET_FILTERS_USERS", CLEAN_FILTERS_USERS = "CLEAN_FILTERS_USERS",
    // SET_SELECTEDS_DOMAINS = "SET_SELECTEDS_DOMAINS",
    SET_SELECTEDS_CASES = "SET_SELECTEDS_CASES",
    SET_SELECTEDS_USERS = "SET_SELECTEDS_USERS"
}

export enum PanelGenesActionType {
    // Async values
    GET_PANELS_SUCCESS = 'GET_PANELS_SUCCESS', GET_PANELS_LOADING = 'GET_PANELS_LOADING', GET_PANELS_ERROR = 'GET_PANELS_ERROR', GET_PANELS_REFRESH = 'GET_PANELS_REFRESH',
    GET_PANEL_SUCCESS = 'GET_PANEL_SUCCESS', GET_PANEL_LOADING = 'GET_PANEL_LOADING', GET_PANEL_ERROR = 'GET_PANEL_ERROR',

    // Sync values
    SET_FILTERS = "SET_FILTERS", CLEAN_FILTERS = "CLEAN_FILTERS",
    SET_SELECTEDS = "SET_SELECTEDS"
}

export enum QueryActionType {
    // ------------------------------------ Async values ------------------------------------
    GET_VARIANT_SUCCESS = 'GET_VARIANT_SUCCESS', GET_VARIANT_LOADING = 'GET_VARIANT_LOADING', GET_VARIANT_REFRESH = "GET_VARIANT_REFRESH",
    GET_VARIANT_ERROR = 'GET_VARIANT_ERROR', CLEAN_VARIANT = 'CLEAN_VARIANT',

    GET_VARIANTS_SUCCESS = 'GET_VARIANTS_SUCCESS', GET_VARIANTS_LOADING = 'GET_VARIANTS_LOADING', GET_VARIANTS_REFRESH = 'GET_VARIANTS_REFRESH',
    GET_VARIANTS_ERROR = 'GET_VARIANTS_ERROR', CLEAN_VARIANTS = 'CLEAN_VARIANTS',

    GET_GENES_VARIANTS_SUCCESS = 'GET_GENES_VARIANTS_SUCCESS', GET_GENES_VARIANTS_LOADING = 'GET_GENES_VARIANTS_LOADING', GET_GENES_VARIANTS_REFRESH = "GET_GENES_VARIANTS_REFRESH",
    GET_GENES_VARIANTS_ERROR = 'GET_GENES_VARIANTS_ERROR', CLEAN_GENES_VARIANTS = 'CLEAN_GENES_VARIANTS',

    GET_PHENOTYPES_SUCCESS = 'GET_PHENOTYPES_SUCCESS', GET_PHENOTYPES_LOADING = 'GET_PHENOTYPES_LOADING', GET_PHENOTYPES_ERROR = 'GET_PHENOTYPES_ERROR',
    GET_QUERY_COMBOS_SUCCESS = 'GET_QUERY_COMBOS_SUCCESS', GET_QUERY_COMBOS_LOADING = 'GET_QUERY_COMBOS_LOADING', GET_QUERY_COMBOS_ERROR = 'GET_QUERY_COMBOS_ERROR',

    // ------------------------------------ Sync values ------------------------------------
    // --- Filters Values ----------------------
    SET_FILTERS_SAMPLES = "SET_FILTERS_SAMPLES", CLEAN_FILTERS_SAMPLES = "CLEAN_FILTERS_SAMPLES",
    SET_FILTERS_VARIANTS = "SET_FILTERS_VARIANTS", CLEAN_FILTERS_VARIANTS = "CLEAN_FILTERS_VARIANTS",
    SET_FILTERS_QC = "SET_FILTERS_QC", CLEAN_FILTERS_QC = "CLEAN_FILTERS_QC",
    SET_FILTERS_FREQUENCY = "SET_FILTERS_FREQUENCY", CLEAN_FILTERS_FREQUENCY = "CLEAN_FILTERS_FREQUENCY",
    SET_FILTERS_PREDICTORS = "SET_FILTERS_PREDICTORS", CLEAN_FILTERS_PREDICTORS = "CLEAN_FILTERS_PREDICTORS",
    SET_FILTERS_GENES = "SET_FILTERS_GENES", CLEAN_FILTERS_GENES = "CLEAN_FILTERS_GENES",
    SET_FILTERS_PHENOTYPES = "SET_FILTERS_PHENOTYPES", CLEAN_FILTERS_PHENOTYPES = "CLEAN_FILTERS_PHENOTYPES",
    SET_FILTERS_VARIANTGROUPS = "SET_FILTERS_VARIANTGROUPS", CLEAN_FILTERS_VARIANTGROUPS = "CLEAN_FILTERS_VARIANTGROUPS",
    SET_FILTERS_CONSEQUENCE = "SET_FILTERS_CONSEQUENCE", CLEAN_FILTERS_CONSEQUENCE = "CLEAN_FILTERS_CONSEQUENCE",
    SET_FILTERS_EVIDENCE = "SET_FILTERS_EVIDENCE", CLEAN_FILTERS_EVIDENCE = "CLEAN_FILTERS_EVIDENCE",
    SET_FILTERS_MITOCHONDRIAL = "SET_FILTERS_MITOCHONDRIAL", CLEAN_FILTERS_MITOCHONDRIAL = "CLEAN_FILTERS_MITOCHONDRIAL",
    SET_FILTERS_SOMATIC = "SET_FILTERS_SOMATIC", CLEAN_FILTERS_SOMATIC = "CLEAN_FILTERS_SOMATIC",
    SET_FILTERS_PHARMGKB = "SET_FILTERS_PHARMGKB", CLEAN_FILTERS_PHARMGKB = "CLEAN_FILTERS_PHARMGKB",
    // ------------------------------------
    SET_BADGES = "SET_BADGES", CLEAN_BADGES = "CLEAN_BADGES",
    SET_TABS = "SET_TABS", SET_TAB_SELECTED = "SET_TAB_SELECTED",
    SET_SCROLLYPOSITION = "SET_SCROLLYPOSITION"
}
export enum ReportedVariantsActionType {
    // Async values
    GET_VARIANTS_SUCCESS = 'GET_VARIANTS_SUCCESS', GET_VARIANTS_LOADING = 'GET_VARIANTS_LOADING', GET_VARIANTS_ERROR = 'GET_VARIANTS_ERROR', GET_VARIANTS_REFRESH = 'GET_VARIANTS_REFRESH',
    GET_VARIANT_SUCCESS = 'GET_VARIANT_SUCCESS', GET_VARIANT_LOADING = 'GET_VARIANT_LOADING', GET_VARIANT_ERROR = 'GET_VARIANT_ERROR',

    // Sync values
    SET_FILTERS = "SET_FILTERS", CLEAN_FILTERS = "CLEAN_FILTERS",
    SET_SELECTEDS = "SET_SELECTEDS"
}

export enum CNVsActionType {
    // Async values
    GET_CNVS_SUCCESS = 'GET_CNVS_SUCCESS', GET_CNVS_ERROR = 'GET_CNVS_ERROR', GET_CNVS_LOADING = 'GET_CNVS_LOADING',
    // Sync values
    SET_FILTERS = "SET_FILTERS", CLEAN_FILTERS = "CLEAN_FILTERS",
    SET_SORT_COLUMN = "SET_SORT_COLUMN"
}


export enum LangActionType {
    SET_LANGUAGE = 'SET_LANGUAGE'
}
export enum ThemeActionType {
    CHANGE_THEME = 'CHANGE_THEME'
}

