import React from 'react';
import { useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import QueryContext, { TableTitleI } from '../../../../Contexts/Query/QueryContext';
import { AsyncDataI } from "../../../../Contexts/interfaces";
import { Tooltip } from '../../../Common/Tooltip/Tooltip';
import { VariantsOrderByValueT } from '../../../../utils/BioFunctionalData';
import ThemeContext from '../../../../Contexts/Theme/ThemeContext';
interface VariantsTableHeadersI {
    queryVariants?: AsyncDataI,
    variantSelecteds?: string[],
    tableTitles: TableTitleI[],
    isMitochondrial: boolean,
    handleGetQueryVariant?(pageNumber?: number, pageSize?: number, then?: any): void,
    handleVariantSelecteds(i: string, ii: boolean): void,
}
export default function VariantsTableHeaders(props: VariantsTableHeadersI) {
    const { dispatch: { SetOrderBySS } } = useContext(QueryContext);
    const { theme } = useContext(ThemeContext);
    let sortedAndCheckTable = props.tableTitles
        .filter((x: any) => {
            if (props.isMitochondrial) return (x.checked && x.type !== "classification")
            else return (x.checked && x.type !== "mitoMap")
        })
        .sort((a: any, b: any) => a.order - b.order)

    const tableTitles = sortedAndCheckTable.map((x: any, index: number) => {
        // console.log(props.queryVariants?.data?.variants ?? [])
        let colWidth: any = null
        if ((props.queryVariants?.data?.variants ?? [].length > 0)) {
            // console.log(x)
            const element = document.getElementById(`${x.type}_0`)
            if (element) { colWidth = element.getBoundingClientRect().width ?? colWidth }
        }

        return (
            <th key={index + 1} style={{
                fontSize: "10px",
                verticalAlign: "top",
                wordBreak: "break-word",
                textAlign: (x.type === "sampleInfo" ? "center" : "left"),
                width: colWidth,
                minWidth: colWidth ?? "100px"
            }}>
                <span style={{ verticalAlign: "sub" }}>{(x.title ?? "").toUpperCase()}</span>
                <span style={{ float: "right" }}>{x.sortBy && x.sortBy.length > 0 &&
                    x.sortBy.map((xx: { sort: VariantsOrderByValueT, tooltipLabel: string }, sortIndex: number) => {
                        return (
                            <span key={`${sortIndex}`}>
                                &nbsp;
                                <Tooltip label={xx.tooltipLabel}>
                                    <Button variant="outline-light" style={{
                                        borderRadius: "50px",
                                        padding: "1px 6px 0px",
                                        border: "none",
                                        margin: 0
                                    }} onClick={() => {
                                        SetOrderBySS([xx.sort])
                                        if (props.handleGetQueryVariant) props.handleGetQueryVariant(1)
                                    }}
                                    >
                                        <FontAwesomeIcon icon={faAngleDown} style={{ fontSize: "12px", color: "gray" }} />
                                    </Button>
                                </Tooltip>
                            </span>
                        )
                    })
                }</span>
            </th>
        )
    })
    return (
        <thead>
            <tr style={{ color: theme.grayHeader, backgroundColor: theme.backgroundThird, border: "none" }}>
                <th style={{ verticalAlign: "top", textAlign: "center", width: "37px", maxWidth: "37px", padding: "3px" }}>
                    <Form.Check type="checkbox"
                        style={{ margin: "0 4px", height: "0px" }}
                        checked={props.queryVariants?.data?.variants?.length > 0 && props.queryVariants?.data?.variants?.length === props?.variantSelecteds?.length}
                        onChange={() => { props.handleVariantSelecteds("", true) }}
                    />
                </th>
                {tableTitles}
            </tr>
        </thead>
    )
}
