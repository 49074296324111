import React from 'react';
import { useContext, useEffect, useRef, useState } from 'react'
import { LangContext } from '../../../Contexts/Lang'
import { Formik } from 'formik'
import * as yup from 'yup'
import FormInput from '../../Common/Form/FormInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faKey } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'
import UserServices from '../../../Services/UserServices'
import { FormCard, FormRow } from '../../Common/Form/FormCard'
import { UserI } from '../../../Contexts/interfaces'

interface UserPassFormI {
  history: any
  editUser?: UserI
  editMode?: boolean,
  showTitle?: boolean
  // handleEditState? //cambiar a props.handleClose()(): void
  handleClose?(): void
  handleReload?(): void
}
export default function UserPassForm(props: UserPassFormI) {
  const {
    dispatch: { translate },
  } = useContext(LangContext)
  const US = new UserServices()
  const [initialForm, setInitialForm] = useState<any>('')
  const [mode, setmode] = useState<'read' | 'edit'>(
    (props.editUser || props.editMode) ? 'edit' : 'read',
  )
  const handleMode = () => {
    if (mode === 'read') setmode('edit')
    else setmode('read')
  }
  const readMode = mode === 'read'
  let formRef: any = useRef()
  useEffect(() => {
    if (props.editMode && mode === "read") setmode("edit")
    else if (props.editMode === false && mode === "edit") setmode("read")
    let isMounted = true
    if (isMounted) setInitialForm(initializeValues())
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, props.editMode])

  function initializeValues() {
    let values: any = {
      newPass: '',
      rnewPass: '',
    }
    if (!props.editUser) values = { pass: '', ...values }
    return values
  }

  ///.---------------------------------------------------------------------------------------------

  function createSchema() {
    const pass = yup
      .string()
      .min(8, 'Password must have at lest 8 characters')
      .required(translate('error.required'))
    const newPass = yup
      .string()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        translate('user.changePasswordCondition'),
      )
      .required(translate('error.required'))
    // La contraseña debe tener al menos una letra minúscula, un dígito, una mayúscula y al menos 8 caracteres
    const rnewPass = yup
      .string()
      .required(translate('error.required'))
      .when('newPass', {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref('newPass')], 'Both password must be the same'),
      })

    let schema: any = { newPass, rnewPass }
    if (!props.editUser) schema = { pass, ...schema }
    return yup.object().shape(schema)
  }
  function createForm(values: any, handleChange: any, errors: any) {
    function commonFormInput(key: string, name: string) {
      return (
        <FormInput
          type={'password'}
          labelBold={true}
          labelColor={'gray'}
          key={key}
          formGrouplStyle={{ marginBottom: '0' }} // onKeyDown={(e: any) => handleEnter(e)}
          name={name}
          disabled={readMode}
          required={true}
          requiredFeedback={errors[name]}
          feedbackValidator={!!errors[name]}
          value={values[name]}
          onChange={handleChange}
        />
      )
    }
    const oldPassInput = commonFormInput('passKey', 'pass')
    const newPassInput = commonFormInput('newPassKey', 'newPass')
    const rnewPassInput = commonFormInput('rnewPassKey', 'rnewPass')
    return (
      <form>
        {!props.editUser && (
          <>
            <FormRow
              label={translate(`user.oldPassword`)}
              input={oldPassInput}
              required={true}
            />
            <br />
          </>
        )}
        <FormRow
          label={translate(`user.newPassword`)}
          input={newPassInput}
          required={true}
          subInput={translate('user.changePasswordCondition')}
        />
        <br />
        <FormRow
          label={translate(`user.password`)}
          input={rnewPassInput}
          required={true}
        />
      </form>
    )
  }
  function handleSubmit(data: any, e: any): void {
    if (props.editUser) {
      US.updatePass(
        `${props.editUser.domain}`,
        `${props.editUser.id}`,
        data.newPass,
        data.rnewPass,
      ).then((res: any) => {
        if (res?.data?.errors && res.data.errors.toLowerCase().indexOf("oldpasswordequalsnewone") > -1) {
          formRef?.current?.setErrors({
            newPass: translate("user.errorEqualPass")
          })
        }
        else {
          if (props.handleReload) props.handleReload()
          if (props.handleClose) props.handleClose()
        }
      }).catch((e: any) => {
        console.log(e)
        // if (props.handleReload) props.handleReload()
        // if (props.handleClose) props.handleClose()
      })
    } else {
      US.updateLoggedPass(data.pass, data.newPass, data.rnewPass).then(
        (res: any) => {
          if (res.error || Object.keys(res ?? {}).length > 0) {
            formRef?.current?.setErrors({
              pass: translate('user.invalidPassword'),
            })
          } else {
            formRef?.current?.setValues(initializeValues())
          }
        },
      )
    }
  }
  return (
    <Formik id="UserPassForm"
      initialValues={initialForm}
      validationSchema={createSchema()}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      handleSubmit={console.log}
      innerRef={formRef}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <FormCard
          title={!props.showTitle ? undefined :
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ alignSelf: "center" }}>
                  <FontAwesomeIcon
                    icon={faKey}
                    style={{
                      color: readMode || props.editUser ? 'lightgray' : '#007BFF',
                      fontSize: '20px',
                      marginRight: '10px',
                    }}
                  />
                  {readMode
                    ? translate('user.password')
                    : translate('user.change-password')}
                </div>
                {!props.editUser && (
                  <div style={{ float: 'right' }}>
                    {props.editMode ?
                      <FontAwesomeIcon icon={faEdit}
                        style={{
                          color: "lightgray",
                          fontSize: "20px", padding: 0
                        }} />
                      :

                      <Button
                        variant="outline-primary"
                        style={{
                          border: "none",
                          // padding: 0 
                        }}
                        onClick={handleMode}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{
                            // color: 'lightgray',
                            fontSize: '20px',
                            padding: 0,
                          }}
                        />
                      </Button>
                    }
                  </div>
                )}
              </div>
            </>
          }
          body={createForm(values, handleChange, errors)}
          enableBody={!readMode}
          enableSubmit={!readMode}
          // enableBody={true}
          // enableSubmit={true}
          submit={handleSubmit}
          handleCancel={() => { if (props.handleClose) props.handleClose() }} //cambiar a props.handleClose()
        // handleCancel={() => { }}                //cambiar a props.handleClose()
        // saveLabel={translate("user.savePassword")}
        />
      )}
    </Formik>
  )
}
