import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, ButtonGroup, Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import { LangContext } from "../../../../Contexts/Lang";
import { checkValue, checkValueAndReturnAnother } from "../../../../utils/helpers";
import { LinkGeneCards, LinkGnomAd, LinkNCBI, LinkUniprot, LinkOMIM, LinkENSEMBL, LinkClinGen } from "../../../Common/Links/Links";
import { faDna, faComment, faFileExport, faSortAmountDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ReactSelect from "../../../Common/Form/ReactSelect";
import ReportServices from "../../../../Services/ReportService";
import { useContext, useState } from "react";
import { Tooltip } from "../../../Common/Tooltip/Tooltip";
import { bodyType } from "./QueryGenes";
import DropdownToolkit from "../../../Common/DropdownToolkit";
import { LabelValueI, variantsOrderByOptions } from "../../../../utils/BioFunctionalData";
import VariantServices from "../../../../Services/VariantServices";
import { ModalCommentGroupT, ModalCommentGroup } from "../../../Common/ModalCommentGroup/ModalCommentGroup";
import { ModalWarning } from "../../../Common/ModalWarning";
import QueryContext, { TableTitleI } from "../../../../Contexts/Query/QueryContext";
import { AsyncDataI } from "src/Contexts/interfaces";
import VariantsTableHeaders from "../VariantsTable/VariantsTableHeaders";
import { getVariantsSelecteds_IdsPosChangeGene } from "src/Components/Common/ModalCommentGroup/VariantIdentificationTableRow";
import CustomAcmgGuideByGene from 'src/Components/Common/CustomAcmgGuideByGene/CustomAcmgGuideByGene';


interface QueryGenesPanelI {
    history: any,   //props.history
    study: any,    //responseData?.study
    gene: any,      //responseData?.gene?
    setbody(x: bodyType): void,
    phenotypes: any[],
    regions: any,
    regionSelected: any,
    setregionSelected: any,
    variantsSelecteds: any[],
    orderBy: any,
    isMitochondrial: boolean,
    handleOrderBy(option: any): void,
    handleReload?(): void,
    handleReloadOnGoBack?(): void,
    queryVariants?: AsyncDataI,
    tableTitles: TableTitleI[],
    handleVariantSelecteds(i: string, ii: boolean): void,
    tableHeadersRef?: React.MutableRefObject<any>,
    handleScrollHeadersTable?(scroll: any): void,
}

export default function QueryGenesPanel(props: QueryGenesPanelI) {
    const { genesVariants } = useContext(QueryContext);
    const { dispatch: { translate } } = useContext(LangContext);
    const initOrderByOptions = () => {
        let orderByOpt: any = variantsOrderByOptions(translate)
        if (props.isMitochondrial) {
            orderByOpt = orderByOpt.filter((x: any) => x.value !== "infoColumnData.INTERVAR_VEREDICT" && x.value !== "infoColumnData.GNOMAD_GENOME_ALL" && x.value !== "infoColumnData.GNOMAD_EXOME_ALL")
        }
        return orderByOpt
    }
    const orderByOpt = initOrderByOptions()
    const VariantS = new VariantServices();
    const [removeCommentModal, setremoveCommentModal] = useState<{ show: boolean, variants: number[] }>({ show: false, variants: [] });
    const handleCloseRemoveCommentModal = () => { setremoveCommentModal({ show: false, variants: [] }) }
    const handleShowRemoveCommentModal = (variants: number[]) => { setremoveCommentModal({ show: true, variants: variants }) }
    const handleUncomentVariant = () => {
        if (props.handleReloadOnGoBack) props.handleReloadOnGoBack();
        VariantS.uncomment_variants(props.study.id, props.variantsSelecteds)
            .then((res: any) => {
                if (res.status === 200) {
                    handleCloseRemoveCommentModal();
                    if (props.handleReload) props.handleReload();
                }
            }).catch((e: any) => {
                console.log("e")
                console.log(e)
            })
    }

    const initFormikModal = { show: false, type: "comment" as ModalCommentGroupT, data: undefined, editData: undefined }
    const [commentGroupModal, setcommentGroupModal] = useState<{ show: boolean, type: ModalCommentGroupT, data: any, editData: any }>(initFormikModal);
    const handleCloseCommentGroupModal = () => { setcommentGroupModal(initFormikModal) }
    const handleShowCommentGroupModal = (type: ModalCommentGroupT, data: any, editData?: any) => {
        setcommentGroupModal({ show: true, type: type, data: data, editData: editData })
    }
    const getConcatedComents = (commentIds: string[]) => {
        if (commentIds.length > 1) {
            let comments = commentIds.map((x: any) => props.study.comments[x].comment)
            let commentsConcatedLowered = comments.map((x: any, i: number) => {
                return [x.toLowerCase().replace(/ /g, '').replace(/"\n"/g, '').replace(/(\r\n|\n|\r)/gm, ""), i]
            });
            let setComments = new Set(commentsConcatedLowered.map((x: any) => x[0]));
            let filteredIndex = Array.from(setComments.values())
                .map((x: any) => (commentsConcatedLowered.find((y: any) => y[0] === x) ?? ["", ""])[1])
            if (filteredIndex.length > 1)
                return comments.filter((x: any, i: number) => filteredIndex.indexOf(i) > -1).map((x: any) => { return `* ${x}` }).join("\n")
            return comments.filter((x: any, i: number) => filteredIndex.indexOf(i) > -1).join("\n")
        }
        else {
            if (props.study.comments[commentIds[0]])
                return props.study.comments[commentIds[0]].comment
            else
                return ""
        }
    }
    const getStateComents = (commentIds: string[]) => {
        let states = commentIds.map((x: any) => props.study.comments[x].state)
        function getMode(array: any[]) {
            var frequency: any = []; // array of frequency.
            var maxFreq = 0; // holds the max frequency.
            var modes: any = [];
            for (var i in array) {
                frequency[array[i]] = (frequency[array[i]] || 0) + 1; // increment frequency.
                if (frequency[array[i]] > maxFreq)  // is this frequency > max so far ?
                    maxFreq = frequency[array[i]]; // update max.
            }
            for (var k in frequency) if (frequency[k] === maxFreq) modes.push(k);
            return modes[0];
        }
        const mode = getMode(states)
        return mode
    }


    function getExportDropdownItems() {
        const ReportS = new ReportServices();
        const orderByVal = props.orderBy.length > 0 ? props.orderBy[0]?.value : undefined;

        let exportDropdownItems: any = [

            {
                label: `${translate("common.selectedVariants")} (xls)`,
                href: ReportS.get_downloadSelectedXls_link(`&studyId=${props.study?.id}`, (props.variantsSelecteds ?? []).join(), orderByVal),
                disabled: props.variantsSelecteds.length === 0
            },
            {
                label: `${translate("common.selectedVariants")} (vcf)`,
                href: ReportS.get_downloadSelectedVariantsToVcf_link(props.study?.id, (props.variantsSelecteds ?? []).join()),
                disabled: props.variantsSelecteds.length === 0
            }
        ]
        return exportDropdownItems;
    }
    const exportDropdownItems = getExportDropdownItems()

    const getLabelValue = (label: string, value: string | JSX.Element) => {
        return (
            <>
                <strong style={{ color: "#444B59" }}>
                    {label}: &nbsp;
                </strong>
                {typeof value === "string" ? checkValue(value) : value}
                <br />
            </>
        )
    }

    const getLabelValueInfo = (label: string, value: string, info: string) => {
        return (
            <Tooltip label={info}>
                <span style={{ fontSize: "12px" }}>
                    <span style={{}}>
                        {label}: &nbsp;
                    </span>
                    <span style={{}}>
                        {value}%&nbsp;
                        <FontAwesomeIcon icon={faInfoCircle} style={{ color: "gray" }} />
                    </span>
                </span>
            </Tooltip >
        )
    }

    const getRegionsOptions = (): LabelValueI[] => {
        if (!props.gene?.regions) return []
        return Object.keys(props.gene.regions)
            .map((x: any, i: number) => { return { label: x, value: x } })
    }
    const regionOptions = getRegionsOptions()

    const getWidthOrderByOptions = () => {
        const orderByOptFiltered = (orderByOpt ?? []).filter((x: any) => props.orderBy.find((y: any) => { return (x?.value ?? "    ") === y?.value }))
        if (orderByOptFiltered.length === 0) return "120px"
        const orderByOptReduced = orderByOptFiltered.map((x: any) => (x?.label ?? "     ").length * 6)
            .reduce((accum: number, curr: number) => accum + curr)
        return `${100 + props.orderBy.length * 38 + (props.orderBy.length === 0 || !props.orderBy[0] ? 0 : (orderByOptReduced))}px`
    }
    return (
        <>
            <ModalWarning labelTitle={translate('common.confirm')}
                label={translate('casesQuery.doYouWantToRemoveCommentsOnTheSelectedVariants')}
                labelButton={translate('common.delete')}
                showState={removeCommentModal.show}
                handleClose={handleCloseRemoveCommentModal}
                onClick={handleUncomentVariant}
            />
            <ModalCommentGroup show={commentGroupModal.show}
                handleClose={handleCloseCommentGroupModal}
                type={commentGroupModal.type}
                data={commentGroupModal.data}
                editData={commentGroupModal.editData}
                studyId={`${props?.study?.id}`}
                handleReload={() => {
                    if (props.handleReloadOnGoBack) props.handleReloadOnGoBack();
                    if (props.handleReload) props.handleReload()
                }}
            />
            <div id="QueryGenesPanel" style={{ padding: "10px" }} >
                <h4 style={{ fontWeight: "bold", color: "#444B59" }}>
                    &nbsp;
                    <FontAwesomeIcon icon={faDna} style={{ fontSize: "18px", margin: "1px", color: "dimgray" }} />
                    &nbsp;
                    {props.gene?.geneName ? `${props.gene?.geneName}` : " - "}
                </h4>
                <Row style={{ margin: "5px 0px", backgroundColor: "#F6F6F6", border: "solid 1px lightgray" }}>
                    <Col md={6}>
                        {getLabelValue(translate("casesQueryGenes.approvedName"), props.gene?.descriptor?.approvedsymbol)}
                        {getLabelValue(translate("common.previoussymbols"), props.gene?.descriptor?.previoussymbols)}
                        {getLabelValue(translate("common.synonyms"), props.gene?.descriptor?.synonyms)}
                    </Col>
                    <Col md={6}>
                        {getLabelValue(translate("common.references"), <>
                            {checkValueAndReturnAnother(props.gene?.descriptor?.uniprotid,
                                <>
                                    <LinkUniprot fontSize={"13px"} uniprotId={props.gene?.descriptor?.uniprotid} />, &nbsp;
                                </>
                            )}
                            {checkValueAndReturnAnother(props.gene?.descriptor?.ensemblid,
                                <>
                                    <LinkENSEMBL fontSize={"13px"} transcriptEnst={props.gene?.descriptor?.ensemblid} label={"Ensembl"} />, &nbsp;
                                </>
                            )}
                            {checkValueAndReturnAnother(props.gene?.descriptor?.omimid,
                                <>
                                    <LinkOMIM fontSize={"13px"} omimId={props.gene?.descriptor?.omimid} />, &nbsp;
                                </>
                            )}
                            <LinkGeneCards fontSize={"13px"} geneName={props.gene?.geneName} />, &nbsp;
                            <LinkNCBI fontSize={"13px"} geneName={props.gene?.geneName} />
                        </>)}
                        {getLabelValue("gnomAD Browser ", <LinkGnomAd fontSize={"13px"} geneName={props.gene?.geneName ?? "-"} label={props.gene?.geneName ?? "-"} />)}
                        {getLabelValue("ClinGen ",
                            <>
                                <LinkClinGen fontSize={"13px"} clingenId={props.gene?.descriptor?.hgncId} label={props.gene?.geneName} />
                                <CustomAcmgGuideByGene geneName={props.gene?.geneName} />
                            </>
                        )}
                        {/* ClinGen:F12<br /> */}
                    </Col>
                </Row>
            </div>
            <Tabs defaultActiveKey="variants" id="uncontrolled-tab-example" style={{ marginLeft: "10px" }}
                onSelect={(x: any) => { props.setbody(x as bodyType) }}
            >
                <Tab eventKey="variants"
                    style={{ padding: "5px 10px 5px", backgroundColor: "#f4f4f4" }}
                    title={<>
                        {translate("common.variants")} &nbsp;
                        <BadgeAmountData value={(props.gene?.variants ?? []).length} bold={true} />
                    </>
                    }>
                    <Row style={{ margin: 0, padding: 0, display: "block" }}>
                        <ButtonGroup style={{ backgroundColor: "white", height: "32px" }}>
                            <DropdownToolkit icon={faComment}
                                tooltip={translate("casesQuery.commentVariants")}
                                disabled={props.variantsSelecteds.length === 0}
                                items={[
                                    {
                                        label: translate("common.comment"),
                                        onClick: () => {
                                            let commenIds: any = (Object.keys(genesVariants.data?.study?.comments) ?? [])
                                                .filter((x: any) => props.variantsSelecteds.filter((xx: any) => xx.indexOf(x) > -1).length > 0)
                                            handleShowCommentGroupModal("comment",
                                                { variants: getVariantsSelecteds_IdsPosChangeGene(props.queryVariants?.data?.variants, props.variantsSelecteds) },
                                                {
                                                    comment: genesVariants.data?.study?.comments && commenIds ?
                                                        getConcatedComents(commenIds) ?? undefined : undefined,
                                                    state: genesVariants.data?.study?.comments && commenIds ?
                                                        getStateComents(commenIds) ?? undefined : undefined
                                                }
                                            )
                                        },
                                    },
                                    {
                                        label: translate("casesQuery.removeComment"),
                                        onClick: () => {
                                            handleShowRemoveCommentModal(props.variantsSelecteds)
                                        }
                                    }
                                ]}
                            // disabled={variantsSelecteds.length === 0}
                            />
                            <DropdownToolkit icon={faFileExport}
                                tooltip={translate("common.export")}
                                items={exportDropdownItems}
                                disabled={props.variantsSelecteds.length === 0}
                            // items={[]}
                            />
                        </ButtonGroup>
                        <div style={{ display: "flex", float: "right", margin: 0 }}>
                            <ReactSelect options={orderByOpt}
                                onChange={(e: any) => props.handleOrderBy(e)}
                                value={props.orderBy}

                                isMulti={true}
                                divStyles={{ margin: 0 }}
                                width={getWidthOrderByOptions()}
                            />
                            <Tooltip label={translate("common.sortBy")}>
                                <Button variant="outline-secondary"
                                    style={{ height: "32px", padding: "0 8px", borderColor: "lightgray", marginLeft: "-1px" }}
                                    onClick={() => {
                                        if (props.handleReload) props.handleReload()
                                    }}>
                                    <FontAwesomeIcon icon={faSortAmountDown} style={{ fontSize: "17px" }} />
                                </Button>
                            </Tooltip>
                        </div>
                    </Row>
                    <Row id="queryBodyToolkitRow"
                        ref={props.tableHeadersRef}
                        onScrollCapture={(e: any) => { if (props.handleScrollHeadersTable) props.handleScrollHeadersTable(e); }}

                        style={{ margin: "5px -10px -5px -10px", padding: 0, overflowX: "auto" }}>
                        <Table style={{ margin: 0 }} id={"queryBodyToolkitRow_QueryVariantsHeaders"}>
                            <VariantsTableHeaders queryVariants={props.queryVariants}
                                variantSelecteds={props.variantsSelecteds}
                                tableTitles={props.tableTitles}
                                isMitochondrial={props.isMitochondrial}
                                handleVariantSelecteds={props.handleVariantSelecteds}
                            />
                        </Table>
                    </Row>
                </Tab>
                {Object.keys(props.gene?.regions ?? {}).length > 0 &&
                    <Tab eventKey="regions"
                        style={{ padding: "5px 10px 5px", backgroundColor: "#f4f4f4" }}
                        title={
                            <>
                                {translate("casesQueryGenes.regions")} &nbsp;
                                <BadgeAmountData value={`${(props.regionSelected?.regions ?? []).length}`} bold={true} />
                            </>
                        }>
                        {/* <> */}
                        <Row style={{ margin: 0, justifyContent: "space-between", minHeight: "32px" }}>
                            <div style={{ fontWeight: "bold", color: "#444B59", float: "left", paddingTop: "5px" }}>
                                {Object.keys(props.gene?.regions)[0]} - {props.regionSelected?.genename}
                            </div>
                            {(regionOptions ?? []).length > 1 &&
                                <div style={{ float: "right" }}>
                                    <ReactSelect options={regionOptions}
                                        onChange={(e: any) => {
                                            console.log("e")
                                            console.log(e)
                                            // if ()
                                        }}
                                        value={regionOptions[0]}
                                        // isMulti={true}
                                        width={"150px"}
                                    />

                                </div>
                            }
                        </Row>
                        {getLabelValueInfo("PCT Coverage", props.regionSelected?.pct20xAllExons ?? "", translate("common.pctCoverageInfo"))} &nbsp;|&nbsp;
                        {getLabelValueInfo("PCT_AT_20X", props.regionSelected?.pct20xAllTargets ?? "", translate("common.pctAt20XInfo"))}&nbsp;|&nbsp;
                        {getLabelValueInfo("PCT_AE_20X", props.regionSelected?.pctgeneCoverage ?? "", translate("common.pctAe20XInfo"))}
                        {/* </> :
                        <div style={{
                            minHeight: "50px",
                            textAlign: "center",
                            paddingTop: "15px",
                            fontSize: "12px"
                        }}>
                            Los datos de cobertura no han sido cargados
                        </div> */}
                    </Tab>
                }
                {(props?.phenotypes ?? []).length > 0 &&
                    <Tab eventKey="phenotypes"
                        style={{ padding: "2px", background: "linear-gradient(to top, rgba(220,220,220, 1), rgba(244,244,244,1))" }}
                        title={
                            <>
                                {translate("common.phenotypes")} &nbsp;
                                <BadgeAmountData value={`${(props?.phenotypes ?? []).length}`} bold={true} />
                            </>
                        }>
                    </Tab>
                }
            </Tabs >
            <Row>
            </Row>
        </>
    )
}


interface BadgeAmountDataI {
    value: string,
    variant?: string,
    fontSize?: string
    bold?: boolean
}
function BadgeAmountData(props: BadgeAmountDataI) {
    return (
        <Badge variant={props.variant ?? "secondary"} pill
            style={{
                fontSize: props.fontSize ?? "12px",
                fontWeight: props.bold ? "bold" : "inherit"
            }} >
            {props.value}
        </Badge>
    )
}

export type { bodyType }