import React from 'react';
import { useContext } from 'react';
import {  Card, Table } from 'react-bootstrap';
import { LangContext } from "../../../../Contexts/Lang";
import "./styles.css"


interface IntersectionTableI {
    data : any
}

export default function IntersectionTable(props: IntersectionTableI) {
    const { dispatch: { translate } } = useContext(LangContext);
    // console.log("props.data intersectionTable")
    // console.log(props.data)
    return (
        <Card style = {{margin: "10px"}}>
            <Table responsive className="statisticTable">
                <tbody>
                    <tr>
                        <td>
                            #{translate('casesDetail.shared')}
                        </td>
                        <td>
                            {props.data?.Identity ??"-"}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            #dbSNP
                        </td>
                        <td>
                            {props.data?.IdentitydbSNPVariants ??"-"}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            #{translate('casesDetail.coding')}
                        </td>
                        <td>
                            {props.data?.IdentityCodingVariants ??"-"}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            #{translate('casesDetail.nonSynonymous')}
                        </td>
                        <td>
                            {props.data?.IdentityCodingNonSynonimVariants ??"-"}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {translate('casesDetail.rarenonsynonymous')}
                        </td>
                        <td>
                            {props.data?.IdentityRareNonSynonimVariants ??"-"}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    )
}
