import React from 'react';
import { Table, Form, Row, Button } from 'react-bootstrap';
import "../../Common/table.css"
import { LinkENSEMBL, NcbiDbsnpLinksByVcfId } from '../../Common/Links/Links';
import BadgeVariantImpact from '../../Common/Badges/BadgeVariantImpact';
import CopyToClipboard from '../../Common/CopyToClipboard';
import { useContext, useState } from 'react';
import { LangContext } from '../../../Contexts/Lang';
import { Tooltip } from '../../Common/Tooltip/Tooltip';
import { AsyncDataI, StudyI } from '../../../Contexts/interfaces';
import Loader from '../../Common/Loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Urlify } from '../../../Helpers/Helpers';
import ModalAcmgCalc, { AcmgCalcDataI } from '../../Common/AcmgCalc/ModalAcmgCalc';
import { commentStatesColors } from '../../../utils/BioFunctionalData';
import ThemeContext from '../../../Contexts/Theme/ThemeContext';
import BadgeVariantEvidence from '../../Common/Badges/BadgeVariantEvidence';
import BadgeVariantVeredict from 'src/Components/Common/Badges/BadgeVariantVeredict';

interface CommentsTableI {
    history: any,
    comments: AsyncDataI,
    // variants: any,
    // handleFilter: any,
    // variantsLoading: any,
    variantsSelecteds: any,
    handleVariantsSelecteds?: any,
    handleReload(): void,
    handleCommentVariant(variantId: string): void,
    handleDeleteComment(variantId?: string[]): void,
    studyId: string
}
export default function CommentsTable(props: CommentsTableI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const initAcmgCalculator = { show: false, data: undefined }
    const [acmgCalculator, setacmgCalculator] = useState<{ show: boolean, data: AcmgCalcDataI | undefined }>(initAcmgCalculator);
    const handleCloseacmgCalculator = () => { setacmgCalculator(initAcmgCalculator) }
    const handleShowacmgCalculator = (veredict: string, evidence: string[], variantId: string, acmgBayScore: string, acmgBayVeredict: string, title: JSX.Element) => {
        setacmgCalculator({ show: true, data: { veredict, evidence, variantId, acmgBayScore, acmgBayVeredict, title } })
    }
    let rows: any = []
    if (!props.comments.loading)
        rows = (props.comments?.data?.results ?? []).map((x: any, i: number) =>
            <TableRow key={i}
                actualRow={x}
                study={props.comments.data.study}
                variantsSelecteds={props.variantsSelecteds}
                handleVariantsSelecteds={props.handleVariantsSelecteds}
                handleReload={props.handleReload}
                history={props.history}
                handleCommentVariant={props.handleCommentVariant}
                handleDeleteComment={props.handleDeleteComment}
                handleShowacmgCalculator={handleShowacmgCalculator}
                studyId={props.studyId}
            />
        )
    const columnTitleStyle = { padding: 4, fontSize: 14 }
    return (
        <>
            <ModalAcmgCalc studyId={props.studyId}
                show={acmgCalculator.show}
                handleClose={handleCloseacmgCalculator}
                data={acmgCalculator.data}
            />

            {
                !props.comments.loaded && !props.comments.loading ?
                    <Loader onContainer={true} type={'DNA'} /> :
                    <Table responsive="md" className="selectTable" style={{ marginBottom: "-1px" }}>
                        <thead>
                            <tr style={{ color: "dimgray", "backgroundColor": "#FAFAFA", margin: 0, padding: "5px" }}>
                                <th style={columnTitleStyle} ></th>
                                <th style={columnTitleStyle} >{translate("commentedVariants.comments")}</th>
                                <th style={columnTitleStyle} >{translate("common.variant")}</th>
                                <th style={columnTitleStyle} >{translate("common.genes")}</th>
                                <th style={columnTitleStyle} >{translate("common.impactEffect")}</th>
                                <th style={columnTitleStyle} >{translate("common.classification")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
            }
        </>
    )
}

interface TableRowI {
    studyId: any,
    study: StudyI,
    actualRow: any,
    variantsSelecteds: any[],
    handleVariantsSelecteds?: any,
    handleReload: any,
    history: any,
    handleCommentVariant(variantId: string): void,
    handleDeleteComment(variantId?: string[]): void,
    handleShowacmgCalculator(veredict: string, evidence: string[], variantId: string, acmgBayScore: string, acmgBayVeredict: string, title: JSX.Element): void
}

function TableRow(props: TableRowI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);

    // const getDecisionLabel = (decision: string) => {
    //     let type = "normal";
    //     switch (decision.trim().toLowerCase()) {
    //         case "reported": type = "bold"; break;
    //         case "discarded": type = "italic"; break;
    //     }
    //     return (
    //         <label style={{
    //             fontWeight: type === "bold" ? "bold" : undefined,
    //             fontStyle: type === "italic" ? "italic" : undefined,
    //         }}>
    //             {decision}
    //         </label>
    //     )
    // }
    const handleRowClick = () => {
        props.history.push(`/cases/query/variantDetail/${props.studyId}/${props.actualRow.id}`);
    }
    const variantColumn = (
        <td onClick={handleRowClick} style={{ wordBreak: "break-word" }}>
            <LinkENSEMBL chromosome={props.actualRow.chromosome}
                position={props.actualRow.position}
                genomeRef={props.study.genomeReference}
                fontSize={"12px"} />
            <CopyToClipboard valueToCopy={`${props.actualRow.chromosome}:${props.actualRow.position}`} /> <br />
            <strong style={{ fontSize: "11px" }}>
                {props.actualRow.reference} {" > "} {props.actualRow.alternative}
            </strong>
            <CopyToClipboard valueToCopy={`${props.actualRow.reference} > ${props.actualRow.alternative}`} /> <br />
            {/* <hr style={{ margin: "3px 0" }} /> */}
            <label style={{ fontSize: "11px" }}>
                {
                    props.actualRow?.codon && <>
                        {props.actualRow.codon}
                        <CopyToClipboard valueToCopy={props.actualRow.codon} /> <br />
                    </>
                }
                {
                    props.actualRow?.infoColumnData?.CODON_CHANGE && <>
                        {props.actualRow.infoColumnData.CODON_CHANGE}
                        <CopyToClipboard valueToCopy={props.actualRow.infoColumnData.CODON_CHANGE} /> <br />
                    </>
                }
            </label>
        </td>
    );
    const genColumn = (
        <td onClick={handleRowClick}
        //style={{ "width": "160px" }}
        >
            <strong>
                {props.actualRow.infoColumnData.GENE_NAME}
            </strong>
            <CopyToClipboard valueToCopy={props.actualRow.infoColumnData.GENE_NAME} />
            <br />
            {props.actualRow.vcfId && (props.actualRow.vcfId ?? "").trim().length > 2 ?
                <NcbiDbsnpLinksByVcfId vcfIds={props.actualRow.vcfId} copyToClipboard={true} /> :
                " - "
            } <br />
        </td>
    );
    const getCommentColumn = () => {
        let commentType = props.actualRow.state;
        let color = "gray"
        if (Object.keys(commentStatesColors).indexOf(commentType) > -1) color = commentStatesColors[commentType.toUpperCase()]
        // commentType = commentType.toLowerCase().replaceAll("_", " ");
        // translate(`common.relevanceOptions.${commentType.toUpperCase()}`)
        // commentType = commentType[0].toUpperCase() + commentType.slice(1);
        const getIconTooltipButton = (label: string, icon: any, onClick?: any) => {
            return (
                <Tooltip label={label} placement={"top"}>
                    <Button variant="outline-light"
                        style={{ border: "none", borderRadius: "50px", padding: "3px 6px", margin: "0 1px" }}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            if (onClick) onClick()
                            // props.handleShowCommentGroupModal("comment", { variantsId: [props.variantId] })
                        }}
                    >
                        {/* <FontAwesomeIcon icon={faEdit} style={{ border: "none", fontSize: "10px", float: "right" }} /> */}
                        <FontAwesomeIcon icon={icon} style={{ fontSize: "11px", color: "gray" }} />
                    </Button>
                </Tooltip>
            )
        }
        let date;
        if (props.actualRow?.dateCreated)
            date = new Date(props.actualRow?.dateCreated ?? "").toISOString().replaceAll("-", "/").replace("T", " ").split(".")[0].split(" ")[0];
        return (
            <td onClick={handleRowClick} style={{ wordBreak: "break-word" }}>
                <Row style={{ margin: 0, padding: 0, justifyContent: "space-between" }}>
                    <span style={{ color: color, fontSize: "15px", fontWeight: "bold" }}>
                        {translate(`common.relevanceOptions.${commentType.toUpperCase()}`)}
                        {date &&
                            <span style={{ margin: 0, fontSize: "10px", color: "gray", fontWeight: "lighter" }}>
                                &nbsp;|&nbsp;{date}&nbsp;
                            </span>
                        }
                    </span>
                    <span>
                        {getIconTooltipButton(translate("common.edit"), faEdit, () => {
                            props.handleCommentVariant(props.actualRow?.id ?? "")
                        })}
                        {getIconTooltipButton(translate("common.delete"), faTrash, () => { props.handleDeleteComment([props.actualRow?.id ?? ""]) })}
                    </span>
                </Row>
                <hr style={{ margin: "2px 0px" }} />
                <Row style={{ margin: 0, padding: 0, alignItems: "self-end" }}>
                    <Urlify text={props.actualRow.comment ?? ""} />
                    <CopyToClipboard valueToCopy={props.actualRow.comment} />
                </Row>
            </td>

        );
    }
    const impact = props.actualRow.infoColumnData?.IMPACT
    const effect = props.actualRow.infoColumnData?.EFFECT
    const impactEffectColumn = (
        <td key={`ImpactEffect_${"lalala"}`}
        // style={{ "width": "140px" }}
        //onClick={() => handleGoVariantsDetails(rv.id)}
        >
            {impact && <Row style={{ margin: 0, padding: 0 }}>
                <BadgeVariantImpact impact={impact} />
            </Row>}
            {
                effect && <Row style={{ margin: 0, padding: 0 }}>
                    {effect}
                </Row>
            }
            {
                (props.actualRow?.infoColumnData?.BAYESDEL_ADDAF_SCORE ||
                    props.actualRow?.infoColumnData?.REVELSCORE) &&
                <hr style={{ margin: "4px 0px" }} />
            }
            {
                props.actualRow?.infoColumnData?.BAYESDEL_ADDAF_SCORE &&
                < Row style={{ margin: 0, padding: 0 }}>
                    <strong>
                        BayesDel addAF:
                    </strong>
                    &nbsp; {props.actualRow?.infoColumnData?.BAYESDEL_ADDAF_SCORE}
                </Row>
            }
            {
                props.actualRow?.infoColumnData?.REVELSCORE &&
                < Row style={{ margin: 0, padding: 0 }}>
                    <strong>
                        Revel score:
                    </strong>
                    &nbsp; {props.actualRow?.infoColumnData?.REVELSCORE}
                </Row>
            }
        </td >
    )
    const get_Column_Classification = () => {
        const acmg_bayesian_veredict = props.actualRow?.infoColumnData?.ACMG_BAYESIAN_VEREDICT;
        const acmg_bayesian_score = props.actualRow?.infoColumnData?.ACMG_BAYESIAN_SCORE;
        let intervar_evidence = (props.actualRow.infoColumnData?.INTERVAR_EVIDENCE ?? "").split(",");
        let intervar_veredict = props.actualRow.infoColumnData?.INTERVAR_VEREDICT;
        // const change = (props.actualRow.infoColumnData?.CODON_CHANGE ?? "").toUpperCase().replaceAll(/[0-9]/g, '').replaceAll("+", "").replace("C.", "").replace("-", "")
        // const rsArray = (props.actualRow.vcfId ?? "").split(";").filter((x: string) => x.toLowerCase().trim().startsWith("rs"))
        const getEvidenceData = () => {
            let result: any = ""
            if (intervar_veredict || intervar_evidence.length > 1) {
                result = (<div key={"commonSource"}>
                    {/* <ButtonAcmgCalc handleShowacmgCalculator={props.handleShowacmgCalculator}
                        intervar_veredict={intervar_veredict}
                        intervar_evidence={intervar_evidence}
                        variantId={props.actualRow.id}
                        acmgBayScore={acmg_bayesian_score}
                        acmgBayVeredict={acmg_bayesian_veredict}
                        chromosome={props.actualRow.chromosome}
                        position={props.actualRow.position}
                        change={change}
                        geneName={props.actualRow.infoColumnData.GENE_NAME}
                        studyId={props.studyId}
                        rsArray={rsArray}
                    /> */}
                    ACMG/AMP 2015:<br />
                    {intervar_veredict &&
                        <span key={"lalala"}>
                            <BadgeVariantVeredict labelInput={intervar_veredict}
                                id={0}
                                size={"big"}
                            />
                            <br />
                        </span>
                    }
                    {intervar_evidence.map((x: any, i: number) => {
                        return (
                            <span key={`${i + 100}`}>
                                <BadgeVariantEvidence labelInput={x}
                                    id={i + 1}
                                    fontSize={10} />
                            </span>
                        )
                    })}
                </div>);
            }
            if (acmg_bayesian_veredict || acmg_bayesian_score) {
                result = (<>
                    {result}
                    {result !== "" && <hr style={{ margin: "3px 0" }} />}
                    {translate("common.bayesian")}: {acmg_bayesian_score}<br />
                    {acmg_bayesian_veredict &&
                        <span key={5747}>
                            <BadgeVariantVeredict labelInput={acmg_bayesian_veredict}
                                id={9999}
                                size={"big"} />
                            <br />
                        </span>}
                </>
                );
            }
            return result
        }
        return (
            <td key={`Acmg_${"aaaa"}`}    // onClick={() => handleGoVariantsDetails(rv.id)}
            // style={{ "width": "180px" }}
            >
                {(intervar_veredict || intervar_evidence.length > 1) && <>
                    {getEvidenceData()}
                </>
                }
            </td>
        )
    }

    return (
        <tr style={{
            borderBottom: "1px solid lightgray",
            fontSize: "12px",
            color: theme.grayHeader,
            cursor: "pointer"
        }}>
            <td style={{ border: "none", width: "35px", verticalAlign: "middle", textAlign: "center" }}>
                <Form.Check type="checkbox"
                    value={props?.variantsSelecteds?.find((y: any) => y === props.actualRow.id)}
                    checked={props?.variantsSelecteds?.find((y: any) => y === props.actualRow.id) ?? false}
                    onChange={() => {
                        props.handleVariantsSelecteds(props.actualRow.id)
                    }}
                />
            </td>
            {getCommentColumn()}
            {variantColumn}
            {genColumn}
            {impactEffectColumn}
            {get_Column_Classification()}
        </tr>
    )
}

