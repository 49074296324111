import React, { useReducer } from "react";
import ReportedVariantsContext, { ContextReportedVariantsStatesI, FiltersReportedVariantsI } from "./ReportedVariantsContext";
import ReportedVariantsReducer from "./ReportedVariantsReducer";
import { ReportedVariantsActionType } from "../actionsTypes";
import ReportedVariantServices from "../../Services/ReportedVariantService";
import { ContextStateManager } from "../ContextStateManager";

const ReportedVariantsContextState = (props: any) => {
    let ReportedVariantS = new ReportedVariantServices();
    const initialState: ContextReportedVariantsStatesI = {
        variant: { data: null, loaded: false, loading: false, error: false },
        variants: { data: null, loaded: false, loading: false, error: false, refresh: false },
        selecteds: [],
        filters: {
            geneName: "",
            dbsnpId: "",
            decision: null,
            classification: "",
            study: "",
            sortColumn: "",
        }
    };

    const [state, dispatch] = useReducer(ReportedVariantsReducer, initialState);
    const CSM = new ContextStateManager(state, initialState, ReportedVariantsActionType, dispatch)

    const getVariant = async (id: string) => CSM.GetAsyncData("GET_VARIANT_", { id }, ReportedVariantS, "readReportedVariant");
    const getVariants = async (filters: FiltersReportedVariantsI, pageNumber?: number, pageSize?: number) => CSM.GetAsyncData("GET_VARIANTS_", { filters, pageNumber, pageSize }, ReportedVariantS, "search");
    const refreshVariants = () => dispatch({ type: ReportedVariantsActionType.GET_VARIANTS_REFRESH });
    const setFilters = (key: string, value: string): boolean => CSM.SetFilters("filters", "SET_FILTERS", key, value)
    const cleanFilters = () => CSM.CleanFilters("filters", "SET_FILTERS")
    const handleSelecteds = (id: string) => CSM.HandleSelecteds("selecteds", "SET_SELECTEDS", state.variants.data?.reports ?? [], id)
    const handleSelectAll = () => CSM.HandleSelectAll("selecteds", "SET_SELECTEDS", state.variants.data?.reports ?? [])

    return (
        <ReportedVariantsContext.Provider
            value={{
                variant: state.variant,
                variants: state.variants,
                filters: state.filters,
                selecteds: state.selecteds,
                dispatch: {
                    getVariant,
                    getVariants,
                    refreshVariants,
                    setFilters,
                    cleanFilters,
                    handleSelecteds,
                    handleSelectAll
                }
            }}
        >
            {props.children}
        </ReportedVariantsContext.Provider>
    );
};

export default ReportedVariantsContextState;