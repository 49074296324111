import React from 'react';
import { useContext, useState } from 'react';
import { Button, Col, Row, Dropdown, Form, ButtonToolbar } from 'react-bootstrap';
import { LangContext } from '../../../Contexts/Lang';
import "../../Common/table.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCogs, faSortAmountDown, faFileExport, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import ReactSelect from '../../Common/Form/ReactSelect';
import { Tooltip } from '../../Common/Tooltip/Tooltip';
import { ModalWarning } from '../../Common/ModalWarning'
import CircleLabel from '../../Common/CircleLabel';
import { LabelValueI } from '../../../utils/BioFunctionalData';
import UserContext from 'src/Contexts/User/UserContext';
import FormInput from '../../Common/Form/FormInput';

interface MultipleActionsOptionsI {
	icon: IconDefinition,
	label: string,
	handleClick(): void,
	modalWarning?: {
		label: string,
		buttonLabel: string
	}
}
interface ExportActionsOptionsI {
	label: string,
	disabled?: boolean,
	href?: string,
	handleClick?(): void,
}
interface HomeTableToolkitI {
	id?: string,
	rowData: any[],
	rowDataSelected?: any[],
	handleSelectAll?: any,
	multipleActionsOptions?: ("divider" | MultipleActionsOptionsI)[],
	addElement?: {
		icon?: IconDefinition,
		handleCreate(): void,
		tooltipLabel: string,
	}
	secondElementLeftToRight?: JSX.Element
	exportActions?: ("divider" | ExportActionsOptionsI)[],
	sortBy: {
		value: string,
		options: "default" | (LabelValueI | "default")[],
		handleSortBy(input: any): void;
	},
	filterInput?: {
		onChange(x: any): void,
		value: string,
		placeHolder: string,
	},
	buttonsToolbar?: any
}

export default function HomeToolkit(props: HomeTableToolkitI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { user } = useContext(UserContext);
	const isAdmin = (user.data?.authorities ?? []).find((x) => (x?.authority ?? "").toLowerCase().indexOf("admin") > -1)
	let sortByDefaultOptions = [
		{ label: translate('home.tableManager.date'), value: "" },
		{ label: translate('home.tableManager.name'), value: "name" }
	]
	const initialModalWarning = { show: false, label: "", buttonLabel: "", handler: () => { } }
	const [showModalWarning, setshowModalWarning] = useState<{ show: boolean, label: string, buttonLabel: string, handler(): void }>(initialModalWarning)
	const handleCloseModalWarning = () => setshowModalWarning(initialModalWarning);
	const handleShowModalWarning = (label: string, buttonLabel: string, handler: any) => setshowModalWarning({ show: true, label: label, buttonLabel: buttonLabel, handler: handler });
	// console.log(props?.sortBy?.options)
	let sortByOptions: any = props?.sortBy?.options === "default" ?
		sortByDefaultOptions :
		Array.isArray(props?.sortBy?.options) ?
			props.sortBy.options.indexOf("default") > -1 ?
				[...sortByDefaultOptions, ...props?.sortBy?.options] :
				props?.sortBy?.options :
			[];
	sortByOptions = sortByOptions.filter((x: any) => x !== "default") as LabelValueI[]
	// console.log(sortByOptions)
	const buttonAddElement = <Button variant={"outline-success"}
		style={{
			borderRadius: "50px",
			padding: "0 8px",
			border: "none",
			margin: "2px 4px 1px",
			height: "29px",
			width: "29px",
			alignItems: "center",
			display: "flex",
			justifyContent: "center",
		}}
		onClick={() => {
			props.addElement?.handleCreate();
		}}>
		<Tooltip label={props.addElement?.tooltipLabel}>
			<FontAwesomeIcon icon={faPlus}
				style={{ border: "none", fontSize: "17px" }}
				data-tip="Add case"
			/>
		</Tooltip>
	</Button>
	return (
		<div id={`HomeToolkit${props.id ? ("_" + props.id) : ""}`} style={{ width: "100%", borderBottom: "solid 1px lightgray" }}>
			<ModalWarning labelTitle={translate('common.confirm')}
				label={showModalWarning?.label}
				labelButton={showModalWarning?.buttonLabel}
				showState={showModalWarning.show}
				handleClose={handleCloseModalWarning}
				onClick={() => { if (showModalWarning && showModalWarning?.handler) showModalWarning?.handler() }}
			/>
			<Row style={{ margin: "5px 0px 3px", display: "flex", float: "left", width: "100%", backgroundColor: "rgb(244, 244, 244)", paddingBottom: "3px" }}>
				<Col xs={7} sm={8} md={9} style={{ padding: 0 }}>
					<div style={{
						// margin: "10px ​0 3px 10px",
						marginLeft: "5px",
						display: "flex"
					}}>
						{props.rowDataSelected &&
							<Tooltip label={translate('home.tableManager.selectAll')}>
								<Form.Check type="checkbox"
									style={{ alignSelf: "center", textAlignLast: "center", margin: "5px 6px 0px 6px" }}
									checked={((props?.rowDataSelected ?? []).length === props?.rowData?.length && props?.rowData?.length !== 0)}
									onChange={props.handleSelectAll}
								/>
							</Tooltip>
						}
						{props.filterInput && <>
							<FormInput onChange={props.filterInput?.onChange}
								value={props.filterInput?.value}
								placeHolder={props.filterInput?.placeHolder}
							/>&nbsp;
						</>}
						{props.buttonsToolbar &&
							<>
								<ButtonToolbar id="HomeToolkit-buttonsToolbar" style={{ marginBottom: "0!important" }}>
									{props.buttonsToolbar}
								</ButtonToolbar>
								&nbsp;
							</>
						}
						{props.multipleActionsOptions && props.multipleActionsOptions.length > 0 &&
							<Dropdown alignRight={true} >
								<Tooltip label={translate('home.tableManager.groupActions')}>
									<Dropdown.Toggle id="dropdown-basic"
										variant={props?.rowDataSelected?.length === 0 ? "outline-secondary" : "outline-primary"}
										disabled={props?.rowDataSelected?.length === 0}
										style={{ boxShadow: "none", height: "32px", border: "none", borderRadius: "0", fontSize: "17px", marginRight: "5px" }}>
										<FontAwesomeIcon icon={faCogs}
											style={{ border: "none", verticalAlign: "text-bottom" }}
											data-tip="Add case"
										/>
										&nbsp;
										{props.rowDataSelected &&
											Array.isArray(props.rowDataSelected) &&
											props.rowDataSelected.length > 0 &&
											<CircleLabel label={`${props.rowDataSelected.length}`}
												size={1}
												backColor={"#007BFF"}
											/>
										}
									</Dropdown.Toggle>
								</Tooltip>
								<Dropdown.Menu style={{ minWidth: "200px", borderRadius: "0", overflow: "visible" }} >
									{props.multipleActionsOptions?.map((x: MultipleActionsOptionsI | "divider", i: number) => {
										if (x === "divider") return (<Dropdown.Divider key={i} />)
										else return (
											<Dropdown.Item key={i}
												style={{ borderRadius: "0" }}
												onClick={() => {
													if (x.modalWarning) handleShowModalWarning(x.modalWarning.label, x.modalWarning.buttonLabel, x.handleClick)
													else if (x.handleClick) x.handleClick()
												}}>
												<Row>
													<Col sm={2} style={{ padding: "0 0 0 7px" }}>
														<FontAwesomeIcon icon={x.icon} style={{ border: "none", color: "gray", fontSize: "15px" }} />
													</Col>
													<Col sm={10} style={{ padding: "0 12px 0 0" }}>
														{x.label}
													</Col>
												</Row>
											</Dropdown.Item>
										)
									})}
								</Dropdown.Menu>
							</Dropdown>
						}
						{props.exportActions && props.exportActions.length > 0 &&
							<Dropdown alignRight={true} >
								<Tooltip label={translate('common.export')}>
									<Dropdown.Toggle id="dropdown-basic"
										variant={"outline-secondary"}
										style={{ paddingTop: "4px", boxShadow: "none", height: "32px", border: "none", borderRadius: "0", fontSize: "17px", marginRight: "5px" }}>
										<FontAwesomeIcon icon={faFileExport}
											style={{ border: "none" }}
											data-tip="Add case"
										/>
									</Dropdown.Toggle>
								</Tooltip>
								<Dropdown.Menu style={{ minWidth: "200px", borderRadius: "0", overflow: "visible" }} >
									{props.exportActions.map((x: ("divider" | ExportActionsOptionsI), i: number) => {
										if (x === "divider") return (<Dropdown.Divider key={i} />)
										else return (
											<Dropdown.Item key={i}
												variant="secondary"
												href={x.href ?? undefined}// ReportS.get_downloadSelectedVariantInfoXls_link((props?.rowDataSelected ?? []).map((x: any) => `${x}`))
												// disabled={props?.rowDataSelected?.length === 0}
												disabled={x.disabled ?? false}
												target="_blank">

												{/* {`${translate("common.selectedVariants")} (xls)`} */}
												{x.label}
											</Dropdown.Item>
										)
									})}
								</Dropdown.Menu>
							</Dropdown>
						}
						{
							(props.addElement && props.id && props.id === "cases") ?
								isAdmin && buttonAddElement :
								props.addElement && buttonAddElement
						}
						{
							props.secondElementLeftToRight 
						}
					</div>
				</Col >
				<Col xs={5} sm={4} md={3} style={{ padding: 0 }}>
					{props?.sortBy?.options &&
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<p style={{ alignSelf: "center", fontSize: "10px", display: "flex", float: "right", margin: 0, color: "gray" }}>Order By</p>
							<FontAwesomeIcon icon={faSortAmountDown} style={{ margin: '0 10px 0 7px', fontSize: "20px", color: "darkgray", alignSelf: "center" }} />
							<ReactSelect options={sortByOptions}
								onChange={(e: any) => {
									if (props.sortBy.handleSortBy) props.sortBy.handleSortBy(e.value)
								}}
								value={sortByOptions.filter((x: any) => x.value === props.sortBy.value)}
								// width={props.type === "comments" ? '170px' : '100px'}
								width={'170px'}
								divStyles={{ marginRight: '7px' }}
							/>
						</div>
					}
				</Col >
			</Row>
		</div >
	)
}
