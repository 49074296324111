import React from 'react';
import { useEffect, useContext, useState } from 'react';
import { User } from '../../Components/Users/User/User';
import { Users } from '../../Components/Users/Users';
import { Layout } from '../../Components/Layout/Layout';
import UserContext from '../../Contexts/User/UserContext';
import { Redirect } from 'react-router-dom';

interface UserPageI {
    history: any
}
export default function UserPage(props: UserPageI) {
    const { user } = useContext(UserContext);
    const [body, setbody] = useState<any>(<></>)
    // const [reload, setreload] = useState(false)
    const currentRoll = user.data?.rolestoString ?? ""

    useEffect(() => {
        if (currentRoll !== "ROLE_ADMIN" && currentRoll !== "ROLE_SYSTEM" && currentRoll !== "ROLE_OPERATOR")
            setbody(<Redirect to='/' />)
        else {
            if ((props.history?.location?.pathname ?? "").indexOf("users") > 0 && currentRoll !== "ROLE_OPERATOR")
                setbody(<Users history={props.history} />)
            else
                setbody(
                    <User history={props.history}
                        editMode={props.history?.location?.pathname.indexOf("edit") > -1}
                    />)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, props.history?.location?.pathname])

    return (
        <Layout history={props.history}>
            {body}
        </Layout>
    )
}
