import React, { useContext, useState } from 'react'
import { LangContext } from 'src/Contexts/Lang';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { Popover } from '../../Popover';

export default function CoverageByGeneBarLayer(barProps: any, handleSelect: any, coverageFilteredData: any) {
    const [currentIndex, setCurrentIndex] = useState("");
    const { theme } = useContext(ThemeContext);
    const { dispatch: { translate } } = useContext(LangContext);

    return (
        <>
            {barProps.bars.map((i: { data: any, key: number, height: any, width: any, x: number, y: number }) => {
                const thereIs = coverageFilteredData.some((xxx: any) => xxx === i.data.data.geneName);
                return (<g key={i.key} transform={`translate(${i.x}, ${i.y})`} style={{ cursor: "pointer" }}>
                    <Popover id={"CoverageByGeneBar-Popover"}
                        content={
                            <div style={{ padding: 10, fontSize: "10px" }}>
                                &nbsp;<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
                                    {translate("common.geneName")}
                                </span> : {i.data.data.geneName}<br />
                                &nbsp;<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
                                    {translate("common.coverage")}
                                </span> : {i.data.data.coverage}<br />
                                &nbsp;<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
                                    % Targets 20X
                                </span> : {i.data.data.cov20XExons}<br />
                                &nbsp;<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
                                    % Exons 20X
                                </span> : {i.data.data.cov20XTargets}<br />
                            </div>
                        }
                        trigger={"hover"}
                        // trigger={"click"}
                        style={{ minWidth: "200px", fontSize: "10px", zIndex: 2 }}
                        contentStyle={{ padding: "0 0 0 2px" }}
                    >


                        <rect
                            width={i.width}
                            height={i.height}
                            fill={thereIs ? "rgba(60, 165, 145,0.7)" : i.data.indexValue === currentIndex ? "rgba(90, 165, 165,0.7)" : "rgba(170, 235, 215,0.7)"}
                            strokeWidth={!thereIs ? undefined : 1}
                            stroke={!thereIs ? undefined : "gray"}
                            onMouseEnter={() => { setCurrentIndex(i.data.indexValue); }}
                            onClick={() => { handleSelect(i.data.data.geneName) }}
                            // onMouseMove={(event: any) => {
                            //     // handleTooltip(i.data, event);
                            // }}
                            onMouseLeave={(event: any) => { setCurrentIndex(""); }}
                        />
                    </Popover>
                </g>)
            }
            )}
        </>
    );
}

