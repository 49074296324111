const Util = {
    isArray: function (val: any) {
        return Object.prototype.toString.call(val) === '[object Array]';
    },
    isNil: function (val: any) {
        return val === null || Util.typeOf(val)
    },
    typeOf: function (val: any, type?: any) {
        return (type || 'undefined') === typeof val;
    },
    funEach: function (obj: any, fun: any) {
        if (Util.isNil(obj))
            return;      // empty value

        if (!Util.typeOf(obj, 'object'))
            obj = [obj]; // Convert to array

        if (Util.isArray(obj)) {
            // Iterate over array
            for (var i = 0, l = obj.length; i < l; i++)
                fun.call(null, obj[i], i, obj);
        } else {
            // Iterate over object
            for (var key in obj)
                Object.prototype.hasOwnProperty.call(obj, key) && fun.call(null, obj[key], key, obj);
        }
    }
};
const serialize = (params: any) => {
    let pair: any = [];
    const encodeValue = (v: any) => {
        if (Util.typeOf(v, 'object'))
            v = JSON.stringify(v);
        return encodeURIComponent(v);
    };
    Util.funEach(params, (val: any, key: any) => {
        let isNil = Util.isNil(val);

        if (!isNil && Util.isArray(val))
            key = `${key}[]`;
        else
            val = [val];

        Util.funEach(val, (v: any) => {
            pair.push(`${key}=${isNil ? "" : encodeValue(v)}`);
        });
    });

    return pair.join('&');
};

export { serialize }