import { ReportedVariantsActionType } from "../actionsTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any, action: any) => {
    const { payload, type } = action;
    switch (type) {
        // Async methods
        case ReportedVariantsActionType.GET_VARIANT_SUCCESS: return { ...state, variant: { data: payload, loading: false, error: false, loaded: true } };
        case ReportedVariantsActionType.GET_VARIANT_ERROR: return { ...state, variant: { data: state.variant.data, loading: false, error: payload, loaded: true } };
        case ReportedVariantsActionType.GET_VARIANT_LOADING: return { ...state, variant: { data: state.variant.data, loading: true, error: false, loaded: false } };

        case ReportedVariantsActionType.GET_VARIANTS_SUCCESS: return { ...state, variants: { data: payload, loading: false, error: false, loaded: true, refresh: false } };
        case ReportedVariantsActionType.GET_VARIANTS_ERROR: return { ...state, variants: { data: state.variants.data, loading: false, error: payload, loaded: true, refresh: false } };
        case ReportedVariantsActionType.GET_VARIANTS_LOADING: return { ...state, variants: { data: state.variants.data, loading: true, error: false, loaded: false, refresh: false } };
        case ReportedVariantsActionType.GET_VARIANTS_REFRESH: return { ...state, variants: { ...state.variants, refresh: true } };

        // Sync methods
        case ReportedVariantsActionType.SET_FILTERS: return { ...state, filters: payload };
        case ReportedVariantsActionType.SET_SELECTEDS: return { ...state, selecteds: payload };

        default: return state;
    }
}