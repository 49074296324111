import React from 'react';
import { useContext } from 'react';
import { Button, Card, Col, Row, InputGroup, FormControl } from 'react-bootstrap';
import { LangContext } from '../../../Contexts/Lang';
import "../../Common/table.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { useWindowSize } from '../../../Helpers/WindowsSizeHelper';
import { Tooltip } from '../../Common/Tooltip/Tooltip';
import FormDate from '../../Common/Form/FormDate';
import { checkKeyEnterPress } from '../../../Helpers/Helpers';
import ReactSelect from '../../Common/Form/ReactSelect';
import FormInput, { FormInputType } from '../../Common/Form/FormInput';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import LinkTooltip from 'src/Components/Common/Links/LinkTooltip';
import { LabelValueI } from 'src/utils/BioFunctionalData';

type HomeFilterT = "default" | "domainDetail"
type inputFiltersT = "select" | "date" | "number" | "text" | "textarea" | "button"
interface InputFilterI {
	type: inputFiltersT,
	stateName: string,
	options?: LabelValueI[],
	placeholder?: string,
	textUnderInput?: string | JSX.Element,
	group?: string | JSX.Element,
	defaultValueOnClick?: string,
	tooltip?: string,
}
interface HomeFilterI {
	filters?: any,
	inputFilters?: InputFilterI[],
	setFilter?(fieldName: string, value: any): void,
	callFilter?(): any,
	handleCleanFilter?(): any,
	HLType?: HomeFilterT,
	submitType?: "filter" | "search"
	header?: JSX.Element,
	footer?: JSX.Element,
}

export default function HomeFilter(props: HomeFilterI) {
	const { dispatch: { translate } } = useContext(LangContext);

	const { widthType } = useWindowSize()
	const submitType = props.submitType ?? "filter"
	const { theme } = useContext(ThemeContext);
	// console.log(widthType)
	const sizeXlOrLg = widthType === "lg" || widthType === "xl" || widthType === "xxl"
	const HLType: HomeFilterT = props.HLType ? props.HLType : "default";
	const isDomainDetail = (HLType === "domainDetail");
	const buttons = (<>
		<Button variant="outline-primary"
			style={{ margin: "0 2px 0px 0px", height: "32px" }}
			onClick={props.callFilter}>
			{submitType === "filter" ? translate('common.filter') : translate('common.search')}
		</Button>
		<Tooltip label={submitType === "filter" ? translate('common.cleanFilter') : translate('common.cleanSearch')}>
			<Button variant="outline-secondary"
				style={{ margin: "0 2px", height: "32px" }}
				onClick={props?.handleCleanFilter}>
				<FontAwesomeIcon icon={faUndo} style={{ border: "none" }} />
			</Button>
		</Tooltip>
	</>
	)
	const getColStyles = (isHighlight: boolean) => {
		return {
			padding: "2px",
			border: `solid 1px ${isDomainDetail ? "#F4F4F4" : "#FFF"}`,
			backgroundColor: (isHighlight ? "rgb(74 196 255)" : undefined)
		}
	}
	const getBody = () => {
		const CardBody6 = (jsxInputFilters: { component: JSX.Element, highlight: boolean }[], buttonsCount: number) => (
			<>
				<Row style={{ padding: 0, width: "100%", margin: "0" }}>
					{(jsxInputFilters ?? []).map((x: any, i: number) =>
						<Col xs={6} sm={4} md={4} lg={2} key={i} style={getColStyles(x.highlight)}>
							{x.component}
						</Col>
					)}
					{!sizeXlOrLg &&
						<div style={{ padding: "2px", alignItems: "center", placeContent: "flex-end", width: "100%", margin: "0px 0px 2px", textAlign: "end" }}>
							{buttons}
						</div>
					}
				</Row>
				{sizeXlOrLg &&
					<Row style={{ padding: 0, minWidth: "140px", margin: "0", display: "initial", textAlign: "end", alignSelf: "center" }}>
						{buttons}
					</Row>
				}
			</>
		)
		const CardBody5 = (jsxInputFilters: { component: JSX.Element, highlight: boolean }[], buttonsCount: number) => (
			<>
				<Row style={{ padding: 0, width: "100%", margin: "0" }}>
					{(jsxInputFilters ?? []).map((x: any, i: number) => {
						if (i < 3)
							return (
								<Col xs={4} sm={4} md={2} lg={2} key={i} style={getColStyles(x.highlight)}>
									{x.component}
								</Col>
							)
						return (
							<Col xs={6} sm={6} md={3} lg={3} key={i} style={getColStyles(x.highlight)}>
								{x.component}
							</Col>
						)
					}
					)}
					{!sizeXlOrLg &&
						<div style={{ padding: "2px", alignItems: "center", placeContent: "flex-end", width: "100%", margin: "0px 0px 2px", textAlign: "end" }}>
							{buttons}
						</div>
					}
				</Row>
				{sizeXlOrLg &&
					<Row style={{ padding: 0, minWidth: "140px", margin: "0", display: "initial", textAlign: "end", alignSelf: "center" }}>
						{buttons}
					</Row>
				}
			</>
		)
		const CardBody4 = (jsxInputFilters: { component: JSX.Element, highlight: boolean }[], buttonsCount: number) => (
			<>
				<Row style={{ padding: 0, width: "100%", margin: "0" }}>
					{(jsxInputFilters ?? []).map((x: any, i: number) => {
						if (i < 2)
							return (
								<Col xs={6} sm={6} md={3} lg={3} key={i} style={getColStyles(x.highlight)}>
									{x.component}
								</Col>
							)
						return (
							<Col xs={6} sm={6} md={3} lg={3} key={i} style={getColStyles(x.highlight)}>
								{x.component}
							</Col>
						)
					}
					)}
					{!sizeXlOrLg &&
						<div style={{ padding: "2px", alignItems: "center", placeContent: "flex-end", width: "100%", margin: "0px 0px 2px", textAlign: "end" }}>
							{buttons}
						</div>
					}
				</Row>
				{sizeXlOrLg &&
					<Row style={{ padding: 0, minWidth: "140px", margin: "0", display: "initial", textAlign: "end", alignSelf: "center" }}>
						{buttons}
					</Row>
				}
			</>
		)
		const CardBody3 = (jsxInputFilters: { component: JSX.Element, highlight: boolean }[], buttonsCount: number) => (
			<>
				<Row style={{ padding: 0, width: "100%", margin: "0", display: "flex" }}>
					{(jsxInputFilters ?? []).map((x: any, i: number) => {
						if (i === 0)
							return <Col xs={12} sm={12} md={12} lg={5} key={i} style={getColStyles(x.highlight)}>
								{x.component}
							</Col>
						if (i >= jsxInputFilters.length - buttonsCount)
							return (
								<Col xs={2} sm={2} md={2} lg={1} key={i} style={getColStyles(x.highlight)}>
									{x.component}
								</Col>
							)
						return <Col xs={5} sm={5} md={5} lg={3} key={i} style={getColStyles(x.highlight)}>
							{x.component}
						</Col>
					})}
					{!sizeXlOrLg &&
						<Col lg={1} xl={1}
							style={{
								padding: "2px",
								alignItems: "center",
								placeContent: "flex-end",
								margin: "0px 0px 2px",
								display: "flex"
							}}>
							{buttons}
						</Col>
					}

				</Row >
				{sizeXlOrLg &&
					<Row style={{ padding: 0, minWidth: "140px", margin: "0", display: "initial", textAlign: "end", alignSelf: "center" }}>
						{buttons}
					</Row>
				}
			</>
		)
		const CardBody2 = (jsxInputFilters: { component: JSX.Element, highlight: boolean }[], buttonsCount: number) => (
			<>
				<Row style={{ padding: 0, width: "100%", margin: "0", display: "flex" }}>
					{/* {(jsxInputFilters ?? []).map((x: any, i: number) =>
                        <Col xs={6} sm={4} md={3} lg={2} key={i} style={getColStyles(x.highlight)}>
                            {x.component}
                        </Col>
                    )}*/}
					<Col xs={6} sm={6} md={8} lg={8} key={0} style={getColStyles(jsxInputFilters[0].highlight)}>
						{jsxInputFilters[0].component}
					</Col>
					<Col xs={4} sm={4} md={4} lg={4} key={1} style={getColStyles(jsxInputFilters[1].highlight)}>
						{jsxInputFilters[1].component}
					</Col>
					{!sizeXlOrLg &&
						<>
							<div style={{ padding: "2px", alignItems: "center", placeContent: "flex-end", width: "100%", margin: "0px 0px 2px", textAlign: "end" }}>
								{buttons}
							</div>
						</>
					}
				</Row>
				{sizeXlOrLg &&
					<>
						<Row style={{ padding: 0, minWidth: "140px", margin: "0", display: "initial", textAlign: "end", alignSelf: "center" }}>
							{buttons}
						</Row>
					</>
				}
			</>
		)
		const CardBody1 = (jsxInputFilters: { component: JSX.Element, highlight: boolean }[], buttonsCount: number) => (
			<>
				<Row style={{ padding: 0, width: "100%", margin: "0", display: "flex" }}>
					{(jsxInputFilters ?? []).map((x: any, i: number) =>
						<Col xs={12} sm={12} md={10} lg={10} key={i} style={{ ...getColStyles(x.highlight), backgroundColor: "transparent" }}>
							{/* <Col xs={12} sm={12} md={10} lg={10} key={i} style={{}}> */}
							{x.component}
						</Col>
					)}
					<Col xs={12} sm={12} md={2} lg={2}
						style={{
							padding: "3px 2px",
							// alignItems: "center",
							placeContent: "flex-end",
							margin: "0px 0px 2px",
							display: "flex"
						}}>
						{buttons}
					</Col>
				</Row >
			</>
		)
		if (!props.inputFilters) return <></>
		const setJsxInputFilters = (): {
			components: { component: JSX.Element, highlight: boolean }[], buttonsCount: number
		} => {
			function handleDate(date: Date, fieldName: string, textUnderInput?: JSX.Element | string) {
				if (!date) return
				let dd = String(date.getDate()).padStart(2, '0');
				let mm = String(date.getMonth() + 1).padStart(2, '0');
				let yyyy = date.getFullYear();
				let dateFormated = `${dd}/${mm}/${yyyy}`;
				if (props.setFilter) props.setFilter(fieldName, dateFormated);
			}

			const getInputDateComponent = (stateName: string, placeholder: string, textUnderInput?: JSX.Element | string) => {
				let selected = undefined;
				if (props.filters && props.filters[`${stateName}`])
					if (props.filters[`${stateName}`] !== "") {
						let d = props.filters[`${stateName}`].split("/")
						if (d.length > 2) {
							selected = (new Date(d[2], d[1] - 1, d[0]))
						}
					}
				return (
					<FormDate placeHolder={placeholder}
						selected={selected}
						onChange={(date: any) => handleDate(date, stateName)}
						onKeyDown={(e: any) => checkKeyEnterPress(e, () => {
							if (props.setFilter) props.setFilter(stateName, e.target.value)
							if (props.callFilter) props.callFilter()
						})} />
				)
			}

			const getSelectInputComponent = (stateName: string, options?: LabelValueI[], placeholder?: string, textUnderInput?: JSX.Element | string) => {
				const studyTypeValue = (options ?? []).filter((x: any) => x.value === (props.filters ? props.filters[`${stateName}`] : false))

				return (
					<ReactSelect options={options}
						onChange={(e: any) => { if (props.setFilter) props.setFilter(stateName, e.value) }}
						value={studyTypeValue}
						placeholder={placeholder}
					// onKeyDown={(e: any) => {

					// }} 
					/>

				)
			}

			const getInputTextComponent = (type: FormInputType, stateName: string, placeholder: string, textUnderInput?: JSX.Element | string, group?: string | JSX.Element, defaultValueOnClick?: any) => {
				let value = props?.filters ? props?.filters[`${stateName}`] === null ? null : props?.filters[`${stateName}`] : undefined
				return (
					<>
						{!group ?
							<FormInput
								type={type}
								value={value}
								onChange={(e: any) => {
									if (props.setFilter)
										props.setFilter(stateName, e.target.value)
								}}
								textAreaRows={1}
								placeHolder={placeholder}
								onKeyDown={(e: any) => { checkKeyEnterPress(e, () => { if (props.callFilter) props.callFilter(); }) }} />
							:
							<InputGroup id={"LALA"}>
								<InputGroup.Prepend style={{ height: "inherit", borderRadius: 0 }}>
									<InputGroup.Text id="btnGroupAddon"
										style={{ fontSize: "12px", borderRadius: 0, color: "dimgray", fontWeight: "bold" }}>
										{group}
									</InputGroup.Text>
								</InputGroup.Prepend>
								<FormControl style={{ height: "inherit", fontSize: "12px", borderRadius: 0 }}
									onClick={() => {
										if (props.setFilter && defaultValueOnClick !== undefined && (value === null || value === undefined)) {
											props.setFilter(stateName, defaultValueOnClick)
										}
									}}
									onChange={(e: any) => {
										if (props.setFilter)
											props.setFilter(stateName, e.target.value)
									}}
									onKeyDown={(e: any) => { checkKeyEnterPress(e, () => { if (props.callFilter) props.callFilter(); }) }}
									type="number"
									value={value === null ? "" : value}
									placeholder={`${placeholder}`}
								/>
							</InputGroup>
						}
						{textUnderInput ?? ""}
					</>
				)
			}

			const getButtonComponent = (stateName: string, label: string, tooltip?: string) => {
				const value = props?.filters ? props?.filters[`${stateName}`] : undefined
				let button = (
					<Button variant="outline-secondary"
						style={{
							width: "inherit",
							backgroundColor: "white",
							color: "gray",
							border: `1px solid ${theme.borderColor}`,
							fontWeight: "bold"
						}}
						onClick={(e: any) => { if (props.setFilter) props.setFilter(stateName, value) }}
					>
						{label}
					</Button>
				)
				if (tooltip) button = <Tooltip label={tooltip}> {button}</Tooltip>
				return button
			}
			let buttonsCount = 0;
			const components = (props.inputFilters ?? []).map((x: InputFilterI, i: number) => {
				let h = props?.filters ? props?.filters[`${x.stateName}`] ? true : false : false
				let element = { component: <></>, highlight: h }
				switch (x.type) {
					case "select":
						element.component = getSelectInputComponent(x.stateName, x?.options, x?.placeholder, x?.textUnderInput); break;
					case "textarea":
						element.component = getInputTextComponent("textarea", x.stateName, x?.placeholder ?? "", x?.textUnderInput); break;
					case "text":
						element.component = getInputTextComponent("text", x.stateName, x?.placeholder ?? "", x?.textUnderInput); break;
					case "number":
						element.component = getInputTextComponent("number", x.stateName, x?.placeholder ?? "", x?.textUnderInput, x.group, x.defaultValueOnClick); break;
					case "date":
						element.component = getInputDateComponent(x.stateName, x?.placeholder ?? "", x?.textUnderInput); break;
					case "button":
						buttonsCount++;
						element.component = getButtonComponent(x.stateName, x?.placeholder ?? "", x?.tooltip); break;
				}
				return element;
			})
			return { components, buttonsCount }
		}
		const { components, buttonsCount } = setJsxInputFilters()
		switch (components.length - buttonsCount) {
			case 1: return CardBody1(components, buttonsCount);
			case 2: return CardBody2(components, buttonsCount);
			case 3: return CardBody3(components, buttonsCount);
			case 4: return CardBody4(components, buttonsCount);
			case 5: return CardBody5(components, buttonsCount);
			case 6: return CardBody6(components, buttonsCount);
			default: return <></>
		}
	}

	return (
		<div id="HomeFilter">
			{props.header &&
				<PanelFilter position='header'>
					{props.header}
				</PanelFilter>
			}
			<Card style={{ border: "none", borderRadius: 0, overflow: "initial", backgroundColor: isDomainDetail ? "#f4f4f4" : "auto" }}>
				<Card.Body style={{
					padding: "5px",
					border: `1px solid ${isDomainDetail ? "#f4f4f4" : "#e3e3e3"}`,
					display: sizeXlOrLg ? "flex" : "content",
					justifyContent: sizeXlOrLg ? "space-between" : "auto"
				}}
					onKeyDown={(e: any) => { checkKeyEnterPress(e, () => { if (props.callFilter) props.callFilter() }) }}
				>
					{getBody()}
				</Card.Body>
				{props.footer && <Card.Footer style={{ padding: 4 }}>
					<p style={{ fontSize: "10px", color: "dimGray", margin: "2px" }}>
						&nbsp;{translate("genesPanel.processGeneInputGeneNomenclature")}
						<LinkTooltip tooltipLabel={`HGNC - genenames.org`}
							href={`http://www.genenames.org/`}
							label={<strong>{translate("genesPanel.hgncApprovedNomenclature")}</strong>} />
					</p>
				</Card.Footer>}
			</Card>
		</div>
	)
}

interface PanelFilterI {
	position: "header" | "footer",
	children: JSX.Element,
}
function PanelFilter(props: PanelFilterI) {
	const { theme } = useContext(ThemeContext);
	return (
		<div style={{
			width: "100%",
			backgroundColor: theme.backgroundPrimary,
			border: `1px solid ${theme.borderColor}`,
			padding: "5px 10px",
			borderBottom: props.position === "footer" ? "auto" : "none",
			borderTop: props.position === "header" ? "auto" : "none"
		}}>
			{props.children}
		</div>
	)
}

export type { InputFilterI }