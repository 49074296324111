// https://usehooks.com/useWindowSize/
import { useState, useEffect } from "react";
export type widthSizesT = "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
export function useWindowSize() {
    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({
        width: 0,
        height: 0,
    });
    const [widthType, setWidthType] = useState<widthSizesT>("xl");
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            const w = window.innerWidth >= 1500 ? "xxl" :
                window.innerWidth >= 1200 ? "xl" :
                    window.innerWidth >= 992 ? "lg" :
                        window.innerWidth >= 768 ? "md" :
                            window.innerWidth >= 576 ? "sm" : "xs"
            setWidthType(w)
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return { windowSize, widthType };
}