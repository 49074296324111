import axios, { AxiosResponse } from 'axios';
import { AbstractService } from './AbstractService';

export default class LoginService extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "login/";
    }
    async login(username: string, password: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}auth`
        let encodePassword = window.btoa(password)
        const data = {
            "username": username,
            "password": encodePassword
        }
        return axios.post(url, data)
    }

    async logout(): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}logout`
        const config = {
            ...this.authHeader,
            params: {
                userId: this.user?.id,
                _: (new Date()).getTime()
            }
        }
        // console.log("config.params")
        // console.log(config.params)
        localStorage.removeItem("_Buser")
        this.user = undefined;
        return axios.get(url, config)
    }
}