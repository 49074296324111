import React from 'react';
import { Badge } from "react-bootstrap";
import { useContext } from "react";
import ThemeContext from 'src/Contexts/Theme/ThemeContext';

interface BadgeVariantVeredictI {
    labelInput: string,
    id: number,
    size?: "small" | "big",
    fontSize?: number,
}

export default function BadgeVariantVeredict(props: BadgeVariantVeredictI) {
    const { theme } = useContext(ThemeContext);
    const size = props.size ?? "small"
    if ((props.labelInput??"").trim().length === 0) return <span key={props.id}></span>
    const label = size === "small" ?
        props.labelInput.trim().toUpperCase().replaceAll("_", " ") :
        (props.labelInput[0].toUpperCase() + props.labelInput.substring(1, props.labelInput.length)).replaceAll("_", " ")
    let opacity = null;
    const getVeredictVariantType = (veredict: string): string => {
        switch (veredict.trim().toUpperCase()) {
            case "PATHOGENIC": return theme.acmg_vere_p;
            case "LIKELY PATHOGENIC": return theme.acmg_vere_lp;
            case "LIKELY_PATHOGENIC": return theme.acmg_vere_lp;
            case "UNCERTAIN SIGNIFICANCE": return theme.acmg_vere_vus;
            case "UNCERTAIN_SIGNIFICANCE": return theme.acmg_vere_vus;
            case "BENIGN": return theme.acmg_vere_b;
            case "LIKELY BENIGN": return theme.acmg_vere_lb;
            case "LIKELY_BENIGN": return theme.acmg_vere_lb;
            default: return "gray";
        }
    }
    let backColor = getVeredictVariantType(props.labelInput);
    return (
        < Badge key={props.id}
            style={{
                fontWeight: "inherit",
                fontSize: props.fontSize ?? size === "small" ? 10 : 13,
                margin: "1px 2px",
                color: "white",
                alignSelf: "center",
                backgroundColor: backColor,
                // padding: size === "small" ? "3px 3px 2px 3px" : "auto",
                opacity: opacity !== null ? opacity : "1",
            }}>
            <b>{label}</b>
        </Badge>
    )
}
