import { createContext } from "react";
import { AsyncDataI, AsyncPagesI, StudyI } from "../interfaces";

interface DomainI {
    ateCreated: number,
    description: string,
    enabled: boolean,
    id: number,
    name: string,
    simpleDateFormatCreated: string
}
interface FilterDomainReqI {
    domainidSearch: string,
    domainnameSearch: string,
    studynameSearch: string,
    usernameSearch: string,
    sortColumn: string
}
interface FiltersCasesDomainReqI {
    nameSearch: string,
    studyStateSearch: string,
    dateCreatedFromSearch: string,
    dateCreatedToSearch: string
    sortColumn: string
}
interface FiltersUserDomainReqI {
    fullnameSearch: string,
    usernameSearch: string
    sortColumn: string
}

interface ContextDomainStatesI {
    domain: AsyncDataI & {
        data: null | (AsyncPagesI & {
            domain : DomainI,
            domains: string[],
            studies: AsyncPagesI & {
                results: StudyI[]
            }
            users: AsyncPagesI & {
                results: StudyI[]
            }
        })

    },                         
    domains: AsyncDataI & {
        data: null | (AsyncPagesI & {
            result: AsyncPagesI & {
                results: DomainI[]
            }
        }),
    },
    filters_domains: FilterDomainReqI,
    filters_cases: FiltersCasesDomainReqI,
    filters_users: FiltersUserDomainReqI,
    // selectedsDomains: string[],
    selectedsCases: string[],
    selectedsUsers: string[],
}
interface ContextPropsDomainI extends ContextDomainStatesI {
    dispatch: {
        getDomain: (domainId: string, pageNumber?: number, pageSize?: number, tab?: "study" | "user",
            filtersDomain?: FiltersCasesDomainReqI, filtersUsers?: FiltersUserDomainReqI) => Promise<void>;
        getDomains: (filters?: any, pageNumber?: number, pageSize?: number) => Promise<void>;
        refreshDomains: () => void;
        setFiltersDomains: (key: string, value: string) => boolean;
        cleanFiltersDomains: () => void;
        setFiltersCases: (key: string, value: string) => boolean;
        cleanFiltersCases: () => void;
        setFiltersUsers: (key: string, value: string) => boolean;
        cleanFiltersUsers: () => void;
        // handleSelectedsDomains: (id: string) => void;
        // handleSelectAllDomains: () => void;
        handleSelectedsCases: (id: string) => void;
        handleSelectAllCases: () => void;
        handleSelectedsUsers: (id: string) => void;
        handleSelectAllUsers: () => void;
    }
}

const CasesContext = createContext({} as ContextPropsDomainI);

export default CasesContext;

export type { FilterDomainReqI, FiltersCasesDomainReqI, FiltersUserDomainReqI, ContextDomainStatesI }