import { CasesActionType } from "../actionsTypes";
import { } from "../actionsTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any, action: any) => {
    const { payload, type } = action;
    switch (type) {
        // Async methods
        case CasesActionType.GET_CASE_SUCCESS: return { ...state, study: { data: payload, loading: false, error: false, loaded: true } };
        case CasesActionType.GET_CASE_ERROR: return { ...state, study: { data: state.study.data, loading: false, error: payload, loaded: true } };
        case CasesActionType.GET_CASE_LOADING: return { ...state, study: { data: state.study.data, loading: true, error: false, loaded: false } };

        case CasesActionType.GET_CASES_SUCCESS: return { ...state, cases: { data: payload, loading: false, error: false, loaded: true, refresh: false } };
        case CasesActionType.GET_CASES_ERROR: return { ...state, cases: { data: state.cases.data, loading: false, error: payload, loaded: true, refresh: false } };
        case CasesActionType.GET_CASES_LOADING: return { ...state, cases: { data: state.cases.data, loading: true, error: false, loaded: false, refresh: false } };
        case CasesActionType.GET_CASES_REFRESH: return { ...state, cases: { ...state.cases, refresh: true } };

        case CasesActionType.GET_VARIANT_GROUPS_SUCCESS: return { ...state, variantGroups: { data: payload, loading: false, error: false, loaded: true } };
        case CasesActionType.GET_VARIANT_GROUPS_ERROR: return { ...state, variantGroups: { data: state.variantGroups.data, loading: false, error: payload, loaded: true } };
        case CasesActionType.GET_VARIANT_GROUPS_LOADING: return { ...state, variantGroups: { data: state.variantGroups.data, loading: true, error: false, loaded: false } };

        // Sync methods
        case CasesActionType.SET_FILTERS: return { ...state, filters: payload };
        case CasesActionType.SET_SELECTEDS: return { ...state, selecteds: payload };

        default:
            return state;
    }
}