import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { LangContext } from '../../Contexts/Lang';
import { Button, Card } from 'react-bootstrap';

interface ErrorPageI {
  match: any
}

export default function ErrorPage(props: ErrorPageI) {
  const { dispatch: { translate } } = useContext(LangContext);
  console.log("props.match")
  console.log(props.match)
  let type = "404";
  if (props.match?.params && Object.keys(props.match?.params).length > 0) {
    type = props.match?.params["0"] === "/503" ? "503" : type
  }
  console.log()
  console.log(type)
  let label = translate('errorPage.body404')
  if (type === "503") {
    label = translate('errorPage.body503')
  }

  return (
    <section className="background">
      <section style={{ "background": "rgb(90,90,90,0.2)", width: "100%", height: "350px" }}>
        <div style={{ display: "flex", placeContent: "center", marginTop: "3vh" }}>
          <Card border="danger" style={{ width: '25rem' }}>
            <Card.Header style={{ background: "rgb(125,125,125,0.1)" }}>
              <h1 style={{ color: "#DC3545", marginBottom: "0", fontSize: "55px", textAlign: "center" }}>
                {type}
                <FontAwesomeIcon icon={faExclamationCircle} style={{ marginLeft: "15px", border: "none", color: "#DC3545" }} />
              </h1>
            </Card.Header>
            <Card.Body>
              <p style={{ textAlign: "center", margin: "38px ​0px", color: "gray" }}>{label}</p>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Button variant="outline-info" style={{ float: "right" }}>
                  <FontAwesomeIcon icon={faHome} style={{ border: "none", fontSize: "20px" }} />
                </Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
      </section >
    </section >
  );
}
