import React from 'react';
import { useContext, useState } from "react";
import { LangContext } from "../../../../Contexts/Lang";
import VariantServices from "../../../../Services/VariantServices";
import { Col, Container, Row } from "react-bootstrap";
import CopyToClipboard from "../../../Common/CopyToClipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { LinkENSEMBL, LinkLocalGen, LinkNCBI } from '../../../Common/Links/Links';
import { ModalCommentGroupT, ModalCommentGroup } from "../../../Common/ModalCommentGroup/ModalCommentGroup";
import { ModalWarning } from "../../../Common/ModalWarning";
import { checkValueAndReturnAnother } from "../../../../utils/helpers";
import QueryContext from "../../../../Contexts/Query/QueryContext";
import ThemeContext from "../../../../Contexts/Theme/ThemeContext";
import { Tooltip } from "../../../Common/Tooltip/Tooltip";
import LiftOverGRChSwitcher from "../../../Common/LiftOverGRChSwitcher"
import CommentIconPopover from "src/Components/Common/ModalCommentGroup/CommentIconPopovert";

interface VariantIdTitleI {
	history: any,
	studyId: number,
	variantId: string,
	handleReload(): void,
	refreshQueryVariants(): void,
	refreshGenesVariants(): void,
	style?: React.CSSProperties
}
export default function VariantIdTitle(props: VariantIdTitleI) {
	const { variant } = useContext(QueryContext);
	const { theme } = useContext(ThemeContext);
	const { dispatch: { translate } } = useContext(LangContext);

	const VariantS = new VariantServices();

	const chPosition = `${variant.data?.variant?.chromosome ?? " - "}:${variant.data?.variant?.position ?? " - "}`;
	const gene = variant.loaded ? variant.data?.hugoGenes[`${Object.keys(variant.data?.hugoGenes)[0]}`] : undefined;
	const infoCd = variant.data?.variant?.infoColumnData

	const initFormikModal = { show: false, type: "comment" as ModalCommentGroupT, data: undefined, editData: undefined }
	const [commentGroupModal, setcommentGroupModal] = useState<{ show: boolean, type: ModalCommentGroupT, data: any, editData: any }>(initFormikModal);
	const [removeCommentModal, setremoveCommentModal] = useState<{ show: boolean, variants: string[] }>({ show: false, variants: [] });
	const handleCloseCommentGroupModal = () => { setcommentGroupModal(initFormikModal) }
	const handleShowCommentGroupModal = (type: ModalCommentGroupT, data: any, editData?: any) => {
		setcommentGroupModal({ show: true, type: type, data: data, editData: editData })
	}
	const handleShowRemoveCommentModal = (variants: string[]) => { setremoveCommentModal({ show: true, variants: variants }) }
	const handleCloseRemoveCommentModal = () => { setremoveCommentModal({ show: false, variants: [] }) }


	const getComment = () => {
		if (variant.data?.study?.comments &&
			Object.keys(variant.data.study.comments).indexOf(`${props.variantId}`) > -1) {
			return variant.data.study.comments[`${props.variantId}`]
		} else {
			return undefined
		}
	}

	const handleUncomentVariant = () => {
		if (removeCommentModal.variants)
			VariantS.uncomment_variants(props.studyId, removeCommentModal.variants)
				.then((res: any) => {
					// console.log("res");
					// console.log(res);
					if (res.status === 200) {
						handleCloseRemoveCommentModal();
						props.handleReload();
						props.refreshQueryVariants();
					}
				}).catch((e: any) => {
					console.log("e")
					console.log(e)
				})
	}



	const vcfIds = (variant.data?.variant?.vcfId ?? "").split(";")
		.filter((x: string) => x.toLowerCase().trim().startsWith("rs"))
		.map((x: any, i: number) => <span key={`${i}`} style={{ fontWeight: "bold" }}>
			<LinkNCBI dbsnpRs={x} fontSize={"15px"} />
			<CopyToClipboard valueToCopy={x} />&nbsp;
		</span>
		)
	const labelStyle: React.CSSProperties = { fontSize: "13px", alignSelf: "center", color: theme.grayHeader }


	const aaChEns = infoCd?.AACHANGE_ENSGENE ?? ""
	const tId = (infoCd?.TRANSCRIPT_ID ?? "").trim();
	let tIdV = tId;
	let variantId = " -- "
	if ((aaChEns ?? "").indexOf(tId) > -1 && tId.length > 1) {
		tIdV = aaChEns.substring(aaChEns.indexOf(tId)).split(":")[0].split(",")[0] // look for the transcript version
	}
	const aaChString = (infoCd?.AA_CHANGE && infoCd?.AA_CHANGE.trim().length > 1) ? `p.(${(infoCd?.AA_CHANGE).replace("p.", "")})` : "";
	variantId = `${tIdV}:${infoCd?.CODON_CHANGE} ${aaChString}`
	const linkEnsemblLabel = `${variant.data?.variant?.chromosome}:${variant.data?.variant?.position} ${variant.data?.variant?.reference ?? " - "}>${variant.data?.variant?.alternative ?? " - "}`

	return (<Container style={{ minWidth: "100%", ...(props.style ?? {}), boxShadow: "rgba(124, 124, 124, 0.35) 0px 5px 10px 0px" }}>
		<ModalWarning labelTitle={translate('common.confirm')}
			label={translate('casesQuery.doYouWantToRemoveCommentsOnTheSelectedVariants')}
			labelButton={translate('common.delete')}
			showState={removeCommentModal.show}
			handleClose={handleCloseRemoveCommentModal}
			onClick={handleUncomentVariant}
		/>
		<ModalCommentGroup show={commentGroupModal.show}
			handleClose={handleCloseCommentGroupModal}
			type={commentGroupModal.type}
			data={commentGroupModal.data}
			editData={commentGroupModal.editData}
			studyId={`${props.studyId}`}
			handleReload={() => {
				props.refreshQueryVariants();
				props.refreshGenesVariants();
				props.handleReload()
			}}
		/>

		<Row id={"variantId"} style={{ backgroundColor: theme.backgroundSecondary, padding: "10px 0 0px" }}>
			<Col xl={12} md={12} xs={12}>
				<h4 style={{ color: theme.grayHeader, marginLeft: "0px", marginBottom: "0px", textAlign: "center", fontSize: "20px", wordBreak: "break-word" }}>
					{/* {`NM_000540.2:c.11314C>T p.(Arg3772Trp)`}  // TARGET! */}
					{variantId}
					<CopyToClipboard valueToCopy={variantId} />
				</h4>
				<hr style={{ margin: "10px 5px 0", color: "white" }} />
			</Col>
			<Row id={"gen"} style={{ margin: "10px", width: "100%", fontSize: "12px" }}>
				{/* <hr /> */}
				<Col xl={12} md={12} xs={12} style={{ ...labelStyle }}>
					<label style={{ margin: 0, padding: 0 }}>
						{translate("common.gene")}&nbsp;: &nbsp;
						<LinkLocalGen geneName={gene?.approvedsymbol}
							caseId={`${props.studyId}`}
							variantId={`${props.variantId}`}
							fontSize={"15px"} />
						<CopyToClipboard valueToCopy={`${gene?.approvedsymbol}`} />
						&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
					</label>
					<label style={{ margin: 0, padding: 0 }}>
						RSID&nbsp;: &nbsp;
						{checkValueAndReturnAnother(variant.data?.variant?.vcfId, vcfIds)}
						&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
					</label>
					<label style={{ margin: 0, padding: 0 }}>
						{translate("common.chromosomePosition")}&nbsp;
						{checkValueAndReturnAnother(variant.data?.variant?.chromosome && variant.data?.variant?.position,
							<>
								{variant.data?.study?.genomeReference ? `(${variant.data?.study?.genomeReference})` : ""} : &nbsp;
								<LinkENSEMBL chromosome={variant.data?.variant?.chromosome}
									position={variant.data?.variant?.position}
									genomeRef={variant.data?.study?.genomeReference}
									fontSize={"15px"}
									label={linkEnsemblLabel}
								/>
								<CopyToClipboard valueToCopy={`${chPosition}`} />
								<LiftOverGRChSwitcher genomeReference={(variant.data?.study?.genomeReference ?? "").toUpperCase().trim() === "GRCH38" ? "38" : "19"}
									dbsnp={{
										chromosome: variant.data?.variant?.chromosome,
										position: variant.data?.variant?.position,
										reference: variant.data?.variant?.reference,
										alternative: variant.data?.variant?.alternative,
									}}
								/>
							</>)}
					</label>
					<span style={{ float: "right" }}>
						<CommentIconPopover placement={"left-start"}
							hasComment={getComment() ? true : false}
							variantId={props.variantId}
							variant_IdPosChangeGen={{
								id: variant.data?.variant?.id,
								position: `${variant.data?.variant?.chromosome}:${variant.data?.variant?.position}`,
								change: `${variant.data?.variant?.reference ?? " - "}>${variant.data?.variant?.alternative ?? " - "}`,
								gen: infoCd?.GENE_NAME,
							}}
							comment={getComment()}
							handleShowCommentGroupModal={handleShowCommentGroupModal}
							handleShowRemoveCommentModal={handleShowRemoveCommentModal}
						/>
						&nbsp;&nbsp;
						<Tooltip label={
							translate("variantDetail.downloadVcfVariant")
						}>
							<a href={`data:text/plain;charset=utf-8,${variant.data?.variant?.rawInfo}`} download="vcf_variant">
								<FontAwesomeIcon icon={faDownload} style={{ fontSize: "15px" }} />
							</a>
						</Tooltip>
					</span>
					<br />
				</Col>
			</Row>
		</Row>
	</Container>
	)
}