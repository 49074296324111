import React from 'react';
import { useContext } from "react";
import { LangContext } from "../../Contexts/Lang";
import ThemeContext from '../../Contexts/Theme/ThemeContext';
import TruncatedTextWithPopover from './TruncatedTextWithPopover';


interface NucleotidChangeI {
    clinvarAlleles: string,//"CAG/C" where CAG is the reference and C is the alternative (deletion of AG in this case)
}
export default function NucleotidChange(props: NucleotidChangeI) {
    const { theme } = useContext(ThemeContext);
    const { dispatch: { translate } } = useContext(LangContext);
    const getRef = () => {
        if (!props.clinvarAlleles || props.clinvarAlleles.length < 3) return "-"
        const ref = props.clinvarAlleles.split("/")[0];
        return <TruncatedTextWithPopover popoveTitle={translate("common.reference")} popoveContent={ref} />
    }
    const getAlt = () => {
        let alt: any = props.clinvarAlleles.split("/");
        if (!props.clinvarAlleles || props.clinvarAlleles.length < 3 || alt.length < 2) return "-"
        alt = alt[1];
        return <TruncatedTextWithPopover popoveTitle={translate("common.alternative")} popoveContent={alt} />
    }

    return (
        <span style={{ color: theme.grayHeader, fontWeight: "bold", margin: 0, display: "flex", height: "fit-content" }}>
            {getRef()}&nbsp;{">"}&nbsp;{getAlt()}
        </span>

    )
}
