import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';
import { serialize } from './utils';

export default class QuerySheetServices extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "querySheet/";
    }

    async save_results(caseId: string,
        req: any,
        resultsTitle: string,
        resultsDescription: string,
        pageSize?: 25,
        pageNumber?: 1,
    ): Promise<AxiosResponse<any>> {
        // console.log("req")
        // console.log(req)
        const paramsReq = serialize(req)
        let filtersEncrypteds = window.btoa(paramsReq)
        const url = `${this.basePath}saveResults`
        const config = {
            ...this.authHeader,
            params: {
                domainId: this.user?.domain,
                studyId: +caseId,
                advancedFiltersModal: filtersEncrypteds,
                userId: this.user?.id,
                resultsTitle: resultsTitle,
                resultsDescription: resultsDescription,
                pageSize: pageSize,
                pageNumber: pageNumber,
                resultId: "",
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }
    async edit_results(caseId: number, queryId: number, title: any, description: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}editResults`
        const config = {
            ...this.authHeader,
            params: {
                domainId: this.user?.domain,
                resultsId: queryId,
                studyId: caseId,
                userId: this.user?.id,
                resultsTitle: title,
                resultsDescription: description,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }

    async delete_query(queryId: string): Promise<AxiosResponse<any>> {
        // querySheet/deleteResults?resultsId=172&_=1643104109232
        const url = `${this.basePath}deleteResults`
        const config = {
            ...this.authHeader,
            params: {
                resultsId: queryId,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)

    }
}
