import React from 'react';
import { useContext } from 'react';
import { LangContext } from "../../../../../Contexts/Lang";
import { useWindowSize } from '../../../../../Helpers/WindowsSizeHelper';
import QueryTitle from "../../QueryTitle";
import { Collapse } from 'react-bootstrap';
import QueryContext from '../../../../../Contexts/Query/QueryContext';

interface QueryBodyEmptyEmptyI {
    history: any,
    filterCollapsed: boolean,
}

export default function QueryBodyEmpty(props: QueryBodyEmptyEmptyI) {
    const { queryCombos } = useContext(QueryContext);
    const { dispatch: { translate } } = useContext(LangContext);
    const { widthType } = useWindowSize()

    return (
        <div style={{ display: "flex", justifyContent: "space-between", padding: "0 13px", width: "100%" }}>
            <Collapse in={!props.filterCollapsed} dimension="width">
                <div id="example-collapse-text">
                    <div style={{ width: (widthType === "xs" || widthType === "sm") ? '35px' : '380px' }}>
                        .
                    </div>
                </div>
            </Collapse>
            <div style={{ marginTop: '15px', width: "100%" }}>
                <div style={{ marginLeft: '20px' }}>
                    <QueryTitle history={props.history}
                        study={queryCombos.data?.study} />
                </div>
                <hr style={{ margin: 0 }} />
                <h6 style={{ padding: "20px", marginLeft: "15px" }} //onClick={handleGoToCasesDetail}
                >
                    {translate("casesQuery.advancedQuery").toUpperCase()}
                </h6>
                <div style={{ width: "100%", height: "35vh", textAlign: "center", paddingTop: "150px" }}>
                    <p style={{ fontSize: "11px" }}>
                        {translate("casesQuery.noQueryWasDoneYet").toUpperCase()}
                    </p>
                </div>
            </div>
        </div>
    )
}