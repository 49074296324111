import geneNameIds from './geneNameIds.json';
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext } from "react"
import { Button } from "react-bootstrap"
import { Tooltip } from '../Tooltip/Tooltip';
import { LangContext } from 'src/Contexts/Lang';

export default function CustomAcmgGuideByGene(props: { geneName: string }) {
    const { dispatch: { translate } } = useContext(LangContext);
    const indexOfGene = Object.keys(geneNameIds).indexOf(props.geneName);
    if (indexOfGene !== -1) {
        const url = `https://cspec.genome.network/cspec/ui/svi/svi/${Object.values(geneNameIds)[indexOfGene]}`
        return (
            <Tooltip label={translate("common.customAcmgGuideInfo")}>
                <Button variant="outline-warning" href={url} target="_blank"
                    style={{ padding: "0 4px", height: "21px", borderRadius: "10px", border: "none", margin: "3px" }}
                >
                    <FontAwesomeIcon icon={faStar} style={{ fontSize: "13px", marginTop: "3px" }} />
                </Button>
            </Tooltip>
        )
    }
    return (<></>)
}


