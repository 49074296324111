import React from 'react';
import { Button, Form } from 'react-bootstrap';
// import { LangContext } from "../../../../../Contexts/Lang";
import "./sidebar.css";
// import { Tooltip } from "../../../../../Components/Tooltip";
import FormInput from '../../../../Common/Form/FormInput';
import ReactSelect from '../../../../Common/Form/ReactSelect';
import { MySlider, RangeSlider } from '../../../../Common/Form/SliderRange';
import { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, placement } from '../../../../Common/Tooltip/Tooltip';

type formInputType = "number" | "text" | "textarea" | "password" | "email"

interface FilterInputI {
    type: formInputType,
    state: any,
    setState: any,
    name: string,
    label: any,
    description?: string,
    placeholder?: string
}
function FilterInput(props: FilterInputI) {
    return (
        <div key={props.name}>
            <FormInput type={props.type}
                labelBold={true}
                labelColor={"#333"}
                // borderRadius={true}
                label={props.label}
                placeHolder={props.placeholder}
                name={props.name}
                formLabelStyle={{ marginBottom: "3px" }}
                formControlStyle={{ height: "calc(1.5em + .75rem + -2px)" }}       // onKeyDown={(e: any) => handleEnter(e)}
                value={props.state[props.name]}
                onChange={(e: any) => {
                    // console.log(e.target.value)
                    var newState = props.state;
                    newState[`${props.name}`] = e.target.value
                    props.setState({ ...newState })
                }} />
            {props.description &&
                <p style={{ color: "gray", fontSize: "11px", "marginBottom": 0 }}>
                    {props.description}
                </p>
            }
        </div>
    )
}

interface FilterSelectI {
    id?: string,
    options: any[],
    label: any,
    state: any,
    setState: any,
    name: string,
    isMulti?: boolean,
    inputNameSearch?: string,
    placeholder?: string
    disabled?: boolean
}
function FilterSelect(props: FilterSelectI) {
    return (
        <ReactSelect options={props.options}
            id={props.id}
            height="29px"
            label={props.label}
            labelBold={true}
            isMulti={props.isMulti ?? false}
            labelStyles={{ marginBottom: "3px", color: "#333", zIndex: 2 }}
            placeholder={props.placeholder}
            disabled={props.disabled}
            value={props.state[`${props.name}`] ?? []}
            onInputChange={(e: any) => {
                var newState = props.state;
                if (props.inputNameSearch) {
                    newState[`${props.inputNameSearch}`] = e
                    props.setState({ ...newState })
                }
            }}
            onChange={(e: any) => {
                var newState = props.state;
                newState[`${props.name}`] = e
                props.setState({ ...newState })
            }}
        />)
}

interface FilterRangeSliderI {
    options: any[],
    state: any,
    setState: any,
    name: string,
    label: any,
    rangeButtons?: {
        label: string,
        value: number
    }[],
    rangeButtonsInverted?: boolean
    doublePresicion?: number,
}
function FilterRangeSlider(props: FilterRangeSliderI) {
    function handleSliderRangeButton(value: number) {
        var newState = props.state;
        if (props.rangeButtonsInverted) newState[`${props.name}`] = [value, props.options[props.options.length - 1]]
        else newState[`${props.name}`] = [props.options[0], value]
        props.setState({ ...newState })
    }
    return <div id="FilterRangeSlider">
        <div style={{ margin: "0 5px", display: "flex", justifyContent: "space-between" }}>
            <div style={{ margin: "2px -5px", color: "#333", fontWeight: "bold", maxWidth: "240px" }}>
                {props.label}
            </div>
            <div style={{ display: "flex", marginBottom: "15px", justifyContent: "flex-end" }}>
                {props.rangeButtons && props.rangeButtons.map((x: any, i: number) => {
                    return <SliderRangeButton key={i} label={x.label} handler={() => handleSliderRangeButton(x.value)} />
                })}
            </div>
        </div>
        <RangeSlider arrayData={props.options}
            values={props.state[`${props.name}`]}
            handleData={(e: any) => {
                var newState = props.state;
                newState[`${props.name}`] = e
                props.setState({ ...newState })
            }}
            doublePresicion={props.doublePresicion}
        />
    </div>
}


interface FilterSliderI {
    options: any[],
    state: any,
    setState: any,
    name: string,
    label: any,
    doublePresicion?: number,
    rangeButtons?: {
        label: string,
        value: number
    }[]
}
function FilterSlider(props: FilterSliderI) {
    function handleSliderRangeButton(value: number) {
        var newState = props.state;
        newState[`${props.name}`] = value
        props.setState({ ...newState })
    }

    return <div id="FilterSlider">
        <div style={{ margin: "0 5px", display: "flex", justifyContent: "space-between", height: "30px" }}>
            <div style={{ margin: "2px -5px", color: "#333", fontWeight: "bold" }}>
                {props.label}
            </div>
            <div style={{ display: "flex", marginBottom: "15px", justifyContent: "flex-end" }}>
                {props.rangeButtons && props.rangeButtons.map((x: any, i: number) => {
                    return <SliderRangeButton key={i} label={x.label} handler={() => handleSliderRangeButton(x.value)} />
                })}
            </div>
        </div>
        <MySlider arrayData={props.options}
            value={props.state[`${props.name}`]}
            handleData={(e: any) => {
                var newState = props.state;
                newState[`${props.name}`] = e
                props.setState({ ...newState })
            }}
            doublePresicion={props.doublePresicion}
        />
    </div>
}

interface FilterRadioButtonsI {
    label: string,
    state: any,
    setState: any,
    name: string,
    radios?: {
        label: string,
        value: any
    }[]
}
function FilterRadioButtons(props: FilterRadioButtonsI) {
    return (
        <div id="FilterRadioButtons">
            <p style={{ margin: 0, color: "#333", fontWeight: "bold" }}>
                {props.label}
            </p>
            <div style={{ display: "flex" }}>
                {props.radios && props.radios.map((x: any, i: number) => {
                    return (
                        <Form.Check type={"radio"} style={{ margin: "0 5px" }}
                            label={x.label}
                            key={i}
                            checked={props.state[`${props.name}`]?.value?.toLowerCase() === x.value.toLowerCase()}
                            onChange={() => {
                                var newState = props.state;
                                newState[`${props.name}`] = x
                                props.setState({ ...newState })
                            }}
                        />)
                })}
            </div>
        </div>)
}

interface FilterInformationI {
    label: string,
    link: string,
    styles?: CSSProperties,
    tooltipPosition?: placement
}
function FilterInformation(props: FilterInformationI) {
    return (
        <Tooltip label={props.label} placement={props.tooltipPosition}>
            <a href={props.link} target="_blank" rel="noreferrer">
                {/* {props.label} */}
                <FontAwesomeIcon icon={faExternalLinkAlt} style={{ fontSize: "10px", margin: "1px 5px", color: "#009EEA", ...(props.styles ?? {}) }} />
            </a>
        </Tooltip>
    )
}
interface FilterExcludeCheckboxI {
    label: string | JSX.Element,
    state: any,
    setState: any,
    name: string,
    pStyle?: object
}
function FilterExcludeCheckbox(props: FilterExcludeCheckboxI) {
    return (
        <div id="FilterExcludeCheckbox" style={{ display: 'flex' }}>
            <Form.Check type="checkbox"
                checked={props.state[`${props.name}`] ?? false}
                onChange={
                    () => {
                        var newState = props.state;
                        newState[`${props.name}`] = !props.state[`${props.name}`]
                        props.setState({ ...newState })
                    }}
            />
            <div style={{ margin: "0 -3px 3px", color: "#333", 
              alignSelf: "flex-end", fontSize: "11px", ...( props.pStyle?props.pStyle : {} ) }}>
                {props.label}
            </div>
        </div>
    )
}

interface SliderRangeButtonI {
    label: string,
    handler: any
}
function SliderRangeButton(props: SliderRangeButtonI) {
    return (
        <Button id="SliderRangeButton"
            variant="outline-secondary"
            onClick={props.handler}
            style={{
                padding: "5px",
                height: "25px",
                fontSize: "10px",
                marginRight: "2px",
            }}>{props.label}</Button>
    )
}

export {
    FilterInput,
    FilterSelect,
    FilterRangeSlider,
    FilterSlider,
    FilterRadioButtons,
    FilterInformation,
    FilterExcludeCheckbox
}