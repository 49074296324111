import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { placement, Tooltip } from './Tooltip/Tooltip';
import { Popover } from '../../Components/Common/Popover';
type trigger = 'click' | 'focus' | "hover"
interface IconButtonTooltipPopoverI {
    icon: any,
    tooltipLabel?: string,
    onClick?: any,
    placement?: placement,
    popOver?: {
        title?: any,
        content: any,
        trigger?: trigger,
        place?: placement
    }
    autoClosePopover?: number,
    autoCloseTooltip?: number,
    fontSize?: string,
    style?: any,
    popoverStyle?: any,
    popoverBodyStyle?: any,
    iconStyle?: any,
    show?: boolean,
}
export default function IconButtonTooltipPopover(props: IconButtonTooltipPopoverI) {
    let style = props.style ?? {}
    let popoverStyle = props.popoverStyle ?? {}
    const iconStyle = props.iconStyle ?? {}
    let butoon = (
        <Button variant="outline-light"
            onClick={(e: any) => { e.preventDefault(); e.stopPropagation(); if (props.onClick) props.onClick(e) }}
            style={{
                border: "none",
                borderRadius: "50px",
                padding: "3px",
                marginLeft: "3px",
                marginRight: "3px",
                ...style
            }}>
            {
                props.popOver ?
                    <Popover
                        placement={props.popOver.place}
                        title={props.popOver.title}
                        content={props.popOver.content}
                        autoClose={props.autoClosePopover}
                        trigger={props.popOver.trigger}
                        show={props.show}
                        contentStyle={props.popoverBodyStyle}
                        style={popoverStyle}
                    >
                        <FontAwesomeIcon icon={props.icon}
                            style={{ fontSize: props.fontSize ?? "13px", margin: "3px 5px", color: "darkgray", verticalAlign: "middle", ...iconStyle }} />
                    </Popover>
                    :
                    <FontAwesomeIcon icon={props.icon}
                        style={{ fontSize: "13px", margin: "3px 5px", color: "darkgray", verticalAlign: "middle", ...iconStyle }} />
            }
        </Button >)
    if (props.tooltipLabel) {
        return (
            <Tooltip
                autoClose={props.autoCloseTooltip}
                label={props.tooltipLabel}
                placement={props.placement ?? "top"}>

                {butoon}
            </Tooltip>
        )
    }
    return butoon
}
