import React, { useContext } from 'react';
import { useMemo } from "react";
import { Container, Row, Image } from "react-bootstrap";
import { Spinner } from 'react-bootstrap';
import dnaGif from "./LoaderDNA300x225.gif"
import { LangContext } from 'src/Contexts/Lang';
type loaderType = "DNA" | "SPINNER";
interface LoaderDNAI {
    label?: string
    color?: string
    fontSize?: number
    onContainer?: boolean
    type?: loaderType
}
export default function Loader(props: LoaderDNAI) {
    const { dispatch: { translate } } = useContext(LangContext);
	const label = props.label ?? `${translate('common.loading')}...`
    const fontSize = props.fontSize ? `${props.fontSize}px` : "11px"
    const color = props.color ?? 'gray'
    const onContainer = props.onContainer ?? false;
    const dna = useMemo(() => {
        return (
            <>
                <span style={{
                    height: 40,
                    width: 200,
                    zIndex : 2,
                    fontSize: fontSize,
                    color: color,
                    position: "absolute",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    display: "flex",
                    margin: 0
                }} >
                    {label}
                </span >
                <Image src={dnaGif} style={{ width: "200px", zIndex : 1 }}/>
            </>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const spinner = (
        <>
            <div style={{ marginTop: "40px" }}>
                <span style={{
                    height: 150,
                    width: 150,
                    color: color,
                    fontSize: fontSize,
                    position: "absolute",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    margin: 0
                }} >
                    {label}
                </span >
                <Spinner animation="border" role="status">
                    <span className="sr-only">{label}</span>
                </Spinner>
            </div>
        </>)
    const loader = props.type === "DNA" ? dna : spinner
    if (!onContainer) return loader;
    return (
        <Container style={{ marginTop: "0px", display: "contents" }}>
            <Row className="justify-content-md-center"
                style={{ backgroundColor: "white", width: "100%", margin: "0px", marginTop: "20px", justifyContent: "center"  }}>
                {loader}
                {/* {spinner} */}
            </Row>
        </Container>
    )
}