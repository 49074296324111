import React, { useContext } from 'react';
import { Col, Row, Table } from "react-bootstrap"
import Frequency from "./Frequency"
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
// import { useWindowSize } from 'src/Helpers/WindowsSizeHelper';
import { LangContext } from 'src/Contexts/Lang';
import BadgeAbsentInGnomad from './Badges/BadgeAbsentInGnomad';

interface TableVariantFrequencyI {
    infoCd?: any
    type?: "detail" | "advancedQuerys"
}
export default function TableVariantFrequency(props: TableVariantFrequencyI) {
    let frequencies: Frequency;
    const type = props.type ?? "detail"
    const isDetail = type === "detail";
    const { theme } = useContext(ThemeContext);
    const { dispatch: { translate } } = useContext(LangContext);
    // const { widthType } = useWindowSize()
    // const sizeXxl = widthType === "xxl"
    const warningnIcon = <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: "5px", border: "none", fontSize: "12px", color: "#F28B00" }} />
    if (!props.infoCd) return <></>
    frequencies = new Frequency(props.infoCd);
    if (!frequencies.thereIsGnomadExome && !frequencies.thereIsGnomadGenome)
        return (<div style={{marginTop: 10}}><BadgeAbsentInGnomad /><br /></div>);
    const getLabelColumn = (label: string, style?: any) => {
        return isDetail ? (
            <Col xs={6} sm={6} md={5} lg={5} xl={4}
                style={{
                    // textAlign: "right",
                    color: "gray",
                    // fontWeight: "bold",
                    fontSize: 12,
                    padding: 0,
                    ...(style ? style : {})
                }}>
                {label}
            </Col >
        ) :
            <Col xs={2} sm={2} md={2} lg={3} xl={3}
                style={{
                    // textAlign: "right",
                    color: "gray",
                    // fontWeight: "bold",
                    fontSize: 11,
                    padding: 0,
                    ...(style ? style : {})
                }}>
                {label}
            </Col >
    }
    const getDataColumn = (checkData: any, data: string | JSX.Element, style?: any) => {
        return (
            <Col style={{
                // textAlign: "right",
                // color: theme.grayHeader,
                padding: 0,
                paddingLeft: isDetail ? 10 : 2,
                ...(style ? style : {})
            }}>
                {checkData === undefined ? "-" : data}
            </Col >
        )
    }
    const getPercentAndProbValue = (value: any, hasWarning: boolean) => {
        return (<>
            {(parseFloat(value ?? "0") * 100).toFixed(2)} %
            <span style={{ fontSize: 9, verticalAlign: "middle" }}> &nbsp;({value}) </span>
            {isDetail && hasWarning && warningnIcon}
        </>)
    }
    // const getValidation = (val1: any, val2: any) : boolean => {
    //     return frequencies.gnomadExome.alleleFreq || frequencies.gnomadExome.alleleFreq === 0 ||
    //     frequencies.gnomadGenome.alleleFreq || frequencies.gnomadGenome.alleleFreq === 0
    // }
    const rowStyle = { borderBottom: `solid 1px ${theme.background4}`, marginRight: 0, marginLeft: 0 }
    const alleleFreqRow = (!isDetail ? (frequencies.gnomadExome.alleleFreq !== undefined || frequencies.gnomadGenome.alleleFreq !== undefined) : true) ?
        <Row style={rowStyle}>
            {getLabelColumn(translate("common.GNOMAD_EXOME_AF"))}
            {getDataColumn(frequencies.gnomadExome.alleleFreq, getPercentAndProbValue(frequencies.gnomadExome.alleleFreq, frequencies.gnomadExome.hasWarning))}
            {getDataColumn(frequencies.gnomadGenome.alleleFreq, getPercentAndProbValue(frequencies.gnomadGenome.alleleFreq, frequencies.gnomadGenome.hasWarning))}
        </Row> : <></>;
    const alleleCountRow = (!isDetail ? (frequencies.gnomadExome.alleleCount !== undefined || frequencies.gnomadGenome.alleleCount !== undefined) : true) ?
        <Row style={rowStyle}>
            {getLabelColumn(isDetail ? translate("common.GNOMAD_EXOME_AC") : "AC", { fontWeight: isDetail ? "auto" : "bold" })}
            {getDataColumn(frequencies.gnomadExome.alleleCount, <>{frequencies.gnomadExome.alleleCount}</>)}
            {getDataColumn(frequencies.gnomadGenome.alleleCount, <>{frequencies.gnomadGenome.alleleCount}</>)}
        </Row> : <></>;
    const alleleNumbRow = (!isDetail ? (frequencies.gnomadExome.alleleNumb !== undefined || frequencies.gnomadGenome.alleleNumb !== undefined) : true) ?
        <Row style={rowStyle}>
            {getLabelColumn(translate("common.GNOMAD_EXOME_AN"))}
            {getDataColumn(frequencies.gnomadExome.alleleNumb, <>{frequencies.gnomadExome.alleleNumb}</>)}
            {getDataColumn(frequencies.gnomadGenome.alleleNumb, <>{frequencies.gnomadGenome.alleleNumb}</>)}
        </Row> : <></>;
    const nHomRow = (!isDetail ? (frequencies.gnomadExome.nHom !== undefined || frequencies.gnomadExome.nHom !== undefined) : true) ?
        <Row style={rowStyle}>
            {getLabelColumn(isDetail ? translate("common.GNOMAD_EXOME_NHOM") : "HOM", { fontWeight: isDetail ? "auto" : "bold" })}
            {getDataColumn(frequencies.gnomadExome.nHom, <>{frequencies.gnomadExome.nHom}</>)}
            {getDataColumn(frequencies.gnomadGenome.nHom, <>{frequencies.gnomadGenome.nHom}</>)}
        </Row> : <></>;
    const popMaxAlleleFreqRow = (!isDetail ? (frequencies.gnomadExome.popMaxAlleleFreq !== undefined || frequencies.gnomadGenome.popMaxAlleleFreq !== undefined) : true) ?
        <Row style={rowStyle}>
            {getLabelColumn(isDetail ? translate("common.GNOMAD_EXOME_POPMAX_AF") : "AF", { paddingLeft: "0", fontWeight: isDetail ? "auto" : "bold" })}
            {getDataColumn(frequencies.gnomadExome.popMaxAlleleFreq, getPercentAndProbValue(frequencies.gnomadExome.popMaxAlleleFreq, frequencies.gnomadExome.hasWarning))}
            {getDataColumn(frequencies.gnomadGenome.popMaxAlleleFreq, getPercentAndProbValue(frequencies.gnomadGenome.popMaxAlleleFreq, frequencies.gnomadGenome.hasWarning))}
        </Row> : <></>;
    const titleStyles = { color: theme.grayHeader, fontWeight: "bold", padding: "4px 0px 0px 10px", fontSize: isDetail ? 13 : 10 };
    return (
        <>
            <div style={{ display: "flex" }}>
                <Table>
                    <Row style={{ ...rowStyle, borderBottom: `solid 3px ${theme.background4}`, }} >
                        {getLabelColumn("")}
                        {getDataColumn(true, "Exomes", titleStyles)}
                        {getDataColumn(true, "Genomes", titleStyles)}
                    </Row>
                    {popMaxAlleleFreqRow}
                    {isDetail ? alleleFreqRow : alleleCountRow}
                    {isDetail ? alleleCountRow : nHomRow}
                    {isDetail ? alleleNumbRow : ""}
                    {isDetail ? nHomRow : ""}
                </Table >
            </div>

        </>
    )
}