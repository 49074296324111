import React from 'react';
import { useContext } from 'react';
import { LangContext } from "../../Contexts/Lang";
import { Table, Button } from 'react-bootstrap';
import { faDna, faRandom } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '../Common/Tooltip/Tooltip';
import { AlignmentT } from './types';

interface SamplesInfoTableI {
    chPosition: string;
    gene: string;
    change: string;
    hasAlignment?: boolean;
    sampleData: undefined | {
        name: string;
        badge: JSX.Element;
        info: JSX.Element | string;
    }[];
    handleSampleSelected(sampleName: string, alignmentT: AlignmentT): void;

}
export default function SamplesInfTableo(props: SamplesInfoTableI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const getAlignmentButton = (sampleName: string, type: AlignmentT, color: string) => {
        const label = type === "gene" ? translate('casesQuery.geneAlignment') : translate('casesQuery.variantAlignment');
        return (
            <Tooltip label={label}>
                <Button variant="outline-secondary"
                    style={{ margin: "0 2px", height: "fit-content", border: "none", padding: "3px 7px", borderRadius: "50px" }}
                    onClick={() => {
                        props.handleSampleSelected(sampleName, type);
                    }}>
                    <FontAwesomeIcon icon={type === "gene" ? faDna : faRandom} style={{ fontSize: "12px" }} />
                </Button>
            </Tooltip>
        );
    };
    return (
        <div style={{
            background: "linear-gradient(to bottom, #f4f4f4, #FFFFFF)",
            padding: "20px",
            // borderTop: "solid 1px lightgray"
        }}>
            <div>
                <span style={{ color: "darkslategray", fontWeight: "bold", padding: "5px", fontSize: "12px" }}>
                    {translate(`common.variant`)}:&nbsp;&nbsp;
                    {props.chPosition}&nbsp;&nbsp;&nbsp;&nbsp;
                    {props.change}&nbsp;&nbsp;|&nbsp;&nbsp;
                    {props.gene}
                </span>
            </div>

            <Table size="xl">
                <thead>
                    <tr style={{ "backgroundColor": "#E8E8E8", margin: 0, padding: "5px" }}>
                        <th style={{ fontWeight: "normal" }}>{translate(`common.variants`)}</th>
                        <th style={{ fontWeight: "normal" }}>{translate(`casesQuery.sampleInformation`)}</th>
                        {/* {props.hasAlignment && <th style={{ fontWeight: "normal" }}>{translate(`casesQuery.viewAlignment`)}</th>} */}
                    </tr>
                </thead>
                <tbody>
                    {props?.sampleData &&
                        props?.sampleData.map((x: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        {x.badge}
                                    </td>
                                    <td>
                                        {x.info}
                                        {props.hasAlignment &&
                                            <div style={{ float: 'right' }}>
                                                {getAlignmentButton(x.name, "variant", "lightgray")}
                                                &nbsp;
                                                {getAlignmentButton(x.name, "gene", "lightgray")}
                                            </div>}
                                    </td>
                                </tr>);
                        })}
                </tbody>
            </Table>
        </div>
    );
}
