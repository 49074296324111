// predictors info:
// https://usf.app.box.com/s/r505gv70i1jpzgt2qwyip12no513ehac
//TODO:: CREAR CLASS DE PREDICTORES!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
type PredictorsMetaT = "bayesdel_addaf" | "bayesdel_noaf" | "revel_score"
type PredictorsPredT = "cadd" | "dann" | "ea" | "fathmm" | "mpc" | "mutpred2" | "polyphen2" | "sift" | "vest" | "mutation_taster" | "lrt" | "provean" | "mcap" | "alpha_missense"
type PredictorsSpliT = | "ada" | "rf"
type PredictorsConsT = | "primateai" | "phylop" | "gerp" | "phast_cons"
type PredictorsAllT = PredictorsMetaT | PredictorsPredT | PredictorsSpliT | PredictorsConsT;

type VeredictT = "US" | "B" | "P"
type conditionT = ">" | ">=" | "<" | "<=" | "==="
type PredictorT = "META" | "PRED" | "CONS" | "SPLI";

interface MetaPredictorsNamesI {
    REVELSCORE?: any,
    BAYESDEL_ADDAF_SCORE?: any,
    BAYESDEL_NOAF_SCORE?: any
}
interface PredictorsNamesI {
    CADD_RAW?: any,
    CADD_1000G_SCORE?: any,
    DANN_SCORE?: any,
    FATHMM_SCORE?: any,
    LRT_PRED?: any,
    LRT_SCORE?: any,
    M_CAP_PRED?: any,
    M_CAP_SCORE?: any,
    MUTATIONTASTER?: any,
    MUTATIONTASTER_SCORE?: any,
    POLYPHEN2_HDIV_PRED?: any,
    POLYPHEN2_HDIV_SCORE?: any,
    PROVEAN_PRED?: any,
    PROVEAN_SCORE?: any,
    SIFT_SCORE?: any,
    VEST4_SCORE?: any,
    AM_PATHOGENICITY?:any, //Alpha Missense score, Ex: 0.1
    AM_CLASS?:any,//Alpha Missense veredict, Ex: Pathogenic
}
interface AllPredictorsNamesI extends MetaPredictorsNamesI, PredictorsNamesI { };


interface PredictorsVeredictI {
    type: PredictorsAllT,
    pathogenic?: { value: number | string[], condition: conditionT },
    benign?: { value: number | string[], condition: conditionT }
}
interface PredictorI {
    id: PredictorsAllT,
    type: PredictorT,
    scoreFieldName: String[],
    predFieldName?: String[],
    valueByTranscript?: boolean,
    veredict?: PredictorsVeredictI,
}

const predictorsVeredictsData: PredictorsVeredictI[] = [    //https://genome.ucsc.edu/cgi-bin/hgVai
    //metapredictors
    { type: "bayesdel_addaf", pathogenic: { value: 0.13, condition: ">=" }, benign: { value: -0.36, condition: "<=" } },
    { type: "bayesdel_noaf", pathogenic: { value: 0.13, condition: ">=" }, benign: { value: -0.18, condition: "<=" } },
    { type: "revel_score", pathogenic: { value: 0.644, condition: ">=" }, benign: { value: 0.290, condition: "<=" } },
    //single predictors
    { type: "cadd", pathogenic: { value: 25.3, condition: ">=" }, benign: { value: 22.7, condition: "<=" } },
    { type: "dann", pathogenic: { value: 0.9, condition: ">=" }, benign: { value: 0.1, condition: "<=" } },
    { type: "fathmm", pathogenic: { value: -4.14, condition: "<=" }, benign: { value: 3.32, condition: ">=" } },
    { type: "lrt", pathogenic: { value: ["D"], condition: "===" }, benign: { value: ["N"], condition: "===" } },
    { type: "mcap", pathogenic: { value: ["A", "D"], condition: "===" }, benign: { value: ["N", "P"], condition: "===" } },
    { type: "mutation_taster", pathogenic: { value: ["A", "D"], condition: "===" }, benign: { value: ["N", "P"], condition: "===" } },
    { type: "provean", pathogenic: { value: ["D"], condition: "===" }, benign: { value: ["N"], condition: "===" } },
    { type: "polyphen2", pathogenic: { value: 0.978, condition: ">=" }, benign: { value: 0.113, condition: "<=" } },
    { type: "sift", pathogenic: { value: 0.001, condition: "<=" }, benign: { value: 0.080, condition: ">=" } },
    { type: "vest", pathogenic: { value: 0.764, condition: ">=" }, benign: { value: 0.449, condition: "<=" } },
    { type: "alpha_missense", pathogenic: { value: ["PATHOGENIC", "LIKELY_PATHOGENIC"], condition: "===" }, benign: { value: ["LIKELY_BENIGN", "BENIGN" ], condition: "===" } },
    //conservartion
    { type: "phast_cons", pathogenic: { value: 0.5, condition: "<" }, benign: { value: 0.5, condition: ">=" } },
    { type: "gerp", pathogenic: { value: 2.7, condition: "<=" }, benign: { value: 2.7, condition: ">" } },
    { type: "phylop", pathogenic: { value: 1.879, condition: "<" }, benign: { value: 7.367, condition: ">=" } },
    { type: "primateai", pathogenic: { value: 0.79, condition: ">=" }, benign: { value: 0.483, condition: "<=" } },
    //splice
    { type: "ada", pathogenic: { value: 0.708, condition: ">=" }, benign: { value: 0.708, condition: "<" } },
    { type: "rf", pathogenic: { value: 0.515, condition: ">=" }, benign: { value: 0.515, condition: "<" } },
    //???
    { type: "ea", pathogenic: { value: 0.685, condition: ">=" }, benign: { value: 0.262, condition: "<=" } },
    { type: "mpc", pathogenic: { value: 1.36, condition: ">=" } },
    { type: "mutpred2", pathogenic: { value: 0.737, condition: ">=" }, benign: { value: 3.91, condition: "<=" } },
];
const predictorsVeredictMap: Map<PredictorsAllT, PredictorsVeredictI> = new Map(predictorsVeredictsData.map(item => [item.type, item]));

//ASOCIACOR CADA UNO DE ESTOS A POREDICTORVERDECIT
const predictorsData: PredictorI[] = [
    { type: "META", id: "bayesdel_addaf", scoreFieldName: ["BAYESDEL_ADDAF_SCORE"], valueByTranscript: false, veredict: predictorsVeredictMap.get("bayesdel_addaf") },
    { type: "META", id: "bayesdel_noaf", scoreFieldName: ["BAYESDEL_NOAF_SCORE"], valueByTranscript: false, veredict: predictorsVeredictMap.get("bayesdel_noaf") },
    { type: "META", id: "revel_score", scoreFieldName: ["REVELSCORE"], valueByTranscript: true, veredict: predictorsVeredictMap.get("revel_score") },
    { type: "PRED", id: "cadd", scoreFieldName: ["CADD_RAW", "CADD_1000G_SCORE"], valueByTranscript: false, veredict: predictorsVeredictMap.get("cadd") },
    { type: "PRED", id: "dann", scoreFieldName: ["DANN_SCORE"], valueByTranscript: false, veredict: predictorsVeredictMap.get("dann") },
    { type: "PRED", id: "fathmm", scoreFieldName: ["FATHMM_SCORE"], valueByTranscript: true, veredict: predictorsVeredictMap.get("fathmm") },
    { type: "PRED", id: "lrt", scoreFieldName: ["LRT_SCORE"], predFieldName: ["LRT_PRED"], valueByTranscript: false, veredict: predictorsVeredictMap.get("lrt") },
    { type: "PRED", id: "mcap", scoreFieldName: ["M_CAP_SCORE"], predFieldName: ["M_CAP_PRED"], valueByTranscript: false, veredict: predictorsVeredictMap.get("mcap") },
    { type: "PRED", id: "mutation_taster", scoreFieldName: ["MUTATIONTASTER", "MUTATIONTASTER_SCORE"], predFieldName: ["MUTATIONTASTER_PRED"], valueByTranscript: true, veredict: predictorsVeredictMap.get("mutation_taster") },
    { type: "PRED", id: "provean", scoreFieldName: ["PROVEAN_SCORE"], predFieldName: ["PROVEAN_PRED"], valueByTranscript: true, veredict: predictorsVeredictMap.get("provean") },
    { type: "PRED", id: "polyphen2", scoreFieldName: ["POLYPHEN2_HDIV_SCORE"], predFieldName: ["POLYPHEN2_HDIV_PRED"], valueByTranscript: true, veredict: predictorsVeredictMap.get("polyphen2") },
    { type: "PRED", id: "sift", scoreFieldName: ["SIFT_SCORE"], valueByTranscript: true, veredict: predictorsVeredictMap.get("sift") },
    { type: "PRED", id: "vest", scoreFieldName: ["VEST4_SCORE"], valueByTranscript: true, veredict: predictorsVeredictMap.get("vest") },
    { type: "PRED", id: "alpha_missense", scoreFieldName: ["AM_CLASS"], predFieldName: ["AM_PATHOGENICITY"], valueByTranscript: false , veredict: predictorsVeredictMap.get("alpha_missense") },
    
    { type: "CONS", id: "gerp", scoreFieldName: ["GERP_RS"], valueByTranscript: false, veredict: predictorsVeredictMap.get("gerp") },
    { type: "CONS", id: "phylop", scoreFieldName: ["PHYLOP"], valueByTranscript: false, veredict: predictorsVeredictMap.get("phylop") },
    { type: "CONS", id: "primateai", scoreFieldName: ["VEST4_SCORE"], valueByTranscript: false, veredict: predictorsVeredictMap.get("primateai") },
    { type: "CONS", id: "phast_cons", scoreFieldName: ["PHASTCONS"], valueByTranscript: false, veredict: predictorsVeredictMap.get("phast_cons") },

    { type: "SPLI", id: "ada", scoreFieldName: ["DBSCSNV_ADA_SCORE"], valueByTranscript: false, veredict: predictorsVeredictMap.get("ada") },
    { type: "SPLI", id: "rf", scoreFieldName: ["DBSCSNV_RF_SCORE"], valueByTranscript: false, veredict: predictorsVeredictMap.get("rf") },
]
// let predictorIdValue: Map<VeredictPredictorsIdT, PredictorI> = new Map();
const predictorsMap: Map<PredictorsAllT, PredictorI> = new Map(predictorsData.map(item => [item.id, item]));

const getPredictorsByType = (type: PredictorT): Map<PredictorsAllT, PredictorI> => {
    const predFiltered = predictorsData.filter(p => p.type === type)
    return new Map(predFiltered.map(item => [item.id, item]));
}

const allPredictorsNull: AllPredictorsNamesI = {
    REVELSCORE: null, BAYESDEL_ADDAF_SCORE: null, BAYESDEL_NOAF_SCORE: null, CADD_RAW: null, DANN_SCORE: null, FATHMM_SCORE: null,
    LRT_PRED: null, LRT_SCORE: null, M_CAP_PRED: null, M_CAP_SCORE: null, MUTATIONTASTER: null, MUTATIONTASTER_SCORE: null,
    PROVEAN_SCORE: null, PROVEAN_PRED: null, SIFT_SCORE: null, VEST4_SCORE: null, POLYPHEN2_HDIV_SCORE: null, POLYPHEN2_HDIV_PRED: null,
    AM_CLASS: null,
}

const allPredictorsNames: String[] = [];
predictorsData.forEach((p: PredictorI) => {
    if ((p.type === "META" || p.type === "PRED") && p.scoreFieldName) allPredictorsNames.push(...p.scoreFieldName);
    if ((p.type === "META" || p.type === "PRED") && p.predFieldName) allPredictorsNames.push(...p.predFieldName);
});
let predictorsFieldsNamesByTranscript : String[] = [];
predictorsData.forEach((p: PredictorI) => {
    if ((p.type === "META" || p.type === "PRED") && p.scoreFieldName && p.valueByTranscript) predictorsFieldsNamesByTranscript.push(...p.scoreFieldName);
    if ((p.type === "META" || p.type === "PRED") && p.predFieldName  && p.valueByTranscript) predictorsFieldsNamesByTranscript.push(...p.predFieldName);
});

export {
    predictorsVeredictMap,
    predictorsMap,
    getPredictorsByType,
    allPredictorsNull,
    allPredictorsNames,
    predictorsFieldsNamesByTranscript
}
export type {
    VeredictT,
    PredictorsVeredictI,
    MetaPredictorsNamesI,
    PredictorsNamesI,
    AllPredictorsNamesI,
    PredictorsMetaT,
    PredictorsPredT,
    PredictorsSpliT,
    PredictorsConsT,
    PredictorsAllT
}
