import React from 'react';
import { Button, Card, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faEnvelope, IconDefinition, faUsers, faUser } from '@fortawesome/free-solid-svg-icons';
import FormInput from "../../Common/Form/FormInput";
import { useEffect, useState, useContext, useRef } from "react";
import { LangContext } from '../../../Contexts/Lang';
import { formInputType } from '../../Common/Interfaces';
import * as yup from "yup";
import { Formik } from "formik";
import ReportedVariantService, { createUpdateReportedVariantI } from "../../../Services/ReportedVariantService";
import { variantAcmgValues } from "../../../utils/BioFunctionalData";
import { DetailTableVariant } from "../Detail/DetailReportedVariantsTables";
import UserContext from "../../../Contexts/User/UserContext";

// type formInputType = "number" | "text" | "textarea" | "password" | "email"
interface ProcessReportedVariantI {
    history: any,
    editVariantId?: string,
    addVariant?: {
        studyId: string
        variants: string
        samplenames: string
    }
    show: boolean,
    handleClose: any,
    handleReload?: any
}
export default function ProcessReportedVariant(props: ProcessReportedVariantI) {
    const { user } = useContext(UserContext);
    const { dispatch: { translate } } = useContext(LangContext);

    const [loading, setloading] = useState<boolean>(true)
    const [study, setstudy] = useState<any>({})
    const [reportedVariant, setreportedVariant] = useState<any>(false)

    const ReportedVariantS = new ReportedVariantService();

    let formRef: any = useRef();
    useEffect(() => {
        let isMounted = true;
        if (isMounted && props.editVariantId && !reportedVariant) {
            ReportedVariantS.readReportedVariant(props.editVariantId)
                .then((res: any) => {
                    // console.log("res")
                    // console.log(res)
                    if (isMounted && res.status === 200) {
                        formRef?.current?.setValues({
                            decision: (res.data.reportedVariantInfo.decision ?? "").trim().toLowerCase(),
                            diagnostic: res.data.reportedVariantInfo.diagnostic,
                            clasification: res.data.reportedVariantInfo.classification,
                            confirmMethod: res.data.reportedVariantInfo.confirmation,
                            referenceGenome: res.data.reportedVariantInfo.genomeReference,
                            library: res.data.reportedVariantInfo.libraryPrepKit,
                            lineage: (res.data.reportedVariantInfo.lineage ?? "").trim().toLowerCase(),
                            observation: res.data.reportedVariantInfo.observation,
                            studyCase: res.data.reportedVariantInfo.studyName,
                            samples: res.data.reportedVariantInfo.samples,

                            institution: res.data.reportedVariantInfo.institution,
                            professional: res.data.reportedVariantInfo.contact,
                            email: res.data.reportedVariantInfo.email,

                            sharedUserProfile: res.data.reportedVariantInfo.shared,
                        })
                        setstudy({
                            name: res.data.reportedVariantInfo.studyName,
                            samplesNames: res.data.reportedVariantInfo.samples
                        })
                        setreportedVariant(res.data.reportedVariantInfo)
                    }
                    setloading(false)
                })
                .catch((e: any) => {
                    console.log(e)
                    if (isMounted) setloading(false)
                })
        } else {
            if (props.addVariant && !reportedVariant) {

                ReportedVariantS.getReportedVariant(props.addVariant.studyId, props.addVariant.variants, props.addVariant.samplenames)
                    .then((res: any) => {
                        // console.log("res")
                        // console.log(res)
                        if (isMounted && res.status === 200) {
                            setstudy(res.data.study)
                            setreportedVariant(res.data.reportedVariantInfo)
                            formRef?.current?.setValues({
                                referenceGenome: res.data.study.genomeReference,
                                studyCase: res.data.study.name,

                                institution: user.data.username,
                                professional: user.data.username,
                                email: user.data.email,

                                decision: (res.data.reportedVariantInfo.decision ?? "").trim().toLowerCase(),
                                diagnostic: res.data.reportedVariantInfo.diagnostic ?? "",
                                clasification: res.data.reportedVariantInfo.classification ?? "",
                                confirmMethod: res.data.reportedVariantInfo.confirmation ?? "",
                                library: res.data.reportedVariantInfo.libraryPrepKit ?? "",
                                lineage: (res.data.reportedVariantInfo.lineage ?? "").trim().toLowerCase(),
                                observation: res.data.reportedVariantInfo.observation ?? "",
                                samples: res.data.reportedVariantInfo.samples ?? "",
                                sharedUserProfile: res.data.reportedVariantInfo.shared ?? "",
                            })
                        }
                        setloading(false)
                    })
                    .catch((e: any) => {
                        console.log(e)
                        if (isMounted) setloading(false)
                    })
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const detailTableVariant = (
        <DetailTableVariant
            vcfId={reportedVariant?.vcfId}
            chromosome={reportedVariant.chromosome}
            genomeReference={reportedVariant.genomeReference}
            position={reportedVariant.position}
            genename={reportedVariant?.reportedVariant?.genename}
            reference={reportedVariant?.reference}
            alternative={reportedVariant?.alternative}
            impact={reportedVariant?.reportedVariant?.impact}
            effect={reportedVariant?.reportedVariant?.effect}
            codon={reportedVariant?.codon}
            classification={reportedVariant?.classification}
        />
    )

    function initializeValues() {
        return {
            decision: "", //*
            diagnostic: "", //*
            clasification: "",//*
            confirmMethod: "",
            referenceGenome: "",//*
            library: "",
            lineage: "",//*
            observation: "",
            studyCase: "",
            samples: "",
            institution: "", //* 
            professional: "", //*
            email: "", //*
            sharedUserProfile: "",
        }
    }
    function createSchema() {
        const decision = yup.string().required(translate(`error.required`));
        const diagnostic = yup.string().required(translate(`error.required`));
        const clasification = yup.string().required(translate(`error.required`));
        const confirmMethod = yup.string();
        const lineage = yup.string().required(translate(`error.required`));
        const referenceGenome = yup.string();
        const library = yup.string();
        const observation = yup.string();
        const studyCase = yup.string();
        const samples = yup.string();
        const institution = yup.string().required(translate(`error.required`));
        const professional = yup.string().required(translate(`error.required`));
        const email = yup.string().email('Contact Information must be a email').required(translate(`error.required`));
        const sharedUserProfile = yup.boolean();
        return yup.object().shape({
            decision,
            diagnostic,
            clasification,
            confirmMethod,
            referenceGenome,
            library,
            lineage,
            observation,
            studyCase,
            samples,
            institution,
            professional,
            email,
            sharedUserProfile
        })
    }
    function createForm(values: any, handleChange: any, errors: any) {
        function getSelectOptions(array: any[], addCeroValue: boolean = false) {
            if (addCeroValue)
                array = [{ value: undefined, label: "..." }, ...array]
            return array?.map((x: any, i: number) =>
                <option key={i} value={x.value}>{x.label}</option>
            )

        }
        function commonFormInput(type: formInputType, key: string, name: string, label: string | JSX.Element, placeholder?: string, required: boolean = false) {
            return <FormInput type={type}
                labelBold={true}
                labelColor={"gray"}
                // borderRadius={true}
                label={label === "" ? undefined : label}
                key={key}
                formGrouplStyle={{ "marginBottom": "5px" }}       // onKeyDown={(e: any) => handleEnter(e)}
                formLabelStyle={{ "marginBottom": "5px", fontSize: "13px" }}
                placeHolder={placeholder}
                name={name}
                // disabled={props.mode === "read"}
                required={required}
                requiredFeedback={errors[name]}
                feedbackValidator={!!errors[name]}
                value={values[name]}
                onChange={handleChange} />
        }
        function commonFormSelect(key: string, name: string, options: any, label: string, required: boolean = false) {
            return <Form.Group key={key} style={{ marginBottom: "5px" }} >
                <Form.Label style={{ borderRadius: "0", color: "gray", fontSize: "13px", marginBottom: "5px", display: "flex" }}>
                    <span><strong>{label}</strong></span>
                    {required && <label style={{ color: "#DD3D4D", margin: 0 }}>&nbsp;*</label>}
                </Form.Label>
                <Form.Control as="select" aria-label="Default select example" style={{ borderRadius: 0, fontSize: "12px" }}
                    name={name}
                    value={values[name]}
                    onChange={(e: any) => { return handleChange(e) }}
                    isInvalid={!!errors[name]}
                >
                    {options}
                </Form.Control>
                <Form.Control.Feedback type="invalid"> {errors[name]} </Form.Control.Feedback>
            </Form.Group>
        }
        function commonCheckbox(key: string, name: string, label?: string | JSX.Element) {
            return (<label style={{ display: "flex", fontSize: "12px", color: "gray", alignItems: "center" }}>
                <Form.Check type="checkbox"
                    key={key}
                    name={name}
                    value={values[name]}
                    checked={values[name]}
                    onChange={() => {
                        let newValues = formRef?.current?.values
                        newValues[name] = !values[name]
                        formRef?.current?.setValues(newValues)
                        // return handleChange(true) 
                    }}
                />
                {label && label}
            </label>
            )
        }
        const decisionOptions = [
            { label: translate("variant.decisionOptions.discarded"), value: "discarded" },
            { label: translate("variant.decisionOptions.hold"), value: "hold" },
            { label: translate("variant.decisionOptions.reported"), value: "reported" }
        ]
        const lineageValues = [
            { label: translate("variant.lineageOptions.germline"), value: "germline" },
            { label: translate("variant.lineageOptions.somatic"), value: "somatic" },
            { label: translate("variant.lineageOptions.mosaic"), value: "mosaic" }

        ]
        const getCommonIconLabel = (icon: IconDefinition, label: string, required?: boolean) => {
            return (
                <span style={{ display: "flex", alignItems: "center", color: "gray", justifyContent: "flex-end", fontSize: "12px" }}>
                    {label}
                    &nbsp;
                    {required && <>
                        <span style={{ color: "#DD3B4A" }}> * </span>
                        &nbsp;
                    </>
                    }
                    <FontAwesomeIcon icon={icon} style={{ border: "none", fontSize: "12px" }} />&nbsp;
                </span>
            )
        }
        const emailLabel = getCommonIconLabel(faEnvelope, translate(`common.email`), true)
        const professionalLabel = getCommonIconLabel(faUser, translate(`common.professional`), true)
        const sharedUserProfileLabel = getCommonIconLabel(faUsers, "Public")
        const decision = commonFormSelect("decisionKey", "decision", getSelectOptions(decisionOptions, true), translate("common.decision"), true);
        const diagnostic = commonFormInput("textarea", "diagnosticKey", "diagnostic", translate("common.diagnostic"), `...`, true)
        const clasification = commonFormSelect("clasificationKey", "clasification", getSelectOptions(variantAcmgValues, true), translate("common.classification"), true);
        const confirmMethod = commonCheckbox("confirmMethodKey", "confirmMethod", "Sanger u otro metodo independiente")
        const referenceGenome = commonFormInput("text", "referenceGenomeKey", "referenceGenome", translate("common.referenceGenome"), `...`, true);
        const library = commonFormInput("text", "libraryKey", "library", translate(`common.library`), `...`)
        const lineage = commonFormSelect("lineageKey", "lineage", getSelectOptions(lineageValues, true), translate("variant.lineage"), true);
        const observation = commonFormInput("textarea", "observationKey", "observation", translate(`common.observation`), `...`)
        const studyCase = commonFormInput("text", "studyCaseKey", "studyCase", translate(`common.case`), `...`)
        const samples = commonFormInput("text", "sampleKey", "samples", translate(`common.samples`), `...`)
        const sharedUserProfile = commonCheckbox("sharedUserProfileKey", "sharedUserProfile")
        const institution = commonFormInput("text", "institutionKey", "institution", "", `...`, true)
        const professional = commonFormInput("text", "professionalKey", "professional", "", `...`, true)
        const email = commonFormInput("email", "emailKey", "email", "", `...`, true)

        const reportForm = (
            <>
                <Row style={{ margin: 0 }}>
                    <Col md={6}>{decision}</Col>
                    <Col md={6}>{clasification}</Col>
                </Row>
                <Row style={{ margin: 0 }}>
                    <Col md={12}>{diagnostic}</Col>
                    <Col md={12}>{confirmMethod}</Col>
                </Row>
                <Row style={{ margin: 0 }}>
                    <Col md={4}>{referenceGenome}</Col>
                    <Col md={4}>{library}</Col>
                    <Col md={4}>{lineage}</Col>
                    <Col md={12}>{observation}</Col>
                </Row>
            </>
        )
        const reportedByCard = (
            <Row style={{ padding: "0", margin: 0 }}>
                <ListGroup style={{ width: "100%" }}>
                    <ListGroup.Item style={{ padding: "5px", backgroundColor: "#FAFAFA", borderRadius: 0, marginBottom: 0 }}>
                        <label style={{ fontSize: " 16px", color: "#444B59", margin: "6px" }}>Reported by</label><br />
                    </ListGroup.Item>
                </ListGroup>
                <Card style={{ width: "100%" }}>
                    <Card.Body style={{ backgroundColor: "#FDFDFD", padding: "5px 15px" }}>
                        <Row style={{ margin: 0 }}>
                            <Col xs={6} sm={4} lg={4} style={{ padding: "5px", textAlign: "end" }}>
                                <label style={{
                                    fontWeight: "bold",
                                    color: "gray",
                                    marginBottom: 0,
                                    verticalAlign: "sub"
                                }}>
                                    {translate("common.institution")}
                                    <span style={{ color: "#DD3B4A" }}> * </span>
                                </label>
                            </Col>
                            <Col xs={6} sm={8} lg={8} style={{ padding: "5px" }}>
                                {institution}
                            </Col>
                        </Row>
                        <hr style={{ margin: "5px 0px 10px" }} />

                        <Row style={{ margin: 0, padding: 0 }}>
                            <Col xs={6} sm={4} lg={4} style={{ padding: "5px", alignSelf: "center" }}>
                                {emailLabel}
                            </Col>
                            <Col xs={6} sm={8} lg={8} style={{ padding: "5px" }}>
                                {email}
                            </Col>
                        </Row>
                        <Row style={{ margin: 0 }}>
                            <Col xs={6} sm={4} lg={4} style={{ padding: "5px", alignSelf: "center" }}>
                                {professionalLabel}
                            </Col>
                            <Col xs={6} sm={8} lg={8} style={{ padding: "5px" }}>
                                {professional}
                            </Col>
                        </Row>
                        <Row style={{ margin: 0 }}>
                            <Col xs={6} sm={4} lg={4} style={{ padding: "5px", alignSelf: "center" }}>
                                {sharedUserProfileLabel}
                            </Col>
                            <Col xs={6} sm={8} lg={8} style={{ padding: "5px" }} >
                                {sharedUserProfile}
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </Row>
        )
        if (loading)
            return (
                <Card><Card.Body>

                </Card.Body ></Card >
            )
        return (
            <Card>
                <Card.Body>
                    <Row style={{ margin: 0 }}>
                        <Col lg={6} xl={6}>
                            {detailTableVariant}
                        </Col>
                        <Col lg={6} xl={6}>
                            {reportedByCard}
                        </Col>
                    </Row>
                    <hr style={{ margin: "15px -20px" }} />
                    {reportForm}
                    <hr style={{ margin: "15px -20px" }} />
                    <Row style={{ margin: 0 }}>
                        <Col md={6}>
                            {studyCase}
                        </Col>
                        <Col md={6}>
                            {samples}
                        </Col>
                    </Row>
                    {/* <hr style={{ margin: "15px -20px 5px" }} /> */}

                </Card.Body >
            </Card >
        )
    }
    function getTitle() {
        const iconStyles = { border: "none", marginRight: "7px", fontSize: "27px", verticalAlign: "text-bottom" }
        let title = <>
            <FontAwesomeIcon icon={faPlus} style={{ color: "#34AC4f", ...iconStyles }} />
            <strong>{translate(`variant.addReportedVariant`)}</strong>
        </>
        if (props.editVariantId)
            title = <>
                <FontAwesomeIcon icon={faEdit} style={{ color: "#007BFF", ...iconStyles }} />
                <strong>{translate(`variant.editReportedVariant`)}</strong>
            </>
        return (
            <Modal.Title style={{ margin: "0px 10px", color: "#FFF" }}>
                {title}
            </Modal.Title>)
    }
    const title = getTitle()

    function handleSubmit(data: any) {
        let request: createUpdateReportedVariantI = {
            study: study.name,
            sample: Array.isArray(study.samplesNames) ? study.samplesNames.join(",") : study.samplesNames,
            id: `${reportedVariant.study}`,
            varInfoId: props.editVariantId ?? "",
            varid: `${reportedVariant?.reportedVariant?.id}`,
            classification: data.clasification,
            validated: data.confirmMethod ? "on" : "",
            contact: data.professional,
            decision: data.decision,
            diagnostic: data.diagnostic,
            email: data.email,
            genomeReference: data.referenceGenome,
            institution: data.institution,
            libraryPrepKit: data.library,
            lineage: data.lineage,
            observation: data.observation,
            shared: data.shared ? "true" : ""
        }
        function handleCreatePanel() {
            request.varInfoId = props.addVariant?.variants ?? ""
            request.id = props.addVariant?.studyId ?? ""
            return ReportedVariantS.createReportedVariant(request)
                .then((res: any) => {
                    if (res.status === 200) {
                        if (res.data.errors === "geneset.error.alreadyExists") {
                            formRef.current.setErrors(
                                { "name": `${translate(`common.name`)} ${translate(`error.alreadyDefined`)}` })
                        } else {
                            if (props.handleReload)
                                props.handleReload()
                            props.handleClose()
                        }
                    }
                }).catch((e: any) => {
                    console.log("e")
                    console.log(e)
                })
        }
        function handleEditPanel() {
            return ReportedVariantS.updateReportedVariant(request)
                .then((res: any) => {
                    if (res.status === 200) {
                        if (res.data.errors === "geneset.error.alreadyExists") {
                            formRef.current.setErrors(
                                { "name": `${translate(`common.name`)} ${translate(`error.alreadyDefined`)}` })
                        } else {
                            if (props.handleReload)
                                props.handleReload()
                            props.handleClose()
                        }
                    }
                }).catch((e: any) => {
                    console.log("e")
                    console.log(e)
                })
        }
        if (props.editVariantId) handleEditPanel()
        else handleCreatePanel()
    }

    return (
        <Modal show={props.show} onHide={props.handleClose} dialogClassName="my-modal" >
            <Formik initialValues={initializeValues()}
                validationSchema={createSchema()}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                // handleSubmit={console.log}
                innerRef={formRef}
                validateOnChange={false}
                validateOnBlur={true}>
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: "#444B59" }}>
                                {title}
                            </Modal.Header>
                            <Modal.Body style={{ padding: "0" }}>
                                {createForm(values, handleChange, errors)}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={props.handleClose}>
                                    {translate(`common.close`)}
                                </Button>
                                <Button variant="primary" type="submit">
                                    {translate(`common.save`)}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                )}
            </Formik>
        </Modal>
    )
}
