import React from 'react';
import NavbarB from './NavbarB';
import "../Common/table.css"
import NavbarSecondary from './NavbarSecondary';
import { useContext, useEffect } from 'react';
import UserContext from '../../Contexts/User/UserContext';
import UserService from '../../Services/UserServices';
import ThemeContext from '../../Contexts/Theme/ThemeContext';
import Footer from './Footer';
import { Caching } from 'src/utils/helpers';
interface HomePropsI {
    history: any,
    children: JSX.Element
}
export function Layout(props: HomePropsI) {
    const { theme } = useContext(ThemeContext);
    const { user } = useContext(UserContext);
    const US = new UserService();
    Caching()
    useEffect(() => {
        let isMounted = true;
        if (isMounted && !user?.loaded) {
            if (Object.keys(US.user ?? {}).length === 0 || !US?.user?.id) {
                localStorage.removeItem("_Buser");
                // console.log("_Buser removed from localstorage")
                props.history.push("/login")
            }
        }
        return () => { isMounted = false }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div id="Layout" style={{ backgroundColor: theme.backgroundSecondary }}>
            <NavbarB history={props.history} />
            <NavbarSecondary history={props.history} />
            <div style={{ minHeight: "calc(100vh - 38px)", padding: "72px 14px 0px 23px" }}>
                {props.children}
            </div>
            <Footer />
        </div>
    );
}


export { Footer }