import React from "react";
import { useContext } from "react";
import ThemeContext from "src/Contexts/Theme/ThemeContext";
import hotspotsIcon from '../../../imgs/somatic/hotspots.png';
import oncokbIcon from '../../../imgs/somatic/oncokb.png';
import cosmicIcon from '../../../imgs/somatic/cosmic.png';
import civicIcon from '../../../imgs/somatic/civic.png';
import pmkbIcon from '../../../imgs/somatic/pmkb.png';
import { Tooltip } from "../Tooltip/Tooltip";
import { LangContext } from "src/Contexts/Lang";
import { civicGeneId } from "src/utils/BioFunctionalData";
import LinkTooltip from "../Links/LinkTooltip";
import { parseAminoAcidSequence } from "src/utils/AminoParser";

function CommonDbIcon(dbIcon: any, name: string, hasIcon: boolean = true, link: string | null = null, size: string | null = null) {
	const { theme } = useContext(ThemeContext);
	const { dispatch: { translate } } = useContext(LangContext);
	let icon = <></>
	if (hasIcon)
		icon = <img src={dbIcon} style={{ height: size ?? "17px", verticalAlign: "bottom" }} alt="Cosmic" />;
	else
		icon = <label style={{
			height: size ?? "17px",
			width: size ?? "17px",
			verticalAlign: "bottom",
			borderRadius: "10px",
			fontWeight: "bold",
			backgroundColor: theme.backgroundThird,
			textAlign: "center",
			marginBottom: 0,
			// paddingTop: "1px"
		}}>
			{dbIcon}
		</label>;
	if (!link) return icon;
	return <Tooltip label={`${translate("common.linkTo")} ${name}`}>
		<a href={link}
			style={{
				margin: "2px"
				//  fontSize: (props.fontSize ?? "auto") 
			}}
			target="_blank"
			rel="noreferrer"
			onClick={(e: any) => e.stopPropagation()}>
			{icon}
		</a>
	</Tooltip>
}

interface HotspotsDbIconI {
	link?: "home"
}
function HotspotsDbIcon(props: HotspotsDbIconI) {
	if (!props.link) return CommonDbIcon(hotspotsIcon, "Cancer Hotspots")
	let dblink = "https://www.cancerhotspots.org/"
	return CommonDbIcon(hotspotsIcon, "Cancer Hotspots", true, dblink)
}
type linkT = "home" | "variant" | "gene";

interface OncokbDbIconI {
	link?: linkT,
	gene?: string, genevariation?: string,
	size?: string,
	label?: string,
}
function OncokbDbIcon(props: OncokbDbIconI) {
	const { dispatch: { translate } } = useContext(LangContext);
	if (!props.link) return CommonDbIcon(oncokbIcon, "OncoKB", true, null, props.size)
	let dblink = "https://www.oncokb.org/";
	if (props.link === "variant" && props.gene && props.gene.trim().length > 2 && props.genevariation && props.genevariation.trim().length > 2) {
		//https://www.oncokb.org/gene/BRAF/V600E 
		dblink = `${dblink}gene/${props.gene}/${props.genevariation}`
	} else if (props.link === "gene") {
		dblink = `${dblink}gene/${props.gene}`
	}
	if (props.label) {
		return <Tooltip label={`${translate("common.linkTo")} OncoKB`}>
			<a href={dblink}
				// style={{ margin: "2px" }}
				target="_blank"
				rel="noreferrer"
				onClick={(e: any) => e.stopPropagation()}>
				{props.label}
			</a>
		</Tooltip>
	} else
		return CommonDbIcon(oncokbIcon, "OncoKB", true, dblink, props.size)
}

interface CosmicDbIconI {
	link?: "home" | "variant",
	cosmicId?: string
}
function CosmicDbIcon(props: CosmicDbIconI) { //culd be the comsic legacy(COSM4163566) or the actual id(COSV53026075)
	if (!props.link) return CommonDbIcon(cosmicIcon, "COSMIC")
	let dblink = "https://cancer.sanger.ac.uk/cosmic"
	if (props.link === "variant" && props.cosmicId && props.cosmicId.trim().length > 2)
		//https://cancer.sanger.ac.uk/cosmic/search?q=COSV5377801
		dblink = `${dblink}/search?q=${props.cosmicId}`
	return CommonDbIcon(cosmicIcon, "COSMIC", true, dblink)
}
interface PmkbDbIconI {
	link?: string
	defaultLink?: boolean
}
function PmkbDbIcon(props: PmkbDbIconI) { //culd be the comsic legacy(COSM4163566) or the actual id(COSV53026075)
	if (!props.link && !props.defaultLink) return CommonDbIcon(pmkbIcon, "PMKB")
	let dblink
	if (props.defaultLink)
		dblink = "https://pmkb.weill.cornell.edu/"
	else if (props.link && props.link.trim().length > 2)
		dblink = props.link
	return CommonDbIcon(pmkbIcon, "PMKB", true, dblink)
}

interface CivicDbIconI {
	link?: "home",
	linkDir?: string,
	gene?: string
}
function CivicDbIcon(props: CivicDbIconI) {
	if (!props.link) return CommonDbIcon(civicIcon, "CIViC", true)
	let dblink = "https://civicdb.org/"
	if (props.linkDir) return CommonDbIcon(civicIcon, "CIViC", true, props.linkDir)
	if (props.gene && props.gene in civicGeneId) dblink = `${dblink}genes/${civicGeneId[props.gene]}`
	return CommonDbIcon(civicIcon, "CIViC", true, dblink)
}



const getOncoKbLinkFromInfoCD = (infoColumnData: any, geneName: string, size?: string, linkt: linkT = "variant", label?: string) => {
	let link: any = false;
	if (linkt === "variant" && infoColumnData.ONCOKB_ALTERATION && infoColumnData.ONCOKB_ALTERATION.trim().length > 2)
		link = <OncokbDbIcon link={linkt} gene={geneName} genevariation={infoColumnData.ONCOKB_ALTERATION} size={size} label={label} />
	if (linkt === "gene")
		link = <OncokbDbIcon link={linkt} gene={geneName} size={size} label={label} />
	return link;
}
const getCosmicLinkFromInfoCD = (infoColumnData: any) => {
	let link: any = false;
	if (infoColumnData.COSMIC_GENOMIC_MUTATION_ID && infoColumnData.COSMIC_GENOMIC_MUTATION_ID.trim().length > 2)
		link = <CosmicDbIcon link="variant" cosmicId={infoColumnData.COSMIC_GENOMIC_MUTATION_ID} />
	else if (infoColumnData.COSMIC_LEGACY_MUTATION_ID && infoColumnData.COSMIC_LEGACY_MUTATION_ID.trim().length > 2)
		link = <CosmicDbIcon link="variant" cosmicId={infoColumnData.COSMIC_LEGACY_MUTATION_ID} />
	return link;
}
const getCivicLinkFromInfoCD = (infoColumnData: any) => {
	let link: any = false;
	if ((infoColumnData.CIVIC_VN && infoColumnData.CIVIC_VN.trim().length > 2) ||
		(infoColumnData.CIVIC_CSQ && infoColumnData.CIVIC_CSQ.trim().length > 2)) {
		const civic: string = infoColumnData.CIVIC_CSQ;
		if (civic.indexOf("https://civicdb.org/links/variants") > -1) {
			const linkDir = civic.slice(civic.indexOf("https://civicdb.org/links/variants"), civic.length - 1).split(",")[0];
			link = <CivicDbIcon link="home" linkDir={linkDir} />
		} else
			link = <CivicDbIcon link="home" gene={infoColumnData.GENE_NAME} />
	}
	return link;
}
const getHotspotsLinkFromInfoCD = (infoColumnData: any) => {
	let link: any = false;
	if (infoColumnData.HOTSPOTS_HUGO_SYMBOL && infoColumnData.HOTSPOTS_HUGO_SYMBOL.trim().length > 2)
		link = <HotspotsDbIcon link="home" />
	return link;
}

interface LinkOncoTreeI {
	fontSize?: string;
}
function LinkOncoTree(props: LinkOncoTreeI) {
	const { dispatch: { translate } } = useContext(LangContext);
	return (
		<LinkTooltip tooltipLabel={`${translate("common.goTo")} OncoTree`}
			href={`https://oncotree.mskcc.org/`}
			label={`OncoTree`}
			fontSize={props.fontSize ?? "auto"}
		/>
	)
}
interface LinkPMKBI {
	fontSize?: string;
}
function LinkPMKB(props: LinkPMKBI) {
	const { dispatch: { translate } } = useContext(LangContext);
	return (
		<LinkTooltip tooltipLabel={`${translate("common.goTo")} PMKB - Precision Medicine Knowledgebase`}
			href={`https://pmkb.weill.cornell.edu/`}
			label={`PMKB`}
			fontSize={props.fontSize ?? "auto"}
		/>
	)
}
interface LinkCancerVariantsI {
	geneName: string,
	aaChange: string,
	fontSize?: string;
}
function LinkCancerVariants(props: LinkCancerVariantsI) {
	const { dispatch: { translate } } = useContext(LangContext);
	let aaChange = props.aaChange.replace("p.", "")
	aaChange = parseAminoAcidSequence(aaChange);
	return (
		<LinkTooltip tooltipLabel={`${translate("common.goTo")} Cancer Variants`}
			href={`https://search.cancervariants.org/#${props.geneName}%20${aaChange}`}
			label={`MetaKB`}
			fontSize={props.fontSize ?? "auto"}
		/>
	)
}

interface LinkMyCancerGenomeI {
	geneName?: string, //example
	aaChange?: string, //amino acid change with aa in one letter, example r132c
	fontSize?: string;
	parse?: boolean;
}
function LinkMyCancerGenome(props: LinkMyCancerGenomeI) {
	const { dispatch: { translate } } = useContext(LangContext);
	// https://www.mycancergenome.org/content/alteration/idh1-r132c/
	const href = `https://www.mycancergenome.org/`
	let link = <></>
	if (!props.geneName || !props.aaChange) {
		link = (
			<LinkTooltip tooltipLabel={`${translate("common.goTo")} MyCancerGenome`}
				href={`${href}`}
				label={`MyCancerGenome`}
				fontSize={props.fontSize ?? "auto"}
			/>
		)
	} else {
		const geneName = props.geneName.trim();
		let aaChange = props.aaChange.trim();
		if (geneName.length < 2 && aaChange.length < 2) return <></>
		if (props.parse) {
			aaChange = props.aaChange.replace("p.", "")
			aaChange = parseAminoAcidSequence(aaChange);
		}
		link = (
			<LinkTooltip tooltipLabel={`${translate("common.goTo")} MyCancerGenome`}
				href={`${href}content/alteration/${geneName}-${aaChange}`}
				label={`MyCancerGenome`}
				fontSize={props.fontSize ?? "auto"}
			/>
		)
	}
	return link;
}


export {
	// SomatDbIcon,
	HotspotsDbIcon,
	OncokbDbIcon,
	CosmicDbIcon,
	CivicDbIcon,
	PmkbDbIcon,
	getOncoKbLinkFromInfoCD,
	getCosmicLinkFromInfoCD,
	getCivicLinkFromInfoCD,
	getHotspotsLinkFromInfoCD,

	LinkPMKB,
	LinkMyCancerGenome,
	LinkOncoTree,
	LinkCancerVariants
}