import { ThemeActionType } from "../actionsTypes";
import { } from "../actionsTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any, action: any) => {
    // const { payload, type } = action;
    const { payload, type } = action;
    switch (type) {
        // Async methods
        case ThemeActionType.CHANGE_THEME: return { ...state, theme: payload };
        default:
            return state;
    }
}