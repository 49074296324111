import React from "react";
import { useContext } from "react";
import { LangContext } from "../../../Contexts/Lang";
import { AsyncDataI } from "../../../Contexts/interfaces";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "../../Common/Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CnvTitleI {
    history: any,
    cnvsReq: AsyncDataI,
}
export default function CnvTitle(props: CnvTitleI) {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <div onClick={() => props.history.goBack()} style={{ cursor: 'pointer', fontSize: '22px', display: "flex", alignItems: "center" }}>
            <Tooltip label={translate("common.goToPreviousPage")} placement={"bottom"}>
                <span>
                    <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "17px" }} />&nbsp;
                    {translate("common.cnvsAnalysis")}
                    {props.cnvsReq.loaded && <>&nbsp;-&nbsp;{props.cnvsReq.data.study?.name}</>}
                </span>
            </Tooltip>
            {/* {props.cnvsReq.loaded && <>
                &nbsp;&nbsp;
                <span style={{ fontSize: "9px" }}>
                    <Badge variant="info" pill
                        style={{
                            fontWeight: "bold",
                            margin: 0
                        }}
                    >
                        {props.cnvsReq.data?.study?.genomeReference ?? ""}
                    </Badge>
                    <br style={{ margin: 0 }} />
                    {(props.cnvsReq.data?.study?.samples ?? []).length === 1 ?
                        <Badge variant="info" pill
                            style={{
                                fontWeight: "bold",
                                margin: 0
                            }}>
                            {props.cnvsReq.data?.study?.samples[0]?.sample?.name ?? ""}
                        </Badge>
                        :
                        <label style={{ margin: 0 }}>
                            {translate("common.samples")}&nbsp;:&nbsp;
                            {(props.cnvsReq.data?.study?.samples ?? []).length}
                        </label>
                    }
                </span>
                &nbsp;
            </>} */}
        </div>
    )
}