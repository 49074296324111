import React from 'react';
import { useContext } from "react";
import { AsyncDataI } from "src/Contexts/interfaces";
import { Col, Container, Row } from 'react-bootstrap';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { ZMissBadge } from 'src/Components/Common/Badges/BadgesPliZscore';
import { Tooltip } from 'src/Components/Common/Tooltip/Tooltip';

interface ConstraintsTableI {
    // studyId: number,
    // variantId: string,
    variant: AsyncDataI,
    // isMitochondrial: boolean,
}

export default function ConstraintsTable(props: ConstraintsTableI) {
    // const { theme } = useContext(ThemeContext);
    const { theme } = useContext(ThemeContext);

    const variant = props.variant.data;
    const infoCd: any = variant?.variant?.infoColumnData
    const rowStyle = { borderBottom: `solid 1px ${theme.background4}`, marginRight: 0, marginLeft: 0 }
    const getLabelColumn = (label: string, style?: any, tooltip?: string) => {
        return (
            <Col xs={2} sm={2} md={2} lg={2} xl={2}
                style={{
                    color: "gray",
                    fontSize: 12,
                    padding: 2,
                    textAlignLast: "right",
                    ...(style ? style : {})
                }}>
                {tooltip ?
                    <Tooltip label={tooltip}>
                        <span>
                            {label}
                        </span>
                    </Tooltip> :
                    label
                }
            </Col >
        )
    }
    const getDataColumn = (checkData: any, data: number | JSX.Element | false, style?: any) => {
        return (
            <Col style={{
                fontSize: 12,
                padding: 2,
                ...(style ? style : {})
            }}>
                {checkData === undefined ? " - " : data === false ? "" : (typeof data === "number") ? data.toFixed(3) : data}
            </Col >
        )
    }
    const PLI = getDataColumn(infoCd?.GNOMAD_PLI, infoCd?.GNOMAD_PLI)
    const PNULL = getDataColumn(infoCd?.GNOMAD_PNULL, infoCd?.GNOMAD_PNULL)
    const ZLOF = getDataColumn(infoCd?.GNOMAD_ZLOF, infoCd?.GNOMAD_ZLOF)
    const ZMISS = getDataColumn(infoCd?.GNOMAD_ZMISS, <ZMissBadge value={infoCd?.GNOMAD_ZMISS} />)
    const GNOMAD_OE_MIS = getDataColumn(infoCd?.GNOMAD_GNOMAD_OE_MIS, infoCd?.GNOMAD_GNOMAD_OE_MIS)

    // {
    //     id: "ZMISS",
    //     label: "ZMISS",
    //     value: <span style={commonStyle}>
    //         {infoCd?.GNOMAD_ZMISS ? <ZMissBadge value={infoCd?.GNOMAD_ZMISS} /> : " - "}
    //     </span>,
    //     info: {
    //         title: "Z-Score-Mis",
    //         body: translate("casesQuery.zScoreInfo")
    //     }
    // },
    return (
        // <DetailQueryTable id="DetailQueryTable_annotations"
        //     title={
        //         <>
        //             <FontAwesomeIcon icon={faPenAlt} style={{ color: "lightgray", float: "right", margin: "4px 5px 0 0", fontSize: "15px" }} />
        //             &nbsp;{"Constraints metrics"}
        //         </>
        //     }
        //     data={dataTableAnottation}
        // />

        <>
            <Container style={{ width: "-webkit-fill-available", padding: 10 }}>
                    <Row style={{ ...rowStyle, fontSize: 14, color: "gray", fontWeight: "bold", borderBottom: `solid 1px ${theme.backgroundThird}`, }} >
                        &nbsp;Constraints metrics
                    </Row>
                    <Row style={{ ...rowStyle, borderBottom: `solid 3px ${theme.background4}`, }} >
                        {getLabelColumn("pLI: ")}{PLI}
                        {getLabelColumn("pNULL: ")}{PNULL}
                        {getLabelColumn("o/e: ", {}, "observed/expected")}{GNOMAD_OE_MIS}
                    </Row>
                    <Row style={{ ...rowStyle, borderBottom: `solid 3px ${theme.background4}`, }} >
                        {getLabelColumn("Z-Missense: ")}{ZMISS}
                        {getLabelColumn("Z-LoF: ")}{ZLOF}

                        {getLabelColumn("")}{getDataColumn("", false)}
                    </Row>
            </Container >
        </ >
    )
} 