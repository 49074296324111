import { useContext } from "react";
import { Accordion, Badge, Button, Card } from 'react-bootstrap';
import { LangContext } from "../../../Contexts/Lang";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "src/Components/Common/Tooltip/Tooltip";
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';


interface FilterPanelHeaderI {
	genomeReference?: string,
	samples?: any[],
	genesList?: string,
	correlation?: string,
	nComp?: number
}
export default function FilterPanelHeader(props: FilterPanelHeaderI) {
	const { dispatch: { translate } } = useContext(LangContext);
	return (
		<div style={{ margin: 0, display: "flex" }}>
			<div style={{ padding: "0 7px 0 0", minWidth: "fit-content" }}>
				<Tooltip placement='right-start'
					label={"CNVs detectados por DECoN. Se muestran sólo los CNVs con Bayes Factor mayor a 8 y su correspondiente análisis suplementario realizado con la herramienta ONCOCNV."}>
					<FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: "17px", color: "lightgray", verticalAlign: "middle" }} />
				</Tooltip>
				&nbsp;&nbsp;
				<Badge variant="info" pill style={{ fontWeight: "bold", margin: 0 }} >
					{props.genomeReference ?? ""}
				</Badge>
				&nbsp;
				{(props.samples ?? []).length === 1 ?
					<Badge variant="info" pill
						style={{ fontWeight: "bold", margin: 0 }}>
						{props.samples &&
							props.samples?.length > 0 &&
							props.samples[0]?.sample?.name ?
							props.samples[0]?.sample?.name :
							""
						}
					</Badge>
					:
					<Badge variant="secondary" pill
						style={{ fontWeight: "bold", margin: 0 }}>
						{translate("common.samples")}&nbsp;:&nbsp;
						{(props.samples ?? []).length}
					</Badge>}
				&nbsp;
			</div>
			<div style={{ padding: 0, width: "100%", borderLeft: "solid 1px #EEE", paddingLeft: 12 }}>
				<CnvMetrics genesList={props.genesList}
					correlation={props.correlation}
					nComp={props.nComp} />
			</div>
		</div >
	)
}

interface CnvMetricsI {
	genesList?: string,
	correlation?: string,
	nComp?: number
}
function CnvMetrics(props: CnvMetricsI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const geneList = (props.genesList ?? "").split(",").filter((x: string) => x.length > 2 || x.indexOf(":") > -1).join(", ")
	const correlation = props.correlation;
	const nComp = props.nComp;
	const isOmim = geneList?.includes("OMIM_Genes");
	const correlationCompoent = !correlation ? <></> : <span style={{ verticalAlign: "text-top" }}>
		<span style={{ fontWeight: "bold" }}>
			{translate("common.correlation")}:&nbsp;
		</span>
		<span>
			{correlation}
		</span>
		&nbsp;|&nbsp;
	</span>
	const nCompCompoent = !nComp ? <></> : <span style={{ verticalAlign: "text-top" }}>
		<span style={{ fontWeight: "bold" }}>
			{translate("common.controlSamples")}:&nbsp;
		</span>
		<span>
			{nComp}
		</span>
	</span>
	return (
		geneList.length < 12 ? <div style={{ fontSize: "12px", color: "dimgray", alignContent: "end", padding: 2 }}>
			{correlationCompoent}
			{nCompCompoent}
			{isOmim && <div style={{ float: "right", paddingTop: 2 }}>
				{translate("common.analizedGenes")} &nbsp;:&nbsp; OMIM Genes
			</div>}
		</div> :
			<Accordion style={{ minWidth: "", alignContent: "end" }}>
				<Card style={{ border: "none", color: "dimgray" }}>
					<Accordion.Toggle as={Card.Header} eventKey="0" style={{ fontSize: "12px", backgroundColor: "#FFF", cursor: "pointer", padding: 2 }}>
						{correlationCompoent}
						{nCompCompoent}
						<span style={{ float: "right" }}>
							{translate("common.analizedGenes")} &nbsp;&nbsp;
							<Button variant="outline-light" style={{
								borderRadius: "50px",
								padding: "2px 6px 0px",
								border: "none",
								margin: 0
							}}
							>
								<FontAwesomeIcon icon={faAngleDown} style={{ fontSize: "12px", color: "gray" }} />
							</Button>

						</span>

					</Accordion.Toggle>
					<Accordion.Collapse eventKey="0">
						<Card.Body style={{ minWidth: "10px", fontSize: "11px" }}>
							{geneList.toUpperCase().indexOf("OMIM") > -1 ?
								"GENES OMIM  - link aca" :
								<span style={{ wordBreak: "break-word" }}>

									{geneList.split(",").join(", ")}
									{geneList.split(",").join(", ")}
									{geneList.split(",").join(", ")}
								</span>
							}
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
	)
}