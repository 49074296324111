import React from 'react';
import { Badge, Button, Card, Table } from "react-bootstrap"
import { useContext, useState } from "react";
import { LangContext } from "../../../../Contexts/Lang";
import { ModalSamplesIgv, modalTabsType, SampleInfoIgvDataI } from "../../../IGV/ModalSamplesIgv";
import { StudyI } from "../../../../Contexts/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ThemeContext from '../../../../Contexts/Theme/ThemeContext';
import { roundWithTooltipoTo0 } from "src/Helpers/Helpers";
import { ModalIgvDataI, AlignmentT } from 'src/Components/IGV/types';
import TrascriptComponent from 'src/Components/Common/TranscriptComponent';

interface RegionsTableI {
    regions: any[],
    study: StudyI,
    cardStyles?: any,
    geneNameColumn?: boolean
}
export default function RegionsTable(props: RegionsTableI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const [showModalSamplesIgv, setShowModalSamplesIgv] = useState<{ show: boolean, data?: SampleInfoIgvDataI }>({ show: false, data: undefined });
    const handleCloseModalSamplesIgv = () => setShowModalSamplesIgv({ show: false, data: undefined });
    const handleShowModalSamplesIgv = (i: SampleInfoIgvDataI) => {
        setShowModalSamplesIgv({
            show: true,
            data: {
                ...i,
                igvData: i.igvData ? {
                    ...i.igvData,
                    library: props.study.libraryPrepKit
                } : undefined
            }
        });
    }
    const studyType = (props.study?.type ?? "").toLowerCase().trim()
    // console.log(props.regions)
    return (
        <>
            {(studyType === "multiple" || studyType === "familiar" || (props.study?.hasBam ?? false)) &&
                (showModalSamplesIgv?.data?.chromosome && showModalSamplesIgv?.data?.position && showModalSamplesIgv?.data?.gen) &&
                <ModalSamplesIgv
                    caseId={`${props.study.id}`}
                    show={showModalSamplesIgv.show}
                    handleClose={handleCloseModalSamplesIgv}
                    mood={showModalSamplesIgv?.data?.mood ?? "sample"}
                    chromosome={showModalSamplesIgv?.data?.chromosome}
                    position={showModalSamplesIgv?.data?.position}
                    gen={showModalSamplesIgv?.data?.gen}
                    sampleData={showModalSamplesIgv.data?.sampleData}
                    igvData={showModalSamplesIgv.data?.igvData}
                />
            }

            {props.regions.length === 0 ? <></> :
                <Card style={{
                    borderRadius: "0px",
                    border: "none",
                    width: "100%",
                    boxShadow: "rgb(200 200 200) 0px 5px 4px",
                    marginBottom: "25px",
                    ...(props.cardStyles ?? {})
                }}>
                    <Card.Body style={{ padding: "0px", width: "100%" }}>
                        <Table responsive="sm" className="myTable" striped bordered style={{ marginBottom: 0 }}>
                            <thead style={{ backgroundColor: "#EEEEEE", color: theme.grayHeader }}>
                                <tr>
                                    {props.geneNameColumn && <th style={{}}>{translate("common.gene")}</th>}
                                    <th style={{}}>{translate("common.transcript")}</th>
                                    <th style={{}}>{translate("common.chromosome")}</th>
                                    <th style={{}}>{translate("common.start")}</th>
                                    <th style={{}}>{translate("common.end")}</th>
                                    <th style={{}}>{translate("common.meanDepth")}</th>
                                    <th style={{}}>{`% ${translate("common.coverage")}`}</th>
                                    {
                                        (props.study?.hasBam ?? false) &&
                                        <th style={{}}>
                                            {translate("casesQuery.viewAlignment")}
                                        </th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <RegionsRows regions={props.regions}
                                    study={props.study}
                                    handleShowModalSamplesIgv={handleShowModalSamplesIgv}
                                    geneNameColumn={props.geneNameColumn}
                                />
                            </tbody>
                        </Table >
                    </Card.Body>
                </Card>
            }
        </>
    )
}

interface RegionI {
    chrom: string,//"chr7"
    coverage: string,//"1.0"
    end: string,//"34849675"
    genename: string,//"NPSR1"
    mean: string,//"101.86200000000001"
    start: string,//"34849275"
    transcript: string,//"NM_207172,NM_001300934,NM_207173,NM_001300935,NM_001300933"
}
interface RegionsRowsI {
    regions: (any | RegionI)[]
    study: StudyI,
    handleShowModalSamplesIgv(i: SampleInfoIgvDataI): void;
    geneNameColumn?: boolean
}

function RegionsRows(props: RegionsRowsI) {
    const getBadge_Sample = (sample: any, infoIcon: boolean = false, fontSize: string = "11px") => {
        return (
            <Badge variant="secondary"
                style={{
                    fontWeight: "inherit",
                    fontSize: fontSize,
                    color: "white",
                    backgroundColor: sample?.afectado ? "#FF4E58" : "#999999",
                    margin: "2px",
                    padding: "1px 5px 3px 5px",
                    alignSelf: "center"
                }}>
                {`${sample?.sample?.name ?? "-"} ${sample ? sample.tag[0] : ""}`}
                {infoIcon &&
                    <FontAwesomeIcon icon={faInfoCircle}
                        style={{ fontSize: "12px", margin: "2px 0 0 6px", verticalAlign: "text-bottom" }} />
                }
            </Badge>
        )
    }

    const getModal_IGVData = (viewType?: AlignmentT, sampleNameSelected?: string): ModalIgvDataI => {
        let commonData: any
        let data: any = {}
        const alignaments = (props.study.samples ?? []).map((x: any, i: number) => {
            const actualSample = props.study?.samples.find((y: any) => y.sample.name === x.sampleName)
            if (i === 0) {
                data.studyName = x.sampleName;
                data.reference = props.study?.genomeReference;
                commonData = {
                    effect: "NA",
                    impact: "-"
                }
            }
            return ({
                effect: x.effect,
                impact: x.impact,
                sampleName: x.sampleName,//x.sampleName,
                sampleBadge: getBadge_Sample(actualSample, false, "14px")//x.sampleName,
            })
        })
        const igvSamples = props.study?.samples.map((x: any) => {
            const igvElement = alignaments.find((y: any) => y.sampleName === x.sample.name)
            if (igvElement)
                return (igvElement)
            return {
                ...commonData,
                sampleName: x.sample.name,//x.sample.name
                sampleBadge: getBadge_Sample(x, false, "14px")//x.sample.name
            }
        })
        console.log(data)
        return {
            ...data,
            sampleNameSelecte: sampleNameSelected,
            view: viewType,
            alignments: igvSamples
        }
    }

    const getModal_SampleIgvData = (x: RegionI, mood: modalTabsType = "sample", view: AlignmentT, sampleNameSelected?: string): SampleInfoIgvDataI => {
        let handlerData: any = {
            mood: mood,
            chromosome: x.chrom,
            position: `${x.start}-${x.end}`,
            gen: x.genename
        }
        // if (props.studyType === "familiar" || props.studyType === "multiple") {
        //     const sd = getModal_SampleData()
        //     handlerData = {
        //         ...handlerData,
        //         sampleData: {
        //             change: rv.codonChange,
        //             table: sd
        //         }
        //     }
        // }
        if (props.study?.hasBam) {
            const igvData = getModal_IGVData(view, sampleNameSelected)
            handlerData = {
                ...handlerData,
                igvData: igvData
            }
        }
        return handlerData
    }
    const getModal_SampleIgvButton = (x: RegionI) => {
        const getShowModalLink = (label: string, mood: modalTabsType = "sample", view: AlignmentT = "variant", sampleNameSelected?: string) => {
            return (
                <Button style={{ cursor: "pointer", color: "#009EEA", padding: "2px 5px" }} variant="outline-light"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        const handlerData = getModal_SampleIgvData(x, mood, view, sampleNameSelected)
                        props.handleShowModalSamplesIgv(handlerData)
                    }}
                >
                    {label}
                </Button>
            )
        }
        if (props.study.type === "familiar" || props.study.type === "multiple")
            return (
                <></>
                // <div style={{ textAlign: "center" }}>
                //     <hr style={{ margin: "7px" }} />
                //     {getShowModalLink("Show samples", "sample")}
                // </div>
            )
        if (props.study?.hasBam)
            return (
                <div style={{ textAlign: "center" }}>
                    {getShowModalLink("Locus", "igv", "variant", props.study.samplesNames[0])}
                </div>
            )
        return ""
    }
    return (
        <>
            {(props.regions ?? []).map((x: any, i: number) => {
                return (
                    <tr key={i}
                        style={{ fontSize: "12px" }}
                        onClick={() => { }}>
                        {props.geneNameColumn && <td style={{ padding: "3px 5px" }}>{x.genename}</td>}
                        <td style={{ padding: "3px 5px" }}><TrascriptComponent transcript={x.transcript} /></td>
                        <td style={{ padding: "3px 5px" }}>{x.chrom}</td>
                        <td style={{ padding: "3px 5px" }}>{x.start}</td>
                        <td style={{ padding: "3px 5px" }}>{x.end}</td>
                        <td style={{ padding: "3px 5px" }}>{x.mean ? roundWithTooltipoTo0(+x.mean) : "-"}</td>
                        <td style={{ padding: "3px 5px" }}>{x.coverage ? roundWithTooltipoTo0((+x.coverage) * 100) : "-"}</td>
                        {
                            (props.study?.hasBam ?? false) &&
                            <td style={{ padding: "3px 5px" }}>
                                {getModal_SampleIgvButton(x)}
                            </td>
                        }
                    </tr>)
            })}
        </>)
    // else (<></>)
}




