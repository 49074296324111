import React from "react";
import { useContext } from "react";
import { BadgeFilters } from "src/Components/Common/Badges/BadgeFilters";
import LabelPopover from "src/Components/Common/LabelPopover";
import { LangContext } from "src/Contexts/Lang";
import { getVariantFiltersLabels } from "src/utils/BioFunctionalData";
import { checkValue } from "src/utils/helpers";


interface SampleInfoVafI {
	genoInfo?: {
		alleles: any[],
		sreadDepth: any[],
		ad: any[]
	},
	dp?: string,
	style?: any,
}
function SampleInfoVaf(props: SampleInfoVafI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const getVaf = (genInfo: any) => {
		let vaf = "--", AD = "";
		if (genInfo) {
			let alles = genInfo.alleles, sdps = genInfo.sreadDepth;
			if ((alles[0] === "." && alles[1] === ".") || (alles[0] === "0" && alles[1] === "0"))
				AD = genInfo.ad[0] || ".";
			else
				if (alles[0] === alles[1])
					AD = genInfo.ad[genInfo.ad.length - 1] || ".";
				else {
					AD = genInfo.ad[1];
					if (!AD) AD = genInfo.ad[genInfo.ad.length - 1] || ".";
				}
			let DP = genInfo.readDepth;
			if (!DP) DP = ".";
			if (sdps && AD === ".") AD = sdps.join('|');
			if (!isNaN(+AD) && !isNaN(+DP)) {
				let val = ((+AD / +DP) * 100).toFixed(1)
				vaf = `${val}%`;
			}
		}
		return vaf;
	}
	return (<span style={{ display: "inline-flex" }}>
		<LabelPopover popoverTitle={translate("common.vaf")}
			popoverBody={translate("common.vafDescription")}
			style={props.style ? { ...props.style } : {}}
			label={<>
				<strong>VAF</strong>
				{` : ${props.genoInfo ? getVaf(props.genoInfo) : props.dp} \t`}
			</>} />
	</span>
	)
}

interface SampleInfoDpI {
	genoInfo?: {
		alleles: any[],
		sreadDepth: any[],
		ad: any[]
	},
	dp?: string,
	style?: any,
	longLabel?: boolean
}
function SampleInfoDp(props: SampleInfoDpI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const getDp = (genInfo: any) => {
		let genotype = "--", AD = "";
		if (genInfo) {
			let alles = genInfo.alleles, sdps = genInfo.sreadDepth;
			if ((alles[0] === "." && alles[1] === ".") || (alles[0] === "0" && alles[1] === "0"))
				AD = genInfo.ad[0] || ".";
			else
				if (alles[0] === alles[1])
					AD = genInfo.ad[genInfo.ad.length - 1] || ".";
				else {
					AD = genInfo.ad[1];
					if (!AD) AD = genInfo.ad[genInfo.ad.length - 1] || ".";
				}
			let DP = genInfo.readDepth;
			if (!DP) DP = ".";
			if (sdps && AD === ".") AD = sdps.join('|');
			genotype = AD + "/" + DP;
		}
		return genotype;
	}
	return (<span style={{ display: "inline-flex" }}>
		<LabelPopover popoverTitle={translate("casesQuery.sampleInfoDetail.coverage.title")}
			popoverBody={translate("casesQuery.sampleInfoDetail.coverage.content")}
			style={props.style ? { ...props.style } : {}}
			label={<>
				<strong>{props.longLabel ? "Depth" : "DP"}</strong>
				{` : ${props.genoInfo ? getDp(props.genoInfo) : props.dp} \t`}
			</>} />
	</span>
	)
}

interface SampleInfoGqI {
	conditionalQuality: any,
	style?: any,
	longLabel?: boolean,
}
function SampleInfoGq(props: SampleInfoGqI) {
	const { dispatch: { translate } } = useContext(LangContext);
	return (<span style={{ display: "inline-flex" }}>
		<LabelPopover popoverTitle={translate("casesQuery.sampleInfoDetail.quality.title")}
			popoverBody={translate("casesQuery.sampleInfoDetail.quality.content")}
			style={props.style ? { ...props.style } : {}}
			label={<>
				<strong>{props.longLabel ? "Genotype Quality" : "GQ"}</strong>
				{` : ${checkValue(props.conditionalQuality)}`}
			</>} />
	</span>
	)
}

interface SampleInfoFsI {
	fs: any,
	style?: any,
	longLabel?: boolean,
}
function SampleInfoFs(props: SampleInfoFsI) {
	const { dispatch: { translate } } = useContext(LangContext);
	return (<span style={{ display: "inline-flex" }}>
		<LabelPopover popoverTitle={translate("casesQuery.sampleInfoDetail.fs.title")}
			popoverBody={translate("casesQuery.sampleInfoDetail.fs.content")}
			style={props.style ? { ...props.style } : {}}
			label={<span>
				<strong>{props.longLabel ? "Fisher strand bias" : "FS"}</strong>
				{` : ${checkValue(props.fs)} `}
			</span>} />
	</span>
	)
}

interface SampleInfoQualI {
	quality: any,
	style?: any,
	longLabel?: boolean,
}
function SampleInfoQual(props: SampleInfoQualI) {
	const { dispatch: { translate } } = useContext(LangContext);
	return (<span style={{ display: "inline-flex" }}>
		<LabelPopover popoverTitle={translate("casesQuery.sampleInfoDetail.qual.title")}
			popoverBody={translate("casesQuery.sampleInfoDetail.qual.content")}
			style={props.style ? { ...props.style } : {}}
			label={<>
				<strong>{props.longLabel ? "Quality" : "QUAL"}</strong>{` : ${checkValue(props.quality)} `}
			</>} />
	</span>
	)
}

interface SampleInfoGenotypeI {
	genoInfo: any,
	style?: any,
	longLabel?: boolean
}
function SampleInfoGenotype(props: SampleInfoGenotypeI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const getGenotypeGT = (genInfo: any) => {
		let genotype = "";
		//GT:AD:DP:GQ:PL
		//0|0:48:1:51,51
		if (genInfo) {
			let sepa = "/";
			if (genInfo.phased) {
				sepa = "|";
			}
			genotype = genInfo.alleles.join(sepa);
		}
		if (!genotype || genotype === "") {
			genotype = "--";
		}
		return genotype;
	}
	return (<span style={{ display: "inline-flex" }}>
		<LabelPopover popoverBody={translate("casesQuery.genotype")}
			style={props.style ? { ...props.style } : {}}
			label={<>
				<strong>{props.longLabel ? "Genotype" : "GT"} </strong>
				{` : ${getGenotypeGT(props.genoInfo)}`}
			</>} />
	</span>
	)
}

interface SampleInfoHfI {
	genoInfo: any,
	style?: any,
	longLabel?: boolean
}
function SampleInfoHf(props: SampleInfoHfI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const getGenotypeHF = (genInfo: any) => {
		let genotype = "";
		//GT:AD:DP:GQ:PL
		//0|0:48:1:51,51
		if (genInfo) {
			let HF = genInfo.heteroplasmy;
			if (HF) { genotype = HF.join('|') };
		}
		if (!genotype || genotype === "") {
			genotype = "--";
		}
		return genotype;
	}
	return (<span style={{ display: "inline-flex" }}>
		<LabelPopover popoverTitle={translate("casesQuery.sampleInfoDetail.heteroplasmy.title")}
			popoverBody={translate("casesQuery.sampleInfoDetail.heteroplasmy.content")}
			style={props.style ? { ...props.style } : {}}
			label={<>{` | `}<strong>{props.longLabel ? "Heteroplasmy" : "HF"}</strong>{` : ${getGenotypeHF(props.genoInfo)} `}</>} />

	</span>
	)
}

interface SampleInfoFilterI {
	filter: string,
	style?: any,
}
function SampleInfoFilter(props: SampleInfoFilterI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const getBadgeFilter = () => {
		if (!props.filter) return "--"
		const variantFiltersLabels = getVariantFiltersLabels(translate);
		const variantFiltersLabelsKeys = Object.keys(variantFiltersLabels);
		let filters = props.filter.toLowerCase().trim().split(";").filter((x: string) => x.length > 1);
		let filtersCleanedAndSorted = filters
			.filter((x: string) => variantFiltersLabelsKeys.includes(x))
			.sort((a: string, b: string): number => {
				return variantFiltersLabelsKeys.indexOf(a) - variantFiltersLabelsKeys.indexOf(b);
			});
		let filtersBadges = filtersCleanedAndSorted.map((x: string, i: number) => {
			return <span key={i} style={{ display: "inline-flex" }}>
				<BadgeFilters _key={x} index={i} />
				{i !== filtersCleanedAndSorted.length - 1 ? <>,&nbsp;</> : ""}
			</span>;
		})
		return filtersBadges;
	}
	return (<>
		<LabelPopover popoverTitle={translate("casesQuery.sampleInfoDetail.filter.title")}
			popoverBody={translate("casesQuery.sampleInfoDetail.filter.content")}
			label={<strong>{translate("common.filterO")}</strong>} />
		&nbsp;:&nbsp;{getBadgeFilter()}
	</>
	)
}



export {
	SampleInfoVaf,
	SampleInfoDp,
	SampleInfoGq,
	SampleInfoFs,
	SampleInfoQual,
	SampleInfoGenotype,
	SampleInfoHf,
	SampleInfoFilter
}