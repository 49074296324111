import React from 'react';
import { Badge } from "react-bootstrap";
import { Tooltip } from "../Tooltip/Tooltip";

interface PLiBadgeI {
    value: number
}
function PLiBadge(props: PLiBadgeI) {
    const pliThreshold = 0.9;
    if (!props.value) return (<>--</>)
    return (
        props.value < pliThreshold ?
            `${props.value}`.indexOf("e-") > -1 || `${props.value}`.indexOf("0.00") > -1 ?
                <Tooltip label={`${props.value}`}>
                    <span>{props.value.toFixed(2)}</span>
                </Tooltip > :
                <span>{props.value.toFixed(2)}</span> :
            <Tooltip label={`pLi > ${pliThreshold}`}>
                <Badge key={"someKey"} variant="danger"
                    style={{ fontWeight: "bold", margin: "1px 2px", fontSize: "11px", font: "white" }}>
                    {(+props.value).toFixed(2)}
                </Badge>
            </Tooltip >
    )
}

interface ZMissBadgeI {
    value: number
}
function ZMissBadge(props: ZMissBadgeI) {
    const zMissThreshold = 3.09;
    if (!props.value) return (<>--</>)
    return (
        props.value < zMissThreshold ?
            `${props.value}`.indexOf("e-") > -1 || `${props.value}`.indexOf("0.00") > -1 ?
                <Tooltip label={`${props.value}`}>
                    <span>{props.value.toFixed(2)}</span>
                </Tooltip > :
                <span>{props.value.toFixed(2)}</span>
            : (
                <Tooltip label={`Z-Missense > ${zMissThreshold}`}>
                    <Badge key={"someKey"} variant="warning"
                        style={{ fontWeight: "bold", margin: "1px 2px", fontSize: "11px", color: "white" }}>
                        {(+props.value).toFixed(2)}
                    </Badge>
                </Tooltip >
            )
    )
}

export { ZMissBadge, PLiBadge }