import React from 'react';
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"

interface CloseButtonI {
    onClose(): void,
    styles?: any,
}
export default function CloseButton(props: CloseButtonI) {
    const styles = props.styles ?? {}
    return (
        <Button variant="outline-secondary" style={{ border: "none", float: "right", padding: "0px 2px", borderRadius: "2px", ...styles }}
            onClick={() => { props.onClose() }}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: "12px", margin: "0 2px" }} />
        </Button>
    )
}

