import React from 'react';
import { Row, Container, Button } from 'react-bootstrap';
import "../../Common/table.css"
import { useContext, useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { LangContext } from '../../../Contexts/Lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { StudyI } from 'src/Contexts/interfaces';
import { Tooltip } from 'src/Components/Common/Tooltip/Tooltip';
import CloseButton from 'src/Components/Common/CloseButton';
import IconButtonTooltipPopover from 'src/Components/Common/IconButtonTooltipPopover';
import LinkTooltip from 'src/Components/Common/Links/LinkTooltip';

type fieldToSortT = "sample" | "gene" | "decon" | "onco" | "region";
interface CnvTableHeadersI {
    study: StudyI,
    handleSortByFilter(fieldToSort: fieldToSortT): void;
    rowChildren: any[],
}
export default function CnvTableHeaders(props: CnvTableHeadersI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);

    const showSamplesColumn = (props.study?.samples ?? []).length > 1;
    const getColWidths = (index: number): string | number => {
        // console.log("asds")
        const width = (props.rowChildren && props.rowChildren.length > index) ? props.rowChildren[index].offsetWidth : undefined;
        return width;
        // return "inherith";
    }
    const columnTitleStyle = { padding: "3px 7px", verticalAlign: "top" }
    // console.log(props.firstColWidth ?? "lala")
    return (
        <thead>
            <tr style={{
                color: theme.grayHeader,
                backgroundColor: theme.backgroundThird,
                border: "none",
                margin: 0,
                padding: "5px",
                fontSize: "10px",
                width: "100%",
                textTransform: "uppercase"
            }}>
                {showSamplesColumn &&
                    <th style={{
                        ...columnTitleStyle,
                        // textAlign: "center",
                        minWidth: getColWidths(0),
                        // width: props.firstColRef?.current?.offsetWidth ?? "100px",
                    }}
                    >
                        <label style={{ paddingTop: "3px", marginBottom: 0 }}>
                            {translate("common.sample")}
                        </label>
                        <SortButton fieldToSort={"sample"}
                            handleSortByFilter={props.handleSortByFilter}
                        />
                    </th>}


                <th style={{
                    ...columnTitleStyle,
                    // textAlign: "center",
                    minWidth: getColWidths(!showSamplesColumn ? 0 : 1),
                }} >
                    <label style={{ paddingTop: "3px", marginBottom: 0 }}>
                        {translate("common.gene")}
                    </label>
                    <SortButton fieldToSort={"gene"}
                        handleSortByFilter={props.handleSortByFilter}
                    />
                </th>
                <th style={{
                    minWidth: getColWidths(!showSamplesColumn ? 1 : 2),
                    ...columnTitleStyle
                }} >
                    <label style={{ paddingTop: "3px", marginBottom: 0 }}>
                        {translate("common.type")}
                    </label>
                </th>
                <th style={{
                    minWidth: getColWidths(!showSamplesColumn ? 2 : 3),
                    ...columnTitleStyle
                }} >
                    <span>
                        <Tooltip label={translate("common.source")}>
                            <span>
                                DECoN
                            </span>
                        </Tooltip>
                        <InfoPopoverButton>
                            <Container style={{ fontSize: "12px", padding: 0 }}>
                                <Row style={{ margin: 0 }}>
                                    DECoN: Detection of Exon Copy Number
                                </Row>
                                <hr style={{ margin: "2px" }} />
                                <Row style={{ margin: 0 }}>
                                    <LinkTooltip
                                        label={"Documentation"}
                                        tooltipLabel=""
                                        href={"https://d1ijoxngr27nfi.cloudfront.net/cavac/decon_documentation.pdf"}
                                        externalLink={true}
                                    />
                                </Row>
                            </Container>
                        </InfoPopoverButton>
                    </span>
                    <SortButton fieldToSort={"decon"}
                        handleSortByFilter={props.handleSortByFilter}
                    />
                </th>
                <th style={{
                    minWidth: getColWidths(!showSamplesColumn ? 3 : 4),
                    ...columnTitleStyle
                }} >
                    <span>
                        <Tooltip label={translate("common.source")}>
                            <span>
                                ONCOCNV
                            </span>
                        </Tooltip>
                        <InfoPopoverButton>
                            <Container style={{ fontSize: "12px" }}>
                                <Row style={{ margin: 0 }}>
                                    ONCOCNV: Detection of copy number changes in deep sequencing data
                                    <hr style={{ margin: "2px" }} />
                                </Row>
                                <Row style={{ margin: 0 }}>
                                    <LinkTooltip
                                        label={"Documentation"}
                                        tooltipLabel=""
                                        href={"http://boevalab.inf.ethz.ch/ONCOCNV/"}
                                        externalLink={true}
                                    />

                                </Row>
                            </Container>
                        </InfoPopoverButton>
                    </span>
                    <SortButton fieldToSort={"onco"}
                        handleSortByFilter={props.handleSortByFilter}
                    />
                </th>
            </tr>
        </thead>
    )
}

interface InfoPopoverButtonI {
    children?: JSX.Element,
}
function InfoPopoverButton(props: InfoPopoverButtonI) {
    const [show, setshow] = useState(false)
    const [notclick, setnotclick] = useState(false)
    return (
        <IconButtonTooltipPopover icon={faInfoCircle}
            // tooltipLabel={"OMIM"}
            popoverBodyStyle={{ maxHeight: "251px", overflowY: "auto" }}
            placement="bottom"
            show={show}
            onClick={() => {
                if (!notclick) {
                    setshow(true); setnotclick(true)
                } else { setshow(false); setnotclick(false) }
            }}
            popoverStyle={{ padding: 0 }}
            popOver={{
                content: (<>
                    <CloseButton styles={{ marginTop: "1px" }}
                        onClose={() => { setnotclick(true) }} />

                    {props.children ?? "--"}
                </>)
            }}
        />
    )
}

interface SortButtonI {
    fieldToSort: fieldToSortT,
    handleSortByFilter: any,
}
function SortButton(props: SortButtonI) {
    return (
        <span key={`0`} style={{ float: "right" }}>
            <Button variant="outline-light" style={{
                borderRadius: "50px",
                padding: "1px 6px 0px",
                border: "none",
                margin: 0
            }} onClick={() => { props.handleSortByFilter(props.fieldToSort) }}
            >
                <FontAwesomeIcon icon={faAngleDown} style={{ fontSize: "12px", color: "gray" }} />
            </Button>
        </span>
    )
}