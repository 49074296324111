import React from 'react';
import { useContext } from "react";
import { LangContext } from "../../../../Contexts/Lang";
import CopyToClipboard from "../../../Common/CopyToClipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { LinkENSEMBL, LinkNCBI } from '../../../Common/Links/Links';
import BadgeManeSelect from "../../../Common/Badges/BadgeManeSelect";
import { checkValueAndReturnAnother } from "../../../../utils/helpers";
import DetailQueryTable, { DetailQueryTableRowI } from "./DetailQueryTable";
import { AsyncDataI, VariantTranscriptI, VariantTranscriptT } from "src/Contexts/interfaces";

interface NomenclatureTableI {
	variant: AsyncDataI,
	isMitochondrial: boolean,
}

export default function NomenclatureTable(props: NomenclatureTableI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const infoCd = props.variant.data?.variant?.infoColumnData
	const aa_change = infoCd?.AA_CHANGE;

	var exonOrIntronVariant2: any = null
	const rawInfo = props.variant.data?.variant?.rawInfo;
	if (infoCd?.EXON_ID && rawInfo && rawInfo.trim() !== "") {
		const rawInfoSplited = rawInfo.split(";")
		const exonCount = rawInfoSplited.find((x: string) => x.startsWith("SNPEFF_EXON_COUNT"))?.split("=")[1];
		exonOrIntronVariant2 = <span>
			Exon {translate("common.or")} Intron:&nbsp;
			{infoCd?.EXON_ID}&nbsp;{translate("common.of")}&nbsp;{exonCount}&nbsp;{translate("common.totalExons")}
		</span>
	}
	let codonChange = infoCd?.CODON_CHANGE ? infoCd?.CODON_CHANGE : " -- ";
	codonChange = aa_change ? `${codonChange} - p.(${(aa_change ?? " -- ").replace("p.", "")})` : `${codonChange}`;


	const getnomenclatureTableData = (): DetailQueryTableRowI[] => {
		const transcriptData = props.variant?.data?.transcriptData;
		const getTranscriptData = (type: VariantTranscriptT) => {
			if (!transcriptData) return "";
			return transcriptData.filter((x: VariantTranscriptI) => x.type === type)
				.map((x: VariantTranscriptI, i: number) => [
					<span key={`link_${i}`}>
						{
							x.type === "Ensembl" ?
								<LinkENSEMBL transcriptEnst={x.id} fontSize={"12px"} /> :
								<LinkNCBI transcriptNm={x.id} fontSize={"12px"} />
						}
						{x.isMane && <BadgeManeSelect fontSize={11} />}
						<CopyToClipboard valueToCopy={x.id} />
					</span >,
					<span key={`span_${i}`} style={{}}>
						{!x.description ? "" : <>
							{x.description}
							<CopyToClipboard valueToCopy={x.description} />
						</>}
					</span>
				]);

		}
		const ensemblData = getTranscriptData("Ensembl")
		const refseqData = getTranscriptData("RefSeq")
		const getEnsemblLinks = (type: "gene" | "transcript") => {
			if (!transcriptData) return " -- "
			let transcriptDataProcessed: any = transcriptData;
			if (type === "gene") transcriptDataProcessed = Array.from(new Map(transcriptDataProcessed.map((item: any) => [item.geneId, item])).values());
			const result = transcriptDataProcessed
				.filter((x: VariantTranscriptI) => x.type !== "RefSeq")
				.map((x: VariantTranscriptI, i: number) => {
					const id = type === "gene" ? x.geneId : x.id;
					if (!id) return <span key="0"></span>;
					return (
						<span key={`${i}`}>&nbsp;
							<LinkENSEMBL transcriptEnst={id} />
							{x.isMane && type === "transcript" && <BadgeManeSelect fontSize={11} />}
							{i !== transcriptDataProcessed.length - 1 ? "," : ""}
							&nbsp;</span>
					)
				})
			return result
		}
		let alt = props.variant.data?.variant?.alternative ?? " - ";
		let ref = props.variant.data?.variant?.reference ?? " - ";
		alt = alt.length > 33 ? `${alt.substring(0, 30)}...` : alt;
		ref = ref.length > 33 ? `${ref.substring(0, 30)}...` : ref;
		let nomenclatureTableData = [
			{
				id: "change",
				label: translate("common.change"),
				value: <>
					<span>
						{`${ref} -> ${alt}`}
					</span>
					<CopyToClipboard valueToCopy={`${props.variant.data?.variant?.reference ?? " - "} > ${props.variant.data?.variant?.alternative ?? " - "}`} />
					<br />
					{checkValueAndReturnAnother(props.variant.data?.variant?.infoColumnData?.TRANSCRIPT_ID,
						<>
							<LinkENSEMBL transcriptEnst={props.variant.data?.variant?.infoColumnData?.TRANSCRIPT_ID} fontSize={"12px"} />
							<CopyToClipboard valueToCopy={`${props.variant.data?.variant?.infoColumnData?.TRANSCRIPT_ID}`} />
						</>
					)}
					<br />
					<div>
						{codonChange}
					</div>
					{exonOrIntronVariant2 && <>
						<div>
							{exonOrIntronVariant2}
						</div>
					</>
					}
				</>
			},
			{
				id: "refseq",
				label: "RefSeq",
				value: <>
					{refseqData.length === 0 ? " -- " :
						refseqData.map((x: JSX.Element[], i: number) => {
							return (
								<div key={`refSeq_${i}`} style={{ fontSize: "12px" }}>
									{x[0]}
									{x[1]}
								</div>
							)
						})}
				</>
			},
			{
				id: "ensembl",
				label: "Ensembl",
				value: <>
					{ensemblData.length === 0 ? " -- " :
						ensemblData.map((x: JSX.Element[], i: number) => {
							return (
								<div key={`refSeq_${i}`} style={{ fontSize: "12px" }}>
									{x[0]}
									{x[1]}
								</div>
							)
						})}
				</>
			},
			{
				id: "ensemblgene",
				label: translate("variantDetail.ensemblGene"),
				value: getEnsemblLinks("gene")
				// value: getEnsemblLink(props.variant.data?.variant?.infoColumnData?.ENSEMBL_GENEID)
			},
			{
				id: "enembltranscript",
				label: translate("common.ensemblTranscript"),
				value: getEnsemblLinks("transcript")
				// value: getEnsemblLink(props.variant.data?.variant?.infoColumnData?.ENSEMBL_TRANSCRIPTID)
			},
		]
		if (props.variant.data?.variant?.infoColumnData?.AACHANGE_ENSGENE) {
			nomenclatureTableData = nomenclatureTableData.filter((x: any) => x.id !== "ensemblgene" && x.id !== "enembltranscript")
		} else {
			nomenclatureTableData = nomenclatureTableData.filter((x: any) => x.id !== "ensembl")
		}
		return nomenclatureTableData;
	}
	const nomenclatureTableData = getnomenclatureTableData()
	return (
		<DetailQueryTable id="DetailQueryTable_hgvs"
			title={
				<>
					<FontAwesomeIcon icon={faClipboardList} style={{ color: "lightgray", float: "right", margin: "3px 5px 0 0" }} />
					&nbsp;{translate("common.nomenclature")}
				</>

			}
			data={nomenclatureTableData}
		/>
	)
} 