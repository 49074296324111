import React from 'react';
import { useContext } from "react";
import { LangContext } from "../../../../Contexts/Lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVial } from "@fortawesome/free-solid-svg-icons";
import DetailQueryTable, { DetailQueryTableRowI } from "./DetailQueryTable";
import { AsyncDataI, StudyI } from "src/Contexts/interfaces";
import { BadgeSamplePopover } from 'src/Components/Common/Badges/BadgeSample';
import { BadgeZygosityByAllele } from 'src/Components/Common/Badges/BadgeZygosity';
import { ModalSampleIgvButton, SampleInfo } from 'src/Components/Common/SampleInfo';
import { SampleInfoIgvDataI } from 'src/Components/IGV/ModalSamplesIgv';
import { rowValuesAdapter } from '../VariantsTable/VariantsTableBody';

interface SampleInfoDetailTableI {
	variant: AsyncDataI,
	isMitochondrial: boolean,
	study: StudyI,
	isSomatic: boolean,
	handleShowModalSamplesIgv(i: SampleInfoIgvDataI): void

}

export default function SampleInfoDetailTable(props: SampleInfoDetailTableI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const variant = props.variant?.data?.variant;
	const rv = rowValuesAdapter(variant, variant.id, null, null, props.study?.samples)
	const allRv = rv ? [rv] : []
	const sampleInfo_simpleOrFamiliar = (allRv).map((x: any, i: number): DetailQueryTableRowI => {
		const sample = props.study?.samples.find((y: any) => y.sample.name.toUpperCase() === x.genoInfo.sampleName.toUpperCase());
		return ({
			id: "1",
			label: (
				<div style={{ textAlign: "center" }}>
					<BadgeSamplePopover sample={sample} />
				</div>),
			value: (
				<div >
					<div >
						{!props.isSomatic && <>
							&nbsp;<strong>{translate("common.zygosity")}</strong>&nbsp;:&nbsp;
							<BadgeZygosityByAllele alleles={x.genoInfo.alleles}
								chromosome={x.chromosome}
								study={props.study}
								isMale={(sample?.sample?.sexo ?? "").toUpperCase().trim() === "MALE"}
								isMitochondrial={(props.study?.subtype ?? "").toUpperCase().trim() === "MT-DNA"}
							/>
							&nbsp;|&nbsp;
						</>
						}
						<SampleInfo variant={x}
							isSomatic={props.isSomatic}
							isMitochondrial={props.isMitochondrial}
							longLabel={true}
						/>
						{allRv.length - 1 !== i && <hr style={{ margin: "5px" }} />}
					</div >
					<div style={{ textAlignLast: "start", marginLeft: 4 }}>
						<ModalSampleIgvButton
							study={props.study}
							studyType={props.study.type}
							studyId={props.study.id}
							isSomatic={props.isSomatic}
							rv={rv}
							allRv={[rv]}
							handleShowModalSamplesIgv={props.handleShowModalSamplesIgv}
						/>
					</div >
				</div >
			)

		})
	})

	return (
		<DetailQueryTable id="DetailQueryTable_hgvs"
			title={
				<>
					<FontAwesomeIcon icon={faVial} style={{ color: "lightgray", float: "right", margin: "3px 5px 0 0" }} />
					&nbsp;{translate("casesQuery.sampleInformationSing")}
				</>

			}
			data={sampleInfo_simpleOrFamiliar}
		/>
	)
} 