import React from 'react';
import { Accordion, Card, Form, Table } from "react-bootstrap";
import { useContext } from "react";
import { LangContext } from "../../../Contexts/Lang";
import './styles.css';
import FormDate from '../../Common/Form/FormDate';
import { studyType } from '../../Common/Interfaces';
import BadgeSample from "../../Common/Badges/BadgeSample";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

interface EditSamplesI {
    studyType: studyType,
    samples: any[],
    setsamplesEdit: any,
}
export default function EditCases(props: EditSamplesI) {

    const { dispatch: { translate } } = useContext(LangContext);
    const rows = props.samples.map((x: any, i: number) => {
        function handleSamplesEdit(newSample: any) {
            let s = props.samples;
            const index = s.findIndex((y: any) => y.id === x.id);
            s[index] = newSample
            props.setsamplesEdit([...s])
        }
        return (
            <tr key={i} >
                <EditSamplesRow studyType={props.studyType}
                    row={x}
                    handleSamplesEdit={handleSamplesEdit} />
            </tr>
        )
    })
    const thStyles = { padding: "5px" }
    function getSamplesBadges() {
        return props.samples.map((x: any, i: number) => {

            return (
                <span key={i}>
                    <BadgeSample
                        name={x.sample.name}
                        afected={x.afectado}
                        fontSize="10px" />&nbsp;
                </span>
            )
        })
    }
    return (
        <div style={{ padding: 0 }}>
            <Accordion.Toggle as={Card.Header} eventKey="1"  >
                <span style={{ display: "flex", justifyContent: "space-between"}}>
                    <span style={{ display: "block" }}>{translate(`home.processCase.editSamples`)}</span>
                    <FontAwesomeIcon icon={faAngleDown} style={{ float: "right", fontSize: "16px", color: "gray" }} />
                </span>
                <div style={{ display: "inline-table", marginLeft: "10px" }}>
                    {getSamplesBadges()}
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
                <Card.Body style={{ padding: "1rem" }}>
                    {/* <div style={{ overflowY: "scroll", maxHeight: "55vh", overflowX: "hidden", fontSize: "12px" }}> */}
                    <Table responsive="sm" className="myTable" >
                        <thead style={{ backgroundColor: "#EEEEEE" }}>
                            <tr>
                                <th style={thStyles}>{translate(`common.sample`)}</th>
                                {props.studyType === "familiar" &&
                                    <th style={thStyles}>{translate(`home.processCase.relation`)}</th>
                                }
                                <th style={thStyles}>{translate(`common.sex`)}</th>
                                <th style={thStyles}>{translate(`common.birth`)}</th>
                                {props.studyType === "familiar" &&
                                    <th style={thStyles}>{translate(`home.processCase.affected`)}</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table >
                    {/* </div> */}
                </Card.Body>
            </Accordion.Collapse>
        </div>
    )
}
interface EditSampleRowI {
    studyType: studyType,
    row: any,
    handleSamplesEdit: any
}
function EditSamplesRow(props: EditSampleRowI) {

    const { dispatch: { translate } } = useContext(LangContext);

    const relationship = [
        // OTHER("Other"), FATHER("Father"), MOTHER("Mother"), PROBAND("Proband"), SIBLING("Sibling");
        { value: "FATHER", label: translate(`home.processCase.relations.father`) },
        { value: "MOTHER", label: translate(`home.processCase.relations.mother`) },
        { value: "SIBLING", label: translate(`home.processCase.relations.sibling`) },
        { value: "PROBAND", label: translate(`home.processCase.relations.proband`) },
        { value: "OTHER", label: translate(`home.processCase.relations.other`) }
    ]
    const sex = [
        { value: "MALE", label: translate(`common.sexType.male`) },
        { value: "FEMALE", label: translate(`common.sexType.female`) },
        { value: "", label: translate(`common.sexType.notDefined`) }
    ]
    function getSelectOptions(array: any[]) {
        return array?.map((x: any, i: number) =>
            <option key={i} value={x.value}>{x.label}</option>
        )

    }
    function commonFormSelect(key: string, name: string, options: any) {
        function getSelectValue() {
            if (name === "sexo") {
                const s = props.row.sample.sexo
                if (s !== "MALE" && s !== "FEMALE")
                    return ""
                return s
            } else {
                const r = props.row.tag
                if (r !== "FATHER" && r !== "MOTHER" && r !== "SIBLING" && r !== "PROBAND")
                    return "OTHER"
                return r
            }
        }
        function handleChange(e: any) {
            let newRow = props.row;
            if (name === "sexo") {
                newRow.sample.sexo = e.target.value
                props.handleSamplesEdit({ ...newRow })
            } else {
                newRow.tag = e.target.value
            }
            props.handleSamplesEdit({ ...newRow })
        }
        const value = getSelectValue()
        return <Form.Group key={key} style={{ marginBottom: "0" }} >
            <Form.Control
                as="select" aria-label="Default select example"
                style={{ borderRadius: 0, fontSize: "12px", height: "26px", padding: "0 0 0 5px" }}
                name={name}
                value={value}
                onChange={handleChange}>
                {options}
            </Form.Control>
        </Form.Group >
    }
    const sexSelect = commonFormSelect("genomeRefKey", "sexo", getSelectOptions(sex))
    const relSelect = commonFormSelect("genomeRefKey", "genomaRef", getSelectOptions(relationship))
    let birth = props.row.sample.fechaNacimiento
    if (birth && birth !== "")
        birth = new Date(birth)
    function handleDate(date: Date) {
        let newRow = props.row;
        newRow.sample.fechaNacimiento = date
        props.handleSamplesEdit({ ...newRow })
    }

    return (
        <>
            <td style={{ padding: "2px", verticalAlign: "middle", textAlignLast: "center" }}>
                <BadgeSample name={props.row.sample.name}
                    afected={props.row.afectado}
                    fontSize="12px" />
            </td>
            {props.studyType === "familiar" &&
                <td style={{ padding: "2px", verticalAlign: "middle" }}>
                    {relSelect}
                </td>
            }
            <td style={{ padding: "2px", verticalAlign: "middle" }}>
                {sexSelect}
            </td>
            <td style={{ padding: "2px", verticalAlign: "middle" }}>
                <FormDate //placeHolder={translate('home.filter.createdFrom')}
                    selected={birth}
                    style={{ "maxWidth": props.studyType !== "familiar" ? "auto" : "180px", maxHeight: "26px" }}
                    // selected={props.filters.dateCreatedFromSearch ? new Date(props.filters.dateCreatedFromSearch) : undefined}
                    onChange={(date: any) => handleDate(date)}
                    useMyClass={true}
                // onKeyDown={checkKeyPress} 
                />
            </td>
            {props.studyType === "familiar" &&
                <td style={{ padding: "2px", verticalAlign: "midle", textAlignLast: "center" }}>
                    <Form.Check type="checkbox"
                        checked={props.row.afectado}
                        onChange={() => {
                            let newRow = props.row;
                            newRow.afectado = !newRow.afectado
                            props.handleSamplesEdit({ ...newRow })
                        }}
                    />
                </td>
            }
        </>
    )
}
