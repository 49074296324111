import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';

export default class SampleServices extends AbstractService {
    basePath: string;
    constructor() {
        super();
        this.basePath = this.REACT_APP_API_URL + "sample/";
    }

    async get_sampleFiles(domainId?: string): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}sampleFiles`
        const config = {
            ...this.authHeader,
            params: {
                userId: this.user?.id,
                domainId: domainId ?? this.user?.domain,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }

    async get_samples(): Promise<AxiosResponse<any>> {
        const url = `${this.basePath}samples`
        const config = {
            ...this.authHeader,
            params: {
                domainId: this.user?.domain,
                _: (new Date()).getTime()
            }
        }
        return await axios.get(url, config)
    }

}
