import { UserActionType } from "../actionsTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any, action: any) => {
  const { payload, type } = action;
  switch (type) {
    // Async methods
    case UserActionType.LOGIN_SUCCESS: return { ...state, user: { data: payload, loading: false, error: false, loaded: true } };
    case UserActionType.LOGIN_ERROR: return { ...state, user: { data: state.user.data, loading: false, error: payload, loaded: true } };
    case UserActionType.LOGIN_LOADING: return { ...state, user: { data: state.user.data, loading: true, error: false, loaded: false } };

    case UserActionType.LOGOUT_SUCCESS: return { ...state, user: { data: {}, loading: false, error: false, loaded: false } };
    case UserActionType.LOGOUT_ERROR: return { ...state, user: { data: {}, loading: false, error: payload, loaded: false } };
    case UserActionType.LOGOUT_LOADING: return { ...state, user: { data: state.user.data, loading: true, error: false, loaded: false } };

    case UserActionType.UPDATE_USER_LOADING: return { ...state, user: { data: state.user.data, loading: true, error: false, loaded: false } };
    case UserActionType.UPDATE_USER_ERROR: return { ...state, user: { data: state.user.data, loading: false, error: payload, loaded: true } };
    case UserActionType.UPDATE_USER_SUCCESS: return { ...state, user: { data: payload, loading: false, error: false, loaded: true } };

    case UserActionType.UPDATE_PASS_LOADING: return { ...state, user: { data: state.user.data, loading: true, error: false, loaded: false } };
    case UserActionType.UPDATE_PASS_ERROR: return { ...state, user: { data: state.user.data, loading: false, error: payload, loaded: true } };
    case UserActionType.UPDATE_PASS_SUCCESS: return { ...state, user: { data: payload, loading: false, error: false, loaded: true } };

    case UserActionType.GET_USER_LOADING: return { ...state, users: { data: state.users.data, loading: true, error: false, loaded: false } };
    case UserActionType.GET_USER_ERROR: return { ...state, users: { data: state.users.data, loading: false, error: payload, loaded: true } };
    case UserActionType.GET_USER_SUCCESS: return { ...state, users: { data: payload, loading: false, error: false, loaded: true } };

    case UserActionType.GET_USERS_LOADING: return { ...state, users: { data: state.users.data, loading: true, error: false, loaded: false, refresh: false } };
    case UserActionType.GET_USERS_ERROR: return { ...state, users: { data: state.users.data, loading: false, error: payload, loaded: true, refresh: false } };
    case UserActionType.GET_USERS_SUCCESS: return { ...state, users: { data: payload, loading: false, error: false, loaded: true, refresh: false } };
    case UserActionType.GET_USERS_REFRESH: return { ...state, users: { ...state.users, refresh: true } };
    // Async methods

    case UserActionType.SET_FILTERS: return { ...state, filters: payload };
    case UserActionType.SET_SELECTEDS: return { ...state, selecteds: payload };

    default:
      return state;
  }
}