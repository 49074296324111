import React from 'react';
import { Badge, Button } from "react-bootstrap"
import { modalTabsType, SampleInfoIgvDataI } from "../../../../IGV/ModalSamplesIgv";
import { StudyI } from "../../../../../Contexts/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { roundWithTooltipoTo0 } from "src/Helpers/Helpers";
import { getColumnId } from "./GeneTable";
import { AlignmentT, ModalIgvDataI } from 'src/Components/IGV/types';
import TrascriptComponent from 'src/Components/Common/TranscriptComponent';

interface GenesI {
	chrom: string,//"chr7"
	coverage: string,//"1.0"
	end: string,//"34849675"
	genename: string,//"NPSR1"
	mean: string,//"101.86200000000001"
	start: string,//"34849275"
	transcript: string,//"NM_207172,NM_001300934,NM_207173,NM_001300935,NM_001300933"
}
interface GenesTableRowsI {
	regions: (any | GenesI)[]
	study: StudyI,
	studyId: string,
	handleShowModalSamplesIgv(i: SampleInfoIgvDataI): void,
	geneName: string,
	settableMounted(val: boolean): void,
	studyName: string
}

export default function GenesTableRows(props: GenesTableRowsI) {

	const getModal_SampleIgvButton = (x: GenesI) => {
		const getModal_SampleIgvData = (x: GenesI, mood: modalTabsType = "sample", view: AlignmentT, sampleNameSelected?: string): SampleInfoIgvDataI => {
			const getModal_IGVData = (viewType?: AlignmentT, sampleNameSelected?: string): ModalIgvDataI => {
				const getBadge_Sample = (sample: any, infoIcon: boolean = false, fontSize: string = "11px") => {
					return (
						<Badge variant="secondary"
							style={{
								fontWeight: "inherit",
								fontSize: fontSize,
								color: "white",
								backgroundColor: sample?.afectado ? "#FF4E58" : "#999999",
								margin: "2px",
								padding: "1px 5px 3px 5px",
								alignSelf: "center"
							}}>
							{`${sample?.sample?.name ?? "-"} ${sample ? sample.tag[0] : ""}`}
							{infoIcon &&
								<FontAwesomeIcon icon={faInfoCircle}
									style={{ fontSize: "12px", margin: "2px 0 0 6px", verticalAlign: "text-bottom" }} />
							}
						</Badge>
					)
				}
				let commonData: any
				let data: any = {}
				const alignaments = (props.study.samples ?? []).map((x: any, i: number) => {
					const actualSample = props.study?.samples.find((y: any) => y.sample.name === x.sampleName)
					if (i === 0) {
						data.studyName = x.sampleName;
						data.assembly = props.study?.genomeReference;
						commonData = {
							effect: "NA",
							impact: "-"
						}
					}
					return ({
						effect: x.effect,
						impact: x.impact,
						sampleName: x.sampleName,//x.sampleName,
						sampleBadge: getBadge_Sample(actualSample, false, "14px")//x.sampleName,
					})
				})
				const igvSamples = props.study?.samples.map((x: any) => {
					const igvElement = alignaments.find((y: any) => y.sampleName === x.sample.name)
					if (igvElement)
						return (igvElement)
					return {
						...commonData,
						sampleName: x.sample.name,//x.sample.name
						sampleBadge: getBadge_Sample(x, false, "14px")//x.sample.name
					}
				})
				console.log(data)
				return {
					...data,
					sampleNameSelecte: sampleNameSelected,
					view: viewType,
					alignments: igvSamples
				}
			}
			let handlerData: any = {
				mood: mood,
				chromosome: x.chrom,
				position: `${x.start}-${x.end}`,
				gen: x.genename
			}
			// if (props.studyType === "familiar" || props.studyType === "multiple") {
			//     const sd = getModal_SampleData()
			//     handlerData = {
			//         ...handlerData,
			//         sampleData: {
			//             change: rv.codonChange,
			//             table: sd
			//         }
			//     }
			// }
			if (props.study?.hasBam) {
				const igvData = getModal_IGVData(view, sampleNameSelected)
				handlerData = {
					...handlerData,
					igvData: igvData
				}
			}
			return handlerData
		}
		const getShowModalLink = (label: string, mood: modalTabsType = "sample", view: AlignmentT = "variant", sampleNameSelected?: string) => {
			return (
				<Button style={{ cursor: "pointer", color: "#009EEA", padding: "2px 5px" }} variant="outline-light"
					onClick={(e: any) => {
						e.stopPropagation();
						const handlerData = getModal_SampleIgvData(x, mood, view, sampleNameSelected)
						props.handleShowModalSamplesIgv(handlerData)
					}}
				>
					{label}
				</Button>
			)
		}
		if (props.study.type === "familiar" || props.study.type === "multiple")
			return (
				<div style={{ textAlign: "center" }}>
					{getShowModalLink("Show samples", "sample")}
				</div>
			)
		if (props.study?.hasBam)
			return (
				<div style={{ textAlign: "center" }}>
					{getShowModalLink("Locus", "igv", "variant", props.study.samplesNames[0])}
				</div>
			)
		return ""
	}

	const onClickHandler = (e: any) => {
		const hiddenElement = e.currentTarget.nextSibling;
		hiddenElement.className.indexOf("collapse show") > -1 ?
			hiddenElement.classList.remove("show") :
			hiddenElement.classList.add("show");
	};

	let regions = props.regions ? props.regions : [];
	return (
		<>
			{(regions).map((x: any, i: number) => {
				const register = (
					<tr key={i}
						style={{ fontSize: "12px" }}
						onClick={onClickHandler}>
						<td id={getColumnId(props.studyName, props.geneName, "transcript", i)}
							style={{ padding: "3px 5px", minWidth: "90px" }}>
							<TrascriptComponent transcript={x.transcript} />
						</td>
						<td id={getColumnId(props.studyName, props.geneName, "region", i)}
							style={{ padding: "3px 5px", minWidth: "90px" }}>
							{x.chrom}:{x.start}-{x.end}
						</td>
						<td id={getColumnId(props.studyName, props.geneName, "meanDepth", i)}
							style={{ padding: "3px 5px", minWidth: "140px" }}>
							{x.mean ? roundWithTooltipoTo0(x.mean) : "-"}
						</td>
						<td id={getColumnId(props.studyName, props.geneName, "coverage", i)}
							style={{ padding: "3px 5px", minWidth: "85px" }}>
							{x.coverage ? roundWithTooltipoTo0((+x.coverage) * 100) : "-"}
						</td>
						{(props.study?.hasBam ?? false) &&
							<td id={getColumnId(props.studyName, props.geneName, "alignment", i)}
								style={{ padding: "3px 5px", minWidth: "120px" }}>
								{getModal_SampleIgvButton(x)}
							</td>
						}
					</tr>
				)
				if (i === regions.length - 1) {
					setTimeout(() => {
						props.settableMounted(true)
					}, 10);
				}
				return register
			})}
		</>)
	// else (<></>)
}




