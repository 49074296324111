import React from 'react';
import { faUser, faUserEdit, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { LangContext } from "../../../Contexts/Lang";
import UserContext from "../../../Contexts/User/UserContext";
import FormInput from "../../Common/Form/FormInput";
// import { UserCard as FormCard, UserFormRow as FormRow } from "./UserCard";
import { Formik } from "formik";
import * as yup from "yup";
import { formInputType } from "../../Common/Interfaces";
import UserService from "../../../Services/UserServices";
import { rollesOptions } from "../../../utils/BioFunctionalData";
import { FormCard, FormRow } from "../../Common/Form/FormCard";
import { UserI } from "../../../Contexts/interfaces";


interface UserFormI {
    history: any,
    editUser?: UserI,
    createUser?: boolean,
    editMode?: boolean,
    domain?: {
        id: string,
        name: string
    }
    // handleEditState?(): void,
    handleClose?(): void,
    handleReload?(): void,
}
function UserForm(props: UserFormI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { user: loggedUser, dispatch: { updateLoggedUser } } = useContext(UserContext);
    const [initialForm, setInitialForm] = useState<any>("");
    const [mode, setmode] = useState<"read" | "edit">(props.editUser || props.createUser || props.editMode ? "edit" : "read");
    const US = new UserService();
    const handleMode = () => {
        if (mode === "read") setmode("edit")
        else setmode("read")
    }
    const readMode = mode === "read"
    let formRef: any = useRef();
    useEffect(() => {
        if (props.editMode && mode === "read") setmode("edit")
        else if (props.editMode === false && mode === "edit") setmode("read")
        let userForm: any = {
            name: "",
            fullname: "",
            email: "",
        }
        if (props.createUser) {
            userForm = {
                ...userForm,
                roll: rollesOptions(translate)[0],
            }
        } else if (props.editUser) {
            const userRoll = rollesOptions(translate)
                .filter((x: any) => x.value === props.editUser?.rolestoString.trim() ?? "")
            userForm = {
                name: props.editUser.username,
                fullname: props.editUser.fullName,
                email: props.editUser.email,
                roll: userRoll.length > 0 ? userRoll[0] : rollesOptions(translate)[4],
                enabled: props.editUser.enabled,
            }
        } else if (loggedUser.loaded && !props.createUser) {
            userForm = {
                name: loggedUser.data.username,
                fullname: loggedUser.data.fullName,
                email: loggedUser.data.email,
            }
        }
        setInitialForm(userForm)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedUser, props.editUser, props.editMode])

    ///.---------------------------------------------------------------------------------------------

    function createSchema() {
        const name = yup.string().min(3, translate(`error.toShort`)).max(50, translate(`error.toLong`)).required(translate(`error.required`));
        const fullname = yup.string().required(translate(`error.required`));
        const email = yup.string().email('Invalid email').required('Email is Required');
        let newSchema: any = { name, fullname, email }
        if (props.createUser || props.editUser) {
            const roll = yup.object()
            newSchema = { ...newSchema, roll }
        }
        if (props.editUser) {
            const enable = yup.boolean()
            newSchema = { ...newSchema, enable }
        }
        return yup.object().shape(newSchema)
    }
    function createForm(values: any, handleChange: any, errors: any) {
        var actualUser: any = loggedUser.data
        // console.log("createForm") 
        if (props.editUser) actualUser = props.editUser
        function commonFormInput(type: formInputType, key: string, name: string, required: boolean = false) {
            return <FormInput type={type}
                labelBold={true}
                labelColor={"gray"}
                key={key}
                formGrouplStyle={{ "marginBottom": 0 }}       // onKeyDown={(e: any) => handleEnter(e)}
                name={name}
                disabled={readMode}
                required={required}
                requiredFeedback={errors[name]}
                feedbackValidator={!!errors[name]}
                value={values[name]}
                onChange={handleChange} />
        }
        function commonFormSelect(key: string, name: string, options: any) {
            let getSelectOptions = (array: any[], addCeroValue: boolean = false) => {
                if (addCeroValue)
                    array = [{ value: undefined, label: "..." }, ...array]
                return array?.map((x: any, i: number) =>
                    <option key={i} value={x.value}>{x.label}</option>
                )
            }
            const opt = getSelectOptions(options)
            return <Form.Group key={key} style={{ marginBottom: 0 }} >
                <Form.Control as="select" aria-label="Default select example" style={{ borderRadius: 0, fontSize: "12px" }}
                    name={name}
                    value={values[name]?.value}
                    onChange={(e: any) => {
                        const newO = options.filter((x: any) => x.value === e.target.value)[0]
                        formRef?.current?.setValues({ ...values, "roll": newO })
                    }}
                    isInvalid={!!errors[name]}>
                    {opt}
                </Form.Control>
                <Form.Control.Feedback type="invalid"> {errors[name]} </Form.Control.Feedback>
            </Form.Group>
        }
        const rollSelect = commonFormSelect("rollKey", "roll", rollesOptions(translate))
        const nameInput = commonFormInput("text", "nameKey", "name", true);
        const fullnameInput = commonFormInput("text", "fullnameKey", "fullname", true);
        const emailInput = commonFormInput("email", "emailKey", "email", true);
        const domain = (
            <label style={{ fontWeight: "bold", color: "dimgray", fontSize: "12px" }}>
                &nbsp;&nbsp;{
                    props.domain?.name ? props.domain.name : (actualUser?.domainName ?? "")
                }
            </label>)
        return (
            <>
                <FormRow label={translate(`common.username`)}
                    input={nameInput}
                    required={true} /><br />
                <FormRow label={translate(`user.fullName`)}
                    input={fullnameInput}
                    required={true} /><br />
                <FormRow label={"Email"}
                    input={emailInput}
                    required={true} /><br />
                {
                    props.createUser || props.editUser ?
                        <>
                            < FormRow label={"Rol"}
                                input={rollSelect} />
                            <br />
                        </> :
                        <>
                            <FormRow label={"Rol"}
                                input={
                                    <label style={{ fontWeight: "bold", color: "dimgray", fontSize: "12px" }}>
                                        &nbsp;&nbsp;{(actualUser?.rolestoString ?? "").replace("ROLE_", "")}
                                    </label>
                                } />
                            <br />
                        </>
                }
                {
                    props.editUser &&
                    <>
                        <FormRow label={"Enable"}
                            input={
                                <Form.Check type="checkbox"
                                    checked={values?.enabled ?? false}
                                    name={"enabled"}
                                    onChange={handleChange}
                                />
                            } />
                        <br />
                        {/* <FormRow label={"Password"}
                            input={<>
                                <Button variant={"outline-primary"} onClick={props.handleEditState} style={{ border: "none" }}>
                                    <FontAwesomeIcon icon={faKey} style={{ marginRight: "10px" }} />
                                    {translate("user.change-password")}
                                </Button>
                            </>} />
                        <br /> */}
                    </>
                }
                <FormRow label={translate(`common.domain`)}
                    input={domain} />
            </>
        )
    }
    function handleSubmit(data: any): void {
        const reloadAndClose = () => {
            if (props.handleReload) props.handleReload();
            if (props.handleClose) props.handleClose();
        }
        if (props.createUser) {
            US.createUser(data.name, data.fullname, data.email, data.roll.value, props.domain?.id).then(() => reloadAndClose())
        } else if (props.editUser && props.editUser.id) {
            US.updateUser(`${props.editUser.id}`, data.name, data.fullname, data.email, `${props.editUser?.domain}`, data.enabled, data.roll.value).then(() => reloadAndClose())
        } else {
            updateLoggedUser(data.name, data.fullname, data.email).then(() => reloadAndClose())
        }
    }

    ///.---------------------------------------------------------------------------------------------
    return (
        <Formik id="UserForm"
            initialValues={initialForm}
            validationSchema={createSchema()}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            handleSubmit={console.log}
            innerRef={formRef}
            validateOnChange={false}
            validateOnBlur={true}>
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                <FormCard title={
                    !props.createUser && !props.editUser ?
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {mode === "read" ?
                                <div style={{ alignSelf: "center" }}>
                                    <FontAwesomeIcon icon={faUser} style={{ color: "lightgray", fontSize: "20px", marginRight: "10px" }} />
                                    {translate('user.user')}
                                </div> :
                                <div>
                                    <FontAwesomeIcon icon={faUserEdit} style={{ color: "#007BFF", fontSize: "20px", marginRight: "10px" }} />
                                    {translate('user.user-edit')}
                                </div>
                            }
                            <div style={{ float: "right" }}>
                                {props.editMode ?
                                    <FontAwesomeIcon icon={faEdit}
                                        style={{
                                            color: "lightgray",
                                            fontSize: "20px", padding: 0
                                        }} />
                                    :
                                    <Button variant="outline-primary"
                                        style={{
                                            border: "none",
                                            // padding: 0
                                        }}
                                        onClick={handleMode}
                                    >
                                        <FontAwesomeIcon icon={faEdit}
                                            style={{
                                                // color: "lightgray", 
                                                fontSize: "20px", padding: 0
                                            }} />
                                    </Button>
                                }
                            </div>
                        </div> : undefined
                }
                    body={createForm(values, handleChange, errors)}
                    enableSubmit={!readMode}
                    submit={handleSubmit}
                    handleCancel={props.handleClose}
                />
            )}
        </Formik>
    )
}

export { UserForm }
