import React from 'react';
import { Badge } from "react-bootstrap"
import { useContext } from "react";
import ThemeContext from '../../Contexts/Theme/ThemeContext';
import { Popover } from "src/Components/Common/Popover";
import { TriggersT } from '../IGV/types';

interface TruncatedTextWithPopoverI {
    popoveTitle: String,
    popoveContent: String,
    triger?: TriggersT,
    treshold?: number,
}

export default function TruncatedTextWithPopover(props: TruncatedTextWithPopoverI) {
    const { theme } = useContext(ThemeContext);
    const trigger = props.triger ?? "hover"
    const treshold = props.treshold ?? 10;
    if (`${props.popoveContent??""}`.length < treshold) return <>{props.popoveContent}</>;
    return <Popover title={props.popoveTitle}
        content={<div style={{ padding: "10px" }}>{props.popoveContent}</div>}
        autoClose={5000}
        style={{ fontSize: "10px" }}
        trigger={trigger}
        placement={"bottom"}
    >
        <Badge variant="light" style={{ cursor: "pointer", fontSize: "12px", color: theme.grayHeader, backgroundColor: theme.backgroundSecondary }}>
            {`${props.popoveContent.substring(0, treshold - 3)}...`}
        </Badge>
    </Popover>
}