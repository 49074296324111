type GnomadFrequencyT = "exome" | "genome";
interface GnomadFrequencyAdpaterI {
    alleleFreq: string,
    alleleCount: string,
    alleleNumb: string,
    popMaxAlleleFreq: string,
    popMaxAlleleCount: string,
    popMaxAlleleNumb: string,
    popMaxNHom: string,
    nHom: string
}
interface GnomadFrequencyI {
    type: GnomadFrequencyT,
    alleleFreq?: string,
    alleleCount?: number,
    alleleNumb?: number,
    popMaxAlleleFreq?: string,
    popMaxAlleleCount?: number,
    popMaxAlleleNumb?: number,
    popMaxNHom?: number,
    nHom?: number
}
class GnomadFrequency implements GnomadFrequencyI {
    private readonly threshold = 0.02;//TODO : REMOVE THIS AFTER THE NEW VIEW IN ADVANCED QUERYS
    public hasWarning: boolean;//TODO : REMOVE THIS AFTER THE NEW VIEW IN ADVANCED QUERYS
    public type: GnomadFrequencyT;
    public alleleFreq?: string;
    public alleleCount?: number;
    public alleleNumb?: number;
    public popMaxAlleleFreq?: string;
    public popMaxAlleleCount?: number;
    public popMaxAlleleNumb?: number;
    public popMaxNHom?: number;
    public nHom?: number
    public adapter: GnomadFrequencyAdpaterI;
    static readonly freqExomesAdapter: GnomadFrequencyAdpaterI = {
        alleleFreq: "GNOMAD_EXOME_ALL",
        alleleCount: "GNOMAD_EXOME_AC",
        alleleNumb: "GNOMAD_EXOME_AN",
        popMaxAlleleFreq: "GNOMAD_EXOME_POPMAX_AF",
        popMaxAlleleCount: "GNOMAD_EXOME_POPMAX_AC",
        popMaxAlleleNumb: "GNOMAD_EXOME_POPMAX_AN",
        popMaxNHom: "GNOMAD_EXOME_POPMAX_NHOM",
        nHom: "GNOMAD_EXOME_NHOM"
    }
    static readonly freqGenomesAdapter: GnomadFrequencyAdpaterI = {
        alleleFreq: "GNOMAD_GENOME_ALL",
        alleleCount: "GNOMAD_GENOME_AC",
        alleleNumb: "GNOMAD_GENOME_AN",
        popMaxAlleleFreq: "GNOMAD_GENOME_POPMAX_AF",
        popMaxAlleleCount: "GNOMAD_GENOME_POPMAX_AC",
        popMaxAlleleNumb: "GNOMAD_GENOME_POPMAX_AN",
        popMaxNHom: "GNOMAD_GENOME_POPMAX_NHOM",
        nHom: "GNOMAD_GENOME_NHOM"
    }
    // static getReversAdapter = (type: GnomadFrequencyT) => {
    //     const freq: any = type === "exome" ? GnomadFrequency.freqExomesAdapter : GnomadFrequency.freqGenomesAdapter
    //     return Object.keys(freq).reduce((acc: any, key: any) => {
    //         acc[freq[key]] = key;
    //         return acc;
    //     }, {});
    // }
    constructor(type: GnomadFrequencyT, infoCd?: any) { // todo: typify this infoCD data!!
        this.type = type;
        this.hasWarning = false;
        this.adapter = this.type === "exome" ? GnomadFrequency.freqExomesAdapter : GnomadFrequency.freqGenomesAdapter;
        const adapters = Object.entries(this.adapter);
        if (infoCd) {
            adapters.forEach((x: string[]) => {
                if (infoCd[x[1]] || infoCd[x[1]] === 0) {
                    if (x[1] === this.alleleFreq) {
                        Object.assign(this, { [x[0]]: `${infoCd[x[1]]}`, });
                    } else {
                        Object.assign(this, { [x[0]]: infoCd[x[1]], });
                    }
                }
            })
            if (this.alleleFreq) this.alleleFreq = this.handleDecimals(this.alleleFreq, "af");
            if (this.popMaxAlleleFreq) this.popMaxAlleleFreq = this.handleDecimals(this.popMaxAlleleFreq, "af");
        }
    }

    private handleDecimals(input: any, type?: "af") {
        let result: any = parseFloat(`${input}`);
        if (type=== "af" && result < this.threshold) this.hasWarning = true;
        if (`${result}`.indexOf("0.0000") > -1) {
            result = result.toExponential();
            const exponentialIndex = `${result}`.indexOf("e-");
            if (exponentialIndex > 3) {
                result = `${result}`.slice(0, 3) + `${result}`.slice(exponentialIndex, `${result}`.length);
            }
        } else {
            result = result.toFixed(4);
        }
        return result;
    }
}


export default GnomadFrequency;
export type { GnomadFrequency, GnomadFrequencyAdpaterI }
